import { graphql } from "@apollo/client/react/hoc";
import { Loading } from "components/notification";
import { batchesSelectors } from "ducks/batches";
import { thingSelectors } from "ducks/thing";
import PropTypes from "prop-types";
import { pathOr } from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withProps } from "react-recompose";
import { LIST_THING_BATCHES } from "../../../../graphql/queries";
import ThingCredentialList from "./thing_credential_list";

const _hasProcessingBatches = (files, maxProcessingBatchAge) => {
  const now = new Date().getTime();
  const hasProcessingBatches =
    files &&
    files.some(
      file => !file.finishedAt && now - file.createdAt < maxProcessingBatchAge
    );
  return hasProcessingBatches;
};

class ThingCredentialsWidget extends Component {
  render() {
    if (this.props.isLoading && !this.props.isCreating) {
      return (
        <div style={{ top: "30%", position: "absolute" }}>
          <Loading style={{ top: "35%", position: "absolute" }} />;
        </div>
      );
    } else if (this.props.graphQlListBatches?.error) {
      const errorMessage = this.props.graphQlListBatches?.error.message?.includes(
        "User does not have required privilege"
      )
        ? "You do not have permission to see Thing Credentials"
        : "There was an error fetching credentials";

      return <p style={{ top: "30%", position: "absolute" }}>{errorMessage}</p>;
    } else return <ThingCredentialList {...this.props} />;
  }
}

ThingCredentialsWidget.propTypes = {
  params: PropTypes.object,
  container: PropTypes.object,
  files: PropTypes.array,
  fetchState: PropTypes.string,
  thingType: PropTypes.string,
  dashboardType: PropTypes.string,
  maxProcessingBatchAge: PropTypes.number,
  filter: PropTypes.object,
  listThingBatches: PropTypes.func,
  creatingFinished: PropTypes.func,
  isLoading: PropTypes.bool,
  isCreating: PropTypes.bool,
  hasProcessingBatches: PropTypes.bool,
  userCanDownloadBatch: PropTypes.bool,
  graphQlListBatches: PropTypes.object
};

export default compose(
  graphql(LIST_THING_BATCHES, {
    name: "graphQlListBatches",
    options: props => ({
      variables: props.thingType
        ? {
            filter: { thingTypes: [props.thingType], prefix: "batch-" },
            showError: false
          } // Kind of wierd behaviour from the backend api, we know
        : { filter: { prefix: "batch-" }, showError: false }
    })
  }),
  withProps(props => ({
    isLoading: props.graphQlListBatches.loading,
    files: pathOr(
      [],
      ["graphQlListBatches", "allThingBatches", "files"]
    )(props),
    userCanDownloadBatch: props.userCanDownloadBatch
  })),
  connect((state, props) => {
    const maxProcessingBatchAge = 300000;
    return {
      userCanDownloadBatch: thingSelectors.userCanDownloadBatch(state),
      isLoading: props.isLoading,
      isCreating: batchesSelectors.isCreating(state),
      hasProcessingBatches: _hasProcessingBatches(
        props.files,
        maxProcessingBatchAge
      )
    };
  })
)(ThingCredentialsWidget);
