import * as R from "ramda";
import { createSelector } from "reselect";
import { resourceSort } from "utils/sorters";
import * as gu from "utils/general_utils";
import { normalizeDomainsResponse } from "adapters/domains_adapter";
import { hasRegexpTest, whereKeyHasRegexMatch } from "utils/suggester_utils";

const resourcesSelector = createSelector(
  R.prop("schema"),
  R.path(["entities", "resources"])
);

const thingsRootSelector = R.compose(R.prop("thingsList"));

const thingsSelector = createSelector(
  thingsRootSelector,
  (state, { thingType }) => thingType,
  (thingsListState, thingType) =>
    R.pipe(
      R.pathOr([], ["entities", "thingTypes", thingType, "things"]),
      thingIds =>
        R.pick(thingIds, R.pathOr({}, ["entities", "things"])(thingsListState)),
      R.values
    )(thingsListState)
);

const _formatIcon = includeIcon => (item = {}) => {
  if (!includeIcon) return item.icon;
  if (/virtual/.test(item.id)) {
    return "vr";
  }
  if (/tcxn/.test(item.id)) {
    return "TCXN Convention";
  }
  if (/domain/.test(item.id)) {
    return "domain";
  }
  return item.icon;
};

const _labelPath = R.path(["metadata", "label"]);
export const _extractLabelFromMetaData = R.when(
  _labelPath,
  R.pipe(e => R.assoc("label", _labelPath(e), e))
);

export const pickFirstNonNilProp = props =>
  R.pipe(R.props(props), R.values, R.filter(R.compose(R.not, R.isNil)), R.head);

// We need to do some transformation in order to satisfy the table component
const _transforms = { id: R.identity };
const _extractText = R.pipe(
  _extractLabelFromMetaData,
  pickFirstNonNilProp(["path", "label", "name", "thingName", "id", "userName"])
);
export const dropdownTransducer = (
  transforms = _transforms,
  includeIcon = false
) =>
  R.map(
    R.pipe(
      R.defaultTo({}),
      R.converge(R.mergeDeepRight, [
        // We keep whatever we got
        R.identity,
        // Construct object for remapping
        R.applySpec({
          id: R.pipe(
            pickFirstNonNilProp([
              "thingName",
              "id",
              "path",
              "label",
              "userName"
            ]),
            transforms.id
          ),
          // Generic display name for Autosuggester
          text: _extractText,
          label: _extractText,
          // Specific for the Resource Picker in Table component
          visible: R.T, // We default to visible when newly added
          // Specific for the Resource Picker in Table component
          checked: R.propOr(true, "visible"),
          // Specific for the Resource Picker in Table component
          icon: _formatIcon(includeIcon),
          // Specific for the Resource Picker in Table component
          resource: R.propOr("Unknown", "id"),
          selector: R.always("resource")
        })
      ])
    )
  );

// Matcher takes an array of objects and runs a regexp on the id prop
const matcher = matcher =>
  R.pipe(R.defaultTo([]), R.filter(R.pipe(R.defaultTo({}), matcher)), R.values);

export const thingTypesByWildcardSelector = createSelector(
  R.identity,
  (thingTypes, query) => query,
  (thingTypes, query = "") => {
    const res = R.pipe(
      matcher(whereKeyHasRegexMatch("label", query)),
      // Make final transformation for our view
      R.transduce(dropdownTransducer(), R.flip(R.append), [])
    )(thingTypes);
    return res;
  }
);

export const usersByWildcardSelector = createSelector(
  R.identity,
  (users, query) => query,
  (users, query = "") => {
    const res = R.pipe(
      // Make final transformation for our view
      R.transduce(dropdownTransducer(), R.flip(R.append), [])
    )(users);
    return res;
  }
);

export const thingsByWildcardSelector = createSelector(
  thingsSelector,
  (things, query) => query,
  (things, { thingLabel = "" }) => {
    return R.pipe(
      matcher(whereKeyHasRegexMatch("label", thingLabel)),
      // Make final transformation for our view
      R.transduce(dropdownTransducer(), R.flip(R.append), [])
    )(things);
  }
);

const domainsFromTreeSelector = createSelector(
  domainTree => domainTree,
  domainTree =>
    R.pipe(
      normalizeDomainsResponse,
      R.path(["entities", "domains"]),
      R.values
    )(domainTree)
);

export const domainsByWildcardSelector = createSelector(
  domainsFromTreeSelector,
  (domains, query) => query,
  (domains, query = "") => {
    return R.pipe(
      matcher(whereKeyHasRegexMatch("path", query)),
      R.transduce(
        dropdownTransducer({
          id: R.pipe(R.split("/"), R.last)
        }),
        R.flip(R.append),
        []
      )
    )(domains);
  }
);

const _isCorrectThingType = thingTypeId =>
  whereKeyHasRegexMatch("id", `^${thingTypeId}(/.*)`);

const _filterOn = query =>
  R.anyPass([
    whereKeyHasRegexMatch("id", `(/.*)${query}`),
    R.pathSatisfies(hasRegexpTest(`(.*)${query}`), ["metadata", "label"]),
    R.propSatisfies(hasRegexpTest(`(.*)${query}`), ["label"])
  ]);

export const _filterOnMatcher = (query = "") => matcher(_filterOn(query));

export const resourcesByWildcardSelector = createSelector(
  resourcesSelector,
  (resources, query) => query,
  (resources, query, thingTypeId) => thingTypeId,
  (resources, query = "", thingTypeId = ".*") => {
    return R.pipe(
      matcher(R.allPass([_isCorrectThingType(thingTypeId), _filterOn(query)])),
      R.transduce(
        dropdownTransducer(undefined, true),
        R.flip(gu.mergeOnProp("id")),
        []
      ),
      resourceSort
    )(resources);
  }
);

export const adjustResourceSuggestions = (
  resources,
  query = "",
  thingTypeId = ".*"
) => {
  return R.pipe(
    matcher(R.allPass([_isCorrectThingType(thingTypeId), _filterOn(query)])),
    R.transduce(
      dropdownTransducer(undefined, true),
      R.flip(gu.mergeOnProp("id")),
      []
    ),
    resourceSort
  )(resources);
};

export const optionsDecorator = suggesterOptionsDecorator => list =>
  suggesterOptionsDecorator ? suggesterOptionsDecorator(list) : list;
export const filterOnQuery = (query = "") => _filterOnMatcher(query);
export const filterOnThingType = thingType =>
  thingType ? R.filter(_isCorrectThingType(thingType)) : r => r;
