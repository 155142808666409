import {
  F,
  T,
  always,
  append,
  assoc,
  assocPath,
  concat,
  contains,
  defaultTo,
  dissoc,
  evolve,
  ifElse,
  map,
  mergeDeepLeft,
  mergeDeepRight,
  mergeDeepWith,
  path,
  pipe,
  prop,
  reduce,
  union,
  uniq,
  without
} from "ramda";
import { handleActions, createAction } from "redux-actions";
import * as nz from "utils/normalisation_utils";
import { requestStatus } from "utils/request_status";

export const initialStateGroups = {
  ...nz.normalizedInitialState({
    selectedThingNames: [],
    request: {
      createStatus: requestStatus.IDLE,
      isAddingThings: false,
      isSelectingAllThings: false,
      isDescribeGroupLoading: false,
      isListThingsLoading: false
    }
  })
};

export const DEVICE_MANAGEMENT_LIST_GROUPS_REQUEST =
  "DEVICE_MANAGEMENT_LIST_GROUPS_REQUEST";
export const DEVICE_MANAGEMENT_LIST_GROUPS_SUCCESS =
  "DEVICE_MANAGEMENT_LIST_GROUPS_SUCCESS";
export const DEVICE_MANAGEMENT_LIST_GROUPS_FAILURE =
  "DEVICE_MANAGEMENT_LIST_GROUPS_FAILURE";

export const DEVICE_MANAGEMENT_CREATE_GROUP_REQUEST =
  "DEVICE_MANAGEMENT_CREATE_GROUP_REQUEST";
export const DEVICE_MANAGEMENT_CREATE_GROUP_SUCCESS =
  "DEVICE_MANAGEMENT_CREATE_GROUP_SUCCESS";
export const DEVICE_MANAGEMENT_CREATE_GROUP_FAILURE =
  "DEVICE_MANAGEMENT_CREATE_GROUP_FAILURE";

export const DEVICE_MANAGEMENT_DESCRIBE_GROUP_REQUEST =
  "DEVICE_MANAGEMENT_DESCRIBE_GROUP_REQUEST";
export const DEVICE_MANAGEMENT_DESCRIBE_GROUP_SUCCESS =
  "DEVICE_MANAGEMENT_DESCRIBE_GROUP_SUCCESS";
export const DEVICE_MANAGEMENT_DESCRIBE_GROUP_FAILURE =
  "DEVICE_MANAGEMENT_DESCRIBE_GROUP_FAILURE";

export const DEVICE_MANAGEMENT_LIST_THINGS_REQUEST =
  "DEVICE_MANAGEMENT_LIST_THINGS_REQUEST";
export const DEVICE_MANAGEMENT_LIST_THINGS_SUCCESS =
  "DEVICE_MANAGEMENT_LIST_THINGS_SUCCESS";
export const DEVICE_MANAGEMENT_LIST_THINGS_FAILURE =
  "DEVICE_MANAGEMENT_LIST_THINGS_FAILURE";

export const DEVICE_MANAGEMENT_DELETE_GROUPS_REQUEST =
  "DEVICE_MANAGEMENT_DELETE_GROUPS_REQUEST";
export const DEVICE_MANAGEMENT_DELETE_GROUP_SUCCESS =
  "DEVICE_MANAGEMENT_DELETE_GROUP_SUCCESS";
export const DEVICE_MANAGEMENT_DELETE_GROUPS_SUCCESS =
  "DEVICE_MANAGEMENT_DELETE_GROUPS_SUCCESS";

export const DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_REQUEST =
  "DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_REQUEST";
export const DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_SUCCESS =
  "DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_SUCCESS";
export const DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_FAILURE =
  "DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_FAILURE";

export const DEVICE_MANAGEMENT_TOGGLE_SELECT_THING =
  "DEVICE_MANAGEMENT_TOGGLE_SELECT_THING";

export const DEVICE_MANAGEMENT_DESELECT_THING =
  "DEVICE_MANAGEMENT_DESELECT_THING";

export const DEVICE_MANAGEMENT_DESELECT_ALL_THINGS =
  "DEVICE_MANAGEMENT_DESELECT_ALL_THINGS";

export const DEVICE_MANAGEMENT_SELECT_ALL_THINGS_REQUEST =
  "DEVICE_MANAGEMENT_SELECT_ALL_THINGS_REQUEST";
export const DEVICE_MANAGEMENT_SELECT_ALL_THINGS_SUCCESS =
  "DEVICE_MANAGEMENT_SELECT_ALL_THINGS_SUCCESS";
export const DEVICE_MANAGEMENT_SELECT_ALL_THINGS_FAILURE =
  "DEVICE_MANAGEMENT_SELECT_ALL_THINGS_FAILURE";

export const DEVICE_MANAGEMENT_REMOVE_THINGS_REQUEST =
  "DEVICE_MANAGEMENT_REMOVE_THINGS_REQUEST";
export const DEVICE_MANAGEMENT_REMOVE_THINGS_SUCCESS =
  "DEVICE_MANAGEMENT_REMOVE_THINGS_SUCCESS";
export const DEVICE_MANAGEMENT_REMOVE_THINGS_FAILURE =
  "DEVICE_MANAGEMENT_REMOVE_THINGS_FAILURE";

export const listGroups = createAction(DEVICE_MANAGEMENT_LIST_GROUPS_REQUEST);
export const listGroupsSuccess = createAction(
  DEVICE_MANAGEMENT_LIST_GROUPS_SUCCESS
);
export const listGroupsFailure = createAction(
  DEVICE_MANAGEMENT_LIST_GROUPS_FAILURE
);

export const createGroup = createAction(DEVICE_MANAGEMENT_CREATE_GROUP_REQUEST);
export const createGroupSuccess = createAction(
  DEVICE_MANAGEMENT_CREATE_GROUP_SUCCESS
);
export const createGroupFailure = createAction(
  DEVICE_MANAGEMENT_CREATE_GROUP_FAILURE
);
export const describeGroup = createAction(
  DEVICE_MANAGEMENT_DESCRIBE_GROUP_REQUEST
);
export const describeGroupFailure = createAction(
  DEVICE_MANAGEMENT_DESCRIBE_GROUP_FAILURE
);
export const describeGroupSuccess = createAction(
  DEVICE_MANAGEMENT_DESCRIBE_GROUP_SUCCESS
);

export const listThings = createAction(DEVICE_MANAGEMENT_LIST_THINGS_REQUEST);
export const listThingsFailure = createAction(
  DEVICE_MANAGEMENT_LIST_THINGS_FAILURE
);
export const listThingsSuccess = createAction(
  DEVICE_MANAGEMENT_LIST_THINGS_SUCCESS
);

export const deleteGroups = createAction(
  DEVICE_MANAGEMENT_DELETE_GROUPS_REQUEST
);
export const deleteGroupSuccess = createAction(
  DEVICE_MANAGEMENT_DELETE_GROUP_SUCCESS
);
export const deleteGroupsSuccess = createAction(
  DEVICE_MANAGEMENT_DELETE_GROUPS_SUCCESS
);

export const addThingsToGroup = createAction(
  DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_REQUEST
);
export const addThingsToGroupSuccess = createAction(
  DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_SUCCESS
);
export const addThingsToGroupFailure = createAction(
  DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_FAILURE
);

export const toggleSelectThing = createAction(
  DEVICE_MANAGEMENT_TOGGLE_SELECT_THING
);
export const deselectThing = createAction(DEVICE_MANAGEMENT_DESELECT_THING);
export const deselectAllThings = createAction(
  DEVICE_MANAGEMENT_DESELECT_ALL_THINGS
);

export const selectAllThings = createAction(
  DEVICE_MANAGEMENT_SELECT_ALL_THINGS_REQUEST
);
export const selectAllThingsSuccess = createAction(
  DEVICE_MANAGEMENT_SELECT_ALL_THINGS_SUCCESS
);
export const selectAllThingsFailure = createAction(
  DEVICE_MANAGEMENT_SELECT_ALL_THINGS_FAILURE
);

export const removeThings = createAction(
  DEVICE_MANAGEMENT_REMOVE_THINGS_REQUEST
);
export const removeThingsSuccess = createAction(
  DEVICE_MANAGEMENT_REMOVE_THINGS_SUCCESS
);
export const removeThingsFailure = createAction(
  DEVICE_MANAGEMENT_REMOVE_THINGS_FAILURE
);

export default handleActions(
  {
    [DEVICE_MANAGEMENT_LIST_GROUPS_REQUEST]: state =>
      evolve(
        {
          request: { error: always(null), isLoading: T }
        },
        state
      ),
    [DEVICE_MANAGEMENT_LIST_GROUPS_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          entities: always(mergeDeepLeft(payload.entities, state.entities)),
          results: always(payload.results),
          request: { isLoading: F }
        },
        state
      ),
    [DEVICE_MANAGEMENT_LIST_GROUPS_FAILURE]: (state, { payload }) =>
      evolve(
        {
          request: { isLoading: F, error: always(payload) }
        },
        state
      ),
    [DEVICE_MANAGEMENT_CREATE_GROUP_REQUEST]: state =>
      evolve(
        {
          request: {
            createStatus: always(requestStatus.REQUESTED),
            error: always(null),
            isLoading: T
          }
        },
        state
      ),
    [DEVICE_MANAGEMENT_CREATE_GROUP_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          entities: mergeDeepRight(payload.entities),
          results: pipe(concat(payload.results), uniq),
          request: {
            isLoading: F,
            createStatus: always(requestStatus.SUCCESSFUL)
          }
        },
        state
      ),
    [DEVICE_MANAGEMENT_CREATE_GROUP_FAILURE]: (state, { payload }) =>
      evolve(
        {
          request: {
            createStatus: always(requestStatus.FAILURE),
            error: always(payload),
            isLoading: F
          }
        },
        state
      ),
    [DEVICE_MANAGEMENT_DELETE_GROUPS_REQUEST]: evolve({
      request: {
        isLoading: T
      }
    }),
    [DEVICE_MANAGEMENT_DELETE_GROUP_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          entities: dissoc(payload.id),
          results: without(payload.id),
          request: {
            isLoading: F
          }
        },
        state
      ),
    [DEVICE_MANAGEMENT_DELETE_GROUPS_SUCCESS]: evolve({
      request: {
        isLoading: F
      }
    }),
    [DEVICE_MANAGEMENT_TOGGLE_SELECT_THING]: (state, { payload }) =>
      evolve(
        {
          selectedThingNames: ifElse(
            contains(payload),
            without(payload),
            append(payload)
          )
        },
        state
      ),
    [DEVICE_MANAGEMENT_DESCRIBE_GROUP_REQUEST]: state =>
      evolve(
        {
          request: { error: always(null), isDescribeGroupLoading: T }
        },
        state
      ),
    [DEVICE_MANAGEMENT_DESCRIBE_GROUP_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          entities: mergeDeepLeft(assoc(payload.id, payload, {})),
          request: { error: always(null), isDescribeGroupLoading: F }
        },
        state
      ),
    [DEVICE_MANAGEMENT_DESCRIBE_GROUP_FAILURE]: (state, { payload }) =>
      evolve(
        {
          request: {
            error: always(payload),
            isDescribeGroupLoading: F
          }
        },
        state
      ),
    [DEVICE_MANAGEMENT_LIST_THINGS_REQUEST]: state =>
      evolve(
        {
          request: { error: always(null), isListThingsLoading: T }
        },
        state
      ),
    [DEVICE_MANAGEMENT_LIST_THINGS_SUCCESS]: (state, { payload }) =>
      pipe(
        // Construct a skeleton of all our groups. [{...}, ...]
        defaultTo([]),
        map(thingName =>
          assocPath(
            ["entities", prop("id", payload), "thingNames"],
            [thingName],
            {}
          )
        ),
        // Merge our group skeletons and concat. {...}
        reduce(mergeDeepWith(concat), {}),
        // Merge our thingNames state with our current groups data on thingNames and dedup. {...}
        mergeDeepWith((l, r) => uniq(concat(l, r)), state),
        evolve({
          request: {
            error: always(null),
            isListThingsLoading: F
          }
        })
      )(path(["listThings", "things"], payload)),
    [DEVICE_MANAGEMENT_LIST_THINGS_FAILURE]: (state, { payload }) =>
      evolve(
        {
          request: {
            error: always(payload),
            isListThingsLoading: F
          }
        },
        state
      ),
    [DEVICE_MANAGEMENT_DESELECT_THING]: (state, { payload }) =>
      evolve(
        {
          selectedThingNames: without(payload)
        },
        state
      ),
    [DEVICE_MANAGEMENT_DESELECT_ALL_THINGS]: state =>
      evolve(
        {
          selectedThingNames: always([])
        },
        state
      ),

    [DEVICE_MANAGEMENT_SELECT_ALL_THINGS_REQUEST]: state =>
      evolve(
        {
          request: {
            isSelectingAllThings: T
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_SELECT_ALL_THINGS_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          selectedThingNames: union(payload),
          request: {
            isSelectingAllThings: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_SELECT_ALL_THINGS_FAILURE]: state =>
      evolve(
        {
          request: {
            isSelectingAllThings: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_REQUEST]: state =>
      evolve(
        {
          request: {
            isAddingThings: T
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_FAILURE]: state =>
      evolve(
        {
          request: {
            isAddingThings: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_SUCCESS]: state =>
      evolve(
        {
          selectedThingNames: always([]),
          request: {
            isAddingThings: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_REMOVE_THINGS_REQUEST]: state =>
      evolve({
        request: {
          isListThingsLoading: T
        }
      })(state),

    [DEVICE_MANAGEMENT_REMOVE_THINGS_SUCCESS]: (state, { payload }) =>
      evolve({
        entities: {
          [payload.id]: {
            thingNames: without(payload.thingNames)
          }
        },
        request: {
          isListThingsLoading: F
        }
      })(state),

    [DEVICE_MANAGEMENT_REMOVE_THINGS_FAILURE]: (state, { payload }) =>
      evolve({
        entities: {
          [payload.id]: {
            thingNames: without(payload.successful)
          }
        },
        request: {
          isListThingsLoading: F
        }
      })(state)
  },
  initialStateGroups
);
