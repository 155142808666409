import { graphql } from "@apollo/client/react/hoc";
import { getResourceValue } from "ducks/aggregators/resources";
import { getResourceName } from "ducks/schema/schema_selectors";
import { emitToStream } from "ducks/thing";
import {
  RESOURCE_OPTIONS_QUERY,
  THING_DOMAIN_TOPIC,
  THING_TYPE_RESOURCE_QUERY
} from "graphql/queries";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  compose,
  pure,
  withHandlers,
  withProps,
  withStateHandlers
} from "react-recompose";
import * as resourceUtils from "utils/resource_utils";
import DesiredDialog from "./desired";

export const getInitialDesiredValue = ({ pendingValue }) =>
  pendingValue == null ? "" : String(pendingValue);

const _mapStateToProps = (state, props) => {
  const {
    open,
    resourceId,
    thingName,
    thingType,
    subthing,
    subThingType,
    type
  } = props.modalStateDesired;

  const resourceState = getResourceValue({
    state,
    resourceName: resourceId,
    thingName,
    thingType,
    subthing,
    subThingType
  }).toJS();

  const reportedValue = String(R.path(["latestValue", "value"], resourceState));
  const initialDesiredValue = getInitialDesiredValue(resourceState);

  return {
    open,
    onClose: props.closeModalDesired,
    resourceId,
    reportedValue,
    initialDesiredValue,
    thingName,
    thingType,
    subthing,
    subThingType,
    type
  };
};

const _mapDispatchToProps = { emitToStream };

const _withStateHandlers = withStateHandlers(
  props => ({
    desiredValue: props.initialDesiredValue
  }),
  {
    onDesiredValueChange: () => e => ({ desiredValue: e.target.value })
  }
);

const _withProps = withProps(props => ({
  label: R.path(
    ["thingTypeResourceQuery", "thingTypeResource", "metadata", "label"],
    props
  ),
  unit: R.path(
    ["thingTypeResourceQuery", "thingTypeResource", "metadata", "unit"],
    props
  ),
  isVirtual: R.path(
    ["thingTypeResourceQuery", "thingTypeResource", "metadata", "isVirtual"],
    props
  ),
  settableOptions: R.pathOr(
    [],
    ["resourceOptionsQuery", "resourceOptions", "options"],
    props
  ),
  domainPath: R.path(["thingDomainTopicQuery", "thing", "domainTopic"], props),
  isLoading:
    props.thingTypeResourceQuery.loading ||
    props.resourceOptionsQuery.loading ||
    props.thingDomainTopicQuery.loading
}));

export const getValueToReport = ({ type, desiredValue }) =>
  type === "reject" ? null : resourceUtils.typeValue(desiredValue).virtualValue;

export const makePayload = ({ isVirtual, value, subthing, resourceId }) => {
  if (/^[^/]+\/tcxn\/.*$/.test(resourceId)) {
    subthing = "tcxn";
  }

  const resourceName = getResourceName(resourceId);
  const isSubthing = subthing != null && subthing !== "default";
  const payloadType = isVirtual ? "reported" : "desired";
  return isSubthing
    ? { state: { [payloadType]: { [subthing]: { [resourceName]: value } } } }
    : { state: { [payloadType]: { [resourceName]: value } } };
};

const _withHandlers = withHandlers({
  onConfirm: ({
    closeModalDesired,
    desiredValue,
    domainPath,
    emitToStream,
    isVirtual,
    resourceId,
    subthing,
    thingName,
    type
  }) => () => {
    const value = getValueToReport({ type, desiredValue });
    const data = makePayload({ isVirtual, resourceId, subthing, value });
    emitToStream({ domainPath, thingName, data });
    closeModalDesired();
  }
});

export default compose(
  connect(_mapStateToProps, _mapDispatchToProps),
  graphql(THING_DOMAIN_TOPIC, { name: "thingDomainTopicQuery" }),
  graphql(RESOURCE_OPTIONS_QUERY, { name: "resourceOptionsQuery" }),
  graphql(THING_TYPE_RESOURCE_QUERY, { name: "thingTypeResourceQuery" }),
  _withStateHandlers,
  _withProps,
  _withHandlers,
  pure
)(DesiredDialog);
