import { createAction, handleActions } from "redux-actions";
import { fromJS } from "immutable";
import { baseTheme } from "themes";

export const THEME_REQUEST = "THEME_REQUEST";
export const THEME_SUCCESS = "THEME_SUCCESS";
export const THEME_FAILURE = "THEME_FAILURE";

export const SAVE_THEME_REQUEST = "SAVE_THEME_REQUEST";
export const SAVE_THEME_SUCCESS = "SAVE_THEME_SUCCESS";
export const SAVE_THEME_FAILURE = "SAVE_THEME_FAILURE";
export const SET_THEME = "SET_THEME";

export const getTheme = createAction(THEME_REQUEST);
export const getThemeSuccess = createAction(THEME_SUCCESS);
export const getThemeFailure = createAction(THEME_FAILURE);

export const saveTheme = createAction(SAVE_THEME_REQUEST);
export const saveThemeSuccess = createAction(SAVE_THEME_SUCCESS);
export const saveThemeFailure = createAction(SAVE_THEME_FAILURE);
export const setTheme = createAction(SET_THEME);

const initialState = fromJS(baseTheme);

export default handleActions(
  {
    [SET_THEME]: (state, { payload: theme }) => state.merge(theme)
  },
  initialState
);
