import { createSelector } from "reselect";
import { Map, List } from "immutable";
import { _lowerCaseSubThing } from "utils/resource_utils";
import { authSelectors } from "../auth";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";
import * as R from "ramda";
import merge from "lodash.merge";
import { isLegacyWriteRole } from "routes/main/settings/roles/role_constants";

export const getThingShadow = createSelector(
  (thingState, thingName) =>
    thingState ? thingState.getIn(["thingShadow", thingName], Map()) : Map(),
  thingShadow => thingShadow
);

export const getDomainId = (thingState, thingName) =>
  getThingShadow(thingState, thingName).getIn(["domain"]);

export const getResourceValue = (state, thingName, resourceId, subthing) => {
  if (resourceId.indexOf("domain.") > -1) {
    return state.thing.getIn([
      "thingShadow",
      thingName,
      ...resourceId.split(".")
    ]);
  } else {
    resourceId = _lowerCaseSubThing(resourceId);
    resourceId =
      subthing && subthing !== "default"
        ? `${subthing}/${resourceId}`
        : resourceId;
    if (!subthing && resourceId.indexOf("/subthing/") > 0) {
      // when we have a resource id that contains a subthing but
      // the subthing is null then we need to filter on subthings
      const reg = new RegExp(`${thingName}/.*/${resourceId}$`);
      const resources = state.thing
        .getIn(["thingShadow", thingName, "resources"], Map({}))
        .filter((val, key) => reg.test(key));
      return resources && resources.size > 0 ? resources.first() : undefined;
    } else {
      resourceId = `${thingName}/${resourceId}`;
      return state.thing.getIn([
        "thingShadow",
        thingName,
        "resources",
        resourceId
      ]);
    }
  }
};

export const addDefaultSubThing = (pickerSubThings, thingType) => {
  if (pickerSubThings && pickerSubThings.size > 0) {
    return pickerSubThings.insert(
      0,
      Map({
        thingName: "default",
        subThingName: "default",
        thingType,
        type: "",
        subthingId: "default"
      })
    );
  } else {
    return pickerSubThings;
  }
};

export const getPickerSubthings = (thingState, thingName) => {
  const subthings = thingState.getIn(
    ["thingShadow", thingName, "subthings"],
    Map()
  );
  return subthings
    .reduce((pickerList, val, key) => {
      const [, subThingName, thingType, , type] = key.split("/");
      return pickerList.push(
        Map({ thingName, subThingName, thingType, type, subthingId: key })
      );
    }, List())
    .sort((a, b) => {
      const asubthingId = a.get("subthingId");
      const bsubthingId = b.get("subthingId");
      if (asubthingId < bsubthingId) {
        return -1;
      } else if (asubthingId > bsubthingId) {
        return 1;
      }
      return 0;
    });
};

export const isLoading = state => state.thing.get("isLoading");

export const getResourceIds = (normalizedThingShadow, thingName, subthing) => {
  let thingEntity = normalizedThingShadow.getIn(
    ["entities", "thing", thingName],
    Map()
  );
  let resourceIds = thingEntity.get("resources");
  const subThingIds = thingEntity.get("subthings");
  resourceIds = R.equals(undefined, subthing)
    ? resourceIds
    : subThingIds
        .filter(
          subThingId => subThingId.indexOf(`${thingName}/${subthing}/`) > -1
        )
        .reduce((resourceIds, subThingId) => {
          const subThingResources = normalizedThingShadow.getIn([
            "entities",
            "subthing",
            subThingId,
            "resources"
          ]);
          if (subThingResources) {
            return resourceIds.push(...subThingResources.toArray());
          } else {
            return resourceIds;
          }
        }, resourceIds);
  return resourceIds;
};

export const isLoadingImage = state => state.thing.get("isLoadingImage", false);

export const getCreateThingStatus = state =>
  state.thing.get("createThingStatus");

export const getAllResources = (normalizedThingShadow, thingName) =>
  normalizedThingShadow.getIn(["thingShadow", thingName, "resources"]);

export const resources = (thingState, thingName, thingType, subthing) => {
  const idPath = `${thingName}/${
    R.equals(undefined, subthing) ? "" : subthing + "/"
  }${thingType}`;
  const r = new RegExp(`^${idPath}`);
  return thingState
    ? thingState
        .getIn(["thingShadow", thingName, "resources"], Map())
        .filter((val, key) => r.test(key))
    : Map({});
};

export const removeThingSubThingPrefix = (resources, thingType) =>
  resources
    ? resources.reduce((acc, val, key) => {
        let i = key.indexOf(`/${thingType}`) + 1;
        const id = key.slice(i);
        return acc.set(id, val.set("id", id));
      }, Map({}))
    : Map({});

export const getResourcesFromShadow = (
  thingState,
  thingName,
  thingType,
  subthing
) => {
  if (subthing) {
    return removeThingSubThingPrefix(
      resources(thingState, thingName, thingType, subthing),
      thingType
    );
  } else {
    return removeThingSubThingPrefix(
      getAllResources(thingState, thingName),
      thingType
    );
  }
};

export const hasResources = (thingState, thingName, thingType, subthing) =>
  resources(thingState, thingName, thingType, subthing).size > 0;

export const hasThingShadowResources = thingShadow => {
  let hasResources = false;
  if (thingShadow) {
    hasResources = thingShadow.get("resources", List()).size > 0;
    if (!hasResources && thingShadow) {
      hasResources = thingShadow
        .get("subthings", List())
        .reduce(
          subthing =>
            hasResources ? hasResources : subthing.get("resources").size > 0,
          hasResources
        );
    }
  }
  return hasResources;
};

export const getDomainPath = createSelector(getThingShadow, thingShadow =>
  thingShadow.get("domainTopic")
);

export const getDomain = (state, thingName, extraPath = []) =>
  state.thing.getIn(["thingShadow", thingName, "domain", ...extraPath]);

export const hasThingTypeNewlyCreatedThings = (state, thingType) =>
  state.getIn(["thingCreated", thingType], Map()).size > 0;

const getRSSI = (thingId, thingType, resources) => {
  const old = resources.getIn([
    `${thingId}/${thingType}/tcxn/network_type`,
    "latestValue",
    "value"
  ]);
  const current = resources.getIn([
    `${thingId}${thingType}/tcxn/network_type`,
    "latestValue",
    "value"
  ]);

  const networkType = current || old;

  let oldRSSI;
  if (networkType === "WLAN")
    oldRSSI = resources.getIn([
      `${thingId}/${thingType}/tcxn/wlan/rssi`,
      "latestValue",
      "value"
    ]);
  if (networkType === "3G")
    oldRSSI = resources.getIn([
      `${thingId}/${thingType}/tcxn/cellular/rssi`,
      "latestValue",
      "value"
    ]);

  const currentRSSI = resources.getIn([
    `${thingId}/${thingType}/tcxn/rssi`,
    "latestValue",
    "value"
  ]);

  return currentRSSI || oldRSSI;
};

// We will support the old tcxn format for a few releases (current release: 2.13)
// when it is time to remove the support remove the code
// associated with the old format.
export const getTCXNValuesByThingId = (state, { thingId }) => {
  const thingType = state.thing.getIn(
    ["thingShadow", thingId, "thingType"],
    null
  );
  const resources = state.thing.getIn(
    ["thingShadow", thingId, "resources"],
    null
  );

  if (!thingType || !resources || !resources.getIn) return {};
  const oldFormat = {
    thingId,
    ipAddress: resources.getIn([
      `${thingId}/${thingType}/tcxn/ip_addr`,
      "latestValue",
      "value"
    ]),
    imsi: resources.getIn([
      `${thingId}/${thingType}/tcxn/cellular/imsi`,
      "latestValue",
      "value"
    ]),
    imei: resources.getIn([
      `${thingId}/${thingType}/tcxn/cellular/imei`,
      "latestValue",
      "value"
    ]),
    iccid: resources.getIn([
      `${thingId}/${thingType}/tcxn/cellular/iccid`,
      "latestValue",
      "value"
    ]),
    firmwareVersion: resources.getIn([
      `${thingId}/${thingType}/tcxn/fw_ver`,
      "latestValue",
      "value"
    ]),
    rssi: getRSSI(thingId, thingType, resources),
    networkType: resources.getIn([
      `${thingId}/${thingType}/tcxn/network_type`,
      "latestValue",
      "value"
    ]),
    battery_level: resources.getIn([
      `${thingId}/${thingType}/tcxn/battery_level`,
      "latestValue",
      "value"
    ])
  };
  const currentFormat = {
    thingId,
    ipAddress: resources.getIn([
      `${thingId}/${thingType}/tcxn/ip_addr`,
      "latestValue",
      "value"
    ]),
    imsi: resources.getIn([
      `${thingId}/${thingType}/tcxn/imsi`,
      "latestValue",
      "value"
    ]),
    imei: resources.getIn([
      `${thingId}/${thingType}/tcxn/imei`,
      "latestValue",
      "value"
    ]),
    iccid: resources.getIn([
      `${thingId}/${thingType}/tcxn/iccid`,
      "latestValue",
      "value"
    ]),
    firmwareVersion: resources.getIn([
      `${thingId}/${thingType}/tcxn/fw_ver`,
      "latestValue",
      "value"
    ]),
    rssi: getRSSI(thingId, thingType, resources),
    networkType: resources.getIn([
      `${thingId}/${thingType}/tcxn/network_type`,
      "latestValue",
      "value"
    ]),
    connectionStatus: resources.getIn([
      `${thingId}/${thingType}/tcxn/connection_status`,
      "latestValue",
      "value"
    ]),
    battery_level: resources.getIn([
      `${thingId}/${thingType}/tcxn/battery_level`,
      "latestValue",
      "value"
    ])
  };

  return R.reject(R.isNil, merge(oldFormat, currentFormat)); // Cant use object assign here since it overrides values for keys which has undefined valueI
};

export const userCanDownloadBatch = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Things, OPERATIONS.CREATE)
);

export const userCanReadThings = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Things, OPERATIONS.READ)
);

export const userCanPublish = createSelector(
  authSelectors.permissionsSelector,
  R.anyPass([
    isAllowed(OBJECT_TYPES.ThingPubSub, OPERATIONS.UPDATE),
    R.propSatisfies(R.any(isLegacyWriteRole), "roles") // workaround for legacy roles not having the ThingPubSub privilege, shouldn't be needed after https://jira.skunk-works.no/browse/TCAB-5622
  ])
);
