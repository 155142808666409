/**
 *  Stop Propagation and Prevent Default wrapper
 * @param {*} fn is the function to be called
 * @param  {...any} args are the arguments for the function
 */
export const sppd = (fn, ...args) => ev => {
  ev.stopPropagation();
  ev.preventDefault();
  fn.call(ev, ...args);
};

/**
 * Stop Propagation wrapper
 * @param {*} fn is the function to be called
 * @param  {...any} args are the arguments for the function
 */
export const sp = (fn, ...args) => ev => {
  ev.stopPropagation();
  fn.call(ev, ...args);
};

/**
 * Prevent Default wrapper
 * @param {*} fn is the function to be called
 * @param  {...any} args are the arguments for the function
 */

export const pd = (fn, ...args) => ev => {
  ev.preventDefault();
  fn.call(ev, ...args);
};
