import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getPickerSubthings,
  addDefaultSubThing
} from "ducks/thing/thing_selectors";
import {
  compose,
  withHandlers,
  branch,
  renderComponent,
  pure
} from "react-recompose";

import SubThingPicker from "./sub_thing_picker";

const SubThingPickerContainer = ({
  query,
  params,
  subThings,
  handleChangeSubthing,
  disabled
}) => (
  <SubThingPicker
    query={query}
    params={params}
    subThings={subThings}
    handleChangeSubthing={handleChangeSubthing}
    disabled={disabled}
  />
);

SubThingPickerContainer.propTypes = {
  subThings: PropTypes.object,
  thingName: PropTypes.string,
  handleChangeSubthing: PropTypes.func,
  params: PropTypes.object,
  query: PropTypes.object,
  disabled: PropTypes.bool
};
SubThingPickerContainer.defaultProps = {};

function mapStateToProps(
  state,
  {
    thingName,
    router: {
      location: { query },
      params
    }
  }
) {
  const subThings = addDefaultSubThing(
    getPickerSubthings(state.thing, thingName),
    params.thingType
  );
  return {
    subThings,
    query,
    params
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withHandlers({
    handleChangeSubthing: ({
      router: {
        push,
        location: { pathname, query = {} },
        params: { subthing, subThingType = "untyped" }
      }
    }) => event => {
      let [key, type] = event.target.value.split(" - ");
      type = type ? type : "untyped";
      key = encodeURIComponent(key);
      type = encodeURIComponent(type);
      const keypath = `${encodeURIComponent(subThingType)}/${encodeURIComponent(
        subthing
      )}`;
      const newKeypath = `${type}/${key}`;
      let path = pathname.replace(keypath, newKeypath);
      push({
        pathname: path,
        query
      });
    }
  }),
  branch(
    ({ subThings }) => subThings === undefined || subThings.size <= 1,
    renderComponent(() => <span />)
  ),
  pure
)(SubThingPickerContainer);
