import React, { useState } from "react";
import PropTypes from "prop-types";
import { VictoryPie, VictoryLegend, VictoryContainer } from "victory";
import { useTheme } from "@material-ui/core/styles";

export const transformLabeledIntervalsToXYIntervals = labeledIntervals =>
  labeledIntervals.map(interval => ({ x: interval[0], y: interval[1] }));

export const getLegendData = labeledIntervals => {
  const total = labeledIntervals.reduce((acc, item) => acc + item[1], 0);
  return labeledIntervals.map(interval => ({
    name: interval[0] + ": " + Math.round((interval[1] / total) * 100) + "%"
  }));
};

const getRadius = (width, height, breakpoint) =>
  Math.min(width, height) / (breakpoint ? 3.1 : 2.6);
const BLEND_FACTOR = 0.4;
const INNER_RADIUS_FACTOR = 0.65;
const MOVE_SIDEWAYS_FACTOR = 0.2;
const DATA_LENGTH_BREAKPOINT = 6;

const PieChart = ({ width, height, data }) => {
  const theme = useTheme();
  const [hoveredSegment, setHoveredSegment] = useState(undefined);
  const radius = getRadius(width, height, DATA_LENGTH_BREAKPOINT < data.length);

  const getBlendFactor = index => {
    if (hoveredSegment === undefined) return 1;
    return index === hoveredSegment ? 1 : BLEND_FACTOR;
  };

  const eventHandlers = {
    onMouseOver: (a, b, index) => setHoveredSegment(Number(index)),
    onMouseOut: () => setHoveredSegment(undefined)
  };

  return (
    <>
      <VictoryPie
        data={transformLabeledIntervalsToXYIntervals(data)}
        containerComponent={<VictoryContainer responsive={false} />}
        width={width}
        height={height}
        padding={{
          right:
            -width *
            MOVE_SIDEWAYS_FACTOR *
            (DATA_LENGTH_BREAKPOINT < data.length ? 1.7 : 1), // move the chart out of the way in case the legend will be more than 1 column
          top: height - radius * 2 - theme.spacing(4) // moves it to bottom, and then uses material spacing to adjus the last part
        }}
        standalone={false}
        innerRadius={radius * INNER_RADIUS_FACTOR}
        colorScale="qualitative"
        radius={radius}
        style={{
          labels: { opacity: 0 },
          data: { opacity: ({ index }) => getBlendFactor(index) }
        }}
        events={[{ target: "data", eventHandlers }]}
      />
      <VictoryLegend
        standalone={false}
        itemsPerRow={DATA_LENGTH_BREAKPOINT}
        data={getLegendData(data)}
        orientation="vertical"
        colorScale="qualitative"
        x={theme.spacing()}
        style={{
          labels: {
            fontSize: ({ data }) => {
              // Make the text size responsive as well
              return data.length > DATA_LENGTH_BREAKPOINT &&
                Math.min(width, height) < 450
                ? 14 - Math.sqrt(data.length)
                : 14;
            },
            fontFamily: "telenor-regular",
            opacity: ({ index }) => getBlendFactor(index)
          },
          data: { opacity: ({ index }) => getBlendFactor(index) }
        }}
        events={[{ target: "labels", eventHandlers }]}
      />
    </>
  );
};

const PieWidget = ({ container, labeledIntervals }) => {
  return (
    <svg
      viewBox={`0 0 ${container.width} ${container.height}`}
      width="100%"
      height="100%"
    >
      <PieChart
        width={container.width}
        height={container.height}
        data={labeledIntervals}
      />
    </svg>
  );
};

PieChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.array
};

PieWidget.propTypes = {
  container: PropTypes.object,
  labeledIntervals: PropTypes.array
};

PieWidget.defaultProps = {
  labeledIntervals: []
};

export default PieWidget;
