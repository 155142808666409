import React from "react";
import PropTypes from "prop-types";
import HighLightOff from "@material-ui/icons/HighlightOff";
import { compose, withState } from "react-recompose";
import * as colors from "themes/colors";
import TextFieldWithoutBorderBottom from "components/text/text_field_without_underline";

const iconCss = { fill: colors.colorDustyGrayApprox, padding: "0 10px 0 0" };
const inputCss = { margin: "0" };
const inputStyle = { width: "120px" };
const seperatorCss = {
  marginRight: "10px",
  height: "45px",
  display: "inline-block",
  top: "10px",
  bottom: "10px",
  background: colors.colorDustyGrayApprox,
  width: "1px",
  position: "relative"
};

const FreeTextFilter = ({
  text,
  setText,
  onUpdateFreeText,
  onRemoveFreeTextSearch
}) => (
  <span>
    <TextFieldWithoutBorderBottom
      label={"Search"}
      value={text}
      inputStyle={inputCss}
      style={inputStyle}
      margin="dense"
      onChange={e => {
        setText(e.target.value);
        onUpdateFreeText(e, e.target.value);
      }}
    />
    {text && text.length > 0 && (
      <HighLightOff
        style={iconCss}
        onClick={e => {
          setText("");
          return onRemoveFreeTextSearch(e);
        }}
      />
    )}
    <span style={seperatorCss}>&nbsp;</span>
  </span>
);

FreeTextFilter.propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func,
  onUpdateFreeText: PropTypes.func.isRequired,
  onRemoveFreeTextSearch: PropTypes.func.isRequired
};

FreeTextFilter.defaultProps = {
  text: ""
};

export default compose(withState("text", "setText", ({ text = "" }) => text))(
  FreeTextFilter
);
