import { createAction } from "redux-actions";

export const MQTT_DATA_FAILURE = "MQTT_DATA_FAILURE";
export const MQTT_DATA_RECEIVED = "MQTT_DATA_RECEIVED";
export const MQTT_PUBLISH = "MQTT_PUBLISH";
export const MQTT_PUBLISH_FAILURE = "MQTT_PUBLISH_FAILURE";
export const MQTT_PUBLISH_SUCCESS = "MQTT_PUBLISH_SUCCESS";
export const MQTT_SUBSCRIBE = "MQTT_SUBSCRIBE";
export const MQTT_SUBSCRIBE_SUCCESS = "MQTT_SUBSCRIBE_SUCCESS";
export const MQTT_UNSUBSCRIBE = "MQTT_UNSUBSCRIBE";
export const MQTT_UNSUBSCRIBE_SUCCESS = "MQTT_UNSUBSCRIBE_SUCCESS";

export const TYPE_RESOURCE_SCHEMA_UPDATE = "RESOURCE_SCHEMA.UPDATE";
export const TYPE_THING_CREATE = "THING.CREATE";

export const mqttDataFailure = createAction(MQTT_DATA_FAILURE);
export const mqttDataReceived = createAction(MQTT_DATA_RECEIVED);
export const mqttPublish = createAction(MQTT_PUBLISH);
export const mqttPublishFailure = createAction(MQTT_PUBLISH_FAILURE);
export const mqttPublishSuccess = createAction(MQTT_PUBLISH_SUCCESS);
export const mqttSubscribe = createAction(MQTT_SUBSCRIBE);
export const mqttSubscribeSuccess = createAction(MQTT_SUBSCRIBE_SUCCESS);
export const mqttUnsubscribe = createAction(MQTT_UNSUBSCRIBE);
export const mqttUnsubscribeSuccess = createAction(MQTT_UNSUBSCRIBE_SUCCESS);
