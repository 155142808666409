import {
  append,
  F,
  T,
  always,
  any,
  eqBy,
  evolve,
  ifElse,
  map,
  pickAll,
  prop,
  propEq,
  reject,
  unionWith,
  whereEq
} from "ramda";
import { handleActions, createAction } from "redux-actions";

const groupToTarget = group => ({
  id: group.id,
  label: group.description,
  type: "group"
});

const initalCreateJobState = {
  availableJobTypes: ["CONTINUOUS", "SNAPSHOT"],
  description: "",
  domain: "",
  jobDocumentFile: undefined,
  jobType: "SNAPSHOT",
  targets: [],
  showValidation: false
};

export const initialStateJobs = {
  createJob: initalCreateJobState,
  jobDetails: {
    executionSummaries: [],
    job: {},
    selectedStatus: ""
  },
  request: {
    isExecutionsLoading: false,
    isLoading: false,
    isJobLoading: false,
    isJobDownloading: false
  },
  selectedGroups: []
};

export const DEVICE_MANAGEMENT_CANCEL_JOB_REQUEST =
  "DEVICE_MANAGEMENT_CANCEL_JOB_REQUEST";
export const DEVICE_MANAGEMENT_CANCEL_JOB_SUCCESS =
  "DEVICE_MANAGEMENT_CANCEL_JOB_SUCCESS";
export const DEVICE_MANAGEMENT_CANCEL_JOB_FAILURE =
  "DEVICE_MANAGEMENT_CANCEL_JOB_FAILURE";

export const DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_REQUEST =
  "DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_REQUEST";
export const DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_SUCCESS =
  "DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_SUCCESS";
export const DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_FAILURE =
  "DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_FAILURE";

export const DEVICE_MANAGEMENT_TOGGLE_SELECT_GROUP =
  "DEVICE_MANAGEMENT_TOGGLE_SELECT_GROUP";
export const DEVICE_MANAGEMENT_DESELECT_GROUP =
  "DEVICE_MANAGEMENT_DESELECT_GROUP";
export const DEVICE_MANAGEMENT_DESELECT_ALL_GROUPS =
  "DEVICE_MANAGEMENT_DESELECT_ALL_GROUPS";
export const DEVICE_MANAGEMENT_SELECT_ALL_GROUPS =
  "DEVICE_MANAGEMENT_SELECT_ALL_GROUPS";

export const listJobExecutions = createAction(
  DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_REQUEST
);
export const listJobExecutionsSuccess = createAction(
  DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_SUCCESS
);
export const listJobExecutionsFailure = createAction(
  DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_FAILURE
);

export const cancelJob = createAction(DEVICE_MANAGEMENT_CANCEL_JOB_REQUEST);
export const cancelJobSuccess = createAction(
  DEVICE_MANAGEMENT_CANCEL_JOB_SUCCESS
);
export const cancelJobFailure = createAction(
  DEVICE_MANAGEMENT_CANCEL_JOB_FAILURE
);

export const DEVICE_MANAGEMENT_SET_JOB_DOCUMENT_FILE =
  "DEVICE_MANAGEMENT_SET_JOB_DOCUMENT_FILE";

export const DEVICE_MANAGEMENT_CLEAR_CREATE_JOB =
  "DEVICE_MANAGEMENT_CLEAR_CREATE_JOB";
export const DEVICE_MANAGEMENT_SET_JOB_TYPE = "DEVICE_MANAGEMENT_SET_JOB_TYPE";
export const DEVICE_MANAGEMENT_SET_JOB_DESCRIPTION =
  "DEVICE_MANAGEMENT_SET_JOB_DESCRIPTION";
export const DEVICE_MANAGEMENT_ADD_GROUPS_TO_JOB =
  "DEVICE_MANAGEMENT_ADD_GROUPS_TO_JOB";
export const DEVICE_MANAGEMENT_REMOVE_JOB_TARGET =
  "DEVICE_MANAGEMENT_REMOVE_JOB_TARGET";
export const DEVICE_MANAGEMENT_SET_JOB_DOMAIN =
  "DEVICE_MANAGEMENT_SET_JOB_DOMAIN";

export const DEVICE_MANAGEMENT_CREATE_JOB_REQUEST =
  "DEVICE_MANAGEMENT_CREATE_JOB_REQUEST";
export const DEVICE_MANAGEMENT_CREATE_JOB_FAILURE =
  "DEVICE_MANAGEMENT_CREATE_JOB_FAILURE";
export const DEVICE_MANAGEMENT_CREATE_JOB_SUCCESS =
  "DEVICE_MANAGEMENT_CREATE_JOB_SUCCESS";
export const DEVICE_MANAGEMENT_CREATE_JOB_VALIDATION_FAILURE =
  "DEVICE_MANAGEMENT_CREATE_JOB_VALIDATION_FAILURE";

export const DEVICE_MANAGEMENT_JOB_DOCUMENT_REQUEST =
  "DEVICE_MANAGEMENT_JOB_DOCUMENT_REQUEST";
export const DEVICE_MANAGEMENT_JOB_DOCUMENT_FAILURE =
  "DEVICE_MANAGEMENT_JOB_DOCUMENT_FAILURE";
export const DEVICE_MANAGEMENT_JOB_DOCUMENT_SUCCESS =
  "DEVICE_MANAGEMENT_JOB_DOCUMENT_SUCCESS";

// ---- end jobs

export const DEVICE_MANAGEMENT_DESCRIBE_JOB_REQUEST =
  "DEVICE_MANAGEMENT_DESCRIBE_JOB_REQUEST";
export const DEVICE_MANAGEMENT_DESCRIBE_JOB_FAILURE =
  "DEVICE_MANAGEMENT_DESCRIBE_JOB_FAILURE";
export const DEVICE_MANAGEMENT_DESCRIBE_JOB_SUCCESS =
  "DEVICE_MANAGEMENT_DESCRIBE_JOB_SUCCESS";

export const DEVICE_MANAGEMENT_SET_JOB_EXECUTION_STATUS =
  "DEVICE_MANAGEMENT_SET_JOB_EXECUTION_STATUS";

export const selectAllGroups = createAction(
  DEVICE_MANAGEMENT_SELECT_ALL_GROUPS
);
export const deselectAllGroups = createAction(
  DEVICE_MANAGEMENT_DESELECT_ALL_GROUPS
);
export const deselectGroup = createAction(DEVICE_MANAGEMENT_DESELECT_GROUP);
export const toggleSelectGroup = createAction(
  DEVICE_MANAGEMENT_TOGGLE_SELECT_GROUP
);
export const setExecutionStatus = createAction(
  DEVICE_MANAGEMENT_SET_JOB_EXECUTION_STATUS
);

export const clearCreateJob = createAction(DEVICE_MANAGEMENT_CLEAR_CREATE_JOB);
export const setJobDocumentFile = createAction(
  DEVICE_MANAGEMENT_SET_JOB_DOCUMENT_FILE
);
export const setJobDescription = createAction(
  DEVICE_MANAGEMENT_SET_JOB_DESCRIPTION
);
export const setJobDomain = createAction(DEVICE_MANAGEMENT_SET_JOB_DOMAIN);
export const setJobType = createAction(DEVICE_MANAGEMENT_SET_JOB_TYPE);
export const addGroupsToJob = createAction(DEVICE_MANAGEMENT_ADD_GROUPS_TO_JOB);
export const removeJobTarget = createAction(
  DEVICE_MANAGEMENT_REMOVE_JOB_TARGET
);

export const createJob = createAction(DEVICE_MANAGEMENT_CREATE_JOB_REQUEST);
export const createJobSuccess = createAction(
  DEVICE_MANAGEMENT_CREATE_JOB_SUCCESS
);
export const createJobFailure = createAction(
  DEVICE_MANAGEMENT_CREATE_JOB_FAILURE
);

export const describeJob = createAction(DEVICE_MANAGEMENT_DESCRIBE_JOB_REQUEST);
export const describeJobSuccess = createAction(
  DEVICE_MANAGEMENT_DESCRIBE_JOB_SUCCESS
);
export const describeJobFailure = createAction(
  DEVICE_MANAGEMENT_DESCRIBE_JOB_FAILURE
);

export const getJobDocument = createAction(
  DEVICE_MANAGEMENT_JOB_DOCUMENT_REQUEST
);
export const getJobDocumentSuccess = createAction(
  DEVICE_MANAGEMENT_JOB_DOCUMENT_SUCCESS
);
export const getJobDocumentFailure = createAction(
  DEVICE_MANAGEMENT_JOB_DOCUMENT_FAILURE
);

export default handleActions(
  {
    [DEVICE_MANAGEMENT_SET_JOB_DOCUMENT_FILE]: (state, { payload }) =>
      evolve({
        createJob: { jobDocumentFile: always(payload) }
      })(state),

    [DEVICE_MANAGEMENT_SET_JOB_TYPE]: (state, { payload }) =>
      evolve({
        createJob: { jobType: always(payload) }
      })(state),

    [DEVICE_MANAGEMENT_SET_JOB_DESCRIPTION]: (state, { payload }) =>
      evolve({
        createJob: { description: always(payload) }
      })(state),

    [DEVICE_MANAGEMENT_ADD_GROUPS_TO_JOB]: (state, { payload }) =>
      evolve({
        createJob: {
          targets: unionWith(
            eqBy(pickAll(["id", "type"])),
            map(groupToTarget, payload)
          )
        }
      })(state),

    [DEVICE_MANAGEMENT_REMOVE_JOB_TARGET]: (state, { payload }) =>
      evolve({
        createJob: {
          targets: reject(whereEq(pickAll(["id", "type"], payload)))
        }
      })(state),

    [DEVICE_MANAGEMENT_SET_JOB_DOMAIN]: (state, { payload }) =>
      evolve({
        createJob: {
          domain: always(payload)
        }
      })(state),

    [DEVICE_MANAGEMENT_CREATE_JOB_REQUEST]: state =>
      evolve({
        request: { isLoading: T }
      })(state),

    [DEVICE_MANAGEMENT_CREATE_JOB_SUCCESS]: state =>
      evolve({
        createJob: always(initalCreateJobState),
        request: { isLoading: F }
      })(state),

    [DEVICE_MANAGEMENT_CREATE_JOB_FAILURE]: state =>
      evolve({
        createJob: { showValidation: T },
        request: { isLoading: F }
      })(state),

    [DEVICE_MANAGEMENT_CLEAR_CREATE_JOB]: state =>
      evolve(
        {
          createJob: always(initalCreateJobState)
        },
        state
      ),

    [DEVICE_MANAGEMENT_DESCRIBE_JOB_REQUEST]: state =>
      evolve(
        {
          request: {
            isJobLoading: T
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_DESCRIBE_JOB_FAILURE]: state =>
      evolve(
        {
          request: {
            isJobLoading: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_DESCRIBE_JOB_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          jobDetails: {
            job: always(payload)
          },
          request: {
            isJobLoading: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_SET_JOB_EXECUTION_STATUS]: (state, { payload }) =>
      evolve(
        {
          jobDetails: {
            selectedStatus: always(payload)
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_REQUEST]: state =>
      evolve(
        {
          request: {
            isExecutionsLoading: T
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          jobDetails: {
            executionSummaries: always(payload)
          },
          request: {
            isExecutionsLoading: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_FAILURE]: state =>
      evolve(
        {
          request: {
            isExecutionsLoading: F
          }
        },
        state
      ),

    [DEVICE_MANAGEMENT_CANCEL_JOB_REQUEST]: state =>
      evolve({ request: { isJobLoading: T } }, state),

    [DEVICE_MANAGEMENT_CANCEL_JOB_SUCCESS]: state =>
      evolve({ request: { isJobLoading: F } }, state),

    [DEVICE_MANAGEMENT_CANCEL_JOB_FAILURE]: state =>
      evolve({ request: { isJobLoading: F } }, state),
    [DEVICE_MANAGEMENT_TOGGLE_SELECT_GROUP]: (state, { payload }) =>
      evolve(
        {
          selectedGroups: ifElse(
            any(propEq("id", payload.id)),
            reject(propEq("id", payload.id)),
            append(payload)
          )
        },
        state
      ),

    [DEVICE_MANAGEMENT_DESELECT_GROUP]: (state, { payload }) =>
      evolve(
        {
          selectedGroups: reject(propEq("id", payload.id))
        },
        state
      ),

    [DEVICE_MANAGEMENT_DESELECT_ALL_GROUPS]: state =>
      evolve(
        {
          selectedGroups: always([])
        },
        state
      ),

    [DEVICE_MANAGEMENT_SELECT_ALL_GROUPS]: (state, { payload }) =>
      evolve(
        {
          selectedGroups: unionWith(eqBy(prop("id")), payload)
        },
        state
      ),
    [DEVICE_MANAGEMENT_JOB_DOCUMENT_REQUEST]: state =>
      evolve({
        request: { isJobDownloading: T }
      })(state),
    [DEVICE_MANAGEMENT_JOB_DOCUMENT_SUCCESS]: state =>
      evolve({
        request: { isJobDownloading: F }
      })(state),
    [DEVICE_MANAGEMENT_JOB_DOCUMENT_FAILURE]: state =>
      evolve({
        request: { isJobDownloading: F }
      })(state)
  },
  initialStateJobs
);
