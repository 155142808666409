import getWidgetBase from "../get_widget_base";
import DynamicWidget from "./dynamic_widget_container";

export const getWidget = props => {
  return getWidgetBase({
    ...props,
    noResize: true,
    scrollable: true,
    isRealtime: true,
    widgetComp: DynamicWidget
  });
};
