import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const selectCss = { width: "150px", fontSize: "14px" };
const containerCss = {
  display: "inline-block",
  verticalAlign: "middle",
  position: "relative",
  paddingRight: "10px"
};

export const statusTexts = {
  "-1": { key: "all", text: "All" },
  0: { key: "offline", text: "Offline" },
  1: { key: "partial", text: "Offline (connectivity available)" },
  2: { key: "online", text: "Online" },
  3: { key: "notConnected", text: "Not yet connected" },
  4: { key: "unknownStatus", text: "Unknown connection status" }
};

const OnlineOffline = ({ thingStatus, onChangeThingStatus }) => {
  return (
    <div style={containerCss}>
      <FormControl>
        <FormHelperText>Thing Status</FormHelperText>
        <Select
          style={selectCss}
          value={thingStatus}
          onChange={onChangeThingStatus}
        >
          {Object.keys(statusTexts).map(key => (
            <MenuItem value={key} key={statusTexts[key].key}>
              {statusTexts[key].text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

OnlineOffline.propTypes = {
  thingStatus: PropTypes.string,
  onChangeThingStatus: PropTypes.func
};

OnlineOffline.defaultProps = {
  thingStatus: "-1"
};

export default OnlineOffline;
