import { invoke, invoke$ } from "./invoke";

export const fetchUsers$ = ({ sort = "userName", filter = {}, page = 1 }) => {
  const action = {
    action: "LIST",
    sort,
    filter,
    page,
    size: 10,
    attributes: {
      email: null,
      phone: null,
      firstName: null,
      lastName: null,
      company: null,
      address: null,
      zip: null,
      city: null,
      country: null,
      userName: null,
      domainName: null,
      roleName: null,
      enabled: null,
      createdAt: null
    }
  };
  return invoke$("UserLambda", action);
};

export const getSingleUser$ = userName => {
  const action = {
    action: "GET",
    attributes: {
      userName,
      email: null,
      phone: null,
      password: null,
      passwordConfirm: null,
      firstName: null,
      lastName: null,
      company: null,
      address: null,
      zip: null,
      city: null,
      country: null,
      roleName: null,
      domainName: null,
      notes1: null,
      notes2: null,
      notes3: null,
      termsAgreed: null,
      dateTermsAgreed: null,
      termsVersion: null,
      enabled: null
    }
  };
  return invoke$("UserLambda", action);
};

export const getUserData$ = () =>
  invoke$("UserLambda", {
    action: "GET_USERDATA",
    attributes: {}
  });

export const updateUserData$ = attributes =>
  invoke$("UserLambda", {
    action: "UPDATE_USERDATA",
    attributes
  });

export const resetPassword = userName => {
  const action = {
    action: "RESET_PASSWORD",
    attributes: { userName }
  };
  return invoke("UserLambda", action);
};

export const updateProfile$ = profile =>
  invoke$("UserLambda", {
    action: "UPDATE_PROFILE",
    attributes: profile
  });
