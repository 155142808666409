import { combineReducers } from "redux-immutable";

import CrudDuck from "./crud_duck";
import { Viewmode, Grid, Layout } from "./records";
import widgetCrudDuck from "./widget_reducer";

import dashboards from "./dashboards";
import result from "./result";
import request from "./request";
import dashboardViewState from "./dashboard_view_state";
/**
 {dashboard : {
   request: {
     isLoading: false,
     error: null
   },
   result: [],
   entities: {
     dashboards: {
     },
     viewModes: {
     },
     grids: {
     },
     widgets: {
     },
     props: {
     },
     columnConfig: {
     }
   }
 }
 }
 **/
export const viewModeCrudDuck = new CrudDuck("viewModes", Viewmode, {
  aggregateSuccess: "DASHBOARDS_SUCCESS",
  relationships: ["grids"]
});
const viewModes = viewModeCrudDuck.reducer;

export const gridCrudDuck = new CrudDuck("grids", Grid, {
  aggregateSuccess: "DASHBOARDS_SUCCESS",
  relationships: ["widgets", "layout"] // plurality defines if its a collection or just a single ...
});
const grids = gridCrudDuck.reducer;

/*export const columnConfigCrudDuck = new CrudDuck("columnConfig", ColumnConfig, {
  aggregateSuccess: "DASHBOARDS_SUCCESS"
});
const columnConfig = columnConfigCrudDuck.reducer;

export const propsCrudDuck = new CrudDuck("props", Props, {
  aggregateSuccess: "DASHBOARDS_SUCCESS",
  relationships: ["columnConfig"]
});
const props = propsCrudDuck.reducer;*/

const widgets = widgetCrudDuck.reducer;

export const layoutCrudDuck = new CrudDuck("layouts", Layout, {
  aggregateSuccess: "DASHBOARDS_SUCCESS"
});
const layouts = layoutCrudDuck.reducer;

export { widgetCrudDuck };

const entities = combineReducers({
  dashboards,
  viewModes,
  grids,
  widgets,
  layouts
});

export default combineReducers({
  dashboardViewState,
  request,
  result,
  entities
});
