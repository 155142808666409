import resourceTypes from "components/widget/widget_types/widget_resource_types";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import Joi from "joi-browser";
import * as R from "ramda";
import * as im from "../../../../utils/immutable_utils";
import { filterResources } from "../../edit/widget_resource_utils";

export default ({ type, onResourceChange, thingType }) => {
  return {
    fields: [
      {
        id: "histogramResource",
        type: "autocomplete",
        suggesterType: DATA_TYPES.RESOURCES,
        label: "Resource",
        hintText: "Thing resource",
        onChange: ({ id, value }) => {
          const valueObject = { id: value };
          onResourceChange({ id, value: valueObject });
        },
        suggesterOptionsDecorator: results => {
          return filterResources(type, resourceTypes.NUMBER, results);
        },
        thingType: R.pipe(
          im.toJS,
          R.when(R.is(Object), R.prop("id"))
        )(thingType)
      },
      {
        id: "interval",
        label: "Interval",
        name: "interval",
        type: "number"
      }
    ],
    initialModel: {
      histogramResource: undefined,
      interval: undefined
    },
    schema: {
      histogramResource: Joi.string().required(),
      interval: Joi.number()
        .required()
        .min(1)
        .max(1000)
    }
  };
};
