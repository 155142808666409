import { getWidgetKeypath } from "utils/widget_utils";
import { createSelector } from "reselect";
import { List } from "immutable";
import {
  getEditingThingTypeViewModeObj,
  getEditingThingTypeResources
} from "./thing_types_selectors";
import * as thingTypeUtils from "utils/thing_type_utils";

export const getWidgets = createSelector(
  (state, { viewMode, subThingType, subthing }) => ({
    editingThingType: state.thingTypes.getIn(["editingThingType"]),
    viewMode,
    subThingType,
    subthing
  }),
  ({ editingThingType, viewMode, subThingType, subthing }) => {
    const widgetKeypath = getWidgetKeypath(
      viewMode,
      subThingType,
      subthing,
      editingThingType,
      ["viewModes"]
    );

    return editingThingType.getIn(widgetKeypath, List());
  }
);

export const getEditingThingTypeWidgetResourceDiff = createSelector(
  getEditingThingTypeViewModeObj,
  getEditingThingTypeResources,
  (viewModeObj, resources) =>
    thingTypeUtils.widgetResourceDiff(viewModeObj, resources)
);
