import { createAction, handleActions } from "redux-actions";
import { always, evolve, F, T, path } from "ramda";

export const SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_REQUEST =
  "SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_REQUEST";

export const SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_SUCCESS =
  "SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_SUCCESS";

export const SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_FAILURE =
  "SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_FAILURE";

export const SYSTEM_MANAGEMENT_UPLOAD_CONSENT_REQUEST =
  "SYSTEM_MANAGEMENT_UPLOAD_CONSENT_REQUEST";

export const SYSTEM_MANAGEMENT_UPLOAD_CONSENT_SUCCESS =
  "SYSTEM_MANAGEMENT_UPLOAD_CONSENT_SUCCESS";

export const SYSTEM_MANAGEMENT_UPLOAD_CONSENT_FAILURE =
  "SYSTEM_MANAGEMENT_UPLOAD_CONSENT_FAILURE";

export const SYSTEM_MANAGEMENT_SET_UPLOAD_VERSION =
  "SYSTEM_MANAGEMENT_SET_UPLOAD_VERSION";

export const SYSTEM_MANAGEMENT_SET_UPLOAD_FILE =
  "SYSTEM_MANAGEMENT_SET_UPLOAD_FILE";

export const getConsentDocumentRequest = createAction(
  SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_REQUEST
);

export const getConsentDocumentSuccess = createAction(
  SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_SUCCESS
);

export const getConsentDocumentFailure = createAction(
  SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_FAILURE
);

export const consentUploadRequest = createAction(
  SYSTEM_MANAGEMENT_UPLOAD_CONSENT_REQUEST
);

export const consentUploadSuccess = createAction(
  SYSTEM_MANAGEMENT_UPLOAD_CONSENT_SUCCESS
);

export const consentUploadFailure = createAction(
  SYSTEM_MANAGEMENT_UPLOAD_CONSENT_FAILURE
);

export const setUploadVersion = createAction(
  SYSTEM_MANAGEMENT_SET_UPLOAD_VERSION
);

export const setUploadFile = createAction(SYSTEM_MANAGEMENT_SET_UPLOAD_FILE);

export const initialState = {
  uploadFile: {},
  uploadUrl: undefined,
  uploadVersion: undefined,
  documentUrl: undefined,
  documentVersion: undefined,
  retentionTimes: {},
  request: {
    isLoading: false,
    isLoadingRetentionTimes: false,
    isUploadLoading: false,
    error: undefined,
    retentionTimesError: undefined
  }
};

export default handleActions(
  {
    [SYSTEM_MANAGEMENT_SET_UPLOAD_FILE]: (state, { payload }) =>
      evolve(
        {
          uploadFile: always(payload),
          uploadUrl: always(undefined)
        },
        state
      ),
    [SYSTEM_MANAGEMENT_SET_UPLOAD_VERSION]: (state, { payload }) =>
      evolve(
        {
          uploadVersion: always(payload),
          uploadUrl: always(undefined)
        },
        state
      ),
    [SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_REQUEST]: evolve({
      request: { isLoading: T, error: always(undefined) }
    }),
    [SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_SUCCESS]: (
      state,
      { payload = {} }
    ) =>
      evolve(
        {
          request: { isLoading: F },
          documentUrl: always(payload.url),
          documentVersion: always(payload.version),
          uploadFile: always({}),
          uploadUrl: always(undefined),
          uploadVersion: always(undefined)
        },
        state
      ),
    [SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_FAILURE]: (state, { payload }) =>
      evolve(
        {
          request: {
            isLoading: F,
            error: always(path(["errorMessage", "message"], payload))
          },
          documentUrl: always(undefined),
          documentVersion: always(undefined)
        },
        state
      ),
    [SYSTEM_MANAGEMENT_UPLOAD_CONSENT_REQUEST]: evolve({
      request: { isUploadLoading: T, error: always(undefined) }
    }),
    [SYSTEM_MANAGEMENT_UPLOAD_CONSENT_SUCCESS]: (state, { payload }) =>
      evolve(
        {
          uploadFile: always({}),
          uploadUrl: always(payload.url),
          uploadVersion: always(payload.version),
          request: { isUploadLoading: F }
        },
        state
      ),
    [SYSTEM_MANAGEMENT_UPLOAD_CONSENT_FAILURE]: (state, { payload }) =>
      evolve(
        {
          uploadFile: always({}),
          uploadUrl: always(undefined),
          uploadVersion: always(undefined),
          request: {
            isUploadLoading: F,
            error: always(path(["errorMessage", "message"], payload))
          }
        },
        state
      )
  },
  initialState
);
