import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import withNativeColor from "./with_native_color";

const DesiredIcon = props => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4V0L16 8L8 16V12H0V4H8Z"
    />
  </SvgIcon>
);

export default withNativeColor(DesiredIcon);
