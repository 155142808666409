import WidgetEditResource from "components/widget/edit/widget_edit_resource";
import WidgetEditSetable from "components/widget/edit/widget_edit_setable";
import WidgetEditDecimals from "components/widget/edit/widget_edit_decimals";
import { ComposeForm } from "components/widget/edit/compose_form";
export const WidgetEditValue = WidgetEditResource;
export default ComposeForm(
  WidgetEditValue,
  WidgetEditSetable,
  WidgetEditDecimals
);
