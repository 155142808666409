import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withPaletteColor } from "utils/theme_utils";

const SubObject = props => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(7,3)">
      <path d="M17-3v24H-7V-3z" />
      <g>
        <circle cx="5" cy="2.778" r="2.278" stroke={props.color} />
        <circle cx="5" cy="12.778" r="5" fill={props.color} />
        <path stroke={props.color} strokeLinecap="square" d="M5 5v5.556" />
      </g>
    </g>
  </SvgIcon>
);

SubObject.propTypes = {
  color: PropTypes.string.isRequired
};

export default withPaletteColor("main")(SubObject);
