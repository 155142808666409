import { createSelector } from "reselect";
import {
  __,
  evolve,
  map,
  omit,
  path,
  pathOr,
  prop,
  propOr,
  props,
  pipe
} from "ramda";

import { getResources } from "ducks/schema/schema_selectors";
import { Map, fromJS, List } from "immutable";
import Expression from "routes/main/settings/modal/rule_edit/steps/expression";
import { authSelectors } from "ducks/auth";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";

const emptyObject = {};

const _getRulesState = propOr(emptyObject, "rules");

const _getRulesEntities = createSelector(
  _getRulesState,
  pathOr(emptyObject, ["entities", "rules"])
);

const _getActionEntities = createSelector(
  _getRulesState,
  pathOr(emptyObject, ["entities", "actions"])
);

const _getActionEntitiesSansId = createSelector(
  _getActionEntities,
  map(omit(["id"]))
);

const _getExpressionEntities = createSelector(
  _getRulesState,
  pathOr(emptyObject, ["entities", "expressions"])
);

const _getExpressionEntitiesSansId = createSelector(
  _getExpressionEntities,
  map(omit(["id"]))
);

const _getFilterEntities = createSelector(
  _getRulesState,
  pathOr(emptyObject, ["entities", "filters"])
);

const _getFilterEntitiesSansId = createSelector(
  _getFilterEntities,
  map(omit(["id"]))
);

export const isLoading = createSelector(
  _getRulesState,
  path(["request", "isLoading"])
);

export const getError = createSelector(
  _getRulesState,
  path(["request", "error"])
);

const thingTypesSelector = state => state.thingTypes;
const schemaSelector = state => state.schema;

export const getRuleEditData = createSelector(
  thingTypesSelector,
  schemaSelector,
  (thingTypes, schema) => {
    return Map({
      thingTypes: thingTypes.get("types", Map()).map(t =>
        Map({
          label: t.get("label", ""),
          thingsCount: t.get("thingCount", 0)
        })
      ),
      resources: thingTypes.get("types", Map({})).reduce((acc, curr) => {
        const thingTypeId = curr.get("id");
        const resources = getResources(schema, thingTypeId, {
          includeVirtual: true,
          includeTcxn: false,
          includeSubthings: true
        })
          .reduce((a, r) => {
            // TODO: Make it work for tcxn resources
            const id = r
              .get("id")
              .split("/")
              .slice(1)
              .filter(e => e !== "virtual" && e !== "subthing")
              .join(".");

            const label = r.getIn(["metadata", "label"]) || id;
            const unit = r.getIn(["metadata", "unit"]);
            return a.push({ id, label, unit });
          }, List())
          .push({ id: Expression.observationTimestamp.id });
        return acc.set(thingTypeId, resources);
      }, Map())
    });
  }
);

export const getRule = createSelector(
  (state, { ruleId }) => ruleId,
  _getRulesEntities,
  _getActionEntitiesSansId,
  _getExpressionEntitiesSansId,
  _getFilterEntitiesSansId,
  (ruleId, rules, actions, expressions, filters) =>
    pipe(
      propOr(emptyObject, ruleId),
      evolve({
        actions: props(__, actions),
        expression: props(__, expressions),
        filter: prop(__, filters)
      })
    )(rules)
);

export const getRuleAsImmutable = createSelector(getRule, r => fromJS(r));

export const userCanDeleteRules = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Rules, OPERATIONS.DELETE)
);

export const userCanEditRules = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Rules, OPERATIONS.UPDATE)
);

export const userCanCreateRules = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Rules, OPERATIONS.CREATE)
);

export const userCanReadRules = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Rules, OPERATIONS.READ)
);
