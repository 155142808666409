/* eslint-disable react/no-children-prop */
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { compose, pure } from "react-recompose";

const Logo = ({ href, ...rest }) => {
  const theme = useTheme();
  const { src, alt } = theme.logo || emptyObject;
  const image = <img src={src} alt={alt} {...rest} />;
  return href ? <Link to={href} children={image} /> : image;
};

Logo.propTypes = {
  href: PropTypes.string
};

const emptyObject = {};
export default compose(pure)(Logo);
