import { createSelector } from "reselect";
import { path, pipe, values, propOr } from "ramda";
import { sortJobs } from "./jobs_selectors";

const _getInProgressRequest = path([
  "deviceManagement",
  "jobsOngoing",
  "request"
]);
const _getInProgressEntities = path([
  "deviceManagement",
  "jobsOngoing",
  "entities"
]);

export const getInProgressJobs = createSelector(
  _getInProgressEntities,
  pipe(values, sortJobs)
);
export const isInProgressJobsLoading = createSelector(
  _getInProgressRequest,
  propOr(false, "isLoading")
);
