import { gql } from "@apollo/client";
import { parse } from "graphql";

const viewModeReadFields = `
  id
  domain
  label
  description
  isDefault
  isPersonal
  isReadOnly
  grids {
    gridId
    type
    layout
    widgets {
      widgetId
      label
      type
      props
    }
  }
`;

const userListFields = `
  createdAt(momentFunction: { name: format, arguments: "LLL", timezone: $timezone })
  domainName
  email
  enabled
  id: userName
  roleName
  userName
`;

const userMeFields = `
  id: userName
  userName
  firstName
  lastName
  email
  phone
  company
  address
  zip
  city
  country
`;

const exportDateMomentFunction = `
  momentFunction: {
    name: format
    arguments: "DD MMM YYYY HH:mm"
    timezone: $timezone
  }`;

export const LIST_THING_BATCHES = gql`
  query($filter: InputThingBatchFilter) {
    allThingBatches(searchOptions: { filter: $filter }) {
      bucketName
      files {
        url
        id: batchId
        currentSize
        requestedSize
        concurrency
        createdAt
        finishedAt
        attributes {
          domain
          createdBy
          thingType
        }
      }
    }
  }
`;

export const ALL_THING_TYPES = gql`
  query {
    allThingTypes {
      thingTypes {
        id
        label
        domain
        description
        readOnly
      }
    }
  }
`;

export const GET_THING_TYPE = gql`
  query($thingTypeId: String!) {
    thingType(id: $thingTypeId) {
      id
      label
      domain
      description
      readOnly
      data
    }
  }
`;
export const GET_THING_TYPE_WITH_RESOURCES = gql`
  query($thingTypeId: String!) {
    thingType(id: $thingTypeId) {
      id
      label
      domain
      description
      readOnly
      thingCount
      resourcesConnection {
        resources {
          id
          name
          metadata {
            unit
          }
        }
      }
    }
  }
`;

export const THING_TYPE_THING_COUNT = gql`
  query($thingTypeId: String!) {
    thingType(id: $thingTypeId) {
      id
      thingsConnection {
        pageInfo {
          totalCount
        }
      }
    }
  }
`;

export const THING_TYPE_VIEWMODES_QUERY = gql`
  query($thingType: String!) {
    allViewModes(
      searchOptions: { filter: { type: "thingType", thingType: $thingType } }
    ) {
      viewModes {
        id
        label
        isDefault
        isPersonal
        domain
      }
    }
  }
`;

export const DEFAULT_ALLTHINGS_VIEWMODE_QUERY = parse(`
  query {
    defaultViewMode(searchOptions: { filter: { type: "allThings" } }) {
      ${viewModeReadFields}
    }
  }
`);

export const DEFAULT_THINGTYPE_VIEWMODE_QUERY = parse(`
  query($thingType: String!, $preferredViewModeId: ID) {
    defaultViewMode(
      searchOptions: { filter: { type: "thingType", thingType: $thingType } }
      preferredId: $preferredViewModeId
    ) {
      ${viewModeReadFields}
    }
  }
`);

export const ALL_THINGS = gql`
  query allThings($searchOptions: ListThingsSearchOptions, $timezone: String) {
    allThings(searchOptions: $searchOptions) {
      pageInfo {
        page
        pages
        size
        itemCount
        totalCount
      }
      things {
        thingName
        label
        domain
        domainConnection {
          name
        }
        thingTypeConnection {
          label
        }
        thingType
        lastHeardFrom(
          momentFunction: {
            name: fromNow
            arguments: "true"
            timezone: $timezone
          }
        )
        createdAt(
          momentFunction: {
            name: fromNow
            arguments: "true"
            timezone: $timezone
          }
        )
        state
        description
        hasNetworkedThings
        parentThingName
      }
    }
  }
`;

export const THING_TYPE_WITH_THINGS = gql`
  query($thingType: String!, $searchOptions: ListThingsSearchOptions) {
    thingType(id: $thingType) {
      id
      label
      domain
      description
      readOnly
      thingCount
      thingsConnection(searchOptions: $searchOptions) {
        things {
          label
          thingName
        }
        pageInfo {
          page
          itemCount
          size
          totalCount
        }
      }
    }
  }
`;

export const REMOVE_THING = gql`
  mutation removeThing($thingName: String!, $subThingName: String) {
    removeThing(thingName: $thingName, subThingName: $subThingName) {
      thingName
    }
  }
`;

export const CREATE_THING = gql`
  mutation createThing($input: InputCreateThing!, $timezone: String) {
    createThing(input: $input) {
      createdAt(
        momentFunction: {
          name: fromNow
          arguments: "true"
          timezone: $timezone
        }
      )
      createdBy
      description
      domain
      externalId
      label
      parentThingName
      simulated
      thingName
      thingType
    }
  }
`;

export const CREATE_THINGS_BATCH = gql`
  mutation createThingBatch($input: InputCreateThingBatch!) {
    createThingBatch(input: $input) {
      batchId
    }
  }
`;

export const GET_THING = gql`
  query thing($thingName: String!) {
    thing(thingName: $thingName) {
      thingName
      thingType
      domain
      label
      description
      createdAt
      domainTopic
      externalId
      protocol
      imsi
      imei
      simulated
      hasNetworkedThings
      parentThingName
    }
  }
`;

export const THING_DOMAIN_TOPIC = gql`
  query thing($thingName: String!) {
    thing(thingName: $thingName) {
      domainTopic
    }
  }
`;

export const GET_THING_SHADOW = gql`
  query thing($thingName: String!) {
    thing(thingName: $thingName) {
      shadow
      domainTopic
      domain
      domainConnection {
        name
        description
        data
      }
      label
      thingName
      createdAt
      thingType
      parentThingName
      hasNetworkedThings
      description
      simulated
      externalId
      protocol
      imsi
      imei
      thingTypeConnection {
        resourcesConnection {
          resources {
            id
            name
            type
            subthingType
            metadata {
              label
              unit
              isSettable
              isVirtual
              custom {
                label
                value
              }
            }
          }
        }
      }
      networkedThingsConnection(paging: { size: 50 }) {
        pageInfo {
          page
          pages
          size
          itemCount
          totalCount
        }
        things {
          label
          thingName
          thingType
          hasNetworkedThings
        }
      }
    }
  }
`;

export const SIMULATOR_THING_QUERY = gql`
  query thing($thingName: String!) {
    thing(thingName: $thingName) {
      label
      thingType
      thingName
      domainTopic
      simulated
      shadow
      thingTypeConnection {
        label
        resourcesConnection {
          resources {
            id
            name
            type
            subthingType
            metadata {
              label
              unit
              isSettable
              isVirtual
              custom {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_THING = gql`
  mutation updateThing($thingName: String!, $input: InputUpdateThing!) {
    updateThing(thingName: $thingName, input: $input) {
      description
      domain
      externalId
      label
    }
  }
`;

export const UPDATE_VIEW_MODE_MUTATION = parse(`
  mutation(
    $description: String
    $domain: String
    $grids: [InputAddGrid!]
    $isDefault: Boolean
    $isPersonal: Boolean
    $label: String
    $viewModeId: ID!
  ) {
    updateViewMode(
      id: $viewModeId
      input: {
        description: $description
        domain: $domain
        grids: $grids
        isDefault: $isDefault
        isPersonal: $isPersonal
        label: $label
      }
    ) {
      viewMode {
        ${viewModeReadFields}
      }
    }
  }
`);

export const CREATE_ALL_THINGS_VIEWMODE_MUTATION = parse(`
  mutation($grids: [InputAddGrid!]!) {
    createViewMode(
      input: {
        grids: $grids
        isDefault: true
        isPersonal: true
        label: "DefaultView"
        type: "allThings"
      }
    ) {
      viewMode {
        ${viewModeReadFields}
      }
    }
  }
`);

export const CREATE_THING_TYPE_VIEWMODE_MUTATION = parse(`
  mutation(
    $description: String
    $domain: String
    $grids: [InputAddGrid!]!
    $isDefault: Boolean!
    $isPersonal: Boolean!
    $label: String!
    $thingType: String!
  ) {
    createViewMode(
      input: {
        description: $description
        domain: $domain
        grids: $grids
        isDefault: $isDefault
        isPersonal: $isPersonal
        label: $label
        thingType: $thingType
        type: "thingType"
      }
    ) {
      viewMode {
        ${viewModeReadFields}
      }
    }
  }
`);

export const ALL_ROLES = gql`
  query {
    listRoles(pageInfo: { size: 250000 }) {
      roles {
        id
        name
        description
        domain: domainId
        visibleInSubdomains
      }
    }
  }
`;

export const UPDATE_WIDGET_MUTATION = parse(`
  mutation(
    $viewModeId: ID!
    $gridId: String!
    $widgetId: String!
    $widgetProps: JSON
  ) {
    updateWidgetSettings(
      scope: { viewModeId: $viewModeId, gridId: $gridId, widgetId: $widgetId }
      input: { props: $widgetProps }
    ) {
      viewMode {
        ${viewModeReadFields}
      }
    }
  }
`);

export const CREATE_ROLE = gql`
  mutation createRole($input: InputCreateRole!, $timezone: String) {
    createRole(input: $input) {
      id
      name
      description
      domainId
      createdAt(
        momentFunction: {
          name: format
          arguments: "D MMMM YYYY, HH:mm"
          timezone: $timezone
        }
      )
      updatedAt(
        momentFunction: {
          name: format
          arguments: "D MMMM YYYY: HH:mm"
          timezone: $timezone
        }
      )
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($id: ID!, $input: InputUpdateRole!, $timezone: String) {
    updateRole(id: $id, input: $input) {
      id
      name
      description
      domainId
      visibleInSubdomains
      createdAt(
        momentFunction: {
          name: format
          arguments: "D MMMM YYYY, HH:mm"
          timezone: $timezone
        }
      )
      updatedAt(
        momentFunction: {
          name: format
          arguments: "D MMMM YYYY: HH:mm"
          timezone: $timezone
        }
      )
    }
  }
`;

export const GET_ROLE = gql`
  query($roleId: ID!, $timezone: String) {
    role(id: $roleId) {
      id
      description
      domainId
      visibleInSubdomains
      name
      privilegesConnection {
        privileges {
          id
          objectName
          name
          roleId
          delete
          read
          create
          update
          domainId
          type
        }
      }
      createdAt(
        momentFunction: {
          name: format
          arguments: "D MMM YYYY, HH:mm"
          timezone: $timezone
        }
      )
      updatedAt(
        momentFunction: {
          name: format
          arguments: "D MMM YYYY: HH:mm"
          timezone: $timezone
        }
      )
    }
  }
`;

export const REMOVE_ROLE = gql`
  mutation($roleId: ID!) {
    removeRole(id: $roleId) {
      id
    }
  }
`;

export const REMOVE_ROLES = gql`
  mutation($roleIds: [ID!]!) {
    removeRoles(input: { roles: $roleIds }) {
      removedRoleIds
    }
  }
`;

export const ROLE_USERS = gql`
  query($roleId: ID!, $pageInfo: InputMarkerPaging) {
    roleUsers(id: $roleId, pageInfo: $pageInfo) {
      users {
        userName
      }
      pageInfo {
        itemCount
        size
        hasNext
        marker
        nextMarker
      }
    }
  }
`;

export const REMOVE_USER_FROM_ROLE = gql`
  mutation($input: RemoveUserFromRoleInput!) {
    removeUserFromRole(input: $input) {
      roleId
    }
  }
`;

export const REMOVE_USERS_FROM_ROLE = gql`
  mutation($roleId: ID!, $users: [String!]!) {
    removeUsersFromRole(input: { roleId: $roleId, users: $users }) {
      removedIds
    }
  }
`;

export const REMOVE_PRIVILEGE = gql`
  mutation($privilegeId: ID!) {
    removePrivilege(id: $privilegeId) {
      id
    }
  }
`;

export const REMOVE_PRIVILEGES = gql`
  mutation($privilegeIds: [ID!]!) {
    removePrivileges(input: { privileges: $privilegeIds }) {
      removedPrivilegeIds
    }
  }
`;

export const ALL_DOMAINS = gql`
  {
    allDomains(searchOptions: { paging: { size: 20000 } }) {
      domains {
        id
        path
        name
      }
    }
  }
`;

export const DOMAIN_TREE = gql`
  query {
    domainTree {
      tree
    }
  }
`;

export const GET_DOMAIN = gql`
  query($domainId: ID!) {
    domain(domainId: $domainId) {
      name
    }
  }
`;

export const TOP_DOMAINS = gql`
  {
    topDomains {
      domains {
        id
        name
        description
      }
    }
  }
`;
export const SUB_DOMAINS = gql`
  query($domainId: ID!, $pageInfo: InputMarkerPaging) {
    domain(domainId: $domainId) {
      id
      name
      description
      data
      domainMetadata {
        id
        label
        type
      }
      parentsConnection {
        id
        name
        description
      }
      subdomainsConnection(pageInfo: $pageInfo) {
        domains {
          id
          name
          description
        }
        pageInfo {
          hasNext
          size
          itemCount
          nextMarker
        }
      }
    }
  }
`;

export const SUGGEST_USERS_QUERY = gql`
  query($freeText: String) {
    allUsers(
      searchOptions: { filter: { freeText: $freeText, category: all } }
    ) {
      users {
        firstName
        lastName
        userName
      }
    }
  }
`;

export const ADD_ROLE_TO_USER = gql`
  mutation addUserToRole($input: AddUserToRoleInput!) {
    addUserToRole(input: $input) {
      userName
    }
  }
`;
export const ADD_ROLES_TO_USER = gql`
  mutation addRolesToUser($input: AddRolesToUserInput!) {
    addRolesToUser(input: $input) {
      roles {
        userName
        roleId
        policyIsAttached
      }
    }
  }
`;
export const REMOVE_ROLES_FROM_USER = gql`
  mutation removeRolesFromUser($input: RemoveRolesFromUserInput!) {
    removeRolesFromUser(input: $input) {
      roleIds
    }
  }
`;

export const ADD_GRID_MUTATION = parse(`
  mutation(
    $gridId: String!
    $layout: JSON
    $type: String
    $viewModeId: ID!
    $widgets: [InputAddWidgetSettings!]
  ) {
    addGrid(
      scope: { viewModeId: $viewModeId }
      input: {
        gridId: $gridId
        type: $type
        layout: $layout
        widgets: $widgets
      }
    ) {
      viewMode {
        ${viewModeReadFields}
      }
    }
  }
`);

export const UPDATE_GRID_MUTATION = parse(`
  mutation(
    $gridId: String!
    $layout: JSON!
    $viewModeId: ID!
    $widgets: [InputAddWidgetSettings!]!
  ) {
    updateGrid(
      scope: { viewModeId: $viewModeId, gridId: $gridId }
      input: { widgets: $widgets, layout: $layout }
    ) {
      viewMode {
        ${viewModeReadFields}
      }
    }
  }
`);

export const ALL_PERMISSION_METADATA = gql`
  query {
    permissionMetadata {
      metadata
    }
  }
`;

export const ALL_RULES = gql`
  query(
    $sort: RulesSearchSortFields
    $filter: RuleSearchFilter
    $paging: InputListPaging
  ) {
    allRules(searchOptions: { sort: $sort, filter: $filter, paging: $paging }) {
      name
      description
      id
      enabled
      actions {
        type
      }
      readonly
    }
  }
`;

export const GET_RULE = gql`
  query($ruleId: ID!) {
    rule(id: $ruleId) {
      id
      name
      description
      enabled
      threshold {
        count
        interval
      }
      expression {
        __typename
        ... on RuleExpressionGeoFence {
          operator
          resource
          geoFenceValue: value
        }
        ... on RuleExpressionValue {
          operator
          resource
          value
        }
        ... on RuleExpressionPoi {
          operator
          resource
          poiValue: value {
            center {
              lat
              lon
            }
            radius
          }
        }
      }
      actions {
        type
        config
      }
      filter {
        domain
        thingType
        thingNames
      }
    }
  }
`;

export const UPDATE_RULE_MUTATION = gql`
  mutation($id: ID!, $input: InputUpdateRule!) {
    updateRule(id: $id, input: $input) {
      id
      name
      description
      enabled
    }
  }
`;

export const CREATE_RULE_MUTATION = gql`
  mutation($input: InputCreateRule!) {
    createRule(input: $input) {
      id
    }
  }
`;

export const REMOVE_RULE_MUTATION = gql`
  mutation($ruleId: ID!) {
    removeRule(id: $ruleId) {
      id
    }
  }
`;

export const THING_TYPE_RESOURCE_QUERY = gql`
  query thingTypeResource($resourceId: ID!) {
    thingTypeResource(resourceId: $resourceId) {
      id
      name
      type
      metadata {
        label
        unit
        isSettable
        isVirtual
        custom {
          label
          value
        }
      }
    }
  }
`;

export const THING_TYPE_RESOURCES_QUERY = gql`
  query thingTypeResources($thingTypeId: ID!) {
    thingTypeResources(thingTypeId: $thingTypeId) {
      resources {
        id
        name
        type
        metadata {
          label
          unit
          isSettable
          isVirtual
          custom {
            label
            value
          }
        }
      }
    }
  }
`;

export const RESOURCE_OPTIONS_QUERY = gql`
  query($resourceId: ID!) {
    resourceOptions(resourceId: $resourceId) {
      options {
        label
        value
      }
    }
  }
`;

export const FIND_EVENTS_QUERY = gql`
  query allEvents($searchOptions: ListEventsSearchOptions, $timezone: String) {
    allEvents(searchOptions: $searchOptions) {
      pageInfo {
        totalCount
      }
      events {
        id
        timestamp(
          momentFunction: {
            name: format
            arguments: "lll"
            timezone: $timezone
          }
        )
        message
        classification
        acknowledgedBy
        acknowledgedAt(momentFunction: { name: fromNow, timezone: $timezone })
        acknowledgmentRequired
        type
        source {
          domain
          thingName
          thingType
          domain
          resource
          rule
        }
      }
    }
  }
`;

export const ACKNOWLEDGE_EVENT_MUTATION = gql`
  mutation($eventId: ID!) {
    acknowledgeEvent(id: $eventId) {
      id
    }
  }
`;

export const DELETE_VIEW_MODE_MUTATION = gql`
  mutation($viewModeId: ID!) {
    deleteViewMode(id: $viewModeId) {
      id
    }
  }
`;

export const RETENTION_PERIODS_QUERY = gql`
  query {
    retentionPeriods {
      enabled
      storageRetentionPeriod
      searchIndexRetentionPeriod
    }
  }
`;

export const PUT_OBSERVATION_STORAGE_RETENTION_MUTATION = gql`
  mutation($input: ObservationStorageRetention!) {
    putObservationStorageRetention(input: $input) {
      enabled
      storageRetentionPeriod
      searchIndexRetentionPeriod
    }
  }
`;

export const DELETE_OBSERVATIONSTORAGE_RETENTION_MUTATION = gql`
  mutation($input: UserCredentials!) {
    deleteObservationStorageRetention(input: $input) {
      _empty
    }
  }
`;

export const THING_IMAGE = gql`
  query($thingId: String!) {
    file(scope: { fileName: "image", thingName: $thingId }) {
      url
    }
  }
`;

export const DELETE_THING_IMAGE = gql`
  mutation($thingId: String!) {
    removeFile(scope: { thingName: $thingId, fileName: "image" }) {
      id
    }
  }
`;

export const ALL_EXPORT_JOBS_EXECUTIONS = parse(`
  query allExportJobExecutions(
    $input: ListExecutionsSearchOptions
    $pageInfo: InputMarkerPaging
    $timezone: String
  ) {
    allExportJobExecutions(input: $input, pageInfo: $pageInfo) {
      executions {
        id
        submittedAt(${exportDateMomentFunction})
        executionFileName
        completedAt
        status
        jobConnection {
          id
          things
          jobName
          thingTypeLabel
          thingType
          type
          thingTypeConnection {
            label
          }
        }
      }
      pageInfo {
        itemCount
        size
        hasNext
        nextMarker
      }
    }
  }
`);

export const ALL_EXPORT_JOBS = parse(`
  query($pageInfo: InputMarkerPaging, $timezone: String) {
    allExportJobs(pageInfo: $pageInfo) {
      exportJobs {
        id
        things
        thingType
        thingTypeLabel
        jobName
        type
        resources
        createdAt(${exportDateMomentFunction})
        enabled
        thingTypeConnection {
          label
        }
      }
      pageInfo {
        marker
        nextMarker
      }
    }
  }
`);

export const EXPORT_JOB = parse(`
  query($id: ID!, $timezone: String) {
    exportJob(id: $id) {
      id
      things
      thingType
      thingTypeLabel
      jobName
      type
      enabled
      from(${exportDateMomentFunction})
      to(${exportDateMomentFunction})
      createdAt(${exportDateMomentFunction})
      schedule
      timePeriod
      resources
      notifyByEmail
      recipients
      thingTypeConnection {
        label
      }
    }
  }
`);

export const CANCEL_EXPORT_JOB_EXECUTION = gql`
  mutation($executionId: ID!) {
    cancelExportJobExecution(id: $executionId) {
      _empty
    }
  }
`;

export const CREATE_DATA_EXPORT = gql`
  mutation($input: InputCreateExportJob!) {
    createExportJob(input: $input) {
      jobName
    }
  }
`;

export const UPDATE_EXPORT_JOB = gql`
  mutation($id: ID!, $input: InputUpdateExportJob!) {
    updateExportJob(id: $id, input: $input) {
      id
      jobName
      type
      enabled
    }
  }
`;

export const REMOVE_EXPORT_JOB = gql`
  mutation($id: ID!) {
    removeExportJob(id: $id) {
      _empty
    }
  }
`;

export const USERS_QUERY = parse(`
  query allUsers($searchOptions: UserSearchOptions, $timezone: String ) {
    allUsers(searchOptions: $searchOptions) {
      pageInfo {
        page
        pages
        size
        itemCount
        totalCount
      }
      metadata {
        count {
          all
          active
          pending
          unconfirmed
        }
      }
      users {
        createdAt(
          momentFunction: {
            name: format
            arguments: "LLL"
            timezone: $timezone
          }
        )
        domainName
        email
        enabled
        id: userName
        userName
      }
    }
  }
`);

export const USER_QUERY = parse(`
  query user($userName: String!, $timezone: String) {
    user(userName: $userName) {
      ${userListFields}
      address
      company
      dateTermsAgreed(momentFunction: { name: fromNow, timezone: $timezone })
      firstName
      lastName
      notes1
      notes2
      notes3
      phone
      termsAgreed
      termsVersion
      zip
      city
      country
      currentRoles: rolesConnection {
        id
      }
    }
  }
`);

export const REMOVE_USERS_MUTATION = gql`
  mutation removeUsers($userNames: [String!]!) {
    removeUsers(userNames: $userNames) {
      userNames
    }
  }
`;

export const UPDATE_USER_MUTATION = parse(`
  mutation updateUserMutation($input: UpdateUserInput!, $timezone: String) {
    updateUser(input: $input) {
      ${userListFields}
    }
  }
`);

export const CREATE_USER_MUTATION = parse(`
  mutation createUserMutation($input: CreateUserInput!, $timezone: String) {
    createUser(input: $input) {
      ${userListFields}
    }
  }
`);

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPasswordMutation($userName: String!) {
    resetPassword(userName: $userName) {
      _empty
    }
  }
`;

export const REVOKE_CONSENT_MUTATION = gql`
  mutation revokeConsentMutation($userName: String!) {
    revokeConsent(userName: $userName) {
      _empty
    }
  }
`;

export const ME_QUERY = parse(`
  query {
    me {
      ${userMeFields}
    }
  }
`);

export const UPDATE_ME_MUTATION = parse(`
  mutation updateMeMutation($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ${userMeFields}
    }
  }
`);

export const EXPORT_JOB_EXECUTION_URL_QUERY = gql`
  query exportJobExecutionDownloadUrl($id: ID!) {
    exportJobExecutionDownloadUrl(id: $id) {
      url
    }
  }
`;

export const clearClientErrors = gql`
  mutation {
    updateApiError(error: null) @client
  }
`;

export const THING_OBSERVATION_AGGREGATION_QUERY = gql`
  query thingObservationAggregation(
    $searchOptions: ListThingObservationsAggSearchOptions
  ) {
    thingObservationsAggregation(
      searchOptions: $searchOptions
      aggregationType: avg
      aggregationInterval: "1d"
    ) {
      buckets {
        key
        count
        value
      }
    }
  }
`;

export const THING_OBSERVATION_RAW_QUERY = gql`
  query thingObservations($searchOptions: ListThingObservationsSearchOptions) {
    thingObservations(searchOptions: $searchOptions) {
      observations {
        state
        thingName
        thingType
        timestamp
      }
    }
  }
`;

export const ALL_FIRMWARES = gql`
  query {
    allFirmwares {
      firmwares {
        firmwareId
        name
        description
        target
        numberOfVersions
      }
    }
  }
`;

export const GET_FIRMWARE = gql`
  query($firmwareId: ID!) {
    firmware(firmwareId: $firmwareId) {
      firmwareId
      name
      description
      target
      numberOfVersions
      versionsConnection {
        versionId
        firmwareId
        name
        releaseNotes
        filename
        checksum
        hashingAlgorithm
        enabled
      }
    }
  }
`;
