import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import React from "react";

const useActionRowStyles = makeStyles(theme => ({
  column: {
    position: "relative",
    width: 0,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
    overflow: "visible"
  },
  actionContainer: {
    position: "absolute",
    right: 0,
    height: "100%",
    top: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main
  }
}));
const ActionRowColumn = ({ show, children, ...columnProps }) => {
  const classes = useActionRowStyles();
  return (
    <TableCell className={classes.column} {...columnProps}>
      <div className={classes.actionContainer}>{show ? children : null}</div>
    </TableCell>
  );
};

ActionRowColumn.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any
};

export default ActionRowColumn;

export const EmptyActionRowCell = withStyles(() => ({
  column: { width: 0, padding: 0 }
}))(({ classes }) => <TableCell className={classes.column} padding="none" />);

export const FloatingHoverActionRow = withStyles(() => ({
  column: {
    width: 0,
    padding: 0,
    position: "relative"
  },
  actionContainer: {
    display: "flex",
    position: "absolute",
    top: "0px",
    right: "0px",
    height: "100%"
  }
}))(({ show, classes, children }) => (
  <TableCell className={classes.column} padding="none">
    <div className={classes.actionContainer}>{show ? children : null}</div>
  </TableCell>
));

FloatingHoverActionRow.propTypes = {
  show: PropTypes.bool,
  classes: PropTypes.object,
  children: PropTypes.any
};

const useHoverButtonStyles = makeStyles(theme => ({
  hoverButton: {
    padding: "0px 20px",
    height: "100%",
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

export const HoverButton = React.forwardRef(({ children, ...props }, ref) => {
  const classes = useHoverButtonStyles();
  return (
    <Button {...props} className={classes.hoverButton} ref={ref}>
      {children}
    </Button>
  );
});

HoverButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};
