import React from "react";
import PropTypes from "prop-types";
import { FilterContainer, FILTER_TYPES } from "components/filter";

export const DashboardHeader = ({ filterId }) => {
  return <FilterContainer type={FILTER_TYPES.ALL_THINGS} filterId={filterId} />;
};

DashboardHeader.propTypes = {
  filterId: PropTypes.string
};
