import { createAction, handleActions } from "redux-actions";
import * as R from "ramda";

export const USERS_GET_USERDATA_REQUEST = "USERS_GET_USERDATA_REQUEST";
export const USERS_GET_USERDATA_SUCCESS = "USERS_GET_USERDATA_SUCCESS";
export const USERS_GET_USERDATA_FAILURE = "USERS_GET_USERDATA_FAILURE";

export const getUserData = createAction(USERS_GET_USERDATA_REQUEST);
export const getUserDataFailure = createAction(USERS_GET_USERDATA_FAILURE);
export const getUserDataSuccess = createAction(USERS_GET_USERDATA_SUCCESS);

export const initialState = {};

export default handleActions(
  {
    [USERS_GET_USERDATA_SUCCESS]: (state, action) =>
      R.set(R.lensPath(["auth", "user", "data"]), action.payload)(state)
  },
  initialState
);
