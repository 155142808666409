import * as Storage from "api/device/storage";
import constants from "../constants";

export const STORAGE_KEY = constants.LOCAL_STORAGE_SESSION_EXPIRED;
const MAX_TIME_AFTER_EXPIRATION = 15; // minutes

export const deleteExpiredSession = () => {
  Storage.remove(STORAGE_KEY);
};

export const tryRecoverExpiredSession = () => {
  const { pathname = "", search = "", timestamp, message } =
    Storage.get(STORAGE_KEY) || {};
  return {
    hasExpiredSession: timestamp
      ? (Date.now() - timestamp) / 1000 / 60 < MAX_TIME_AFTER_EXPIRATION
      : false,
    lastActiveUrl: `${pathname}${search}`,
    message: message
      ? {
          errorMessage: { message }
        }
      : {}
  };
};

export const setExpiredSession = ({ pathname, search, message } = {}) =>
  Storage.set(STORAGE_KEY, {
    pathname,
    search,
    timestamp: Date.now(),
    message
  });
