import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 0;

  & > * {
    margin: 10px 0;
  }
`;
