import { Observable } from "rxjs";
import { push } from "react-router-redux";
import { isEmpty, project } from "ramda";
import { apiError } from "ducks/errors";
import { setNotificationMessage, setError } from "ducks/system";
import {
  adaptJobs,
  adaptGroups,
  adaptGroup
} from "adapters/device_management_adapter";
import {
  DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_REQUEST,
  DEVICE_MANAGEMENT_CREATE_GROUP_REQUEST,
  DEVICE_MANAGEMENT_CREATE_JOB_REQUEST,
  DEVICE_MANAGEMENT_DELETE_GROUPS_REQUEST,
  DEVICE_MANAGEMENT_DESCRIBE_GROUP_REQUEST,
  DEVICE_MANAGEMENT_DESCRIBE_JOB_REQUEST,
  DEVICE_MANAGEMENT_LIST_GROUPS_REQUEST,
  DEVICE_MANAGEMENT_LIST_THINGS_REQUEST,
  DEVICE_MANAGEMENT_REMOVE_THINGS_REQUEST,
  DEVICE_MANAGEMENT_SELECT_ALL_THINGS_REQUEST,
  DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_REQUEST,
  DEVICE_MANAGEMENT_LIST_COMPLETED_JOBS_REQUEST,
  DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_REQUEST,
  DEVICE_MANAGEMENT_CANCEL_JOBS,
  addThingsToGroupFailure,
  addThingsToGroupSuccess,
  cancelJobsFailure,
  cancelJobsSuccess,
  createGroupFailure,
  createGroupSuccess,
  createJobFailure,
  createJobSuccess,
  deleteGroupSuccess,
  deleteGroupsSuccess,
  describeGroupFailure,
  describeGroupSuccess,
  describeJobSuccess,
  describeJobFailure,
  listGroupsFailure,
  listGroupsSuccess,
  listThingsFailure,
  listThingsSuccess,
  removeThingsFailure,
  removeThingsSuccess,
  selectAllThingsFailure,
  selectAllThingsSuccess,
  listJobsOngoingSuccess,
  listJobsOngoingFailure,
  listJobsCompletedSuccess,
  listJobsCompletedFailure,
  listJobsCanceledSuccess,
  listJobsCanceledFailure,
  DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_REQUEST,
  listJobExecutionsSuccess,
  listJobExecutionsFailure,
  DEVICE_MANAGEMENT_CANCEL_JOB_REQUEST,
  cancelJobFailure,
  cancelJobSuccess,
  describeJob,
  listJobsOngoing,
  listJobsCanceled,
  listJobExecutions,
  setExecutionStatus,
  DEVICE_MANAGEMENT_JOB_DOCUMENT_REQUEST,
  getJobDocumentSuccess,
  getJobDocumentFailure
} from "./";
import {
  partitionResults,
  removeThing,
  getErrors
} from "./utils/device_management_epics_utils";
import { downloadFile } from "utils/file_utils";

export const deviceManagementListGroupsEpic = (action$, store, { restApi }) =>
  action$.ofType(DEVICE_MANAGEMENT_LIST_GROUPS_REQUEST).mergeMap(() =>
    restApi.deviceManagement
      .listGroups$()
      .map(response => listGroupsSuccess(adaptGroups(response)))
      .catch(error => Observable.of(listGroupsFailure(error), apiError(error)))
  );

export const deviceManagementCreateGroupEpic = (action$, store, { restApi }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_CREATE_GROUP_REQUEST)
    .mergeMap(({ payload }) =>
      restApi.deviceManagement
        .createGroup$(payload)
        .map(response => createGroupSuccess(adaptGroup(response)))
        .catch(error =>
          Observable.of(createGroupFailure(error), apiError(error))
        )
    );

export const deviceManagementDescribeGroupEpic = (
  action$,
  store,
  { restApi }
) =>
  action$
    .ofType(DEVICE_MANAGEMENT_DESCRIBE_GROUP_REQUEST)
    .mergeMap(({ payload }) => {
      return restApi.deviceManagement
        .describeGroup$(payload)
        .map(describeGroupSuccess)
        .catch(error => {
          return Observable.of(
            describeGroupFailure(error),
            apiError(error),
            push({
              pathname: `/settings/devicemanagement/groups`
            })
          );
        });
    });

export const deviceManagementListThingsEpic = (action$, store, { restApi }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_LIST_THINGS_REQUEST)
    .mergeMap(({ payload }) =>
      restApi.deviceManagement
        .listThings$(payload)
        .map(listThings => listThingsSuccess({ listThings, id: payload.id }))
        .catch(error =>
          Observable.of(listThingsFailure(error), apiError(error))
        )
    );

export const deviceManagementDeleteGroupsEpic = (action$, store, { restApi }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_DELETE_GROUPS_REQUEST)
    .mergeMap(({ payload }) =>
      Observable.from(payload.thingGroups)
        .mergeMap(thingGroup =>
          restApi.deviceManagement
            .deleteGroup$(thingGroup)
            .map(() => deleteGroupSuccess(thingGroup))
            .catch(error => Observable.of(apiError(error)))
        )
        .concat(Observable.of(deleteGroupsSuccess()))
    );

export const deviceManagementAddThingsToGroupEpic = (
  action$,
  store,
  { restApi }
) =>
  action$
    .ofType(DEVICE_MANAGEMENT_ADD_THINGS_TO_GROUP_REQUEST)
    .mergeMap(({ payload }) =>
      restApi.deviceManagement
        .addThings$(payload)
        .mergeMap(response =>
          Observable.of(
            addThingsToGroupSuccess(response),
            push({
              pathname: `/settings/devicemanagement/groups/${payload.id}`
            })
          )
        )
        .catch(error =>
          Observable.of(addThingsToGroupFailure(), apiError(error))
        )
    );

export const deviceManagementSelectAllThingsEpic = (action$, store, { api }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_SELECT_ALL_THINGS_REQUEST)
    .mergeMap(({ payload }) =>
      api.things
        .findIds$({ filter: payload.filter })
        .map(selectAllThingsSuccess)
        .catch(error =>
          Observable.of(selectAllThingsFailure(), apiError(error))
        )
    );

export const deviceManagementRemoveThingsEpic = (action$, store, { restApi }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_REMOVE_THINGS_REQUEST)
    .mergeMap(({ payload: { thingGroupId, thingNames } }) =>
      Observable.forkJoin(
        thingNames.map(
          removeThing(restApi.deviceManagement.removeThing$, thingGroupId)
        )
      ).mergeMap(results => {
        const [failed, successful] = partitionResults(results);
        return failed.length === 0
          ? Observable.of(
              removeThingsSuccess({ id: thingGroupId, thingNames: successful })
            )
          : Observable.of(
              removeThingsFailure({ id: thingGroupId, successful, failed }),
              ...getErrors(results).map(error => apiError(error))
            );
      })
    );

export const deviceManagementListJobsOngoingEpic = (
  action$,
  store,
  { restApi }
) =>
  action$.ofType(DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_REQUEST).mergeMap(() =>
    restApi.deviceManagement
      .listJobsOngoing$()
      .map(response => listJobsOngoingSuccess(adaptJobs(response)))
      .catch(error =>
        Observable.of(listJobsOngoingFailure(error), apiError(error))
      )
  );

export const deviceManagementCancelJobsEpic = (action$, store, { restApi }) =>
  action$.ofType(DEVICE_MANAGEMENT_CANCEL_JOBS).mergeMap(({ payload }) =>
    restApi.deviceManagement
      .cancelJobs$(project(["jobId"], payload))
      .mergeMap(response => [
        cancelJobsSuccess(response),
        listJobsOngoing(),
        listJobsCanceled()
      ])
      .catch(error => Observable.of(cancelJobsFailure(), apiError(error)))
  );

export const deviceManagementListJobsCompletedEpic = (
  action$,
  store,
  { restApi }
) =>
  action$.ofType(DEVICE_MANAGEMENT_LIST_COMPLETED_JOBS_REQUEST).mergeMap(() =>
    restApi.deviceManagement
      .listJobsCompleted$()
      .map(response => listJobsCompletedSuccess(adaptJobs(response)))
      .catch(error =>
        Observable.of(listJobsCompletedFailure(error), apiError(error))
      )
  );

export const deviceManagementListJobsCanceledEpic = (
  action$,
  store,
  { restApi }
) =>
  action$.ofType(DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_REQUEST).mergeMap(() =>
    restApi.deviceManagement
      .listJobsCanceled$()
      .map(response => listJobsCanceledSuccess(adaptJobs(response)))
      .catch(error =>
        Observable.of(listJobsCanceledFailure(error), apiError(error))
      )
  );

export const deviceManagementCreateJobEpic = (
  action$,
  store,
  { restApi, createJobForm, validateCreateJobForm, adaptCreateJobAttributes }
) =>
  action$.ofType(DEVICE_MANAGEMENT_CREATE_JOB_REQUEST).mergeMap(() => {
    const form = createJobForm(store.getState());
    const validation = validateCreateJobForm(form);

    if (!isEmpty(validation)) {
      return Observable.of(createJobFailure(validation));
    }

    return restApi.deviceManagement
      .putJobDocument$({
        domain: form.jobDomain,
        file: form.selectedJobDocumentFile
      })
      .mergeMap(({ documentId }) =>
        restApi.deviceManagement
          .createJob$(
            adaptCreateJobAttributes({ form, jobDocumentId: documentId })
          )
          .mergeMap(response =>
            Observable.of(
              createJobSuccess(response),
              push({
                pathname: "/settings/devicemanagement/jobs"
              })
            )
          )
      )
      .catch(error =>
        Observable.of(createJobFailure(validation), apiError(error))
      );
  });

export const deviceManagementDescribeJobEpic = (action$, store, { restApi }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_DESCRIBE_JOB_REQUEST)
    .mergeMap(({ payload }) =>
      restApi.deviceManagement
        .describeJob$(payload)
        .map(describeJobSuccess)
        .catch(error =>
          Observable.of(describeJobFailure(error), apiError(error))
        )
    );

export const deviceManagementListJobExecutionsEpic = (
  action$,
  store,
  { restApi }
) =>
  action$
    .ofType(DEVICE_MANAGEMENT_LIST_JOB_EXECUTIONS_REQUEST)
    .mergeMap(({ payload }) =>
      restApi.deviceManagement
        .listJobExecutions$(payload)
        .map(listJobExecutionsSuccess)
        .catch(error =>
          Observable.of(listJobExecutionsFailure(), apiError(error))
        )
    );

export const deviceManagementCancelJobEpic = (action$, store, { restApi }) =>
  action$.ofType(DEVICE_MANAGEMENT_CANCEL_JOB_REQUEST).mergeMap(({ payload }) =>
    restApi.deviceManagement
      .cancelJob$(payload)
      .mergeMap(response =>
        Observable.of(
          cancelJobSuccess(response),
          describeJob({ jobId: payload.jobId }),
          setExecutionStatus(""),
          listJobExecutions({ jobId: payload.jobId, status: "" })
        )
      )
      .catch(error => Observable.of(cancelJobFailure(), apiError(error)))
  );

export const getJobDocumentEpic = (action$, store, { restApi }) =>
  action$
    .ofType(DEVICE_MANAGEMENT_JOB_DOCUMENT_REQUEST)
    .mergeMap(({ payload }) => {
      return restApi.deviceManagement
        .getJobDocument$(payload)
        .mergeMap(response => {
          downloadFile(
            `${payload.jobId}.json`,
            response.document,
            "application/json"
          );
          return Observable.of(
            setNotificationMessage(`File downloaded!`),
            getJobDocumentSuccess()
          );
        })
        .catch(error =>
          Observable.of(
            setError(`File could not be downloaded, please try again!`),
            getJobDocumentFailure(),
            apiError(error)
          )
        );
    });
