import React from "react";
import PropTypes from "prop-types";
import ThingIcon from "@material-ui/icons/WifiTethering";
import { withStyles } from "@material-ui/core/styles";
import { ColumnPicker } from "components/table/index";
import WidgetHeader from "components/widget/header/widget_header";

const WidgetLabel = withStyles(theme => ({
  label: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}))(({ classes, widgetLabel }) => (
  <div className={classes.label}>
    <ThingIcon color="primary" className={classes.icon} />
    {widgetLabel}
  </div>
));

const AllThingsWidgetHeader = ({
  columnPickerProps,
  widgetLabel,
  ...props
}) => (
  <WidgetHeader
    {...props}
    widgetLabel={<WidgetLabel widgetLabel={widgetLabel} />}
  >
    <ColumnPicker
      onSave={columnPickerProps.onColumnPickerSave}
      fields={columnPickerProps.fields}
      tableName={columnPickerProps.tableName}
      onChange={columnPickerProps.onColumnPickerChange}
      setDefaultColumns={columnPickerProps.setColumnPickerDefault}
    />
  </WidgetHeader>
);

AllThingsWidgetHeader.propTypes = {
  columnPickerProps: PropTypes.object.isRequired,
  widgetLabel: PropTypes.string
};

export default AllThingsWidgetHeader;
