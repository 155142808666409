import * as R from "ramda";
import simulatorDataTypes, {
  resourceSpecificTypes
} from "./simulator_data_types";
import { isNotEmptyOrNil } from "utils/general_utils";

const getResourceValue = (
  { strategyType, desiredDataType, strategyParameters, resourceStatePath },
  simConfig
) =>
  R.pathOr(
    R.pathOr(
      R.always(undefined),
      [desiredDataType, "generate", strategyType],
      simulatorDataTypes
    ),
    [resourceStatePath, desiredDataType, "generate", strategyType],
    resourceSpecificTypes
  )(desiredDataType, strategyParameters, simConfig);

const payloadForSingleResource = (state, time) => singleResource => {
  const oldValue = R.pipe(
    R.propOr("", "resourceStatePath"),
    R.ifElse(
      isNotEmptyOrNil,
      path => R.pathOr(null, path.split("/"), state),
      R.always(null)
    )
  )(singleResource);
  const resourceValue = getResourceValue(singleResource, { oldValue, ...time });
  return resourceValue === undefined || !singleResource.resourceStatePath
    ? undefined
    : R.assocPath(
        singleResource.resourceStatePath.split("/"),
        resourceValue,
        {}
      );
};

const createIndividualPayloads = (state, time) =>
  R.pipe(
    R.map(payloadForSingleResource(state, time)),
    R.filter(value => value !== undefined)
  );

const mergePayloads = R.reduce(R.mergeDeepLeft, {});
const filterEnabledPayloads = R.filter(R.propEq("enabled", true));

const mergeWithPrevState = state =>
  R.map(
    R.when(R.prop("resourceStatePath"), r =>
      R.mergeDeepLeft(
        {
          strategyParameters: R.pipe(
            R.pathOr(undefined, r.resourceStatePath.split("/")),
            R.ifElse(R.isNil, R.always({}), value => ({ value }))
          )(state)
        },
        r
      )
    )
  );

export default (payloadConfig, state, time) => ({
  state: {
    reported: R.pipe(
      filterEnabledPayloads,
      mergeWithPrevState(),
      createIndividualPayloads(
        R.pathOr({}, ["state", "reported"], state),
        time
      ),
      mergePayloads
    )(payloadConfig)
  }
});
