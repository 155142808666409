import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import D from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

export const Dialog = props => (
  <D open {...props} maxWidth="md" fullWidth>
    <DialogTitle>{R.prop("title", props)}</DialogTitle>
    <DialogContent>
      <DialogContentText>{props.children}</DialogContentText>
    </DialogContent>
    {props.actions && <DialogActions>{props.actions}</DialogActions>}
  </D>
);

Dialog.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.array
};
