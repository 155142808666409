import React from "react";
import { getWidgetComponent } from "../get_widget_base";
import EventsWidget from "./events_widget_container";
import EventsWidgetFooter from "./events_widget_footer";

export const getWidget = props =>
  getWidgetComponent({
    ...props,
    isContainer: true,
    includeFooter: true,
    scrollable: true,
    contentDirection: "row",
    noResize: true,
    widgetComp: EventsWidget,
    footer: <EventsWidgetFooter {...props} />
  });
