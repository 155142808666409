import { isDevelopmentNodeEnv } from "utils/general_utils";

const isEnabledInStorage = () => {
  try {
    return localStorage.getItem("enableTrace") === "true";
  } catch (err) {
    return false;
  }
};

const isEnabled = isEnabledInStorage() || isDevelopmentNodeEnv();
const LOG_STYLE = "color: #777777";
const ERR_STYLE = "color: #ee4444";
const noop = () => {};

const log = isEnabled
  ? (...args) => console.info("%c[trace]", LOG_STYLE, ...args) // eslint-disable-line no-console
  : noop;

const err = isEnabled
  ? (...args) => console.error("%c[trace]", ERR_STYLE, ...args) // eslint-disable-line no-console
  : noop;

export const traceLog = log;
export const traceErr = err;
