import * as colors from "themes/colors";

export const getStyles = ({
  palette: {
    primary: { main }
  }
}) => ({
  searchIconWrapper: {
    height: "100%",
    width: "35px",
    lineHeight: "35px",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "20px"
  },
  searchIcon: {
    fill: colors.colorDustyGrayApprox,
    verticalAlign: "middle"
  },
  addIcon: {
    fill: colors.colorDustyGrayApprox,
    paddingLeft: "10px"
  },
  innerRowHeight: {
    height: "100%",
    lineHeight: "65px",
    paddingTop: "24px"
  },
  innerRow: {
    height: "100%",
    lineHeight: "65px"
  },
  map: {
    height: "400px"
  },
  global: {
    borderTop: "1px solid rgb(203, 203, 203)",
    minHeight: "65px",
    boxSizing: "border-box",
    flex: 1
  },
  resource: {
    borderTop: "1px solid rgb(203, 203, 203)",
    minHeight: "65px",
    boxSizing: "border-box",
    flex: 1
  },
  clearFilter: {
    fontSize: "12px",
    float: "right",
    cursor: "pointer"
  },
  collapsedTitleWrapper: {
    lineHeight: "48px"
  },
  collapsedTitle: {
    fontSize: "14px",
    paddingLeft: "10px"
  },
  primaryColor: {
    color: main
  },
  freeTextContainer: {
    borderTop: "1px solid rgb(203, 203, 203)",
    minHeight: "65px",
    boxSizing: "border-box"
  },
  freeTextWrapper: {
    height: "100%",
    lineHeight: "65px",
    display: "inline-block",
    width: "90%"
  }
});
