import { getWidgetComponent } from "../get_widget_base";
import AllThingsWidget from "./all_things_widget";

export const StyleText = {
  display: "flex",
  flex: 1,
  overflow: "hidden"
};

export const getWidget = props =>
  getWidgetComponent({
    ...props,
    isContainer: true,
    includeFooter: false,
    includeHeader: false,
    showHeader: false,
    showProgressLine: false,
    scrollable: false,
    noResize: true,
    widgetComp: AllThingsWidget,
    StyleText
  });
