import { pipe, filter } from "ramda";
import { fromJS } from "immutable";
import { getLatestResourceTimestamp } from "ducks/aggregators/resources";
import { nonEmptyString } from "utils/general_utils";
import { widgetProp } from "utils/dashboard_utils";

const getVariables = ({ widget } = {}) =>
  widgetProp(widget, "addedVariables") || [];

const getNames = variables =>
  variables.map(variable => variable && variable.value);

export const getResourceNames = pipe(
  getVariables,
  getNames,
  filter(nonEmptyString)
);

export default (state, props) =>
  fromJS({
    latestValue: {
      value: null,
      timestamp: getLatestResourceTimestamp({
        state,
        resourceNames: getResourceNames(props),
        thingType: props.params.thingType,
        thingName: props.params.thingName,
        subThingType: props.params.subThingType,
        subthing: props.params.subthing
      })
    }
  });
