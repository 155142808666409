import { graphql } from "@apollo/client/react/hoc";
import { userCanUploadFiles } from "ducks/bucket_selectors";
import { thingSelectors, uploadThingImage } from "ducks/thing";
import { DELETE_THING_IMAGE, THING_IMAGE } from "graphql/queries";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "react-recompose";
import { memorizeToJS } from "utils/prevent_new_refs";
import ThingImage from "./image";

const makeMapStateToProps = () => {
  const memorizeToJS1 = memorizeToJS();

  return (state, ownProps) => {
    const unwrappedShadow =
      ownProps.thingShadow && ownProps.thingShadow.toJS
        ? memorizeToJS1(ownProps.thingShadow)
        : null;
    const thingId = unwrappedShadow ? unwrappedShadow.thingName : null;
    const thingType = unwrappedShadow ? unwrappedShadow.thingType : null;

    return {
      thingId,
      thingType,
      isLoadingImage: thingSelectors.isLoadingImage(state),
      userCanUploadFiles: userCanUploadFiles(state)
    };
  };
};

export const _fileStatusState = withState(
  "fileStatus",
  "setFileStatus",
  "loading"
);

export default compose(
  connect(makeMapStateToProps),
  graphql(THING_IMAGE, {
    skip: props =>
      !R.has("thingId", props) || R.isEmpty(R.prop("thingId", props)),
    options: props => ({
      variables: { thingId: R.propOr("", "thingId", props) }
    })
  }),
  graphql(DELETE_THING_IMAGE, { name: "deleteThingImage" }),
  withHandlers({
    uploadThingImage: ({ dispatch }) => ({ thingId, file }) => {
      dispatch(uploadThingImage({ thingName: thingId, file }));
    },
    deleteThingImage: ({ deleteThingImage }) => ({ thingId }) => {
      const variables = { thingId };
      deleteThingImage({
        variables,
        update: proxy => {
          proxy.writeQuery({
            query: THING_IMAGE,
            variables,
            data: { file: null }
          });
        }
      });
    }
  }),
  _fileStatusState
)(ThingImage);
