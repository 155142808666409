import {
  __,
  assoc,
  groupBy,
  mapObjIndexed,
  pipe,
  map,
  flatten,
  uniq,
  indexBy,
  prop
} from "ramda";

const transformThingsTypesByUniqueId = pipe(
  // Below constructs { [thingType]: [{ things }, ...]
  groupBy(prop("thingType")),
  mapObjIndexed(
    // Below pipe constructs { [thingType]: [ thingName, ... ] }
    pipe(
      map(prop("thingName")),
      flatten,
      uniq,
      // and lastly add a key as such { [thingType]: { things: [ thingName, ... ] } }
      assoc("things", __, {})
    )
  )
);

export default response => ({
  things: indexBy(prop("thingName"), response.things),
  thingTypes: transformThingsTypesByUniqueId(response.things)
});
