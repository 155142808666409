import Joi from "joi-browser";
import * as R from "ramda";

const _resourceIsNotSettable = ({ resourceIsSettable, resourceName }) =>
  R.unless(R.isNil, R.pipe(resourceIsSettable, R.not))(resourceName);

const isSetableOptions = [
  {
    label: "No",
    id: "setableOption-no",
    value: 0,
    index: 0
  },
  {
    label: "Yes",
    id: "setableOption-yes",
    value: 1,
    index: 1
  }
];

const WidgetEditSetable = ({
  onIsSetableChange,
  resourceIsSettable,
  resourceName
}) =>
  _resourceIsNotSettable({ resourceIsSettable, resourceName })
    ? {
        fields: [],
        initialModel: {
          isSetable: 0
        },
        schema: {
          isSetable: Joi.number()
        }
      }
    : {
        fields: [
          {
            id: "isSetable",
            key: "isSetable",
            options: isSetableOptions,
            label: "Enable set value",
            hintText: "Is Settable",
            type: "select",
            onChange: onIsSetableChange
          }
        ],
        initialModel: {
          isSetable: 0
        },
        schema: {
          isSetable: Joi.number()
        }
      };

export default WidgetEditSetable;
