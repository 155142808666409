import React from "react";
import PropTypes from "prop-types";
import { Form, Field, ModalFormButtons } from "forms_new";
import { Map } from "immutable";
import { ComposeForm } from "./compose_form";
import widgetTypes from "./widget_types";
import ModalDialog from "components/dialog";
import { withRouter } from "react-router";
import { fromJS } from "immutable";
import FormIconButton from "components/buttons/form_icon";
import {
  compose,
  withState,
  withHandlers,
  shouldUpdate,
  withPropsOnChange,
  lifecycle,
  pure
} from "react-recompose";
import * as R from "ramda";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { SpinnerWhileLoading } from "components/notification";
import { getEditForm } from "components/widget/widget_types/widget_asset";
import { thingSelectors } from "ducks/thing";
import { newLocation } from "records/location";
import { thingTypeSelectors } from "ducks/thing_types";
import { getDomain } from "ducks/domain";
import { getEditingWidgetExtraResources } from "ducks/aggregators/resources";
import * as loading from "ducks/aggregators/loading";
import {
  resourceUnitById,
  resourceIsSettableById
} from "ducks/schema/schema_selectors";
import * as imu from "utils/immutable_utils";
import {
  widgetId,
  widgetProp,
  resourceIdFromWidget
} from "utils/dashboard_utils";
import { isEmptyOrNil } from "utils/general_utils";

export const WidgetEditFragment = ({ typeOptions }) => ({
  id: "wedf",
  initialModel: {
    label: undefined,
    type: undefined
  },
  fields: [
    {
      id: "label",
      label: "Label",
      hintText: "Widget label"
    },
    {
      id: "type",
      label: "Widget Type",
      hintText: "Widget label",
      type: "select",
      options: typeOptions
    }
  ],
  schema: {
    label: Joi.string(),
    type: Joi.string()
  }
});

export const WidgetFormWrapper = ({
  initialModel,
  onFormChange,
  schema,
  handleCancel,
  handleDone,
  fields,
  ...rest
}) => {
  return (
    <Form
      initialModel={initialModel}
      schema={schema}
      onChange={onFormChange}
      onSubmit={handleDone}
    >
      {fields && Array.isArray(fields)
        ? fields.map((f, i) =>
            R.is(Function, f) ? (
              React.createElement(f, { ...rest, key: "chipfield_index_" + i })
            ) : (
              <Field key={"chipfield_" + f.id} {...f} />
            )
          )
        : fields(rest)}
      <ModalFormButtons>
        <FormIconButton
          data-test="modal-cancel-btn"
          onClick={handleCancel}
          text="Cancel"
        />
        <FormIconButton color="primary" type="submit" text="Done" />
      </ModalFormButtons>
    </Form>
  );
};
WidgetFormWrapper.propTypes = {
  initialModel: PropTypes.object,
  widgetTypes: PropTypes.object,
  editingThingType: PropTypes.object,
  dashboardType: PropTypes.string,
  handleCancel: PropTypes.func,
  customProps: PropTypes.object,
  handleDone: PropTypes.func,
  onFormChange: PropTypes.func,
  onResourceChange: PropTypes.func,
  onDecimalsChange: PropTypes.func,
  schema: PropTypes.object,
  forceMount: PropTypes.bool,
  isLoading: PropTypes.bool,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
};

const WidgetEditForm = SpinnerWhileLoading(props => !props.isLoading)(
  SpinnerWhileLoading(props => !props.forceMount)(WidgetFormWrapper)
);

const WidgetEditDialog = props => {
  return (
    <ModalDialog
      id="edit-dialog"
      title={props.title}
      open={props.modalStateWidget.open}
      fullWidth={true}
      onMouseDown={event => {
        event.stopPropagation();
      }}
    >
      <WidgetEditForm {...props} />
    </ModalDialog>
  );
};

WidgetEditDialog.propTypes = {
  title: PropTypes.string,
  initialModel: PropTypes.object,
  schema: PropTypes.object,
  customProps: PropTypes.object,
  editingThingType: PropTypes.object,
  handleCancel: PropTypes.func,
  handleDone: PropTypes.func,
  onResourceChange: PropTypes.func,
  onDecimalsChange: PropTypes.func,
  type: PropTypes.string,
  forceMount: PropTypes.bool,
  isLoading: PropTypes.bool,
  fields: PropTypes.array,
  modalStateWidget: PropTypes.object,
  classes: PropTypes.object
};

const isEmptyArray = maybeArray =>
  Array.isArray(maybeArray) && maybeArray.length === 0;

const getValue = (widgetValue, modelValue, defaultValue) => {
  // always return the model value as this is whats hot in the view
  if (!modelValue && !widgetValue) return defaultValue;
  if (modelValue && modelValue !== widgetValue) return modelValue;
  if (!widgetValue || isEmptyArray(widgetValue)) return widgetValue;
  if (isNaN(widgetValue)) {
    return widgetValue;
  } else {
    return Number(widgetValue);
  }
};

const _getResourceName = R.pipe(R.defaultTo(""), R.split("/"), R.last);
const getResourceIdName = (editingWidget, ownerPropsResource = {}) => {
  const resourceId =
    ownerPropsResource.id || resourceIdFromWidget(editingWidget);
  const resourceName = _getResourceName(resourceId);
  return { resourceName, resourceId };
};

const makeMapStateToProps = () => (state, props) => {
  const params = props.params ? props.params : {};
  const location = newLocation(props.location, params);
  const editingThingType = thingTypeSelectors.getEditingThingType(state);
  const {
    widgetKeypath,
    resourceKeypath,
    virtualResourcesKeyPath
  } = thingTypeSelectors.getKeypaths(
    params,
    location.dashboardType,
    editingThingType,
    ["editingThingType", "viewModes"]
  );
  const thingName = location.params.thingName;
  const domainId = thingSelectors.getDomainId(state.thing, thingName);
  const isLoading = loading.isLoading(state);
  let extraResources;
  let editingWidget;
  let widgetKeypathWithId;
  let hasUndoWidgets;
  if (!isLoading) {
    extraResources = getEditingWidgetExtraResources(state, thingName);

    const sourceWidget = props.widget || props.modalStateWidget.widget;

    editingWidget = fromJS({
      type: sourceWidget.type,
      label: sourceWidget.label,
      widgetId: sourceWidget.widgetId,
      ...sourceWidget.props
    });
    widgetKeypathWithId = [...widgetKeypath, widgetId(sourceWidget)];
    hasUndoWidgets = thingTypeSelectors.hasUndoWidgets(state);
  }

  const resourceId = resourceIdFromWidget(editingWidget);
  const resourceName = _getResourceName(resourceId);
  const getResourceUnit = id => resourceUnitById(state, { id });
  const resourceUnit = getResourceUnit(resourceId);

  const resourceIsSettable = id => resourceIsSettableById(state, { id });

  return {
    widgetKeypath,
    resourceKeypath,
    extraResources,
    editingThingType,
    editingWidget,
    virtualResourcesKeyPath,
    location,
    widgetKeypathWithId,
    isLoading,
    domainId,
    hasUndoWidgets,
    resourceId,
    resourceName,
    resourceUnit,
    getResourceUnit,
    resourceIsSettable,
    updateEditModeWidget: props.updateEditModeWidget
  };
};

const withLifecycle = lifecycle({
  componentDidMount() {
    if (this.props.domainId) {
      this.props.fetchDomainMetadata(this.props.domainId);
    }
  },
  componentDidUpdate(prevProps) {
    const {
      type,
      domainId,
      fetchDomainMetadata,
      setForceMount,
      forceMount
    } = this.props;
    if (type !== prevProps.type) {
      setForceMount(true);
    } else if (forceMount) {
      setForceMount(false);
    }

    // domain meta data, domainId is set when the thingShadow is set
    if (!prevProps.domainId && domainId) {
      fetchDomainMetadata(domainId);
    }
  }
});

const withPropsOnChangeCheck = (props, nextProps) => {
  return (
    (props.domainId === undefined && nextProps.domainId !== undefined) ||
    props.type !== nextProps.type ||
    props.resourceUnit !== nextProps.resourceUnit ||
    props.resourceId !== nextProps.resourceId ||
    props.resourceName !== nextProps.resourceName ||
    !imu.equals(props.resource, nextProps.resource) ||
    props.isSetable !== nextProps.isSetable ||
    props.numberOfDecimals !== nextProps.numberOfDecimals ||
    props.isRealtime !== nextProps.isRealtime ||
    props.isObservationsAggregated !== nextProps.isObservationsAggregated ||
    props.forceMount !== nextProps.forceMount ||
    props.customProps !== nextProps.customProps ||
    props.beforeDone !== nextProps.beforeDone ||
    (props.isLoading !== nextProps.isLoading &&
      (nextProps.domainId !== undefined ||
        nextProps.location.dashboardType === "collection"))
  );
};

export const WidgetEditContainer = compose(
  connect(makeMapStateToProps, (dispatch, { closeModalWidget }) => {
    const onDone = (
      editingThingType,
      editingWidget,
      widgetKeypathWithId,
      virtualResourcesKeyPath,
      customProps,
      defaults,
      resource,
      type,
      updateEditModeWidget
    ) => model => {
      // remove all props
      editingWidget = editingWidget.merge({
        ...model
      });
      if (resource) {
        editingWidget = editingWidget.merge({
          resourceName: resource.id
        });
      }
      // add any custom content
      editingWidget = customProps
        ? editingWidget.merge(customProps)
        : editingWidget;
      // set defualt values if null

      if (defaults) {
        editingWidget = Object.keys(defaults).reduce(
          (ew, key) =>
            isEmptyOrNil(editingWidget.get(key))
              ? editingWidget.set(key, defaults[key])
              : editingWidget,
          editingWidget
        );
      }
      if (resource) {
        editingWidget = editingWidget.merge({
          resourceName: resource.id
        });
        if (type === "Histogram") {
          editingWidget = editingWidget.merge({
            histogramResource: resource.id
          });
        }
      }

      const updatedWidget = R.pipe(
        imu.unImmute,
        R.applySpec({
          widgetId: R.prop("widgetId"),
          label: R.prop("label"),
          type: R.prop("type"),
          props: R.omit(["widgetId", "label", "type", "props"])
        })
      )(editingWidget);

      updateEditModeWidget(updatedWidget);
      closeModalWidget(true);
    };

    const fetchDomainMetadata = domainId => dispatch(getDomain({ domainId }));
    return {
      onDone,
      fetchDomainMetadata
    };
  }),
  // this should change to with reducer
  withState("type", "setWidgetType", undefined),
  withState("model", "setModel", undefined),
  withState("resource", "setResource", undefined),
  withState("forceMount", "setForceMount", undefined),
  withState("customProps", "setCustomProps", Map()),
  withState("beforeDone", "setBeforeDone", { do: () => ({}) }),
  withState("isSetable", "setIsSetable", ({ editingWidget }) =>
    widgetProp(editingWidget, "isSetable")
  ),
  withState("numberOfDecimals", "setNumberOfDecimals", ({ editingWidget }) =>
    widgetProp(editingWidget, "numberOfDecimals")
  ),
  withState("isRealtime", "setIsRealtime", ({ editingWidget }) =>
    widgetProp(editingWidget, "isRealtime")
  ),
  withState(
    "isObservationsAggregated",
    "setIsObservationsAggregated",
    ({ editingWidget }) => widgetProp(editingWidget, "isObservationsAggregated")
  ),
  withHandlers({
    onFormChange: ({ setWidgetType, setModel }) => event => {
      const { changedField, model } = event;
      setModel(model);
      if (changedField.id === "type") setWidgetType(changedField.value);
    },
    handleCancel: ({ handleCancelWidget, modalStateWidget }) => () => {
      const { widget, isNewWidget } = modalStateWidget;
      handleCancelWidget({ widget, isNewWidget });
    },
    onResourceChange: ({ setResource }) => ({ value, event }) => {
      if (event) event.preventDefault();
      setResource(value);
    },
    onNumberOfDecimalsChange: ({ setNumberOfDecimals }) => ({ value }) => {
      setNumberOfDecimals(value);
    },
    onIsSetableChange: ({ setIsSetable }) => event => setIsSetable(event.value),
    onIsRealtimeChange: ({ setIsRealtime }) => event =>
      setIsRealtime(event.value),
    onIsObservationsAggregatedChange: ({
      setIsObservationsAggregated
    }) => event => setIsObservationsAggregated(event.value),
    handleUpdateCustomProps: ({ setCustomProps }) => val =>
      setCustomProps(Map(val))
  }),
  withPropsOnChange(
    (props, nextProps) => withPropsOnChangeCheck(props, nextProps),
    ownerProps => {
      const { editingWidget = Map(), location, model = {} } = ownerProps;
      const type = ownerProps.type || imu.get(editingWidget, "type");

      const { resourceId, resourceName } = getResourceIdName(
        editingWidget,
        ownerProps.resource
      );
      const resourceUnit = ownerProps.getResourceUnit(resourceId);
      const newProps = {
        ...ownerProps,
        resourceId,
        resourceName,
        resourceUnit,
        typeOptions: widgetTypes[location.dashboardType]
      };

      // this is the starting point of the composition chain.
      const form = ComposeForm(WidgetEditFragment, getEditForm(type))(newProps);
      let nInitialModel = Object.keys(form.initialModel).reduce(
        (im, prop) => ({
          ...im,
          [prop]: getValue(
            imu.unImmute(editingWidget.get(prop)),
            model[prop],
            form.initialModel[prop]
          )
        }),
        {}
      );
      nInitialModel = {
        ...nInitialModel,
        type
      };
      if (nInitialModel.hasOwnProperty("resourceName") && resourceName) {
        nInitialModel = {
          ...nInitialModel,
          resourceName
        };
      }
      if (nInitialModel.hasOwnProperty("resourceId") && resourceId) {
        nInitialModel = {
          ...nInitialModel,
          resourceId
        };
      }
      return {
        ...ownerProps,
        model: nInitialModel,
        resourceId,
        resourceName,
        resourceUnit,
        fields: form.fields,
        initialModel: nInitialModel,
        schema: Joi.object().keys(form.schema),
        defaults: form.defaults
      };
    }
  ),
  withHandlers({
    handleDone: ({
      editingThingType,
      editingWidget,
      widgetKeypathWithId,
      virtualResourcesKeyPath,
      onDone,
      customProps,
      defaults,
      beforeDone,
      resource,
      type,
      updateEditModeWidget,
      ...rest
    }) => model => {
      customProps = customProps.merge(beforeDone.do());
      onDone(
        editingThingType,
        editingWidget,
        widgetKeypathWithId,
        virtualResourcesKeyPath,
        customProps,
        defaults,
        resource,
        type,
        updateEditModeWidget
      )(model);
    }
  }),
  shouldUpdate((props, nextProps) => {
    if (props.customProps !== nextProps.customProps) {
      return false;
    }
    return true;
  }),
  pure
);

export const WidgetEditContainerWithForm = WidgetEditContainer(
  WidgetFormWrapper
);

export default withRouter(WidgetEditContainer(withLifecycle(WidgetEditDialog)));
