import {
  format as formatDate,
  formatDistanceToNow,
  isWithinInterval,
  sub as dateSubtract
} from "date-fns";
import { mqttSubscribe, mqttUnsubscribe } from "ducks/mqtt";
import { fromJS, List } from "immutable";
import * as R from "ramda";
import { createAction, handleActions } from "redux-actions";
import { eventTopic } from "utils/mqtt_utils";

export const EVENTS_STREAM_ON_RECEPTION = "EVENTS_STREAM_ON_RECEPTION";

const TOGGLE_ONLY_ACKNOWLEDGE = "TOGGLE_ONLY_ACKNOWLEDGE";

export const EVENTS_NEW_REQUEST = "EVENTS_NEW_REQUEST";
export const EVENTS_NEW_SUCCESS = "EVENTS_NEW_SUCCESS";

export const EVENTS_NEEDS_ACKNOWLEDGMENT_REQUEST =
  "EVENTS_NEEDS_ACKNOWLEDGMENT_REQUEST";
export const EVENTS_NEEDS_ACKNOWLEDGMENT_SUCCESS =
  "EVENTS_NEEDS_ACKNOWLEDGMENT_SUCCESS";

export const EVENTS_WIDGET_REQUEST = "EVENTS_WIDGET_REQUEST";
export const EVENTS_WIDGET_SUCCESS = "EVENTS_WIDGET_SUCCESS";
export const EVENTS_WIDGET_FAILURE = "EVENTS_WIDGET_FAILURE";

export const EVENTS_SEARCH_REQUEST = "EVENTS_SEARCH_REQUEST";
export const EVENTS_SEARCH_SUCCESS = "EVENTS_SEARCH_SUCCESS";

export const ACKNOWLEDGE_REQUEST = "ACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_SUCCESS = "ACKNOWLEDGE_SUCCESS";

export const subscribeToEventsStream = domainPath =>
  mqttSubscribe({ topic: eventTopic(domainPath) });

export const unsubscribeFromEventsStream = domainPath =>
  mqttUnsubscribe({ topic: eventTopic(domainPath) });

export const eventStreamReception = createAction(EVENTS_STREAM_ON_RECEPTION);

export const fetchEventsThatNeedsAcknowledgment = createAction(
  EVENTS_NEEDS_ACKNOWLEDGMENT_REQUEST
);
export const fetchEventsThatNeedsAcknowledgmentSuccess = createAction(
  EVENTS_NEEDS_ACKNOWLEDGMENT_SUCCESS
);

export const fetchNewEvents = createAction(EVENTS_NEW_REQUEST);
export const fetchNewEventsSuccess = createAction(EVENTS_NEW_SUCCESS);

export const fetchWidgetEvents = createAction(EVENTS_WIDGET_REQUEST);
export const fetchWidgetEventsSuccess = createAction(EVENTS_WIDGET_SUCCESS);
export const fetchWidgetEventsFailure = createAction(EVENTS_WIDGET_FAILURE);

export const searchForEvents = createAction(EVENTS_SEARCH_REQUEST);
export const searchForEventsSuccess = createAction(EVENTS_SEARCH_SUCCESS);

export const acknowledge = createAction(ACKNOWLEDGE_REQUEST);
export const acknowledgeSuccess = createAction(ACKNOWLEDGE_SUCCESS);

const _isNullOrEmpty = R.either(R.isNil, R.isEmpty);

const initialState = fromJS({
  isLoading: false,
  error: null,
  onlyShowAcknowledge: false,
  entities: {},
  lists: {
    new: {
      ids: [],
      total: 0,
      isLoading: false
    },
    needsAcknowledgment: {
      ids: [],
      total: 0,
      isLoading: false
    },
    byThingTypeAndClassification: {},
    byThingNameAndClassification: {},
    search: []
  }
});

// Helpers

const increaseTotalIn = (state, listKeyPath, increment) => {
  return state.setIn(
    [...listKeyPath, "total"],
    state.getIn([...listKeyPath, "total"]) + increment
  );
};

const insertIdFirstIn = (state, listKeyPath, id) => {
  const list = state.getIn([...listKeyPath, "ids"]);
  if (list) {
    const newState = state.setIn([...listKeyPath, "ids"], list.insert(0, id));
    return increaseTotalIn(newState, listKeyPath, 1);
  } else {
    return state;
  }
};

const doesDataMatchPeriodAndFilter = (data, period, filter) => {
  if (!filter) return false;
  if (period) {
    let to =
      period && period.get("to") ? new Date(period.get("to")) : new Date();

    let from =
      period && period.get("from")
        ? new Date(period.get("from"))
        : dateSubtract(new Date(), { days: 1 });

    if (!isWithinInterval(new Date(data.timestamp), { start: from, end: to })) {
      return false;
    }
  }
  if (filter) {
    const domains = filter.getIn(["domainsDropDown", "ids"]);
    if (domains && domains.size && !domains.contains(data.source.domain)) {
      return false;
    }
    const thingTypes = filter.getIn(["thingTypesDropDown", "names"]);
    if (
      thingTypes &&
      thingTypes.size &&
      !thingTypes.contains(data.source.thingType)
    ) {
      return false;
    }
    const freeText = filter
      .get("freeText", List())
      .filter(text => text.length > 0);
    const message = data.message.toLowerCase();
    if (
      freeText.size &&
      !freeText.find(text => message.indexOf(text.toLowerCase()) !== -1)
    ) {
      return false;
    }
  }
  return true;
};

const insertIdFirstInListIfDataMatchPeriodAndFilter = (
  state,
  listKeyPath,
  data
) => {
  let newState = state;
  let listState = newState.getIn(listKeyPath);
  if (listState) {
    // Get filter and period, try ALL if classification is not available
    // (they will contain the same filter if both available).
    let filter =
      listState.getIn([data.classification, "filter"]) ||
      (data.classification !== "INTERNAL" &&
        listState.getIn(["ALL", "filter"]));
    // we didn't get an internal filter but we have a filter, which is probably all
    // as a result just get the first filter
    filter =
      !filter && listState.size > 0 ? listState.first().get("filter") : filter;

    const period =
      listState.getIn([data.classification, "period"]) ||
      (data.classification !== "INTERNAL" &&
        listState.getIn(["ALL", "period"]));
    if (doesDataMatchPeriodAndFilter(data, period, filter)) {
      newState = insertIdFirstIn(
        newState,
        [...listKeyPath, data.classification],
        data.id
      );
      if (data.classification !== "INTERNAL") {
        newState = insertIdFirstIn(newState, [...listKeyPath, "ALL"], data.id);
      }
    }
  }
  return newState;
};

export const toggleOnlyAcknowledged = () => ({
  type: TOGGLE_ONLY_ACKNOWLEDGE
});

export const formatTimestamps = R.pipe(
  R.omit(["_action"]),
  R.evolve({
    acknowledgedAt: t => formatDistanceToNow(new Date(t), { addSuffix: true }),
    timestamp: t => formatDate(new Date(t), "MMM d, yyyy h:mm a")
  })
);

export default handleActions(
  {
    [EVENTS_STREAM_ON_RECEPTION]: (state, { payload }) => {
      const eventAction = payload.data._action;
      const data = R.omit(["_action"], payload.data);

      const {
        id,
        classification,
        source: { thingName, thingType }
      } = data;

      let newState = state;

      if (eventAction === "acknowledge") {
        if (!newState.getIn(["entities", String(id), "acknowledgedBy"], null)) {
          newState = increaseTotalIn(
            newState,
            ["lists", "needsAcknowledgment"],
            -1
          );
        }

        if (
          newState.getIn(["lists", "needsAcknowledgment", "ids"]).contains(id)
        ) {
          const list =
            newState.getIn(["lists", "needsAcknowledgment", "ids"]) ||
            fromJS([]);
          newState = newState.setIn(
            ["lists", "needsAcknowledgment", "ids"],
            list.remove(list.indexOf(id))
          );
        }
      } else if (
        !eventAction &&
        data.acknowledgmentRequired &&
        !data.acknowledgedBy
      ) {
        newState = insertIdFirstIn(
          newState,
          ["lists", "needsAcknowledgment"],
          id
        );
      }

      const formatted = formatTimestamps(data);
      if (newState.get("entities").has(String(id))) {
        newState = newState.mergeIn(["entities", String(id)], formatted);
      } else {
        // Add the event to entities.
        newState = newState.mergeIn(["entities"], { [id]: formatted });

        // Add the id to the relevant lists.
        if (
          !data.acknowledgmentRequired &&
          data.classification !== "INTERNAL"
        ) {
          newState = insertIdFirstIn(newState, ["lists", "new"], id);
        }
        if (thingName && classification) {
          newState = insertIdFirstInListIfDataMatchPeriodAndFilter(
            newState,
            ["lists", "byThingNameAndClassification", thingName],
            data
          );
        }
        if (thingType && classification) {
          newState = insertIdFirstInListIfDataMatchPeriodAndFilter(
            newState,
            ["lists", "byThingTypeAndClassification", thingType],
            data
          );
        }
        if (classification) {
          newState = insertIdFirstInListIfDataMatchPeriodAndFilter(
            newState,
            ["lists", "allThings"],
            data
          );
        }
      }
      return newState;
    },

    [EVENTS_NEEDS_ACKNOWLEDGMENT_REQUEST]: (state, action) => {
      return state.setIn(["lists", "needsAcknowledgment", "isLoading"], true);
    },

    [EVENTS_NEEDS_ACKNOWLEDGMENT_SUCCESS]: (state, { payload }) => {
      return state
        .setIn(
          ["lists", "needsAcknowledgment"],
          fromJS({
            ids: payload.result,
            total: payload.total,
            isLoading: false
          })
        )
        .mergeDeep({
          entities: payload.entities.events
        });
    },

    [EVENTS_NEW_REQUEST]: (state, action) =>
      state.setIn(["lists", "new", "isLoading"], true),

    [EVENTS_NEW_SUCCESS]: (state, { payload }) =>
      state
        .setIn(
          ["lists", "new"],
          fromJS({
            ids: payload.result,
            total: payload.total,
            isLoading: false
          })
        )
        .mergeDeep({
          entities: payload.entities.events
        }),

    [EVENTS_WIDGET_REQUEST]: (state, { payload }) => {
      const { classification, thingName, thingType } = payload;
      if (thingName && classification) {
        return state.setIn(
          [
            "lists",
            "byThingNameAndClassification",
            thingName,
            classification,
            "isLoading"
          ],
          true
        );
      } else if (thingType && classification) {
        return state.setIn(
          [
            "lists",
            "byThingTypeAndClassification",
            thingType,
            classification,
            "isLoading"
          ],
          true
        );
      } else if (
        _isNullOrEmpty(thingType) &&
        _isNullOrEmpty(thingName) &&
        classification
      ) {
        return state.setIn(
          ["lists", "allThings", classification, "isLoading"],
          true
        );
      } else {
        return state;
      }
    },

    [EVENTS_WIDGET_SUCCESS]: (
      state,
      { payload: { requestPayload, normalizedResponse } }
    ) => {
      const {
        period,
        classification,
        thingName,
        thingType,
        filter
      } = requestPayload;
      const { entities, result, total } = normalizedResponse;
      if (thingName && classification) {
        // If thing name is available in the payload it means it's a thing details widget.
        return state
          .setIn(
            [
              "lists",
              "byThingNameAndClassification",
              thingName,
              classification
            ],
            fromJS({
              ids: result,
              total: total,
              isLoading: false,
              filter,
              period
            })
          )
          .mergeDeep({
            entities: entities.events
          });
      } else if (thingType && classification) {
        // If no thing name is available in the payload but thing type is it means it's a collection widget.
        return state
          .setIn(
            [
              "lists",
              "byThingTypeAndClassification",
              thingType,
              classification
            ],
            fromJS({
              ids: result,
              total: total,
              isLoading: false,
              filter,
              period
            })
          )
          .mergeDeep({
            entities: entities.events
          });
      } else if (
        _isNullOrEmpty(thingType) &&
        _isNullOrEmpty(thingName) &&
        classification
      ) {
        // If no thing name is available in the payload but thing type is it means it's a collection widget.
        return state
          .setIn(
            ["lists", "allThings", classification],
            fromJS({
              ids: result,
              total: total,
              isLoading: false,
              filter,
              period
            })
          )
          .mergeDeep({
            entities: entities.events
          });
      } else {
        return state;
      }
    },

    [EVENTS_SEARCH_REQUEST]: (state, action) =>
      state.mergeDeep({ isLoading: true }),

    [EVENTS_SEARCH_SUCCESS]: (state, { payload }) =>
      state.setIn(["lists", "search"], fromJS(payload.result)).mergeDeep({
        isLoading: false,
        entities: payload.entities.events
      }),

    [TOGGLE_ONLY_ACKNOWLEDGE]: (state, action) => {
      return state.set(
        "onlyShowAcknowledge",
        !state.get("onlyShowAcknowledge")
      );
    }
  },
  initialState
);

// <---- Selectors ---->

export const getEventById = (events, id) =>
  events.get("entities").get(String(id));

export const getNewEvents = events => {
  let ids = events.getIn(["lists", "new", "ids"]);
  return ids.map(id => getEventById(events, id));
};

export const getNewEventsTotal = events => {
  return events.getIn(["lists", "new", "total"]);
};

export const getEventsThatNeedsAcknowledgment = events => {
  let ids = events.getIn(["lists", "needsAcknowledgment", "ids"]);
  return ids.map(id => getEventById(events, id));
};

export const getEventsThatNeedsAcknowledgmentTotal = events => {
  return events.getIn(["lists", "needsAcknowledgment", "total"]);
};

export const getWidgetEvents = (
  events,
  classification,
  thingName,
  thingType
) => {
  let ids;
  if (thingName && classification) {
    ids = events.getIn(
      [
        "lists",
        "byThingNameAndClassification",
        thingName,
        classification,
        "ids"
      ],
      fromJS([])
    );
  } else if (thingType && classification) {
    ids = events.getIn(
      [
        "lists",
        "byThingTypeAndClassification",
        thingType,
        classification,
        "ids"
      ],
      fromJS([])
    );
  } else if (
    _isNullOrEmpty(thingType) &&
    _isNullOrEmpty(thingName) &&
    classification
  ) {
    ids = events.getIn(
      ["lists", "allThings", classification, "ids"],
      fromJS([])
    );
  } else {
    ids = fromJS([]);
  }
  return ids.map(id => getEventById(events, id));
};

export const getWidgetEventsTotal = (
  events,
  classification,
  thingName,
  thingType
) => {
  if (thingName && classification) {
    return events.getIn(
      [
        "lists",
        "byThingNameAndClassification",
        thingName,
        classification,
        "total"
      ],
      0
    );
  } else if (thingType && classification) {
    return events.getIn(
      [
        "lists",
        "byThingTypeAndClassification",
        thingType,
        classification,
        "total"
      ],
      0
    );
  } else {
    return 0;
  }
};

export const needsAcknowledgment = event => {
  return (
    event.get("acknowledgmentRequired") &&
    (!event.has("acknowledgedBy") || event.get("acknowledgedBy") === null)
  );
};

export const isUnread = (event, lastEventReadTimestamp) => {
  if (event.get("acknowledgmentRequired")) {
    return !event.has("acknowledgedBy") || event.get("acknowledgedBy") === null;
  } else {
    return event.get("timestamp") > lastEventReadTimestamp;
  }
};

export const getEventsFromSearch = (events, ackNeeded) => {
  const ids = events.getIn(["lists", "search"]);
  return ids
    .map(id => getEventById(events, id))
    .filter(event => (ackNeeded ? needsAcknowledgment(event) : event));
};
