import Joi from "joi-browser";

export const OPTION_NAME = "aggregationType";
export const AVERAGE = "avg";
export const SUM = "sum";
export const COUNT = "value_count";

const options = [
  {
    id: "aggregation_option_average",
    label: "Average",
    value: AVERAGE
  },
  {
    id: "aggregation_option_sum",
    label: "Sum",
    value: SUM
  },
  {
    id: "aggregation_option_count",
    label: "Count",
    value: COUNT
  }
];

export default () => ({
  fields: [
    {
      hintText: "Aggregation type",
      id: OPTION_NAME,
      label: "Aggregation type",
      name: OPTION_NAME,
      options,
      type: "select"
    }
  ],
  initialModel: {
    [OPTION_NAME]: AVERAGE
  },
  schema: {
    [OPTION_NAME]: Joi.string().required()
  }
});
