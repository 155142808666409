import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { compose, withHandlers, withState } from "react-recompose";
import ExpandableArrowIcon from "./styled/expandable_arrow_icon";
import HeaderIcon from "./styled/header_icon";
import HeaderLabel from "./styled/header_label";
import HeaderWrapper from "./styled/header_wrapper";

const ICON_SIZE = "48px";

const TelenorCardHeader = ({
  style,
  label,
  icon,
  hasExpandable,
  isExpanded,
  children,
  toggleExpanded,
  "data-test": dataTest
}) => {
  const theme = useTheme();
  return (
    <HeaderWrapper
      data-test={dataTest}
      hasExpandable={hasExpandable}
      iconSize={ICON_SIZE}
      onClick={toggleExpanded}
    >
      {icon && <HeaderIcon iconSize={ICON_SIZE}>{icon}</HeaderIcon>}
      <HeaderLabel
        muiTheme={theme}
        style={style}
        data-test={`${dataTest}-label`}
      >
        {label}
      </HeaderLabel>
      {children}
      {hasExpandable && (
        <IconButton onClick={toggleExpanded}>
          <ExpandableArrowIcon isExpanded={isExpanded} />
        </IconButton>
      )}
    </HeaderWrapper>
  );
};

TelenorCardHeader.propTypes = {
  label: PropTypes.node,
  icon: PropTypes.node,
  // Don't change this to expandable because mui card will auto hide the header then
  hasExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  toggleExpanded: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  theme: PropTypes.object,
  "data-test": PropTypes.string
};
TelenorCardHeader.defaultProps = {
  setExpanded: () => {}
};
TelenorCardHeader.contextTypes = {
  muiTheme: PropTypes.object
};

export default compose(
  withState("isExpanded", "updateExpanded", ({ isExpanded }) =>
    Boolean(isExpanded)
  ),
  withHandlers({
    toggleExpanded: props => ev => {
      if (props.hasExpandable) {
        ev && ev.preventDefault && ev.preventDefault();
        ev && ev.stopPropagation && ev.stopPropagation();
        const newIsExpanded = !props.isExpanded;
        props.updateExpanded(newIsExpanded);
        props.setExpanded(newIsExpanded);
      }
    }
  })
)(TelenorCardHeader);
