import { graphql } from "@apollo/client/react/hoc";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";
import { Dialog } from "components/telenor/dialog";
import { userNameSelector } from "ducks/auth/auth_selectors";
import { Field, Form, ModalFormButtons } from "forms_new";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import {
  CREATE_THING_TYPE_VIEWMODE_MUTATION,
  DEFAULT_THINGTYPE_VIEWMODE_QUERY,
  THING_TYPE_VIEWMODES_QUERY,
  UPDATE_VIEW_MODE_MUTATION
} from "graphql/queries";
import Joi from "joi-browser";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withHandlers, withStateHandlers } from "react-recompose";
import { compose } from "redux";
import { replaceViewMode } from "utils/path_utils";

const makeInitialState = ({
  modalStateViewModeCreateEdit,
  dashboardViewMode
}) => {
  return modalStateViewModeCreateEdit.isNew
    ? {
        label: "",
        description: "",
        domain: "",
        is_personal: "true",
        is_default: false,
        copy_grid_widgets: true
      }
    : {
        label: R.propOr("", "label", dashboardViewMode),
        description: R.propOr("", "description", dashboardViewMode),
        domain: R.propOr("", "domain", dashboardViewMode),
        is_default: R.propOr(false, "isDefault", dashboardViewMode),
        is_personal: R.propOr(false, "isPersonal", dashboardViewMode).toString()
      };
};

const ViewmodeSchema = Joi.object().keys({
  label: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Dashboard name is required" }
      }
    }),
  description: Joi.string()
    .empty("")
    .allow(null),
  is_default: Joi.boolean(),
  is_personal: Joi.string(),
  domain: Joi.string().when("is_personal", {
    is: "false",
    then: Joi.required(),
    otherwise: Joi.empty("").allow(null)
  }),
  copy_grid_widgets: Joi.boolean()
});

const isPersonalOptions = [
  { id: "true", label: "Personal dashboard" },
  { id: "false", label: "Domain dashboard" }
];

const getDialogTitle = ({ modalStateViewModeCreateEdit, dashboardViewMode }) =>
  modalStateViewModeCreateEdit.isNew
    ? "Create new dashboard"
    : `Edit ${R.propOr("dashboard", "label", dashboardViewMode)}`;

const ViewmodeEdit = ({
  initialModel,
  onCancel,
  onSave,
  cancelButtonText,
  saveButtonText,
  isSaving,
  localFormState,
  setLocalFormState,
  modalStateViewModeCreateEdit,
  dashboardViewMode
}) => {
  const isPersonal = localFormState.model.is_personal === "true";
  return (
    <Dialog
      onClose={onCancel}
      title={getDialogTitle({
        modalStateViewModeCreateEdit,
        dashboardViewMode
      })}
      className="data-test-confirm-modal"
    >
      <Form
        schema={ViewmodeSchema}
        initialModel={initialModel}
        onSubmit={onSave}
        onChange={setLocalFormState}
      >
        <Field id="label" label="Dashboard name" />
        <Field id="description" label="Dashboard description" />
        <Field
          id="is_personal"
          type="radio_group"
          label="Personal dashboard"
          options={isPersonalOptions}
        />
        {!isPersonal && (
          <Field
            id="domain"
            type="autocomplete"
            label="Domain"
            suggesterType={DATA_TYPES.DOMAINS}
          />
        )}
        <Field
          id="is_default"
          type="toggle"
          style={{}}
          label={`Make ${isPersonal ? "personal" : "domain"} default`}
        />
        {modalStateViewModeCreateEdit.isNew && (
          <Field
            id="copy_grid_widgets"
            type="toggle"
            style={{}}
            label={`Copy widget grid`}
          />
        )}

        <ModalFormButtons>
          <FormCancelButton
            text={cancelButtonText ? cancelButtonText : `Cancel`}
            onClick={onCancel}
            disabled={isSaving}
          />
          <FormSaveButton
            text={saveButtonText ? saveButtonText : `Save`}
            data-test="confirm"
            isLoading={isSaving}
          />
        </ModalFormButtons>
      </Form>
    </Dialog>
  );
};

ViewmodeEdit.propTypes = {
  initialModel: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  infoText: PropTypes.string,
  infoTextTitle: PropTypes.string,
  cancelButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  setIsMovingWidgets: PropTypes.func,
  isSaving: PropTypes.bool,
  dashboardViewMode: PropTypes.object,
  localFormState: PropTypes.object,
  setLocalFormState: PropTypes.func,
  modalStateViewModeCreateEdit: PropTypes.object
};

const mapStateToProps = (
  state,
  {
    dashboardThingTypeQuery,
    dashboardViewMode,
    cancelButtonText,
    saveButtonText,
    modalStateViewModeCreateEdit
  }
) => {
  return {
    username: userNameSelector(state),
    initialModel: makeInitialState({
      dashboardViewMode,
      modalStateViewModeCreateEdit
    }),
    cancelButtonText,
    saveButtonText,
    dashboardViewMode,
    thingTypeDomain: R.path(["thingType", "domain"], dashboardThingTypeQuery)
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  graphql(CREATE_THING_TYPE_VIEWMODE_MUTATION, {
    name: "createViewModeMutation"
  }),
  graphql(UPDATE_VIEW_MODE_MUTATION, { name: "updateViewMode" }),
  withStateHandlers(
    props => {
      return {
        isSaving: false,
        localFormState: {
          model: makeInitialState(props)
        }
      };
    },
    {
      setIsSaving: state => isSaving => ({ ...state, isSaving }),
      setLocalFormState: state => formState => ({
        ...state,
        localFormState: formState
      })
    }
  ),
  withHandlers({
    onCancel: props => () => {
      props.closeModalViewModeCreateEdit();
    },
    onSave: props => model => {
      props.setIsSaving(true);
      const thingType = R.path(["router", "params", "thingType"], props);
      const isPersonal = model.is_personal === "true";
      const variables = {
        viewModeId: props.dashboardViewMode.id,
        label: model.label,
        description: model.description,
        isPersonal: isPersonal ? true : false,
        domain: isPersonal ? null : model.domain,
        isDefault: model.is_default,
        thingType
      };

      const _handleMutationSuccess = mutationName => response => {
        const newViewModeId = R.path(
          ["data", mutationName, "viewMode", "id"],
          response
        );
        props.closeModalViewModeCreateEdit();

        props.router.push({
          pathname: replaceViewMode(
            props.router.location.pathname,
            newViewModeId
          )
        });
      };

      const _handleMutationDone = () => {
        props.setIsSaving(false);
      };

      const refetchQueries = [
        {
          query: THING_TYPE_VIEWMODES_QUERY,
          variables: { thingType }
        },
        {
          query: DEFAULT_THINGTYPE_VIEWMODE_QUERY,
          variables: { thingType, preferredViewModeId: "DefaultView" }
        }
      ];

      if (props.modalStateViewModeCreateEdit.isNew) {
        props
          .createViewModeMutation({
            variables: {
              ...R.omit("viewModeId", variables),
              grids: model.copy_grid_widgets
                ? props.dashboardViewMode.grids
                : []
            },
            awaitRefetchQueries: true,
            refetchQueries
          })
          .then(_handleMutationSuccess("createViewMode"))
          .finally(_handleMutationDone);
      } else {
        props
          .updateViewMode({ variables, refetchQueries })
          .then(_handleMutationSuccess("updateViewMode"))
          .finally(_handleMutationDone);
      }
    }
  })
)(ViewmodeEdit);
