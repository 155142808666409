import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  progress: {
    marginRight: theme.spacing(1)
  }
}));

const StyledFormSaveButton = ({ text, isLoading, disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <Button disabled={isLoading || disabled} {...rest}>
      {isLoading && <CircularProgress size={16} className={classes.progress} />}
      {text}
    </Button>
  );
};

// Work-around to support the `cloneRecursively()` function in forms_new:
// The props of withStyles(styles)(Comp) is not visible to the cloneRecursively-
// function. We need to have a "simpler" component as the recipient of the props.
const FormSaveButton = props => <StyledFormSaveButton {...props} />;

StyledFormSaveButton.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

FormSaveButton.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

FormSaveButton.defaultProps = {
  "data-test": "save",
  text: "Save",
  type: "submit",
  color: "primary"
};

export default FormSaveButton;
