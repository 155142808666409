import { graphql } from "@apollo/client/react/hoc";
import { adaptThingShadow } from "adapters/thing_adapter_normalized";
import { userCanCreateResource } from "ducks/schema/schema_selectors";
import { simulatorStartPublish, simulatorStopPublish } from "ducks/simulator";
import { setError } from "ducks/system";
import {
  closeStream,
  getThingDetailsSuccess,
  openStream,
  thingSelectors
} from "ducks/thing";
import { userCanPublish } from "ducks/thing/thing_selectors";
import { SIMULATOR_THING_QUERY } from "graphql/queries";
import { fromJS } from "immutable";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withPropsOnChange,
  withStateHandlers
} from "react-recompose";
import { defaultThingDetailsUrl } from "utils/route_utils";
import SimulatorContainer from "./simulator";

const DEFAULT_RESOURCES = [];
const DEFAULT_LABEL = "...";

export const enabledConfigsCount = R.pipe(
  R.propOr([], "resourceConfig"),
  R.filter(R.propEq("enabled", true)),
  R.length
);

export const isValid = (errors, payloadConfig, enabledCount) => {
  if (enabledCount === 0) {
    return false;
  }

  const hasErrorInEnabledConfig = R.pipe(
    R.keys,
    R.any(key => payloadConfig.resourceConfig[key].enabled)
  )(errors);

  return !hasErrorInEnabledConfig;
};

const _withStateHandlers = withStateHandlers(
  {
    payloadConfig: {
      period: { value: 10, unit: "seconds" },
      resourceConfig: []
    },
    errors: {},
    enabledConfigsCount: 0,
    isRunning: false,
    isValid: false
  },
  {
    onPayloadConfigChange: state => (payloadConfig, errors = state.errors) => {
      const enabledCount = enabledConfigsCount(payloadConfig);
      return {
        payloadConfig,
        errors,
        isValid: isValid(errors, payloadConfig, enabledCount),
        enabledConfigsCount: enabledCount
      };
    },

    toggleIsRunning: (
      { payloadConfig, isRunning },
      { simulatorStartPublish, simulatorStopPublish, simulatorThingQuery }
    ) => () => {
      if (isRunning) {
        simulatorStopPublish();
        simulatorThingQuery.refetch();
      } else {
        const { thingName, domainTopic } = simulatorThingQuery.thing;
        simulatorStartPublish({ thingName, domainTopic, payloadConfig });
      }
      return {
        payloadConfig,
        isRunning: !isRunning
      };
    }
  }
);

export const _withHandlers = withHandlers({
  navigateBack: ({ routeParams: { thingType, thingName }, router }) => () => {
    router.push(defaultThingDetailsUrl(router, { thingName, thingType }));
  }
});

export const _lifecycle = lifecycle({
  componentDidMount() {
    const { userCanPublish, setError, router } = this.props;
    if (!userCanPublish) {
      router.push("/");
      setError("You are not allowed to publish payloads for things");
    }
  },
  componentWillUnmount() {
    const { thingName, domainTopic } = R.pipe(
      R.pathOr({}, ["simulatorThingQuery", "thing"]),
      R.pick(["thingName", "domainTopic"])
    )(this.props);
    if (thingName) {
      this.props.simulatorStopPublish();
      this.props.closeStream({ thingName, domainPath: domainTopic });
    }
  }
});

const _withPropsOnChangeMQTTSubscribe = withPropsOnChange(
  (props, nProps) => {
    return (
      !R.hasPath(["simulatorThingQuery", "thing"], props) &&
      R.hasPath(["simulatorThingQuery", "thing"], nProps)
    );
  },
  ({ simulatorThingQuery, getThingDetailsSuccess, openStream }) => {
    if (
      R.prop("loading", simulatorThingQuery) ||
      !R.hasPath(["thing"], simulatorThingQuery)
    )
      return {};
    const { thingName, domainTopic, thingType } = simulatorThingQuery.thing;
    const thingShadow = fromJS(
      adaptThingShadow({
        thingName,
        thingType,
        ...R.path(["thing"])(simulatorThingQuery)
      })
    );

    getThingDetailsSuccess({ thingName, thingShadow });
    openStream({ thingName, domainPath: domainTopic });
  }
);

const _withPropsOnChangeConfigsToWithResources = withPropsOnChange(
  (props, nProps) => {
    return (
      R.pathOr(false, ["simulatorThingQuery", "loading"], props) &&
      !R.pathOr(false, ["simulatorThingQuery", "loading"], nProps)
    );
  },
  ({ payloadConfig, onPayloadConfigChange }) => {
    const resourceConfig = R.pipe(
      R.prop("resourceConfig"),
      R.map(
        R.evolve({
          hasResource: R.always(true)
        })
      )
    )(payloadConfig);
    onPayloadConfigChange({ ...payloadConfig, resourceConfig });
  }
);

const _mapStateToProps = (state, props) => {
  const {
    params: { thingName }
  } = props;
  const thingShadow = thingSelectors
    .getThingShadow(state.thing, thingName)
    .toJS();

  return {
    thingShadow,
    userCanPublish: userCanPublish(state),
    userCanCreateResource: userCanCreateResource(state)
  };
};
const _mapDispatchToProps = {
  simulatorStartPublish,
  simulatorStopPublish,
  openStream,
  closeStream,
  getThingDetailsSuccess,
  setError
};

const _withProps = withProps(({ simulatorThingQuery, thingShadow }) => {
  const thingName = R.pathOr("", ["thing", "thingName"], simulatorThingQuery);
  const thingType = R.pathOr(
    DEFAULT_LABEL,
    ["thing", "thingType"],
    simulatorThingQuery
  );
  const resources = R.pipe(
    R.pathOr(DEFAULT_RESOURCES, [
      "thing",
      "thingTypeConnection",
      "resourcesConnection",
      "resources"
    ]),
    R.map(r =>
      R.mergeDeepLeft(r, {
        metadata: {
          value: R.pathOr(null, [
            "resources",
            `${thingName}/${thingType}/${R.prop("name", r)}`,
            "latestValue",
            "value"
          ])(thingShadow)
        }
      })
    )
  )(simulatorThingQuery);

  return {
    allowedToBeSimulated: R.pathOr(
      false,
      ["thing", "simulated"],
      simulatorThingQuery
    ),
    isLoading: R.propOr(true, "loading", simulatorThingQuery),
    thingName: R.pathOr(DEFAULT_LABEL, ["thing", "label"], simulatorThingQuery),
    thingtypeLabel: R.pathOr(
      DEFAULT_LABEL,
      ["thing", "thingTypeConnection", "label"],
      simulatorThingQuery
    ),
    resources: resources
  };
});

export default compose(
  connect(_mapStateToProps, _mapDispatchToProps),
  withRouter,
  graphql(SIMULATOR_THING_QUERY, {
    name: "simulatorThingQuery",
    options: props => ({
      variables: { thingName: props.router.params.thingName },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    })
  }),
  _withStateHandlers,
  _withHandlers,
  _lifecycle,
  _withPropsOnChangeConfigsToWithResources,
  _withPropsOnChangeMQTTSubscribe,
  _withProps
)(SimulatorContainer);
