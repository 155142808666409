import React, { Component } from "react";
import PropTypes from "prop-types";
import { pipe, equals } from "ramda";
import MapBoxWrapper from "./styled/map_box_wrapper";
import * as mapUtils from "utils/map_utils";

const buildThingsMap = pipe(
  mapUtils.newMap,
  mapUtils.withNewCluster,
  mapUtils.withTooltip,
  mapUtils.withWorldView,
  mapUtils.withZoomControl,
  mapUtils.withTileLayer,
  mapUtils.withMarkerData
);

class ThingsMapWidget extends Component {
  componentDidMount() {
    const { mapId, data, gotoThingDetail } = this.props;

    const { map, onReceiveData } = buildThingsMap({
      markerOptions: { data, gotoThingDetail },
      mapId,
      mapOptions: {
        attributionControl: false,
        maxZoom: 18,
        minZoom: 2,
        scrollWheelZoom: false,
        trackResize: true,
        zoomControl: false
      }
    });

    this.map = map;
    this.onReceiveData = onReceiveData;
  }

  componentDidUpdate(prevProps) {
    const { data, gotoThingDetail, container } = this.props;
    if (this.onReceiveData && data && !data.equals(prevProps.data)) {
      this.onReceiveData({ data, gotoThingDetail });
    }

    if (this.map && container && !equals(container, prevProps.container)) {
      this.map.invalidateSize();
    }
  }

  render() {
    const { mapId } = this.props;
    return <MapBoxWrapper id={mapId} />;
  }
}

ThingsMapWidget.propTypes = {
  data: PropTypes.object, // List([{latLng: [0,1], properties: { thingName: 'foo' } }])
  mapId: PropTypes.string,
  container: PropTypes.object,
  gotoThingDetail: PropTypes.func
};

ThingsMapWidget.defaultProps = {
  gotoThingDetail: () => {}
};

export default ThingsMapWidget;
