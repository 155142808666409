import Joi from "joi-browser";

export const INTERVAL_NAME = "aggregationInterval";
export const intervalRegex = /^([1-9][0-9]*[hdms])?$/;

export default () => ({
  fields: [
    {
      hint:
        'E.g. "3h", "15m", "1d", "30s". Leave empty for the default "3h" (3 hours).',
      id: INTERVAL_NAME,
      label: "Aggregation interval",
      name: INTERVAL_NAME
    }
  ],
  initialModel: {
    [INTERVAL_NAME]: ""
  },
  schema: {
    [INTERVAL_NAME]: Joi.string()
      .regex(intervalRegex)
      .optional()
      .allow(["", null])
      .options({
        language: {
          string: {
            regex: {
              base:
                '!!Specify the interval with a number and a unit, for instance "2d" for 2 days. Supported units are d (days), h (hours), m (minutes) and s (seconds).'
            }
          }
        }
      })
  }
});
