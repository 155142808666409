import { createAction, handleActions } from "redux-actions";
import { fromJS } from "immutable";
import { thingUpdateTopic } from "utils/mqtt_utils";
import { adaptThingShadow } from "adapters/thing_adapter_normalized";
import { requestStatus } from "utils/request_status";
import { mqttPublish, mqttSubscribe, mqttUnsubscribe } from "ducks/mqtt";
import { CREATE_THINGS_BATCH_SUCCESS } from "ducks/batches";

export const THING_DETAILS_REQUEST = "THING_DETAILS_REQUEST";
export const THING_DETAILS_SUCCESS = "THING_DETAILS_SUCCESS";
const THING_DETAILS_FAILURE = "THING_DETAILS_FAILURE";

export const THING_STREAM_ON_RECEPTION = "THING_STREAM_ON_RECEPTION";
export const THING_CREATE_RECEIVED = "THING_CREATE_RECEIVED";

export const THING_IMAGE_UPLOAD_REQUEST = "THING_IMAGE_UPLOAD_REQUEST";
export const THING_IMAGE_UPLOAD_SUCCESS = "THING_IMAGE_UPLOAD_SUCCESS";
export const THING_IMAGE_UPLOAD_FAILURE = "THING_IMAGE_UPLOAD_FAILURE";

const CLEAR_THING_SHADOW = "CLEAR_THING_SHADOW";
const THING_CREATE_RESET = "THING_CREATE_RESET";

export const thingCreateReceived = createAction(THING_CREATE_RECEIVED);
export const thingStreamReception = createAction(THING_STREAM_ON_RECEPTION);

export const uploadThingImage = createAction(THING_IMAGE_UPLOAD_REQUEST);
export const uploadThingImageSuccess = createAction(THING_IMAGE_UPLOAD_SUCCESS);
export const uploadThingImageFailure = createAction(THING_IMAGE_UPLOAD_FAILURE);

export const getThingDetails = createAction(THING_DETAILS_REQUEST);

export const getThingDetailsSuccess = createAction(THING_DETAILS_SUCCESS);
export const onThingStreamReception = createAction(THING_STREAM_ON_RECEPTION);

export const openStream = ({ domainPath, thingName }) =>
  mqttSubscribe({
    topic: thingUpdateTopic(domainPath, thingName),
    metadata: { thingName }
  });

export const closeStream = ({ domainPath, thingName }) =>
  mqttUnsubscribe({
    topic: thingUpdateTopic(domainPath, thingName)
  });

export const emitToStream = ({ domainPath, thingName, data }) =>
  mqttPublish({
    topic: thingUpdateTopic(domainPath, thingName),
    data
  });

export const clearThingShadow = () => ({
  type: CLEAR_THING_SHADOW
});

export const thingCreateReset = thingType => ({
  type: THING_CREATE_RESET,
  thingType
});

export const initialState = fromJS({
  createThingStatus: requestStatus.IDLE,
  isLoading: false,
  error: null,
  search: [],
  networkedThingsRelation: {
    isLoading: false,
    relations: {}
  },
  thingShadow: null
});

export default handleActions(
  {
    [THING_DETAILS_REQUEST]: state =>
      state.merge({
        isLoading: true,
        error: null
      }),
    [THING_DETAILS_SUCCESS]: (state, { payload }) => {
      return state.merge({
        isLoading: false,
        thingShadow: {
          [payload.thingName]: payload.thingShadow
        }
      });
    },
    [THING_DETAILS_FAILURE]: (state, action) =>
      state.merge({
        isLoading: false,
        error: action.error
      }),

    // ---
    [THING_STREAM_ON_RECEPTION]: (state, { payload }) => {
      const thingShadow = state.getIn(["thingShadow", payload.thingName]);
      if (!thingShadow) {
        // needs to be populated by THING_DETAILS_SUCCESS
        return state;
      }
      const newThingShadow = adaptThingShadow(
        {
          shadow: payload.data,
          thingName: payload.thingName,
          thingType: thingShadow.get("thingType")
        },
        thingShadow
      );

      return state.mergeDeepIn(["thingShadow"], {
        [payload.thingName]: newThingShadow
      });
    },
    [THING_IMAGE_UPLOAD_REQUEST]: (state, action) =>
      state.merge({ isLoadingImage: true }),
    [THING_IMAGE_UPLOAD_FAILURE]: (state, action) =>
      state.merge({ isLoadingImage: false }),
    [THING_IMAGE_UPLOAD_SUCCESS]: (state, action) =>
      state.merge({
        isLoadingImage: false
      }),
    // ---
    [CLEAR_THING_SHADOW]: (state, action) => {
      return state.set("thingShadow", null);
    },
    [THING_CREATE_RECEIVED]: (state, { payload }) => {
      return state.setIn(
        [
          "thingCreated",
          payload.data.source.thingType,
          payload.data.source.thingName
        ],
        true
      );
    },
    [THING_CREATE_RESET]: (state, { thingType }) =>
      state.deleteIn(["thingCreated", thingType]),
    [CREATE_THINGS_BATCH_SUCCESS]: (state, { payload: { thingType, batch } }) =>
      state.setIn(["thingCreated", thingType, batch], true)
  },
  initialState
);
