import React from "react";
import { compose, withHandlers, withState } from "react-recompose";
import { concat, join, mergeDeepLeft, of } from "ramda";
import { PropTypes } from "prop-types";
import { unImmute } from "utils/immutable_utils";
import { List } from "immutable";

const _initialState = {
  addedVariables: []
};

const _withState = withState("state", "setState", _initialState);

const _withHandlers = withHandlers({
  setSvg: ({ state, setState }) => svg =>
    setState(mergeDeepLeft({ rootSvg: svg }, state)),
  setSvgRectElem: ({ state, setState }) => e =>
    setState(mergeDeepLeft({ svgRectElem: e }, state))
});

const style = {
  width: "100%",
  height: "100%"
};

const ProcessWidget = ({
  state,
  image,
  resources = new List(),
  addedVariables = new List(),
  setSvg,
  setSvgRectElem,
  startDrag,
  isEdit,
  deselectLabel
}) => {
  return (
    <svg
      onMouseDown={deselectLabel}
      viewBox="0 0 100 100"
      ref={setSvg}
      style={style}
    >
      {image ? (
        <image
          preserveAspectRatio={"xMidYMid meet"}
          xlinkHref={image}
          height={100}
          width={100}
        />
      ) : null}
      {addedVariables.map((addedVariable, index) => {
        const variable = unImmute(addedVariable);

        return (
          <text
            key={index}
            id={index}
            cursor={isEdit ? "pointer" : "default"}
            style={{
              fill: variable.selected && isEdit ? "mediumseagreen" : "black",
              fontSize: variable.fontSize
            }}
            x={variable.x}
            y={variable.y}
            ref={setSvgRectElem}
            onMouseDown={isEdit ? e => startDrag(e, state.rootSvg) : null}
          >
            {join(" ")(
              concat(of(variable.label), of(resources[variable.value]))
            )}
          </text>
        );
      })}
    </svg>
  );
};

ProcessWidget.propTypes = {
  state: PropTypes.object,
  image: PropTypes.string,
  resources: PropTypes.any,
  addedVariables: PropTypes.array,
  setSvg: PropTypes.func,
  setSvgRectElem: PropTypes.func,
  startDrag: PropTypes.func,
  isEdit: PropTypes.bool,
  deselectLabel: PropTypes.func
};

export default compose(_withState, _withHandlers)(ProcessWidget);
