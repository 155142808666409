import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import EventList from "./list";

export const EventsWidget = ({ style, ...rest }) => {
  const theme = useTheme();
  const eventListProps = { theme, ...rest };
  return (
    <div style={{ boxSizing: "border-box", width: "100%", ...style }}>
      <EventList {...eventListProps} />
    </div>
  );
};

EventsWidget.propTypes = {
  animate: PropTypes.bool,
  events: PropTypes.object,
  eventsTotal: PropTypes.number,
  keyPrefix: PropTypes.string,
  lastEventReadTimestamp: PropTypes.number,
  onAcknowledge: PropTypes.func,
  onLoadMore: PropTypes.func,
  maxEvents: PropTypes.number,
  style: PropTypes.object,
  isUserAuthorizedToAcknowledge: PropTypes.bool
};

export default EventsWidget;
