import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  progressCard: {
    display: "flex",
    flexDirection: "column"
  },
  progressCardContent: {}
});

const ProgressCard = ({ isLoading, children, dataTest, classes }) => {
  const mergedClasses = {
    ...useStyles(),
    ...classes
  };
  return (
    <Card className={mergedClasses.progressCard} data-test={dataTest}>
      {isLoading ? (
        <LinearProgress
          variant="indeterminate"
          data-test={`${dataTest}-progress-bar`}
        />
      ) : (
        <LinearProgress variant="determinate" value={100} />
      )}
      <div className={mergedClasses.progressCardContent}>{children}</div>
    </Card>
  );
};

ProgressCard.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  dataTest: PropTypes.string,
  style: PropTypes.object
};

export default ProgressCard;
