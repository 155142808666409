import Card from "components/cards/card";
import { Label } from "components/label";
import RecoverPasswordForm from "forms_new/user/recover_password";
import useLazyUnauthorizedFetch from "hooks/useLazyUnauthorizedFetch";
import React from "react";
import { getStyles } from "./index.style";

const PasswordRecovery = () => {
  const styles = getStyles();
  const [
    forgotPassword,
    { loading, success, error }
  ] = useLazyUnauthorizedFetch("/auth/forgot-password", "POST");

  return (
    <div style={styles.cardContainer}>
      <Label center type="h3">
        Trouble signing in?
      </Label>
      <Card isLoading={loading} noHeader>
        <p>
          Enter your username below to get instructions on how to set a new
          password
        </p>
        <RecoverPasswordForm
          handleSubmit={({ userName }) => forgotPassword({ userName })}
          isLoading={loading}
        />
        <p style={{ textAlign: "center" }}>
          {success && (
            <span>
              If you have submitted a valid username, we will send you
              instructions to assist you with resetting your password. If you
              have not received this email within 10 minutes, please check your
              registered username and try again.
            </span>
          )}
          <span style={styles.errorText}>{error && error}</span>
        </p>
      </Card>
    </div>
  );
};

export default PasswordRecovery;
