import React from "react";
import WidgetFooter from "../widget_footer";
import WidgetHeader from "../header/widget_header";
import { getWidgetAsset } from "./widget_asset";

export const getWidgetFactory = type =>
  getWidgetAsset(type, `${type}WidgetFactory`);

export const getWidget = props => {
  const factory = getWidgetFactory(props.type);
  const { decoratedProps, content } = factory
    ? factory.getWidget(props)
    : { WidgetContent: <div />, decoratedProps: props };

  return {
    header: decoratedProps.includeHeader ? (
      decoratedProps.header || (
        <WidgetHeader
          {...decoratedProps}
          openModalDesired={props.openModalDesired}
        />
      )
    ) : (
      <div />
    ),
    footer: decoratedProps.includeFooter ? (
      decoratedProps.footer || (
        <WidgetFooter
          {...decoratedProps}
          className="widget-container__footer"
        />
      )
    ) : (
      <div />
    ),
    decoratedProps,
    content
  };
};

export const getWidgetResourceValue = (type = "") =>
  getWidgetAsset(type, "getResourceValue");
