import LinearProgress from "@material-ui/core/LinearProgress";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Children } from "react";
import { compose, withState } from "react-recompose";
import CardHeader from "./card_header";
import Card from "./styled/card";
import CardContent from "./styled/card_content";
import IconWrapper from "./styled/icon_wrapper";
import ProgressWrapper from "./styled/progress_wrapper";

export const ProgressLine = props => {
  const theme = useTheme();
  return (
    <ProgressWrapper muiTheme={theme}>
      {props.isLoading && (
        <LinearProgress data-test={`${props["data-test"]}-progress-bar`} />
      )}
    </ProgressWrapper>
  );
};

ProgressLine.propTypes = {
  isLoading: PropTypes.bool,
  "data-test": PropTypes.string
};

const TelenorCard = ({
  icon,
  title,
  collapsedTitle,
  children,
  isLoading,
  isExpandable,
  isExpanded,
  setExpanded,
  noHeader,
  header,
  footer,
  headerChildren,
  textStyle,
  headerStyle,
  noPadding,
  elevation,
  style,
  showProgressLine = true,
  showHeader = true,
  widgetType,
  ...rest
}) => {
  const hasCardContent =
    (!isExpandable || isExpanded) && Children.toArray(children).length;
  const theme = useTheme();
  return (
    <Card
      elevation={elevation}
      isExpandable={isExpandable}
      muiTheme={theme}
      {...rest}
      style={style}
    >
      {!isExpandable && showProgressLine && (
        <ProgressLine isLoading={isLoading} data-test={rest["data-test"]} />
      )}
      {!noHeader &&
        showHeader &&
        (header ? (
          header
        ) : (
          <CardHeader
            data-test={`${rest["data-test"]}-card-header`}
            style={headerStyle}
            icon={icon && <IconWrapper muiTheme={theme}>{icon}</IconWrapper>}
            label={isExpanded ? title : collapsedTitle || title}
            isExpanded={isExpanded}
            setExpanded={() => setExpanded(!isExpanded)}
            hasExpandable={isExpandable}
          >
            {headerChildren}
          </CardHeader>
        ))}
      {hasCardContent && (
        <CardContent
          noPadding={noPadding}
          style={textStyle}
          widgetType={widgetType}
        >
          {children}
        </CardContent>
      )}
      {footer}
    </Card>
  );
};

TelenorCard.contextTypes = {
  muiTheme: PropTypes.object
};

TelenorCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  collapsedTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  containerStyle: PropTypes.object,
  expanded: PropTypes.object,
  expandable: PropTypes.object,
  onExpandChange: PropTypes.func,
  showExpandableButton: PropTypes.bool,
  showProgressLine: PropTypes.bool,
  showHeader: PropTypes.bool,
  initiallyExpanded: PropTypes.object,
  setExpanded: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.node,
  headerChildren: PropTypes.node,
  noHeader: PropTypes.bool,
  textStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  noPadding: PropTypes.bool,
  elevation: PropTypes.number,
  style: PropTypes.object,
  widgetType: PropTypes.string
};

TelenorCard.defaultProps = {
  headerStyle: {}
};

export default compose(
  withState("isExpanded", "setExpanded", ({ isExpanded }) =>
    Boolean(isExpanded)
  )
)(TelenorCard);
