import { handleActions } from "redux-actions";
import { fromJS } from "immutable";
import guid from "easy-guid";

const initialState = fromJS({});
const isPlural = text => text && text.endsWith("s");
const entitiesKeypath = state => (state.has("entities") ? ["entities"] : []);

const setId = entity => {
  if (!entity) return entity;
  if (entity.getIn) {
    const id = entity.get("id");
    if (!id) {
      entity = entity.set("id", guid.new(16));
    }
  } else {
    if (!entity.id) {
      entity = { ...entity, id: guid.new(16) };
    }
  }
  return entity;
};

export default (
  name,
  Record,
  {
    aggregateSuccess,
    relationships = [],
    customReducers = {},
    customActions = {}
  }
) => ({
  reducer: handleActions(
    {
      [aggregateSuccess]: (state, { payload: { entities } }) =>
        entities && entities[name]
          ? Object.keys(entities[name]).reduce(
              (state, key) =>
                state.set(key, new Record(fromJS(entities[name][key]))),
              state
            )
          : state,

      [`${name}_CREATE`]: (state, { entity }) =>
        state.mergeDeep({
          [entity.id]: new Record(setId(entity))
        }),

      [`${name}_UPDATE`]: (state, { entity }) =>
        state.mergeDeep({
          [entity.id]: entity
        }),

      [`${name}_DELETE`]: (state, { id }) => state.remove(id),

      [`${name}_DELETE_ALL`]: (state, { ids = [] }) =>
        ids.reduce((s, id) => {
          return s.remove(id);
        }, state),

      // --- relationships
      ...relationships.reduce((agg, r) => {
        return {
          ...agg,
          [`${r}_CREATE`]: (state, { parentId, entity: { id } }) =>
            state.mergeDeep({
              [parentId]: {
                [r]: isPlural(r) ? [id] : id
              }
            }),

          [`${r}_DELETE`]: (state, { parentId, id }) =>
            isPlural(r)
              ? state.setIn(
                  [parentId, r],
                  state.getIn([parentId, r], []).filter(key => key !== id)
                )
              : state.setIn([parentId, r], undefined)
        };
      }, {}),
      ...customReducers
    },
    initialState
  ),

  actions: {
    create: ({ parentId, entity }) => ({
      type: `${name}_CREATE`,
      parentId,
      entity: setId(entity)
    }),
    update: entity => ({
      type: `${name}_UPDATE`,
      entity
    }),
    delete: ({ parentId, id }) => ({
      type: `${name}_DELETE`,
      parentId,
      id
    }),
    deleteAll: ({ parentId, ids }) => ({
      type: `${name}_DELETE_ALL`,
      parentId,
      ids
    }),
    ...customActions
  },

  selectors: {
    getAll: state => state.getIn([...entitiesKeypath(state), name], fromJS({})),
    getAllKeys: state =>
      state.getIn([...entitiesKeypath(state), name], fromJS({})).keys(),
    get: (state, id) =>
      state.getIn([...entitiesKeypath(state), name, id], fromJS({})),
    getByIds: (state, ids = [], filter) => {
      ids = ids.size ? ids : fromJS(ids);
      if (ids.size === 0) return fromJS([]);
      const ret = filter
        ? ids
            .map(id =>
              state.getIn([...entitiesKeypath(state), name, id], fromJS([]))
            )
            .filter(
              val =>
                val && val.get(filter.propertyName) === filter.propertyValue
            )
        : ids.map(id =>
            state.getIn([...entitiesKeypath(state), name, id], fromJS([]))
          );
      return ret;
    },
    getByProperty: (state, { propertyName, propertyValue }) =>
      state
        .getIn([...entitiesKeypath(state), name])
        .filter(entity => entity.get(propertyName) === propertyValue)
  }
});
