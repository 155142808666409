/**
 * Created by stephenwhite on 02/03/16.
 */
import { calculateInterval } from "api/elastic/elastic_utils";
import { INTERVAL_NAME } from "components/widget/edit/widget_edit_aggregation_interval";
import { format as formatDate } from "date-fns";
import { fromJS, List } from "immutable";
import {
  allPass,
  equals,
  gt,
  length,
  not,
  pipe,
  test,
  is,
  memoizeWith
} from "ramda";
import { resourceIdFromWidget } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import { resourcePath } from "utils/resource_utils";
import { widgetProp } from "./dashboard_utils";

const defaultColor = "#00ACE7";

export const getMarkerColor = (conditions, resourceValue) => {
  if (!conditions || resourceValue === undefined) return defaultColor; // TODO: use default theme color
  const markerColor = conditions.markerColors.find(markerColor => {
    switch (markerColor.operator) {
      case "gt":
        return resourceValue > markerColor.threshold;
      case "gte":
        return resourceValue >= markerColor.threshold;
      case "lt":
        return resourceValue < markerColor.threshold;
      case "lte":
        return resourceValue <= markerColor.threshold;
      case "eq":
        return resourceValue == markerColor.threshold;
      case "neq":
        return resourceValue != markerColor.threshold;
      default:
        console.warn("operator not supported, ", markerColor.operator); //eslint-disable-line
        return false;
    }
  });
  return markerColor ? markerColor.color : defaultColor; // TODO: use default theme color
};

export const withMarkerColors = (conditions, mapDataPoints) =>
  mapDataPoints.withMutations(mutableDataPoints => {
    let lastColor;

    for (let i = mapDataPoints.size - 1; i >= 0; i--) {
      const conditionValue = mapDataPoints.getIn([
        i,
        "properties",
        "conditionValue"
      ]);

      const color =
        conditionValue === undefined && lastColor !== undefined
          ? lastColor
          : getMarkerColor(conditions, conditionValue);

      lastColor = color;
      mutableDataPoints.update(i, p =>
        p.setIn(["properties", "markerColor"], color)
      );
    }
  });

export function getDecimalPrecisionValue(value, numberOfDecimals) {
  let formattedValue;
  if (numberOfDecimalsIsSet(numberOfDecimals) && value && !isNaN(value)) {
    const factor = Math.pow(10, numberOfDecimals);
    formattedValue = Math.trunc(value * factor) / factor;
  } else {
    formattedValue = value;
  }
  return formattedValue;
}

export const numberOfDecimalsIsSet = numberOfDecimals =>
  numberOfDecimals != null && numberOfDecimals !== -1;

export function getResources(widgets) {
  if (!widgets || widgets.size === 0) return [];
  const resources = [];
  widgets.forEach(widget => {
    if (widget !== undefined && typeof widget.get === "function") {
      const type = imu.get(widget, "type");
      const name = resourceIdFromWidget(widget);
      if (
        (type === "Timeseries" || type === "Table") &&
        resources.indexOf(name) < 0
      ) {
        resources.push(name);
      }
    }
  });
  return resources;
}

export const _getWidgetKeypath = (
  viewMode,
  subThingType = "untyped",
  subthing = "default",
  thingType,
  prefix = [],
  dashboard = "thingWidgets"
) => {
  let keypath = [viewMode, "thingWidgets"];
  if (!thingType) return [...prefix, ...keypath];
  if (subThingType !== "untyped" || subthing !== "default") {
    keypath = [viewMode, "subThings", subThingType, dashboard];
  }
  return [...prefix, ...keypath];
};

export const getWidgetKeypath = memoizeWith(
  (
    viewMode,
    subThingType = "untyped",
    subthing = "default",
    thingType = -1,
    prefix = [],
    dashboard = "thingWidgets"
  ) =>
    `${viewMode}${subThingType}${subthing}${thingType}${List(
      prefix
    ).hashCode()}${dashboard}`,
  _getWidgetKeypath
);

export function getResourceKeypath(
  subThingType = "untyped",
  subthing = "default",
  thingType
) {
  let keypath = ["resources"];
  if (!thingType || !subthing) return keypath;
  if (subthing === "default" && subThingType === "untyped") {
    keypath = [...keypath, "thing"];
  } else {
    keypath = [...keypath, "subThings"];
    keypath = [...keypath, subThingType];
  }
  return keypath;
}

export function getThingShadowResourceKeypath(subthingId) {
  let keypath = ["resources"];
  if (subthingId !== "default") {
    keypath = ["subThings", subthingId, "resources"];
  }
  return keypath;
}

const _getKeypaths = ({
  viewMode,
  subThingType,
  subthing,
  editingThingType,
  prefix = ["editingThingType", "viewModes"]
}) => {
  if (!editingThingType)
    return {
      widgetKeypath: [],
      resourceKeypath: [],
      virtualResourcesKeypath: [],
      thingShadowResourceKeypath: []
    };
  if (subthing === "default") {
    subThingType = undefined;
  }
  const widgetKeypath = getWidgetKeypath(
    viewMode,
    subThingType,
    subthing,
    editingThingType,
    prefix
  );

  const resourceKeypath = getResourceKeypath(
    subThingType,
    subthing,
    editingThingType
  );

  let virtualResourcesKeypath = [...resourceKeypath];
  virtualResourcesKeypath[0] = "virtualResources";

  const thingShadowResourceKeypath = getThingShadowResourceKeypath(subthing);

  return {
    widgetKeypath,
    resourceKeypath,
    virtualResourcesKeypath,
    thingShadowResourceKeypath
  };
};

export const getKeypaths = memoizeWith(
  ({
    viewMode,
    subThingType,
    subthing = "default",
    thingType = -1,
    prefix = []
  }) =>
    `${viewMode}${subThingType}${subthing}${thingType}${List(
      prefix
    ).hashCode()}`,
  _getKeypaths
);

const _isValidNumber = allPass([
  pipe(isNaN, not),
  pipe(test(/^0x[0-9a-f]+$/), not), // shouldn't parse hex values
  pipe(length, gt(16)),
  pipe(equals(""), not)
]);

export function getValue(value) {
  if (typeof value === "undefined" || value === null) {
    return "";
  }
  let newValue = value;
  if (is(Date, value)) {
    newValue = formatDate(value, "yyyy-MM-dd HH:mm:ss");
  } else if (_isValidNumber(value)) {
    newValue =
      value.indexOf(".") >= 0 ? parseFloat(value).toFixed(2) : Number(value);
  } else if (is(Object, value)) {
    newValue = JSON.stringify(value);
  }
  return newValue;
}

export const getValueAndPending = (resource, isVirtualResource) => {
  if (!resource) {
    return { pending: false, pendingValue: "", value: "" };
  }
  const value = getValue(resource.getIn(["latestValue", "value"]));
  const pending = resource.getIn(["pending"]) || false;
  const pendingValue = getValue(resource.getIn(["pendingValue"])); // getValue sets decimals to 2. Maybe change this
  if (isVirtualResource && pending) {
    return { value: pendingValue, pending: false, pendingValue: "" };
  } else {
    return { value, pending, pendingValue };
  }
};

export function newCredentialsWidget() {
  return fromJS({
    credentialsWidget: {
      type: "ThingCredentials",
      id: "credentialsWidget",
      layout: {
        w: 12,
        moved: false,
        y: 0,
        h: 5,
        x: 0,
        i: "credentialsWidget"
      },
      label: "Thing Credentials",
      includeHeader: true,
      includeFooter: false
    }
  });
}

export function extractResourceValue(
  thingShadow,
  widget,
  editingThingType,
  thingShadowResourceKeypath,
  virtualResourcesKeypath
) {
  let resource;

  if (
    thingShadow &&
    thingShadowResourceKeypath &&
    thingShadowResourceKeypath.length > 0
  ) {
    const resourceName = resourceIdFromWidget(widget);
    const keypath = [...thingShadowResourceKeypath, resourceName];
    const vrkeypath = [
      ...virtualResourcesKeypath,
      resourceName,
      "defaultValue"
    ];
    if (thingShadow) {
      resource = thingShadow.getIn(keypath);
      if (!resource) {
        let resourceVal = editingThingType.getIn(vrkeypath);
        if (!resourceVal && resourceName) {
          // This is a meta resource (yes I just made up that name)
          resourceVal = thingShadow.getIn(resourceName.split("."));
        }
        resourceVal = getValue(resourceVal);
        resource = fromJS({ latestValue: { value: resourceVal } });
      }
    }
  }
  return resource;
}

export const _getLatestValue = ({ resource = fromJS({}) }) =>
  resource.get("latestValue");

export const buildAggregationQueryKey = ({
  aggregationType = "avg",
  aggregationInterval,
  observationPeriod = {},
  thingType,
  thingName,
  resourceName
}) =>
  [
    aggregationType,
    `${observationPeriod.from}--${observationPeriod.to}`,
    aggregationInterval,
    thingType,
    thingName,
    resourceName
  ].join("|");

export const resourceStatePath = (subthing, resourceId) => {
  const { isTcxn, resourceName } = resourcePath(resourceId);

  if (subthing != null && subthing !== "default") {
    return `${subthing}.${resourceName}`;
  } else if (isTcxn) {
    return `tcxn.${resourceName}`;
  }

  return resourceName;
};

const calculateAggregationInterval = (observationPeriod = {}) => {
  const { from, to } = imu.unImmute(observationPeriod);
  return from && to ? calculateInterval(to.valueOf() - from.valueOf()) : "3h";
};

export const getAggregationInterval = ({ widget, observationPeriod }) =>
  widgetProp(widget, INTERVAL_NAME) ||
  calculateAggregationInterval(observationPeriod);

export const isObservationsAggregated = widget => {
  const isRealtime = widgetProp(widget, "isRealtime") === 1;
  const optionValue = widgetProp(widget, "isObservationsAggregated");
  const isAggregated =
    optionValue === "AGGREGATED_OBSERVATIONS" || optionValue === undefined;
  return !isRealtime && isAggregated;
};

export const isObservationsRaw = widget => {
  return widgetProp(widget, "isObservationsAggregated") === "RAW_OBSERVATIONS";
};
