import { map, mergeMap, take, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { SIMULATOR_START_PUBLISH, SIMULATOR_STOP_PUBLISH } from ".";
import { mqttPublish } from "ducks/mqtt";
import { thingUpdateTopic } from "utils/mqtt_utils";
import { duration } from "./simulator_payload_timer";

export const simulatorPublishEpic = (
  action$,
  store,
  { simulatorPayloadTimer, simulatorPayloadGenerator }
) =>
  action$.pipe(
    ofType(SIMULATOR_START_PUBLISH),
    mergeMap(({ payload: { payloadConfig, domainTopic, thingName } }) => {
      let prevState = {};
      const interval = duration(payloadConfig.period);
      return simulatorPayloadTimer(payloadConfig.period).pipe(
        map(tick =>
          mqttPublish({
            topic: thingUpdateTopic(domainTopic, thingName),
            data: (prevState = simulatorPayloadGenerator(
              payloadConfig.resourceConfig,
              prevState,
              {
                interval,
                elapsed: tick * interval
              }
            ))
          })
        ),
        takeUntil(action$.pipe(ofType(SIMULATOR_STOP_PUBLISH), take(1)))
      );
    })
  );
