import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import { hasThingUpdateTopic } from "utils/mqtt_utils";
import * as thing from "./thing";
import { MQTT_DATA_RECEIVED } from "ducks/mqtt";
import { thingStreamReception } from "ducks/thing";
import { THING_IMAGE } from "graphql/queries";

const THING_IMAGE_FILENAME = "image";

export const imageUploadEpic = (action$, store, { api, getApolloClient }) =>
  action$
    .ofType(thing.THING_IMAGE_UPLOAD_REQUEST)
    .mergeMap(({ payload: { thingName, file } }) =>
      api.files
        .uploadV2$({ thingName, fileName: THING_IMAGE_FILENAME }, file)
        .mergeMap(() =>
          getApolloClient()
            .query({
              query: THING_IMAGE,
              variables: { thingId: thingName },
              fetchPolicy: "network-only"
            })
            .then(res => thing.uploadThingImageSuccess(res))
        )
        .catch(error =>
          Observable.of(thing.uploadThingImageFailure(), apiError(error))
        )
    );

export const getThingDetailsEpic = (action$, store, { api }) =>
  action$
    .ofType(thing.THING_DETAILS_REQUEST)
    .filter(({ thingName }) => !store.getState().thing.get(thingName))
    .mergeMap(({ payload }) =>
      api.things
        .fetchThingDetails$({ thingName: payload })
        .map(response =>
          thing.getThingDetailsSuccess({
            thingName: payload,
            thingShadow: response
          })
        )
        .catch(error => Observable.of(apiError(error)))
    );

export const thingStreamEpic = action$ =>
  action$
    .ofType(MQTT_DATA_RECEIVED)
    .filter(hasThingUpdateTopic)
    .map(({ payload }) =>
      thingStreamReception({
        data: payload.data,
        thingName: payload.metadata.thingName
      })
    );
