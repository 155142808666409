import * as R from "ramda";
import { createDeepEqualSelector } from "utils/selector_utils";
import { domainSelectors } from "ducks/domain";
import { getVirtualResource } from "ducks/thing_types/thing_types_selectors";
import { schemaSelectors } from "ducks/schema";
import {
  getResourceValue as getShadowResourceValue,
  getDomainId
} from "ducks/thing/thing_selectors";
import { getValue } from "utils/widget_utils";
import { fromJS, Map } from "immutable";
import * as sorters from "utils/sorters";
import { appendUnit } from "utils/general_utils";
import {
  _lowerCaseSubThing,
  resourcePath,
  typeValue
} from "utils/resource_utils";

/**
 * I'm really hoping that we can remove most of this code once we go over to the new
 * subthing format.
 */

// --------------------------- private
const _getVirtualResourceValue = (state, resourceName, vrkeypath) => {
  // this could be a virtual resource, the problem is that the old style of resources
  // had no identifer depicting them as virtual or pertaining to meta data
  let vrValue = getVirtualResource({
    state,
    vrkeypath: [
      "editingThingType",
      "virtualResources",
      resourceName,
      "defaultValue"
    ]
  });
  vrValue = vrValue
    ? vrValue
    : getVirtualResource({
        state,
        vrkeypath: ["editingThingType", ...vrkeypath]
      });
  return vrValue;
};

/**
 * buildResourceIds will build shadowIds plus the virtual resource keypath.
 * Shadow resources will always take precedence, as a result if you'v set a value on a
 * virtual resource then this value will show up on the thing shadow as a normal resource.
 * As a result we'll strip out the /virtual/ id from the shadow id so we can get the value
 * directly from the shadow and not from the thing type .... crickey this is complicated.
 */
const buildResourceIds = ({
  resourceName,
  thingType,
  subthing,
  subThingType
}) => {
  let shadowResourceId = resourceName;

  const i = shadowResourceId.lastIndexOf("/");
  const lastName = shadowResourceId.slice(i + 1);
  let vrkeypath = ["virtualResources", "thing", lastName, "defaultValue"];
  // shadow resources always take precedence ... remove the virtual from the keypath
  shadowResourceId = resourceName.replace("virtual/", "");
  if (shadowResourceId.indexOf("/") < 0) {
    let resourceIdPath = [thingType];
    if (subthing !== "default") {
      // then we have a subthing
      resourceIdPath = [...resourceIdPath, subThingType, subthing];
    }
    resourceIdPath = [...resourceIdPath, shadowResourceId];
    shadowResourceId = resourceIdPath.join("/");
  }
  if (subthing && subthing !== "default")
    vrkeypath = [
      "virtualResources",
      "subThings",
      subThingType,
      lastName,
      "defaultValue"
    ];

  return {
    shadowResourceId,
    vrkeypath
  };
};

// --------------------------- public
export const _isVirtual = (state, virtualResourcesKeypath, resourceName) =>
  resourceName &&
  (resourceName.indexOf("/virtual") > 0 ||
    Boolean(
      state.thingTypes.getIn([
        "editingThingType",
        ...virtualResourcesKeypath,
        resourceName
      ])
    ));

export const getVirtualResourceValue = (state, thingName, resourceId) => {
  const { subthing, subThingType, thingType, resourceName } = resourcePath(
    resourceId
  );
  const { vrkeypath, shadowResourceId } = buildResourceIds({
    resourceName: resourceId,
    thingType,
    subthing,
    subThingType
  });
  let value = getShadowResourceValue(
    state,
    thingName,
    shadowResourceId,
    subthing
  );
  if (value) {
    const latestValue = value.getIn(["latestValue", "value"]);
    value = latestValue ? latestValue : value.get("pendingValue");
  } else {
    // try the thing type
    value = _getVirtualResourceValue(state, resourceName, vrkeypath);
  }
  return value;
};

const _formatForWidgetEditPicker = (state, thingName) => resource => {
  const id = resource.get("id");
  const name = resource.get("name");
  const label = resource.getIn(["metadata", "label"]) || name;
  const unit = resource.getIn(["metadata", "unit"]);
  let map = Map({
    id,
    label,
    name,
    unit,
    value: id,
    type: resource.get("type")
  });

  if (/virtual/.test(id)) {
    map = map.set("icon", "vr");
    const value = getVirtualResourceValue(state, thingName, id);
    const { resourceType } = typeValue(value);
    map = map.set("type", resourceType);
  }

  if (/tcxn/.test(id)) {
    map = map.set("icon", "TCXN Convention");
    if (/tcxn\//.test(label)) {
      const strippedLabel = R.pipe(R.split("/"), R.tail, R.join("/"))(label);
      map = map.set("label", strippedLabel);
    }
  }

  return map;
};

export const _getEditingWidgetExtraResources = (state, thingName) => {
  const domainId = getDomainId(state.thing, thingName);
  const resources = R.pipe(
    state => domainSelectors.getDomainResources(state, domainId),
    R.sort(sorters.byIcon)
  )(state);
  return resources;
};

export const _getEditingWidgetResources = (
  state,
  thingName,
  thingTypeId,
  subthing = "default",
  subThingType = "untyped",
  options
) => {
  const subthingPath =
    subthing === "default"
      ? undefined
      : `${thingTypeId}/subthing/${subThingType}`;

  const domainId = getDomainId(state.thing, thingName);
  const domainResources = fromJS(
    domainSelectors.getDomainResources(state, domainId)
  );
  const _options = {
    includeTcxn: false,
    ...options
  };

  const resources = schemaSelectors
    .getResources(
      state.schema,
      subthingPath ? subthingPath : thingTypeId,
      _options
    )
    .map(_formatForWidgetEditPicker(state, thingName))
    .concat(domainResources)
    .sort(sorters.byIcon)
    .toJS();
  return resources;
};

export const _convertOldResourceName = ({
  resourceName,
  thingType,
  subthing,
  subThingType,
  isVirtual
}) => {
  if (resourceName.indexOf("/") > 0) return resourceName;
  // build up the correct path on the new thing shadow
  const path = [thingType];
  if (subthing && subthing !== "default") {
    path.push("subthing");
    path.push(subThingType);
  }
  if (isVirtual) path.push("virtual");
  path.push(resourceName);
  return path.join("/");
};

export const _extractResourcename = (
  resources,
  resourceName,
  subThingType = "untyped",
  subthing = "default"
) => {
  if (!resources || !resourceName || resourceName.indexOf("/") > 0)
    return _lowerCaseSubThing(resourceName);
  const resourceNames = resources
    .filter(resource => {
      const reg =
        subthing === "default"
          ? new RegExp(`(?=.*/${resourceName}$)(^(?!.*subthing).*$)`)
          : new RegExp(`/subthing/${subThingType}/.*${resourceName}$`);
      return reg.test(
        _lowerCaseSubThing(R.is(Object, resource) ? resource.id : resource)
      );
    })
    .map(resource => (R.is(Object, resource) ? resource.id : resource));
  return resourceNames ? resourceNames[0] : resourceName;
};

const emptyResource = fromJS({
  latestValue: {
    value: undefined
  }
});

const _isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export const _getResourceValue = ({
  state,
  resourceName,
  thingName,
  thingType,
  subthing = "default",
  subThingType = "untyped"
}) => {
  if (!resourceName) return emptyResource;
  let resource = emptyResource;

  if (resourceName.indexOf("domain.") >= 0) {
    let domainValue = getShadowResourceValue(
      state,
      thingName,
      resourceName,
      subthing
    );
    // this too be honest is super shit
    // if the domain value is not on the shadow then it might be on the actual
    // domain metadata ... so we take the last section of the resourceName
    // and search in the metadata for that domain .... wow I need to speak
    // I didn't realise it had got so weird ....
    //
    // Frustration counter: 3 (increase this when you get angry)

    if (_isNullOrEmpty(domainValue)) {
      const domainAttributeId = resourceName.slice(
        resourceName.lastIndexOf(".") + 1
      );
      const domainId = getDomainId(state.thing, thingName);
      domainValue = domainSelectors.getDomainMetadataItem(
        state,
        domainId,
        domainAttributeId
      );
    }
    resource = resource.setIn(["latestValue", "value"], domainValue);
  } else {
    const { shadowResourceId, vrkeypath } = buildResourceIds({
      resourceName,
      thingName,
      thingType,
      subthing,
      subThingType
    });
    resource = getShadowResourceValue(
      state,
      thingName,
      shadowResourceId,
      subthing
    );
    if (!resource) {
      // this could be a virtual resource, the problem is that the old style of resources
      // had no identifer depicting them as virtual or pertaining to meta data
      const vrValue = _getVirtualResourceValue(state, resourceName, vrkeypath);
      resource = fromJS({
        latestValue: {
          value: getValue(vrValue)
        }
      });
    }
    // finally if it's a virtual resource then always switch the pending value ....
    // not sure if this is a hack or how we should handle this
    if (
      _isVirtual(state, vrkeypath, resourceName) &&
      !_isNullOrEmpty(resource.get("pendingValue"))
    ) {
      resource = resource.setIn(
        ["latestValue", "value"],
        resource.get("pendingValue")
      );
      resource = resource.set("pending", false);
      resource = resource.set("pendingValue", undefined);
    }
  }

  return resource;
};

// --------------------------- reselect

export const getResourceValue = createDeepEqualSelector(
  ({ state }) => state,
  ({ resourceName }) => resourceName,
  ({ thingName }) => thingName,
  ({ thingType }) => thingType,
  ({ subthing }) => subthing,
  ({ subThingType }) => subThingType,
  (state, resourceName, thingName, thingType, subthing, subThingType) =>
    _getResourceValue({
      state,
      resourceName,
      thingName,
      thingType,
      subthing,
      subThingType
    })
);

export const extractResourcename = createDeepEqualSelector(
  resources => resources,
  (resources, resourceName) => resourceName,
  (resources, resourceName, subThingType) => subThingType,
  (resources, resourceName, subThingType, subthing) => subthing,
  (resources, resourceName, subThingType, subthing) =>
    _extractResourcename(resources, resourceName, subThingType, subthing)
);

export const convertOldResourceName = createDeepEqualSelector(
  ({ resourceName }) => resourceName,
  ({ thingType }) => thingType,
  ({ subthing }) => subthing,
  ({ subThingType }) => subThingType,
  ({ isVirtual }) => isVirtual,
  (resourceName, thingType, subthing, subThingType, isVirtual) =>
    _convertOldResourceName({
      resourceName,
      thingType,
      subthing,
      subThingType,
      isVirtual
    })
);

export const getEditingWidgetResources = createDeepEqualSelector(
  state => state,
  (state, thingName) => thingName,
  (state, thingName, thingTypeId) => thingTypeId,
  (state, thingName, thingTypeId, subthing) => subthing,
  (state, thingName, thingTypeId, subthing, subThingType) => subThingType,
  (state, thingName, thingTypeId, subthing, subThingType, options) => options,
  (state, thingName, thingTypeId, subthing, subThingType, options) =>
    _getEditingWidgetResources(
      state,
      thingName,
      thingTypeId,
      subthing,
      subThingType,
      options
    )
);

export const getEditingWidgetExtraResources = createDeepEqualSelector(
  state => state,
  (state, thingName) => thingName,
  _getEditingWidgetExtraResources
);

export const isVirtual = createDeepEqualSelector(
  state => state,
  (state, virtualResourcesKeypath) => virtualResourcesKeypath,
  (state, virtualResourcesKeypath, resourceName) => resourceName,
  (state, virtualResourcesKeypath, resourceName) =>
    _isVirtual(state, virtualResourcesKeypath, resourceName)
);

export const _getResourceValues = ({
  state,
  resources = [],
  thingName,
  thingType,
  subthing = "default",
  subThingType = "untyped",
  simple = true,
  map = false,
  showUnit = false
}) => {
  const res = resources.reduce(
    (accu, resource) => {
      let value = getResourceValue({
        state,
        resourceName: resource.id,
        thingName,
        thingType,
        subthing,
        subThingType
      });
      let key = resource.label;
      if (resource.id.indexOf("domain.") >= 0) {
        key = resource.id;
      }
      if (simple) {
        value = value.getIn(["latestValue", "value"]);
      }

      if (showUnit) {
        value = appendUnit(value, resource.unit);
      }

      if (map) {
        accu.map[key] = value;
      } else {
        accu.list.push({
          [key]: value
        });
      }
      return accu;
    },
    {
      map: {},
      list: []
    }
  );
  return map ? fromJS(res.map) : res.list;
};

export const getResourceValues = createDeepEqualSelector(
  ({ state }) => state,
  ({ resources }) => resources,
  ({ thingName }) => thingName,
  ({ thingType }) => thingType,
  ({ subthing }) => subthing,
  ({ subThingType }) => subThingType,
  ({ simple }) => simple,
  ({ map }) => map,
  ({ showUnit }) => showUnit,
  (
    state,
    resources,
    thingName,
    thingType,
    subthing,
    subThingType,
    simple,
    map,
    showUnit
  ) =>
    _getResourceValues({
      state,
      resources,
      thingName,
      thingType,
      subthing,
      subThingType,
      simple,
      map,
      showUnit
    })
);

export const getLatestResourceTimestamp = createDeepEqualSelector(
  ({ state }) => state,
  ({ resourceNames }) => resourceNames,
  ({ thingName }) => thingName,
  ({ thingType }) => thingType,
  ({ subthing }) => subthing,
  ({ subThingType }) => subThingType,
  (state, resourceNames, thingName, thingType, subthing, subThingType) => {
    const defaultTimestampValue = null;
    const resourceTimestamps = resourceNames.map(resourceName =>
      getResourceValue({
        state,
        resourceName,
        thingType,
        thingName,
        subThingType,
        subthing
      }).getIn(["latestValue", "timestamp"])
    );
    return R.reduce(R.max, defaultTimestampValue, resourceTimestamps);
  }
);
