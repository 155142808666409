import { reduce, concat, values, mapObjIndexed } from "ramda";

const wrapEpic = (epic, name) => (...args) =>
  epic(...args).catch((error, source$) => {
    console.error(`Fatal: error thrown in observable epic "${name}"`); // eslint-disable-line no-console
    console.error(error); // eslint-disable-line no-console
    return source$; // returning the source$ means restarting the epic that failed
  });

/**
 * Takes an array of objects where the key is an epic name, and the value is an
 * epic. Each epic is wrapped with `wrapEpic` to catch individual errors, and
 * is then added to a flattened array of epics.
 * [{ k: e }] -> [e']
 */
export const wrapEpics = reduce(
  (flatEpics, currentEpicsObj) =>
    concat(flatEpics, values(mapObjIndexed(wrapEpic, currentEpicsObj))),
  []
);
