import { invoke$ as lamdaInvoke$ } from "./invoke";

export const fetchDashboardsInvoke$ = invoke$ => ({
  userName,
  thingTypeId,
  dashboardType
}) =>
  invoke$("DashboardLambda", {
    action: "LIST",
    attributes: {
      filter: {
        type: dashboardType,
        user: userName,
        thingType: thingTypeId
      }
    }
  });

export const saveDashboardInvoke$ = invoke$ => attributes =>
  invoke$("DashboardLambda", {
    action: "UPDATE",
    attributes
  });

export const fetchDashboards$ = fetchDashboardsInvoke$(lamdaInvoke$);
export const saveDashboard$ = saveDashboardInvoke$(lamdaInvoke$);
