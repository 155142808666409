import grey from "@material-ui/core/colors/grey";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import BatteryIcon20 from "@material-ui/icons/Battery20";
import BatteryIcon30 from "@material-ui/icons/Battery30";
import BatteryIcon50 from "@material-ui/icons/Battery50";
import BatteryIcon60 from "@material-ui/icons/Battery60";
import BatteryIcon80 from "@material-ui/icons/Battery80";
import BatteryIcon90 from "@material-ui/icons/Battery90";
import BatteryIconFull from "@material-ui/icons/BatteryFull";
import BatteryIconUnkown from "@material-ui/icons/BatteryUnknown";
import PropTypes from "prop-types";
import React from "react";
import { keyWithValueInRange } from "./utils";

const batteryLevels = props => ({
  "0-20": <BatteryIcon20 data-test="battery-icon-20" {...props} />,
  "21-30": <BatteryIcon30 data-test="battery-icon-30" {...props} />,
  "31-50": <BatteryIcon50 data-test="battery-icon-50" {...props} />,
  "51-60": <BatteryIcon60 data-test="battery-icon-60" {...props} />,
  "61-80": <BatteryIcon80 data-test="battery-icon-80" {...props} />,
  "81-90": <BatteryIcon90 data-test="battery-icon-90" {...props} />,
  "91-100": <BatteryIconFull data-test="battery-icon-full" {...props} />
});

const BatteryLevel = ({ battery, classes }) => {
  if (battery === undefined || battery === null) {
    return <span />;
  }
  const batteryProps = {
    color: "default",
    nativeColor: grey[700]
  };
  const batLevels = batteryLevels(batteryProps);
  const batRange = keyWithValueInRange(battery, batLevels);

  return (
    <span className={classes.wrapper}>
      <Tooltip title={`${battery}%`} className={classes.tooltip}>
        {batRange === undefined ? (
          <BatteryIconUnkown
            data-test="battery-icon-unkown"
            {...batteryProps}
          />
        ) : (
          batLevels[batRange]
        )}
      </Tooltip>
    </span>
  );
};

BatteryLevel.propTypes = {
  battery: PropTypes.number,
  classes: PropTypes.object
};

export default withStyles({
  wrapper: {
    position: "relative",
    display: "flex"
  }
})(BatteryLevel);
