/* eslint-disable react/no-children-prop */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Map } from "immutable";
import { replaceLastParam, thingTypeOverviewPath } from "utils/path_utils";
import * as actions from "ducks/events";
import { userCanAcknowledgeEvents } from "ducks/events/events_selectors";
import { EventsWidget } from "components/widget/widget_types/events_widget/events_widget";
import ProgressCard from "components/cards/progress_card";
import { Page } from "components/page";
import EventsPageTabs from "./components/events_page_tabs";

import ObservationPeriod from "components/observation";

export class EventsPageContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      searchForEvents,
      classification,
      thingTypeId,
      thingName
    } = this.props;

    searchForEvents({
      period: this.props.observation,
      classification: classification.toUpperCase(),
      thingType: thingTypeId,
      thingName
    });
  }

  componentDidUpdate(prevProps) {
    const { observation, classification, thingTypeId, thingName } = this.props;
    if (
      observation !== prevProps.observation ||
      classification !== prevProps.classification ||
      thingTypeId !== prevProps.thingTypeId ||
      thingName !== prevProps.thingName
    ) {
      this.props.searchForEvents({
        period: observation,
        classification: classification.toUpperCase(),
        thingType: thingTypeId,
        thingName
      });
    }
  }

  render() {
    const {
      events,
      isLoading,
      classification,
      thingType,
      toggleOnlyAcknowledged,
      acknowledge,
      children,
      isUserAuthorizedToAcknowledge,
      viewMode
    } = this.props;
    const thingTypeLabel = thingType && thingType.get("label", null);
    const parentUrl =
      thingType && thingTypeOverviewPath(thingType.get("id"), viewMode);
    return (
      <Page
        title={"Events"}
        backButton={thingTypeLabel && parentUrl}
        parentUrl={parentUrl}
        parentName={thingTypeLabel}
        rightElement={<ObservationPeriod />}
      >
        <ProgressCard isLoading={isLoading}>
          <EventsPageTabs
            classification={classification}
            routeToNewClassification={this.props.routeToNewClassification}
            toggleOnlyAcknowledged={toggleOnlyAcknowledged}
          />
          <EventsWidget
            events={events}
            maxEvents={500}
            eventsTotal={events ? events.size : 0}
            onAcknowledge={acknowledge}
            isUserAuthorizedToAcknowledge={isUserAuthorizedToAcknowledge}
          />
          {children}
        </ProgressCard>
      </Page>
    );
  }
}

EventsPageContainer.propTypes = {
  isLoading: PropTypes.bool,
  events: PropTypes.object,
  searchForEvents: PropTypes.func,
  acknowledge: PropTypes.func,
  toggleOnlyAcknowledged: PropTypes.func,
  classification: PropTypes.string,
  thingTypeId: PropTypes.string,
  thingType: PropTypes.object,
  thingLabel: PropTypes.string,
  thingName: PropTypes.string,
  observation: PropTypes.object,
  location: PropTypes.object,
  route: PropTypes.object,
  routeToNewClassification: PropTypes.func,
  children: PropTypes.object,
  isUserAuthorizedToAcknowledge: PropTypes.bool,
  viewMode: PropTypes.string
};

EventsPageContainer.defaultProps = {
  classification: "",
  searchForEvents: () => {}
};

const mapStateToProps = (
  state,
  { params: { thingType: thingTypeId, thingName, classification }, location }
) => {
  return {
    isLoading: state.events.get("isLoading"),
    events: actions.getEventsFromSearch(
      state.events,
      state.events.get("onlyShowAcknowledge")
    ),
    isUserAuthorizedToAcknowledge: userCanAcknowledgeEvents(state),
    classification,
    location,
    observation: state.observation,
    thingTypeId,
    thingType: state.thingTypes.getIn(["types", thingTypeId], Map()),
    thingName
  };
};

const mapDispatchToProps = (dispatch, { location: { pathname } }) => ({
  searchForEvents: payload => dispatch(actions.searchForEvents(payload)),
  acknowledge: id => dispatch(actions.acknowledge(id)),
  routeToNewClassification: classification =>
    dispatch(push(replaceLastParam(pathname, classification))),
  toggleOnlyAcknowledged: () => {
    return dispatch(actions.toggleOnlyAcknowledged());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsPageContainer);
