import * as R from "ramda";
import { Observable } from "rxjs";
import { push } from "react-router-redux";
import { unImmute } from "utils/immutable_utils";
import { apiError } from "ducks/errors";
import { loadSchemas } from "ducks/schema";
import { setNotificationMessage } from "ducks/system";
import * as actions from "./analyses";
import {
  observationRequestsEqual,
  isValidObservationRequest,
  analysisUserData,
  getThingNamesAndResourcePaths
} from "./utils/analyzer_epic_utils";
import {
  FETCH_RESOURCE_MAPPING_REQUEST,
  fetchResourceMappingSuccess,
  fetchResourceMappingFailure
} from "ducks/analyzer";
import { getThingDetails } from "ducks/thing";

export const putAnalysesEpic = (action$, store, { api }) =>
  action$
    .ofType(actions.PUT_ANALYSES_REQUEST)
    .mergeMap(({ payload: analysis }) =>
      api.users
        .updateUserData$(analysisUserData(analysis.name, analysis))
        .map(response => actions.putAnalysesSuccess(response))
        .catch(error =>
          Observable.of(actions.putAnalysesFailure(error), apiError(error))
        )
    );

export const fetchObservationsEpic = action$ =>
  action$
    .ofType(actions.FETCH_OBSERVATIONS_REQUEST)
    .filter(isValidObservationRequest)
    .distinctUntilChanged(observationRequestsEqual)
    .mergeMap(action => {
      const prefixedResources = R.pipe(
        R.path(["payload", "prefixedResources"]),
        unImmute
      )(action);

      return Observable.of(actions.fetchResourceMapping(prefixedResources));
    });

export const initAnalyzerEpic = (action$, store, { api, adaptAnalyses }) =>
  action$
    .ofType(actions.INIT_ANALYZER_REQUEST)
    .mergeMap(({ payload: { analysisId, observationPeriod } }) =>
      api.users
        .getUserData$()
        .map(response => adaptAnalyses(response))
        .mergeMap(normalized => [
          actions.initAnalyzerSuccess(normalized),
          actions.setSelected(analysisId),
          ...(R.has(analysisId, normalized)
            ? [
                actions.fetchObservations({
                  aggregationType: normalized[analysisId].aggregationType,
                  prefixedResources: normalized[analysisId].resources,
                  observationPeriod
                })
              ]
            : []),
          loadSchemas()
        ])
        .catch(error =>
          Observable.of(actions.initAnalyzerFailure(error), apiError(error))
        )
    );

export const deleteAnalysisEpic = (action$, store, { api, adaptAnalyses }) =>
  action$
    .ofType(actions.DELETE_ANALYSIS_REQUEST)
    .mergeMap(({ payload: analysis }) =>
      api.users
        .updateUserData$(analysisUserData(analysis.name, null))
        .map(response => adaptAnalyses({ data: response }))
        .mergeMap(normalized => [
          actions.deleteAnalysisSuccess(normalized),
          push(`/analyze/untitled`),
          actions.createAnalysis(),
          setNotificationMessage(`Analysis '${analysis.name}' deleted.`)
        ])
        .catch(error =>
          Observable.of(actions.deleteAnalysisFailure(error), apiError(error))
        )
    );

export const fetchResourceMappingEpic = (
  action$,
  store,
  { api, adaptThingTypeByThingName }
) => {
  return action$
    .ofType(FETCH_RESOURCE_MAPPING_REQUEST)
    .mergeMap(({ payload }) => {
      const thingNamesAndResourcePaths = getThingNamesAndResourcePaths(payload);
      const thingNames = R.pipe(
        R.pluck("thingName"),
        R.uniq
      )(thingNamesAndResourcePaths);

      return Observable.merge(
        ...thingNames.map(thingName =>
          Observable.of(getThingDetails(thingName))
        ),
        api.things
          .findThingTypeIdsByThingNames$(thingNames)
          .map(response => adaptThingTypeByThingName(response))
          .map(thingTypeByThingName =>
            fetchResourceMappingSuccess({ thingTypeByThingName })
          )
      );
    })
    .catch(error =>
      Observable.of(fetchResourceMappingFailure(error), apiError(error))
    );
};
