import React from "react";
import PropTypes from "prop-types";
import { pipe } from "ramda";
import Tooltip from "@material-ui/core/Tooltip";
import { hasThingShadowResources } from "ducks/thing/thing_selectors";
import { isThingParentThing, isThingSubThing } from "utils/thing_utils";
import * as imu from "utils/immutable_utils";
import * as themeColors from "themes/colors";
import * as thingIcons from "./thing_icons";
import ThingStatusList from "./styled/thing_status_list";
import ThingStatusListItem from "./styled/thing_status_list_item";

const colors = {
  soft: themeColors.colorDustyGrayApprox,
  green: themeColors.colorHarlequinApprox
};

const withDataIcon = (iconStyle, thing, thingShadow) => icons =>
  (thingShadow && hasThingShadowResources(thingShadow)) ||
  (thing && imu.getIn(thing, ["state"]) != null)
    ? icons
    : icons.concat({
        tooltip: "No data available.",
        icon: (
          <thingIcons.NoData
            data-test="things-icons-no-data"
            style={iconStyle}
          />
        )
      });

const withConnectionIcon = (iconStyle, connectionStatus) => icons => {
  switch (connectionStatus) {
    case 1:
      return icons.concat({
        tooltip: "Gateway Online", // limbo state, we have a connection but device is not responding as expected
        icon: (
          <thingIcons.Gateway
            data-test="things-icons-gateway"
            style={iconStyle}
          />
        )
      });
    case 2:
      return icons.concat({
        tooltip: "Online",
        icon: (
          <thingIcons.Online
            data-test="things-icons-online"
            style={{ ...iconStyle, color: colors.green }}
          />
        )
      });
    case 4:
      return icons.concat({
        tooltip: "Unknown connection status", // unknownStatus
        icon: (
          <thingIcons.Unknown
            data-test="things-icons-unknown"
            style={iconStyle}
          />
        )
      });
    case 0:
    default:
      return icons.concat({
        tooltip: "Offline",
        icon: (
          <thingIcons.Offline
            data-test="things-icons-offline"
            style={iconStyle}
          />
        )
      });
  }
};

const withSubthingRelationIcon = (iconStyle, thing) => icons => {
  if (isThingParentThing(thing)) {
    return icons.concat({
      tooltip: "Has networked things",
      icon: <thingIcons.ParentThing style={iconStyle} />
    });
  } else if (isThingSubThing(thing)) {
    return icons.concat({
      tooltip: "Networked thing",
      icon: <thingIcons.NetworkedThing style={iconStyle} />
    });
  }
  return icons;
};
const skip = v => v;

const getStatusList = props => {
  const iconStyle = props.isBigSize
    ? { width: "24px", height: "24px" }
    : { width: "16px", height: "16px" };
  return pipe(
    props.subThingInfo
      ? withSubthingRelationIcon(iconStyle, props.thing)
      : skip,
    props.dataInfo
      ? withDataIcon(iconStyle, props.thing, props.thingShadow)
      : skip,
    props.connectionInfo
      ? withConnectionIcon(iconStyle, props.connectionStatus)
      : skip
  )([]);
};

const ThingStatusBar = props => (
  <ThingStatusList className={props.className} isBigSize={props.isBigSize}>
    {getStatusList(props).map((status, i) => (
      <ThingStatusListItem key={i} isBigSize={props.isBigSize}>
        <Tooltip title={status.tooltip}>{status.icon}</Tooltip>
      </ThingStatusListItem>
    ))}
  </ThingStatusList>
);

ThingStatusBar.propTypes = {
  thing: PropTypes.object,
  thingShadow: PropTypes.object,
  connectionStatus: PropTypes.number,
  isBigSize: PropTypes.bool,
  className: PropTypes.string,
  dataInfo: PropTypes.bool,
  connectionInfo: PropTypes.bool,
  subThingInfo: PropTypes.bool
};

ThingStatusBar.defaultProps = {
  isBigSize: false,
  connectionStatus: -1,
  dataInfo: true,
  connectionInfo: true,
  subThingInfo: true
};

export default ThingStatusBar;
