import React, { Component } from "react";
import { Link } from "react-router";

class NotFoundContainer extends Component {
  render() {
    return (
      <section>
        <h3>404 not found</h3>
        <Link to="/">Home</Link>
      </section>
    );
  }
}

export default NotFoundContainer;
