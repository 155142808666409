import React from "react";
import PropTypes from "prop-types";
import { compose, mapProps, setPropTypes, pure } from "react-recompose";
import styled from "styled-components";

const Base = styled.div`
  flex: 1 auto;
  ${props => props.center && "text-align: center;"};
  ${props => props.uppercase && "text-transform: uppercase;"};
`;
const constructors = {
  h1: styled(Base)`
    font-size: 24px;
  `,
  h3: styled(Base)`
    font-size: 24px;
    margin: 10px 0;
  `,
  h4: styled(Base)`
    font-size: 18px;
    margin: 10px 0;
  `,
  h5: styled(Base)`
    font-size: 16px;
  `
};

const Label = ({ Component, ...props }) => <Component {...props} />;

const propTypes = {
  center: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.oneOf(Object.keys(constructors)).isRequired,
  uppercase: PropTypes.bool
};

Label.propTypes = {
  ...propTypes,
  Component: PropTypes.func
};

export default compose(
  setPropTypes(propTypes),
  mapProps(props => ({
    ...props,
    Component: constructors[props.type]
  })),
  pure
)(Label);
