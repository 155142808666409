import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WidgetEdit from "components/widget/edit/widget_edit";
import { withModalHandlersAndBranch } from "hocs/modal_hocs";
import { equals } from "ramda";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import {
  compose,
  pure,
  shouldUpdate,
  withProps,
  withStateHandlers
} from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";

const getDesiredModalState = ({ type, widget, params }) => ({
  resourceId: resourceIdFromWidget(widget),
  thingName: params.thingName,
  thingType: params.thingType,
  subthing: params.subthing,
  subThingType: params.subThingType,
  type
});

export const WidgetHeaderComp = ({
  handleOpenWidgetMenu,
  handleCloseWidgetMenu,
  widgetLabel,
  isMovingWidgets,
  includeEditMenu = true,
  shouldShowReadMenu,
  shouldShowEditMenu,
  menuItems,
  anchorEl = null,
  children
}) => (
  <CardHeader
    title={widgetLabel}
    titleTypographyProps={{ variant: "h6" }}
    action={
      <React.Fragment>
        {children}
        {(includeEditMenu & shouldShowEditMenu || shouldShowReadMenu) && (
          <React.Fragment>
            <Tooltip
              title={isMovingWidgets ? "Widget Edit" : "Resource options"}
              disableFocusListener
            >
              <IconButton
                data-test={`widget-menu__open`}
                onClick={handleOpenWidgetMenu}
                onMouseDown={event => {
                  event.stopPropagation();
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>

            <Menu
              open={anchorEl !== null}
              anchorEl={anchorEl}
              onClose={handleCloseWidgetMenu}
            >
              {menuItems}
            </Menu>
          </React.Fragment>
        )}
      </React.Fragment>
    }
  />
);

WidgetHeaderComp.propTypes = {
  isSetable: PropTypes.bool,
  resourceIsSettable: PropTypes.bool,
  isPending: PropTypes.bool,
  isMovingWidgets: PropTypes.bool,
  widgetLabel: PropTypes.node,
  openEdit: PropTypes.bool,
  children: PropTypes.node,
  handleOpenWidgetMenu: PropTypes.func,
  handleCloseWidgetMenu: PropTypes.func,
  deleteEditModeWidget: PropTypes.func,
  openModalWidget: PropTypes.func,
  openModalDesired: PropTypes.func,
  anchorEl: PropTypes.object,
  params: PropTypes.object,
  widget: PropTypes.object,
  includeEditMenu: PropTypes.bool,
  shouldShowReadMenu: PropTypes.bool,
  shouldShowEditMenu: PropTypes.bool,
  menuItems: PropTypes.array
};

const _withWidgetMenuStateHandlers = withStateHandlers(
  { anchorEl: null },
  {
    handleOpenWidgetMenu: () => evt => ({ anchorEl: evt.target }),
    handleCloseWidgetMenu: () => () => ({ anchorEl: null })
  }
);

const _withMenuItems = withProps(
  ({
    shouldShowEditMenu,
    isPending,
    widget,
    handleCloseWidgetMenu,
    openModalWidget,
    deleteEditModeWidget,
    openModalDesired,
    params
  }) => ({
    menuItems: shouldShowEditMenu
      ? [
          <MenuItem
            index={0}
            key="0"
            data-test="widget-menu__edit"
            onMouseDown={event => {
              event.stopPropagation();
            }}
            onClick={() => {
              handleCloseWidgetMenu();
              openModalWidget({ open: true, widget, type: "edit" });
            }}
          >
            Edit
          </MenuItem>,
          <MenuItem
            index={1}
            key="1"
            data-test="widget-menu__delete"
            onMouseDown={event => {
              event.stopPropagation();
            }}
            onClick={() => {
              handleCloseWidgetMenu();
              deleteEditModeWidget(widget);
            }}
          >
            Delete
          </MenuItem>
        ]
      : [
          <MenuItem
            key="0"
            onClick={() => {
              handleCloseWidgetMenu();
              openModalDesired(
                getDesiredModalState({
                  widget,
                  params,
                  type: isPending ? "reject" : "set"
                })
              );
            }}
          >
            {isPending ? "Reject" : "Set"}
          </MenuItem>
        ]
  })
);

export default compose(
  withRouter,
  withProps(
    ({
      widget,
      resource,
      widgetLabel,
      isMovingWidgets,
      includeEditMenu,
      resourceIsSettable
    }) => ({
      widgetLabel: widgetLabel || imu.get(widget, "label", ""),
      isPending: resource ? resource.get("pending") : false,
      widgetId: widgetProp(widget, "id"),
      shouldShowEditMenu: isMovingWidgets && includeEditMenu,
      shouldShowReadMenu:
        !isMovingWidgets &&
        Boolean(widgetProp(widget, "isSetable", false)) &&
        resourceIsSettable
    })
  ),
  withModalHandlersAndBranch({
    name: "Widget",
    modal: WidgetEdit,
    handlers: {
      handleCancelWidget: ({ closeModalWidget }) => () => {
        closeModalWidget();
      }
    }
  }),
  _withWidgetMenuStateHandlers,
  shouldUpdate(
    (props, next) =>
      props.shouldShowReadMenu !== next.shouldShowReadMenu ||
      props.anchorEl !== next.anchorEl ||
      props.isSetable !== next.isSetable ||
      props.isPending !== next.isPending ||
      props.isMovingWidgets !== next.isMovingWidgets ||
      props.includeEditMenu !== next.includeEditMenu ||
      props.widgetLabel !== next.widgetLabel ||
      !equals(props.modalState, next.modalState)
  ),
  _withMenuItems,
  pure
)(WidgetHeaderComp);
