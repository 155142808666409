import PropTypes from "prop-types";
import { Map } from "immutable";
import { ifElse, has } from "ramda";
import {
  compose,
  mapProps,
  setPropTypes,
  branch,
  renderNothing
} from "react-recompose";
import styled from "styled-components";
import * as colors from "themes/colors";

const ErrorMsg = styled.code`
  font-family: telenor-regular;
  color: ${colors.telenorErrorTextColor};
`;

const getErrorString = ifElse(
  a => Map.isMap(a),
  a => a.getIn(["errorMessage", "message"]),
  ifElse(
    has("errorMessage"),
    a => a.errorMessage.message,
    a => `${a}`
  )
);

export default compose(
  setPropTypes({
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  mapProps(({ error, ...rest }) => ({
    children: getErrorString(error || ""),
    ...rest
  })),
  branch(props => !props.children, renderNothing)
)(ErrorMsg);
