import getWidgetBase from "../get_widget_base";
import ResourceOverviewWidget from "./resource_overview_widget";

export const getWidget = props => {
  return getWidgetBase({
    ...props,
    isRealtime: true,
    footer: " ",
    widgetComp: ResourceOverviewWidget
  });
};
