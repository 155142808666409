import React from "react";
import getWidgetBase from "../get_widget_base";
import Footer from "../observation_widget_footer";
import TableWidget from "./table_widget_container";

export const getWidget = props => {
  const outProps = {
    ...props,
    footer: <Footer {...props} />,
    widgetComp: TableWidget
  };

  return getWidgetBase(outProps);
};
