import * as R from "ramda";
import { normalize, schema } from "normalizr";
import { getResource } from "utils/resource_utils";
import { flatten } from "utils/general_utils";
import { resourceStateString } from "routes/analyze/helpers";

const adapt = ({ resources, thingId, response }) => {
  const resourceObservations = {};
  resources.forEach(resource => (resourceObservations[resource] = []));
  response.hits.hits.forEach(item => {
    const source = item._source;
    const timestamp = source.timestamp;
    flatten(source.state, (node, key, value, nodeKey, path) => {
      const resourceKey = path.join("/");
      const observation = {
        id: `${item._id}-${thingId}-${path.join("-")}`,
        value,
        timestamp
      };
      resourceObservations[resourceKey] = resourceObservations[
        resourceKey
      ].concat(observation);
    });
  });
  return resourceObservations;
};

export const adaptAggregatedResponse = ({ resources, thingId, response }) => {
  const buckets = response.aggregations.hist.buckets;
  const resourceObservations = {};

  resources.forEach(resource => (resourceObservations[resource] = []));

  buckets.forEach(bucket => {
    resources.forEach(resourceName => {
      const valuePath = [resourceStateString(resourceName), "value"];
      const value = R.path(valuePath, bucket);
      if (value != null) {
        const id = `${bucket.key}-${thingId}-${resourceName}`;
        resourceObservations[resourceName].push({
          id,
          value,
          timestamp: bucket.key
        });
      }
    });
  });
  return resourceObservations;
};

export const adaptRawObservations = ({ thingId, response, resources }) => {
  const resourceObservations = adapt({ response, thingId, resources });
  const _resources = Object.keys(resourceObservations).map(resource => {
    const resourcePathId = getResource(resource, resources);
    return {
      id: `${thingId}/${resourcePathId}`,
      rawObservations: resourceObservations[resource]
    };
  });

  const adaptedResponse = {
    id: thingId,
    resources: _resources
  };

  const observation = new schema.Entity("rawObservations");

  const resource = new schema.Entity("resources", {
    rawObservations: [observation]
  });

  const thing = new schema.Entity("things", {
    resources: [resource]
  });

  return normalize(adaptedResponse, thing);
};

export const adaptAggregatedObservations = ({
  thingId,
  response,
  resources
}) => {
  const adaptedObservations = adaptAggregatedResponse({
    response,
    thingId,
    resources
  });
  const resourceObservations = Object.keys(adaptedObservations).map(
    resource => {
      return {
        id: `${thingId}/${resource}`,
        aggregatedObservations: adaptedObservations[resource]
      };
    }
  );
  const adaptedResponse = {
    id: thingId,
    resources: resourceObservations
  };

  const observation = new schema.Entity("aggregatedObservations");

  const resource = new schema.Entity("resources", {
    aggregatedObservations: [observation]
  });

  const thing = new schema.Entity("things", {
    resources: [resource]
  });
  const normalized = normalize(adaptedResponse, thing);
  return normalized;
};
