import { compose, withHandlers, withProps } from "react-recompose";
import ViewModePicker from "./viewmode_picker";

import * as R from "ramda";

export const withSelectedViewMode = withHandlers({
  setSelectedViewMode: props => event => {
    props.onChange(event.target.value);
  }
});

export const adjustViewModes = viewModes =>
  R.pipe(
    R.groupBy(R.prop("domain")),
    R.applySpec({
      personal: R.prop("null"),
      domain: R.omit(["null"])
    })
  )(viewModes);

const withViewModes = withProps(({ viewModes }) => {
  return {
    viewModes: adjustViewModes(viewModes)
  };
});

export default compose(withViewModes, withSelectedViewMode)(ViewModePicker);
