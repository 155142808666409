import styled from "styled-components";
import * as colors from "themes/colors";

export default styled.h4`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.colorCelesteApprox};
  box-sizing: border-box;
  height: 60px;
  left: 0;
  line-height: 60px;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  right: 0;
  top: 0;
  z-index: 1;
`;
