import { randomValue } from "utils/general_utils";
import { isNil } from "ramda";
import { startGeoPoint } from "../utils";
import { TYPE_FLOAT, TYPE_DOUBLE, TYPE_GEOPOINT } from "../types";
import { latLooper, longLooper } from "utils/latlng_utils";

export default (type, params, { oldValue }) => {
  switch (type) {
    case TYPE_FLOAT:
    case TYPE_DOUBLE: {
      const { low, high, value, precision } = params;
      if (isNil(oldValue)) return parseFloat(value.toFixed(precision));
      return parseFloat((randomValue(low, high) + oldValue).toFixed(precision));
    }
    case TYPE_GEOPOINT: {
      const { lat_low, lat_high, lon_low, lon_high, precision } = params;
      const a = startGeoPoint(params.lat, params.lon, oldValue);
      if (!oldValue)
        return `${a[0].toFixed(precision)},${a[1].toFixed(precision)}`;
      const change_lat = randomValue(lat_low, lat_high);
      const change_lon = randomValue(lon_low, lon_high);

      const b = [change_lat + parseFloat(a[0]), change_lon + parseFloat(a[1])];

      const ab = [b[0] - a[0], b[1] - a[1]];
      const norm = Math.sqrt(Math.pow(ab[0], 2) + Math.pow(ab[1], 2)) || 1;
      // Normalized vector
      ab[0] = ab[0] / norm;
      ab[1] = ab[1] / norm;
      const p = [a[0] + ab[0], a[1] + ab[1]];

      return `${latLooper(p[0]).toFixed(precision)},
        ${longLooper(p[1]).toFixed(precision)}
      `;
    }
    default: {
      const { low, high, value } = params;
      if (isNil(oldValue)) return value;
      return Math.floor(randomValue(low, high) + oldValue + 0.5);
    }
  }
};
