import * as R from "ramda";

export const inRange = (value, lowerInclusive, higherInclusive) =>
  value >= lowerInclusive && value <= higherInclusive;

export const keyWithValueInRange = (value, range) =>
  R.pipe(
    R.keys,
    R.find(R.pipe(R.split("-"), v => inRange(value, ...v)))
  )(range);
