import { getWidgetComponent } from "../get_widget_base";
import ThingCredentialsWidget from "./thing_credentials_widget";

export const getWidget = props => {
  return getWidgetComponent({
    ...props,
    includeFooter: false,
    noResize: false,
    fixedHeader: true,
    widgetComp: ThingCredentialsWidget
  });
};
