import { graphql } from "@apollo/client/react/hoc";
import {
  DEFAULT_THINGTYPE_VIEWMODE_QUERY,
  DELETE_VIEW_MODE_MUTATION,
  THING_TYPE_VIEWMODES_QUERY
} from "graphql/queries";
import { withModalHandlers } from "hocs/modal_hocs";
import * as R from "ramda";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose, withPropsOnChange, withState } from "react-recompose";
import { ViewModeEditModalContainer } from "routes/main/things/modals/";
import * as pathUtils from "utils/path_utils";
import DashboardToolbar from "./dashboard_toolbar";
import { userCanUpdateDashboardForDomain } from "ducks/dashboard/dashboard_selectors";

const mapStateToProps = (state, props) => {
  const {
    saveGrid,
    setIsMovingWidgets,
    restoreUndoWidgets,
    deleteViewModeMutation,
    dispatch,
    location: { query, pathname },
    dashboardViewMode,
    openModalViewModeCreateEdit,
    setDeleteDialogOpen,
    setMenuAnchor,
    thingTypeId
  } = props;
  return {
    ...props,
    dashboardIsReadOnly: R.propOr(false, "isReadOnly")(dashboardViewMode),
    userCanUpdateDashboard: userCanUpdateDashboardForDomain(
      state,
      R.propOr("", "domain")(dashboardViewMode)
    ),
    handleSaveDashboard: () => {
      saveGrid();
    },
    handleUndoDashboard: () => {
      restoreUndoWidgets();
      setIsMovingWidgets(false);
    },
    handleEditDashboard: () => {
      setMenuAnchor(undefined);
      openModalViewModeCreateEdit({ isNew: false });
    },
    handleNewDashboard: () => {
      openModalViewModeCreateEdit({ isNew: true });
    },
    handleDeleteDashboard: () => {
      setIsMovingWidgets(false);
      deleteViewModeMutation({
        variables: { viewModeId: dashboardViewMode.id },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: THING_TYPE_VIEWMODES_QUERY,
            variables: { thingType: thingTypeId }
          },
          {
            query: DEFAULT_THINGTYPE_VIEWMODE_QUERY,
            variables: {
              thingType: thingTypeId,
              preferredViewModeId: "DefaultView"
            }
          }
        ],
        update: () => {
          setDeleteDialogOpen(false);
          setMenuAnchor(undefined);
          dispatch(
            push({
              pathname: pathUtils.replaceViewMode(pathname, "DefaultView"),
              query
            })
          );
        }
      });
    }
  };
};

export default compose(
  graphql(DELETE_VIEW_MODE_MUTATION, { name: "deleteViewModeMutation" }),
  graphql(THING_TYPE_VIEWMODES_QUERY, {
    name: "thingTypeViewModesQuery",
    options: props => ({
      variables: { thingType: props.thingTypeId }
    })
  }),
  withPropsOnChange(["thingTypeViewModesQuery"], props => ({
    dashboardViewModes: R.pathOr(
      [],
      ["thingTypeViewModesQuery", "allViewModes", "viewModes"],
      props
    )
  })),
  withState("menuAnchorEl", "setMenuAnchor", undefined),
  withState("thingListAnchorEl", "setThingListAnchor", undefined),
  withState("deleteDialogOpen", "setDeleteDialogOpen", false),
  withModalHandlers({
    name: "ViewModeCreateEdit",
    modal: ViewModeEditModalContainer
  }),
  connect(mapStateToProps)
)(DashboardToolbar);
