import Loadable from "react-loadable";
import { RouteLoading } from "components/telenor/loading";

export const Layout = Loadable({
  loader: () =>
    import(/* webpackChunkName: "route-analyze"*/ "layouts/default"),
  loading: RouteLoading
});

export const Sidebar = Loadable({
  loader: () => import(/* webpackChunkName: "route-analyze"*/ "./sidebar"),
  loading: RouteLoading
});

export const AnalyzePageContainer = Loadable({
  loader: () => import(/* webpackChunkName: "route-analyze"*/ "./container"),
  loading: RouteLoading
});
