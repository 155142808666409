import * as R from "ramda";
import { unImmute } from "utils/immutable_utils";

const hasResources = resources => unImmute(resources || []).length > 0;

const asRequestComparisonObject = action => ({
  aggregationType: R.path(["payload", "aggregationType"], action),
  prefixedResources: unImmute(
    R.pathOr([], ["payload", "prefixedResources"], action)
  ),
  observationPeriod: unImmute(
    R.pathOr({}, ["payload", "observationPeriod"], action)
  )
});

export const observationRequestsEqual = (x, y) =>
  R.equals(asRequestComparisonObject(x), asRequestComparisonObject(y));

export const isValidObservationRequest = R.pipe(
  R.pathOr({}, ["payload"]),
  R.allPass([
    R.has("observationPeriod"),
    R.has("prefixedResources"),
    R.pathSatisfies(hasResources, ["prefixedResources"])
  ])
);

export const mergeNormalizedObservations = R.reduce(
  R.mergeDeepWith(R.concat),
  {}
);

export const analysisUserData = (name, analysis) => ({
  tcxn: {
    analyses: {
      [name]: analysis
    }
  }
});

export const getThingNamesAndResourcePaths = analysesResources =>
  (analysesResources || []).map(str => {
    const parts = str.split("/");
    return { thingName: R.head(parts), resourcePath: R.tail(parts).join("/") };
  });

export const getResourceMappings = (
  resourcesByThingType,
  thingNameAndResourcePaths,
  thingTypeLookup
) => {
  return thingNameAndResourcePaths.reduce(
    (acc, { thingName, resourcePath }) => {
      const thingType = thingTypeLookup[thingName];
      const thingTypeResources = resourcesByThingType[thingType];
      const pathParts = resourcePath.split("/");
      const isSubthing = pathParts.length > 1 && pathParts[0] !== "tcxn";
      const expression = isSubthing
        ? new RegExp(
            `^${thingType}/subthing/[^/]+/${R.tail(pathParts).join("/")}`
          )
        : new RegExp(`^${thingType}/${resourcePath}`);

      const actualResourceId = thingTypeResources.find(path =>
        expression.test(path)
      );

      const key = `${thingName}/${resourcePath}`;
      return { ...acc, [key]: actualResourceId };
    },
    {}
  );
};
