import CardContent from "@material-ui/core/CardContent";
import { always, anyPass, cond, equals, pathOr, pipe, toLower, T } from "ramda";
import styledMui from "utils/styled_mui";

// overflow:auto is required for IE11 to render things correctly.
// However, it cannot be used on _all_ widget containers, some widgets will cause scrollbars to appear.
// Further, we want to use overflow: hidden in most other cases to avoid SizeMe issues.
export const cardContentOverflow = pipe(
  pathOr("", ["widgetType"]),
  toLower,
  cond([
    [anyPass([equals("events"), equals("thingcredentials")]), always("auto")],
    [equals("allthings"), always("visible")],
    [equals("ruleedit"), always("visible")],
    [T, always("hidden")]
  ])
);

export default styledMui(CardContent, "noPadding", "widgetType")`
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${props => props.noPadding && "padding: 0 !important;"}
  ${props => `overflow: ${cardContentOverflow(props)};`}
`;
