import { Observable } from "rxjs";
import { and, equals, not, pathOr, pipe, propOr } from "ramda";
import { push } from "react-router-redux";
import { setUserName, userConsentNeeded } from "./auth";

export const adaptRegisterPayload = ({ user, awsMarketplaceToken }) =>
  awsMarketplaceToken
    ? { ...user, "x-amzn-marketplace-token": awsMarketplaceToken }
    : user;

export const _smsVerificationIsEnabled = response =>
  and(
    pipe(propOr(false, "UserConfirmed"), not)(response),
    pipe(
      pathOr("", ["CodeDeliveryDetails", "DeliveryMedium"]),
      equals("SMS")
    )(response)
  );

export const _extractErrorKey = pipe(
  pathOr("", ["errorMessage", "messageKey"])
);

export const _errorCases = ({ userName }) => ({
  USER_RESET_PASSWORD: Observable.of(
    setUserName(userName),
    push("/setPassword")
  ),
  USER_CONSENT_REQUIRED: Observable.of(userConsentNeeded())
});

export const getStackAlias = (location, manifest) => {
  if (location.hostname === "localhost") {
    return manifest.StackName === "prod" ? "dev" : manifest.StackName;
  }

  return location.hostname.substr(0, location.hostname.indexOf("."));
};

export const getOauthEndpoint = (location, manifest) =>
  `https://mic-${getStackAlias(
    location,
    manifest
  )}.auth.eu-west-1.amazoncognito.com`;
