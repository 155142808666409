/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import match from "./match";
import parse from "autosuggest-highlight/parse";
import InputComponent from "./suggester_input";
import { Loading } from "components/notification";
import {
  SUGGEST_RESOURCES,
  SUGGEST_THINGS,
  SUGGEST_THING_TYPES,
  SUGGEST_DOMAINS,
  SUGGEST_ALL
} from "ducks/suggester";
import { modalStyle } from "./styles";

const SuggestionItem = ({
  suggestion,
  suggestionIndex,
  query,
  styles,
  onKeyUpDownFocus,
  skipIcon
}) => {
  const text = R.pipe(
    R.type,
    R.when(R.equals("Object"), R.always(R.prop("text")(suggestion)))
  )(suggestion);
  query = R.isNil(query) ? "" : query;
  const matches = match(text, query, true);
  const parts = parse(text, matches);
  return (
    <div
      key={suggestion.id}
      onMouseOver={() => {
        onKeyUpDownFocus(suggestionIndex);
      }}
      style={styles.container}
    >
      <span data-test={`suggestion-label`} style={styles.label}>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={styles.highlight}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)} style={styles.normal}>
              {part.text}
            </span>
          );
        })}
      </span>
      {!skipIcon && (
        <span data-test="suggestion-icon" style={styles.icon}>
          {suggestion.icon}
        </span>
      )}
    </div>
  );
};

SuggestionItem.propTypes = {
  query: PropTypes.string,
  itemIcon: PropTypes.func,
  isHighlighted: PropTypes.bool,
  suggestion: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func
  ]),
  styles: PropTypes.object,
  skipIcon: PropTypes.bool
};

SuggestionItem.defaultProps = {
  itemIcon: () => undefined,
  styles: modalStyle.suggestionItem,
  skipIcon: false
};

const _isSelected = (i, focusedSuggestion) => i === focusedSuggestion;
const _menuItemStyle = (i, focusedSuggestion, styles) =>
  _isSelected(i, focusedSuggestion)
    ? R.path(["menuItem", "highlighted"], styles)
    : R.path(["menuItem", "normal"], styles);

const menuMaxHeight = anchorEl => {
  if (!anchorEl) return "0px";
  const menuBottomMargin = 30;
  const anchorRect = anchorEl.getBoundingClientRect();

  const maxHeight = Math.max(
    100,
    window.innerHeight - anchorRect.bottom - menuBottomMargin
  );
  return `${maxHeight}px`;
};

// --- menu
const SuggestionsMenu = ({
  filteredSuggestions,
  value,
  handleSuggestionSelection,
  focusedSuggestion,
  styles,
  handleMenuEsc,
  dataTest = "",
  isLoading,
  onKeyUpDownFocus,
  skipIcon
}) =>
  isLoading ? (
    <div style={styles.menuItemLoadingContainer}>
      <Loading size={30} thickness={2} isLoading={isLoading} />
    </div>
  ) : (
    <MenuList
      variant={"menu"}
      onEscKeyDown={handleMenuEsc}
      data-test={`${dataTest}-suggester-menu`}
      autoFocus={false}
      autoFocusItem={false}
    >
      {filteredSuggestions && filteredSuggestions.length === 0 && (
        <MenuItem disabled>No results found.</MenuItem>
      )}
      {filteredSuggestions &&
        filteredSuggestions.slice(0, 100).map((suggestion, i) => (
          <MenuItem
            selected={_isSelected(i, focusedSuggestion)}
            style={_menuItemStyle(i, focusedSuggestion, styles)}
            key={`suggestion-${suggestion.id}`}
            data-test={`${dataTest}-${suggestion.id}-menuItem`}
            onClick={event => handleSuggestionSelection(event, suggestion)}
            autoFocus={false}
          >
            <SuggestionItem
              suggestion={suggestion}
              suggestionIndex={i}
              query={value}
              styles={styles.suggestionItem}
              onKeyUpDownFocus={onKeyUpDownFocus}
              skipIcon={skipIcon}
            />
          </MenuItem>
        ))}
      {filteredSuggestions && filteredSuggestions.length > 100 && (
        <MenuItem disabled key={"many-items-notice"}>
          Showing the first 100 items. Search to find more.
        </MenuItem>
      )}
    </MenuList>
  );

const SuggestionsContainer = ({
  anchorEl,
  setAnchorEl,
  menuFocus,
  popover = true,
  filteredSuggestions,
  value,
  handleSuggestionSelection,
  focusedSuggestion,
  styles,
  handleMenuEsc,
  dataTest = "",
  isLoading,
  onKeyUpDownFocus,
  skipIcon,
  containerYPosition,
  floatingLabelText,
  label
}) => {
  const open = Boolean(anchorEl);
  const onClose = () => setAnchorEl(null);
  //if (!open) return null;
  if (popover) {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        disableAutoFocus={!menuFocus}
        className={`${dataTest}-popover`}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          "data-test": "Suggester-Popover",
          style: { maxHeight: menuMaxHeight(anchorEl), marginTop: "4px" }
        }}
        canAutoPosition={false}
        disableRestoreFocus
      >
        <SuggestionsMenu
          filteredSuggestions={filteredSuggestions}
          value={value}
          handleSuggestionSelection={handleSuggestionSelection}
          focusedSuggestion={focusedSuggestion}
          styles={styles}
          handleMenuEsc={handleMenuEsc}
          dataTest={dataTest}
          isLoading={isLoading}
          onKeyUpDownFocus={onKeyUpDownFocus}
          skipIcon={skipIcon}
          containerYPosition={containerYPosition}
          floatingLabelText={floatingLabelText}
          label={label}
        />
      </Popover>
    );
  }
  return (
    <SuggestionsMenu
      menuFocus={menuFocus}
      filteredSuggestions={filteredSuggestions}
      value={value}
      handleSuggestionSelection={handleSuggestionSelection}
      focusedSuggestion={focusedSuggestion}
      styles={styles}
      handleMenuEsc={handleMenuEsc}
      dataTest={dataTest}
      isLoading={isLoading}
      onKeyUpDownFocus={onKeyUpDownFocus}
      skipIcon={skipIcon}
      container={containerYPosition}
      floatingLabelText={floatingLabelText}
      label={label}
    />
  );
};

const Suggester = props => {
  return (
    <div style={props.styles.wrapper}>
      <InputComponent
        setAnchorEl={props.setAnchorEl}
        // value state - multi enhancer
        setValue={props.setValue}
        setTextFieldRef={props.setTextFieldRef}
        setTextFieldFocus={props.setTextFieldFocus}
        onKeyUpDownFocus={props.onKeyUpDownFocus}
        setMenuFocus={props.setMenuFocus}
        setFocus={props.setFocus}
        resetTextField={props.resetTextField}
        value={props.value ? props.value : ""}
        textField={props.textField}
        autoFocus={props.autoFocus}
        isFocused={props.isFocused}
        filteredSuggestions={props.filteredSuggestions}
        // chip state handlers
        chips={props.chips}
        isLoading={props.isLoading}
        disabled={props.disabled}
        removeChip={props.removeChip}
        selectedSuggestion={props.selectedSuggestion}
        focusedChip={props.focusedChip}
        focusedSuggestion={props.focusedSuggestion}
        showAllChips={props.showAllChips}
        setShowAllChips={props.setShowAllChips}
        maxChips={props.maxChips}
        // with handlers - multi enhancer
        handleInputChange={props.handleInputChange}
        handleInputBlur={props.handleInputBlur}
        setFocusedChip={props.setFocusedChip}
        // input props
        id={props.id}
        dataTest={props.dataTest}
        type={"search"}
        helperText={props.helperText}
        errorText={props.errorText}
        onRequestDelete={props.onRequestDelete}
        underlineShow={props.underlineShow}
        addChip={props.addChip}
        suggestions={props.suggestions}
        placeholder={props.hintText}
        floatingLabelText={props.floatingLabelText}
        floatingLabelFixed={props.floatingLabelFixed}
        single={props.single}
        textChip={
          props.single && props.textChip === undefined ? true : props.textChip
        }
        onBlur={props.onBlur}
        textFieldStyles={props.textFieldStyles}
        idPath={props.idPath}
        blurOnSelect={props.blurOnSelect}
        styles={props.styles}
        allowEmptySelected={props.allowEmptySelected}
      />
      <SuggestionsContainer
        anchorEl={props.anchorEl}
        setAnchorEl={props.setAnchorEl}
        isFocused={props.isFocused}
        menuFocus={props.menuFocus}
        value={props.value}
        focusedSuggestion={props.focusedSuggestion}
        filteredSuggestions={props.filteredSuggestions}
        onKeyUpDownFocus={props.onKeyUpDownFocus}
        // with handlers - multi enhancer
        handleSuggestionSelection={props.handleSuggestionSelection}
        handleMenuEsc={props.handleMenuEsc}
        // props
        popover={props.popover}
        isHighlighted={props.isHighlighted}
        styles={props.styles}
        dataTest={props.dataTest}
        idPath={props.idPath ? ["id"] : props.idPath}
        isLoading={props.isLoading}
        skipIcon={props.skipIcon}
        floatingLabelText={props.floatingLabelText}
        label={props.label}
      />
    </div>
  );
};

Suggester.propTypes = {
  // styles
  styles: PropTypes.object,

  // autosuggest
  alwaysRenderSuggestions: PropTypes.bool,

  // data-test
  dataTest: PropTypes.string,

  // mic
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool
  ]),
  themeName: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool
  ]),
  handleInputChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf([
    SUGGEST_RESOURCES,
    SUGGEST_THINGS,
    SUGGEST_THING_TYPES,
    SUGGEST_DOMAINS,
    SUGGEST_ALL
  ]),
  idPath: PropTypes.array,
  // popover
  popover: PropTypes.bool,

  // input field
  id: PropTypes.string,
  addChip: PropTypes.func.isRequired,
  chips: PropTypes.array,
  suggestions: PropTypes.array,
  onRequestDelete: PropTypes.func,
  autoFocus: PropTypes.bool,
  isFocused: PropTypes.bool,
  focusedChip: PropTypes.object,
  setFocusedChip: PropTypes.func,
  removeChip: PropTypes.func,
  hintText: PropTypes.string,
  floatingLabelText: PropTypes.string,
  floatingLabelFixed: PropTypes.bool,
  single: PropTypes.bool,
  textChip: PropTypes.bool,
  underlineShow: PropTypes.bool,
  setValue: PropTypes.func,
  selectedSuggestion: PropTypes.object,
  setSelectedSuggestion: PropTypes.func,
  itemIcon: PropTypes.func,
  textFieldStyles: PropTypes.object,
  blurOnSelect: PropTypes.bool,
  skipIcon: PropTypes.bool,
  allowEmptySelected: PropTypes.bool,
  disabled: PropTypes.bool,

  maxChips: PropTypes.number, //  < 1 results in unlimited, which is default
  showAllChips: PropTypes.bool,
  setShowAllChips: PropTypes.func,
  onInputChange: PropTypes.func
};

Suggester.defaultProps = {
  value: "",
  suggestions: [],
  styles: modalStyle,
  maxChips: 0
};

export default Suggester;
