import Joi from "joi-browser";

const classifications = [
  "WARNING",
  "NOTIFICATION",
  "ALARM",
  "ALL",
  "INTERNAL"
].map(e => ({ id: e, label: e, value: e }));

export default () => ({
  fields: [
    {
      id: "classification",
      label: "Classification",
      name: "classification",
      hintText: "Classification",
      options: classifications,
      type: "select"
    }
  ],
  initialModel: {
    classification: "ALL"
  },

  schema: {
    classification: Joi.string().required()
  }
});
