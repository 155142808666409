import { handleActions } from "redux-actions";
import * as nz from "utils/normalisation_utils";
import * as types from "./domain_action_types";
const initialState = { isLoading: false, error: null };
export default handleActions(
  {
    // default: state => state
    [types.DOMAINS_LIST_REQUEST]: nz.requestStart(),
    [types.DOMAINS_LIST_SUCCESS]: nz.requestSuccess(),
    [types.DOMAINS_LIST_FAILURE]: nz.requestFailure(),
    [types.DOMAINS_REQUEST_RESET]: nz.requestSuccess()
  },
  initialState
);
