import React from "react";
import PropTypes from "prop-types";

import { Form, Field, FormButtons, FieldSet } from "../index";
import FormSaveButton from "components/buttons/form_save";

import Joi from "joi-browser";

const RecoverPasswordSchema = Joi.object().keys({
  userName: Joi.string().required()
});

const PasswordRecoveryForm = ({ handleSubmit, isLoading }) => (
  <Form schema={RecoverPasswordSchema} onSubmit={handleSubmit}>
    <FieldSet>
      <Field id="userName" label="Username" />
    </FieldSet>
    <FormButtons>
      <FormSaveButton
        data-test="recover-password-button"
        fullWidth
        variant="contained"
        text="Recover"
        disabled={isLoading}
      />
    </FormButtons>
  </Form>
);

PasswordRecoveryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PasswordRecoveryForm;
