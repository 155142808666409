import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modal from "ducks/modal";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const Modal = ({ dispatch, modal, actions }) => {
  if (!modal) {
    return <span />;
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {modal.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            modal.onCancel(modal);
            actions.close();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          data-test="confirm"
          onClick={() => {
            modal.onConfirm(modal);
            actions.close();
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  modal: PropTypes.object,
  actions: PropTypes.object,
  dispatch: PropTypes.func
};

export default connect(
  ({ modal }) => ({ modal }),
  dispatch => ({ actions: bindActionCreators(modal, dispatch) })
)(Modal);
