import { fromJS } from "immutable";
import { isValidLatLng, latLngfromString } from "utils/latlng_utils";

export const adaptAggregation = aggregations => {
  const aggregationName = Object.keys(aggregations)[0];
  const aggregation = aggregations[aggregationName];
  const data =
    "buckets" in aggregation
      ? aggregation.buckets.map(item => [`${item.key}`, item.doc_count])
      : aggregation.value;

  return fromJS({
    aggregationName,
    data
  });
};

export const adaptGeoAggregation = data => {
  if (
    !data ||
    !data.aggregations ||
    !data.aggregations.geoBuckets ||
    !data.aggregations.geoBuckets.buckets
  ) {
    return fromJS({});
  }

  return fromJS(
    data.aggregations.geoBuckets.buckets.reduce((results, bucket) => {
      const bucketData = bucket.results.hits.hits
        .filter(p =>
          isValidLatLng(p._source && p._source.state && p._source.state.latlng)
        )
        .map(p => ({
          latLng: latLngfromString(p._source.state.latlng),
          properties: {
            thingName: p._source.thingName,
            thingType: p._source.thingType
          }
        }));

      return results.concat(bucketData);
    }, [])
  );
};
