import { ComposeForm } from "components/widget/edit/compose_form";
import WidgetEditObservationsAggregated from "components/widget/edit/widget_edit_observations_aggregated";
import Joi from "joi-browser";
import * as R from "ramda";
import { baseTheme } from "themes";
import WidgetEditRealtime from "../../edit/widget_edit_realtime";
import resourceTypes from "../widget_resource_types";

const defaultColor = baseTheme.palette.primary1Color;

const yAxisOptions = [
  {
    label: "Total",
    id: "yAxisOptions-total",
    value: 0,
    index: 0
  },
  {
    label: "None",
    id: "yAxisOptions-none",
    value: 1,
    index: 1
  }
];

const WidgetTimeSeries = ({ resourceId, resourceList, params }) => {
  return {
    fields: [
      {
        id: "resources",
        name: "resources",
        type: "color_picker_with_resource",
        thingType: R.prop("thingType")(params)
      },
      {
        id: "yAxisType",
        key: "yAxisType",
        options: yAxisOptions,
        label: "Y Axis type",
        type: "select"
      }
    ],
    initialModel: {
      resources:
        resourceList && resourceList.length > 0
          ? resourceList
          : resourceId
          ? [{ resourceId, color: defaultColor }]
          : [],
      yAxisType: 0
    },
    schema: {
      resources: Joi.array()
        .items(
          Joi.object({
            resourceId: Joi.string().required(),
            color: Joi.string().required()
          })
        )
        .required(),
      yAxisType: Joi.number()
    }
  };
};

export default props =>
  ComposeForm(
    WidgetTimeSeries,
    WidgetEditRealtime,
    WidgetEditObservationsAggregated
  )({
    ...props,
    resourceType: resourceTypes.NUMBER
  });
