import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import withStyles from "../styles";
import { onChange } from "../utils";
import { TYPE_FLOAT } from "../types";

const Line = validator =>
  withStyles(
    ({
      config,
      disabled,
      index,
      onStrategyParameterChange,
      errors,
      classes
    }) => {
      return (
        <React.Fragment>
          <TextField
            data-test="lat"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat",
              validator,
              true
            )}
            value={config.strategyParameters.lat}
            label="Lat (Start)"
            className={classes.textField}
            type="number"
            error={!!errors.lat}
            helperText={errors.lat}
          />
          <TextField
            data-test="lon"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon",
              validator,
              true
            )}
            value={config.strategyParameters.lon}
            label="Long (Start)"
            className={classes.textField}
            type="number"
            error={!!errors.lon}
            helperText={errors.lon}
          />
          <TextField
            data-test="lat_end"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat_end",
              validator,
              true
            )}
            value={config.strategyParameters.lat_end}
            label="Lat (End)"
            className={classes.textField}
            type="number"
            error={!!errors.lat_end}
            helperText={errors.lat_end}
          />
          <TextField
            data-test="lon_end"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon_end",
              validator,
              true
            )}
            value={config.strategyParameters.lon_end}
            label="Long (End)"
            className={classes.textField}
            type="number"
            error={!!errors.lon_end}
            helperText={errors.lon_end}
          />
          <TextField
            data-test="vel"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "vel",
              validator,
              true
            )}
            value={config.strategyParameters.vel}
            label={`Velocity (m/s)`}
            className={classes.textField}
            type="number"
            error={!!errors.vel}
            helperText={errors.vel}
          />
        </React.Fragment>
      );
    }
  );

Line.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onStrategyParameterChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default Line;
