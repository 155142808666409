import * as R from "ramda";
import * as routeActions from "react-router-redux";
import { isSubThingType } from "utils/resource_utils";
import { kebabCase } from "utils/string_utils";
import { makeResourceStatePathString } from "utils/thing_utils";
import {
  resourceUnitByIdFromEntities,
  resourceLabelByIdFromEntities
} from "ducks/schema/schema_selectors";
import { nonEmptyArray, isSuperset } from "./general_utils";

export const getNewSortingString = (currentSortingString, forColumn) => {
  const currentSorting = sortingFromString(currentSortingString);
  return currentSorting.column === forColumn && currentSorting.order === "asc"
    ? `-${forColumn}`
    : forColumn;
};

export const sortingFromString = R.ifElse(
  R.either(R.isNil, R.isEmpty),
  R.always({}),
  R.pipe(R.match(/^(-)?(.+)$/), ([, orderChar, column]) => ({
    column,
    order: orderChar === "-" ? "desc" : "asc"
  }))
);

export const sortingFromLocationQuery = R.pipe(
  R.pathOr({}, ["sort"]),
  sortingFromString
);

export const queryToUrl = query => {
  const queryToParams = Object.keys(query).reduce((all, key) => {
    return all.concat([`${key}=${query[key]}`]);
  }, []);
  return `?${queryToParams.join("&")}`;
};

export const createTableSortPathAction = ({ sorting, locationQuery }) => {
  let type = sorting.get("column");
  type = extractResourceNameFromType(type);
  const currentSorting = sortingFromLocationQuery(locationQuery);
  const path = `${location.pathname}?sort=${
    currentSorting.column === type && currentSorting.order === "asc" ? "-" : ""
  }${type}`;
  return routeActions.push(path);
};

export const checkIfVisible = field => {
  if (field.hasOwnProperty("visible")) {
    return field.visible;
  } else {
    return true;
  }
};

const extractResourceNameFromType = type => {
  const i = type.lastIndexOf("/");
  if (i > 0) {
    return type.slice(i + 1);
  } else {
    return type;
  }
};

export const linksTos = location => ({
  label: prop => ({
    pathname: `${location.pathname}/${prop.get("thingType")}/detail/${prop.get(
      "thingName"
    )}/untyped/default`
  })
});

export const resourcesToColumns = resources =>
  resources.map(resource => {
    return {
      id: resource.get("id"),
      selector: "resource",
      resource: resource.get("id"),
      label: resource.get("label", resource.get("name")),
      visible: true,
      checked: true,
      text: resource.get("name"),
      icon: resource.get("icon")
    };
  });

export const isResourceField = R.propEq("selector", "resource");

export const fieldSortString = (field, fieldTypes) => {
  if (field === undefined || field === null) {
    return undefined;
  }

  const hasValidFieldType = () => {
    const hasFieldTypes = nonEmptyArray(fieldTypes);
    return (
      (!hasFieldTypes && !R.propEq("type", "untyped", field)) ||
      (hasFieldTypes && fieldTypes.length === 1 && fieldTypes[0] !== "untyped")
    );
  };

  const hasGeoPointFieldType = () =>
    R.both(R.complement(R.isNil), R.contains("geo_point"))(fieldTypes);

  const isLabelField = R.propEq("selector", "label");
  const isLastHeardOfField = R.propEq("selector", "lastHeardFrom");
  const isSubThingResourceField = R.propSatisfies(
    r => isSubThingType(r),
    "resource"
  );
  const isStatusField = R.either(
    R.propEq("selector", "status"),
    R.propEq("selector", "Status")
  );

  const thingTypePrefix = /^([^/]+\/)/;
  const tcxnPrefix = /[^/]\/tcxn\//;
  const defaultSortString = R.prop("selector");
  const noSortString = R.always(undefined);
  const statusSortString = R.always("state.tcxn.connection_status");
  const labelSortString = R.always("label.lowercase");
  const resourceSortString = R.pipe(
    R.prop("resource"),
    R.ifElse(
      R.test(thingTypePrefix),
      R.converge(R.concat, [
        R.ifElse(
          R.test(tcxnPrefix),
          R.always(""),
          R.compose(R.head, R.match(thingTypePrefix))
        ),
        r => makeResourceStatePathString(r)
      ]),
      R.always(undefined)
    )
  );

  return R.cond([
    [isStatusField, statusSortString],
    [isLabelField, labelSortString],
    [isLastHeardOfField, noSortString],
    [hasGeoPointFieldType, noSortString],
    [
      isResourceField,
      R.ifElse(
        hasValidFieldType,
        R.ifElse(isSubThingResourceField, noSortString, resourceSortString),
        noSortString
      )
    ],
    [R.T, defaultSortString]
  ])(field);
};

export const fieldOnClickHandler = (handler, options) => {
  if (R.path(["sortString"], options)) {
    const withSingleFieldType = R.pipe(
      R.dissoc("sortFieldTypes"),
      R.assoc("sortFieldType", R.pathOr("", ["sortFieldTypes", 0], options))
    )(options);
    return () => handler(withSingleFieldType);
  }
};

// TODO: write tests
export const withResourceMetadata = R.curry((entities, field) => {
  if (field.selector !== "resource") {
    return field;
  } else {
    const { id, label } = field;
    const unit = resourceUnitByIdFromEntities(entities, id);
    return R.mergeDeepLeft(
      {
        label: resourceLabelByIdFromEntities(entities, id) || label,
        labelSecondary: unit ? `(${unit})` : undefined
      },
      field
    );
  }
});

export const selectRowsItems = R.curry((items, selectedRows) =>
  R.cond([
    [R.equals("all"), R.always(items)],
    [R.equals("none"), R.always([])],
    [R.T, R.map(R.nth(R.__, items))]
  ])(selectedRows)
);

export const selectedRowsIndices = R.curry((items, selectedRows) =>
  R.cond([
    [R.equals("all"), R.always(R.range(0, R.length(items)))],
    [R.equals("none"), R.always([])],
    [R.T, R.always(selectedRows)]
  ])(selectedRows)
);

export const actionDataTest = R.curry((toolbarDataTest, toolbarAction) =>
  R.pipe(
    R.pathOr("", ["label"]),
    kebabCase,
    R.concat(`${toolbarDataTest}-action__`)
  )(toolbarAction)
);

export const isAllSelected = (selectedIdSet, items, idPath = R.prop("id")) =>
  items.length > 0 && isSuperset(selectedIdSet, items.map(idPath));
