import { withTheme } from "@material-ui/core/styles";
import { last, path, pipe, split } from "ramda";
import { compose, mapProps } from "react-recompose";

export const immutableModelToAPIObject = theme =>
  theme.updateIn(["loginTexts"], texts => texts.filter(text => !!text)).toJS();

export const withPaletteColor = paletteColorName => Component =>
  compose(
    withTheme,
    mapProps(({ theme, ...props }) => ({
      ...props,
      color: path(["palette", "primary", paletteColorName], theme)
    }))
  )(Component);

export const logoFileName = (domain, name) =>
  `appboard_logo_${domain}.${pipe(split("."), last)(name)}`;

export const faviconFileName = (domain, name) =>
  `appboard_favicon_${domain}.${pipe(split("."), last)(name)}`;
