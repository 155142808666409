import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import networkedSvg from "assets/images/online-networked-24px.svg";
import parentSvg from "assets/images/online-parent-24px.svg";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  thingSelector: { top: "-2px" },
  thingIcon: {
    height: "16px",
    margin: "0px 10px",
    position: "relative",
    top: "2px"
  }
});

const findThing = (id, _things) =>
  _things.find(thing => thing.thingName === id);

const NetworkedThingsPicker = ({
  classes,
  things,
  selectedThingName,
  onClickThing
}) => (
  <Select
    className={classes.thingSelector}
    value={selectedThingName}
    onChange={e => {
      e.stopPropagation();
      onClickThing(findThing(e.target.value, things));
    }}
    onClick={e => e.stopPropagation()}
    data-test="networked-things-picker"
  >
    {things.map(thing => (
      <MenuItem
        key={thing.thingName}
        value={thing.thingName}
        data-test={"networked-things-picker-item__" + thing.thingName}
      >
        <img
          src={thing.hasNetworkedThings ? parentSvg : networkedSvg}
          className={classes.thingIcon}
        />
        {thing.label}
      </MenuItem>
    ))}
  </Select>
);

NetworkedThingsPicker.propTypes = {
  things: PropTypes.array,
  selectedThingName: PropTypes.string,
  onClickThing: PropTypes.func,
  classes: PropTypes.object
};

export default withStyles(styles)(NetworkedThingsPicker);
