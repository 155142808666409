import * as R from "ramda";

export const lower = R.when(R.is(String), R.invoker(0, "toLowerCase"));
export const trim = R.when(R.is(String), R.invoker(0, "trim"));

export const nonEmptyString = s => s !== null && s !== undefined && s !== "";
export const nonEmptyArray = arr => arr != null && arr.length > 0;

export const isNotNil = R.complement(R.isNil);
export const isNotEmpty = R.complement(R.isEmpty);
export const isEmptyOrNil = R.anyPass([R.isNil, R.isEmpty]);
export const isNotEmptyOrNil = R.complement(isEmptyOrNil);

export const isFunction = R.is(Function);

export const isNodeEnv = envName => process.env.NODE_ENV === envName;
export const isDevelopmentNodeEnv = () => isNodeEnv("development");

const _mapFn = (node, key, val, nodeKey, path, acc = {}, parentPath) =>
  R.is(Object, val)
    ? R.set(R.lensProp(path.join("/")), {}, acc)
    : R.set(R.lensPath([parentPath.join("/"), key]), val, acc);

export const flatten = (
  node,
  mapFn = _mapFn,
  acc = {},
  path = [],
  index = 0
) => {
  if (!node) return acc;

  Object.keys(node).forEach(key => {
    const val = node[key];
    if (R.is(Object, val)) {
      path = [...path, key];
      acc = flatten(val, mapFn, acc, path, index++);
      path = [...path.slice(0, -1)];
    } else {
      acc = mapFn(
        node,
        key,
        val,
        R.last(path),
        [...path, key],
        acc,
        path,
        index++
      );
    }
  });
  return acc;
};

export const flattenOnObj = (
  node,
  mapFn = _mapFn,
  sort,
  acc = {},
  path = [],
  state = { index: 0 }
) => {
  if (!node) return acc;
  R.pipe(
    node => (R.isNil(sort) ? R.keys(node) : sort(node)),
    R.forEach(key => {
      const val = node[key];
      if (R.is(Object, val)) {
        path = [...path, key];
        acc = mapFn(
          node,
          key,
          val,
          R.last(path),
          [...path, key],
          acc,
          path,
          state
        );
        acc = flattenOnObj(val, mapFn, sort, acc, path, state);
        path = [...path.slice(0, -1)];
      }
    })
  )(node);
  return acc;
};

export const appendUnit = (value, unit) =>
  unit == null || unit === "" ? value : `${value} ${unit}`;

export const pluralize = R.curry(
  (zeroString, singleString, multipleString, number) =>
    R.pipe(
      R.defaultTo(0),
      R.cond([
        [R.equals(0), () => zeroString],
        [R.equals(1), n => `${n} ${singleString}`],
        [R.T, n => `${n} ${multipleString}`]
      ])
    )(number)
);

export const pluralizeWithoutCounter = R.curry(
  (zeroString, singleString, multipleString, number) =>
    R.pipe(
      R.defaultTo(0),
      R.cond([
        [R.equals(0), () => zeroString],
        [R.equals(1), () => singleString],
        [R.T, () => multipleString]
      ])
    )(number)
);

// N.B: For performance reasons, this function mutates the list instead of creating a new
export const mergeOnProp = prop => (obj, list = []) => {
  const i = R.findIndex(
    R.ifElse(isNotEmptyOrNil, R.propEq(prop, obj[prop]), R.always(R.F))
  )(list);
  if (i >= 0) {
    list[i] = R.merge(obj, list[i]);
  } else {
    list.push(obj);
  }
  return list;
};

export const immutableToggleSet = (set, value) => {
  const newSet = new Set(set);

  if (set.has(value)) {
    newSet.delete(value);
  } else {
    newSet.add(value);
  }

  return newSet;
};

export const removeAllFromSet = (set, values) => {
  const newSet = new Set(set);
  (values || []).forEach(value => newSet.delete(value));
  return newSet;
};

export const addAllToSet = (set, values) => {
  const newSet = new Set(set);
  (values || []).forEach(value => newSet.add(value));
  return newSet;
};

export const isSuperset = (set, values) => {
  return (values || []).every(value => set.has(value));
};

export const normalizePhone = phone => phone && phone.replace(/[^\+\d]/g, ""); // eslint-disable-line no-useless-escape

export const randomValue = (min, max) => Math.random() * (max - min) + min;

/**
 * Plain JavaScript version of reduce,
 * https://javascript.plainenglish.io/learning-javascript-by-implementing-lodash-methods-combining-values-34246376b4b6
 *
 */

export const reduce = (collection, iteratee, accumulator) => {
  if (Array.isArray(collection)) {
    return collection.reduce(iteratee, accumulator);
  } else {
    let reduced = accumulator;
    for (const key of Object.keys(collection)) {
      iteratee(reduced, collection[key], key);
    }
    return reduced;
  }
};

/**
 * Plain JavaScript version of lodash set,
 * https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method
 */

export const set = (obj, path, value) => {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce(
    (
      a,
      c,
      i // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) >> 0 === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    obj
  )[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
};

/**
 *  Approximate functionality of lodash orderBy in plain JavaScript
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
 *
 */

export const orderBy = function(keys, orders) {
  let cb = () => 0;
  keys.reverse();
  orders.reverse();
  for (const [i, key] of keys.entries()) {
    const order = orders[i];
    if (order == "asc") {
      cb = sortBy(key, cb);
    } else if (order == "desc") {
      cb = sortByDesc(key, cb);
    } else {
      throw new Error(`Unsupported order "${order}"`);
    }
  }
  return cb;
};

export const sortBy = function(key, cb) {
  if (!cb) cb = () => 0;
  return (a, b) =>
    a[key] === null && b[key] === null
      ? 0
      : a[key] === null
      ? 1
      : b[key] === null
      ? -1
      : a[key] > b[key]
      ? 1
      : b[key] > a[key]
      ? -1
      : cb(a, b);
};

export const sortByDesc = function(key, cb) {
  if (!cb) cb = () => 0;
  return (b, a) =>
    a[key] === null && b[key] === null
      ? 0
      : a[key] === null
      ? 1
      : b[key] === null
      ? -1
      : a[key] > b[key]
      ? 1
      : b[key] > a[key]
      ? -1
      : cb(b, a);
};

/**
 * Native version of lodash PickBy
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pickby
 */

export const pickBy = object => {
  const obj = {};
  for (const key in object) {
    if (object[key]) {
      obj[key] = object[key];
    }
  }
  return obj;
};

/**
 * Filters out NaN and null values from array objects data
 *
 * used in timeseries_widget.js this function removes resources that have NaN or null values
 * in their x, y values, for resources that lacked proper y values (for e.g "latlng" in this case)
 * these undefined values would cause issues when rendering the component.
 */
export const filterNullnNaN = data =>
  data
    .map(object =>
      object.filter(o =>
        Object.values(o).every(prop => prop != null && !Number.isNaN(prop))
      )
    )
    .filter(elements => elements.length);
