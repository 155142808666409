import Default from "./random";
import _GeoPoint from "./geo_point";
import GenerateValue from "./generate";
import * as Validators from "../validators";

const Double = Default(Validators.Float);
const Float = Default(Validators.Float);
const Integer = Default(Validators.Int);
const Long = Default(Validators.Int);

const GeoPoint = _GeoPoint({
  lat_min: Validators.Lat,
  lon_min: Validators.Lon,
  lat_max: Validators.Lat,
  lon_max: Validators.Lon
});
export { Default, Double, Float, Integer, Long, GeoPoint, GenerateValue };
