import { withTheme } from "@material-ui/core/styles";
import { manifest } from "api/aws/manifest";
import {
  clearCredentials,
  login,
  recoverExpiredSession,
  setAuthError
} from "ducks/auth";
import { equals, propSatisfies } from "ramda";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  pure,
  withHandlers,
  withProps,
  withState
} from "react-recompose";
import { bindActionCreators } from "redux";
import { giveConsent, userConsentCancelled } from "../../ducks/auth";
import * as authSelectors from "../../ducks/auth/auth_selectors";
import Login from "./components/login";
import { getStyles } from "./index.style";

export const _lifecycle = lifecycle({
  componentDidMount() {
    const { clearCredentials, recoverExpiredSession } = this.props;

    clearCredentials();
    recoverExpiredSession();
  }
});
const _isSmsVerification = propSatisfies(
  equals("phone_number"),
  "SignUpVerificationMedium"
);

export const _withProps = manifest =>
  withProps(({ theme }) => {
    return {
      loginTexts: theme.loginTexts,
      theme,
      styles: getStyles(theme),
      smsVerification: _isSmsVerification(manifest)
    };
  });

export const _withState = withState("user", "setUser", {});

export const _withHandlers = withHandlers({
  handleLogin: ({ location, login, setUser }) => user => {
    setUser(user);
    login(user, location.query.redirect);
  },
  handleConsent: ({ user, location, giveConsent }) => () =>
    giveConsent({ user, redirect: location.query.redirect }),
  handleCancelConsent: ({ userConsentCancelled }) => () =>
    userConsentCancelled()
});

export const _mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      clearCredentials,
      recoverExpiredSession,
      userConsentCancelled,
      giveConsent,
      setAuthError
    },
    dispatch
  );

const _defaultSelectors = {
  userConsentNeeded: authSelectors.userConsentNeeded,
  errorSelector: authSelectors.errorSelector,
  warningSelector: authSelectors.warningSelector,
  isLoading: authSelectors.isLoading
};
export const _makeMapStateToProps = (
  selectors = _defaultSelectors
) => state => ({
  error: selectors.errorSelector(state) || selectors.warningSelector(state),
  userConsentNeeded: selectors.userConsentNeeded(state),
  isLoading: selectors.isLoading(state)
});

export default compose(
  withTheme,
  connect(_makeMapStateToProps(), _mapDispatchToProps),
  _lifecycle,
  _withProps(manifest),
  _withState,
  _withHandlers,
  pure
)(Login);
