import { createSelector } from "reselect";
import { path, pipe, values, propOr } from "ramda";
import { sortJobs } from "./jobs_selectors";

const _getCanceledRequest = path([
  "deviceManagement",
  "jobsCanceled",
  "request"
]);
const _getCanceledEntities = path([
  "deviceManagement",
  "jobsCanceled",
  "entities"
]);

export const getCanceledJobs = createSelector(
  _getCanceledEntities,
  pipe(values, sortJobs)
);
export const isCanceledJobsLoading = createSelector(
  _getCanceledRequest,
  propOr(false, "isLoading")
);
