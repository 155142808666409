import React from "react";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import CloudIcon from "@material-ui/icons/Cloud";
import NoDataIcon from "@material-ui/icons/VisibilityOff";

import CloudUnknownImage from "assets/images/Cloud.svg";
import ParentObjectIcon from "components/svg/parent_object";
import SubObjectIcon from "components/svg/sub_object";

export const Offline = CloudOffIcon;
export const Gateway = CloudQueueIcon;
export const Online = CloudIcon;
export const Unknown = props => <img src={CloudUnknownImage} {...props} />;

export const NoData = NoDataIcon;

export const ParentThing = ParentObjectIcon;
export const NetworkedThing = SubObjectIcon;
