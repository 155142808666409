export default {
  closeButton: {
    background: "rgba(0,0,0,0.5)",
    border: "none",
    cursor: "pointer",
    padding: 0,
    outline: 0,
    position: "absolute",
    top: "15px",
    right: "15px",
    height: "40px",
    minWidth: "45px",
    color: "white"
  },
  closeIcon: {
    color: "white"
  },
  dropzone: {
    minWidth: "250px",
    minHeight: "100px",
    cursor: "pointer"
  }
};
