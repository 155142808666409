import { invoke$ as lambdaInvoke$ } from "./invoke";
import putFileUrl$ from "../utils/put_file_url";

export const uploadConsentDocumentInvoke$ = (invoke$, putFileUrl$) => (
  version,
  file
) =>
  invoke$("ManagementLambda", {
    action: "GENERATE_CONSENT_UPLOAD_URL",
    attributes: { version }
  }).mergeMap(resp =>
    putFileUrl$({
      file,
      url: resp.url
    }).map(() => resp)
  );

export const uploadConsentDocument$ = uploadConsentDocumentInvoke$(
  lambdaInvoke$,
  putFileUrl$
);
