import * as R from "ramda";
// These styles are exposed by react-autosuggest and passed further down to react-autowhatever
export const modalStyle = {
  container: {
    position: "relative",
    backgroundColor: "rgba(255,255,255,0)",
    marginBottom: 8
  },
  suggestionsContainer: {
    padding: "0px",
    margin: "0px",
    maxHeight: "0px"
  },
  suggestionsContainerOpen: {},
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0
  },
  input: {},
  inputOpen: {},
  inputFocused: {},
  suggestionsContainerFirst: {},
  suggestionFirst: {},
  suggestionHighlighted: {
    backgroundColor: "#f5f5f5"
  },
  sectionContainer: {},
  sectionContainerFirst: {},
  sectionTitle: {},

  // menu
  popover: {
    anchorOrigin: { horizontal: "left", vertical: "bottom" },
    targetOrigin: { horizontal: "left", vertical: "top" },
    style: {
      transition:
        "transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms"
    }
  },
  menu: {
    maxHeight: "40vh",
    minWidth: 300,
    width: "100%"
  },
  menuList: {},
  menuItemLoadingContainer: {
    width: 200,
    height: 30,
    padding: 8,
    backgroundColor: "#fff"
  },
  menuStyleProps: {
    autoWidth: true
  },
  menuItem: {
    highlighted: { backgroundColor: "#f5f5f5" },
    normal: { backgroundColor: "#fff" }
  },
  suggestionItem: {
    container: { display: "flex", flexDirection: "row" },
    label: { flex: 1 },
    icon: { flex: 0, alignSelf: "flex-end" },
    highlight: { fontWeight: 900, fontFamily: "telenor-regular" },
    normal: { fontWeight: 100, fontFamily: "telenor-light" }
  }
};
export const tablePickerStyle = {
  ...modalStyle,
  menu: {
    display: "block",
    height: "100%",
    width: "100%",
    marginTop: "-24px"
  },
  menuList: {
    display: "block",
    height: "100%",
    width: "100%",
    padding: "0px"
  },
  menuStyleProps: {
    autoWidth: false
  },
  menuItem: {
    highlighted: {
      width: "100%",
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid grey"
    },
    normal: {
      width: "100%",
      backgroundColor: "#fff",
      borderBottom: "1px solid #f5f5f0"
    }
  }
};

export const inputHasLabel = props =>
  props.floatingLabelText !== undefined || props.label !== undefined;

export const calcHeight = hasLabel => (hasLabel ? 72 : 48);

export const getInputStyles = props => {
  const hasLabel = inputHasLabel(props);
  const { palette, typography, transitions, textField = {} } = R.pick([
    "palette",
    "typography",
    "textField",
    "transitions"
  ])(props.muiTheme);

  const {
    floatingLabelColor,
    focusColor,
    disabledTextColor,
    errorColor,
    hintColor
  } = R.pick(["focusColor", "disabledTextColor", "errorColor", "hintColor"])(
    textField
  );

  const styles = {
    root: {
      fontSize: 16,
      lineHeight: "24px",
      width: "100%",
      height: calcHeight(hasLabel, props.errorText !== undefined),
      display: "inline-block",
      position: "relative",
      backgroundColor: "rgba(255,255,255,0)",
      fontFamily: typography.fontFamily,

      transition: transitions.create(["height"], {
        easing: R.path(["easing", "easeOut"])(transitions),

        duration: R.path(["duration", "shorter"])(transitions)
      }),
      cursor: "text",
      marginBottom: 9,
      ...R.pathOr({}, ["styles", "root"])(props),
      ...(!props.single && { height: "auto", minHeight: calcHeight(hasLabel) })
    },
    input: {
      height: "40px",
      paddingTop: hasLabel ? 7 : 3,
      ...R.pathOr({}, ["styles", "input"])(props)
    },
    error: {
      position: "absolute",
      bottom: -10,
      fontSize: 12,
      lineHeight: "12px",
      color: errorColor,
      transition: transitions.create([], {
        easing: R.path(["easing", "easeOut"])(transitions)
      }),

      ...R.pathOr({}, ["styles", "error"])(props)
    },
    help: {
      position: "absolute",
      bottom: -10,
      fontSize: 12,
      lineHeight: "12px",
      transition: transitions.create([], {
        easing: R.path(["easing", "easeOut"])(transitions)
      }),
      ...R.pathOr({}, ["styles", "help"])(props)
    },
    errorStyle: {
      borderBottom: "1.5px solid rgb(244, 67, 54)"
    },
    floatingLabel: {
      color: props.disabled ? disabledTextColor : floatingLabelColor,
      pointerEvents: "none",
      top: 39,
      position: "absolute",
      ...R.pathOr({}, ["styles", "floatingLabel"])(props)
    },
    floatingLabelFocusStyle: {
      transform: "scale(0.75) translate(0, -36px)",
      top: props.textChip ? 45 : 35,
      ...R.pathOr({}, ["styles", "floatingLabelFocusStyle"])(props)
    },
    textChip: {
      margin: hasLabel ? "12px 8px 0 0" : "12px 8px 0 0",
      paddingBottom: 3,
      float: "left",
      ...R.pathOr({}, ["styles", "textChip"])(props)
    },
    defaultChip: {
      margin: "3px 8px 0 0",
      float: "left",
      activeBackgroundColor: R.prop("primary1Color")(palette),
      ...R.pathOr({}, ["styles", "defaultChip"])(props)
    },
    chipTextRow: {
      marginTop: hasLabel ? 25 : 2,
      height: "100%",
      ...R.pathOr({}, ["styles", "chipTextRow"])(props)
    },
    chipContainer: {
      marginTop: 0,
      ...R.pathOr({}, ["styles", "chipContainer"])(props)
    },
    textFieldContainer: {
      display: "inline-block",
      ...R.pathOr({}, ["styles", "textFieldContainer"])(props)
    },
    hintStyle: {
      hintColor: hintColor,
      bottom: 4,
      pointerEvents: "none",
      ...R.pathOr({}, ["styles", "hintStyle"])(props)
    },
    textFieldUnderline: {
      border: "none",
      ...R.pathOr({}, ["styles", "textFieldUnderline"])(props)
    },
    underlineStyle: {
      bottom: 8,
      boxSizing: "content-box",
      position: "absolute",
      ...R.pathOr({}, ["styles", "underlineStyle"])(props),
      focus: { borderBottom: "1.5px solid rgb(244, 67, 54)" },
      height: 5
    },
    textField: {
      height: 40,
      ...R.pathOr({}, ["styles", "textField"])(props)
    }
  };

  if (props.isFocused) {
    styles.floatingLabel.color = focusColor;
  }

  if (props.floatingLabelText) {
    styles.input.boxSizing = "border-box";
  }

  if (props.errorText) {
    if (props.isFocused) {
      styles.floatingLabel.color = styles.error.color;
    }
  }

  return styles;
};
