import { manifest } from "api/aws/manifest";
import fetchWithRefreshToken from "api/utils/fetch_with_refresh_token";
import * as R from "ramda";
import { defer, Observable } from "rxjs";
import putFileUrl$ from "../utils/put_file_url";

const withRootUrl = path =>
  manifest.ApiGatewayRootUrl && manifest.StackName
    ? `${manifest.ApiGatewayRootUrl}/${manifest.StackName}/${path}`
    : `/${path}`;

const convertToErrorMessage = payload => {
  return R.ifElse(
    R.has("message"),
    R.pipe(
      R.assoc("errorMessage", R.prop("message")(payload)),
      R.dissoc("message")
    ),
    () => payload
  )(payload);
};

// Thing Groups

const listGroups = async () => {
  const response = await fetchWithRefreshToken(withRootUrl("thing-groups"), {});
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const listGroups$ = () => {
  return defer(() => Observable.fromPromise(listGroups()));
};

const listThings = async id => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-groups/${id}/things`),
    {}
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const listThings$ = ({ id }) => {
  return defer(() => Observable.fromPromise(listThings(id)));
};

const describeGroup = async id => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-groups/${id}`),
    {}
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const describeGroup$ = ({ id }) => {
  return defer(() => Observable.fromPromise(describeGroup(id)));
};

const createGroup = async requestBody => {
  const response = await fetchWithRefreshToken(withRootUrl(`thing-groups`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestBody)
  });
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const createGroup$ = requestBody => {
  return defer(() => Observable.fromPromise(createGroup(requestBody)));
};

const deleteGroup = async id => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-groups/${id}`),
    { method: "DELETE" }
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const deleteGroup$ = ({ id }) => {
  return defer(() => Observable.fromPromise(deleteGroup(id)));
};

const addThings = async (id, thingNames) => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-groups/${id}/things`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ thingNames })
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const addThings$ = ({ id, thingNames }) => {
  return defer(() => Observable.fromPromise(addThings(id, thingNames)));
};

const removeThing = async (id, thingName) => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-groups/${id}/things/${thingName}`),
    { method: "DELETE" }
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const removeThing$ = ({ id, thingName }) => {
  return defer(() => Observable.fromPromise(removeThing(id, thingName)));
};

// Thing Jobs

const describeJob = async jobId => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-jobs/jobs/${jobId}`),
    {}
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const describeJob$ = ({ jobId }) => {
  return defer(() => Observable.fromPromise(describeJob(jobId)));
};

const listJobs = async status => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-jobs/jobs?status=${status}`),
    {}
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const listJobsOngoing$ = () => {
  return defer(() => Observable.fromPromise(listJobs("IN_PROGRESS")));
};
export const listJobsCompleted$ = () => {
  return defer(() => Observable.fromPromise(listJobs("COMPLETED")));
};
export const listJobsCanceled$ = () => {
  return defer(() => Observable.fromPromise(listJobs("CANCELED")));
};

const listJobExecutions = async (jobId, status) => {
  const response = await fetchWithRefreshToken(
    withRootUrl(
      `thing-jobs/jobs/${jobId}/job-executions` +
        (status ? `?status=${status}` : "")
    ),
    {}
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const listJobExecutions$ = ({ jobId, status }) => {
  return defer(() => Observable.fromPromise(listJobExecutions(jobId, status)));
};

const getJobDocument = async jobId => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-jobs/documents/${jobId}`),
    {}
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const getJobDocument$ = ({ jobId }) => {
  return defer(() => Observable.fromPromise(getJobDocument(jobId)));
};

const createJob = async requestBody => {
  const response = await fetchWithRefreshToken(withRootUrl(`thing-jobs/jobs`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestBody)
  });
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const createJob$ = requestBody => {
  return defer(() => Observable.fromPromise(createJob(requestBody)));
};

const cancelJob = async (id, comment) => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-jobs/jobs/${id}`),
    {
      method: "PATCH",
      ...(comment
        ? {
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ comment })
          }
        : {})
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const cancelJob$ = ({ jobId, comment }) => {
  return defer(() => Observable.fromPromise(cancelJob(jobId, comment)));
};
export const cancelJobs$ = jobs => {
  return Observable.forkJoin(
    jobs.map(payload =>
      cancelJob$(payload)
        .map(response => ({ payload, response }))
        .catch(error => Observable.of({ payload, error }))
    )
  );
};

const generateUploadURL = async (documentName, domain) => {
  const response = await fetchWithRefreshToken(
    withRootUrl(`thing-jobs/documents`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ documentName, domain })
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  } else if (R.has("errorMessage")(data)) {
    throw data;
  }

  return data;
};

export const putJobDocument$ = ({ domain, file }) => {
  return defer(() =>
    Observable.fromPromise(generateUploadURL(file.name, domain)).mergeMap(
      generateUploadURLResponse =>
        putFileUrl$({
          file,
          url: generateUploadURLResponse.signedUrl,
          contentType: "application/json"
        }).map(() => generateUploadURLResponse)
    )
  );
};
