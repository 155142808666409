import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
// CSS fails to load leaving the spinner invisible, because of css modules
// ... could someone have mentioned this from the start?
import { compose, pure, shouldUpdate } from "react-recompose";

const style = {
  display: "flex",
  justifyContent: "center"
};
const Loading = ({ isLoading = true, size = 40, thickness = 2 }) =>
  isLoading ? (
    <div style={style}>
      <CircularProgress size={size} thickness={thickness} />
    </div>
  ) : (
    <span />
  );

Loading.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number
};

export default compose(
  shouldUpdate(() => false),
  pure
)(Loading);
