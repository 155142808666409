import { invoke$ as lambdaInvoke$ } from "./invoke";
import putFileUrl$, {
  getContentType,
  getFileName
} from "../utils/put_file_url";
import { removeEmptyStrings } from "utils/invoke_utils";

export const uploadV2Invoke$ = (invoke$, putFileUrl$) => (
  { contentType, fileName, ...attributes },
  file
) =>
  invoke$("FileLambdaV2", {
    action: "GENERATE_FILE_UPLOAD_URL",
    attributes: removeEmptyStrings({
      ...attributes,
      contentType: getContentType(file, contentType),
      fileName: getFileName(file, fileName)
    })
  }).mergeMap(({ url }) =>
    putFileUrl$({
      file,
      url,
      contentType: getContentType(file, contentType)
    }).mapTo({ url })
  );

export const uploadV2$ = uploadV2Invoke$(lambdaInvoke$, putFileUrl$);
