import Loadable from "react-loadable";
import { RouteLoading } from "components/telenor/loading";

export const Layout = Loadable({
  loader: () =>
    import(/* webpackChunkName: "route-settings"*/ "../../../layouts/default"),
  loading: RouteLoading
});

export const Sidebar = Loadable({
  loader: () => import(/* webpackChunkName: "route-settings"*/ "./sidebar"),
  loading: RouteLoading
});

export const SettingsHome = Loadable({
  loader: () =>
    import(/* webpackChunkName: "route-settings"*/ "./home/settings_home"),
  loading: RouteLoading
});

export const ThingTypes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./thing_types/thing_types_container"
    ),
  loading: RouteLoading
});

export const Rules = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./rules/rules_list_container"
    ),
  loading: RouteLoading
});

export const RulesEdit = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./modal/rule_edit/rule_edit_container"
    ),
  loading: RouteLoading
});

export const Users = Loadable({
  loader: () => import(/* webpackChunkName: "route-settings"*/ "./users"),
  loading: RouteLoading
});

export const Domains = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./domains/domains_container"
    ),
  loading: RouteLoading
});

export const DomainsSetup = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./domains/domains_setup_container"
    ),
  loading: RouteLoading
});

export const Branding = Loadable({
  loader: () => import(/* webpackChunkName: "route-settings"*/ "./branding"),
  loading: RouteLoading
});

export const Units = Loadable({
  loader: () => import(/* webpackChunkName: "route-settings"*/ "./units"),
  loading: RouteLoading
});

export const FileBrowserContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./files/file_browser_container"
    ),
  loading: RouteLoading
});

export const DeviceManagement = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_container"
    ),
  loading: RouteLoading
});

export const DeviceManagementGroupDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_group_details_container"
    ),
  loading: RouteLoading
});
export const DeviceManagementAddThings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/add_things"
    ),
  loading: RouteLoading
});

export const DeviceManagementJobsDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_jobs_container"
    ),
  loading: RouteLoading
});

export const DeviceManagementFirmwaresContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_firmwares_container"
    ),
  loading: RouteLoading
});

export const DeviceManagementFirmwareDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_firmware_details"
    ),
  loading: RouteLoading
});

export const DeviceManagementGroupsContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_groups_container"
    ),
  loading: RouteLoading
});

export const DeviceManagementAddGroups = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/add_groups"
    ),
  loading: RouteLoading
});

export const DeviceManagementCreateJob = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_create_job"
    ),
  loading: RouteLoading
});

export const DeviceManagementJobDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./device_management/device_management_job_details"
    ),
  loading: RouteLoading
});

export const SystemManagement = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./system_management/system_management_container"
    ),
  loading: RouteLoading
});

export const RolesListContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "feature-flagged"*/ "./roles/roles_list_container"
    ),
  loading: RouteLoading
});

export const RoleDetailsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "feature-flagged"*/ "./roles/role_details_page"
    ),
  loading: RouteLoading
});

export const ExportPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "route-settings"*/ "./export/export_list_page"),
  loading: RouteLoading
});

export const ExportJobDetailsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "route-settings"*/ "./export/job_details_page_container"
    ),
  loading: RouteLoading
});
