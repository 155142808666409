export const groupResourcesByThingId = (resources, separator = ".") => {
  const result = resources.reduce((acc, keypath) => {
    const [thingId, ...rest] = keypath.split("/");
    const resource = rest.join(separator);

    if (acc[thingId]) {
      acc[thingId] = acc[thingId].concat(resource);
    } else {
      acc[thingId] = [resource];
    }

    return acc;
  }, {});

  return result;
};

export const resourceStateString = resource =>
  "state." + resource.replace(/\//, ".");
