import Joi from "joi-browser";

const decimalsOptions = [
  {
    label: "Unlimited",
    id: "-1",
    value: -1,
    index: 0
  },
  {
    label: "0",
    id: "0",
    value: 0,
    index: 1
  },
  {
    label: "1",
    id: "1",
    value: 1,
    index: 2
  },
  {
    label: "2",
    id: "2",
    value: 2,
    index: 3
  },
  {
    label: "3",
    id: "3",
    value: 3,
    index: 4
  },
  {
    label: "4",
    id: "4",
    value: 4,
    index: 5
  },
  {
    label: "5",
    id: "5",
    value: 5,
    index: 6
  },
  {
    label: "6",
    id: "6",
    value: 6,
    index: 7
  },
  {
    label: "7",
    id: "7",
    value: 7,
    index: 8
  },
  {
    label: "8",
    id: "8",
    value: 8,
    index: 9
  },
  {
    label: "9",
    id: "9",
    value: 9,
    index: 10
  },
  {
    label: "10",
    id: "10",
    value: 10,
    index: 11
  }
];

const WidgetEditDecimals = ({ onDecimalsChange }) => {
  return {
    fields: [
      {
        dataTest: "numberOfDecimals",
        id: "numberOfDecimals",
        type: "select",
        label: "Number of displayed decimals",
        hintText: "Number of displayed decimals",
        onChange: onDecimalsChange,
        options: decimalsOptions
      }
    ],
    initialModel: {
      numberOfDecimals: -1
    },
    schema: {
      numberOfDecimals: Joi.number()
    }
  };
};

export default WidgetEditDecimals;
