import React from "react";
import PropTypes from "prop-types";
import SetPasswordForm from "forms_new/user/set_password";
import FormIconButton from "components/buttons/form_icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Label } from "components/label";
import Card from "components/cards/card";
import { getStyles } from "./index.style";
import ConsentDialogModal from "components/consent_dialog/consent_dialog_modal";

const SetPassword = ({
  isLoading,
  error,
  success,
  handleSetPassword,
  userName,
  verifyByPhoneNumber,
  userConsentNeeded,
  handleConsent,
  handleConsentCancelled
}) => {
  const styles = getStyles();
  const successDialog = (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Password set</DialogTitle>
      <DialogContent>
        <DialogContentText>Your password has been set</DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormIconButton href="/" text="Go to dashboard" primary />
      </DialogActions>
    </Dialog>
  );
  return (
    <div style={styles.cardContainer}>
      <Label center type="h3">
        Set password
      </Label>
      <Card isLoading={isLoading} noHeader>
        <SetPasswordForm
          handleSubmit={handleSetPassword}
          isLoading={isLoading}
          userName={userName}
          verifyByPhoneNumber={verifyByPhoneNumber}
        />
        <p style={styles.errorText}>{error && error}</p>
        {success && successDialog}
        <ConsentDialogModal
          consentLabel={"Proceed"}
          open={userConsentNeeded}
          handleConsent={handleConsent}
          handleCancel={handleConsentCancelled}
        />
      </Card>
    </div>
  );
};

SetPassword.propTypes = {
  handleSetPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.string,
  userName: PropTypes.string,
  verifyByPhoneNumber: PropTypes.bool,
  muiTheme: PropTypes.object,
  userConsentNeeded: PropTypes.bool,
  handleConsent: PropTypes.func,
  handleConsentCancelled: PropTypes.func
};

export default SetPassword;
