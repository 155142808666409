import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import Calendar, { calendarStyle } from "../svg/calendar";
import WidgetFooterWrapper from "./widget_footer_wrapper";
import RealtimeWidgetFooterAnnotation from "./widget_types/realtime_widget_footer_annotation";

const getFooterText = resource => {
  let text;

  if (resource) {
    const timestamp = resource.getIn(["latestValue", "timestamp"]);
    if (timestamp) {
      const date = new Date(timestamp * 1000);
      const dateFormat = formatDate(date, "yyyy-MM-dd HH:mm:ss");
      text = `Last updated: ${dateFormat}`;
    }
  }

  return text || "Never updated";
};

const WidgetFooter = props => {
  const { resource, isRealtime } = props;
  const realTime = isRealtime || false;

  const text = getFooterText(resource);
  return (
    <WidgetFooterWrapper>
      {realTime ? (
        <RealtimeWidgetFooterAnnotation resource={resource} />
      ) : (
        <span>
          <Calendar style={calendarStyle} />
          {text}
        </span>
      )}
    </WidgetFooterWrapper>
  );
};

WidgetFooter.propTypes = {
  resource: PropTypes.object,
  widgetType: PropTypes.string,
  widget: PropTypes.object,
  isRealtime: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};
WidgetFooter.defaultProps = {};

export default WidgetFooter;
