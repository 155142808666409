export const getStyles = () => ({
  topItem: {
    position: "relative",
    paddingTop: 0
  },
  allThingsIcon: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  allThingsText: {
    display: "inline-block",
    verticalAlign: "text-bottom"
  }
});
