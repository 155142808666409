/**
 * Created by stephenwhite on 09/05/16.
 */
import * as R from "ramda";
import * as Storage from "api/device/storage";
import { mergeDeepRight } from "ramda";
import { configureUnauthenticated } from "api/utils/cognito_credentials";

export const OPERATIONS = {
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
};

export const OBJECT_TYPES = {
  Auth: "Auth",
  AppBoard: "AppBoard",
  Dashboards: "Dashboards",
  DataExport: "DataExport",
  Domains: "Domains",
  Events: "Events",
  Files: "Files",
  Observations: "Observations",
  Permissions: "Permissions",
  Rules: "Rules",
  Search: "Search",
  StorageRetention: "StorageRetention",
  ThingsListFilter: "ThingsListFilter",
  ThingBatches: "ThingBatches",
  ThingGroups: "ThingGroups",
  ThingJobs: "ThingJobs",
  Things: "Things",
  ThingTypes: "ThingTypes",
  Units: "Units",
  Users: "Users",
  ThingPubSub: "ThingPubSub",
  ThingData: "ThingData"
};

export const SETTING_TYPES = {
  AppBoard: "AppBoard",
  StorageRetention: "StorageRetention"
};

export function clearCredentials() {
  Storage.remove("account");
  Storage.clearAwsCredentials();
  configureUnauthenticated();
}

export const updateAuthUserDetails = user => {
  const account = Storage.get("account");
  Storage.set("account", mergeDeepRight(account)({ user: user }));
};

const invariantObjectType = (objectType, objectTypes) => {
  if (!R.has(objectType, objectTypes)) {
    throw new Error(`Unknown object type "${objectType}"`);
  }
};

const invariantOperation = operation => {
  if (!R.has(operation, OPERATIONS)) {
    throw new Error(`Unknown operation "${operation}"`);
  }
};

export const containsObjectPermissionInDomain = (
  objectType,
  operation,
  domain
) => permissions =>
  R.pipe(
    R.pathOr([], ["domains", domain, objectType]),
    R.includes(operation)
  )(permissions);

const containsObjectPermission = (objectType, operation, permissions) =>
  R.pipe(
    R.prop("domains"),
    R.values,
    R.any(R.pipe(R.propOr([], objectType), R.includes(operation)))
  )(permissions);

const containsSettingPermission = (settingType, operation, permissions) =>
  R.pipe(
    R.pathOr([], ["settings", settingType]),
    R.contains(operation)
  )(permissions);

const hasNoExplicitSettingPermissions = (settingType, permissions) =>
  R.pathEq(["settings", settingType], undefined, permissions);

export const isAllowed = R.curry((objectType, operation, permissions) => {
  invariantObjectType(objectType, OBJECT_TYPES);
  invariantOperation(operation);
  return containsObjectPermission(objectType, operation, permissions);
});

export const isSettingAllowed = R.curry(
  (settingType, operation, permissions) => {
    invariantObjectType(settingType, SETTING_TYPES);
    invariantOperation(operation);
    return (
      hasNoExplicitSettingPermissions(settingType, permissions) ||
      containsSettingPermission(settingType, operation, permissions)
    );
  }
);

export const isSettingAllowedAndHasExplicitSettingPermissions = R.curry(
  (settingType, operation, permissions) => {
    invariantObjectType(settingType, SETTING_TYPES);
    invariantOperation(operation);
    return containsSettingPermission(settingType, operation, permissions);
  }
);
