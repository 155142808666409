import { has, isNil, map, partition, pipe, pluck, reject } from "ramda";
import { Observable } from "rxjs";

export const removeThing = (removeThing$, thingGroupId) => thingName =>
  removeThing$({ id: thingGroupId, thingName })
    .map(() => ({ thingName }))
    .catch(error => Observable.of({ error, thingName }));

export const partitionResults = pipe(
  partition(has("error")),
  map(pluck("thingName"))
);

export const getErrors = pipe(pluck("error"), reject(isNil));
