import * as R from "ramda";
import {
  ACKNOWLEDGE_REQUEST,
  EVENTS_NEEDS_ACKNOWLEDGMENT_REQUEST,
  EVENTS_NEW_REQUEST,
  EVENTS_SEARCH_REQUEST,
  EVENTS_WIDGET_REQUEST,
  acknowledgeSuccess,
  fetchEventsThatNeedsAcknowledgmentSuccess,
  fetchNewEventsSuccess,
  fetchWidgetEventsSuccess,
  searchForEventsSuccess,
  eventStreamReception
} from "ducks/events";
import {
  MQTT_DATA_RECEIVED,
  TYPE_RESOURCE_SCHEMA_UPDATE,
  TYPE_THING_CREATE
} from "ducks/mqtt";
import { hasEventTopic } from "utils/mqtt_utils";
import { schemaUpdateReceived, schemaUpdated } from "ducks/schema";
import { thingCreateReceived } from "ducks/thing";
import { FIND_EVENTS_QUERY, ACKNOWLEDGE_EVENT_MUTATION } from "graphql/queries";
import { getSearchOptions } from "./events_utils";
import { getTimezone } from "graphql/utils/general";

export const fetchEventsThatNeedsAcknowledgmentEpic = (
  action$,
  store,
  { adaptEvents, getApolloClient }
) =>
  action$.ofType(EVENTS_NEEDS_ACKNOWLEDGMENT_REQUEST).mergeMap(action => {
    const client = getApolloClient();
    return client
      .query({
        query: FIND_EVENTS_QUERY,
        variables: {
          timezone: getTimezone(),
          searchOptions: {
            filter: { acknowledgementRequired: true, isAcknowledged: false }
          }
        }
      })
      .then(response =>
        fetchEventsThatNeedsAcknowledgmentSuccess(adaptEvents(response))
      );
  });

export const fetchNewEventsEpic = (
  action$,
  store,
  { adaptEvents, getApolloClient }
) =>
  action$
    .ofType(EVENTS_NEW_REQUEST)
    .mergeMap(({ payload: { lastEventReadTimestamp } }) => {
      const client = getApolloClient();
      const searchOptions = {
        filter: {
          from: lastEventReadTimestamp,
          excludedClassifications: ["INTERNAL"]
        }
      };

      return client
        .query({
          query: FIND_EVENTS_QUERY,
          variables: {
            timezone: getTimezone(),
            searchOptions
          }
        })
        .then(response => fetchNewEventsSuccess(adaptEvents(response)));
    });

export const fetchWidgetEventsEpic = (
  action$,
  store,
  { getApolloClient, adaptEvents }
) =>
  action$.ofType(EVENTS_WIDGET_REQUEST).mergeMap(({ payload }) => {
    const searchOptions = getSearchOptions(payload, 500);
    const client = getApolloClient();
    return client
      .query({
        query: FIND_EVENTS_QUERY,
        variables: {
          timezone: getTimezone(),
          searchOptions
        }
      })
      .then(response =>
        fetchWidgetEventsSuccess({
          requestPayload: payload,
          normalizedResponse: adaptEvents(response)
        })
      );
  });

export const searchForEventsEpic = (
  action$,
  store,
  { getApolloClient, adaptEvents }
) =>
  action$.ofType(EVENTS_SEARCH_REQUEST).mergeMap(({ payload }) => {
    const searchOptions = getSearchOptions(payload, 500);
    const client = getApolloClient();
    return client
      .query({
        query: FIND_EVENTS_QUERY,
        variables: {
          timezone: getTimezone(),
          searchOptions
        }
      })
      .then(response => searchForEventsSuccess(adaptEvents(response)));
  });

export const acknowledgeEpic = (action$, store, { getApolloClient }) =>
  action$.ofType(ACKNOWLEDGE_REQUEST).mergeMap(({ payload: id }) => {
    const client = getApolloClient();
    return client
      .mutate({
        mutation: ACKNOWLEDGE_EVENT_MUTATION,
        variables: {
          eventId: id
        }
      })
      .then(response => acknowledgeSuccess(response));
  });

export const eventStreamEpic = action$ =>
  action$
    .ofType(MQTT_DATA_RECEIVED)
    .filter(hasEventTopic)
    .mergeMap(({ payload: { data } }) =>
      R.prepend(
        eventStreamReception({ data }),
        R.cond([
          [
            R.equals(TYPE_RESOURCE_SCHEMA_UPDATE),
            R.always([schemaUpdateReceived({ data }), schemaUpdated()])
          ],
          [
            R.equals(TYPE_THING_CREATE),
            R.always([thingCreateReceived({ data })])
          ],
          [R.T, R.always([])]
        ])(data.type)
      )
    );
