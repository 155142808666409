import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import {
  eventActionType,
  eventActionThingType,
  eventActionBatch
} from "ducks/events/events_utils";
import { MQTT_DATA_RECEIVED } from "ducks/mqtt";
import { LIST_THING_BATCHES } from "../../graphql/queries";
import {
  createThingsSuccess,
  listThingBatchesSuccess,
  listThingBatchesFailure,
  LIST_THINGS_BATCHES_REQUEST
} from "./batches";

const shouldUpdateCertificateCache = action =>
  eventActionType(action) === "BATCH";

const updateCertificateCache = getApolloClient => action => {
  const client = getApolloClient();
  const thingType = eventActionThingType(action);
  const batch = eventActionBatch(action);

  return Promise.all([
    client.query({
      errorPolicy: "ignore",
      fetchPolicy: "network-only",
      query: LIST_THING_BATCHES,
      variables: { filter: { thingTypes: [thingType], prefix: "batch-" } }
    }),
    client.query({
      errorPolicy: "ignore",
      fetchPolicy: "network-only",
      query: LIST_THING_BATCHES,
      variables: { filter: { prefix: "batch-" } }
    })
  ])
    .then(() => createThingsSuccess({ thingType, batch }))
    .catch(() => createThingsSuccess({ thingType, batch }));
};

export const createThingsEpic = (action$, store, { getApolloClient }) =>
  action$
    .ofType(MQTT_DATA_RECEIVED)
    .filter(shouldUpdateCertificateCache)
    .mergeMap(updateCertificateCache(getApolloClient));

export const listThingBatchesEpic = (action$, store, { api }) =>
  action$.ofType(LIST_THINGS_BATCHES_REQUEST).mergeMap(({ payload }) =>
    api.batch
      .list$(payload)
      .map(response =>
        listThingBatchesSuccess({ thingTypeId: payload.thingTypeId, response })
      )
      .catch(error =>
        Observable.of(listThingBatchesFailure(error), apiError(error))
      )
  );
