import { withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  compose,
  onlyUpdateForPropTypes,
  setPropTypes,
  withPropsOnChange
} from "react-recompose";
import { widgetProp } from "utils/dashboard_utils";
import { getAggregationInterval } from "utils/widget_utils";
import Calendar, { calendarStyle } from "../../svg/calendar";
import { INTERVAL_NAME } from "../edit/widget_edit_aggregation_interval";
import ObservationFooterWrapper from "./observation_footer_wrapper";
import RealtimeWidgetFooterAnnotation from "./realtime_widget_footer_annotation";

const getChipTexts = ({ isRaw, isRealtime, aggregationInterval }) => {
  if (isRealtime) {
    return {
      text: `Last 100`,
      tooltip: `Showing the last 100 observations`
    };
  } else if (!isRaw) {
    return {
      text: "Aggregated",
      tooltip: aggregationInterval
        ? `Showing aggregated observations with an interval of ${aggregationInterval}`
        : "Showing aggregated observations with a resolution relative to selected period"
    };
  }

  return {
    text: "Last 100",
    tooltip: "Showing up to the last 100 observations in selected period"
  };
};

const abbrStyle = {
  textDecoration: "none"
};

const format = date => formatDate(date, "dd MMM yyyy");
const formatPeriod = period => {
  const from = format(new Date(period.get("from")));
  const to = format(new Date(period.get("to")));
  return `${from} - ${to}`;
};

const StyledChip = withStyles({
  root: {
    borderRadius: 0,
    padding: 0,
    margin: "0 0 0 10px",
    height: "24px"
  },
  label: {
    padding: "3px 9px",
    lineHeight: "auto",
    display: "flex",
    color: "rgba(77, 77, 77, 0.87)",
    fontSize: 14,
    fontWeight: 400
  }
})(Chip);

const ObservationWidgetFooter = ({
  aggregationInterval,
  chipTexts,
  formattedPeriod,
  isRealtime,
  resource
}) => (
  <ObservationFooterWrapper>
    {isRealtime ? (
      <RealtimeWidgetFooterAnnotation resource={resource} />
    ) : (
      <StyledChip
        label={
          <React.Fragment>
            <Calendar style={calendarStyle} />
            <abbr
              title={`Showing historic data for the period ${formattedPeriod}`}
              style={abbrStyle}
            >
              {formattedPeriod}
            </abbr>
          </React.Fragment>
        }
      />
    )}
    {aggregationInterval && (
      <StyledChip
        label={
          <abbr
            title={`Aggregation interval: ${aggregationInterval}`}
            style={abbrStyle}
          >
            {aggregationInterval}
          </abbr>
        }
      />
    )}
    <StyledChip
      label={
        <abbr title={chipTexts.tooltip} style={abbrStyle}>
          {chipTexts.text}
        </abbr>
      }
    />
  </ObservationFooterWrapper>
);

const mapStateToProps = (state, props) => {
  const { resource, widget, observationPeriod } = props;
  const isRealtime = widgetProp(widget, "isRealtime", false);
  const isRaw =
    widgetProp(widget, "isObservationsAggregated") === "RAW_OBSERVATIONS";

  return {
    observationPeriod,
    isRaw,
    isRealtime,
    resource
  };
};

ObservationWidgetFooter.propTypes = {
  aggregationInterval: PropTypes.string,
  chipTexts: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.string
  }),
  formattedPeriod: PropTypes.string,
  isRealtime: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  resource: PropTypes.object,
  observations: PropTypes.object
};

const aggregationIntervalString = props =>
  props.isRaw || props.isRealtime
    ? ""
    : getAggregationInterval({
        widget: props.widget,
        observationPeriod: props.observationPeriod
      });

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    (props, nextProps) =>
      props.isRaw !== nextProps.isRaw ||
      props.isRealtime !== nextProps.isRealtime ||
      props.observationPeriod !== nextProps.observationPeriod ||
      widgetProp(props.widget, INTERVAL_NAME) !==
        widgetProp(nextProps.widget, INTERVAL_NAME),
    props => ({ aggregationInterval: aggregationIntervalString(props) })
  ),
  withPropsOnChange(["isRaw", "isRealtime", "aggregationInterval"], props => ({
    chipTexts: getChipTexts(props)
  })),
  withPropsOnChange(["observationPeriod"], props => ({
    formattedPeriod: formatPeriod(props.observationPeriod)
  })),
  onlyUpdateForPropTypes,
  setPropTypes(ObservationWidgetFooter.propTypes)
)(ObservationWidgetFooter);
