import { pathOr } from "ramda";
import { pickBy } from "../src/utils/general_utils";
import { parse as parseQuerystring } from "querystring";
import * as aws from "./api/aws";
import * as Storage from "api/device/storage";
import * as authUtils from "utils/auth_utils";
import { loadAuth, setEnabledSignup } from "ducks/auth";
import { setTheme } from "ducks/theme";
import { getUserData } from "ducks/users";
import { traceErr } from "api/utils/trace";
import constants from "./constants";

const isLocalHost = hostname =>
  [
    /localhost/,
    /web/, //This would be the name of the docker container
    /appboard\.dev/,
    /10\.49\.\d+\.\d+/,
    /192\.168\.\d+\.\d+/
  ].some(expr => expr.test(hostname));

export const initStorage = props => {
  Storage.init(global.localStorage);
  return { ...props, storedAccount: Storage.get("account") };
};

export const initEnvAndAccount = props => {
  const { env, account } = Object.assign(
    {},
    isLocalHost(location.hostname) && {
      account: constants.ACCOUNT,
      env: constants.ENV
    },
    pickBy(parseQuerystring(global.location.search.slice(1)))
  );
  return { ...props, env, account };
};

export const loadTheme = (rootTheme, account) => {
  const domain = pathOr("root", ["user", "domainName"], account);
  return domain === "root"
    ? Promise.resolve(rootTheme)
    : aws.domains
        .theme$(domain)
        .toPromise()
        .catch(error => {
          return rootTheme;
        });
};

export const initAws = ({ renderApp, errorPage }) => ({
  env,
  account,
  storedAccount,
  store,
  App
}) => {
  return aws
    .init({ account, env, storedAccount })
    .then(
      ({
        config: { theme: rootTheme, signUpEnabled, smsUser },
        aws: { account, env },
        account: updatedAccount
      }) => {
        // Ugly fix when localstorage is not set the first time then aws.init will alter the accountNumber and env
        store.dispatch(setEnabledSignup(signUpEnabled));
        store.dispatch(
          loadAuth(
            Object.assign(
              { account: { Id: account, env, smsUser } },
              updatedAccount
            )
          )
        );

        const didAuthenticate = !!updatedAccount;
        if (didAuthenticate) store.dispatch(getUserData());

        return loadTheme(rootTheme, updatedAccount).then(theme => {
          store.dispatch(setTheme(theme));
          return renderApp(App);
        });
      }
    )
    .catch(err => {
      traceErr("AWS init failed", err);
      authUtils.clearCredentials();
      const manifestDidNotLoadError = errorPage(
        "An error has occured. You can reload the page to try again"
      );
      return renderApp(manifestDidNotLoadError);
    });
};
