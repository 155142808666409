import React from "react";
import PropTypes from "prop-types";
import HighLightOff from "@material-ui/icons/HighlightOff";
import * as colors from "themes/colors";

const css = {
  iconCss: {
    fill: colors.colorDustyGrayApprox,
    padding: "0 0 0 10px",
    verticalAlign: "middle"
  }
};

const MapToggle = ({ isActive, onRemoveGeoFilter, onToggleGeoActive }) => {
  return (
    <span>
      {isActive ? (
        <span>
          Location active{" "}
          <HighLightOff onClick={onRemoveGeoFilter} style={css.iconCss} />
        </span>
      ) : (
        <span onClick={onToggleGeoActive}>Set location</span>
      )}
    </span>
  );
};

MapToggle.propTypes = {
  filter: PropTypes.object,
  isActive: PropTypes.bool,
  onRemoveGeoFilter: PropTypes.func,
  onToggleGeoActive: PropTypes.func
};

MapToggle.defaultProps = {
  isActive: false
};

export default MapToggle;
