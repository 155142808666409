import React from "react";
import PropTypes from "prop-types";
import { withStateHandlers } from "react-recompose";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import DraggableList from "components/draggable_list";
import SaveButton from "components/buttons/form_save";
import Suggester from "components/suggester";
import { tablePickerStyle } from "components/suggester/styles";
import { SUGGEST_RESOURCES } from "ducks/suggester";
import ColumnPickerTemplate from "./column_picker_template";

const styles = theme => ({
  wrapper: {
    width: "280px"
  },
  content: {
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden"
  },
  header: {
    padding: theme.spacing(1, 2)
  },
  saveButton: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  }
});

const ColumnPicker = ({
  items,
  isSaving,
  showSuggester,
  buttonText,
  onChangeWithTable,
  setDefault,
  handleUpdateFields,
  onSaveClicked,
  onShowButtonTap,
  handleOpen,
  handleClose,
  anchorEl,
  classes
}) => (
  <div>
    <Tooltip title="Pick Columns" placement="top">
      <IconButton onClick={handleOpen} size="small">
        <ViewColumnIcon />
      </IconButton>
    </Tooltip>
    <Popover
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <Paper className={classes.wrapper}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.header}>
              <Grid container item xs={6} alignItems="center">
                <Typography variant="subtitle1">Pick Columns</Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Button onClick={setDefault} disabled={isSaving}>
                  Default
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={onShowButtonTap}
              fullWidth
              disabled={isSaving}
            >
              {buttonText}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            {showSuggester ? (
              <Suggester
                type={SUGGEST_RESOURCES}
                onSuggestionSelected={(event, { suggestion }) => {
                  handleUpdateFields({ field: suggestion });
                  return false; // do not update the selected chips
                }}
                hintText="Search a resource"
                popover={false}
                single
                styles={tablePickerStyle}
                blurOnSelect={false}
                skipIcon
              />
            ) : (
              <DraggableList
                items={items}
                onChange={onChangeWithTable}
                itemTemplate={ColumnPickerTemplate}
                canDrag={!isSaving}
                disabled={isSaving}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <SaveButton
              className={classes.saveButton}
              isLoading={isSaving}
              text="Save selection"
              variant="contained"
              color="primary"
              fullWidth
              onClick={async e => {
                await onSaveClicked(e);
                handleClose(e);
              }}
            ></SaveButton>
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  </div>
);

ColumnPicker.propTypes = {
  anchorEl: PropTypes.any,
  buttonText: PropTypes.string,
  fields: PropTypes.array,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  handleUpdateFields: PropTypes.func,
  isSaving: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func,
  onChangeWithTable: PropTypes.func,
  onSave: PropTypes.func,
  onSaveClicked: PropTypes.func,
  onShowButtonTap: PropTypes.func,
  setDefault: PropTypes.func,
  showSuggester: PropTypes.bool,
  state: PropTypes.object,
  tableName: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object
};

export default withStateHandlers(
  { anchorEl: null },
  {
    handleOpen: () => evt => ({ anchorEl: evt.target }),
    handleClose: () => () => ({ anchorEl: null })
  }
)(withStyles(styles)(ColumnPicker));
