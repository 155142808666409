import Default from "./static";
import _GeoPoint from "./geo_point";
import GenerateValue from "./generate";
import * as Validators from "../validators";

const Double = Default(Validators.Float);
const Float = Default(Validators.Float);
const Integer = Default(Validators.Int);
const Long = Default(Validators.Int);
const Keyword = Default(Validators.Keyword);
const GeoPoint = _GeoPoint({
  lat: Validators.Lat,
  lon: Validators.Lon
});

export {
  Default,
  Double,
  Float,
  Integer,
  Long,
  Keyword,
  GeoPoint,
  GenerateValue
};
