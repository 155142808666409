import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import withNativeColor from "./with_native_color";

const MoveWidgetsIcon = props => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M20 16L24 12L20 8V16Z" />
        <path d="M4 8L0 12L4 16L4 8Z" />
        <path d="M8 20L12 24L16 20H8Z" />
        <path d="M16 4L12 0L8 4L16 4Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 7C7.89543 7 7 7.89543 7 9V15C7 16.1046 7.89543 17 9 17H15C16.1046 17 17 16.1046 17 15V9C17 7.89543 16.1046 7 15 7H9ZM9.02381 8.42857C8.69507 8.42857 8.42857 8.74837 8.42857 9.14286C8.42857 9.29879 8.4286 9.40469 8.42862 9.49091C8.42866 9.62281 8.42868 9.70866 8.42857 9.85714H15.5714L15.5714 9.14286C15.5714 8.74837 15.3049 8.42857 14.9762 8.42857H9.02381Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default withNativeColor(MoveWidgetsIcon);
