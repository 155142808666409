import { handleActions } from "redux-actions";
import { fromJS } from "immutable";

const NAV_SHOW_SIDEBAR = "NAV_SHOW_SIDEBAR";
const NAV_HIDE_SIDEBAR = "NAV_HIDE_SIDEBAR";
const NAV_TOGGLE_SIDEBAR = "NAV_TOGGLE_SIDEBAR";

export const showSidebar = () => ({ type: NAV_SHOW_SIDEBAR });
export const hideSidebar = () => ({ type: NAV_HIDE_SIDEBAR });
export const toggleSidebar = () => ({ type: NAV_TOGGLE_SIDEBAR });

export const initialState = fromJS({
  sidebar: false
});

export default handleActions(
  {
    NAV_SHOW_SIDEBAR: state =>
      state.merge({
        sidebar: true
      }),
    NAV_HIDE_SIDEBAR: state =>
      state.merge({
        sidebar: false
      }),
    NAV_TOGGLE_SIDEBAR: state =>
      state.merge({
        sidebar: !state.getIn(["sidebar"])
      })
  },
  initialState
);
