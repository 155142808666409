import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import styled from "styled-components";

const transitionName = {
  enter: "events-list-enter",
  enterActive: "events-list-enter-active",
  leave: "events-list-leave",
  leaveActive: "events-list-leave-active",
  appear: "events-list-appear",
  appearActive: "events-list-appear-active"
};

export default styled(props => (
  <ReactCSSTransitionGroup {...props} transitionName={transitionName} />
))`
  .${transitionName.enter} {
    max-height: 0;
    opacity: 0;

    &.${transitionName.enterActive} {
      max-height: 80px;
      opacity: 1;
      transition: all 500ms ease-in-out;
    }
  }

  .${transitionName.leave} {
    opacity: 1;
    max-height: 80px;

    &.${transitionName.leaveActive} {
      opacity: 0;
      max-height: 0;
      transition: all 500ms ease-in-out;
    }
  }
`;
