import { handleActions } from "redux-actions";
import * as nz from "utils/normalisation_utils";
import * as types from "./domain_action_types";
const initialState = {};
export default handleActions(
  {
    [types.DOMAINS_GET_SUCCESS]: nz.successItem
  },
  initialState
);
