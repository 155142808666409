import Popover from "@material-ui/core/Popover";
import { useTheme, withStyles } from "@material-ui/core/styles";
import FormIconButton from "components/buttons/form_icon";
import { authSelectors } from "ducks/auth";
import * as actions from "ducks/events";
import { userCanAcknowledgeEvents } from "ducks/events/events_selectors";
import { hideSidebar } from "ducks/nav";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose, withHandlers, withState } from "react-recompose";
import * as colors from "themes/colors";
import EventWidget from "../widget/widget_types/events_widget/events_widget";
import BadgeLeftContent from "./styled/badge_left_content";
import BadgePopoverContent from "./styled/badge_popover_content";
import BadgeRightContent from "./styled/badge_right_content";
import NotificationContainer from "./styled/notification_container";
import NotificationHeader from "./styled/notification_header";
import StyledBadge from "./styled/styled_badge";

const popoverStyles = theme => ({
  paper: {
    marginRight: "8px",
    marginTop: "16px",
    minHeight: "560px",
    borderBottomColor: theme.palette.primary.main,
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: "solid",
    borderTopWidth: 4,
    fontFamily: "telenor-regular, sans-serif",
    overflow: "inherit",
    "&::after,&::before": {
      bottom: "100%",
      left: "50%",
      border: "solid transparent",
      content: "' '",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none"
    },

    "&::after": {
      borderBottomColor: `${colors.white}`,
      borderWidth: "10px",
      marginLeft: "-10px"
    },

    "&::before": {
      borderBottomColor: "inherit",
      borderWidth: "16px",
      marginLeft: "-16px"
    }
  }
});

const Badge = ({ leftContent, rightContent, inSidebar = false, theme }) => (
  <StyledBadge>
    <BadgeLeftContent muiTheme={theme}>{leftContent}</BadgeLeftContent>
    <BadgeRightContent muiTheme={theme} inSidebar={inSidebar}>
      {rightContent}
    </BadgeRightContent>
  </StyledBadge>
);

Badge.propTypes = {
  leftContent: PropTypes.number.isRequired,
  rightContent: PropTypes.number.isRequired,
  inSidebar: PropTypes.bool,
  theme: PropTypes.object
};

const styles = {
  button: {
    width: "auto",
    cursor: "pointer",
    top: "1px",
    position: "relative",
    right: "8px"
  },
  compactButton: {
    width: "auto",
    margin: 0,
    padding: 0,
    position: "absolute",
    top: "4px",
    right: "20px",
    cursor: "pointer"
  },
  showAll: {
    backgroundColor: colors.white,
    borderTop: `1px solid ${colors.colorCelesteApprox}`,
    boxSizing: "border-box",
    height: 57,
    left: 0,
    paddingBottom: 10,
    paddingTop: 10,
    position: "absolute",
    right: 0,
    textAlign: "center"
  }
};
export const NotificationBadge = ({
  acknowledge,
  loadMore,
  loggedInUser,
  eventsThatNeedsAcknowledgment,
  eventsThatNeedsAcknowledgmentTotal,
  newEvents,
  newEventsTotal,
  onClose,
  onOpen,
  onShowAll,
  popover,
  inSidebar = false,
  onShowAllAndCloseSidebar,
  isUserAuthorizedToAcknowledge,
  classes
}) => {
  const theme = useTheme();
  return (
    <>
      <div
        onClick={inSidebar ? onShowAllAndCloseSidebar : onOpen}
        title={
          inSidebar
            ? null
            : "Events that needs acknowledgment | Other new events"
        }
        style={inSidebar ? styles.compactButton : styles.button}
      >
        <Badge
          leftContent={eventsThatNeedsAcknowledgmentTotal}
          rightContent={newEventsTotal}
          inSidebar={inSidebar}
          theme={theme}
        />
      </div>
      {!inSidebar && (
        <Popover
          anchorEl={popover.anchorEl}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={popover.isOpen}
          onClose={onClose}
          classes={{ paper: classes.paper }}
        >
          <NotificationContainer>
            <NotificationHeader>
              Needs acknowledgment
              <StyledBadge inPopover>
                <BadgePopoverContent muiTheme={theme} needsAcknowledgment>
                  {eventsThatNeedsAcknowledgmentTotal}
                </BadgePopoverContent>
              </StyledBadge>
            </NotificationHeader>
            <EventWidget
              animate
              events={eventsThatNeedsAcknowledgment}
              eventsTotal={eventsThatNeedsAcknowledgmentTotal}
              keyPrefix="needsAcknowledgment"
              onAcknowledge={acknowledge}
              onLoadMore={loadMore}
              maxEvents={10}
              overflowText={
                "Only the first 10 events are shown. To view them all click show all below"
              }
              isUserAuthorizedToAcknowledge={isUserAuthorizedToAcknowledge}
              style={{
                borderBottom: `1px solid ${colors.colorCelesteApprox}`
              }}
            />
            <NotificationHeader>
              Other new events
              <StyledBadge inPopover>
                <BadgePopoverContent muiTheme={theme}>
                  {newEventsTotal}
                </BadgePopoverContent>
              </StyledBadge>
            </NotificationHeader>
            <EventWidget
              animate
              events={newEvents}
              eventsTotal={newEventsTotal}
              keyPrefix="new"
              onLoadMore={loadMore}
              lastEventReadTimestamp={loggedInUser.eventReadTimestamp}
              maxEvents={10}
              onAcknowledge={acknowledge}
              isUserAuthorizedToAcknowledge={isUserAuthorizedToAcknowledge}
            />
          </NotificationContainer>
          <div style={styles.showAll}>
            <FormIconButton
              text="Show all"
              onClick={onShowAll}
              color="primary"
            />
          </div>
        </Popover>
      )}
    </>
  );
};

NotificationBadge.propTypes = {
  acknowledge: PropTypes.func,
  eventsThatNeedsAcknowledgment: PropTypes.object.isRequired,
  eventsThatNeedsAcknowledgmentTotal: PropTypes.number.isRequired,
  loadMore: PropTypes.func,
  loggedInUser: PropTypes.object,
  newEvents: PropTypes.object.isRequired,
  newEventsTotal: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onShowAll: PropTypes.func.isRequired,
  popover: PropTypes.object.isRequired,
  inSidebar: PropTypes.bool,
  onShowAllAndCloseSidebar: PropTypes.func.isRequired,
  isUserAuthorizedToAcknowledge: PropTypes.bool,
  classes: PropTypes.shape({
    paper: PropTypes.string
  })
};

const mapStateToProps = state => ({
  eventsThatNeedsAcknowledgment: actions.getEventsThatNeedsAcknowledgment(
    state.events
  ),
  eventsThatNeedsAcknowledgmentTotal: actions.getEventsThatNeedsAcknowledgmentTotal(
    state.events
  ),
  newEvents: actions.getNewEvents(state.events),
  newEventsTotal: actions.getNewEventsTotal(state.events),
  loggedInUser: authSelectors.userSelector(state),
  isUserAuthorizedToAcknowledge: userCanAcknowledgeEvents(state)
});

class NotificationBadgeContainer extends React.Component {
  componentDidMount = () => {
    this.props.fetchEventsThatNeedsAcknowledgment();
    this.props.fetchNewEvents({
      lastEventReadTimestamp: Date.now()
    });
  };
  render = () => <NotificationBadge {...this.props} />;
}

NotificationBadgeContainer.propTypes = {
  fetchEventsThatNeedsAcknowledgment: PropTypes.func.isRequired,
  fetchNewEvents: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  hideSidebar: () => dispatch(hideSidebar()),
  goToEventsPage: () =>
    dispatch(
      push({
        pathname: "/things/events/all",
        search: null
      })
    ),
  acknowledge: id => dispatch(actions.acknowledge(id)),
  loadMore: () => {
    dispatch(actions.fetchEventsThatNeedsAcknowledgment());
  },
  fetchEventsThatNeedsAcknowledgment: () =>
    dispatch(actions.fetchEventsThatNeedsAcknowledgment()),
  fetchNewEvents: payload => dispatch(actions.fetchNewEvents(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("popover", "update", {
    isOpen: false,
    anchorEl: null
  }),
  withStyles(popoverStyles),
  withHandlers({
    onOpen: props => evt =>
      props.update({
        isOpen: true,
        anchorEl: evt.target
      }),
    onClose: props => () => {
      const now = Date.now();
      props.fetchNewEvents({ lastEventReadTimestamp: now });
      props.update({
        isOpen: false,
        anchorEl: null
      });
    },
    onShowAll: props => () => {
      props.update({
        isOpen: false,
        anchorEl: null
      });
      const now = Date.now();
      props.fetchNewEvents({ lastEventReadTimestamp: now });
      props.goToEventsPage();
    },
    onShowAllAndCloseSidebar: props => () => {
      props.hideSidebar();
      props.goToEventsPage();
    }
  })
)(NotificationBadgeContainer);
