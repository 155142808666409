import { mergeDeepLeft, dissoc, reduceRight, keys, propOr } from "ramda";
import { getCurrentMarkerForBucket } from "ducks/bucket_selectors";

export const _adaptFilesAndOwners = files => {
  const adaptFile = (file, b) => {
    const owner = file.Owner;

    return mergeDeepLeft(
      {
        files: {
          [file.Key]: { ...dissoc("Key", file), id: file.Key, Owner: owner.ID }
        },
        owners: { [owner.ID]: owner }
      },
      b
    );
  };

  return reduceRight(adaptFile, { files: {}, owners: {} }, files);
};

const _getNextMarker = data => {
  if (data.nextMarker) return data.nextMarker;
  else if (data.files.length > 0) return data.files[data.files.length - 1].Key;
  else return {};
};

const _adaptPaging = (bucketData, state) => {
  return {
    [`${bucketData.bucketName}_paging`]: {
      id: `${bucketData.bucketName}_paging`,
      maxKeys: bucketData.maxKeys,
      currentMarker: bucketData.marker,
      nextMarker: _getNextMarker(bucketData),
      isTruncated: bucketData.isTruncated,

      prevMarker: getCurrentMarkerForBucket(state, {
        bucket: bucketData.bucketName
      })
    }
  };
};

export const adaptBucket = (bucketData, state) => {
  const { files, owners } = _adaptFilesAndOwners(
    propOr([], "files")(bucketData)
  );

  return {
    results: [bucketData.bucketName],
    entities: {
      buckets: {
        [bucketData.bucketName]: {
          id: bucketData.bucketName,
          prefix: bucketData.prefix,
          files: keys(files),
          paging: `${bucketData.bucketName}_paging`
        }
      },
      files: files,
      owners: owners,
      paging: _adaptPaging(bucketData, state)
    }
  };
};
