import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as R from "ramda";

const styles = theme => ({
  card: {
    display: "flex",
    width: "100%",
    background: "white"
  },
  summaryColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    borderRight: "2px solid #eee"
  },
  metadataColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    paddingLeft: theme.spacing(2),
    maxHeight: "287px",
    overflowY: "auto"
  },
  descriptionHeader: {
    gridColumn: "1/3",
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  grey: {
    color: "#aaa"
  },
  fieldKey: {
    width: "20%"
  },
  twoColumns: {
    paddingRight: theme.spacing(2),
    maxHeight: "200px",
    overflowY: "auto"
  },
  code: {
    margin: "1px",
    fontSize: "12px",
    width: "80%",
    overflow: "auto"
  },
  dataPair: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1)
  }
});

const _combineMetadataValuesAndDefinitions = (values, definitions) => {
  return Object.entries(values || {}).map(dataItem => {
    const key = definitions.find(i => i.id === dataItem[0]) || {};
    return { value: dataItem[1], ...key };
  });
};

const MetaValue = ({ children, ...rest }) => (
  <Typography {...rest} variant="body2">
    {children}
  </Typography>
);
MetaValue.propTypes = { children: PropTypes.node };

export const ThingTypeSummary = ({
  thingTypeId,
  classes,
  getThingTypeQuery,
  thingTypeMetadataQuery
}) => {
  const { thingType } = getThingTypeQuery;
  const metadataValues = R.prop("data", thingType);
  const metadataDefinitions = R.pathOr(
    [],
    ["domain", "thingTypeMetadata"],
    thingTypeMetadataQuery
  );
  const thingTypeMetadata = _combineMetadataValuesAndDefinitions(
    metadataValues,
    metadataDefinitions
  );
  return (
    <React.Fragment>
      <div className={classes.card}>
        <div className={classes.summaryColumn}>
          <span className={classes.dataPair}>
            <MetaValue className={`${classes.grey} ${classes.fieldKey}`}>
              Id
            </MetaValue>
            <MetaValue>{thingTypeId}</MetaValue>
          </span>
          <span className={classes.dataPair}>
            <MetaValue className={`${classes.grey} ${classes.fieldKey}`}>
              Label
            </MetaValue>
            <MetaValue>{R.prop("label", thingType)}</MetaValue>
          </span>
          <span className={classes.dataPair}>
            <MetaValue className={`${classes.grey} ${classes.fieldKey}`}>
              Domain
            </MetaValue>
            <MetaValue>{R.prop("domain", thingType)}</MetaValue>
          </span>
          <div className={classes.twoColumns}>
            <Typography variant="subtitle1">
              {R.prop("description", thingType)}
            </Typography>
          </div>
        </div>

        <div className={classes.metadataColumn}>
          <div className={classes.descriptionHeader}>
            <Typography className={classes.grey} variant="subtitle1">
              CUSTOM METADATA
            </Typography>
          </div>
          {thingTypeMetadata.length > 0 ? (
            thingTypeMetadata.map((metaItem, i) => (
              <span className={classes.dataPair} key={i}>
                <MetaValue className={`${classes.grey} ${classes.fieldKey}`}>
                  {metaItem.label}
                </MetaValue>
                {metaItem.type === "code" ? (
                  <pre className={classes.code}>{metaItem.value}</pre>
                ) : (
                  <MetaValue>{metaItem.value}</MetaValue>
                )}
              </span>
            ))
          ) : (
            <div className={classes.twoColumns}>
              <MetaValue>No custom metadata available</MetaValue>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

ThingTypeSummary.propTypes = {
  thingTypeId: PropTypes.string,
  classes: PropTypes.object,
  getThingTypeQuery: PropTypes.object,
  thingTypeMetadataQuery: PropTypes.object
};

export default withStyles(styles)(ThingTypeSummary);
