import getWidgetBase from "../get_widget_base";
import { widgetProp } from "utils/dashboard_utils";
import { ProcessWidget } from "./process_widget_container";

const container = { height: 180, width: 500 };

export const getWidget = props => {
  return getWidgetBase({
    ...props,
    addedVariables: widgetProp(props.widget, "addedVariables"),
    container: container,
    image: widgetProp(props.widget, "image"),
    noResize: true,
    scrollable: true,
    isRealtime: true,
    widgetComp: ProcessWidget
  });
};
