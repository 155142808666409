import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const BackdateSelector = ({
  backdate: { value, usePeriod },
  handleBackdateChanged
}) => {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography component="p" variant="body1">
          Export data from last
        </Typography>
        <Typography component="p" variant="body1">
          <TextField
            value={value}
            onChange={e =>
              handleBackdateChanged({
                value: e.target.value > 0 ? e.target.value : 1
              })
            }
            disabled={usePeriod}
            type="number"
            style={{ marginLeft: "6px" }}
          />
          Days
        </Typography>
        <FormControlLabel
          label="Use job period"
          labelPlacement="bottom"
          value={usePeriod.toString()}
          control={
            <Checkbox
              checked={usePeriod}
              onChange={e =>
                handleBackdateChanged({ usePeriod: e.target.checked })
              }
            />
          }
        />
      </Grid>
    </Grid>
  );
};

BackdateSelector.propTypes = {
  backdate: PropTypes.object,
  handleBackdateChanged: PropTypes.func
};

export default BackdateSelector;
