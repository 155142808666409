import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import {
  SET_SELECTED_DOMAIN,
  getBrandingThemeSuccess,
  getBrandingThemeFailure
} from "./branding";

export const getBrandingThemeEpic = (action$, store, { api }) =>
  action$.ofType(SET_SELECTED_DOMAIN).mergeMap(({ payload }) =>
    api.domains
      .theme$(payload)
      .map(getBrandingThemeSuccess)
      .catch(error =>
        Observable.of(getBrandingThemeFailure(error), apiError(error))
      )
  );
