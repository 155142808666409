import React from "react";
import PropTypes from "prop-types";
import FormIconButton from "components/buttons/form_icon";

const PreviousButton = ({
  steps,
  activeStep,
  handlePreviousStep,
  closeModalDataExport
}) => {
  const isLastStep = activeStep === steps - 1;
  return isLastStep ? (
    <FormIconButton
      onClick={handlePreviousStep}
      data-test="previous"
      text="Previous"
    />
  ) : (
    <FormIconButton
      onClick={closeModalDataExport}
      data-test="cancel"
      text="Cancel"
    />
  );
};

PreviousButton.propTypes = {
  steps: PropTypes.number,
  activeStep: PropTypes.number.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  closeModalDataExport: PropTypes.func.isRequired
};

export default PreviousButton;
