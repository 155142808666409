import { graphql } from "@apollo/client/react/hoc";
import { Dialog } from "components/telenor/dialog";
import * as batchActions from "ducks/batches";
import CreateThingsForm from "forms_new/things/create";
import { CREATE_THING, CREATE_THINGS_BATCH } from "graphql/queries";
import { withTimezone, withVariables } from "graphql/utils/general";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { compose, withState } from "react-recompose";
import { withThingType } from "routes/main/things/thing_type/with_thing_type";

const CreateThings = ({
  open,
  title,
  onCancel,
  createSingle,
  createBatch,
  isLoading,
  thingType
}) => (
  <Dialog
    onClose={onCancel}
    open={open}
    title={title}
    className="data-test-create-things-modal"
  >
    <CreateThingsForm
      handleCancel={onCancel}
      createSingle={createSingle}
      createBatch={createBatch}
      isLoading={isLoading}
      thingType={thingType}
    />
  </Dialog>
);

CreateThings.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired,
  createSingle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  thingType: PropTypes.shape({
    domain: PropTypes.string
  })
};

const mapStateToProps = (state, { thingType }) => {
  const routing = state.routing;
  const location = routing.locationBeforeTransitions || { state: {} };
  return {
    title: `Add new things for: ${R.prop("label", thingType)}`,
    location,
    thingType
  };
};

const withIsLoading = withState("isLoading", "setIsLoading", false);

const mergeProps = (
  { ...props },
  dispatchProps,
  {
    closeModalThing,
    modalStateThing: { open },
    createThing,
    createThings,
    setIsLoading,
    isLoading,
    thingType
  }
) => {
  return {
    ...props,
    isLoading,
    open,
    onCancel: closeModalThing,
    createBatch: data => {
      setIsLoading(true);
      createThings({
        variables: {
          input: {
            thingType: R.prop("id", thingType),
            ...data
          }
        }
      })
        .then(() => {
          // upate redux store with information about loading batch
          dispatchProps.createThings();
          setIsLoading(false);
          return closeModalThing();
        })
        .catch(() => setIsLoading(false));
    },
    createSingle: data => {
      setIsLoading(true);
      createThing({
        variables: {
          input: {
            thingType: R.prop("id", thingType),
            ...data
          }
        }
      })
        .then(() => {
          setIsLoading(false);
          return closeModalThing();
        })
        .catch(() => setIsLoading(false));
    },
    closeModalThing
  };
};

export default compose(
  withThingType(),
  graphql(CREATE_THING, {
    name: "createThing",
    options: withVariables(["input"], withTimezone)
  }),
  graphql(CREATE_THINGS_BATCH, { name: "createThings" }),
  withIsLoading,
  connect(mapStateToProps, { ...batchActions, replace }, mergeProps)
)(CreateThings);
