let _storage = global.localStorage;

const convert = data => JSON.stringify(data);
const parse = data => (data === undefined ? undefined : JSON.parse(data));

export const init = storage => {
  _storage = storage;
  return _storage;
};
export const set = (key, data) => {
  try {
    // WORKAROUND: In Safari private mode an QuotaExceededError is thrown when using setItem on localstorage.
    _storage.setItem(key, convert(data));
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};
export const remove = key => _storage.removeItem(key);
export const get = key => parse(_storage.getItem(key));

export const clearAwsCredentials = () => {
  // aws seems to put it's own info into the global.localStorage
  const awsKeys = Object.keys(_storage).filter(
    key =>
      key.indexOf("aws.cognito.identity-id") >= 0 ||
      key.indexOf("aws.cognito.identity-providers") >= 0
  );
  awsKeys.forEach(key => _storage.removeItem(key));
};
