import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { initWidget } from "ducks/bucket";
import { listAttributesScoped } from "ducks/bucket_selectors";
import { userCanReadFilesOnRoot } from "ducks/users/users_selectors";
import { path, pathOr, prop } from "ramda";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withPropsOnChange,
  withState,
  withStateHandlers
} from "react-recompose";
import { isEmptyOrNil, isNotEmptyOrNil } from "utils/general_utils";
import { selectedRowsIndices, selectRowsItems } from "utils/table_utils";
import { uploadFileModalHoc } from "../upload_file/upload_file_modal";
import FileList from "./file_list";

const _defaultSelectors = {
  listAttributesScoped
};
export const _makeMapStateToProps = (selectors = _defaultSelectors) => (
  state,
  props
) => ({
  ...selectors.listAttributesScoped(state, props),
  canReadFilesOnRoot: userCanReadFilesOnRoot(state)
});

export const _mapDispatchToProps = {
  initWidget
};

export const _withStateHandlers = withStateHandlers(
  () => ({ continuationToken: undefined }),
  {
    onLoadMore: state => nextContinuationToken => {
      return { ...state, continuationToken: nextContinuationToken };
    }
  }
);
export const _withHandlers = withHandlers({
  onLoadMore: ({ data }) => () => {
    data.fetchMore({
      variables: {
        continuationToken: path(["allFiles", "pageInfo", "nextMarker"], data)
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldFiles = pathOr([], ["allFiles", "files"], previousResult);
        const newFiles = pathOr([], ["allFiles", "files"], fetchMoreResult);
        const newPageInfo = path(["allFiles", "pageInfo"], fetchMoreResult);
        return {
          allFiles: {
            files: oldFiles.concat(newFiles),
            pageInfo: newPageInfo
          }
        };
      }
    });
  },
  onSelectAll: ({ setSelectedRows, data }) => selectedRows => {
    setSelectedRows(selectedRowsIndices(data.allFiles.files, selectedRows));
  },
  onRowSelection: ({ setSelectedRows, data, selectedRows }) => (
    { target: { checked } },
    rowIndex
  ) => {
    const newSelectedRows = Array.from(selectedRows);

    checked
      ? newSelectedRows.push(rowIndex)
      : newSelectedRows.splice(newSelectedRows.indexOf(rowIndex), 1);

    setSelectedRows(selectedRowsIndices(data.allFiles.files, newSelectedRows));
  },
  onRemove: ({
    data,
    selectedRows,
    setSelectedRows,
    removeFile,
    isPublic,
    isRoot,
    thingType,
    thingName
  }) => () => {
    const selectedFiles = selectRowsItems(data.allFiles.files, selectedRows);
    selectedFiles.forEach(file => {
      removeFile({
        variables: {
          fileName: file.key,
          public: isPublic,
          root: isRoot,
          thingName: thingName,
          thingType: thingType
        },
        update: () => {
          setSelectedRows([]);
          data.refetch();
        }
      });
    });
  }
});

export const fetchFilesQuery = gql`
  query allFiles(
    $thingType: String
    $listAll: Boolean
    $maxKeys: Int
    $prefix: String
    $isPublic: Boolean
    $isRoot: Boolean
    $thingName: String
    $continuationToken: String
  ) {
    allFiles(
      scope: {
        thingType: $thingType
        listAll: $listAll
        public: $isPublic
        root: $isRoot
        thingName: $thingName
      }
      searchOptions: {
        prefix: $prefix
        paging: { marker: $continuationToken, size: $maxKeys }
      }
    ) {
      pageInfo {
        hasNext
        nextMarker
      }
      files {
        id
        url
        key
        thingName
        thingType
        folder
        size
        eTag
        lastModified
      }
    }
  }
`;

export const removeFile = gql`
  mutation removeFile(
    $fileName: String!
    $public: Boolean
    $root: Boolean
    $thingName: String
    $thingType: String
  ) {
    removeFile(
      scope: {
        fileName: $fileName
        public: $public
        root: $root
        thingName: $thingName
        thingType: $thingType
      }
    ) {
      id
    }
  }
`;

const _withState = withState("selectedRows", "setSelectedRows", []);

const _withScopeChange = withPropsOnChange(
  ["currentScope"],
  ({ setSelectedRows }) => {
    setSelectedRows([]);
    return {};
  }
);

const _withPrefixChange = withPropsOnChange(
  (props, nextProps) =>
    isNotEmptyOrNil(props.prefix) && isEmptyOrNil(nextProps.prefix),
  props => {
    const refetch = path(["data", "refetch"], props);
    refetch && refetch();
    return {};
  }
);

export const _withErrorHandling = withPropsOnChange(["error"], props => {
  if (props.error !== undefined) {
    return { files: [] };
  } else {
    return {};
  }
});

const _withThingTypeId = withProps(({ thingType }) => ({
  thingTypeId: prop("id", thingType)
}));

const _withProps = withProps(({ isLoading, data = {} }) => ({
  error: path(["error"], data),
  files: path(["allFiles", "files"])(data),
  nextContinuationToken: path(["allFiles", "pageInfo", "nextMarker"])(data),
  isTruncated: path(["allFiles", "pageInfo", "hasNext"])(data),
  isLoading: path(["allFiles", "files"], data) == undefined || isLoading
}));

const _validateProps = ({
  isPublic,
  isRoot,
  thingType,
  thingName,
  canReadFilesOnRoot
}) => {
  const isEmpty =
    !isPublic &&
    !isRoot &&
    (thingType == null || thingType === "") &&
    (thingName == null || thingName === "");
  const isInvalid = thingType && thingName;
  const isAllowed = isRoot ? canReadFilesOnRoot : true;
  const shouldSkip = isEmpty || isInvalid || !isAllowed;
  return shouldSkip;
};

export const _lifecycle = lifecycle({
  componentDidMount() {
    this.props.initWidget({
      widgetId: this.props.widgetId,
      initialScope: this.props.scope,
      thingType: this.props.thingType,
      thingName: this.props.thingName
    });
  }
});

export default compose(
  connect(_makeMapStateToProps(), _mapDispatchToProps),
  _lifecycle,
  _withStateHandlers,
  _withThingTypeId,
  graphql(fetchFilesQuery, {
    skip: _validateProps
  }),
  graphql(removeFile, { name: "removeFile" }),
  _withProps,
  _withState,
  _withPrefixChange,
  _withScopeChange,
  _withErrorHandling,
  _withHandlers,
  uploadFileModalHoc(prop("widgetId"))
)(FileList);
