import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: "rgb(245, 245, 245) !important"
  },
  hover: {
    backgroundColor: "rgb(245, 245, 245)"
  }
}));

export const TableRowFragment = props => {
  const { renderColumns, ...tableRowProps } = props;
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableRow
        {...tableRowProps}
        hover={isHovered}
        classes={{ selected: classes.selected, hover: classes.hover }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {renderColumns({ isHovered })}
      </TableRow>
    </React.Fragment>
  );
};

TableRowFragment.propTypes = {
  renderColumns: PropTypes.func.isRequired,
  hover: PropTypes.bool
};
