import * as R from "ramda";
import {
  Random,
  Change,
  Static,
  Circle,
  Line,
  Validators,
  Types
} from "./strategy_parameter_editors";

const validateIntBetween = (min, max) =>
  Validators.chain(Validators.Int, Validators.Between(min, max));
const currentValueResource = R.pathOr(0, ["metadata", "value"]);

export const types = {
  [Types.TYPE_DOUBLE]: {
    supportedGenerators: ["static", "random", "change"],
    defaultStrategy: r => ({
      strategyType: "random",
      strategyParameters: {
        min: 0,
        max: 1,
        low: 0,
        high: 0.1,
        precision: 2,
        value: currentValueResource(r)
      }
    }),
    editorComponent: {
      random: Random.Double,
      static: Static.Double,
      change: Change.Double
    },
    generate: {
      random: Random.GenerateValue,
      static: Static.GenerateValue,
      change: Change.GenerateValue
    }
  },
  [Types.TYPE_FLOAT]: {
    supportedGenerators: ["static", "random", "change"],
    defaultStrategy: r => ({
      strategyType: "random",
      strategyParameters: {
        min: 0,
        max: 10,
        low: 0,
        high: 1.0,
        precision: 2,
        value: currentValueResource(r)
      }
    }),
    editorComponent: {
      random: Random.Float,
      static: Static.Float,
      change: Change.Float
    },
    generate: {
      random: Random.GenerateValue,
      static: Static.GenerateValue,
      change: Change.GenerateValue
    }
  },
  [Types.TYPE_INTEGER]: {
    supportedGenerators: ["static", "random", "change"],
    defaultStrategy: r => ({
      strategyType: "random",
      strategyParameters: {
        min: 0,
        max: 100,
        low: 0,
        high: 10,
        value: currentValueResource(r)
      }
    }),
    editorComponent: {
      random: Random.Integer,
      static: Static.Integer,
      change: Change.Integer
    },
    generate: {
      random: Random.GenerateValue,
      static: Static.GenerateValue,
      change: Change.GenerateValue
    }
  },
  [Types.TYPE_LONG]: {
    supportedGenerators: ["static", "random", "change"],
    defaultStrategy: r => ({
      strategyType: "random",
      strategyParameters: {
        min: 0,
        max: 1000,
        low: 0,
        high: 100,
        value: currentValueResource(r)
      }
    }),
    editorComponent: {
      random: Random.Long,
      static: Static.Long,
      change: Change.Long
    },
    generate: {
      random: Random.GenerateValue,
      static: Static.GenerateValue,
      change: Change.GenerateValue
    }
  },
  [Types.TYPE_KEYWORD]: {
    supportedGenerators: ["static"],
    defaultStrategy: r => ({
      strategyType: "static",
      strategyParameters: { value: currentValueResource(r) }
    }),
    editorComponent: {
      static: Static.Keyword
    },
    generate: {
      static: Static.GenerateValue
    }
  },
  [Types.TYPE_GEOPOINT]: {
    supportedGenerators: ["static", "square", "circle", "change", "line"],
    defaultStrategy: r => {
      return {
        strategyType: "circle",
        strategyParameters: R.pipe(
          R.pathOr("0.0,0.0", ["metadata", "value"]),
          R.split(","),
          v => ({
            lat: parseFloat(v[0]),
            lon: parseFloat(v[1]),
            precision: 7,
            lat_min: parseFloat(v[0]) - 0.1,
            lat_max: parseFloat(v[0]) + 0.1,
            lon_min: parseFloat(v[1]) - 0.1,
            lon_max: parseFloat(v[1]) + 0.1,
            lat_low: 0,
            lat_high: 0.01,
            lon_low: 0,
            lon_high: 0.01,
            radius: 0.1
          })
        )(r)
      };
    },
    editorComponent: {
      static: Static.GeoPoint,
      square: Random.GeoPoint,
      change: Change.GeoPoint,
      circle: Circle.GeoPoint,
      line: Line.GeoPoint
    },
    generate: {
      static: Static.GenerateValue,
      square: Random.GenerateValue,
      change: Change.GenerateValue,
      circle: Circle.GenerateValue,
      line: Line.GenerateValue
    }
  }
};

export const resourceSpecificTypes = {
  latlng: {
    [Types.TYPE_GEOPOINT]: types[Types.TYPE_GEOPOINT]
  },
  "tcxn/battery_level": {
    [Types.TYPE_BYTE]: {
      supportedGenerators: ["static", "random", "change"],
      defaultStrategy: r => ({
        strategyType: "random",
        strategyParameters: {
          low: 0,
          high: 1,
          min: 0,
          max: 100,
          value: currentValueResource(r)
        }
      }),
      editorComponent: {
        random: Random.Default(validateIntBetween(0, 100)),
        static: Static.Default(validateIntBetween(0, 100)),
        change: Change.Default({
          value: validateIntBetween(0, 100),
          low: validateIntBetween(-100, 100),
          high: validateIntBetween(-100, 100)
        })
      },
      generate: {
        random: Random.GenerateValue,
        static: Static.GenerateValue,
        change: (...args) =>
          Math.max(Math.min(Change.GenerateValue(...args), 100), 0)
      }
    }
  },
  "tcxn/connection_status": {
    [Types.TYPE_BYTE]: {
      supportedGenerators: ["static", "random", "change"],
      defaultStrategy: r => ({
        strategyType: "random",
        strategyParameters: {
          low: 0,
          high: 1,
          min: 0,
          max: 2,
          value: currentValueResource(r)
        }
      }),
      editorComponent: {
        random: Random.Default(validateIntBetween(0, 2)),
        static: Static.Default(validateIntBetween(0, 2)),
        change: Change.Default(validateIntBetween(0, 2))
      },
      generate: {
        random: Random.GenerateValue,
        static: Static.GenerateValue,
        change: (...args) =>
          Math.max(Math.min(Change.GenerateValue(...args), 2), 0)
      }
    }
  },
  "tcxn/rssi": {
    [Types.TYPE_BYTE]: {
      supportedGenerators: ["static", "random", "change"],
      defaultStrategy: r => ({
        strategyType: "random",
        strategyParameters: {
          low: 0,
          high: 1,
          min: 0,
          max: 100,
          value: currentValueResource(r)
        }
      }),
      editorComponent: {
        random: Random.Default(validateIntBetween(0, 100)),
        static: Static.Default(validateIntBetween(0, 100)),
        change: Change.Default({
          value: validateIntBetween(0, 100),
          low: validateIntBetween(-100, 100),
          high: validateIntBetween(-100, 100)
        })
      },
      generate: {
        random: Random.GenerateValue,
        static: Static.GenerateValue,
        change: (...args) =>
          Math.max(Math.min(Change.GenerateValue(...args), 100), 0)
      }
    }
  }
};

export const isSpecificResourceOrTypeSupported = (type, name) =>
  type in types || name in resourceSpecificTypes;

export const supportedGenerators = (type, name) =>
  R.pathOr(
    R.path([type, "supportedGenerators"], types),
    [name, type, "supportedGenerators"],
    resourceSpecificTypes
  );

export const componentFromConfig = ({ type, strategyType, resourcePath }) =>
  R.pathOr(
    R.path([type, "editorComponent", strategyType], types),
    [resourcePath, type, "editorComponent", strategyType],
    resourceSpecificTypes
  );

export default types;
