import Joi from "joi-browser";

const isRealtimeOptions = [
  {
    label: "No",
    id: "realtimeOption-no",
    value: 0,
    index: 0
  },
  {
    label: "Yes",
    id: "reatimeOption-yes",
    value: 1,
    index: 1
  }
];

const WidgetEditRealtime = ({ onIsRealtimeChange }) => ({
  fields: [
    {
      id: "isRealtime",
      key: "isRealtime",
      options: isRealtimeOptions,
      label: "Enable realtime",
      hintText: "Is Realtime",
      type: "select",
      onChange: onIsRealtimeChange
    }
  ],
  initialModel: {
    isRealtime: 0
  },
  schema: {
    isRealtime: Joi.number()
  }
});

export default WidgetEditRealtime;
