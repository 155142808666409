/* eslint-disable react/no-children-prop */
import { withApollo } from "@apollo/client/react/hoc";
import { getOptions, loadSchema } from "ducks/schema";
import { clearThingShadow } from "ducks/thing";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "react-recompose";
import { withThingType } from "./with_thing_type";
import { throttle } from "../../../../utils/execution_utils";

class ThingTypesContainer extends Component {
  constructor(...args) {
    super(...args);
  }

  componentDidMount() {
    const {
      params: { thingType },
      getOptions,
      loadSchema
    } = this.props;

    getOptions({ thingType });
    loadSchema(thingType);
  }

  componentDidUpdate(prevProps) {
    const {
      push,
      params,
      error,
      location,
      _ttGetThingType,
      client
    } = this.props;
    if (!error) {
      const thingTypeChanged = params.thingType !== prevProps.params.thingType;
      const tid = params.thingType
        ? params.thingType
        : prevProps.params.thingType;
      if (thingTypeChanged) {
        _ttGetThingType({ thingType: tid, viewMode: params.viewMode, client });
      }
    }
    if (error) {
      if (error.match(/not found/i)) {
        // Timeout is needed, routerWillLeave::this.props.error is null if timeout is omitted.
        setTimeout(() => {
          push({ pathname: "/", query: location.query });
        }, 0);
      }
    }
  }

  render() {
    const { children, thingType } = this.props;
    // Only render if we have a ThingType
    if (!thingType) {
      return null;
    }

    const label = thingType.label;
    return <div data-test={`page-thing-type__${label}`}>{children}</div>;
  }
}

ThingTypesContainer.propTypes = {
  client: PropTypes.object,
  thingTypeId: PropTypes.string,
  getThingType: PropTypes.func,
  push: PropTypes.func,
  data: PropTypes.object,
  getOptions: PropTypes.func,
  _ttGetThingType: PropTypes.func,
  loadSchema: PropTypes.func,
  children: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  thingType: PropTypes.object,
  thingTypes: PropTypes.object,
  thingTypeState: PropTypes.object,
  isLoadingThingType: PropTypes.bool,
  route: PropTypes.object,
  thingTypeDirtyState: PropTypes.object,
  routes: PropTypes.array,
  error: PropTypes.string
};

export default compose(
  withRouter,
  withThingType(),
  withApollo,
  connect(
    (state, { router }) => {
      return {
        error: state.thingTypes.get("error"),
        push: router.push
      };
    },
    dispatch => ({
      getOptions: payload => dispatch(getOptions(payload)),
      loadSchema: thingTypeId => dispatch(loadSchema(thingTypeId)),
      _ttGetThingType: throttle(({ thingType }) => {
        dispatch(clearThingShadow());
        dispatch(loadSchema(thingType));
        dispatch(getOptions({ thingType }));
      }, 100),
      clearThingShadow: () => dispatch(clearThingShadow())
    })
  )
)(ThingTypesContainer);
