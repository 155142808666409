import kinks from "@turf/kinks";
import { List } from "immutable";

const expressionDefaults = {
  scalar: {
    operator: "gt",
    value: ""
  },
  latlng: {
    operator: "inside",
    value: []
  }
};

const supportedOperators = [
  "enter",
  "enterPoi",
  "eq",
  "gt",
  "gte",
  "inside",
  "insidePoi",
  "leave",
  "leavePoi",
  "lt",
  "lte",
  "ne",
  "outside",
  "outsidePoi"
];

export const operatorIsSupported = operator =>
  supportedOperators.includes(operator);

export default {
  // We don't want to display the observation timestamp id as label like we do with other resources
  observationTimestamp: {
    id: "__tcxnInternalObservationTimestamp",
    label: "Observation timestamp"
  },
  // Resources that cannot be compared with lt/eq/gt/
  nonScalarResources: ["latlng"],

  describe({ resource, operator, value }) {
    const upperCaseResource =
      resource === this.observationTimestamp.id
        ? this.observationTimestamp.label.toUpperCase()
        : resource.toUpperCase();

    let friendlyDescription;
    switch (operator) {
      case "lt":
        friendlyDescription = `${upperCaseResource} is BELOW ${value}`;
        break;
      case "lte":
        friendlyDescription = `${upperCaseResource} is EQUAL or BELOW ${value}`;
        break;
      case "gt":
        friendlyDescription = `${upperCaseResource} is ABOVE ${value}`;
        break;
      case "gte":
        friendlyDescription = `${upperCaseResource} is EQUAL or ABOVE ${value}`;
        break;
      case "eq":
        friendlyDescription =
          value === ""
            ? `${upperCaseResource} is EMPTY`
            : `${upperCaseResource} is EQUAL to ${value}`;
        break;
      case "ne":
        friendlyDescription =
          value === ""
            ? `${upperCaseResource} is NOT EMPTY`
            : `${upperCaseResource} is NOT EQUAL to ${value}`;
        break;
      case "inside":
        friendlyDescription = `${upperCaseResource} is INSIDE geofence`;
        break;
      case "outside":
        friendlyDescription = `${upperCaseResource} is OUTSIDE geofence`;
        break;
      case "enter":
        friendlyDescription = `${upperCaseResource} ENTERS geofence`;
        break;
      case "leave":
        friendlyDescription = `${upperCaseResource} LEAVES geofence`;
        break;
      case "insidePoi":
        friendlyDescription = `${upperCaseResource} is INSIDE geofence`;
        break;
      case "outsidePoi":
        friendlyDescription = `${upperCaseResource} is OUTSIDE geofence`;
        break;
      case "enterPoi":
        friendlyDescription = `${upperCaseResource} ENTERS geofence`;
        break;
      case "leavePoi":
        friendlyDescription = `${upperCaseResource} LEAVES geofence`;
        break;
    }
    return friendlyDescription.split(" ");
  },

  withDefaults: ({ resource, operator, value }) => {
    const defaults = expressionDefaults[resource] || expressionDefaults.scalar;
    return {
      resource,
      operator: operator || defaults.operator,
      value: value || defaults.value
    };
  },

  validate(expression) {
    const { resource, operator, value } = expression.toJS();

    if (!operatorIsSupported(operator)) {
      return { isValid: true }; // do not run validation on unsupported operator
    }

    // "Simple type" - just make sure everything is defined
    if (resource !== "latlng") {
      if (operator && (operator === "eq" || operator === "ne")) {
        return { isValid: !!(resource && operator) };
      }
      return { isValid: !!(resource && operator && value && value.length) };
    }
    if (!List.isList(value)) {
      return { isValid: !!(resource && operator && value) };
    }

    const poly = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [value]
      }
    };

    // we do not allow lines in the polygon to intersect
    const hasIntersections = kinks(poly).features.length > 0;
    // the polygon needs to have at least 4 points to be meaningful since the last is a duplicate of the first
    const hasEnoughPoints = value.length > 3;

    const message = hasIntersections ? "Region boundaries must not cross" : "";

    const isValid =
      resource && operator && value && hasEnoughPoints && !hasIntersections;

    return {
      isValid,
      message
    };
  }
};
