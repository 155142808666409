import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import amber from "@material-ui/core/colors/amber";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  warningCell: {
    backgroundColor: amber[50],
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },
  warningCellText: {
    margin: "2px"
  },
  warningCellIcon: {
    marginTop: 0,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
});

export default withStyles(styles)(({ children, classes }) => (
  <div className={classes.warningCell}>
    <WarningIcon className={classes.warningCellIcon} nativeColor={amber[500]} />
    <div className={classes.warningCellText}>
      <Typography>{children}</Typography>
    </div>
  </div>
));
