import React from "react";
import PropTypes from "prop-types";
import { Form, Field, FieldSet } from "../";
import FormIconButton from "components/buttons/form_icon";

const LoginForm = ({ onSubmit, isLoading }) => (
  <Form onSubmit={onSubmit}>
    <FieldSet>
      <Field id="userName" label="Username" />
      <Field id="password" label="Password" type="password" />
    </FieldSet>

    <FormIconButton
      fullWidth
      type="submit"
      text="Login"
      disabled={isLoading}
      variant="contained"
      color="primary"
    />
  </Form>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default LoginForm;
