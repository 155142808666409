import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import gwonlineSvg from "assets/images/gwonline.svg";
import nodataSvg from "assets/images/nodata.svg";
import offlineSvg from "assets/images/offline.svg";
import onlineSvg from "assets/images/online.svg";
import unknownSvg from "assets/images/unknown.svg";
import { SimulatorHeader } from "components/simulator";
import BatteryLevel from "components/thing/battery_level";
import Rssi from "components/thing/rssi";
import { format as formatDate, formatDistanceToNow } from "date-fns";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { Link } from "react-router";
import { compose, withProps } from "react-recompose";
import { defaultThingDetailsUrl } from "utils/route_utils";
import ImageContainer from "./summary/image_container";
const { fromJS } = require("immutable");

const styles = theme => ({
  card: {
    width: "100%",
    background: "white",
    display: "flex",
    padding: theme.spacing(2)
  },
  grey: {
    color: "#aaa"
  },
  leftColumn: {
    borderRight: "2px solid #eee",
    width: "50%",
    paddingRight: `${theme.spacing(2)}`
  },
  metadataColumn: {
    width: "50%"
  },
  dataPair: {
    display: "flex",
    marginBottom: `${theme.spacing()}`
  },
  fieldKey: {
    width: "120px",
    paddingLeft: "16px"
  }
});

const imageStyles = {
  maxWidth: "350px",
  margin: "0 auto",
  padding: "10px",
  justifyContent: "center",
  position: "relative"
};

const MetaValue = ({ children, ...rest }) => (
  <div>
    <Typography
      {...rest}
      variant="body2"
      style={{ display: "flex", alignSelf: "center" }}
    >
      {children}
    </Typography>
  </div>
);
MetaValue.propTypes = { children: PropTypes.object };

const ConnectionStatus = ({ statusNumber }) => {
  const statusMapper = {
    0: { label: "Offline", iconSvg: offlineSvg },
    1: { label: "Gateway online", iconSvg: gwonlineSvg },
    2: { label: "Online", iconSvg: onlineSvg },
    3: { label: "Not yet connected", iconSvg: nodataSvg },
    4: { label: "Unknown", iconSvg: unknownSvg }
  };
  return (
    <React.Fragment>
      <img
        style={{ position: "relative", top: "2px", marginRight: "8px" }}
        src={R.pathOr(unknownSvg, [statusNumber, "iconSvg"], statusMapper)}
      />
      {R.pathOr("Unknown", [statusNumber, "label"], statusMapper)}
    </React.Fragment>
  );
};
ConnectionStatus.propTypes = { statusNumber: PropTypes.number };

export const ThingSummary = ({
  classes,
  thingShadow,
  thing,
  summaryData,
  account,
  thingTypeLabel
}) => {
  return (
    <div className={classes.card}>
      <div className={classes.leftColumn}>
        {!!thingShadow.thingName && (
          <ImageContainer
            thingShadow={fromJS(thingShadow)}
            containerStyles={imageStyles}
          />
        )}
        <Typography data-test="thing-description" variant="subtitle1">
          {R.prop("description", thing)}
        </Typography>
      </div>
      <div className={classes.metadataColumn}>
        {summaryData.map(({ key, value }) => {
          return (
            value && (
              <div className={classes.dataPair} key={"summaryData_" + key}>
                <MetaValue className={`${classes.grey} ${classes.fieldKey}`}>
                  {key}
                </MetaValue>
                <MetaValue>{value || "-"}</MetaValue>
              </div>
            )
          );
        })}
      </div>
      {R.equals("Simulator", thingTypeLabel) && (
        <SimulatorHeader
          account={account}
          thingName={R.prop("thingName", thing)}
        />
      )}
    </div>
  );
};

ThingSummary.propTypes = {
  classes: PropTypes.object,
  thingShadow: PropTypes.object,
  thingType: PropTypes.object,
  tcxnValues: PropTypes.object,
  thing: PropTypes.object,
  summaryData: PropTypes.object,
  account: PropTypes.string,
  thingTypeLabel: PropTypes.string
};

const addIfPresent = ({ propName, key, tcxnValues }) =>
  R.when(
    () => R.has(propName, tcxnValues),
    kvMap =>
      R.set(
        R.lensProp("summaryData"),
        R.append(
          { key, value: R.prop(propName, tcxnValues) },
          kvMap.summaryData
        )
      )(kvMap)
  );

const mapProps = ({ thingShadow, thing, tcxnValues, thingType }) => {
  const parentThing =
    !R.prop("hasNetworkedThings", thingShadow) &&
    R.pathOr(null, ["networkedThingsConnection", "parentThing"], thingShadow);

  let keyValueMap = {
    summaryData: [
      { key: "Id", value: R.prop("id", thingShadow) },
      { key: "Label", value: R.prop("label", thing) },
      {
        key: "Status",
        value: (
          <ConnectionStatus
            statusNumber={R.prop("connectionStatus", tcxnValues)}
          />
        )
      },
      {
        key: "Last update",
        value: R.ifElse(
          R.has("lastHeardFrom"),
          R.pipe(R.prop("lastHeardFrom"), date =>
            formatDistanceToNow(new Date(date * 1000), { addSuffix: true })
          ),
          () => "Never"
        )(thingShadow)
      },
      {
        key: "Thing Type",
        value: (
          <Link to={R.prop("url", thingType)}>
            {R.prop("label", thingType)}
          </Link>
        )
      },
      { key: "Domain", value: R.prop("domain", thing) },
      {
        key: "Parent Thing",
        value: parentThing && (
          <Link
            to={defaultThingDetailsUrl(null, {
              thingName: R.prop("thingName", parentThing),
              thingType: R.prop("thingType", parentThing)
            })}
          >
            {R.prop("label", parentThing)}
          </Link>
        )
      },
      {
        key: "Created",
        value: formatDate(
          new Date(R.prop("createdAt", thing)),
          "MMMM dd, yyyy h:mm a"
        )
      }
    ]
  };

  if (tcxnValues.rssi && tcxnValues.networkType) {
    keyValueMap.summaryData.push({
      key: "Signal strength",
      value: (
        <Rssi
          networkType={R.prop("networkType", tcxnValues)}
          signalStrength={R.prop("rssi", tcxnValues)}
        />
      )
    });
  }

  if (tcxnValues.battery_level) {
    keyValueMap.summaryData.push({
      key: "Battery level",
      value: <BatteryLevel battery={R.prop("battery_level", tcxnValues)} />
    });
  }

  return R.pipe(
    addIfPresent({
      propName: "firmwareVersion",
      key: "Firmware version",
      tcxnValues
    }),
    addIfPresent({
      propName: "iccid",
      key: "ICC ID Number",
      tcxnValues
    }),
    addIfPresent({
      propName: "imei",
      key: "IMEI Number",
      tcxnValues
    }),
    addIfPresent({
      propName: "imsi",
      key: "IMSI Number",
      tcxnValues
    }),
    addIfPresent({
      propName: "ipAddress",
      key: "IP Address",
      tcxnValues
    }),
    addIfPresent({
      propName: "network_type",
      key: "Network type",
      tcxnValues
    })
  )(keyValueMap);
};

export default compose(withStyles(styles), withProps(mapProps))(ThingSummary);
