import * as R from "ramda";
import { createSelector } from "reselect";
import { Map } from "immutable";
import { thingTypeFilterIdSelector } from "ducks/things_list/things_list_selectors";
import { domainSelectors } from "ducks/domain";
import { schemaSelectors } from "ducks/schema";
import { thingTypeSelectors } from "ducks/thing_types";
import {
  _resourceEntities,
  resourceLabelByIdFromEntities
} from "../schema/schema_selectors";
import { filterStateToInputElasticThingsFilter } from "graphql/utils/thing";

export const shouldInitFilter = createSelector(
  thingTypeSelectors.getAllThingTypes,
  domainSelectors.getDomainValues,
  schemaSelectors.getResourcesForAllThings,
  (thingTypes, domains, resources) =>
    R.any(R.propEq("size", 0), [thingTypes, resources]) ||
    R.any(R.propEq("length", 0), [domains])
);

const _extractDomains = state =>
  state.filter ? state.filter.getIn(["data", "domains"], Map({})) : Map({});
const _extractThingTypes = state =>
  state.filter ? state.filter.getIn(["data", "thingTypes"], Map({})) : Map({});
const _extractData = state =>
  state.filter ? state.filter.get("data", Map({})) : Map({});
const _extractFilterRootState = state => state.filter && Map({});
const _extractFilterById = (state, filterId) =>
  state.filter ? state.filter.getIn(["filters", filterId], Map({})) : Map({});

export const getThingTypes = createSelector(
  _extractThingTypes,
  thingTypes => thingTypes
);

export const getThingTypesItems = createSelector(getThingTypes, thingTypes =>
  thingTypes.get("items")
);

export const getDomains = createSelector(_extractDomains, domains => domains);

export const getDomainsItems = createSelector(getDomains, domains =>
  domains.get("items")
);

export const getData = createSelector(_extractData, data => data);
export const isLoading = createSelector(_extractFilterRootState, filter =>
  filter.get("isLoading", false)
);

export const error = createSelector(_extractFilterRootState, filter =>
  filter.get("error")
);

const filterWithResourceLabels = (filter, resourceEntities) => {
  const setLabel = item => {
    const id = item.get("name");
    return id
      ? item.set("label", resourceLabelByIdFromEntities(resourceEntities, id))
      : item;
  };

  return filter.updateIn(["resources"], items =>
    items ? items.map(item => setLabel(item)) : items
  );
};

export const getFiltersFilter = createSelector(
  _extractFilterById,
  _resourceEntities,
  (filter, resourceEntities) =>
    filterWithResourceLabels(filter.get("filter", Map({})), resourceEntities)
);

export const getFiltersView = createSelector(_extractFilterById, filter =>
  filter.get("view", Map({}))
);

export const filterRootSelector = R.prop("filter");

export const allThingsStaticFilterIdSelector = R.always("allThings");

export const allThingsFilterSelector = createSelector(
  filterRootSelector,
  allThingsStaticFilterIdSelector,
  (filter, filterId) => filter.getIn(["filters", filterId, "filter"], Map())
);

export const filterObjSelector = createSelector(
  filterRootSelector,
  thingTypeFilterIdSelector,
  (filter, filterId) => filter.getIn(["filters", filterId, "filter"], Map())
);

export const filterByFilterIdProp = createSelector(
  filterRootSelector,
  (state, { filterId }) => filterId,
  (filter, filterId) => filter.getIn(["filters", filterId, "filter"], Map())
);

export const inputElasticThingsFilterSelector = createSelector(
  filterByFilterIdProp,
  (state, props) => props.ignoreGeo,
  (state, props) => props.params,
  (stateFilter, ignoreGeo, routeParams) =>
    filterStateToInputElasticThingsFilter(stateFilter, routeParams, {
      ignoreGeo
    })
);
