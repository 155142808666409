import { css } from "styled-components";
import * as colors from "themes/colors";

export default css`
  input::-ms-clear {
    display: none;
  }

  img {
    max-width: 100%;
  }

  h3 {
    font-size: 1.5em;
  }

  html {
    font-smoothing: antialiased;
  }

  input {
    text-align: inherit;

    ::-webkit-autofill {
      box-shadow: 0 0 0 1000px ${colors.white} inset;
    }
  }

  textarea {
    overflow: auto;
  }

  html,
  body,
  .provider {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .mainContainer {
    overflow-y: auto;
    height: 100vh;
  }

  body {
    height: 100%;
    background-color: ${colors.backgroundColor};
  }

  header,
  footer {
    display: block;
    min-height: 64px;
    width: 100%;
  }

  footer {
    font-size: 2em;
  }

  .error {
    /*font-family: 'arial';*/
    color: ${colors.telenorErrorTextColor};
    /*height: 3em;*/
    padding: 10px;
  }

  .app,
  #app {
    margin: 0;
    height: 100%;
  }

  .clearfix {
    &::before,
    &::after {
      content: " ";
      display: table;
    }

    &::after {
      clear: both;
    }
  }

  .show-test-tags [data-test],
  .show-test-tags [class*="data-test"] {
    &::before {
      content: attr(data-test);
      position: absolute;
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #cdcdcd;
      font-size: 10px;
      z-index: 1000;
      font-family: arial, sans-serif;
      text-transform: none;
      color: ${colors.black};
      padding: 5px;
      line-height: 14px;
      font-weight: normal;
    }

    &:hover {
      &::before {
        background: ${colors.black};
        color: ${colors.white};
      }
    }
  }

  .show-test-tags [class*="data-test"] {
    &::before {
      content: attr(class);
    }
  }

  /* https://jira.skunk-works.no/browse/TCAB-3076 */
  /* https://jira.skunk-works.no/browse/TCAB-2668 */
  /* https://geektnt.com/how-to-remove-x-from-search-input-field-on-chrome-and-ie.html */
  input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`;
