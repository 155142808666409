import { invoke$ } from "./invoke";

export const get$ = id =>
  invoke$("ThingTypeLambda", {
    action: "GET",
    attributes: { id }
  });

export const create$ = thingType =>
  invoke$("ThingTypeLambda", {
    action: "CREATE",
    attributes: thingType
  });

export const update$ = thingType =>
  invoke$("ThingTypeLambda", {
    action: "UPDATE",
    attributes: thingType
  });

export const remove$ = id =>
  invoke$("ThingTypeLambda", {
    action: "REMOVE",
    attributes: { id }
  });
