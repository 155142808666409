import { Component } from "react";
import PropTypes from "prop-types";

export default class DefaultLayout extends Component {
  render() {
    return this.props.children;
  }
}
DefaultLayout.propTypes = {
  children: PropTypes.node
};
