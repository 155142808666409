import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { range, pipe, last, match } from "ramda";
import {
  compose,
  pure,
  withHandlers,
  lifecycle,
  setPropTypes,
  defaultProps
} from "react-recompose";
import {
  filterSelectors,
  initFilter,
  addRootFilter,
  addFreeTextSearch,
  removeFreeTextSearch,
  updateFreeTextSearch,
  updateGenericFilter,
  clearFilters,
  updateResourceFilter,
  removeResourceFilter,
  addResourceFilter,
  updateThingStatus,
  removeGeoFilter,
  updateGeoFilter,
  toggleGeoActive
} from "ducks/filter";
import { setNotificationMessage } from "ducks/system";
import * as thingTypesSelectors from "ducks/thing_types/thing_types_selectors";
import { domainSelectors } from "ducks/domain";
import FILTER_TYPES from "./filter_types";
import Filter from "./filter";
import { debounce } from "../../utils/execution_utils";

const onUpdateFreeTextSearchDebounced = debounce(e => {
  const { filterId, key, value, dispatch } = e;
  dispatch(updateFreeTextSearch(filterId, key, value));
}, 300);

const getThingTypeId = pipe(match(/^thingType-(.*)$/), last);

export default compose(
  connect(() => {
    return (state, { type, filterId }) => {
      const thingTypes =
        type === FILTER_TYPES.ALL_THINGS
          ? thingTypesSelectors.sortThingTypes(
              thingTypesSelectors.getAllThingTypes(state)
            )
          : undefined;
      const domains = domainSelectors.getDomainValues(state);
      const isLoadingDomains = domainSelectors.isLoading(state);
      return {
        filter: filterSelectors.getFiltersFilter(state, filterId),
        filterView: filterSelectors.getFiltersView(state, filterId),
        domains,
        isLoading: filterSelectors.isLoading(state),
        isLoadingThingTypes: thingTypesSelectors.isLoading(state),
        isLoadingDomains,
        error: filterSelectors.error(state),
        data: filterSelectors.getData(state),
        thingTypes
      };
    };
  }),
  setPropTypes({
    isLoading: PropTypes.bool,
    isLoadingThingTypes: PropTypes.bool,
    isLoadingDomains: PropTypes.bool,
    error: PropTypes.object,
    filter: PropTypes.object,
    filterView: PropTypes.object,
    data: PropTypes.object,
    resourceData: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    type: PropTypes.oneOf([FILTER_TYPES.ALL_THINGS, FILTER_TYPES.THINGS])
      .isRequired,
    dispatch: PropTypes.func.isRequired,
    handleChange: PropTypes.func
  }),
  defaultProps({
    type: FILTER_TYPES.THINGS,
    handleChange: () => {}
  }),
  withHandlers({
    onChangeThingStatus: ({ dispatch, filterId }) => evt =>
      dispatch(updateThingStatus(filterId, evt.target.value)),
    onAddFreeTextSearch: ({ dispatch, filterId }) => () =>
      dispatch(addFreeTextSearch(filterId)),
    onRemoveFreeTextSearch: ({ dispatch, filterId }) => key => () =>
      dispatch(removeFreeTextSearch(filterId, key)),
    onUpdateFreeTextSearch: ({ dispatch, filterId }) => key => ({
      target: { value }
    }) => onUpdateFreeTextSearchDebounced({ dispatch, filterId, key, value }),
    onThingTypesFilterChange: ({ dispatch, filterId }) => selectedThingTypes =>
      dispatch(
        updateGenericFilter(
          filterId,
          "thingTypes",
          range(0, selectedThingTypes.length),
          selectedThingTypes
        )
      ),
    onDomainsFilterChange: ({ dispatch, filterId }) => selectedDomains =>
      dispatch(
        updateGenericFilter(
          filterId,
          "domains",
          range(0, selectedDomains.length),
          selectedDomains
        )
      ),
    onResourceFilterChange: ({ dispatch, filterId }) => ({ index, item }) =>
      dispatch(updateResourceFilter(filterId, index, item)),
    onRemoveResourceFilter: ({ dispatch, filterId }) => index =>
      dispatch(removeResourceFilter(filterId, index)),
    onAddResourceFilter: ({ dispatch, filterId }) => () =>
      dispatch(addResourceFilter(filterId)),
    onClearFilter: ({ dispatch, filterId }) => evt => {
      dispatch(clearFilters(filterId, getThingTypeId(filterId)));
      evt.stopPropagation();
    },
    onRemoveGeoFilter: ({ dispatch, filterId }) => () =>
      dispatch(removeGeoFilter(filterId)),
    onUpdateGeoFilter: ({ dispatch, filterId }) => geoJson => {
      if (geoJson.size <= 2) {
        dispatch(
          setNotificationMessage(
            "Invalid GEO-filter, polygon needs at least three points and can't be same coordinate."
          )
        );
      }
      dispatch(updateGeoFilter(filterId, geoJson));
    },
    onToggleGeoActive: ({ dispatch, filterId }) => () =>
      dispatch(toggleGeoActive(filterId))
  }),
  lifecycle({
    componentDidMount() {
      const { dispatch, filterId } = this.props;
      const thingType = getThingTypeId(filterId);
      dispatch(initFilter({ filterId, thingType }));
      dispatch(addRootFilter(filterId, thingType));
    }
  }),
  pure
)(props => <Filter {...props} />);
