import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MuiSelect from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import CodeField from "components/code_field/code_field";
import ColorPickerResource from "components/color_picker/color_picker_resource";
import { ColorThreshold } from "components/color_thresholds/color_thresholds";
import { ColorThresholdWithOperator } from "components/color_thresholds/color_thresholds_with_operator";
import RadioGroup from "components/radio_button_group/radio_group";
import MultiSuggester from "components/suggester";
import { SuggesterContainerSB as MultiSuggesterSB } from "components/suggester/suggester_container";
import { authSelectors } from "ducks/auth";
import Joi from "joi-browser";
import PropTypes from "prop-types";
import * as R from "ramda";
import { equals, propOr } from "ramda";
import React from "react";
import { connect } from "react-redux";
import reformed from "react-reformed";
import {
  compose,
  lifecycle,
  onlyUpdateForPropTypes,
  setPropTypes,
  shallowEqual,
  withHandlers,
  withState
} from "react-recompose";
import styled from "styled-components";
import Autocomplete from "./autocomplete/Autocomplete";
import { getStyles } from "./index.style";

export const getValidationErrors = (schema, model, opts) => {
  const { error } = Joi.validate(model, schema, opts);
  return (
    (error &&
      error.details.reduce(
        (errors, { path, message }) => ({ ...errors, [path]: message }),
        {}
      )) ||
    {}
  );
};

const handleForceModelChanged = (
  currentProps,
  { forceModel, setModel, setForceModel }
) => {
  if (forceModel && !shallowEqual(forceModel, currentProps.forceModel)) {
    setModel(forceModel);
    setForceModel && setForceModel(undefined);
  }
};

const handleModelShapeChanged = (
  currentProps,
  { forceModel, initialModel, setModel }
) => {
  if (
    !forceModel && // if forceModel is truthy, this will be verified by the other check-function
    !R.equals(R.keys(initialModel), R.keys(currentProps.initialModel)) // check if shape of initialModel has changed
  ) {
    setModel(initialModel);
  }
};

export const Form = compose(
  reformed(),
  connect(state => ({
    user: authSelectors.userSelector(state),
    errors: state.errors
  })),
  lifecycle({
    /*
      Allow for override of initialModel when new props reach a form
      This has the down-side of overwriting your model so use with care!
    */
    componentDidUpdate(prevProps) {
      handleForceModelChanged(prevProps, this.props);
      handleModelShapeChanged(prevProps, this.props);
    }
  }),
  onlyUpdateForPropTypes,
  setPropTypes({
    children: PropTypes.array.isRequired,
    schema: PropTypes.object,
    model: PropTypes.object.isRequired,
    setProperty: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    allowUnknown: PropTypes.bool,
    user: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool
  }),
  withHandlers({
    resetForm: ({ setModel, initialModel }) => () => {
      setModel(initialModel);
    }
  }),
  withState("isSubmitting", "setSubmitting", false)
)(props => {
  const {
    children,
    schema = Joi.object(),
    dispatch,
    onChange,
    errors = {},
    model,
    isAuthorized = true,
    setProperty,
    onSubmit,
    allowUnknown,
    style,
    isSubmitting,
    setSubmitting,
    resetForm,
    initialModel
  } = props;

  const onFormChange = ({ model, changedField }) => {
    if (onChange) onChange({ model, changedField });
  };

  const submitHandler = e => {
    e.preventDefault();
    setSubmitting(true);
    dispatch({ type: "CLEAR_ERROR" });
    const handler = onSubmit(model);
    if (handler && handler.then) {
      handler.then(() => setSubmitting(false));
    } else {
      setSubmitting(false);
    }
  };

  const formStyles = getStyles();
  const validationErrors = getValidationErrors(schema, model, {
    allowUnknown,
    convert: false,
    abortEarly: false
  });
  const isValid = !isSubmitting && !Object.keys(validationErrors).length;

  const getErrorMessage = id => {
    const msg =
      validationErrors[id] ||
      errors[id] ||
      ((errors.general || "").includes(id) ? errors.general : "");
    return msg;
  };

  const _fieldValue = (fieldChangePath, e) =>
    fieldChangePath ? R.path(fieldChangePath)(e.value) : e.value;
  function onFieldChange(e, fieldChangePath) {
    const id = e.id;
    if (errors[id]) dispatch({ type: "CLEAR_ERROR" });
    const value = _fieldValue(fieldChangePath, e);
    const model = setProperty(e.id, value);
    onFormChange({ model, changedField: e });
  }

  function cloneRecursively(children) {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }
      let childProps = {};
      const { id, type } = child.props;
      let { disabled } = child.props;
      if (id) {
        disabled = disabled || Boolean(!isAuthorized);
        childProps = {
          ...child.props,
          setProperty,
          defaultValue: propOr(child.props.defaultValue, id, model),
          errorText: getErrorMessage(id),
          resetForm: resetForm,
          onChange: e => {
            onFieldChange(e, child.props.fieldChangeValuePath);
            if (child.props.onChange) child.props.onChange(e);
          },
          disabled,
          model
        };
      }
      if (type === "submit") {
        disabled = !isValid || disabled || Boolean(!isAuthorized);
        childProps = {
          ...child.props,
          disabled
        };
      }

      if (type === "reset") {
        childProps = {
          ...child.props,
          disabled: disabled || equals(model, initialModel),
          onClick: resetForm
        };
      }

      childProps.children = cloneRecursively(child.props.children);

      return React.cloneElement(child, childProps);
    });
  }

  return (
    <form style={{ ...formStyles.form, ...style }} onSubmit={submitHandler}>
      {cloneRecursively(children)}
    </form>
  );
});

export const FieldSet = ({ children, style }) => {
  return (
    <fieldset style={{ ...getStyles().fieldSet, ...style }}>
      {children}
    </fieldset>
  );
};

FieldSet.propTypes = {
  children: PropTypes.array,
  style: PropTypes.object
};

FieldSet.defaultProps = {
  style: {}
};

export const ModalFormButtons = styled.div`
  display: flex;
  justify-content: right;
  padding: 8px;
`;

export const FormButtons = styled.div``;

const FullWidthFormControl = withStyles({
  root: {
    width: "100%"
  }
})(FormControl);

export const Select = ({
  options,
  name,
  remap,
  label,
  id,
  labelProps = {},
  placeholder = null,
  ...props
}) => {
  const labelId = `${id}-label`;
  return (
    <FullWidthFormControl>
      <InputLabel id={labelId} {...labelProps}>
        {label}
      </InputLabel>
      <MuiSelect
        {...props}
        labelId={labelId}
        {...(placeholder && { displayEmpty: true })}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <InputLabel>{placeholder}</InputLabel>
          </MenuItem>
        )}
        {options.map(({ id, label, type, ...o }) => {
          if (remap) {
            label = remap.label ? o[remap.label] : o.label;
            name = remap.name ? o[remap.name] : o.name;
            id = remap.id ? o[remap.id] : o.id;
          }
          if (type === "divider") {
            return <Divider key={id} />;
          } else {
            return (
              <MenuItem data-test={`${name}-${id}`} key={id} value={id} {...o}>
                {label}
              </MenuItem>
            );
          }
        })}
      </MuiSelect>
    </FullWidthFormControl>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
  remap: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  labelProps: PropTypes.node,
  placeholder: PropTypes.string
};

export const getValue = (value, type, isNumberString) => {
  if (value === undefined) return value;
  if (type === "number") return Number(value || 0);

  // Regex explanation:
  // ^-? // start with zero or one dash
  // \d* // followed by zero or many digits
  // \.? // followed by zero or one period
  // \d* // followed by zero or many digit
  if (isNumberString && value) return value.match(/^-?\d*\.?\d*/).pop() || "";
  return value;
};

const _errorText = (isDirty, errorText) => (isDirty ? errorText : undefined);

export const SwitchFormControlLabel = withStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  labelPlacementStart: {
    marginLeft: 0
  }
})(FormControlLabel);

export const Field = withState(
  "isDirty",
  "setDirty",
  false
)(
  ({
    id,
    dataTest,
    "data-test": dataTest2,
    label,
    hint,
    type,
    suggesterType,
    suggesterOptionsDecorator,
    suggesterTheme,
    path = ["id"],
    localState,
    idPath = ["id"],
    floatingLabelText,
    hintText,
    floatingLabelFixed,
    shouldAlwaysRenderSuggestions,
    onSuggestionsFetchRequested,
    suggestions,
    options,
    defaultValue,
    errorText,
    disabled,
    readOnly = false,
    isNumberString = false,
    autoFocus = false,
    onChange = () => {},
    isDirty,
    setDirty,
    remap,
    style,
    chips = [],
    single = true,
    textChip = true,
    thingType,
    ...rest
  }) => {
    const defaults = {
      // eslint-disable-line quote-props
      disabled,
      name: id,
      id,
      ["data-test"]: id ? id : dataTest2,
      fullWidth: true,
      hintText: hint || label,
      label,
      errorText: isDirty && errorText,
      onBlur: () => !isDirty && setDirty(true),
      readOnly
    };
    const changeHandler = (id, value, event) => {
      /*
       Validate in real-time when user enter data
       Disabled due to: TCAB-1349 and TCAB-1305
       if (!isDirty) {
       setDirty(true);
       }
       */
      onChange({ id, value, event });
    };
    switch (type) {
      case "autocomplete":
        return (
          <Autocomplete
            id={id}
            data-test={dataTest2}
            defaultOptions={options}
            changeHandler={changeHandler}
            label={label}
            defaultValue={defaultValue}
            errorText={errorText}
            type={suggesterType}
            hintText={hintText}
            thingType={thingType}
            filterOptions={suggesterOptionsDecorator}
          />
        );
      case "suggester":
        return (
          <MultiSuggester
            dataTest={dataTest}
            id={id}
            idPath={idPath}
            errorText={_errorText(isDirty, errorText)}
            label={label}
            type={suggesterType}
            defaultValue={defaultValue ? defaultValue : ""}
            suggesterOptionsDecorator={suggesterOptionsDecorator}
            onSuggestionSelected={(event, { suggestion }) => {
              return changeHandler(
                id,
                R.isEmpty(path) ? suggestion : R.path(path)(suggestion),
                event
              );
            }}
            shouldAlwaysRenderSuggestions={shouldAlwaysRenderSuggestions}
            single={single}
            textChip={textChip}
            thingType={thingType}
            themeName={suggesterTheme}
            hintText={hintText}
            floatingLabelText={floatingLabelText}
            floatingLabelFixed={floatingLabelFixed}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            suggestions={suggestions}
            localState={localState}
            onBlur={() => !isDirty && setDirty(true)}
          />
        );
      case "suggester-sb":
        return (
          <MultiSuggesterSB
            dataTest={dataTest}
            id={id}
            idPath={idPath}
            errorText={_errorText(isDirty, errorText)}
            label={label}
            defaultValue={defaultValue ? defaultValue : ""}
            suggesterOptionsDecorator={suggesterOptionsDecorator}
            onSuggestionSelected={(event, { suggestion }) => {
              return changeHandler(
                id,
                R.isEmpty(path)
                  ? suggestion
                  : single
                  ? R.path(path)(suggestion)
                  : R.map(R.prop(path), suggestion),
                event
              );
            }}
            shouldAlwaysRenderSuggestions={shouldAlwaysRenderSuggestions}
            chips={chips}
            single={single}
            textChip={textChip}
            thingType={thingType}
            themeName={suggesterTheme}
            hintText={hintText}
            floatingLabelText={floatingLabelText}
            floatingLabelFixed={floatingLabelFixed}
            suggestions={suggestions}
            onBlur={() => !isDirty && setDirty(true)}
          />
        );
      case "select":
        return (
          <Select
            {...defaults}
            value={defaultValue}
            errorText={_errorText(isDirty, errorText)}
            options={options}
            remap={remap}
            onChange={e => changeHandler(id, e.target.value)}
            style={style}
            {...rest}
          />
        );
      case "code":
        return (
          <CodeField
            {...defaults}
            id={id}
            onChange={value => changeHandler(id, value)}
            value={defaultValue}
            errorText={errorText}
          />
        );
      case "toggle":
        return (
          <FormGroup row>
            <SwitchFormControlLabel
              value={defaultValue}
              control={
                <Switch
                  checked={defaultValue}
                  label={label}
                  disabled={disabled}
                  onChange={e => changeHandler(id, e.target.checked)}
                />
              }
              label={label}
              labelPlacement="start"
            />
          </FormGroup>
        );
      case "radio_group":
        return (
          <RadioGroup
            options={options}
            value={defaultValue}
            name={id}
            onChange={value => {
              return changeHandler(id, value);
            }}
          />
        );
      case "color_thresholds":
        return (
          <ColorThreshold
            value={defaultValue}
            onChange={value => changeHandler(id, value)}
          />
        );
      case "color_thresholds_with_operator":
        return (
          <ColorThresholdWithOperator
            value={defaultValue}
            operators={options}
            onChange={value => changeHandler(id, value)}
          />
        );
      case "color_picker_with_resource":
        return (
          <ColorPickerResource
            value={defaultValue}
            onChange={value => changeHandler(id, value)}
            thingType={thingType}
          />
        );
      case "text":
      default:
        return (
          <TextField
            label={label}
            placeholder={hint || label}
            disabled={disabled}
            data-test={id}
            name={id}
            helperText={isDirty ? errorText : null}
            error={isDirty && !!errorText}
            value={defaultValue}
            readOnly={readOnly}
            autoFocus={autoFocus}
            fullWidth
            margin="normal"
            type={type}
            InputLabelProps={{ shrink: true }}
            onBlur={e => {
              defaults.onBlur();
              if (isNumberString && e.target.value && !isNaN(e.target.value)) {
                changeHandler(id, Number(e.target.value));
              }
            }}
            onChange={e => {
              changeHandler(id, getValue(e.target.value, type, isNumberString));
            }}
            inputProps={{ "data-test": id }}
          />
        );
    }
  }
);
