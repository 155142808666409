import styled from "styled-components";

export default styled.div`
  font-size: 20px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${props => props.muiTheme.typography.fontFamily};
  color: ${props => props.muiTheme.palette.text.primary};

  &:first-child {
    padding-left: 12px;
  }
`;
