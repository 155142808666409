import { configureUnauthenticated } from "api/utils/cognito_credentials";
import { apiError } from "ducks/errors";
import { setTheme } from "ducks/theme";
import { always, pathOr } from "ramda";
import { push, replace } from "react-router-redux";
import { Observable } from "rxjs";
import { deleteExpiredSession } from "utils/user_session";
import {
  AUTH_GIVE_CONSENT_REQUEST,
  giveConsentFailure,
  initiateOauthFailure,
  initiateOauthSuccess,
  loginFailure,
  loginSuccess,
  loginUser,
  LOGIN_REQUEST,
  OAUTH2_REFRESH_TOKEN_REQUEST,
  OAUTH2_REQUEST,
  refreshTokenOauth,
  refreshTokenOauthFailure,
  refreshTokenOauthSuccess,
  registerFailure,
  registerSuccess,
  REGISTER_REQUEST,
  setPasswordFailure,
  setPasswordSuccess,
  setUserName,
  SET_PASSWORD_REQUEST
} from "./auth";
import {
  adaptRegisterPayload,
  getOauthEndpoint,
  _errorCases,
  _extractErrorKey,
  _smsVerificationIsEnabled
} from "./auth_epics_utils";

export const ensureTheme$ = (loginResponse, api) => {
  const domain = pathOr("root", ["user", "domainName"], loginResponse);
  return domain === "root"
    ? Observable.empty()
    : api.domains
        .theme$(domain)
        .map(theme => setTheme(theme))
        .catch(error => Observable.of(apiError(error)));
};

export const registerUserEpic = (action$, store, { restApi }) =>
  action$.ofType(REGISTER_REQUEST).mergeMap(({ payload }) =>
    restApi.auth
      .registerUser$(adaptRegisterPayload(payload))
      .mergeMap(response =>
        Observable.if(
          always(_smsVerificationIsEnabled(response)),
          Observable.of(setUserName(payload.userName), push("/verifySms")),
          Observable.of(registerSuccess(response))
        )
      )
      .catch(error => Observable.of(registerFailure(error), apiError(error)))
  );

export const loginUserEpic = (action$, store, { api, restApi }) =>
  action$
    .ofType(LOGIN_REQUEST)
    .mergeMap(({ payload: { userName, password, redirect = "/" } }) =>
      restApi.auth
        .loginUser$({ userName, password })
        .mergeMap(api.setCredentials$)
        .mergeMap(response => {
          deleteExpiredSession();
          return ensureTheme$(response, api).concat(
            Observable.of(loginSuccess(response), replace(redirect))
          );
        })
        .catch(
          error =>
            _errorCases({ userName })[_extractErrorKey(error)] ||
            Observable.of(loginFailure(error))
        )
    );

export const setPasswordEpic = (action$, store, { api, restApi }) =>
  action$.ofType(SET_PASSWORD_REQUEST).mergeMap(({ payload }) =>
    restApi.auth
      .setPassword$(payload)
      .mergeMap(api.setCredentials$)
      .mergeMap(response => {
        return ensureTheme$(response, api).concat(
          Observable.of(setPasswordSuccess(response))
        );
      })
      .catch(
        error =>
          _errorCases({})[_extractErrorKey(error)] ||
          Observable.of(setPasswordFailure(error))
      )
  );

export const giveConsentEpic = (action$, store, { restApi }) =>
  action$
    .ofType(AUTH_GIVE_CONSENT_REQUEST)
    .mergeMap(({ payload: { user, redirect } }) =>
      restApi.auth
        .giveConsent$(user)
        .map(() => loginUser({ ...user, redirect }))
        .catch(error =>
          Observable.of(giveConsentFailure(error), apiError(error))
        )
    );

export const oauthEpic = (action$, store, { api }) =>
  action$
    .ofType(OAUTH2_REQUEST)
    .mergeMap(({ payload: { code, location, manifest } }) => {
      return api.auth
        .initiateOauth$({
          code,
          clientId: manifest.UserPoolClient,
          endpoint: getOauthEndpoint(location, manifest)
        })
        .mergeMap(json => {
          return Observable.of(initiateOauthSuccess(), refreshTokenOauth(json));
        })
        .catch(e => {
          return Observable.of(initiateOauthFailure(), apiError(e));
        });
    });

export const refreshTokenEpic = (action$, store, { api }) =>
  action$
    .ofType(OAUTH2_REFRESH_TOKEN_REQUEST)
    .mergeMap(({ payload: { refresh_token, redirect = "/" } }) => {
      configureUnauthenticated();
      return api.auth
        .refreshToken$(refresh_token)
        .mergeMap(api.setCredentials$)
        .mergeMap(response => {
          return ensureTheme$(response, api).concat(
            Observable.of(
              refreshTokenOauthSuccess(),
              loginSuccess(response),
              push(redirect)
            )
          );
        })
        .catch(e => {
          return Observable.of(refreshTokenOauthFailure(), apiError(e));
        });
    });
