import Joi from "joi-browser";
import {
  any,
  ascend,
  assoc,
  compose,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  reduce,
  sortWith,
  values
} from "ramda";
import { createSelector } from "reselect";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";
import { lower } from "utils/general_utils";
import { authSelectors } from "../auth";
import { sortGroups, _getGroupEntities } from "./groups_selectors";

const _getCreateJob = path(["deviceManagement", "jobs", "createJob"]);
const _getJobsRequest = path(["deviceManagement", "jobs", "request"]);

export const sortJobs = sortWith([
  ascend(compose(lower, prop("description"))),
  ascend(prop("jobId"))
]);

export const isAddingGroups = createSelector(
  _getJobsRequest,
  prop("isAddingGroups")
);

export const selectedGroups = path([
  "deviceManagement",
  "jobs",
  "selectedGroups"
]);

export const selectedJobDocumentFile = createSelector(
  _getCreateJob,
  prop("jobDocumentFile")
);

export const availableJobTypes = createSelector(
  _getCreateJob,
  prop("availableJobTypes")
);

export const selectedJobType = createSelector(_getCreateJob, prop("jobType"));

export const jobDescription = createSelector(
  _getCreateJob,
  prop("description")
);

export const jobTargets = createSelector(_getCreateJob, prop("targets"));

export const jobDomain = createSelector(_getCreateJob, prop("domain"));

export const isJobsLoading = createSelector(_getJobsRequest, prop("isLoading"));

const showCreateJobValidation = createSelector(
  _getCreateJob,
  prop("showValidation")
);

const createJobSchema = Joi.object().keys({
  jobDescription: Joi.string()
    .required()
    .trim()
    .label("Description"),
  jobDomain: Joi.string()
    .required()
    .label("Domain"),
  jobTargets: Joi.array()
    .required()
    .min(1)
    .label("Job targets"),
  selectedJobDocumentFile: Joi.any()
    .required()
    .label("Job document")
    .options({
      language: {
        any: { required: "!!You must choose a job document" }
      }
    }),
  selectedJobType: Joi.string()
    .required()
    .valid("SNAPSHOT", "CONTINUOUS")
    .label("Job type")
    .options({
      language: {
        any: { allowOnly: "!!You must select a job type" }
      }
    })
});

const getErrors = pipe(
  pathOr([], ["error", "details"]),
  reduce((errors, error) => assoc(error.path, error.message, errors), {})
);

export const validateCreateJobForm = form =>
  getErrors(Joi.validate(form, createJobSchema, { abortEarly: false }));

export const createJobForm = createSelector(
  jobDescription,
  jobDomain,
  jobTargets,
  selectedJobDocumentFile,
  selectedJobType,
  (
    jobDescription,
    jobDomain,
    jobTargets,
    selectedJobDocumentFile,
    selectedJobType
  ) => ({
    jobDescription,
    jobDomain,
    jobTargets,
    selectedJobDocumentFile,
    selectedJobType
  })
);

export const createJobValidation = createSelector(
  showCreateJobValidation,
  createJobForm,
  (showValidation, form) => (showValidation ? validateCreateJobForm(form) : {})
);

export const jobDetailsJob = path([
  "deviceManagement",
  "jobs",
  "jobDetails",
  "job"
]);

export const executionSummaries = path([
  "deviceManagement",
  "jobs",
  "jobDetails",
  "executionSummaries"
]);

export const selectedExecutionStatus = path([
  "deviceManagement",
  "jobs",
  "jobDetails",
  "selectedStatus"
]);

export const isJobLoading = createSelector(
  _getJobsRequest,
  prop("isJobLoading")
);

export const isExecutionsLoading = createSelector(
  _getJobsRequest,
  prop("isExecutionsLoading")
);

export const selectableGroups = createSelector(
  _getGroupEntities,
  selectedGroups,
  (groups, selectedGroups) =>
    pipe(
      values,
      map(group =>
        assoc("selected", any(propEq("id", group.id), selectedGroups), group)
      ),
      sortGroups
    )(groups)
);

export const isJobDownloading = createSelector(
  _getJobsRequest,
  prop("isJobDownloading")
);

export const userCanReadThingJobs = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingJobs, OPERATIONS.READ)
);
