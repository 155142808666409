import React from "react";
import PropTypes from "prop-types";
import Color from "./styled/color";
import Root from "./styled/root";
import Text from "./styled/text";

const Swatch = ({ color, label, isActive, onClick, style }) => (
  <Root isActive={isActive} onClick={onClick} style={style}>
    <Color color={color} />
    {label && <Text isActive={isActive}>{label}</Text>}
  </Root>
);

Swatch.propTypes = {
  color: PropTypes.string,
  label: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object
};

export default Swatch;
