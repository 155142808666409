import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { VictoryTheme } from "victory";
import Cursor from "./Cursor";
import { getActivePoints } from "./utils";
import { throttle } from "../../../utils/execution_utils";

const MouseContainer = ({
  data,
  scale,
  setActivePoint,
  labels,
  colors,
  removeYAxis,
  ...props
}) => {
  const areaRef = useRef(null);
  const [points, setPoints] = useState([]);
  const [mousePosition, setMousePosition] = useState(undefined);
  const activePoints = mousePosition && getActivePoints(points, mousePosition);
  const activePoint =
    activePoints && activePoints.length > 0 ? activePoints[0] : undefined;

  useEffect(() => {
    const getPoints = () => {
      // Flatten the array and convert the data to points.
      return data.reduce((accumulator, currentValue, index) => {
        const label = labels && labels[index] ? labels[index] : "Value";
        const color = colors && colors[index] ? colors[index] : "black";
        return [
          ...accumulator,
          ...currentValue.map(({ x, y }) => ({
            x: scale.x(x),
            y: removeYAxis ? 0 : scale.y(y),
            xLabel: `Time: ${formatDate(x, "yyyy-MM-dd HH:mm")}`,
            yLabel: `${label}: ${y}`,
            color
          }))
        ];
      }, []);
    };

    setPoints(getPoints());
  }, [props.width, props.height]);

  useEffect(() => {
    const currentRef = areaRef.current;

    const onMouseMove = throttle(event => {
      const { left, right, top, bottom } = currentRef.getBoundingClientRect();
      const width = currentRef.attributes.getNamedItem("width").value;
      const height = currentRef.attributes.getNamedItem("height").value;
      const x = ((event.x - left) / (right - left)) * width;
      const y = ((event.y - top) / (bottom - top)) * height;

      setMousePosition({ x, y });
    }, 200);
    // onMouseMove is using throttle which means that setMousePosition
    // can trigger after onMouseLeave; Added timeout to prevent this.
    const onMouseLeave = () => {
      setTimeout(() => setMousePosition(undefined), 200);
    };

    if (currentRef) {
      currentRef.addEventListener("mousemove", onMouseMove);
      currentRef.addEventListener("mouseleave", onMouseLeave);
    }

    return () => {
      currentRef.removeEventListener("mousemove", onMouseMove);
      currentRef.removeEventListener("mouseleave", onMouseLeave);
    };
  }, []);

  useEffect(() => {
    if (activePoint?.x && (removeYAxis || activePoint?.y)) {
      setActivePoint({ x: activePoint.x, y: activePoint.y });
    } else {
      setActivePoint(undefined);
    }
  }, [activePoint?.x, activePoint?.y]);

  return (
    <>
      {activePoint && (
        <Cursor
          x={activePoint.x}
          y={removeYAxis ? mousePosition.y : activePoint.y}
          cursorDimension={removeYAxis ? "x" : undefined}
          domain={{
            x: [
              VictoryTheme.material.scatter.padding,
              props.width - VictoryTheme.material.scatter.padding
            ],
            y: [
              VictoryTheme.material.scatter.padding,
              props.height - VictoryTheme.material.scatter.padding
            ]
          }}
          width={props.width}
          height={props.height}
          points={activePoints}
        />
      )}
      <rect
        ref={areaRef}
        width={props.width}
        height={props.height}
        fill="none"
      />
    </>
  );
};
MouseContainer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  domain: PropTypes.object,
  data: PropTypes.array,
  scale: PropTypes.object,
  setActivePoint: PropTypes.func,
  labels: PropTypes.array,
  colors: PropTypes.array,
  removeYAxis: PropTypes.bool
};

export default MouseContainer;
