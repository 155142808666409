import React from "react";
import PropTypes from "prop-types";
import DynamicWidgetEditor from "./dynamic_widget_editor";
import DynamicWidgetContainer from "./dynamic_widget_container";
import WidgetEditDecimals from "../../edit/widget_edit_decimals";
import { ComposeForm } from "../../edit/compose_form";

const WidgetEditDynamic = props => {
  const {
    handleUpdateCustomProps: handleTemplateChange,
    params,
    editingWidget: widget
  } = props;
  return (
    <DynamicWidgetContainer params={params} widget={widget}>
      <DynamicWidgetEditor handleTemplateChange={handleTemplateChange} />
    </DynamicWidgetContainer>
  );
};

WidgetEditDynamic.propTypes = {
  template: PropTypes.string,
  handleUpdateCustomProps: PropTypes.func,
  thingName: PropTypes.string,
  params: PropTypes.object,
  editingWidget: PropTypes.object
};

export default ComposeForm(WidgetEditDecimals, () => ({
  fields: [WidgetEditDynamic]
}));
