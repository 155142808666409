import { createSelector } from "reselect";
import {
  __,
  allPass,
  ascend,
  assoc,
  compose,
  contains,
  defaultTo,
  length,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  sort,
  sortBy,
  sortWith,
  values
} from "ramda";
import { getPagedThings } from "../things_list/things_list_selectors";
import { extractThingTypes } from "../thing_types/thing_types_selectors";
import { defaultComparator } from "utils/sorters";
import { lower } from "utils/general_utils";
import { authSelectors } from "../auth";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";

const _getGroupRequest = path(["deviceManagement", "groups", "request"]);

export const _getGroupEntities = path([
  "deviceManagement",
  "groups",
  "entities"
]);

export const sortGroups = sortWith([
  ascend(compose(lower, prop("description"))),
  ascend(prop("id"))
]);

const _getThingGroupId = (state, props) => propOr({}, "thingGroupId", props);

export const allGroups = createSelector(
  _getGroupEntities,
  pipe(values, sortGroups)
);

export const isGroupsLoading = createSelector(
  _getGroupRequest,
  prop("isLoading")
);

export const createGroupRequestStatus = createSelector(
  _getGroupRequest,
  prop("createStatus")
);

export const requestError = createSelector(_getGroupRequest, prop("error"));

export const isListThingsLoading = createSelector(
  _getGroupRequest,
  prop("isListThingsLoading")
);

export const isDescribeGroupLoading = createSelector(
  _getGroupRequest,
  prop("isDescribeGroupLoading")
);

export const selectedThingNames = createSelector(
  path(["deviceManagement", "groups", "selectedThingNames"]),
  sort(defaultComparator)
);

export const isAddingThings = createSelector(
  _getGroupRequest,
  prop("isAddingThings")
);

export const isSelectingAllThings = createSelector(
  _getGroupRequest,
  prop("isSelectingAllThings")
);

export const currentThingGroup = createSelector(
  _getGroupEntities,
  _getThingGroupId,
  (groups, thingGroupId) => propOr({}, thingGroupId, groups)
);

export const thingGroupThingGroupID = createSelector(
  currentThingGroup,
  prop("id")
);

export const thingGroupNumberOfDevices = createSelector(
  currentThingGroup,
  compose(length, defaultTo([]), prop("thingNames"))
);

export const thingGroupDescription = createSelector(
  currentThingGroup,
  prop("description")
);

export const thingGroupDomain = createSelector(
  currentThingGroup,
  prop("domain")
);

export const thingGroupCreatedAt = createSelector(
  currentThingGroup,
  prop("createdAt")
);

export const thingsByGroup = createSelector(
  _getGroupEntities,
  _getThingGroupId,
  (groups, thingGroupId) =>
    pipe(
      pathOr([], [thingGroupId, "thingNames"]),
      // Construct final shape
      map(assoc("label", __, {})),
      sortBy(prop("label"))
    )(groups)
);

export const selectableThings = createSelector(
  getPagedThings,
  selectedThingNames,
  extractThingTypes,
  (things, selectedNames, thingTypes) =>
    things.map(({ label, thingType, thingName }) => ({
      label,
      thingType: thingTypes.getIn([thingType, "label"]) || thingType,
      thingName,
      selected: contains(thingName, selectedNames)
    }))
);

export const userCanCreateGroup = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingGroups, OPERATIONS.CREATE)
);

export const userCanDeleteGroup = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingGroups, OPERATIONS.DELETE)
);

export const userCanCreateJob = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingJobs, OPERATIONS.CREATE)
);

export const userCanCancelJob = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingJobs, OPERATIONS.UPDATE)
);

export const userCanAddThingsToGroup = createSelector(
  authSelectors.permissionsSelector,
  allPass([
    isAllowed(OBJECT_TYPES.Things, OPERATIONS.UPDATE),
    isAllowed(OBJECT_TYPES.ThingGroups, OPERATIONS.CREATE)
  ])
);

export const userCanReadThingGroup = createSelector(
  authSelectors.permissionsSelector,
  allPass([
    isAllowed(OBJECT_TYPES.Things, OPERATIONS.READ),
    isAllowed(OBJECT_TYPES.ThingGroups, OPERATIONS.READ)
  ])
);
