import styled from "styled-components";
import * as colors from "themes/colors";

export default styled.div`
  border: 2px solid
    ${props =>
      props.isActive ? colors.colorMercuryApprox : colors.backgroundColor};
  align-items: center;
  justify-content: center;
  margin: 20px;
  display: flex;
  flex: 1;
  padding: 10px;
  background: ${colors.backgroundColor};
  cursor: pointer;
`;
