import { TYPE_GEOPOINT } from "../types";
import { latLooper, longLooper } from "utils/latlng_utils";

export default (type, params) => {
  switch (type) {
    case TYPE_GEOPOINT:
    default: {
      const { lat, lon, radius, precision } = params;
      const a = Math.random() * 2 * Math.PI;
      const r = radius * Math.sqrt(Math.random());

      const newLat = parseFloat((lat + r * Math.cos(a)).toFixed(precision));
      const newLon = parseFloat((lon + r * Math.sin(a)).toFixed(precision));
      return `${latLooper(newLat)},${longLooper(newLon)}`;
    }
  }
};
