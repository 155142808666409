import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { compose, shouldUpdate } from "react-recompose";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SimulatorIcon from "@material-ui/icons/PlayCircleFilled";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormIconButton from "components/buttons/form_icon";
import DataExportIcon from "components/icon/data_export";
import Rssi from "components/thing/rssi";
import ThingStatusBar from "components/thing/thing_status_bar";
import BatteryLevel from "components/thing/battery_level";
import { getConnectionStatus } from "utils/thing_utils";
import { thingDetailsUrl } from "utils/route_utils";
import NetworkedThingsPicker from "./networked_things_picker";
import ThingSummary from "./thing_summary";

const styles = theme => ({
  thingTypeLink: {
    marginRight: "10px"
  },
  root: {
    backgroundColor: "inherit"
  },
  panelRoot: {
    marginTop: theme.spacing(4) + "px !important",
    marginBottom: theme.spacing(2) + "px !important"
  },
  summaryContent: {
    justifyContent: "space-between"
  },
  detailsExpander: { padding: "0px" },
  rssiBatIconWrapper: {
    display: "inline-block",
    marginRight: "8px"
  }
});

const _shouldRenderNetworkedThingsMenu = _thingShadow =>
  R.prop("hasNetworkedThings")(_thingShadow) ||
  R.prop("parentThingName")(_thingShadow);

export const getParentAndNetworkedThingList = networkedThingsConnection => [
  {
    ...R.propOr([], "parentThing", networkedThingsConnection),
    hasNetworkedThings: true
  },
  ...R.propOr([], "subThings", networkedThingsConnection)
];

const TooltipToolbarButton = props => (
  <Tooltip title={props.tooltipText} placement="top" color="inherit">
    <ToolbarButton {...R.omit(["tooltipText"], props)} />
  </Tooltip>
);

TooltipToolbarButton.propTypes = {
  tooltipText: PropTypes.string
};

const ToolbarButton = ({
  icon,
  text = undefined,
  color = "default",
  onClick,
  ...props
}) => {
  return (
    <FormIconButton
      color={color}
      icon={icon}
      text={text}
      onClick={e => {
        e.stopPropagation();
        if (props.href && onClick) {
          if (!(e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            onClick(props.href);
          }
        } else if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    />
  );
};

ToolbarButton.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

const ThingToolbar = ({
  account,
  title,
  thingTypeUrl,
  thingTypeLabel,
  onDeleteThing,
  onAddNetworkedThing,
  router,
  classes,
  onEditThing,
  onCreateExport,
  thingShadow,
  userCanPublish,
  isLoading,
  tcxnValues,
  thing
}) => {
  const _thingShadow = thingShadow.toJS();

  return (
    <div>
      <Accordion
        elevation={0}
        defaultExpanded={false}
        classes={{
          root: classes.panelRoot
        }}
        square
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          classes={{
            content: classes.summaryContent
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container data-test="thing-summary-header">
            <Grid container item xs={12} lg={6} alignItems="center">
              <FormIconButton
                color="default"
                text={thingTypeLabel}
                icon={<LeftIcon />}
                onClick={e => {
                  e.stopPropagation();
                  router.push(thingTypeUrl);
                }}
                className={classes.thingTypeLink}
                data-test="page-header-back-button"
              />
              {_shouldRenderNetworkedThingsMenu(_thingShadow) ? (
                <NetworkedThingsPicker
                  data-test="networked-things-picker"
                  things={getParentAndNetworkedThingList(
                    R.prop("networkedThingsConnection", _thingShadow)
                  )}
                  disabled={isLoading}
                  selectedThingName={R.prop("thingName", _thingShadow)}
                  onClickThing={thing => {
                    router.push(thingDetailsUrl(router, thing));
                  }}
                />
              ) : (
                <Typography variant="h6">{title}</Typography>
              )}
              {!isLoading && (
                <Grid container item xs={4}>
                  <ThingStatusBar
                    thing={thing}
                    connectionStatus={getConnectionStatus(thing, true)}
                    thingShadow={thingShadow}
                    subThingInfo={false}
                    isBigSize
                  />
                  <Rssi
                    networkType={R.prop("networkType", tcxnValues)}
                    signalStrength={R.prop("rssi", tcxnValues)}
                    showNetworkType={false}
                    classes={{ wrapper: classes.rssiBatIconWrapper }}
                  />
                  <BatteryLevel
                    battery={R.prop("battery_level", tcxnValues)}
                    classes={{ wrapper: classes.rssiBatIconWrapper }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              container
              justify="flex-end"
              alignItems="center"
            >
              {userCanPublish && _thingShadow && _thingShadow.simulated && (
                <TooltipToolbarButton
                  text="Simulate"
                  tooltipText="Simulate Thing resource values"
                  href={`/thingsimulator/${_thingShadow.thingType}/${_thingShadow.thingName}`}
                  icon={<SimulatorIcon />}
                  onClick={router.push}
                />
              )}

              <TooltipToolbarButton
                icon={<DataExportIcon />}
                text="Export"
                onClick={onCreateExport}
                tooltipText="Export historical Thing data"
              />
              <TooltipToolbarButton
                icon={<EditIcon />}
                onClick={onEditThing}
                tooltipText="Edit Thing"
              />
              <TooltipToolbarButton
                icon={<DeleteIcon />}
                onClick={onDeleteThing}
                tooltipText="Delete Thing"
              />

              {_thingShadow &&
                !_thingShadow.simulated &&
                !isLoading &&
                !R.prop("parentThingName")(_thingShadow) && (
                  <TooltipToolbarButton
                    icon={<AddIcon />}
                    color="primary"
                    text="Networked Thing"
                    tooltipText="Add networked thing"
                    data-test="add-networkedthing__button"
                    onClick={onAddNetworkedThing}
                  />
                )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails elevation={1}>
          <ThingSummary
            account={account}
            thingType={{ label: thingTypeLabel, url: thingTypeUrl }}
            thingShadow={_thingShadow}
            tcxnValues={tcxnValues}
            thing={thing}
            thingTypeLabel={thingTypeLabel}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

ThingToolbar.propTypes = {
  title: PropTypes.string,
  thing: PropTypes.object,
  thingTypeUrl: PropTypes.string,
  thingTypeLabel: PropTypes.string,
  router: PropTypes.object,
  classes: PropTypes.object,
  onEditThing: PropTypes.func,
  onDeleteThing: PropTypes.func,
  onAddNetworkedThing: PropTypes.func,
  onCreateExport: PropTypes.func,
  thingShadow: PropTypes.object,
  userCanPublish: PropTypes.bool,
  isLoading: PropTypes.bool,
  tcxnValues: PropTypes.object,
  account: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default compose(
  shouldUpdate(({ isLoading }) => {
    return !isLoading;
  }),
  withRouter,
  withStyles(styles)
)(ThingToolbar);
