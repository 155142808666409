import { fromJS, List } from "immutable";
import { head, forEach, toString } from "ramda";
import "mapbox.js";
import L from "leaflet";
import "mapbox.js/theme/style.css";
// Needed to copy leaflet css and images from node_modules because of Issue in Leaflet 1.0, fix should be added to upcomming version.
// https://github.com/Leaflet/Leaflet/issues/4849
import "../components/widget/widget_types/things_map_widget/leaflet.css";
/* import 'leaflet/dist/leaflet.css'; */
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.pm";
import { MAPBOX } from "../constants";
import { thingDetailsUrl } from "./route_utils";

const WORLD_VIEW_CENTER = [40, 0];
const WORLD_VIEW_ZOOM = 2;
const MAX_LINKS = 15;

export const newMap = ({ mapId, mapOptions, ...rest }) => ({
  map: L.map(mapId, mapOptions),
  mapId,
  mapOptions,
  ...rest
});

export const withNewCluster = ({ map, ...rest }) => {
  const layer = L.markerClusterGroup({
    iconCreateFunction: cluster => {
      return L.divIcon({
        html: `<div class="custom-cluster-marker"><span>${cluster.getChildCount()}</span></div>`
      });
    },
    showCoverageOnHover: false
  });

  layer.off("clusterclick");
  layer.on("clusterclick", c => {
    map.fitBounds(c.layer.getBounds(), { padding: [100, 100] });
  });
  map.addLayer(layer);
  return { map, layer, ...rest };
};

export const withFeatureGroupLayer = ({ map, ...rest }) => {
  const layer = L.featureGroup();
  layer.addLayers = function(layers) {
    forEach(layer => {
      this.addLayer(layer);
    }, layers);
  };
  map.addLayer(layer);
  return { map, layer, ...rest };
};

export const withTooltip = ({ layer, markerOptions, ...rest }) => {
  layer.on("clustermouseover", clus => {
    const markers = clus.layer.getAllChildMarkers();
    const links = (markers.length > MAX_LINKS
      ? markers.slice(0, MAX_LINKS)
      : markers
    ).map((lay, i) => {
      const url = thingDetailsUrl({}, lay.options);
      const title = lay.options.title;
      return `<a href="${url}" data-markerindex="${i}">${title}</a>`;
    });

    const contentElement = L.DomUtil.create("div");
    contentElement.innerHTML = `${links.join(", ")}${
      markers.length > MAX_LINKS ? "..." : ""
    }`;

    if (typeof markerOptions.gotoThingDetail === "function") {
      contentElement.addEventListener(
        "click",
        ev => {
          const markerIndex = ev.target.dataset.markerindex;
          const marker = markerIndex != null && markers[markerIndex];
          marker && markerOptions.gotoThingDetail(marker, ev);
        },
        true
      );
    }

    clus.layer.bindPopup(contentElement).openPopup();
  });

  return { layer, markerOptions, ...rest };
};

export const withWorldView = ({ map, ...rest }) => ({
  ...rest,
  map: map
    .setView(WORLD_VIEW_CENTER, WORLD_VIEW_ZOOM)
    .setMaxBounds(
      L.latLngBounds(L.latLng(-90.0, -180.0), L.latLng(90.0, 180.0))
    )
});

export const withZoomControl = ({ map, ...rest }) => {
  L.control
    .zoom({
      zoomInText: "+",
      zoomOutText: "-"
    })
    .addTo(map);

  return { map, ...rest };
};

export const withTileLayer = ({ map, tileOptions, ...rest }) => {
  L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    {
      id: "mapbox/streets-v11",
      accessToken: MAPBOX.TOKEN,
      ...tileOptions
    }
  ).addTo(map);
  return { map, tileOptions, ...rest };
};

export const withMarkerData = ({
  map,
  mapId,
  layer,
  markerOptions,
  ...rest
}) => {
  const _addMarkers = markers => {
    layer.clearLayers();
    layer.addLayers(markers.toArray(), {
      chunkedLoading: true,
      chunkInterval: 150,
      chunkDelay: 50
    });

    if (markerOptions.disableMarkerAutofit !== true) {
      map.fitBounds(layer.getBounds(), { padding: [50, 50], maxZoom: 12 });
    }
  };
  const getClassname = (getMarkerType, d, index) => {
    if (!getMarkerType) return "custom-marker";
    const markerType = getMarkerType(d, index);
    return markerType === "trace" ? "trace-marker" : "custom-marker";
  };
  const getCurrentMarkerSVG = markerColor => `<svg viewBox="0 0 14 20" fill="${markerColor}" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z" />
  </svg>
  `;
  const getTraceMarkerSVG = markerColor =>
    `<svg viewBox="0 0 5 5" fill="${markerColor}">
    <path d="M2.5 0A2.5 2.5 0 0 0 0 2.5 2.5 2.5 0 0 0 2.5 5 2.5 2.5 0 0 0 5 2.5 2.5 2.5 0 0 0 2.5 0zm0 1.25a1.25 1.25 0 0 1 .125.006 1.25 1.25 0 0 1 .123.019 1.25 1.25 0 0 1 .121.03 1.25 1.25 0 0 1 .117.044 1.25 1.25 0 0 1 .113.054 1.25 1.25 0 0 1 .106.066 1.25 1.25 0 0 1 .1.075 1.25 1.25 0 0 1 .092.085 1.25 1.25 0 0 1 .082.094 1.25 1.25 0 0 1 .073.102 1.25 1.25 0 0 1 .062.108 1.25 1.25 0 0 1 .05.114 1.25 1.25 0 0 1 .04.119 1.25 1.25 0 0 1 .028.122 1.25 1.25 0 0 1 .015.124 1.25 1.25 0 0 1 .003.088 1.25 1.25 0 0 1-.007.125 1.25 1.25 0 0 1-.018.124 1.25 1.25 0 0 1-.031.12 1.25 1.25 0 0 1-.043.118 1.25 1.25 0 0 1-.054.113 1.25 1.25 0 0 1-.065.106 1.25 1.25 0 0 1-.076.1 1.25 1.25 0 0 1-.085.09 1.25 1.25 0 0 1-.094.083 1.25 1.25 0 0 1-.102.073 1.25 1.25 0 0 1-.108.062 1.25 1.25 0 0 1-.114.051 1.25 1.25 0 0 1-.12.04 1.25 1.25 0 0 1-.12.027 1.25 1.25 0 0 1-.125.015 1.25 1.25 0 0 1-.088.003 1.25 1.25 0 0 1-.125-.006 1.25 1.25 0 0 1-.123-.019 1.25 1.25 0 0 1-.121-.03 1.25 1.25 0 0 1-.118-.044 1.25 1.25 0 0 1-.112-.054 1.25 1.25 0 0 1-.107-.065 1.25 1.25 0 0 1-.1-.076 1.25 1.25 0 0 1-.091-.085 1.25 1.25 0 0 1-.082-.094 1.25 1.25 0 0 1-.073-.101 1.25 1.25 0 0 1-.062-.109 1.25 1.25 0 0 1-.051-.114 1.25 1.25 0 0 1-.04-.119 1.25 1.25 0 0 1-.027-.122 1.25 1.25 0 0 1-.015-.124A1.25 1.25 0 0 1 1.25 2.5a1.25 1.25 0 0 1 .006-.124 1.25 1.25 0 0 1 .019-.124 1.25 1.25 0 0 1 .03-.121 1.25 1.25 0 0 1 .043-.118 1.25 1.25 0 0 1 .055-.112 1.25 1.25 0 0 1 .065-.107 1.25 1.25 0 0 1 .076-.099 1.25 1.25 0 0 1 .085-.091 1.25 1.25 0 0 1 .094-.083 1.25 1.25 0 0 1 .101-.073 1.25 1.25 0 0 1 .109-.062 1.25 1.25 0 0 1 .114-.05 1.25 1.25 0 0 1 .118-.04 1.25 1.25 0 0 1 .122-.028 1.25 1.25 0 0 1 .124-.015A1.25 1.25 0 0 1 2.5 1.25z" paint-order="stroke markers fill"/></svg>`;
  const getMarkerHtml = (getMarkerType, d, index) => {
    const markerColor = d.getIn(["properties", "markerColor"]);
    if (!getMarkerType) return getCurrentMarkerSVG(markerColor);
    const markerType = getMarkerType(d, index);
    return markerType === "trace"
      ? getTraceMarkerSVG(markerColor)
      : getCurrentMarkerSVG(markerColor);
  };
  const _createMarkers = (
    data,
    gotoThingDetail,
    titleSelector,
    getMarkerType
  ) => {
    return data.map((d, index) =>
      L.marker(L.latLng(...d.get("latLng")), {
        title: titleSelector
          ? titleSelector(d)
          : d.getIn(["properties", "thingName"]),
        alt: d.getIn(["properties", "thingName"]),
        thingType: d.getIn(["properties", "thingType"]),
        thingName: d.getIn(["properties", "thingName"]),
        icon: L.divIcon({
          html: getMarkerHtml(getMarkerType, d, index),
          className: getClassname(getMarkerType, d, index)
        })
      }).on("click", ev => gotoThingDetail(d, ev.originalEvent))
    );
  };

  let markerLayer;

  const _addMarkerLines = (data, lineOptions, map) => {
    const line = data.map(observation => {
      return observation.get("latLng");
    });
    markerLayer = L.polyline(line.toJS(), lineOptions).addTo(map);
  };

  const _clearMarkerLines = map => {
    if (markerLayer) {
      markerLayer.removeFrom(map);
      markerLayer = null;
    }
  };

  const _addLayersAndMarkers = (data, gotoThingDetail) => {
    if (data && map && layer) {
      const markers = _createMarkers(
        data,
        gotoThingDetail,
        markerOptions.titleSelector,
        markerOptions.getMarkerType
      );
      if (markers && markers.size > 0) _addMarkers(markers);
      if (data.size === 0 && layer && layer.getLayers().length) {
        _clearMarkerLines(map);
        layer.clearLayers();
      }

      if (markerOptions.connectingLines) {
        _clearMarkerLines(map);
        _addMarkerLines(data, markerOptions.lineOptions, map);
      }
    }
  };

  const onReceiveData = ({
    data,
    gotoThingDetail = () => {},
    titleSelector
  }) => {
    _addLayersAndMarkers(data, gotoThingDetail, titleSelector);
    if (data && data.size === 0) {
      map.setView(WORLD_VIEW_CENTER, WORLD_VIEW_ZOOM);
    }
  };

  if (markerOptions && markerOptions.data && markerOptions.data.size) {
    onReceiveData({ ...markerOptions });
  }

  return { map, mapId, layer, markerOptions, onReceiveData, ...rest };
};

export const withDrawPoly = ({ map, drawPolyOptions, ...rest }) => {
  let polygonLayer = null;
  const _addPolygon = ({ geoJson, type }) => {
    if (type === "poi") {
      const center = geoJson.get("center");
      polygonLayer = L.circle(
        [center.get("lat"), center.get("lon")],
        geoJson.get("radius")
      );
    } else {
      polygonLayer = L.geoJson({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [geoJson.toJS()]
        }
      });
    }

    polygonLayer.addTo(map);
    map.fitBounds(polygonLayer.getBounds(), { padding: [50, 50] });
  };

  const _removePolygon = () => {
    if (polygonLayer) {
      polygonLayer.removeFrom(map);
      polygonLayer = null;
    }
  };

  const onReceiveGeoJson = props => {
    const { geoJson, type } = props;
    if (geoJson && geoJson.size) {
      map.pm.disableDraw(type === "poi" ? "Circle" : "Poly");
      _addPolygon({ geoJson, type });
    } else {
      map.pm.enableDraw(type === "poi" ? "Circle" : "Poly");
      _removePolygon();
    }
  };

  const onPerimeterChangeChanged = ({ onPerimeterChange, type }) => {
    map.removeEventListener("pm:create");
    if (typeof onPerimeterChange === "function") {
      map.addEventListener("pm:create", ({ layer }) => {
        if (type === "poi") {
          const center = layer.getBounds().getCenter();
          onPerimeterChange(
            layer
              ? fromJS({
                  radius: layer.getRadius(),
                  center: {
                    lat: toString(center.lat),
                    lon: toString(center.lng)
                  }
                })
              : fromJS({})
          );
        } else {
          const layerJson = layer.toGeoJSON();
          onPerimeterChange(
            layerJson ? List(head(layerJson.geometry.coordinates)) : List()
          );
        }

        layer && layer.removeFrom(map);
      });
    }
  };

  if (drawPolyOptions) {
    if (drawPolyOptions.geoJson) {
      onReceiveGeoJson(drawPolyOptions);
    }
    if (drawPolyOptions.onPerimeterChange) {
      onPerimeterChangeChanged(drawPolyOptions);
    }
  }

  return {
    map,
    drawPolyOptions,
    onReceiveGeoJson,
    onPerimeterChangeChanged,
    ...rest
  };
};
