import * as R from "ramda";
import { fromJS, List, Set, Iterable } from "immutable";

export const isImmutable = a =>
  R.allPass([
    R.complement(R.isNil),
    R.has("size"),
    R.anyPass([R.hasIn("get"), R.hasIn("getIn"), R.hasIn("toJS")])
  ])(a);

export const equals = (a, b) =>
  R.when(
    R.complement(R.isNil),
    R.ifElse(
      isImmutable,
      a => a.equals(isImmutable(b) ? b : fromJS(b)),
      R.equals(b)
    )
  )(a);

export const isList = a =>
  R.allPass([isImmutable, R.ifElse(R.isNil, R.F, R.hasIn("push"))])(a);

export const isMap = a =>
  R.allPass([
    isImmutable,
    R.when(R.complement(R.isNil), R.complement(R.hasIn("push")))
  ])(a);

export const unImmute = R.when(isImmutable, im => im.toJS());

export const getIn = (obj, path, or) =>
  R.ifElse(
    R.isNil,
    R.always(or),
    R.ifElse(isImmutable, obj => obj.getIn(path, or), R.pathOr(or, path))
  )(obj);

export const get = (obj, prop, or) =>
  R.ifElse(
    R.isNil,
    R.always(or),
    R.ifElse(isImmutable, obj => obj.get(prop, or), R.propOr(or, prop))
  )(obj);

export const toJSArray = list =>
  R.cond([
    [isList, list => list.toJS()],
    [isMap, list => list.toArray()],
    [R.is(Object), list => Object.values(list)],
    [R.T, list => list]
  ])(list);

export const toJS = im => R.when(isImmutable, im => im.toJS())(im);

export const unionSetMerger = (a, b) => {
  if (List.isList(a) && List.isList(b)) {
    return a
      .toSet()
      .union(b)
      .toList();
  }
  if (Set.isSet(a) && Set.isSet(b)) {
    return a.union(b);
  }
  if (Set.isSet(a) && List.isList(b)) {
    return a.union(b.toSet());
  }
  if (List.isList(a) && Set.isSet(b)) {
    return a.toSet().union(b);
  }
  if (a && a.mergeWith) {
    return a.mergeWith(unionSetMerger, b);
  }
  return b;
};

export const getLength = R.ifElse(
  isImmutable,
  R.prop("size"),
  R.prop("length")
);

export const mergeConfig = (config, newConfig) => {
  // The built in merge functions does not support merging the config object, in
  // for example webhook_action.js
  //return config.merge(newConfig);      fails testcase "Should merge config parameters deep"
  //return config.mergeDeep(newConfig);  fails testcase "should merge config with lists replaced and not merged"

  const configMerger = (a, b) => {
    if (Iterable.isKeyed(a)) {
      // recursively merge like deepMerge
      return a.mergeWith(configMerger, b);
    } else {
      // replace value like merge
      return b;
    }
  };

  return config.mergeWith(configMerger, newConfig);
};
