import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = () => ({
  container: {
    width: "100%",
    marginTop: "24px",
    marginBottom: "24px"
  },
  notifyToggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  textField: {
    width: "100%"
  },
  emailValidationError: {
    color: "red"
  }
});

const NotifyByEmailForm = ({
  notifyByEmail,
  recipients,
  handleToggleNotifyByEmail,
  handleRecipientsChanged,
  emailValidationError,
  validateRecipients,
  classes
}) => (
  <Grid container spacing={8}>
    <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
      <Typography component="h5">Notify by email when job is done</Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <Switch
        checked={notifyByEmail}
        onChange={handleToggleNotifyByEmail}
        data-test="email-notify"
      />
    </Grid>
    {notifyByEmail && (
      <Grid item xs={12}>
        <TextField
          label="List of email addresses, comma separated"
          placeholder="test@email.com, user@email.com"
          className={classes.textField}
          value={recipients}
          onChange={handleRecipientsChanged}
          onBlur={validateRecipients}
          error={
            emailValidationError !== "" && emailValidationError !== undefined
          }
          helperText={emailValidationError}
          autoFocus
        />
      </Grid>
    )}
  </Grid>
);

NotifyByEmailForm.propTypes = {
  notifyByEmail: PropTypes.bool.isRequired,
  recipients: PropTypes.string.isRequired,
  handleToggleNotifyByEmail: PropTypes.func.isRequired,
  handleRecipientsChanged: PropTypes.func.isRequired,
  validateRecipients: PropTypes.func.isRequired,
  emailValidationError: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(styles)(NotifyByEmailForm);
