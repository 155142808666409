import { useTheme } from "@material-ui/core/styles";
import { Modal } from "components/modal";
import { StackNameDisplay } from "development/components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { isDevelopmentNodeEnv } from "utils/general_utils";

const showStack = isDevelopmentNodeEnv();

const AppMain = styled.main`
  color: ${props => props.baseTheme.palette.text.primary};
  font-family: ${props => props.baseTheme.typography.fontFamily};
  height: 100%;
`;

const AppContainer = ({ children }) => {
  const theme = useTheme();
  return (
    <AppMain baseTheme={theme}>
      <Modal />
      {children}
      {showStack && <StackNameDisplay />}
    </AppMain>
  );
};

AppContainer.propTypes = {
  children: PropTypes.object
};

export default AppContainer;
