import React from "react";
import PropTypes from "prop-types";
import {
  compose,
  pure,
  branch,
  defaultProps,
  shouldUpdate
} from "react-recompose";
import NoDataIcon from "@material-ui/icons/VisibilityOff";
import sizeMe from "react-sizeme";
import { equals, omit } from "ramda";
import WidgetContainerContentBase from "./styled/widget_container_content_base";
import WidgetContainerContentEdit from "./styled/widget_container_content_edit";
import WidgetContainerContentValue from "./styled/widget_container_content_value";
import WidgetContainerContentItem from "./styled/widget_container_content_item";
import WidgetContainerContentAllThings from "./styled/widget_container_content_allthings";
import WidgetContainerContentThingcredentials from "./styled/widget_container_content_thingcredentials";

const containerTypes = {
  allthings: WidgetContainerContentAllThings,
  edit: WidgetContainerContentEdit,
  value: WidgetContainerContentValue,
  valueaggregation: WidgetContainerContentValue,
  item: WidgetContainerContentItem,
  thingcredentials: WidgetContainerContentThingcredentials
};

const NoData = (
  <NoDataIcon style={{ width: 32, height: 32, margin: 20 }} color="inherit" />
);

const WidgetContent = props => {
  const {
    isComponent,
    resource,
    wkey,
    size,
    widgetComp,
    noDataComponent
  } = props;
  const type = (props.type || "").toLowerCase();
  const ContainerComponent = containerTypes[type] || WidgetContainerContentBase;
  const container = size
    ? { width: Math.ceil(size.width), height: Math.ceil(size.height) }
    : null;
  return (
    <ContainerComponent
      data-type={type}
      contentDirection={props.contentDirection}
      scrollable={props.scrollable}
      key={wkey}
    >
      {!isComponent && !resource
        ? noDataComponent || <NoData />
        : React.createElement(widgetComp, {
            ...omit(["widgetComp"], props),
            container
          })}
    </ContainerComponent>
  );
};

WidgetContent.propTypes = {
  widgetComponent: PropTypes.func,
  onResize: PropTypes.func,
  noResize: PropTypes.bool,
  size: PropTypes.object,
  gridSize: PropTypes.object,
  noDataComponent: PropTypes.element,
  widgetComp: PropTypes.func,
  filterId: PropTypes.string,
  resource: PropTypes.object,
  type: PropTypes.string,
  wkey: PropTypes.string,
  unit: PropTypes.string,
  isComponent: PropTypes.bool,
  scrollable: PropTypes.bool,
  contentDirection: PropTypes.oneOf(["row", "column"]),
  isRealtime: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

export default compose(
  defaultProps({
    contentDirection: "column",
    scrollable: false
  }),
  branch(
    ({ noResize }) => noResize,
    () => props => <WidgetContent {...props} />,
    sizeMe({
      monitorWidth: true,
      monitorHeight: true,
      refreshMode: "debounce",
      refreshRate: 250
    }) // will wrap WidgetContent in a sizeme component
  ),
  shouldUpdate((props, next) => {
    return (
      !equals(props.resource, next.resource) || // resourcesChange
      !equals(props.resourceList, next.resourceList) ||
      !equals(props.type, next.type) || // typeChange
      !equals(props.observationPeriod, next.observationPeriod) || // observation picker changed
      !(props.noResize || equals(props.size, next.size)) || // sizeChange
      !equals(props.widget, next.widget) || // widgetChange
      props.thingType !== next.thingType ||
      !props.thingType || // thingTypeChange
      props.viewMode !== next.viewMode ||
      !props.viewMode || // viewModeChange
      props.subthing !== next.subthing
    );
  }),
  pure
)(WidgetContent);
