import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import Simulator from "../components/simulator/";
import * as colors from "themes/colors";

// The Simulator listens for changes on these resources
const SIMULATOR_SETABLE_RESOURCES = {
  background: "",
  fontColor: colors.black,
  fontSize: "12px"
};

const subscribe = (target, eventName, handle) => {
  target.addEventListener(eventName, handle, false);
  return () => target.removeEventListener(eventName, handle);
};

const _isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default class App extends Component {
  componentDidMount() {
    const { background, fontColor, fontSize } = this.props;
    this.setState({
      timeActive: 0,
      intervalId: null,
      setableResources: { background, fontColor, fontSize }
    });
    const { mqttClient } = this.props;
    this.subscribeThingEvent(mqttClient);
    this.emitThingEventIntervall(mqttClient);

    this.unsubscribeBeforeunload = subscribe(global, "beforeunload", e => {
      mqttClient
        .publish({
          state: {
            reported: Object.assign({}, this.context.device, {
              tcxn: {
                ["connection_status"]: 0
              }
            })
          }
        })
        .then(() => mqttClient.unsubscribe()); // device offline
      return (e.returnValue = "Closing this tab will disconnect the Simulator"); // should return for maximum compatibility
    });

    // this.tick = setTimeout(() => socket.getLatestThingShadow(), 1000);
  }

  componentWillUnmount() {
    // In componentWillMount we make use of the beforeunload event. Should only be used for the Simulator page.
    this.unsubscribeBeforeunload();
    clearInterval(this.state.intervalId);
  }

  emitThingEventIntervall(mqttClient) {
    // Send data every x seconds
    const intervalId = setInterval(() => {
      this.setState({
        timeActive: this.state.timeActive + mqttClient.INTERVAL
      });
      if (this.state.timeActive <= this.props.timeout) {
        /* eslint-disable */
        mqttClient.publish({
          state: {
            reported: {
              ...this.context.device,
              ...this.state.setableResources,
              tcxn: {
                connection_status: 2
              }
            }
          }
        }); // device online
        /* eslint-enable */
      } else {
        /* eslint-disable */
        mqttClient.publish({
          state: {
            reported: {
              ...this.context.device,
              ...this.state.setableResources,
              tcxn: {
                connection_status: 0
              }
            }
          }
        }); // device offline
        /* eslint-enable */
        clearInterval(this.state.intervalId);
      }
    }, mqttClient.INTERVAL);

    this.setState({
      intervalId
    });
  }

  onGetAcceptedThingShadow(mqttClient, payload = {}) {
    const acceptedKeys = R.keys(SIMULATOR_SETABLE_RESOURCES);
    const reportedSetables = R.pick(acceptedKeys, payload.reported);
    const deltaSetables = R.pick(acceptedKeys, payload.delta);

    R.forEachObjIndexed((v, k) => {
      /* eslint-disable */
      this.state.setableResources[k] = v;
      /* eslint-enable */
    }, reportedSetables);

    if (payload.delta && !_isNullOrEmpty(deltaSetables)) {
      if (!_isNullOrEmpty(deltaSetables)) {
        const desiredKeys = R.difference(
          Object.keys(payload.desired),
          acceptedKeys
        );
        const desired = desiredKeys.reduce((des, key) => {
          des[key] = payload.desired[key];
          return des;
        }, {});
        mqttClient.publish({
          state: {
            reported: deltaSetables,
            desired
          }
        });

        R.forEachObjIndexed((v, k) => {
          /* eslint-disable */
          this.state.setableResources[k] = v;
          /* eslint-enable */
        }, deltaSetables);
      }
    }

    // Check if there is a value for the setable-properties
    // If not, publish default value for them so the Appboard can pick up the resources
    const all = Object.assign({}, deltaSetables, reportedSetables);

    const reportDefaults = acceptedKeys.reduce((toReport, key) => {
      if (!all[key]) {
        toReport[key] = SIMULATOR_SETABLE_RESOURCES[key];
      }
      return toReport;
    }, {});

    if (!_isNullOrEmpty(reportDefaults)) {
      mqttClient.publish({
        state: {
          reported: reportDefaults
        }
      });
    }

    this.setState(this.state);
  }

  onUpdateDeltaThingShadow(mqttClient, payload = {}) {
    const acceptedKeys = R.keys(SIMULATOR_SETABLE_RESOURCES);
    const desiredKeys = R.keys(payload);
    const differenceDesiredKeys = R.difference(desiredKeys, acceptedKeys);
    const reportedIntersectionKeys = R.intersection(desiredKeys, acceptedKeys);

    const desired = differenceDesiredKeys.reduce((des, key) => {
      des[key] = payload[key];
      return des;
    }, {});

    const reported = reportedIntersectionKeys.reduce((rep, key) => {
      rep[key] = payload[key];
      return rep;
    }, {});

    const shouldEmit = R.keys(reported).length > 0;

    if (shouldEmit) {
      mqttClient.publish({
        state: {
          reported,
          desired
        }
      });

      R.forEachObjIndexed((v, k) => {
        /* eslint-disable */
        this.state.setableResources[k] = v;
        /* eslint-enable */
      }, reported);

      this.setState(this.state);
    }
  }

  subscribeThingEvent(mqttClient) {
    mqttClient.subscribe(({ update, delta }) => {
      if (update)
        this.onGetAcceptedThingShadow(mqttClient, update && update.state);
      if (delta)
        this.onUpdateDeltaThingShadow(mqttClient, delta && delta.state);
    });
  }

  render() {
    const { device } = this.context;
    const isActive = this.state.timeActive <= this.props.timeout;
    const status = isActive
      ? "Move mouse or tilt device to generate data"
      : "Simulator is stopped";
    const statusCSS = {
      color: "#D8D8D8",
      fontSize: 28,
      padding: "20 0"
    };

    const sectionCSS = {
      maxWidth: 620,
      height: "100%",
      margin: "0 auto",
      textAlign: "center",
      position: "relative",
      background: this.state.setableResources.background,
      color: this.state.setableResources.fontColor,
      fontSize: this.state.setableResources.fontSize
    };

    const telenorButtonCSS = {
      marginTop: "20",
      height: 40,
      width: "50%",
      margin: "0 auto",
      color: colors.white,
      background: "none",
      boxSizing: "border-box",
      backgroundColor: colors.telenorPrimaryColor,
      borderRadius: "2px",
      border: "10px",
      padding: 0,
      outline: "none",
      cursor: "pointer",
      textTransform: "uppercase",
      fontSize: "16px",
      lineHeight: "36px",
      display: "block"
    };
    return (
      <section style={sectionCSS}>
        <h1 style={statusCSS}>{status}</h1>
        {isActive ? (
          <Simulator device={device} />
        ) : (
          <button
            style={telenorButtonCSS}
            onClick={() => window.location.reload()}
          >
            Start Simulator
          </button>
        )}
      </section>
    );
  }
}

App.contextTypes = {
  device: PropTypes.object
};

App.propTypes = {
  timeout: PropTypes.number,
  mqttClient: PropTypes.object,
  background: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string
};

App.defaultProps = {
  background: "",
  fontColor: colors.black,
  fontSize: "12px",
  timeout: 60000
};
