import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { widgetProp } from "utils/dashboard_utils";
import { getValueAndPending } from "utils/widget_utils";
import GaugeChart from "./gauge_chart";

export const _getColorPattern = (color_thresholds, themeColorHex) => {
  return color_thresholds && color_thresholds.length > 0
    ? color_thresholds.map(item => item.color)
    : [themeColorHex];
};
export const _getThresholdsArray = color_thresholds => {
  const _defaultThresholds = [0];
  return color_thresholds && color_thresholds.length > 0
    ? color_thresholds.map(item => item.threshold)
    : _defaultThresholds;
};

const GaugeWidget = ({
  resource,
  widget,
  container,
  isVirtualResource,
  unit
}) => {
  const theme = useTheme();
  const { value, pendingValue } = getValueAndPending(
    resource,
    isVirtualResource
  );
  const min = widgetProp(widget, "min", 0);
  const max = widgetProp(widget, "max", 100);
  const numberOfDecimals = widgetProp(widget, "numberOfDecimals");
  const color_thresholds = widgetProp(widget, "color_thresholds", []);
  return (
    <GaugeChart
      value={Number(value)}
      pendingValue={Number(pendingValue)}
      unit={unit}
      min={min}
      max={max}
      thresholds={color_thresholds}
      theme={theme}
      dimensions={container}
      numberOfDecimals={numberOfDecimals}
    />
  );
};

GaugeWidget.propTypes = {
  resource: PropTypes.object,
  layout: PropTypes.object,
  widget: PropTypes.object,
  container: PropTypes.object,
  isVirtualResource: PropTypes.bool,
  unit: PropTypes.string
};

export default GaugeWidget;
