import { authSelectors } from "ducks/auth";
import { createSelector } from "reselect";
import {
  isAllowed,
  isSettingAllowedAndHasExplicitSettingPermissions,
  containsObjectPermissionInDomain,
  OBJECT_TYPES,
  OPERATIONS,
  SETTING_TYPES
} from "utils/auth_utils";
import { userIsRoot, userNameSelector } from "../auth/auth_selectors";

export const userCanDeleteUsers = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Users, OPERATIONS.DELETE)
);

export const userCanEditUsers = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Users, OPERATIONS.UPDATE)
);

export const userCanCreateUsers = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Users, OPERATIONS.CREATE)
);

export const userCanReadUsers = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Users, OPERATIONS.READ)
);

export const userCanListRoles = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Permissions, OPERATIONS.READ)
);

export const userCanCreateUnits = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Units, OPERATIONS.CREATE)
);

export const userCanUpdateUnits = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Units, OPERATIONS.UPDATE)
);

export const userCanDeleteUnits = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Units, OPERATIONS.DELETE)
);

export const userCanReadFilesOnRoot = createSelector(
  authSelectors.permissionsSelector,
  authSelectors.userSelector,
  (permissions, user) => {
    const hasRootExplicit = containsObjectPermissionInDomain(
      OBJECT_TYPES.Files,
      OPERATIONS.READ,
      "root"
    )(permissions);

    return hasRootExplicit || user.domainPath === "/";
  }
);

export const userCanReadStorageRetention = createSelector(
  authSelectors.permissionsSelector,
  isSettingAllowedAndHasExplicitSettingPermissions(
    SETTING_TYPES.StorageRetention,
    OPERATIONS.READ
  )
);

export const userCanCreateStorageRetention = createSelector(
  authSelectors.permissionsSelector,
  isSettingAllowedAndHasExplicitSettingPermissions(
    SETTING_TYPES.StorageRetention,
    OPERATIONS.CREATE
  )
);

export const userCanDeleteStorageRetention = createSelector(
  authSelectors.permissionsSelector,
  isSettingAllowedAndHasExplicitSettingPermissions(
    SETTING_TYPES.StorageRetention,
    OPERATIONS.DELETE
  )
);

export const userCanRevokeConsent = createSelector(
  authSelectors.permissionsSelector,
  userIsRoot,
  (state, { userName }) => userNameSelector(state) === userName,
  (permissions, isRoot, isLoggedInUser) =>
    isLoggedInUser ||
    (isAllowed(OBJECT_TYPES.Users, OPERATIONS.UPDATE)(permissions) && isRoot)
);
