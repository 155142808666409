import { getAppConfig } from "api/rest/auth";
import AWS from "aws-sdk/global";
import * as R from "ramda";
import { defer, Observable } from "rxjs";
import { nonEmptyString } from "utils/general_utils";
import {
  configureAuthenticated,
  configureUnauthenticated
} from "../utils/cognito_credentials";
import manifestUrl from "../utils/manifest_url";
import { refreshToken$, refreshTokenLock } from "../utils/refresh_token";
import { traceErr, traceLog } from "../utils/trace";
import { manifest, ManifestInit } from "./manifest";

const manifestApiUrl = (env, apiGatewayRootUrl) =>
  `${apiGatewayRootUrl}/${env}/metadata/manifest/`;

const loadManifest = ({ account, env }) =>
  Observable.ajax({
    url: manifestUrl(account, env),
    responseType: "json",
    crossDomain: true
  })
    .map(({ response }) => ManifestInit(response))
    .toPromise();

const getApiKey = ({ env, apiGatewayRootUrl }) =>
  Observable.ajax({
    url: manifestApiUrl(env, apiGatewayRootUrl),
    responseType: "json",
    crossDomain: true
  })
    .map(({ response }) => {
      const apiKey = response.ApiKey;
      window.localStorage.setItem("apiKey", apiKey);
    })
    .toPromise();

const tryAuthenticate = storedAccount => {
  const isLogin = window.location.pathname.indexOf("login") >= 0;
  const hasStoredAccount = R.pathSatisfies(
    nonEmptyString,
    ["credentials", "token"],
    storedAccount
  );

  if (isLogin || !hasStoredAccount) {
    return Promise.resolve(null);
  } else {
    return configureAuthenticated(storedAccount.credentials.token)
      .getPromise()
      .then(() => {
        traceLog("[init] credentials ok");
        return storedAccount;
      })
      .catch(err => {
        traceErr("[init] credentials error", err);
        return refreshTokenLock
          .singleton(defer(() => refreshToken$(false)))
          .toPromise()
          .then(updatedAccount => {
            return updatedAccount;
          })
          .catch(e => {
            traceErr("refresh without redirect failed", e);
            return Promise.resolve(null);
          });
      });
  }
};

export default ({ account, env, storedAccount }) => {
  return loadManifest({ account, env }).then(() => {
    AWS.config.region = manifest.Region;
    const aws = {
      account: manifest.AccountNumber || account,
      env: manifest.StackName || env
    };

    configureUnauthenticated();
    return getApiKey({
      env: manifest.StackName || env,
      apiGatewayRootUrl: manifest.ApiGatewayRootUrl
    }).then(() => {
      return getAppConfig()
        .then(config =>
          tryAuthenticate(storedAccount).then(account => {
            traceLog("[init] auth successful", account);
            return {
              aws,
              config,
              account
            };
          })
        )
        .catch(err => {
          traceErr("getAppConfig failed", err);
          return Promise.reject(err);
        });
    });
  });
};
