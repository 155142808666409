import { fromJS } from "immutable";
import { push } from "react-router-redux";
import { createAction, handleActions } from "redux-actions";
import { requestStatus } from "utils/request_status";
import { setNotificationMessage } from "ducks/system";
import { getEditingAnalysis } from "./selectors";
import createCSV from "./utils/createCSV";
import downloadCSV from "./utils/downloadCSV";

export const name = "analyses";

export const DEFAULT_NAME = "";
export const DEFAULT_CHARTTYPE = "line";

// Action types

export const CREATE = `${name}/CREATE`;
export const SAVE = `${name}/SAVE`;
export const UPDATE = `${name}/UPDATE`;
export const EXPORT = `${name}/EXPORT`;
export const SET_SELECTED = `${name}/SET_SELECTED`;
export const TOGGLE_RESOURCE = `${name}/TOGGLE_RESOURCE`;
export const CLEAR_RESOURCES = `${name}/CLEAR_RESOURCES`;

export const FETCH_AGGREGATED_OBSERVATIONS_REQUEST = `${name}/FETCH_AGGREGATED_OBSERVATIONS_REQUEST`;
export const FETCH_AGGREGATED_OBSERVATIONS_SUCCESS = `${name}/FETCH_AGGREGATED_OBSERVATIONS_SUCCESS`;
export const FETCH_AGGREGATED_OBSERVATIONS_FAILURE = `${name}/FETCH_AGGREGATED_OBSERVATIONS_FAILURE`;

export const FETCH_RAW_OBSERVATIONS_REQUEST = `${name}/FETCH_RAW_OBSERVATIONS_REQUEST`;
export const FETCH_RAW_OBSERVATIONS_SUCCESS = `${name}/FETCH_RAW_OBSERVATIONS_SUCCESS`;
export const FETCH_RAW_OBSERVATIONS_FAILURE = `${name}/FETCH_RAW_OBSERVATIONS_FAILURE`;

export const FETCH_OBSERVATIONS_REQUEST = `${name}/FETCH_OBSERVATIONS_REQUEST`;
export const FETCH_OBSERVATIONS_FAILURE = `${name}/FETCH_OBSERVATIONS_FAILURE`;

export const FETCH_RESOURCE_MAPPING_REQUEST = `${name}/FETCH_RESOURCE_MAPPING_REQUEST`;
export const FETCH_RESOURCE_MAPPING_FAILURE = `${name}/FETCH_RESOURCE_MAPPING_FAILURE`;
export const FETCH_RESOURCE_MAPPING_SUCCESS = `${name}/FETCH_RESOURCE_MAPPING_SUCCESS`;

export const PUT_ANALYSES_REQUEST = `${name}/PUT_ANALYSES_REQUEST`;
export const PUT_ANALYSES_SUCCESS = `${name}/PUT_ANALYSES_SUCCESS`;
export const PUT_ANALYSES_FAILURE = `${name}/PUT_ANALYSES_FAILURE`;

export const DELETE_ANALYSIS_REQUEST = `${name}/DELETE_ANALYSIS_REQUEST`;
export const DELETE_ANALYSIS_SUCCESS = `${name}/DELETE_ANALYSIS_SUCCESS`;
export const DELETE_ANALYSIS_FAILURE = `${name}/DELETE_ANALYSIS_FAILURE`;

export const SET_SELECTED_DATA = "SET_SELECTED_DATA";
export const SET_SELECTED_RESOURCES = "SET_SELECTED_RESOURCES";

export const SORT_ANALYSES = `${name}/SORT_ANALYSES`;
export const SET_SEARCH_QUERY = `${name}/SET_SEARCH_QUERY`;

export const INIT_ANALYZER_REQUEST = `${name}/INIT_ANALYZER_REQUEST`;
export const INIT_ANALYZER_SUCCESS = `${name}/INIT_ANALYZER_SUCCESS`;
export const INIT_ANALYZER_FAILURE = `${name}/INIT_ANALYZER_FAILURE`;

// Action creators

export const initAnalyzer = createAction(INIT_ANALYZER_REQUEST);
export const initAnalyzerSuccess = createAction(INIT_ANALYZER_SUCCESS);
export const initAnalyzerFailure = createAction(INIT_ANALYZER_FAILURE);

export const putAnalyses = createAction(PUT_ANALYSES_REQUEST);
export const putAnalysesSuccess = createAction(PUT_ANALYSES_SUCCESS);
export const putAnalysesFailure = createAction(PUT_ANALYSES_FAILURE);

export const createAnalysis = createAction(CREATE);
export const saveAnalysis = createAction(SAVE);
export const updateAnalysis = createAction(UPDATE);
export const exportAnalysis = createAction(EXPORT);
export const setSelected = createAction(SET_SELECTED);
export const toggleResource = createAction(TOGGLE_RESOURCE);

export const deleteAnalysis = createAction(DELETE_ANALYSIS_REQUEST);
export const deleteAnalysisSuccess = createAction(DELETE_ANALYSIS_SUCCESS);
export const deleteAnalysisFailure = createAction(DELETE_ANALYSIS_FAILURE);

export const fetchAggregatedObservations = createAction(
  FETCH_AGGREGATED_OBSERVATIONS_REQUEST
);
export const fetchAggregatedObservationsSuccess = createAction(
  FETCH_AGGREGATED_OBSERVATIONS_SUCCESS
);
export const fetchAggregatedObservationsFailure = createAction(
  FETCH_AGGREGATED_OBSERVATIONS_FAILURE
);

export const fetchRawObservations = createAction(
  FETCH_RAW_OBSERVATIONS_REQUEST
);
export const fetchRawObservationsSuccess = createAction(
  FETCH_RAW_OBSERVATIONS_SUCCESS
);
export const fetchRawObservationsFailure = createAction(
  FETCH_RAW_OBSERVATIONS_FAILURE
);

export const fetchObservations = createAction(FETCH_OBSERVATIONS_REQUEST);
export const fetchObservationsFailure = createAction(
  FETCH_OBSERVATIONS_FAILURE
);

export const fetchResourceMapping = createAction(
  FETCH_RESOURCE_MAPPING_REQUEST
);
export const fetchResourceMappingFailure = createAction(
  FETCH_RESOURCE_MAPPING_FAILURE
);
export const fetchResourceMappingSuccess = createAction(
  FETCH_RESOURCE_MAPPING_SUCCESS
);

export const setSelectedData = createAction(SET_SELECTED_DATA);
export const setResources = createAction(SET_SELECTED_RESOURCES);
export const clearResources = createAction(CLEAR_RESOURCES);

export const filterMyAnalyses = query => {
  return { type: SET_SEARCH_QUERY, payload: query };
};

export const sortMyAnalyses = query => {
  return { type: SORT_ANALYSES, payload: query };
};

// Thunks

export const saveChanges = () => (dispatch, getState) => {
  const state = getState().analyzer;
  const resources = state
    .getIn(["datapicker", "resources"])
    .reduce(
      (list, thing, key) => [...list, ...thing.map(res => `${key}/${res}`)],
      []
    );
  const selected = state.getIn(["datapicker", "selected"]);
  dispatch(updateAnalysis({ resources, selected }));
};

export const prepareSaveAnalysis = () => (dispatch, getState) => {
  const data = getEditingAnalysis(getState());

  if (data.get("name") === DEFAULT_NAME) {
    return dispatch(setNotificationMessage(`Invalid analysis name!`));
  }

  if (data.get("resources").size) {
    dispatch(setNotificationMessage(`Analysis: '${data.get("name")}' saved!`));
    dispatch(push(`/analyze/${data.get("name")}`));
    dispatch(putAnalyses(data.toJS()));
    dispatch(saveAnalysis(data));
  } else {
    dispatch(
      setNotificationMessage(`Analysis must have at least one resource!`)
    );
  }
};

export const prepareExportAnalysis = data => dispatch => {
  if (!data || Object.keys(data).length === 0) {
    return dispatch(setNotificationMessage(`Cannot export empty csv`));
  }

  const csv = createCSV(data);
  downloadCSV(csv);
  dispatch(setNotificationMessage(`Downloading CSV: '${csv.title}'...`));
  dispatch(exportAnalysis());
};

export const newAnalysis = () => dispatch => {
  dispatch(push(`/analyze/untitled`));
  dispatch(createAnalysis());
};

export const initialState = fromJS({
  deleteStatus: requestStatus.IDLE,
  selected: DEFAULT_NAME,
  edits: {
    name: DEFAULT_NAME,
    resources: [],
    modified: Date.now(),
    chartType: DEFAULT_CHARTTYPE,
    hiddenResources: {},
    aggregationType: "AGGREGATED_OBSERVATIONS"
  },
  history: {}
});

export const reduceCreate = state =>
  state
    .set("selected", DEFAULT_NAME)
    .set("edits", initialState.get("edits").set("modified", Date.now()));

export const reduceSave = (state, { payload }) => {
  const name = payload.get("name");
  if (!name || name === DEFAULT_NAME) {
    return state.merge({ error: "Invalid name" });
  } else {
    return state
      .set("selected", name)
      .setIn(["history", name], payload.set("modified", Date.now()));
  }
};

export const reduceDeleteRequest = (state, action) =>
  state.set("deleteStatus", requestStatus.REQUESTED);

export const reduceDeleteFailure = (state, action) =>
  state.set("deleteStatus", requestStatus.FAILURE);

export const reduceDeleteSuccess = (state, action) =>
  state
    .set("history", fromJS(action.payload))
    .set("deleteStatus", requestStatus.SUCCESSFUL);

export const reduceUpdate = (state, { payload }) => {
  return state
    .set("selected", state.get("selected", DEFAULT_NAME))
    .mergeIn(["edits"], fromJS({ ...payload, modified: Date.now() }));
};

export const reduceExport = state => state;

export const reduceSetSelected = (state, { payload }) => {
  return state.get("selected") === payload
    ? state
    : state
        .set("selected", payload)
        .set(
          "edits",
          state.getIn(["history", payload], initialState.get("edits"))
        );
};

export const reduceInitAnalyzer = (state, action) =>
  state.mergeDeep({ history: fromJS(action.payload) });

export const reduceToggleResource = (state, { payload }) =>
  state.updateIn(["edits", "hiddenResources", payload], bool => {
    return !bool;
  });

const reduceSortAnalyzes = (state, { payload }) => {
  const order = payload.sort.includes("-") ? 1 : -1;
  const key = payload.sort.replace("-", "");
  var analyses = state.get("history").sort((a, b) => {
    if (a.get(key) > b.get(key)) return -1 * order;
    if (a.get(key) < b.get(key)) return order;

    return 0;
  });

  return state.set("history", analyses);
};

const reduceSetSearchQuery = (state, { payload }) => {
  return state.setIn(["query"], fromJS(payload));
};

const reduceResourceMapping = (state, { payload }) =>
  state.setIn(["thingTypeByThingName"], payload.thingTypeByThingName);

export default handleActions(
  {
    [SAVE]: reduceSave,
    [CREATE]: reduceCreate,
    [UPDATE]: reduceUpdate,
    [EXPORT]: reduceExport,
    [TOGGLE_RESOURCE]: reduceToggleResource,
    [SET_SELECTED]: reduceSetSelected,
    [SORT_ANALYSES]: reduceSortAnalyzes,
    [SET_SEARCH_QUERY]: reduceSetSearchQuery,

    [DELETE_ANALYSIS_REQUEST]: reduceDeleteRequest,
    [DELETE_ANALYSIS_FAILURE]: reduceDeleteFailure,
    [DELETE_ANALYSIS_SUCCESS]: reduceDeleteSuccess,
    [INIT_ANALYZER_SUCCESS]: reduceInitAnalyzer,

    [FETCH_RESOURCE_MAPPING_SUCCESS]: reduceResourceMapping
  },
  initialState
);
