import React from "react";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";

const RouteLoadingWrapper = styled.div`
  height: 4px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 9999;
`;

export const RouteLoading = () => (
  <RouteLoadingWrapper>
    <LinearProgress />
  </RouteLoadingWrapper>
);
