import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import DialogContentText from "@material-ui/core/DialogContentText";
import prettyBytes from "pretty-bytes";
import { defaultTo, head, isNil, pipe, prop, propIs, toLower } from "ramda";
import FormIconButton from "components/buttons/form_icon";
import * as colors from "themes/colors";

export const StyledDropzone = styled(Dropzone)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${colors.backgroundColor};
  text-align: center;
  border: 2px dashed ${colors.colorMercuryApprox};
  box-sizing: border-box;
  padding: 16px 24px;
`;

export const ErrorText = styled.p`
  color: ${colors.telenorErrorTextColor};
`;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.openDropzone = this.openDropzone.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
  }

  openDropzone() {
    this._dropzoneRef.open();
  }

  handleOnDrop(files) {
    if (!this.props.isLoading) {
      this.props.onSelectFile(head(files));
    }
  }

  render() {
    const {
      errorText,
      isLoading,
      selectedFile,
      subject,
      children,
      dropZoneText
    } = this.props;

    const fileName = pipe(prop("name"), defaultTo(""))(selectedFile);

    const fileSize = pipe(
      prop("size"),
      defaultTo(0),
      prettyBytes
    )(selectedFile);

    return (
      <StyledDropzone
        disablePreview
        disableClick
        disabled={isLoading}
        multiple={false}
        onDrop={this.handleOnDrop}
        ref={node => (this._dropzoneRef = node)}
      >
        {isNil(selectedFile) || !propIs(String, "name", selectedFile) ? (
          <div style={{ padding: 5 }}>
            <DialogContentText>
              {dropZoneText
                ? dropZoneText
                : `Drag and drop ${toLower(subject)} here, or click the button`}
            </DialogContentText>
            <FormIconButton
              data-test="choose_file_button"
              primary
              variant="contained"
              disabled={isLoading}
              text={`Choose ${subject}`}
              onClick={this.openDropzone}
            />
          </div>
        ) : (
          <div style={{ padding: "5px" }}>
            <DialogContentText>
              <strong data-test="upload_file_name">{fileName}</strong>{" "}
              <span data-test="upload_file_size">({fileSize})</span>
              <FormIconButton
                variant="contained"
                disabled={isLoading}
                text={`Change ${subject}`}
                onClick={this.openDropzone}
                style={{ margin: "20px" }}
              />
              {children}
            </DialogContentText>
          </div>
        )}
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </StyledDropzone>
    );
  }
}

UploadFile.propTypes = {
  subject: PropTypes.string,
  errorText: PropTypes.string,
  dropZoneText: PropTypes.string,
  isLoading: PropTypes.bool,
  onSelectFile: PropTypes.func,
  selectedFile: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number
  }),
  children: PropTypes.node
};

UploadFile.defaultProps = {
  subject: "file"
};

export default UploadFile;
