import { handleActions, createAction } from "redux-actions";
import * as R from "ramda";
import * as authUtils from "utils/auth_utils";
import { tryRecoverExpiredSession } from "utils/user_session";
import { clearMessages } from "ducks/system";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_REGISTER_SUCCESS = "CLEAR_REGISTER_SUCCESS";
export const USER_CONSENT_NEEDED = "USER_CONSENT_NEEDED";
export const USER_CONSENT_CANCELLED = "USER_CONSENT_CANCELLED";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const SET_USERNAME = "SET_USERNAME";
export const LOAD_AUTH_SUCCESS = "LOAD_AUTH_SUCCESS";
export const RESET_CREDENTIALS = "RESET_CREDENTIALS";
export const UPDATE_AUTH_ACC_DETAILS = "UPDATE_AUTH_ACC_DETAILS";
export const CHECK_EXPIRED_SESSION = "CHECK_EXPIRED_SESSION";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";
export const SIGNUP_ENABLED = "SIGNUP_ENABLED";
export const AUTH_GIVE_CONSENT_REQUEST = "AUTH_GIVE_CONSENT_REQUEST";
const AUTH_GIVE_CONSENT_FAILURE = "AUTH_GIVE_CONSENT_FAILURE";

export const OAUTH2_REQUEST = "OAUTH2_REQUEST";
export const OAUTH2_SUCCESS = "OAUTH2_SUCCESS";
export const OAUTH2_FAILURE = "OAUTH2_FAILURE";
export const OAUTH2_REFRESH_TOKEN_REQUEST = "OAUTH2_REFRESH_TOKEN_REQUEST";
export const OAUTH2_REFRESH_TOKEN_SUCCESS = "OAUTH2_REFRESH_TOKEN_SUCCESS";
export const OAUTH2_REFRESH_TOKEN_FAILURE = "OAUTH2_REFRESH_TOKEN_FAILURE";

export const initiateOauth = createAction(OAUTH2_REQUEST);
export const initiateOauthSuccess = createAction(OAUTH2_SUCCESS);
export const initiateOauthFailure = createAction(OAUTH2_FAILURE);

export const refreshTokenOauth = createAction(OAUTH2_REFRESH_TOKEN_REQUEST);
export const refreshTokenOauthSuccess = createAction(
  OAUTH2_REFRESH_TOKEN_SUCCESS
);
export const refreshTokenOauthFailure = createAction(
  OAUTH2_REFRESH_TOKEN_FAILURE
);

export const register = createAction(REGISTER_REQUEST);
export const registerSuccess = createAction(REGISTER_SUCCESS);
export const registerFailure = createAction(REGISTER_FAILURE);
export const setUserName = createAction(SET_USERNAME);

export const setPassword = createAction(SET_PASSWORD_REQUEST);
export const setPasswordSuccess = createAction(SET_PASSWORD_SUCCESS);
export const setPasswordFailure = createAction(SET_PASSWORD_FAILURE);

export const loginUser = createAction(LOGIN_REQUEST);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailure = createAction(LOGIN_FAILURE);

export const userConsentNeeded = createAction(USER_CONSENT_NEEDED);
export const userConsentCancelled = createAction(USER_CONSENT_CANCELLED);
export const updateAuthUserDetails = createAction(UPDATE_AUTH_ACC_DETAILS);

export const giveConsent = createAction(AUTH_GIVE_CONSENT_REQUEST);
export const giveConsentFailure = createAction(AUTH_GIVE_CONSENT_FAILURE);

export const setAuthError = createAction(SET_AUTH_ERROR);

export const clearRegisterSuccess = () => ({
  type: CLEAR_REGISTER_SUCCESS
});

export const recoverExpiredSession = () => dispatch => {
  const { message, hasExpiredSession } = tryRecoverExpiredSession();
  return dispatch({
    type: CHECK_EXPIRED_SESSION,
    hasExpiredSession,
    message
  });
};

export const login = ({ userName, password }, redirect = "/") => dispatch => {
  dispatch(clearMessages());
  const { hasExpiredSession, lastActiveUrl } = tryRecoverExpiredSession();
  dispatch(
    loginUser({
      userName,
      password,
      hasExpiredSession,
      lastActiveUrl,
      redirect
    })
  );
};

export const setEnabledSignup = signUpEnabled => ({
  type: SIGNUP_ENABLED,
  payload: {
    signUpEnabled
  }
});

export const loadAuth = account => dispatch => {
  if (account) {
    dispatch({
      type: LOAD_AUTH_SUCCESS,
      body: account
    });
  }
};

export const resetCredentials = () => ({
  type: RESET_CREDENTIALS
});

export const clearCredentials = () => dispatch => {
  authUtils.clearCredentials();
  return dispatch(resetCredentials());
};

const initialView = {
  register: {
    isLoading: false,
    registerSuccess: false,
    error: null
  },
  verify: {
    verifyComplete: false,
    isLoading: false,
    error: null,
    userName: null
  },
  password: {
    isLoading: false,
    success: false,
    error: null
  }
};

export const initialState = {
  isLoading: false,
  signUpEnabled: false,
  permissions: { objects: {} },
  credentials: {},
  account: {},
  user: {},
  userConsentNeeded: false,
  error: null,
  warning: null,
  view: initialView
};

export default handleActions(
  {
    [OAUTH2_REQUEST]: state =>
      R.evolve({ isLoading: R.T, error: R.always(null) }, state),

    [OAUTH2_SUCCESS]: state =>
      R.evolve({ isLoading: R.F, error: R.always(null) }, state),

    [OAUTH2_FAILURE]: (state, { payload }) =>
      R.evolve({ isLoading: R.F, error: R.always(payload) }, state),

    [CLEAR_REGISTER_SUCCESS]: state =>
      R.set(R.lensPath(["view", "register", "registerSuccess"]), false, state),

    [SIGNUP_ENABLED]: (state, { payload: { signUpEnabled } }) =>
      R.assoc("signUpEnabled", signUpEnabled, state),

    [UPDATE_AUTH_ACC_DETAILS]: (state, { payload }) =>
      R.evolve({ user: R.mergeDeepLeft(payload) }, state),

    [LOGIN_REQUEST]: state =>
      R.evolve({ isLoading: R.T, error: R.always(null) }, state),

    [LOGIN_SUCCESS]: (state, { payload: { user, credentials, permissions } }) =>
      R.evolve(
        {
          isLoading: R.F,
          credentials: R.always(credentials),
          user: R.always(user),
          permissions: R.always(permissions),
          view: R.always(initialView)
        },
        state
      ),

    [LOGIN_FAILURE]: (state, { payload }) =>
      R.evolve(
        {
          isLoading: R.F,
          error: R.always(payload)
        },
        state
      ),
    [USER_CONSENT_NEEDED]: R.evolve({
      isLoading: R.F,
      error: R.always(null),
      userConsentNeeded: R.T
    }),
    [USER_CONSENT_CANCELLED]: R.evolve({
      userConsentNeeded: R.F
    }),
    [AUTH_GIVE_CONSENT_REQUEST]: R.evolve({
      isLoading: R.T,
      error: null
    }),
    [AUTH_GIVE_CONSENT_FAILURE]: (state, action) =>
      R.evolve({
        isLoading: R.F,
        error: R.always(action.payload)
      }),
    [REGISTER_REQUEST]: state =>
      R.evolve(
        {
          view: {
            register: {
              error: R.always(null),
              isLoading: R.T
            }
          },
          error: R.always(null)
        },
        state
      ),

    [REGISTER_SUCCESS]: state =>
      R.evolve(
        {
          view: {
            register: {
              isLoading: R.F,
              registerSuccess: R.T
            }
          }
        },
        state
      ),

    [REGISTER_FAILURE]: (state, { payload }) =>
      R.evolve(
        {
          view: {
            register: {
              isLoading: R.F,
              registerSuccess: R.F,
              error: R.always(payload)
            }
          }
        },
        state
      ),

    [SET_PASSWORD_REQUEST]: R.set(
      R.lensPath(["view", "password", "isLoading"]),
      true
    ),

    [SET_PASSWORD_SUCCESS]: (
      state,
      { payload: { user, credentials, permissions } }
    ) =>
      R.evolve({
        credentials: R.always(credentials),
        user: R.always(user),
        permissions: R.always(permissions),
        view: {
          password: {
            error: R.always(null),
            isLoading: R.F,
            success: R.T
          }
        }
      })(state),

    [SET_PASSWORD_FAILURE]: (state, { payload }) =>
      R.evolve(
        {
          view: {
            password: {
              isLoading: R.F,
              error: R.always(R.path(["errorMessage", "message"], payload))
            }
          }
        },
        state
      ),

    [SET_USERNAME]: (state, { error, payload }) =>
      R.evolve(
        {
          isLoading: R.F,
          view: {
            verify: {
              userName: R.always(payload),
              isLoading: R.F,
              error: R.always(error)
            }
          }
        },
        state
      ),

    [LOAD_AUTH_SUCCESS]: (state, action) => R.mergeDeepLeft(action.body, state),

    [RESET_CREDENTIALS]: state => R.assoc("credentials", null, state),

    [CHECK_EXPIRED_SESSION]: (state, { message, hasExpiredSession }) =>
      R.assoc("warning", hasExpiredSession ? message : null, state),

    [SET_AUTH_ERROR]: (state, action) => R.assoc("error", action.payload, state)
  },
  initialState
);
