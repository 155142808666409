/**
 * Created by stephenwhite on 12/02/16.
 */
import React, { Component, cloneElement } from "react";
import PropTypes from "prop-types";
import FormIconButton from "components/buttons/form_icon";
import { Dialog } from "components/telenor/dialog";
import { nonEmptyArray } from "utils/general_utils";

export default class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDisabled: false,
      isLoading: false,
      show: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nshow = nextState.show === undefined ? false : nextState.show;
    return (
      nshow !== this.state.show ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.children !== this.props.children ||
      nextState.isLoading !== this.state.isLoading
    );
  }

  open() {
    this.setState({ show: true });
  }

  _handleOpenConfirm() {
    return event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ show: true });
    };
  }

  _handleConfirm() {
    return () => {
      const { confirmAction, skipConfirmCleanup } = this.props;
      const confirmCleanup = () => {
        if (!skipConfirmCleanup) {
          this.setState({
            confirmDisabled: false,
            isLoading: false,
            show: false
          });
        }
      };
      const errorCleanup = () => {
        this.setState({ confirmDisabled: false, isLoading: false });
      };

      const cfpromise = confirmAction();
      if (cfpromise && cfpromise.then) {
        this.setState({ confirmDisabled: true, isLoading: true }, () => {
          cfpromise.then(confirmCleanup).catch(errorCleanup);
        });
      } else {
        this.setState({ confirmDisabled: true }, confirmCleanup);
      }
    };
  }

  render() {
    const { children, title, message, disabled } = this.props;
    const { confirmDisabled, isLoading, show } = this.state;
    const originalButton = nonEmptyArray(children) ? children[0] : children;
    const button = originalButton
      ? cloneElement(originalButton, {
          disabled: disabled,
          onClick: this._handleOpenConfirm()
        })
      : null;

    const handleCancel = () => {
      return this.setState({ show: false });
    };
    const customActions = [
      <FormIconButton
        text="Cancel"
        key="cancel"
        onClick={handleCancel}
        disabled={confirmDisabled || isLoading}
      />,
      <FormIconButton
        text="Confirm"
        color="primary"
        key="confirm"
        data-test="confirm"
        onClick={this._handleConfirm()}
        disabled={confirmDisabled}
        isLoading={isLoading}
      />
    ];

    const dialog = (
      <Dialog
        title={title}
        actions={customActions}
        open={show}
        contentClassName="data-test-confirm-modal"
        onClose={handleCancel}
      >
        {message}
      </Dialog>
    );
    const style = Object.assign(
      { display: "inline-block" },
      this.props.customStyle
    );
    return (
      <div style={style}>
        {button}
        {show ? dialog : null}
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  customStyle: PropTypes.object,
  confirmAction: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  skipConfirmCleanup: PropTypes.bool
};

ConfirmDialog.defaultProps = {
  customStyle: {},
  disabled: false,
  skipConfirmCleanup: false
};
