import WidgetEditResource from "components/widget/edit/widget_edit_resource";
import Joi from "joi-browser";
import WidgetEditSetable from "components/widget/edit/widget_edit_setable";
import { ComposeForm } from "components/widget/edit/compose_form";
import resourceTypes from "../widget_resource_types";
import WidgetEditDecimals from "../../edit/widget_edit_decimals";

export const gaugeFragment = () => ({
  initialModel: {
    min: 0,
    max: 100,
    color_thresholds: []
  },
  fields: [
    {
      id: "min",
      label: "min",
      name: "min",
      hintText: "min",
      isNumberString: true
    },
    {
      id: "max",
      label: "max",
      name: "max",
      hintText: "max",
      isNumberString: true
    },
    {
      id: "color_thresholds",
      name: "color",
      hintText: "color",
      type: "color_thresholds"
    }
  ],
  schema: {
    min: Joi.number()
      .options({ convert: true })
      .required(),
    max: Joi.number()
      .options({ convert: true })
      .required(),
    color_thresholds: Joi.array().items(
      Joi.object({
        threshold: Joi.required(),
        color: Joi.string()
          .empty()
          .required()
      })
    )
  }
});

export default props =>
  ComposeForm(
    WidgetEditResource,
    gaugeFragment,
    WidgetEditSetable,
    WidgetEditDecimals
  )({
    ...props,
    resourceType: resourceTypes.NUMBER
  });
