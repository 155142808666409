import React from "react";
import PropTypes from "prop-types";
import {
  getValueAndPending,
  getDecimalPrecisionValue
} from "utils/widget_utils";
import { widgetProp } from "utils/dashboard_utils";
import PendingValueDisplay from "./pending_value_display";

const valueStyle = { maxWidth: "100%", width: "100%" };

const ValueWidget = ({ resource, unit, isVirtualResource, widget }) => {
  const numberOfDecimals = widgetProp(widget, "numberOfDecimals");
  const { value = "", pending, pendingValue = "" } = getValueAndPending(
    resource,
    isVirtualResource
  );
  return (
    <span style={valueStyle} data-test={`resource__${resource.get("id")}`}>
      {String(getDecimalPrecisionValue(value, numberOfDecimals))}
      {pending === true && (
        <PendingValueDisplay
          displayValue={getDecimalPrecisionValue(
            pendingValue,
            numberOfDecimals
          )}
        />
      )}
      {unit && unit !== "-" ? ` ${unit}` : null}
    </span>
  );
};

ValueWidget.propTypes = {
  resource: PropTypes.object,
  unit: PropTypes.string,
  isVirtualResource: PropTypes.bool,
  widget: PropTypes.object
};

export default ValueWidget;
