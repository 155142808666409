import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -11,
    marginLeft: -12
  },
  colorSecondary: {
    color: grey[700]
  }
}));

export const FormIconButton = ({
  text,
  isLoading,
  disabled,
  icon,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const displayOnlyIcon = !text && !children && icon;
  return (
    <div className={classes.wrapper}>
      {displayOnlyIcon ? (
        <IconButton
          disabled={isLoading || disabled}
          classes={{ colorSecondary: classes.colorSecondary }}
          {...rest}
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          disabled={isLoading || disabled}
          startIcon={icon ? icon : null}
          classes={{ textSecondary: classes.colorSecondary }}
          {...rest}
        >
          {text}
          {children}
        </Button>
      )}
      {isLoading && (
        <CircularProgress
          className={classes.buttonProgress}
          size={displayOnlyIcon ? 24 : 18}
        />
      )}
    </div>
  );
};

FormIconButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  classes: PropTypes.object,
  children: PropTypes.node
};

FormIconButton.defaultProps = {
  color: "secondary",
  isLoading: false,
  disabled: false
};

export default FormIconButton;
