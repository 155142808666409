import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fromJS } from "immutable";
import * as R from "ramda";
import { compose, withProps } from "react-recompose";
import ValueWidget from "./value_widget";

const query = gql`
  query domainMetadataQuery($thingName: String!) {
    thing(thingName: $thingName) {
      thingName
      domainConnection {
        name
        description
        data
      }
    }
  }
`;

export const isDomainMetadataResourceId = resourceId =>
  resourceId ? !!resourceId.match(/^domain./) : false;

export const addDomainMetadataToProps = props => {
  const domainConnection = R.pathOr(
    undefined,
    ["domainMetadataQuery", "thing", "domainConnection"],
    props
  );
  const resourceId = R.pathOr(
    undefined,
    ["widget", "props", "resourceId"],
    props
  );
  if (!resourceId) return props;

  const value = R.pathOr(
    undefined,
    resourceId.split(".").slice(1),
    domainConnection
  );
  return {
    resource: fromJS({ latestValue: { value } })
  };
};

export default compose(
  graphql(query, {
    name: "domainMetadataQuery",
    skip: props => !isDomainMetadataResourceId(props.widget.props.resourceId),
    options: ({ thingName }) => ({ variables: { thingName } })
  }),
  withProps(props => {
    return isDomainMetadataResourceId(props.widget.props.resourceId)
      ? addDomainMetadataToProps(props)
      : props;
  })
)(ValueWidget);
