/* eslint-disable react/no-children-prop */
import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import * as R from "ramda";
import { THING_TYPE_THING_COUNT } from "graphql/queries";
import Chip from "@material-ui/core/Chip";
import { ListNavItem } from "components/telenor";
import { Loading } from "components/notification";
import List from "@material-ui/core/List";

const countToText = count => {
  if (count >= 99) {
    return "99+";
  }
  return count;
};

const ThingTypesList = ({ isLoading, types, activeItem, handleNav }) => {
  const { data, loading: thingCountLoading } = useQuery(
    THING_TYPE_THING_COUNT,
    {
      skip: activeItem == null,
      variables: { thingTypeId: activeItem }
    }
  );
  const thingCount = R.pathOr(0, [
    "thingType",
    "thingsConnection",
    "pageInfo",
    "totalCount"
  ])(data);

  return isLoading ? (
    <Loading />
  ) : (
    <List value={activeItem}>
      {types.map((item, i) => {
        let id = -1;
        let label = "";
        if (item) {
          id = item.id;
          label = item.label;
        }
        return (
          <ListNavItem
            disabled={item.readOnly}
            key={i}
            value={id}
            onClick={handleNav(id)}
            selected={activeItem === id}
            data-test={`select-thing-type__${label}`}
            leftIcon={
              activeItem === id ? (
                thingCountLoading ? (
                  <Loading size={20} />
                ) : (
                  <Chip label={countToText(thingCount)} color="secondary" />
                )
              ) : null
            }
          >
            {label}
          </ListNavItem>
        );
      })}
    </List>
  );
};

ThingTypesList.propTypes = {
  isLoading: PropTypes.bool,
  types: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  activeItem: PropTypes.string,
  handleNav: PropTypes.func,
  handleThingTypeEdit: PropTypes.func,
  userCanEditThingType: PropTypes.bool
};

export default ThingTypesList;
