import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

const OffsetPaginationFooter = ({
  isLoading,
  count,
  page,
  rowsPerPage,
  onChangePage
}) => {
  const classes = useStyles();
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
  const handleFirstPageButtonClick = event => onChangePage(event, 0);
  const handleBackButtonClick = event => onChangePage(event, page - 1);
  const handleNextButtonClick = event => onChangePage(event, page + 1);
  const handleLastPageButtonClick = event => onChangePage(event, lastPage);

  return (
    <div className={classes.root}>
      <IconButton
        disabled={isLoading || page < 1}
        onClick={handleFirstPageButtonClick}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        disabled={isLoading || page < 1}
        onClick={handleBackButtonClick}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLoading || page >= lastPage}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLoading || page >= lastPage}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

OffsetPaginationFooter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default OffsetPaginationFooter;
