export const WIDGET_TYPES = {
  VALUE: "Value",
  GAUGE: "Gauge",
  MAP: "Map",
  TABLE: "Table",
  TIMESERIES: "Timeseries",
  FILES: "Files",
  EVENTS: "Events",
  DYNAMIC: "Dynamic",
  PROCESS: "Process",
  RESOURCEOVERVIEW: "ResourceOverview"
};

export default {
  detail: [
    { label: "Value", id: "Value", value: "Value", index: 0 },
    { label: "Gauge", id: "Gauge", value: "Gauge", index: 1 },
    { label: "Map", id: "Map", value: "Map", index: 2 },
    { label: "Table", id: "Table", value: "Table", index: 3 },
    { label: "Time Series", id: "Timeseries", value: "Timeseries", index: 4 },
    { label: "Files", id: "Files", value: "Files", index: 5 },
    { label: "Events", id: "Events", value: "Events", index: 6 },
    { label: "Dynamic", id: "Dynamic", value: "Dynamic", index: 7 },
    { label: "Process", id: "Process", value: "Process", index: 8 },
    {
      label: "Resource overview",
      id: "ResourceOverview",
      value: "ResourceOverview",
      index: 9
    }
  ],
  collection: [
    {
      label: "Thing Credentials",
      id: "ThingCredentials",
      value: "ThingCredentials",
      index: 0
    },
    {
      label: "Pie Chart Histogram",
      id: "Histogram",
      value: "Histogram",
      description:
        "The Pie Chart displays how many things you have in different intervals. Enter the trigger size",
      index: 1
    },
    {
      label: "Things Map Aggregations",
      id: "ThingsMap",
      value: "ThingsMap",
      index: 2
    },
    { label: "Events", id: "Events", value: "Events", index: 3 },
    {
      label: "Gauge Aggregation",
      id: "GaugeAggregation",
      value: "GaugeAggregation",
      index: 4
    },
    {
      label: "Value Aggregation",
      id: "ValueAggregation",
      value: "ValueAggregation",
      index: 5
    },
    {
      label: "Time Series Aggregation",
      id: "TimeseriesAggregation",
      value: "TimeseriesAggregation",
      index: 6
    },
    {
      label: "Table Aggregation",
      id: "TableAggregation",
      value: "TableAggregation",
      index: 7
    },
    { label: "Files", id: "Files", value: "Files", index: 8 },
    { label: "Things List", id: "AllThings", value: "AllThings", index: 9 }
  ]
};
