import { createSelectorCreator, defaultMemoize } from "reselect";
import { equals } from "ramda";
import { isImmutable } from "./immutable_utils";

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  (a, b) => {
    if (isImmutable(a)) {
      return a.equals(b);
    } else {
      return equals(a, b);
    }
  }
);
