import { fromJS } from "immutable";
import CrudDuck from "./crud_duck";
import { Widget } from "./records";

const UPDATE_COLUMNS = "UPDATE_COLUMNS";
const customActions = {
  updateColumns: ({ widgetId, columns }) => ({
    type: UPDATE_COLUMNS,
    widgetId,
    columns
  })
};

const customReducers = {
  [UPDATE_COLUMNS]: (state, { widgetId, columns }) => {
    if (widgetId) {
      state = state.setIn([widgetId, "props", "columnConfig"], fromJS(columns));
    }
    return state;
  }
};

export const widgetCrudDuck = new CrudDuck("widgets", Widget, {
  aggregateSuccess: "DASHBOARDS_SUCCESS",
  relationships: ["props"],
  customReducers,
  customActions
});

export default widgetCrudDuck;
