import { startOfDay, sub as dateSubtract } from "date-fns";
import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { convertObservationPeriodAsDates } from "../../utils/view_utils";

const SET_PERIOD = "SET_PERIOD";
const SET_PERIOD_RANGE = "SET_PERIOD_RANGE";

export const setPeriod = (type, date) => ({
  type: SET_PERIOD,
  payload: { type, date }
});

export const setPeriodRange = range => ({
  type: SET_PERIOD_RANGE,
  range
});

export const initialState = fromJS({
  from: startOfDay(dateSubtract(new Date(), { days: 7 })),
  to: new Date()
});

export default handleActions(
  {
    [SET_PERIOD]: (state, { payload }) =>
      state.mergeDeep({
        [payload.type]: payload.date
      }),
    [SET_PERIOD_RANGE]: (state, { range }) => {
      const fromTo = convertObservationPeriodAsDates(range);
      return state.mergeDeep({
        ...fromTo
      });
    }
  },
  initialState
);
