import Avatar from "@material-ui/core/Avatar";
import grey from "@material-ui/core/colors/grey";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InfoIcon from "@material-ui/icons/Info";
import FormIconButton from "components/buttons/form_icon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router";
import { compose } from "react-recompose";

const SmallAvatar = withStyles({
  avatar: { margin: "2px", width: "24px", height: "24px" }
})(({ classes, ...rest }) => <Avatar className={classes.avatar} {...rest} />);

const MenuButton = ({ image, name, onClick }) => (
  <FormIconButton
    data-test="nav_profilenav"
    text={
      <React.Fragment>
        {name} <ArrowDropDown />
      </React.Fragment>
    }
    color={"default"}
    icon={<SmallAvatar src={image} />}
    onClick={onClick}
  />
);

MenuButton.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func
};

const useStyles = makeStyles(theme => ({
  linkContainer: {
    textDecoration: "none"
  },
  linkContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  menuItemText: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.fontSize * 1.25
  },
  menuItemIcon: {
    fontSize: theme.typography.fontSize * 2
  }
}));

const ProfileNav = ({ image, name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const classes = useStyles();

  return (
    <React.Fragment>
      <MenuButton image={image} name={name} onClick={handleClick} />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/profile"
          dataTest="nav_profile"
        >
          <AccountCircleIcon
            className={classes.menuItemIcon}
            style={{ color: grey[500] }}
          />
          <Typography className={classes.menuItemText}>Your Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/about"
          dataTest="nav_about"
        >
          <InfoIcon
            className={classes.menuItemIcon}
            style={{ color: grey[500] }}
          />
          <Typography className={classes.menuItemText}>About</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/logout"
          data-test="nav_logout"
        >
          <ExitToAppIcon
            className={classes.menuItemIcon}
            style={{ color: grey[500] }}
          />
          <Typography className={classes.menuItemText}>Log out</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

ProfileNav.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string
};

export default compose(ProfileNav);
