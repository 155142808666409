import React from "react";
import Dialog from "@material-ui/core/Dialog";
import ConsentDialogContainer from "./consent_dialog_container";
import PropTypes from "prop-types";

const ConsentDialogModal = ({
  consentLabel,
  handleConsent,
  handleCancel,
  open
}) => (
  <Dialog open={open} fullWidth={true} maxWidth="lg">
    <ConsentDialogContainer
      consentLabel={consentLabel}
      handleConsent={handleConsent}
      handleCancel={handleCancel}
    />
  </Dialog>
);

ConsentDialogModal.propTypes = {
  consentLabel: PropTypes.string,
  handleConsent: PropTypes.func,
  handleCancel: PropTypes.func,
  open: PropTypes.bool
};

export default ConsentDialogModal;
