import { handleActions, createAction } from "redux-actions";
import {
  always,
  assocPath,
  evolve,
  lensPath,
  over,
  T,
  F,
  isNil,
  when,
  pathSatisfies
} from "ramda";
import {
  getFileUrlStatePath,
  newWidgetState,
  scopePath
} from "utils/bucket_utils";

export const BUCKET_UPLOAD_REQUEST = "BUCKET_UPLOAD_REQUEST";
export const BUCKET_UPLOAD_SUCCESS = "BUCKET_UPLOAD_SUCCESS";
export const BUCKET_UPLOAD_FAILURE = "BUCKET_UPLOAD_FAILURE";

export const BUCKET_GET_REQUEST = "BUCKET_GET_REQUEST";
export const BUCKET_GET_SUCCESS = "BUCKET_GET_SUCCESS";
export const BUCKET_GET_FAILURE = "BUCKET_GET_FAILURE";

export const BUCKET_INIT_WIDGET = "BUCKET_INIT_WIDGET";
export const BUCKET_SET_CURRENT_SCOPE = "BUCKET_SET_CURRENT_SCOPE";
export const BUCKET_SET_PREFIX = "BUCKET_SET_PREFIX";
export const BUCKET_SET_SELECTED = "BUCKET_SET_SELECTED";
export const BUCKET_SET_THING_NAME = "BUCKET_SET_THING_NAME";
export const BUCKET_SET_THING_TYPE = "BUCKET_SET_THING_TYPE ";
export const BUCKET_SET_SELECTED_ROWS = "BUCKET_SET_SELECTED_ROWS";

export const initWidget = createAction(BUCKET_INIT_WIDGET);
export const listSetPrefix = createAction(BUCKET_SET_PREFIX);
export const listSetSelected = createAction(BUCKET_SET_SELECTED);
export const listSetThingType = createAction(BUCKET_SET_THING_TYPE);
export const listSetThingName = createAction(BUCKET_SET_THING_NAME);
export const setCurrentScope = createAction(BUCKET_SET_CURRENT_SCOPE);
export const setSelectedRows = createAction(BUCKET_SET_SELECTED_ROWS);

export const get = createAction(BUCKET_GET_REQUEST);
export const getSuccess = createAction(BUCKET_GET_SUCCESS);
export const getFailure = createAction(BUCKET_GET_FAILURE);

export const upload = createAction(BUCKET_UPLOAD_REQUEST);
export const uploadSuccess = createAction(BUCKET_UPLOAD_SUCCESS);
export const uploadFailure = createAction(BUCKET_UPLOAD_FAILURE);

export const initialState = {
  request: {
    isLoading: false,
    error: ""
  },
  results: [],
  entities: {
    fileUrls: {
      root: {},
      public: {},
      thingName: {},
      thingType: {}
    },
    paging: {},
    files: {},
    owners: {},
    buckets: {},
    widgets: {}
  }
};

export default handleActions(
  {
    [BUCKET_GET_SUCCESS]: (state, { payload }) =>
      assocPath(
        ["entities", "fileUrls", ...getFileUrlStatePath(payload)],
        payload.url,
        state
      ),
    [BUCKET_UPLOAD_REQUEST]: (state, { payload: { widgetId } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({ request: { isLoading: T } }),
        state
      ),
    [BUCKET_UPLOAD_SUCCESS]: (state, { payload: { widgetId } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({ request: { isLoading: F } }),
        state
      ),
    [BUCKET_UPLOAD_FAILURE]: (state, { payload: { widgetId } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({ request: { isLoading: F } }),
        state
      ),

    [BUCKET_INIT_WIDGET]: (
      state,
      { payload: { widgetId, initialScope, thingName, thingType } }
    ) => {
      const widgetPath = ["entities", "widgets", widgetId];
      return when(
        pathSatisfies(isNil, widgetPath),
        assocPath(
          widgetPath,
          newWidgetState(initialScope, thingName, thingType)
        ),
        state
      );
    },
    [BUCKET_SET_CURRENT_SCOPE]: (state, { payload: { widgetId, scope } }) =>
      assocPath(
        ["entities", "widgets", widgetId, "currentScope"],
        scope
      )(state),

    [BUCKET_SET_PREFIX]: (state, { payload: { widgetId, prefix } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({ prefix: always(prefix) }),
        state
      ),

    [BUCKET_SET_SELECTED]: (state, { payload: { widgetId, selected } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({ selected: always(selected) }),
        state
      ),

    [BUCKET_SET_THING_TYPE]: (state, { payload: { widgetId, thingType } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({
          selected: always(thingType),
          thingType: always(thingType)
        }),
        state
      ),

    [BUCKET_SET_THING_NAME]: (state, { payload: { widgetId, thingName } }) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({
          selected: always(thingName),
          thingName: always(thingName)
        }),
        state
      ),

    [BUCKET_SET_SELECTED_ROWS]: (
      state,
      { payload: { widgetId, selectedRows } }
    ) =>
      over(
        lensPath(scopePath(state, widgetId)),
        evolve({ selectedRows: always(selectedRows) }),
        state
      )
  },
  initialState
);
