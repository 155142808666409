import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  lifecycle,
  compose,
  withHandlers,
  setPropTypes,
  withProps,
  withState
} from "react-recompose";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { assoc, propEq, pathOr, pipe, match, ifElse, nth, always } from "ramda";
import { manifest } from "api/aws/manifest";
import { register, clearRegisterSuccess, authSelectors } from "ducks/auth";
import { generateSignupSchema } from "forms_new/user/schemas";
import { Signup } from "forms_new/user";
import { Card } from "components/cards";
import { Label } from "components/label";
import ConsentDialogModal from "components/consent_dialog/consent_dialog_modal";
import { getStyles } from "./index.style";
import { nonEmptyArray } from "utils/general_utils";
import { Snackbar } from "@material-ui/core";
import * as R from "ramda";
import Button from "@material-ui/core/Button";

const _defaultSelectors = {
  registerErrorSelector: authSelectors.registerErrorSelector,
  registerIsLoadingSelector: authSelectors.registerIsLoadingSelector,
  registerSuccessSelector: authSelectors.registerSuccessSelector
};
export const _makeMapStateToProps = (
  selectors = _defaultSelectors
) => state => ({
  error: selectors.registerErrorSelector(state),
  isLoading: selectors.registerIsLoadingSelector(state),
  registerSuccess: selectors.registerSuccessSelector(state)
});

export const _mapDispatchToProps = dispatch =>
  bindActionCreators({ register, clearRegisterSuccess }, dispatch);

export const _withHandlers = withHandlers({
  onCreate: ({ register, awsMarketplaceToken }) => user => {
    register({ user, awsMarketplaceToken });
  },
  onShowConsentDialog: ({ setShowConsentDialog, setUser }) => user => {
    setUser(assoc("consent", true, user));
    setShowConsentDialog(true);
  },
  onHideConsentDialog: ({ setShowConsentDialog }) => () => {
    setShowConsentDialog(false);
  }
});

const _consentNeeded = propEq("ConsentRequired", "true");

export const _getInitialAwsMarketplaceToken = pipe(
  pathOr("", ["router", "location", "search"]),
  match(/x-amzn-marketplace-token=([^&]+)/),
  ifElse(nonEmptyArray, pipe(nth(1), decodeURIComponent), always(null))
);

export const _withProps = _manifest =>
  withProps(() => ({
    styles: getStyles(),
    signupSchema: generateSignupSchema(),
    consentNeeded: _consentNeeded(_manifest ? _manifest : manifest)
  }));

export const _consentDialogState = withState(
  "showConsentDialog",
  "setShowConsentDialog",
  false
);

export const _createdUserState = withState("user", "setUser", {});

export const _awsMarketplaceTokenState = withState(
  "awsMarketplaceToken",
  "setAwsMarketplaceToken",
  _getInitialAwsMarketplaceToken
);

export const _lifecycle = lifecycle({
  componentWillUnmount() {
    const { clearRegisterSuccess } = this.props;
    clearRegisterSuccess();
  }
});

export const SignupContainer = ({
  onCreate,
  registerSuccess,
  isLoading,
  signupSchema,
  styles,
  showConsentDialog,
  onShowConsentDialog,
  onHideConsentDialog,
  consentNeeded,
  user,
  error
}) => {
  const { errorMessage: { message } = {} } = error || {};

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMessage(null);
  };

  const handleErrorMessage = err => {
    setErrorMessage(err);

    if (!R.isEmpty(errorMessage)) {
      setOpen(true);
    }
  };

  useEffect(() => {
    handleErrorMessage(message);
  }, [message]);

  return (
    <div style={styles.cardContainer}>
      <Label center type="h3">
        Sign up
      </Label>
      {registerSuccess && (
        <p>
          Your sign up was successful. Please wait and check your email inbox
          for a confirmation link.
        </p>
      )}
      {!registerSuccess && (
        <Card noHeader>
          <Signup
            initialModel={{}}
            editRole
            isEditing
            onSubmit={consentNeeded ? onShowConsentDialog : onCreate}
            isLoading={isLoading}
            signupSchema={signupSchema}
          />
          <ConsentDialogModal
            open={showConsentDialog && !isLoading}
            consentLabel={"Proceed"}
            handleConsent={() => onCreate(user)}
            handleCancel={onHideConsentDialog}
          />
        </Card>
      )}
      {!registerSuccess && message != null && (
        <Snackbar
          data-test="signup-snack-bar"
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errorMessage || ""}
          action={[
            <Button
              key="close"
              onClick={handleClose}
              color="secondary"
              size="small"
            >
              Close
            </Button>
          ]}
        />
      )}
    </div>
  );
};

SignupContainer.defaultProps = {
  consentNeeded: false
};

SignupContainer.propTypes = {
  onCreate: PropTypes.func.isRequired,
  signupSchema: PropTypes.object,
  registerSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  consentNeeded: PropTypes.bool,
  showConsentDialog: PropTypes.bool,
  onShowConsentDialog: PropTypes.func,
  onHideConsentDialog: PropTypes.func,
  styles: PropTypes.object,
  user: PropTypes.object,
  error: PropTypes.object
};

export default compose(
  connect(_makeMapStateToProps(), _mapDispatchToProps),
  withRouter,
  setPropTypes({
    error: PropTypes.object,
    registerSuccess: PropTypes.bool,
    muiTheme: PropTypes.object
  }),
  _consentDialogState,
  _createdUserState,
  _awsMarketplaceTokenState,
  _withHandlers,
  _withProps(),
  _lifecycle
)(SignupContainer);
