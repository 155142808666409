import styled from "styled-components";

export default styled.article`
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  border: 0;
  flex: 3 1 80%;
  order: 2;

  @media all and (max-width: 320px) {
    /* Return them to document order */
    order: 0;
  }
`;
