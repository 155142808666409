import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import TextField from "@material-ui/core/TextField";
import {
  Autocomplete as MUIAutocomplete,
  createFilterOptions
} from "@material-ui/lab";
import PropTypes from "prop-types";
import * as R from "ramda";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { debounce } from "../../utils/execution_utils";

export const DATA_TYPES = {
  RESOURCES: "RESOURCES",
  ALL_RESOURCES: "ALL_RESOURCES",
  THINGS: "THINGS",
  SINGLE_THINGS: "SINGLE_THINGS",
  THING_TYPES: "THING_TYPES",
  MULTI_THING_TYPES: "MULTI_THING_TYPES",
  USERS: "USERS",
  DOMAINS: "DOMAINS",
  MULTI_DOMAINS: "MULTI_DOMAINS",
  ADDITIONAL_ROLES: "ADDITIONAL_ROLES"
};

const THING_TYPES = gql`
  {
    allThingTypes {
      thingTypes {
        id
        label
        domain
        description
        readOnly
      }
    }
  }
`;

const DOMAINS = gql`
  query($searchTerm: String!, $pageSize: Int!, $topDomainId: String) {
    search(
      searchOptions: {
        searchTerm: $searchTerm
        filter: { objectType: DOMAIN, topDomain: $topDomainId }
        sort: { field: "score", order: desc }
        paging: { size: $pageSize }
      }
    ) {
      result {
        __typename
        ... on DomainResponse {
          id
          name
          path
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

const RESOURCES = gql`
  query($searchTerm: String!, $thingTypeId: String) {
    search(
      searchOptions: {
        searchTerm: $searchTerm
        filter: { objectType: RESOURCE, thingType: $thingTypeId }
        sort: { field: "score", order: desc }
      }
    ) {
      result {
        __typename
        ... on ResourceResponse {
          id
          name
          dataType
        }
      }
    }
  }
`;

const USERS = gql`
  query {
    allUsers(searchOptions: { paging: { size: 9999 } }) {
      pageInfo {
        totalCount
      }
      users {
        id
        userName
      }
    }
  }
`;

const SingleAutocomplete = ({
  id,
  label,
  "data-test": dataTest,
  changeHandler,
  defaultValue,
  errorText,
  helperText,
  defaultOptions,
  customRefetch,
  loading,
  getOptionLabel,
  hintText,
  fullWidth = false,
  customChangeHandler = null, // make this default later
  filterOptions,
  renderOption,
  getOptionDisabled
}) => {
  const [open, setOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({ id: defaultValue });

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  // Update the label when defaultValue or options change
  useEffect(() => {
    const valueFromOptions =
      options.length > 0 ? options.find(o => o.id === defaultValue) : undefined;
    const newValue =
      valueFromOptions !== undefined ? valueFromOptions : { id: defaultValue };
    if (defaultValue !== value.id && valueFromOptions === undefined) {
      customRefetch(defaultValue);
    }
    setValue(newValue);
  }, [defaultValue]);

  useEffect(() => {
    if (options.length > 0 && value.id === defaultValue && !open) {
      const val = options.find(o => o.id === defaultValue);
      if (val) setValue(val);
    }
  }, [options]);

  return (
    <MUIAutocomplete
      id={id}
      open={open}
      value={value}
      data-test={dataTest}
      options={options}
      loading={loading}
      fullWidth={fullWidth}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setDirty(true);
      }}
      getOptionSelected={(option, _value) => {
        return _value ? option.id === _value.id : false;
      }}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      renderInput={params => {
        const newParams = {
          ...params,
          inputProps: {
            ...params?.inputProps,
            "data-test": dataTest
          }
        };
        return (
          <TextField
            {...newParams}
            label={label}
            error={dirty && errorText != ""}
            helperText={errorText || helperText}
            placeholder={hintText}
          />
        );
      }}
      onChange={(event, _value, _reason) => {
        if (_reason === "select-option") {
          setValue(_value);
          // ugly, but refactor this later to one nice looking thing
          if (customChangeHandler) customChangeHandler(id, _value);
          else changeHandler(id, _value ? _value.id : null);
        }
      }}
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          customRefetch(value);
        }
      }}
      filterOptions={filterOptions}
      renderOption={renderOption}
    />
  );
};

const MultiAutocomplete = ({
  id,
  defaultOptions,
  getOptionLabel,
  label,
  hintText,
  helperText,
  changeHandler,
  getOptionSelected,
  renderOption,
  getOptionDisabled,
  filterOptions,
  customRefetch,
  defaultValue,
  value
}) => {
  return (
    <MUIAutocomplete
      multiple
      filterSelectedOptions
      id={id}
      value={value}
      options={defaultOptions}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={hintText}
          helperText={helperText}
        />
      )}
      defaultValue={defaultValue}
      onChange={(event, _value) => {
        changeHandler(_value);
      }}
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          customRefetch(value);
        }
      }}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
    />
  );
};

const DOMAIN_PAGE_SIZE = 100;
const INVALID_ID = -1;

const AdditionalResultsOption = () => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="body2" color="textSecondary">
        More results available, refine your search if you can not find what you
        are looking for
      </Typography>
    </Grid>
  </Grid>
);

const buildOptions = (data, itemRenderCount, totalCount) => {
  if (totalCount <= itemRenderCount) return data;

  return [...data, { id: INVALID_ID }];
};

const filterDomainOptions = (options, state) =>
  createFilterOptions({
    stringify: ({ path, id, name }) => `${path || id}${name ? ` ${name}` : ""}`
  })(options, state);

const DomainAutocomplete = props => {
  const domain = R.path(["thingType", "domain"], props);
  const { loading, data, refetch } = useQuery(DOMAINS, {
    variables: {
      searchTerm: "",
      pageSize: DOMAIN_PAGE_SIZE,
      topDomainId: domain
    }
  });

  const Comp = R.has("multiple", props)
    ? MultiAutocomplete
    : SingleAutocomplete;

  const debouncedRefetch = debounce(refetch, 300);

  return (
    <Comp
      {...props}
      defaultOptions={buildOptions(
        R.pathOr([], ["search", "result"], data),
        DOMAIN_PAGE_SIZE,
        R.path(["search", "pageInfo", "totalCount"], data)
      )}
      loading={loading}
      customRefetch={value => debouncedRefetch({ searchTerm: value })}
      getOptionLabel={option => option.path || option.id || ""}
      filterOptions={filterDomainOptions}
      getOptionDisabled={option => option.id === INVALID_ID}
      renderOption={option => {
        return option.id === INVALID_ID ? (
          <AdditionalResultsOption />
        ) : (
          <Grid container alignItems="center">
            <Grid item xs>
              <span>{option.path || option.id || ""}</span>

              <Typography variant="body2" color="textSecondary">
                {option.name}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

const ThingTypeAutocomplete = props => {
  return (
    <Query query={THING_TYPES}>
      {({ error, loading, data }) => {
        return (
          <SingleAutocomplete
            {...props}
            defaultOptions={R.pathOr([], ["allThingTypes", "thingTypes"], data)
              .slice()
              .sort((a, b) => (a.label > b.label ? 1 : -1))}
            loading={loading}
            // eslint-disable-next-line
            customRefetch={console.log.bind(
              null,
              "will not refetch, need to implement search to thingtypes first"
            )}
            getOptionLabel={option => option.label || option.id || ""}
          />
        );
      }}
    </Query>
  );
};

const ThingsAutocomplete = props => {
  return (
    <MultiAutocomplete
      {...props}
      getOptionLabel={option => option.text || option.id || ""}
    />
  );
};

const SingleThingsAutocomplete = props => {
  return (
    <SingleAutocomplete
      {...props}
      getOptionLabel={option => option.text || option.id || ""}
      getOptionSelected={(option, value) => {
        return option?.id && value?.id ? option.id === value.id : false;
      }}
    />
  );
};

const removeThingTypeId = id => {
  // This slices away the initial thingTypeId in the id. 98/subthing/res -> subthing/res
  return id
    .split("/")
    .slice(1)
    .join("/");
};

const AllResourcesAutocomplete = props => {
  return (
    <Query
      query={RESOURCES}
      variables={{
        searchTerm: "",
        ...(props?.thingTypeId ? { thingTypeId: props?.thingTypeId } : {})
      }}
    >
      {({ loading, data, refetch }) => {
        return (
          <SingleAutocomplete
            {...props}
            loading={loading}
            defaultOptions={R.pathOr([], ["search", "result"], data)}
            customChangeHandler={(id, value) => {
              props?.changeHandler(id, value);
            }}
            getOptionLabel={option => {
              return props?.thingTypeId && option.id
                ? removeThingTypeId(option.id)
                : option.id || "";
            }}
            customRefetch={value => {
              return refetch({
                searchTerm: value,
                ...(props?.thingTypeId
                  ? { thingTypeId: props?.thingTypeId }
                  : {})
              });
            }}
          />
        );
      }}
    </Query>
  );
};

AllResourcesAutocomplete.propTypes = {
  thingTypeId: PropTypes.string,
  changeHandler: PropTypes.func
};

const ResourceAutocomplete = connect(state => ({
  reduxThingType: R.pathOr(
    null,
    ["editingThingType", "id"],
    state.thingTypes.toJSON()
  )
}))(props => {
  return (
    <Query
      query={RESOURCES}
      skip={!props.reduxThingType}
      variables={{
        searchTerm: "",
        ...(props?.reduxThingType ? { thingTypeId: props?.reduxThingType } : {})
      }}
    >
      {({ loading, data, refetch }) => {
        return (
          <SingleAutocomplete
            {...props}
            loading={loading}
            defaultOptions={R.pathOr([], ["search", "result"], data)}
            changeHandler={(a, b) => {
              props.changeHandler(a, b);
            }}
            getOptionLabel={option => {
              return option.id ? removeThingTypeId(option.id) : option.id || "";
            }}
            customRefetch={value => {
              return refetch({
                searchTerm: value,
                ...(props?.reduxThingType
                  ? { thingTypeId: props?.reduxThingType }
                  : {})
              });
            }}
          />
        );
      }}
    </Query>
  );
});

const UsersAutocomplete = props => {
  return (
    <Query query={USERS}>
      {({ loading, data }) => {
        return (
          <SingleAutocomplete
            loading={loading}
            defaultOptions={R.pathOr([], ["allUsers", "users"], data)}
            getOptionLabel={option => {
              return option.userName || option.id || "";
            }}
            // eslint-disable-next-line
            customRefetch={console.log.bind(
              null,
              "will not refetch, need to implement search to thingtypes first"
            )}
            customChangeHandler={(_id, _value) => {
              props?.changeHandler(_id, _value);
            }}
            {...props}
          />
        );
      }}
    </Query>
  );
};

UsersAutocomplete.propTypes = {
  changeHandler: PropTypes.func
};

const AdditionalRolesAutocomplete = props => (
  <MultiAutocomplete
    {...props}
    getOptionLabel={option => option.text || option.id || ""}
    getOptionSelected={(option, value) =>
      option?.id && value?.id ? option.id === value.id : false
    }
    changeHandler={selectedValues =>
      props?.changeHandler(props?.id, selectedValues)
    }
  />
);

AdditionalRolesAutocomplete.propTypes = {
  changeHandler: PropTypes.func,
  id: PropTypes.string
};

const MultiThingTypesAutocomplete = props => {
  return (
    <Query query={THING_TYPES}>
      {({ loading, data }) => {
        return (
          <MultiAutocomplete
            {...props}
            defaultOptions={R.pathOr([], ["allThingTypes", "thingTypes"], data)
              .slice()
              .sort((a, b) => (a.label > b.label ? 1 : -1))}
            loading={loading}
            getOptionLabel={option => option.label || option.id || ""}
          />
        );
      }}
    </Query>
  );
};

const Autocomplete = ({ type, ...rest }) => {
  switch (type) {
    case DATA_TYPES.DOMAINS:
      return <DomainAutocomplete {...rest} />;
    case DATA_TYPES.MULTI_DOMAINS:
      return <DomainAutocomplete {...rest} multiple />;
    case DATA_TYPES.THING_TYPES:
      return <ThingTypeAutocomplete {...rest} />;
    case DATA_TYPES.MULTI_THING_TYPES:
      return <MultiThingTypesAutocomplete {...rest} />;
    case DATA_TYPES.THINGS:
      return <ThingsAutocomplete {...rest} />;
    case DATA_TYPES.SINGLE_THINGS:
      return <SingleThingsAutocomplete {...rest} />;
    case DATA_TYPES.RESOURCES:
      return <ResourceAutocomplete {...rest} />;
    case DATA_TYPES.ALL_RESOURCES:
      return <AllResourcesAutocomplete {...rest} />;
    case DATA_TYPES.USERS:
      return <UsersAutocomplete {...rest} />;
    case DATA_TYPES.ADDITIONAL_ROLES:
      return <AdditionalRolesAutocomplete {...rest} />;
    default:
      throw Error("No type chosen");
  }
};

Autocomplete.propTypes = {
  type: PropTypes.string
};

export default Autocomplete;

MultiAutocomplete.propTypes = {
  id: PropTypes.string,
  dataTest: PropTypes.string,
  label: PropTypes.string,
  changeHandler: PropTypes.func,
  defaultValue: PropTypes.array,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  defaultOptions: PropTypes.array,
  customRefetch: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  hintText: PropTypes.string,
  renderOption: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  filterOptions: PropTypes.func,
  value: PropTypes.any,
  thingType: PropTypes.shape({
    domain: PropTypes.string
  })
};

SingleAutocomplete.propTypes = {
  id: PropTypes.string,
  "data-test": PropTypes.string,
  label: PropTypes.string,
  changeHandler: PropTypes.func,
  defaultValue: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  defaultOptions: PropTypes.array,
  customRefetch: PropTypes.func,
  loading: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  hintText: PropTypes.string,
  fullWidth: PropTypes.bool,
  customChangeHandler: PropTypes.func,
  filterOptions: PropTypes.func,
  renderOption: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  thingType: PropTypes.shape({
    domain: PropTypes.string
  })
};
