import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { inputElasticThingsFilterSelector } from "ducks/filter/filter_selectors";
import { resourceLabelById } from "ducks/schema/schema_selectors";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, pure, withPropsOnChange } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import { resourceStatePath } from "utils/widget_utils";

const thingStateHistogramQuery = gql`
  query(
    $filter: InputListThingsFilter!
    $resourceStatePath: String!
    $interval: Float!
  ) {
    thingStateHistogram(
      searchOptions: { filter: $filter, resource: $resourceStatePath }
      interval: $interval
    ) {
      buckets {
        key
        count
      }
    }
  }
`;

const THING_STATE_HISTOGRAM_BUCKETS_PATH = [
  "thingStateHistogramQuery",
  "thingStateHistogram",
  "buckets"
];

const mapStateToProps = (state, props) => {
  const { widget } = props;
  const resourceId = resourceIdFromWidget(widget);

  return {
    ...props,
    resourceStatePath: resourceStatePath(props.subthing, resourceId),
    filter: inputElasticThingsFilterSelector(state, props),
    interval: parseInt(widgetProp(widget, "interval"), 10),
    resourceLabel:
      resourceLabelById(state, { id: resourceIdFromWidget(widget) }) ||
      props.resourceName
  };
};

export const asLabeledIntervals = (interval, buckets) =>
  buckets
    .filter(bucket => bucket.count > 0)
    .map(bucket => [
      `${bucket.key}-${bucket.key + interval - 1}`,
      bucket.count
    ]);

const EMPTY_BUCKETS = [];

const withThingStateHistogram = compose(
  connect(mapStateToProps),
  graphql(thingStateHistogramQuery, {
    name: "thingStateHistogramQuery"
  }),
  withPropsOnChange(
    (props, nextProps) =>
      R.path(THING_STATE_HISTOGRAM_BUCKETS_PATH, props) !==
      R.path(THING_STATE_HISTOGRAM_BUCKETS_PATH, nextProps),
    props => ({
      labeledIntervals: asLabeledIntervals(
        props.interval,
        R.pathOr(EMPTY_BUCKETS, THING_STATE_HISTOGRAM_BUCKETS_PATH, props)
      )
    })
  ),
  pure
);

export default withThingStateHistogram;
