import React from "react";
import PropTypes from "prop-types";

const Simulator = ({ device }) => {
  delete device.tcxn;
  return (
    <pre>
      {Object.keys(device).map((key, i) => (
        <pre key={i}>
          {" "}
          {key}: {device[key]}
        </pre>
      ))}
    </pre>
  );
};

Simulator.propTypes = {
  device: PropTypes.object.isRequired
};

export default Simulator;
