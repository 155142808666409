import React from "react";
import PropTypes from "prop-types";
import { ModalFormButtons, Form, FieldSet, Field } from "../";
import { User as UserSchema } from "./schemas";
import FormSave from "components/buttons/form_save";

const ProfileForm = ({ onSubmit, initialModel, isLoading }) => (
  <Form
    schema={UserSchema}
    onSubmit={onSubmit}
    initialModel={initialModel}
    allowUnknown
  >
    <FieldSet>
      <Field id="userName" label="Username" disabled />
      <Field id="firstName" label="First name" />
      <Field id="lastName" label="Last name" />
      <Field id="email" label="E-mail" disabled />
      <Field
        id="phone"
        label="Phone"
        hint="International format like +46 738 123456"
      />
      <Field id="company" label="Company" />
      <Field id="address" label="Address" />
      <Field id="zip" label="Zip" />
      <Field id="city" label="City" />
      <Field id="country" label="Country" />
    </FieldSet>

    <ModalFormButtons>
      <FormSave isLoading={isLoading} />
    </ModalFormButtons>
  </Form>
);

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialModel: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ProfileForm;
