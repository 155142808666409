import { handleActions } from "redux-actions";
import { Request } from "./records";

export const initialState = new Request();

export default handleActions(
  {
    // ---
    DASHBOARDS_REQUEST: state =>
      state.merge({
        isLoading: true,
        error: null
      }),
    DASHBOARDS_SUCCESS: state =>
      state.merge({
        isLoading: false,
        error: null
      }),
    REQUEST_RESET: state =>
      state.merge({
        isLoading: false,
        error: null
      }),
    DASHBOARDS_FAILURE: state =>
      state.merge({
        isLoading: false
      }),

    // ---
    DASHBOARD_REQUEST: state =>
      state.merge({
        isLoading: true,
        error: null
      }),
    DASHBOARD_SUCCESS: state =>
      state.merge({
        isLoading: false,
        error: null
      }),
    DASHBOARD_FAILURE: state =>
      state.merge({
        isLoading: false
      })
  },
  initialState
);

export const selectors = {
  getDashboardRequest: state => state.dashboard.get("request")
};
