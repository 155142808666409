import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import AdHocForm from "../forms/adhoc_form";
import NotifyByEmailForm from "../forms/notify_form";
import ScheduledForm from "../forms/schedule_form";

const Step2 = props => {
  const {
    label,
    errorText,
    isScheduled,
    handleLabelChange,
    handleToggleIsScheduled
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography component={"h6"} variant={"h6"}>
            2. Specify scheduling details and save export
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id={"label"}
            label={"Label the export"}
            value={label}
            placeholder={"My export"}
            fullWidth={true}
            error={errorText !== ""}
            helperText={errorText}
            onBlur={e => handleLabelChange(e.target.value)}
            onChange={e => handleLabelChange(e.target.value)}
            style={{ marginBottom: "12px" }}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <Typography component="h6">Scheduled job</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Switch
                checked={isScheduled}
                onChange={handleToggleIsScheduled}
                data-test="is-schedule-toggle"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isScheduled ? (
            <ScheduledForm {...props} />
          ) : (
            <AdHocForm {...props} />
          )}
        </Grid>
        <Grid item xs={12}>
          <NotifyByEmailForm {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Step2.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  isScheduled: PropTypes.bool,
  handleLabelChange: PropTypes.func,
  handleToggleIsScheduled: PropTypes.func
};

export default Step2;
