import { createAction, handleActions } from "redux-actions";
import { fromJS } from "immutable";
import { requestStatus } from "utils/request_status";

export const CREATE_THINGS_BATCH_REQUEST = "CREATE_THINGS_BATCH_REQUEST";
export const CREATE_THINGS_BATCH_SUCCESS = "CREATE_THINGS_BATCH_SUCCESS";
export const CREATE_THINGS_BATCH_FAILURE = "CREATE_THINGS_BATCH_FAILURE";
export const CREATE_THINGS_BATCH_FINISHED = "CREATE_THINGS_BATCH_FINISHED";

export const LIST_THINGS_BATCHES_REQUEST = "LIST_THINGS_BATCHES_REQUEST";
export const LIST_THINGS_BATCHES_SUCCESS = "LIST_THINGS_BATCHES_SUCCESS";
export const LIST_THINGS_BATCHES_FAILURE = "LIST_THINGS_BATCHES_FAILURE";

export const createThings = createAction(CREATE_THINGS_BATCH_REQUEST);
export const createThingsSuccess = createAction(CREATE_THINGS_BATCH_SUCCESS);
export const createThingsFailure = createAction(CREATE_THINGS_BATCH_FAILURE);

export const listThingBatches = createAction(LIST_THINGS_BATCHES_REQUEST);
export const listThingBatchesSuccess = createAction(
  LIST_THINGS_BATCHES_SUCCESS
);
export const listThingBatchesFailure = createAction(
  LIST_THINGS_BATCHES_FAILURE
);

export const creatingFinished = () => ({
  type: CREATE_THINGS_BATCH_FINISHED
});

const initialState = fromJS({
  createStatus: requestStatus.IDLE,
  isLoading: false,
  error: null
});

export default handleActions(
  {
    [CREATE_THINGS_BATCH_REQUEST]: state =>
      state.merge({
        createStatus: requestStatus.REQUESTED,
        isLoading: true,
        isCreating: true,
        error: null
      }),
    [CREATE_THINGS_BATCH_SUCCESS]: (state, action) =>
      state.merge({
        createStatus: requestStatus.SUCCESSFUL,
        isLoading: false,
        isCreating: false
      }),
    [CREATE_THINGS_BATCH_FAILURE]: (state, action) =>
      state.merge({
        createStatus: requestStatus.FAILURE,
        isLoading: false,
        isCreating: false,
        error: action.payload
      }),
    [LIST_THINGS_BATCHES_REQUEST]: state =>
      state.merge({
        isLoading: true,
        error: null
      }),
    [LIST_THINGS_BATCHES_SUCCESS]: (
      state,
      { payload: { thingTypeId, response } }
    ) =>
      state
        .set("isLoading", false)
        .deleteIn(["thingTypes", thingTypeId, "files"])
        .mergeDeep(
          fromJS({
            thingTypes: {
              [thingTypeId]: response
            }
          })
        ),
    [LIST_THINGS_BATCHES_FAILURE]: (state, action) =>
      state.merge({
        isLoading: false,
        error: action.payload
      }),
    [CREATE_THINGS_BATCH_FINISHED]: (state, action) =>
      state.merge({
        isLoading: false,
        isCreating: false
      })
  },
  initialState
);

export const batchesSelectors = {
  getFiles: (state, thingTypeId) =>
    state.batches.getIn(["thingTypes", thingTypeId, "files"], null),
  isLoading: state => state.batches.get("isLoading"),
  isCreating: state => state.batches.get("isCreating"),
  getCreateStatus: state => state.batches.get("createStatus")
};
