import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const ConfirmDeleteThingDialog = ({ thingName, onDone, open }) => {
  return (
    <Dialog
      open={open}
      onClose={onDone.bind(null, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm delete {thingName}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete thing {thingName}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDone.bind(null, false)} color="secondary" autoFocus>
          Cancel
        </Button>
        <Button onClick={onDone.bind(null, true)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDeleteThingDialog.propTypes = {
  thingName: PropTypes.string,
  onDone: PropTypes.func,
  open: PropTypes.bool
};
