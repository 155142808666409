import Joi from "joi-browser";

export const operators = {
  gt: "> (greater than)",
  gte: ">= (greater than, or equal)",
  lt: "< (less than)",
  lte: "<= (less than, or equal)",
  eq: "== (equals)",
  neq: "!= (not equals)"
};
export default () => ({
  fields: [
    {
      id: "conditions",
      name: "color",
      hintText: "color",
      options: operators,
      type: "color_thresholds_with_operator"
    }
  ],
  initialModel: {
    conditions: {
      resourceId: undefined,
      markerColors: []
    }
  },
  schema: {
    conditions: Joi.object({
      resourceId: Joi.when("markerColors", {
        is: Joi.array().min(1),
        then: Joi.string()
          .required()
          .empty(),
        otherwise: Joi.string()
      }),
      markerColors: Joi.array().items(
        Joi.object({
          operator: Joi.string().valid(...Object.keys(operators)),
          threshold: Joi.string().allow(""),
          color: Joi.string()
            .empty()
            .required()
        })
      )
    })
  }
});
