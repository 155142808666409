import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import FormIconButton from "components/buttons/form_icon";
import { push } from "react-router-redux";
import { replaceLastParamsUntil } from "utils/path_utils";
import BaseWidgetFooterWrapper from "../../widget_footer_wrapper";
import * as imu from "utils/immutable_utils";

const WidgetFooterWrapper = styled(BaseWidgetFooterWrapper)`
  text-align: center;
  min-height: 40px;
`;

export const EventsWidgetFooter = ({ onShowAll }) => {
  return (
    <WidgetFooterWrapper>
      <div style={{ textAlign: "center", width: "100%" }}>
        <FormIconButton text="Show all" onClick={onShowAll} />
      </div>
    </WidgetFooterWrapper>
  );
};

EventsWidgetFooter.propTypes = {
  onShowAll: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    location: { pathname },
    widget,
    params: { thingType, thingName }
  } = ownProps;
  const replaceUntilParam = thingName || thingType; // If we have the thingName then use that other the thingType
  const classification = imu.get(widget, "classification", "").toLowerCase();
  return {
    onShowAll: () => {
      const eventPath = replaceUntilParam
        ? replaceLastParamsUntil(
            replaceUntilParam,
            pathname,
            `events/${classification || "all"}`
          )
        : `${pathname}/events/${classification || "all"}`;

      return dispatch(
        push({
          pathname: eventPath,
          search: null,
          state: { previousPath: pathname }
        })
      );
    }
  };
};

export default connect(null, mapDispatchToProps)(EventsWidgetFooter);
