import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import Joi from "joi-browser";
import { omit } from "ramda";
import PropTypes from "prop-types";
import React from "react";
import { compose, withState } from "react-recompose";
import { Field, FieldSet, Form, ModalFormButtons } from "../";
import { networkLabelMappings } from "./create";

const ThingSchema = Joi.object().keys({
  thingName: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  externalId: Joi.string()
    .empty("")
    .trim()
    .allow(null),
  protocol: Joi.string()
    .empty("")
    .trim()
    .allow(null),
  imsi: Joi.string()
    .empty("")
    .trim()
    .allow(null),
  imei: Joi.string()
    .empty("")
    .trim()
    .allow(null),
  label: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  description: Joi.string()
    .empty("")
    .trim()
    .allow(null),
  domain: Joi.string()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  simulated: Joi.boolean()
});

const EditThingForm = ({
  initialModel,
  handleSubmit,
  handleCancel,
  data: { supportedThingProtocols },
  formModel,
  setFormModel,
  isLoading
}) => (
  <Form
    initialModel={initialModel}
    forceModel={formModel}
    schema={ThingSchema}
    onSubmit={formData => handleSubmit(omit(["protocol"], formData))}
    allowUnknown
    onChange={({ model }) =>
      setFormModel(
        model.protocol === "iotgw"
          ? { ...model, externalId: initialModel.externalId }
          : omit(["externalId"], model)
      )
    }
  >
    <FieldSet>
      <Field id="thingName" label="Thing ID" disabled />
      <Field
        id="externalId"
        label="External ID"
        readOnly={formModel.protocol === "iotgw"}
      />
      <Field id="label" label="Thing Label" />
      <Field id="description" label="Description" />
      <Field
        id="domain"
        label="Domain"
        type="autocomplete"
        data-test="edit-thing-form__domain-select"
        suggesterType={DATA_TYPES.DOMAINS}
      />
      {supportedThingProtocols && supportedThingProtocols.length > 1 ? (
        <Field
          id="protocol"
          type="select"
          label="Network integration"
          hintText="Network integration"
          labelProps={{ shrink: true }}
          defaultValue="mqtt"
          disabled={true}
          options={supportedThingProtocols.map(p => ({
            id: p,
            label: networkLabelMappings[p],
            value: p
          }))}
        />
      ) : (
        ""
      )}
      {formModel.protocol === "iotgw" || initialModel.protocol === "iotgw" ? (
        <>
          <Field id="imsi" key="imsi" label="IMSI" />
          <Field id="imei" key="imei" label="IMEI" />
        </>
      ) : (
        ""
      )}
      <Field
        id="simulated"
        key="simulated"
        label="Allow simulation"
        type="toggle"
        style={{}}
        disabled={true}
      />
    </FieldSet>
    <ModalFormButtons>
      <FormCancelButton onClick={handleCancel} disabled={isLoading}>
        Cancel
      </FormCancelButton>
      <FormSaveButton disabled={isLoading} />
    </ModalFormButtons>
  </Form>
);

EditThingForm.propTypes = {
  initialModel: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setFormModel: PropTypes.func.isRequired,
  formModel: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const fetchProtocolsQuery = gql`
  {
    supportedThingProtocols
  }
`;

export default compose(
  graphql(fetchProtocolsQuery),
  withState("formModel", "setFormModel", {})
)(EditThingForm);
