import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  AVERAGE as defaultAggregationType,
  OPTION_NAME as aggregationTypeOptionName
} from "components/widget/edit/widget_edit_aggregation_options";
import { inputElasticThingsFilterSelector } from "ducks/filter/filter_selectors";
import { resourceLabelById } from "ducks/schema/schema_selectors";
import { fromJS } from "immutable";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, pure, withPropsOnChange } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import { resourcePath } from "utils/resource_utils";

const thingStateAggregationQuery = gql`
  query(
    $filter: InputListThingsFilter!
    $resourceName: String!
    $aggregationType: AggregationType!
  ) {
    thingStateAggregation(
      searchOptions: { filter: $filter, resource: $resourceName }
      aggregationType: $aggregationType
    ) {
      aggregationValue
    }
  }
`;

const THING_STATE_VALUE_PATH = [
  "thingStateAggregationQuery",
  "thingStateAggregation",
  "aggregationValue"
];

const mapStateToProps = (state, props) => {
  const resourceId = resourceIdFromWidget(props.widget);
  const { resourceName } = resourcePath(resourceId);

  return {
    ...props,
    resourceName,
    filter: inputElasticThingsFilterSelector(state, props),
    aggregationType: widgetProp(
      props.widget,
      aggregationTypeOptionName,
      defaultAggregationType
    ),
    resourceLabel:
      resourceLabelById(state, { id: resourceIdFromWidget(props.widget) }) ||
      props.resourceName
  };
};

const withThingStateAggregations = compose(
  connect(mapStateToProps),
  graphql(thingStateAggregationQuery, {
    name: "thingStateAggregationQuery"
  }),
  withPropsOnChange(
    (props, nextProps) =>
      R.path(THING_STATE_VALUE_PATH, props) !==
      R.path(THING_STATE_VALUE_PATH, nextProps),
    props => ({
      resource: fromJS({
        id: props.resourceId,
        latestValue: { value: R.path(THING_STATE_VALUE_PATH, props) }
      })
    })
  ),
  pure
);

export default withThingStateAggregations;
