export const getStyles = () => ({
  form: {
    maxHeight: "inherit"
  },
  fieldSet: {
    margin: 0,
    border: "none",
    overflowY: "auto"
  }
});
