import {
  actions as dashboardActions,
  utils as dashboardUtils
} from "./dashboards";
import { selectors as requestSelectors } from "./request";
import dashboardReducer, {
  viewModeCrudDuck,
  gridCrudDuck,
  layoutCrudDuck
} from "./dashboard_reducers";
import widgetSelectors from "./widget_selectors";
import widgetCrudDuck from "./widget_reducer";

import * as dashboardAggregateSelectors from "./dashboard_selectors";

const actions = {
  ...dashboardActions,
  viewModes: viewModeCrudDuck.actions,
  grids: gridCrudDuck.actions,
  widgets: widgetCrudDuck.actions,
  layouts: layoutCrudDuck.actions
};
const selectors = {
  ...dashboardAggregateSelectors,
  ...requestSelectors,
  viewModes: viewModeCrudDuck.selectors,
  grids: gridCrudDuck.selectors,
  widgets: widgetSelectors,
  layouts: layoutCrudDuck.selectors
};

const utils = {
  ...dashboardUtils
};

export {
  actions,
  selectors,
  widgetSelectors,
  dashboardAggregateSelectors,
  utils
};
export default dashboardReducer;
