import * as R from "ramda";
import { unImmute } from "utils/immutable_utils";
import { isNilOrEmpty } from "utils/sorters";
import { withPropsOnChange } from "react-recompose";

export const withThings = withPropsOnChange(["data"], props => {
  const things = R.pathOr([], ["allThings", "things"], props.data);
  const isLoading = R.propOr(true, "loading")(props.data);

  return { things, isLoading };
});

export const _filterNullProp = (name, value) =>
  isNilOrEmpty(value) ? {} : { [name]: value };

const PAGING_KEYS = ["pages", "page", "pageSize", "totalCount"];

export const allThingsQueryPropsHandler = props => {
  if (R.pathOr(true, ["data", "loading"], props)) {
    return props;
  }

  const pageInfo = R.pathOr({}, ["data", "allThings", "pageInfo"], props);
  const currentPagingData = props.ownProps.paginationStatus;
  const newPagingData = {
    pages: pageInfo.pages,
    page: Math.min(pageInfo.page, pageInfo.pages),
    pageSize: pageInfo.size,
    totalCount: pageInfo.totalCount
  };

  if (PAGING_KEYS.some(key => currentPagingData[key] !== newPagingData[key])) {
    const things = R.pipe(
      R.pathOr([], ["data", "allThings", "things"]),
      R.map(R.prop("thingName"))
    )(props);
    props.ownProps.setPaging({
      pagingData: { ...newPagingData, things },
      widgetId: props.ownProps.widgetId
    });
  }

  return props;
};

export const _filterResources = R.pipe(
  R.filter(R.pipe(R.prop("value"), R.complement(isNilOrEmpty))),
  R.map(R.omit(["id", "hasSelectedResource"]))
);

const _mapGeoPoints = R.pipe(
  R.defaultTo([]),
  R.map(arr => ({ lon: arr[0], lat: arr[1] }))
);

const EMPTY_FILTER = {};

export const filterStateToInputElasticThingsFilter = (
  stateFilter,
  routeParams = {},
  options = {}
) => {
  if (!stateFilter) {
    return EMPTY_FILTER;
  }

  const filter = unImmute(stateFilter);

  return {
    ...(routeParams.thingType
      ? { thingTypes: [routeParams.thingType] }
      : _filterNullProp("thingTypes", R.prop("thingTypes")(filter))),
    // ..._filterNullProp("stateType", stateType), //"thing | nested | all",
    ..._filterNullProp("domains", R.prop("domains")(filter)),
    ..._filterNullProp(
      "resources",
      _filterResources(R.propOr([], "resources", filter))
    ),
    ..._filterNullProp(
      "freeText",
      R.filter(
        R.complement(isNilOrEmpty),
        R.values(R.propOr({ t: "" }, "freeText", filter))
      )
    ),
    ..._filterNullProp("thingStatus", R.prop("thingStatus")(filter)),
    ...(options.ignoreGeo
      ? {}
      : _filterNullProp("geoPolygon", _mapGeoPoints(R.prop("geo")(filter))))
  };
};

export const createSearchOptions = ({
  filter,
  paging,
  paginationStatus,
  routeParams
}) => {
  return {
    ...(filter
      ? { filter: filterStateToInputElasticThingsFilter(filter, routeParams) }
      : {}),
    ...(paging
      ? {
          paging: {
            ..._filterNullProp(
              "size",
              R.propOr(
                R.propOr(10, "size")(paging),
                "pageSize",
                paginationStatus
              )
            ),
            ..._filterNullProp("page", R.prop("page")(paging))
          }
        }
      : {}),
    ...(paginationStatus
      ? {
          sorting: {
            ..._filterNullProp("sort", R.prop("sort")(paginationStatus)),
            ..._filterNullProp(
              "sortFieldType",
              R.prop("sortFieldType")(paginationStatus)
            )
          }
        }
      : {})
  };
};
