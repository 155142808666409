import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioButtonGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

const RadioGroup = ({ name, options = [], value, onChange }) => {
  return (
    <FormControl component="fieldset">
      <RadioButtonGroup
        name={name}
        onChange={e => onChange(e.target.value)}
        value={value}
      >
        {options.map(option => (
          <FormControlLabel
            value={option.id}
            key={option.id}
            label={option.label}
            control={<Radio />}
          />
        ))}
      </RadioButtonGroup>
    </FormControl>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default RadioGroup;
