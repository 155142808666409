import { invoke$ as lambdaInvoke$ } from "./invoke";

export const get$ = thingTypeId =>
  lambdaInvoke$("ResourceLambda", {
    action: "GET",
    attributes: {
      thingType: thingTypeId,
      schemaType: "normalized",
      metadata: null
    }
  }).map(res => res.schema);

export const list$ = () =>
  lambdaInvoke$("ResourceLambda", {
    action: "LIST",
    attributes: {
      schemaType: "normalized",
      metadata: null
    }
  });

export const createResourceInvoke$ = invoke$ => query =>
  invoke$("ResourceLambda", {
    action: "CREATE_RESOURCE",
    attributes: query
  });

export const updateResourceInvoke$ = invoke$ => query =>
  invoke$("ResourceLambda", {
    action: "UPDATE_RESOURCE",
    attributes: query
  });

export const createResource$ = createResourceInvoke$(lambdaInvoke$);

export const updateResource$ = updateResourceInvoke$(lambdaInvoke$);

export const update$ = ({ thingType, resource } = {}) =>
  lambdaInvoke$("ResourceLambda", {
    action: "UPDATE_RESOURCE",
    attributes: {
      thingType,
      resource
    }
  });

export const create$ = ({ thingType, resource } = {}) =>
  lambdaInvoke$("ResourceLambda", {
    action: "CREATE_RESOURCE",
    attributes: {
      thingType,
      resource
    }
  });

export const getOptions$ = ({ thingType } = {}) =>
  lambdaInvoke$("ResourceLambda", {
    action: "GET_OPTIONS",
    attributes: {
      thingType
    }
  });

export const saveOptions$ = ({ thingType, resource: { id }, options } = {}) =>
  lambdaInvoke$("ResourceLambda", {
    action: "UPDATE_OPTIONS",
    attributes: {
      thingType,
      resourceId: id,
      options
    }
  });

export const deleteOptions$ = ({ resource: { id } } = {}) =>
  lambdaInvoke$("ResourceLambda", {
    action: "DELETE_OPTIONS",
    attributes: {
      resourceId: id
    }
  });
