import Joi from "joi-browser";
import * as im from "utils/immutable_utils";
import * as R from "ramda";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";

export const getUnitHint = resourceUnit =>
  resourceUnit ? `Leave empty to use "${resourceUnit}"` : "Unit";

const WidgetEditResource = ({
  resources = [],
  onResourceChange,
  resource,
  resourceId,
  resourceType,
  resourceUnit,
  thingType
}) => {
  return {
    fields: [
      // Keeping this for reference to how they brought in the extra resources
      // {
      //   dataTest: "resourceId",
      //   id: "resourceId",
      //   type: "suggester",
      //   suggesterType: "SUGGEST_RESOURCES",
      //   floatingLabelText: "Resources",
      //   floatingLabelFixed: true,
      //   hintText: "Thing resource",
      //   suggesterOptionsDecorator: results => {
      //     return filterResources(type, resourceType, [
      //       ...results,
      //       ...extraResources
      //     ]);
      //   },
      //   onChange: onResourceChange,
      //   fieldChangeValuePath: ["id"],
      //   shouldAlwaysRenderSuggestions: false,
      //   single: true,
      //   textChip: true,
      //   thingType: R.pipe(
      //     im.toJS,
      //     R.when(R.is(Object), R.prop("id"))
      //   )(thingType),
      //   path: []
      // },
      {
        dataTest: "resourceId",
        id: "resourceId",
        type: "autocomplete",
        suggesterType: DATA_TYPES.RESOURCES,
        label: "Resource",
        hintText: "Thing resource",
        onChange: ({ value }) => {
          onResourceChange({ value });
        },
        thingType: R.pipe(
          im.toJS,
          R.when(R.is(Object), R.prop("id"))
        )(thingType)
      },
      {
        id: "unit",
        label: "Unit",
        hint: getUnitHint(resourceUnit)
      }
    ],
    defaults: {
      unit: ""
    },
    initialModel: {
      resourceId: undefined,
      unit: ""
    },
    schema: {
      resourceId: Joi.string().required(),
      unit: Joi.string().allow("")
    }
  };
};

export default WidgetEditResource;
