import { format as formatDate } from "date-fns";
import { resourceLabelById } from "ducks/schema/schema_selectors";
import { fromJS } from "immutable";
import { connect } from "react-redux";
import { compose, lifecycle, pure, withStateHandlers } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import { resourceStatePath } from "utils/widget_utils";
import { TABLE_TIMESTAMP_FORMAT } from "../widget_types/table_widget/table_widget";
import {
  pipe,
  map,
  when,
  is,
  reject,
  ifElse,
  F,
  either,
  isEmpty,
  isNil
} from "ramda";

const mapStateToProps = (state, props) => {
  const { widget, container, layout, resource, resourceList, unit } = props;
  const resourceId = resourceIdFromWidget(widget);
  const resourceMap = resourceList.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.get("id")]: currentValue
    }),
    {}
  );

  return {
    container,
    layout,
    resource,
    resourceList,
    resourceMap,
    unit,
    widget,
    resourceLabel:
      resourceLabelById(state, { id: resourceId }) || props.resourceName,
    thingName: props.params.thingName,
    thingType: props.params.thingType
  };
};

export const _convertToCorrectTimestamp = thingShadowTimestamp =>
  formatDate(
    thingShadowTimestamp ? new Date(thingShadowTimestamp * 1000) : new Date(),
    TABLE_TIMESTAMP_FORMAT
  );

const convertToObservation = (resource = fromJS({})) => {
  if (!resource.get("id") || !resource.get("latestValue")) {
    return undefined;
  }

  const resourcePath = resourceStatePath(
    resource.get("subThingName"),
    resource.get("id")
  );
  const latestValue = resource
    .get("latestValue")
    .update("timestamp", _convertToCorrectTimestamp);

  return fromJS({
    resourcePath,
    value: latestValue.get("value"),
    timestamp: latestValue.get("timestamp")
  });
};

const getObservations = ({ resourceList = fromJS([]) }) => {
  return resourceList.map(resource => convertToObservation(resource));
};

export const removeNulls = pipe(
  map(when(is(Object), v => removeNulls(v))),
  reject(ifElse(is(String), F, either(isEmpty, isNil)))
);

export const _realtimeStateHandler = withStateHandlers(
  props => {
    const observationFromResource = convertToObservation(props.resource);
    const observations =
      props.resourceList && props.resourceList.length > 0
        ? fromJS(getObservations(props))
        : observationFromResource
        ? fromJS([observationFromResource])
        : fromJS([]);
    return {
      observations
    };
  },
  {
    addObservations: ({ observations }) => newObservation => {
      //filters out resources that has undefined values
      const filteredObservations = removeNulls(observations);
      const newFilteredObservation = removeNulls(newObservation);

      if (newFilteredObservation.length > 0) {
        return {
          observations: filteredObservations
            .push(...newFilteredObservation)
            .sort((a, b) => {
              const at = a.get("timestamp");
              const bt = b.get("timestamp");
              return at > bt ? -1 : 1;
            })
            .takeLast(100)
        };
      }
    },
    clearObservations: () => () => ({
      observations: fromJS([])
    })
  }
);

const _shouldUpdateRealtime = ({ addObservations, resource }, nextProps) => {
  if (resource.get("latestValue") !== nextProps.resource.get("latestValue")) {
    addObservations([convertToObservation(nextProps.resource)]);
  }
};

const addNewObservations = (
  { addObservations, resourceMap },
  nextProps,
  addAll
) => {
  const newObservations = [];
  nextProps.resourceList.forEach(nextResource => {
    const currentResource = resourceMap[nextResource.get("id")];
    if (
      addAll ||
      currentResource === undefined ||
      (currentResource &&
        currentResource.get("latestValue") !== nextResource.get("latestValue"))
    ) {
      newObservations.push(convertToObservation(nextResource));
    }
  });

  if (newObservations.length > 0) {
    addObservations(newObservations);
  }
};

export const _lifecycle = lifecycle({
  componentDidUpdate(prevProps) {
    if (this.props.resourceList && this.props.resourceList.length > 0) {
      const resourcesInCurrent = this.props.resourceList.filter(
        resource => prevProps.resourceMap[resource.get("id")] !== undefined
      );
      const shouldResetList =
        prevProps.resourceList.length !== this.props.resourceList.length ||
        resourcesInCurrent.length !== this.props.resourceList.length;
      if (shouldResetList) {
        this.props.clearObservations();
      }
      addNewObservations(prevProps, this.props, shouldResetList);
    } else if (this.props.resource) {
      if (this.props.resource.get("id") !== prevProps.resource.get("id")) {
        prevProps.clearObservations();
      }
      _shouldUpdateRealtime(prevProps, this.props);
    }
  }
});

export const widgetIsRealtime = ({ widget }) =>
  widgetProp(widget, "isRealtime");

const withThingObservationsRealtime = compose(
  connect(mapStateToProps),
  _realtimeStateHandler,
  _lifecycle,
  pure
);

export default withThingObservationsRealtime;
