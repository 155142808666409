import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "white"
  }
}));

const TableHeaderCell = ({ children, className, ...rest }) => {
  const classes = useStyles();
  return (
    <TableCell
      {...rest}
      className={className ? `${classes.root} ${className}` : classes.root}
    >
      {typeof children === "string" ? children.toUpperCase() : children}
    </TableCell>
  );
};

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.object
};

export default TableHeaderCell;
