/* Remove this file when we don't do any .toJS or .toList inside any connect or mapStateToProps */
/* This was a quick fix for performance */

import { createSelector } from "reselect";

export const memorizeToJS = () =>
  createSelector(
    immutable => immutable,
    immutable => immutable.toJS()
  );

export const memorizeToList = () =>
  createSelector(
    immutable => immutable,
    immutable => immutable.toList()
  );
