import { handleActions, createAction } from "redux-actions";
import * as nz from "utils/normalisation_utils";

export const initialStateJobsCanceled = nz.normalizedInitialState();

// ---- jobs
export const DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_SUCCESS =
  "DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_SUCCESS";
export const DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_REQUEST =
  "DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_REQUEST";
export const DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_FAILURE =
  "DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_FAILURE";

export const listJobsCanceled = createAction(
  DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_REQUEST
);
export const listJobsCanceledSuccess = createAction(
  DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_SUCCESS
);
export const listJobsCanceledFailure = createAction(
  DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_FAILURE
);
// ---- end jobs

export default handleActions(
  {
    [DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_REQUEST]: nz.requestStartEvolve,
    [DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_SUCCESS]: nz.requestSuccessEvolve,
    [DEVICE_MANAGEMENT_LIST_CANCELED_JOBS_FAILURE]: nz.requestFailureEvolve
  },
  initialStateJobsCanceled
);
