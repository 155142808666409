import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import FormIconButton from "components/buttons/form_icon";

const moveWidgetsSvg = require("assets/images/move_widgets.svg");

const style = {
  button: {
    alignItems: "center"
  },
  container: {
    display: "flex"
  }
};

const ViewModeEditButtons = ({
  isMovingWidgets,
  handleMoveWidgets,
  handleSave,
  handleUndo
}) => {
  return (
    <div style={style.container}>
      {isMovingWidgets && (
        <>
          <Tooltip title="Save dashboard layout" placement="top">
            <FormIconButton
              style={style.button}
              datatest="editviewmode__save"
              onClick={handleSave}
              color="inherit"
              text="Save"
              icon={<SaveIcon data-test="save-icon" />}
            />
          </Tooltip>
          <Tooltip title="Undo changes to layout" placement="top">
            <FormIconButton
              style={style.button}
              data-test="edit-viewmode__undo"
              onClick={handleUndo}
              color="inherit"
              icon={<UndoIcon data-test="undo-icon" />}
              text="Undo"
            />
          </Tooltip>
        </>
      )}
      {!isMovingWidgets && (
        <span>
          <MoveWidgetsButton onClick={handleMoveWidgets} />
        </span>
      )}
    </div>
  );
};

export const EditViewModeButton = ({ onClick }) => (
  <FormIconButton
    style={style.button}
    data-test="edit-viewmode__edit"
    onClick={onClick}
    color="inherit"
    icon={<CreateIcon />}
  />
);

EditViewModeButton.propTypes = {
  onClick: PropTypes.func
};

export const MoveWidgetsButton = ({ onClick }) => (
  <Tooltip title="Move dashboard widgets" placement="top">
    <FormIconButton
      style={style.button}
      data-test="move-widgets-viewmode__edit"
      onClick={onClick}
      color="inherit"
      text="Move"
      icon={<img src={moveWidgetsSvg} />}
    />
  </Tooltip>
);

MoveWidgetsButton.propTypes = {
  onClick: PropTypes.func
};

ViewModeEditButtons.propTypes = {
  handleMoveWidgets: PropTypes.func,
  handleEditViewMode: PropTypes.func,
  handleSave: PropTypes.func,
  handleUndo: PropTypes.func,
  handleDeleteViewMode: PropTypes.func,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  isMovingWidgets: PropTypes.bool,
  viewModeId: PropTypes.string,
  viewModeLabel: PropTypes.string
};

ViewModeEditButtons.defaultProps = {};

export default ViewModeEditButtons;
