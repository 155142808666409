import { compose, lifecycle } from "react-recompose";
import { last } from "ramda";

// eslint-disable-next-line no-unused-vars
const routerWillLeave = props => nextLocation => {
  const shouldShowModal = props.isMovingWidgets === true;

  // TODO: Confirm dialog "Do you want to save before exiting?"
  // if (shouldShowModal) {
  //   props.openModalViewModeCreateEdit({
  //     navigateLocationOnClose: nextLocation
  //   });
  // }

  return !shouldShowModal;
};

// this HOC expects an "isMovingWidgets" boolean prop, and also the props of a modal HOC named "Save".
const withRouteLeaveViewModeModalHoc = compose(
  lifecycle({
    componentDidUpdate(prevProps) {
      prevProps.router.setRouteLeaveHook(
        last(prevProps.router.routes),
        routerWillLeave(prevProps)
      );
    }
  })
);

export default withRouteLeaveViewModeModalHoc;
