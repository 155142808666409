import { createDeepEqualSelector as createSelector } from "utils/selector_utils";
import * as widgetUtils from "utils/widget_utils";
import { Map } from "immutable";
import { authSelectors } from "ducks/auth";
import { getResources } from "ducks/schema/schema_selectors";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";

const emptyThingTypes = Map();

export const extractThingTypes = state =>
  state.thingTypes.get("types", emptyThingTypes);

const _getThingTypesState = state =>
  state && state.thingTypes ? state.thingTypes : Map();

const _getEditingThingTypeState = createSelector(
  _getThingTypesState,
  thingTypeState => thingTypeState.getIn(["editingThingType"]) || Map()
);

export const getEditingThingTypeId = createSelector(
  _getEditingThingTypeState,
  thingTypeState => thingTypeState.getIn(["id"])
);

export const getEditingThingTypeViewModeId = createSelector(
  _getEditingThingTypeState,
  thingTypeState => thingTypeState.getIn(["viewMode"])
);

export const getEditingThingTypeViewModeObj = createSelector(
  _getEditingThingTypeState,
  getEditingThingTypeViewModeId,
  (thingTypeState, viewModeId) =>
    thingTypeState.getIn(["viewModes", viewModeId], Map())
);

export const getEditingThingTypeResources = state =>
  getResources(state.schema, getEditingThingTypeId(state), {
    includeTcxn: false,
    type: "obj"
  });

export const getThingTypeResources = (state, props) =>
  getResources(state.schema, props.id, {
    includeTcxn: true,
    includeSubthings: false,
    type: "obj"
  });

const selectResourceKeypath = createSelector(
  ({ subThingType }) => subThingType,
  ({ subthing }) => subthing,
  ({ editingThingType }) => editingThingType,
  (subThingType, subthing, editingThingType) =>
    widgetUtils.getResourceKeypath(subThingType, subthing, editingThingType)
);

const selectVirtualResourcesKeyPath = createSelector(
  resourceKeypath => resourceKeypath,
  resourceKeypath => {
    // virtual resources when created are on the thing type
    // we just need to replace exhange resources with virtualResources
    const sliced = resourceKeypath.slice(0);
    sliced[0] = "virtualResources";
    return sliced;
  }
);

export const getVRKeypath = resourceKeypath =>
  selectVirtualResourcesKeyPath(resourceKeypath);

const selectWidgetDetailKeypath = createSelector(
  ({ viewMode }) => viewMode,
  ({ subThingType }) => subThingType,
  ({ subthing }) => subthing,
  ({ editingThingType }) => editingThingType,
  ({ selector }) => selector,
  ({ widgetKey }) => widgetKey,
  widgetUtils.getWidgetKeypath
);

const selectWidgetKeypath = createSelector(
  viewMode => viewMode,
  viewMode => ["editingThingType", "viewModes", viewMode, "collectionWidgets"]
);

export const getWidgetKeypath = (
  viewMode,
  subThingType,
  subthing,
  editingThingType,
  selector,
  dashboardType
) => {
  if (dashboardType === "detail") {
    return selectWidgetDetailKeypath({
      viewMode,
      subThingType,
      subthing,
      editingThingType,
      selector,
      widgetKey:
        dashboardType === "collection" ? "collectionWidgets" : "thingWidgets"
    });
  } else {
    return selectWidgetKeypath(viewMode);
  }
};

export const getKeypaths = (
  { viewMode, subThingType, subthing },
  dashboardType,
  editingThingType,
  selector
) => {
  const widgetKeypath = getWidgetKeypath(
    viewMode,
    subThingType,
    subthing,
    editingThingType,
    selector,
    dashboardType
  );

  if (dashboardType === "detail") {
    const resourceKeypath = selectResourceKeypath({
      subThingType,
      subthing,
      editingThingType
    });
    const virtualResourcesKeyPath = getVRKeypath(resourceKeypath);
    return {
      widgetKeypath,
      resourceKeypath,
      virtualResourcesKeyPath
    };
  } else {
    return {
      widgetKeypath
    };
  }
};

export const isLoading = state => state.thingTypes.get("isLoading");

export const isLoadingThingType = state =>
  state.thingTypes.get("isLoadingThingType", false);

export const getVirtualResource = ({ state, vrkeypath }) =>
  state.thingTypes.getIn(vrkeypath);

export const getEditingThingType = createSelector(
  state => state.thingTypes.get("editingThingType"),
  editingThingType => editingThingType
);

export const hasUndoWidgets = state => state.thingTypes.has("undoWidgets");

export const getAllThingTypes = createSelector(
  extractThingTypes,
  thingTypes => thingTypes
);

const _thingTypesEmpty = thingTypes =>
  thingTypes === undefined || thingTypes.isEmpty();
export const hasThingTypes = createSelector(
  extractThingTypes,
  thingTypes => !_thingTypesEmpty(thingTypes)
);

export const sortThingTypes = createSelector(
  thingTypes => thingTypes,
  thingTypes =>
    thingTypes.toList().sort((a, b) => {
      const alabel = a
        ? a
            .get("label", "")
            .trim()
            .toLowerCase()
        : "";
      const blabel = b
        ? b
            .get("label", "")
            .trim()
            .toLowerCase()
        : "";
      if (alabel < blabel) {
        return -1;
      }
      if (alabel > blabel) {
        return 1;
      }
      return 0;
    })
);

export const hasUserSaved = createSelector(
  getEditingThingTypeViewModeObj,
  viewModeObj => viewModeObj.getIn(["userSaved"], false)
);

export const userCanCreateThingType = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingTypes, OPERATIONS.CREATE)
);

export const userCanEditThingType = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingTypes, OPERATIONS.UPDATE)
);

export const userCanReadThingType = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingTypes, OPERATIONS.READ)
);
