import React from "react";
import { compose, withHandlers } from "react-recompose";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { remove, insert, set, lensPath } from "ramda";
import FormIconButton from "components/buttons/form_icon";
import ColorPicker from "./color_picker";

export const ColorThreshold = compose(
  withHandlers({
    onThresholdInput: ({ onChange, value }) => id => event => {
      onChange(set(lensPath([id, "threshold"]), event.target.value, value));
    },
    onColorSelected: ({ onChange, value }) => id => hexValue => {
      onChange(set(lensPath([id, "color"]), hexValue, value));
    },
    onRemoveRow: ({ value, onChange }) => id => () => {
      onChange(remove(id, 1, value));
    },
    onAddRow: ({ value, onChange }) => () => {
      onChange(
        insert(value.length, { threshold: "0", color: "#1F6E1F" }, value)
      );
    }
  }),
  withStyles(theme => ({
    addIcon: {
      marginRight: theme.spacing(1)
    },
    removeIconButton: {
      padding: theme.spacing(1)
    },
    conditionRow: {
      alignItems: "center",
      display: "flex"
    },
    thresholdField: {
      margin: theme.spacing(1),
      width: 100
    },
    addIconButton: {
      marginTop: theme.spacing(1.5)
    },
    wrapper: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  }))
)(
  ({
    classes,
    value,
    onThresholdInput,
    onColorSelected,
    onRemoveRow,
    onAddRow
  }) => {
    return (
      <div className={classes.wrapper}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.header}
        >
          Color Intervals
        </Typography>
        {value && (
          <div className={classes.conditionList}>
            {value.map((colorThreshold, conditionIndex) => {
              return (
                <div
                  className={classes.conditionRow}
                  key={`ct_${conditionIndex}`}
                >
                  <IconButton
                    className={classes.removeIconButton}
                    onClick={onRemoveRow(conditionIndex)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <div className={classes.inputFields}>
                    <TextField
                      className={classes.thresholdField}
                      value={colorThreshold.threshold}
                      placeholder="Threshold"
                      onChange={onThresholdInput(conditionIndex)}
                    />
                  </div>
                  <ColorPicker
                    color={colorThreshold.color}
                    onChange={onColorSelected(conditionIndex)}
                  />
                </div>
              );
            })}
          </div>
        )}
        <FormIconButton
          className={classes.addIconButton}
          text="Add color interval"
          onClick={onAddRow}
          icon={<AddCircleOutlineIcon className={classes.addIcon} />}
        />
      </div>
    );
  }
);
