import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    "&::before": {
      borderBottom: "none"
    },
    "&::after": {
      borderBottom: "none"
    },
    "&:hover": {
      "&:not(disabled)": {
        "&:not(focused)": {
          "&:not(error)": {
            borderBottom: "none",
            "&:before": {
              borderBottom: "none !important"
            }
          }
        }
      }
    }
  }
});

const TextFieldWithoutUnderline = props => {
  const classes = useStyles();
  return <TextField InputProps={{ classes: classes }} {...props} />;
};

export default TextFieldWithoutUnderline;
