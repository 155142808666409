import styled from "styled-components";

export default styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 400px;

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    background-image: none;
  }
`;
