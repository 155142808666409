import { createAction, handleActions } from "redux-actions";
import { fromJS } from "immutable";

const SET_PREVIOUS_THINGTYPE_PATH = "SET_PREVIOUS_THINGTYPE_PATH";
const SET_VIEWMODE_THINGTYPE = "SET_VIEWMODE_THINGTYPE";
export const THINGTYPE_SUCCESS = "THINGTYPE_SUCCESS";
export const UPDATE_THING_COUNT_SUCCESS = "UPDATE_THING_COUNT_SUCCESS";
export const ADD_WIDGETS = "ADD_WIDGETS";

export const updateThingCountSuccess = createAction(UPDATE_THING_COUNT_SUCCESS);

export const getThingTypeSuccess = createAction(THINGTYPE_SUCCESS);

export const addWidgets = createAction(ADD_WIDGETS);

export const setPreviousLocation = (pathname, query) => ({
  type: SET_PREVIOUS_THINGTYPE_PATH,
  pathname,
  query
});

export const setViewMode = (typeId, viewMode) => ({
  type: SET_VIEWMODE_THINGTYPE,
  typeId,
  viewMode
});

export const initialState = fromJS({
  isLoading: false,
  error: null,
  types: {},
  defaultViewMode: "DefaultView", // Only used for inital Router redirect,
  editingThingType: null
});

export default handleActions(
  {
    [SET_VIEWMODE_THINGTYPE]: (state, { typeId, viewMode }) => {
      if (state.get("editingThingType")) {
        state = state.setIn(["editingThingType", "viewMode"], viewMode);
      }
      return state.setIn(["types", typeId, "viewMode"], viewMode);
    },
    [SET_PREVIOUS_THINGTYPE_PATH]: (state, action) =>
      state.merge({
        previousLocation: {
          pathname: action.pathname,
          query: action.query
        }
      }),
    [THINGTYPE_SUCCESS]: (state, action) => {
      const imtt = fromJS(action.payload);
      return state
        .setIn(["types", imtt.get("id")], imtt)
        .setIn(["editingThingType"], imtt)
        .setIn(["editingThingType", "viewMode"], "DefaultView")
        .setIn(["isLoadingThingType"], false);
    },
    [UPDATE_THING_COUNT_SUCCESS]: (state, action) => {
      const { thingType, thingCount } = action.payload;
      const newState = state.setIn(
        ["types", thingType, "thingCount"],
        thingCount
      );

      if (state.getIn(["editingThingType", "id"]) === thingType) {
        return newState.setIn(["editingThingType", "thingCount"], thingCount);
      }

      return newState;
    }
  },
  initialState
);
