import grey from "@material-ui/core/colors/grey";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVert from "@material-ui/icons/MoreVert";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import FormIconButton from "components/buttons/form_icon";
import Dialog from "components/dialog";
import MoveWidgetsIcon from "components/icon/move_widgets";
import ViewModePickerContainer from "components/viewmode_picker/viewmode_picker_container";
import WidgetEdit from "components/widget/edit/widget_edit";
import { setNotificationMessage } from "ducks/system";
import { branchModal } from "hocs/modal_hocs";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { push } from "react-router-redux";
import { compose } from "react-recompose";
import { ViewModeEditModalContainer } from "routes/main/things/modals/";
import ObservationPeriodPickerWrapper from "routes/main/things/thing_type/overview/components/view_mode/observation/observation_period_picker_wrapper";
import * as pathUtils from "utils/path_utils";
import SubThingPickerContainer from "./sub_thing_picker_container";

const styles = () => ({
  wrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  notMovingContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  actionButtonsContainer: {
    display: "flex",
    alignItems: "center"
  },
  thingListButton: {
    fontSize: "inherit",
    height: "100%",
    fontFamily: "inherit",
    textTransform: "inherit"
  },
  thingListPopoverPaper: {
    maxWidth: "420px",
    borderRadius: "16px"
  }
});

const ViewModeEditModal = branchModal(
  "ViewModeCreateEdit",
  ViewModeEditModalContainer
);

const WidgetModal = branchModal("Widget", WidgetEdit);

const TooltipFormIconButton = props => (
  <Tooltip title={props.tooltipText} placement="top">
    <div>
      <FormIconButton {...R.omit(["tooltipText"], props)} />
    </div>
  </Tooltip>
);

TooltipFormIconButton.propTypes = {
  tooltipText: PropTypes.string
};

export const DashboardToolbar = props => {
  const {
    userCanCreateThingType,
    handleNewWidget,
    dashboardIsReadOnly,
    userCanUpdateDashboard,
    isMovingWidgets,
    handleSaveDashboard,
    handleUndoDashboard,
    setIsMovingWidgets,
    handleNewDashboard,
    handleEditDashboard,
    handleDeleteDashboard,
    modalStateViewModeCreateEdit,
    closeModalViewModeCreateEdit,
    modalStateWidget,
    handleCancelWidget,
    updateEditModeWidget,
    closeModalWidget,
    dashboardViewModes,
    dashboardViewMode,
    dispatch,
    location: { pathname },
    setMenuAnchor,
    menuAnchorEl,
    setDeleteDialogOpen,
    deleteDialogOpen,
    classes,
    grids,
    thingName,
    dashboardIsLoading,
    dashboardHasThingListWidget,
    thingListAnchorEl,
    setThingListAnchor,
    type
  } = props;

  return (
    <Toolbar className={classes.wrapper} disableGutters>
      {isMovingWidgets ? (
        <React.Fragment>
          <TooltipFormIconButton
            data-test="edit-viewmode__save"
            onClick={handleSaveDashboard}
            text="Save"
            color="default"
            tooltipText="Save dashboard layout"
            icon={<SaveIcon nativeColor={grey[700]} />}
          />

          <TooltipFormIconButton
            data-test="edit-viewmode__undo"
            onClick={handleUndoDashboard}
            text="Undo"
            color="default"
            tooltipText="Undo changes to layout"
            icon={<UndoIcon nativeColor={grey[700]} />}
          />
          <TooltipFormIconButton
            text="Widget"
            color="primary"
            data-test="add-widgets-button"
            onClick={handleNewWidget}
            tooltipText="Add widget to display resource value"
            icon={<AddIcon nativeColor={grey[700]} />}
          />
        </React.Fragment>
      ) : (
        <div className={classes.notMovingContainer}>
          <div>
            <ViewModePickerContainer
              viewModes={dashboardViewModes}
              selectedViewMode={R.prop("id", dashboardViewMode)}
              onChange={(e, { props: { value } }) =>
                value &&
                dispatch(
                  push({
                    pathname: pathUtils
                      .replaceViewMode(pathname, value)
                      .replace("thingslist", "overview")
                  })
                )
              }
              disabled={dashboardIsLoading}
            />
            <SubThingPickerContainer
              location={location}
              thingName={thingName}
              disabled={dashboardIsLoading}
            />
            {type === "collection" && !dashboardHasThingListWidget && (
              <div
                style={{
                  display: "inline-block",
                  height: "100%",
                  paddingLeft: "8px"
                }}
              >
                <FormIconButton
                  className={classes.thingListButton}
                  text={"Thing List"}
                  color="primary"
                  onClick={R.ifElse(
                    R.always(userCanCreateThingType),
                    R.pipe(R.prop("target"), R.tap(setThingListAnchor)),
                    R.tap(() => {
                      dispatch(
                        setNotificationMessage(
                          "The Thing List tab will be removed going forward, and the Thing List will instead be available as a widget. Your account don't allow dashboard edits, why you might need to ask your administrator to add the widget."
                        )
                      );

                      dispatch(
                        push({
                          pathname: pathUtils.replaceLastParam(
                            pathname,
                            "thingslist"
                          )
                        })
                      );
                    })
                  )}
                />
              </div>
            )}
          </div>

          <div className={classes.actionButtonsContainer}>
            <ObservationPeriodPickerWrapper disabled={dashboardIsLoading} />

            <TooltipFormIconButton
              icon={
                <MoveWidgetsIcon
                  nativeColor={
                    dashboardIsLoading ||
                    (dashboardIsReadOnly && !userCanUpdateDashboard)
                      ? grey[400]
                      : grey[700]
                  }
                />
              }
              color="default"
              text="Move"
              tooltipText="Move and edit Dashboard widgets"
              onClick={() => setIsMovingWidgets(true)}
              data-test="set-is-moving-widgets-button"
              disabled={
                dashboardIsLoading ||
                (dashboardIsReadOnly && !userCanUpdateDashboard)
              }
            />
            <TooltipFormIconButton
              text="Dashboard"
              data-test="add-viewmode-button"
              onClick={handleNewDashboard}
              color="primary"
              icon={
                <AddIcon
                  nativeColor={dashboardIsLoading ? grey[400] : "primary"}
                />
              }
              tooltipText="Add new Dashboard"
              disabled={dashboardIsLoading}
            />
            {dashboardIsReadOnly === false && (
              <>
                <FormIconButton
                  onClick={e => setMenuAnchor(e.currentTarget)}
                  data-test="open-dashboard-menu-button"
                  icon={
                    <MoreVert
                      nativeColor={dashboardIsLoading ? grey[400] : grey[700]}
                    />
                  }
                  disabled={dashboardIsLoading}
                />
                <Menu
                  open={Boolean(menuAnchorEl)}
                  anchorEl={menuAnchorEl}
                  onClose={() => setMenuAnchor(undefined)}
                  disabled={dashboardIsLoading}
                >
                  <MenuItem onClick={handleEditDashboard}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <Typography>Edit dashboard</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setDeleteDialogOpen(true)}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <Typography>Delete dashboard</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
          <Dialog
            title="Confirm Delete Dashboard"
            open={deleteDialogOpen}
            text={`Are you sure you want to delete the dashboard: ${R.prop(
              "label",
              dashboardViewMode
            )}?`}
            actions={[
              <FormIconButton
                key="cancel-delete-viewmode"
                text="Cancel"
                onClick={() => setDeleteDialogOpen(false)}
              />,
              <FormIconButton
                key="confirm-delete-viewmode"
                text="Confirm"
                onClick={() => handleDeleteDashboard()}
              />
            ]}
          />
        </div>
      )}
      <ViewModeEditModal
        modalStateViewModeCreateEdit={modalStateViewModeCreateEdit}
        closeModalViewModeCreateEdit={closeModalViewModeCreateEdit}
        grids={grids}
        dashboardViewMode={dashboardViewMode}
      />
      <WidgetModal
        modalStateWidget={modalStateWidget}
        handleCancelWidget={handleCancelWidget}
        updateEditModeWidget={updateEditModeWidget}
        closeModalWidget={closeModalWidget}
      />
      <Popover
        open={Boolean(thingListAnchorEl)}
        anchorEl={thingListAnchorEl}
        classes={{
          paper: classes.thingListPopoverPaper
        }}
        onClose={() => {
          setThingListAnchor(undefined);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Typography
          style={{
            margin: "16px",
            marginBottom: "0px"
          }}
        >
          The Thing List tab will be removed going forward. Instead, the Thing
          List will be available as a standard widget that can be placed in any
          dashboard. <br /> <br />
          Please add the Thing List widget to your dashboard(s) using the
          rearrange dashboard widget{" "}
          <MoveWidgetsIcon style={{ verticalAlign: "middle" }} />, or with the
          button below
        </Typography>

        <FormIconButton
          color="primary"
          text="Add Things List Widget"
          style={{
            margin: "auto",
            display: "block",
            paddingBottom: "16px"
          }}
          onClick={() => {
            setThingListAnchor(undefined);
            handleNewWidget({ widgetType: "AllThings" });
          }}
        />
      </Popover>
    </Toolbar>
  );
};

DashboardToolbar.defaultProps = {
  classes: {},
  location: {}
};

DashboardToolbar.propTypes = {
  userCanCreateThingType: PropTypes.bool.isRequired,
  handleNewWidget: PropTypes.func.isRequired,
  dashboardIsReadOnly: PropTypes.bool.isRequired,
  userCanUpdateDashboard: PropTypes.bool.isRequired,
  setIsMovingWidgets: PropTypes.func.isRequired,
  handleSaveDashboard: PropTypes.func.isRequired,
  handleUndoDashboard: PropTypes.func.isRequired,
  isMovingWidgets: PropTypes.bool.isRequired,
  setMenuAnchor: PropTypes.func.isRequired,
  menuAnchorEl: PropTypes.object,
  classes: PropTypes.object.isRequired,
  handleEditDashboard: PropTypes.func.isRequired,
  handleDeleteDashboard: PropTypes.func.isRequired,
  modalStateWidget: PropTypes.object.isRequired,
  handleCancelWidget: PropTypes.func.isRequired,
  updateEditModeWidget: PropTypes.func.isRequired,
  closeModalWidget: PropTypes.func.isRequired,
  dashboardViewModes: PropTypes.array,
  dashboardViewMode: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  setDeleteDialogOpen: PropTypes.func.isRequired,
  deleteDialogOpen: PropTypes.bool.isRequired,
  handleNewDashboard: PropTypes.func.isRequired,
  modalStateViewModeCreateEdit: PropTypes.object,
  closeModalViewModeCreateEdit: PropTypes.func,
  grids: PropTypes.object,
  thingName: PropTypes.string,
  dashboardIsLoading: PropTypes.bool,
  dashboardHasThingListWidget: PropTypes.bool,
  thingListAnchorEl: PropTypes.object,
  setThingListAnchor: PropTypes.func,
  type: PropTypes.string
};

export default compose(withStyles(styles))(DashboardToolbar);
