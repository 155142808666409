import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DataExportIcon from "components/icon/data_export";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import NextButton from "./components/next_button";
import PreviousButton from "./components/previous_button";

const iconStyle = { marginRight: "16px" };

const steps = [Step1, Step2];
const stepCount = steps.length;

const styles = {
  stepperRoot: {
    width: "100%",
    margin: 0,
    position: "relative"
  },
  dialogActionRoot: {
    margin: 0
  },
  stepWrapper: {
    overflow: "auto",
    flex: "0 1 auto"
  }
};

const CreateDataExportDialog = props => {
  const { classes } = props;
  const nextBtn = NextButton({ ...props, steps: stepCount });
  const prevBtn = PreviousButton({ ...props, steps: stepCount });
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={props.modalStateDataExport.open}
      onClose={props.closeModalDataExport}
    >
      <DialogTitle>
        <DataExportIcon style={iconStyle} />
        <span>Observation export data</span>
        <Typography
          component={"p"}
          variant={"body1"}
          style={{ padding: "10px" }}
        >
          Data Export is an additional service priced separately when used.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={classes.stepWrapper}>
            <fieldset style={{ border: "none" }}>
              {steps[props.activeStep](props)}
            </fieldset>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant={"body1"}>
              Export files can be found in Settings → Data Export
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionRoot}>
        <MobileStepper
          className={classes.stepperRoot}
          steps={stepCount}
          activeStep={props.activeStep}
          nextButton={nextBtn}
          backButton={prevBtn}
        />
      </DialogActions>
    </Dialog>
  );
};
/**<CreateDataExportContainer
          thingTypeId={props.thingTypeId}
          thingType={props.thingType}
          selectedThings={props.selectedThings}
          closeModalDataExport={props.closeModalDataExport}
        /> */
CreateDataExportDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  modalStateDataExport: PropTypes.object,
  thingType: PropTypes.any,
  selectedThings: PropTypes.array,
  thingTypeId: PropTypes.string,
  closeModalDataExport: PropTypes.func,
  handleCreateExport: PropTypes.func,

  startDate: PropTypes.object,
  endDate: PropTypes.object,
  label: PropTypes.string,
  //selectedThings: PropTypes.array,
  //thingType: PropTypes.any,
  thingTypeResources: PropTypes.array,
  selectedThingTypeResources: PropTypes.array,
  isSubmitting: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleLabelChange: PropTypes.func,
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func,
  handleSelectedResourcesChange: PropTypes.func,
  //handleCreateExport: PropTypes.func,
  //thingTypeId: PropTypes.string,
  activeStep: PropTypes.number,
  handleNextStep: PropTypes.func,
  handlePreviousStep: PropTypes.func
};

export default withStyles(styles)(CreateDataExportDialog);
