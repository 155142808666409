import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { telenorTextColor } from "themes/colors";

const DataExportIcon = props => (
  <SvgIcon {...props} style={{ color: telenorTextColor }}>
    <path d="M6.09 8.41L8.67 11L-2.62268e-07 11L-1.74846e-07 13L8.67 13L6.09 15.59L7.5 17L12.5 12L7.5 7L6.09 8.41Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 9V12V19C24 20.1046 23.1046 21 22 21H11C9.89543 21 9 20.1046 9 19V17H11V19H22V12H17H15V10V5L11 5V7H9V5C9 3.89543 9.89543 3 11 3H15H18L18.25 3.25L23.75 8.75L24 9ZM19 6.5L20.5 8L22 9.5L22.5 10H22.1716H22H19.6716H19H17V8V7.32843V5V4.82843V4.5L17.5 5L19 6.5Z"
    />
  </SvgIcon>
);

export default DataExportIcon;
