import * as R from "ramda";
import { timeFrom } from "./view_utils";
import { resourcePath } from "utils/resource_utils";
import { get, getIn } from "utils/immutable_utils";
import { nonEmptyString } from "./general_utils";

export const getMaxValueRecursive = arr => {
  const values = Object.values(arr.flatten().toJS());
  if (values.length === 0) return null;
  return Math.max(...values);
};

export const getLatestReportedResourceTime = thing => {
  let metadata = get(thing, "metadata");
  if (metadata) {
    return timeFrom(getMaxValueRecursive(metadata));
  } else {
    return null;
  }
};

export const getConnectionStatus = (thing, fromShadow = false) => {
  const connectionStatusPath = fromShadow
    ? ["shadow", "state", "reported", "tcxn", "connection_status"]
    : ["state", "tcxn", "connection_status"];
  const statePath = fromShadow ? ["shadow", "state"] : ["state"];

  const connectionStatus = getIn(thing, connectionStatusPath);
  const hasState = getIn(thing, statePath);
  if (connectionStatus !== undefined) {
    return connectionStatus;
  } else if (hasState) {
    return 4; // unknown connection Status
  } else {
    return 3; // not connected
  }
};

export const makeResourcePath = R.pipe(
  resource => resourcePath(resource),
  R.ifElse(
    R.propSatisfies(R.isNil, "subThingType"),
    R.ifElse(
      R.propEq("isTcxn", true),
      R.compose(R.tail, R.split("/"), R.prop("resource")),
      R.compose(R.of, R.prop("resourceName"))
    ),
    R.props(["subThingType", "resourceName"])
  )
);
export const makeResourcePathString = R.pipe(makeResourcePath, R.join("/"));
export const makeResourceStatePath = R.pipe(
  makeResourcePath,
  R.prepend("state")
);
export const makeResourceStatePathString = R.pipe(
  makeResourceStatePath,
  R.join(".")
);

export const hasMatchingThingType = resourceId =>
  R.propEq("thingType", R.pipe(R.split("/"), R.head)(resourceId));

export const getResourceValue = (thing, resource) =>
  getIn(thing, makeResourceStatePath(resource));

export const isThingParentThing = thing =>
  get(thing, "hasNetworkedThings") === true;

export const isThingSubThing = thing =>
  nonEmptyString(get(thing, "parentThingName"));

export const getParentThingNameFromThingShadow = R.cond([
  [isThingSubThing, thing => get(thing, "parentThingName")],
  [isThingParentThing, thing => get(thing, "thingName")],
  [R.T, R.always(undefined)]
]);
