import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "react-recompose";
import { authSelectors } from "ducks/auth";
import { clearCredentials } from "utils/auth_utils";
import redirectToRoot from "api/utils/redirect_to_root";

const LogoutComponent = () => <span>Logged out</span>;

export default compose(
  connect(state => ({
    env: authSelectors.getEnv(state),
    accountId: authSelectors.getAccountId(state)
  })),
  lifecycle({
    componentDidMount() {
      clearCredentials();
      redirectToRoot(this.props.accountId, this.props.env);
    }
  })
)(LogoutComponent);
