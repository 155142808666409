import * as R from "ramda";

const _toList = (a = []) => (R.is(Array)(a) ? a : [a]);

export const _concat = (a, b) =>
  R.ifElse(
    R.anyPass([({ a }) => R.is(Array)(a), ({ b }) => R.is(Array)(b)]),
    ({ a = [], b = [] }) => R.union(_toList(a), _toList(b)),
    ({ a }) => a
  )({ a, b });

export const success = (state, action) =>
  R.mergeDeepWith(_concat, action.payload)(state);

export const listSuccess = (state, action) => R.merge(state, action.payload);

export const successItem = (state, action) => {
  const id = R.path(["payload", "id"])(action);
  const item = action.payload;
  return R.over(R.lensPath([id]), state => R.mergeWith(_concat, state, item))(
    state
  );
};

export const requestStart = (request = {}) =>
  R.mergeDeepLeft({
    isLoading: true,
    error: null,
    ...request
  });

export const requestSuccess = (request = {}) =>
  R.mergeDeepLeft({
    isLoading: false,
    ...request
  });

export const requestFailure = (request = {}) =>
  R.mergeDeepLeft({
    isLoading: false,
    ...request
  });

const _evolveRequest = R.evolve({
  request: { error: R.always(null), isLoading: R.T }
});

const _evolveSuccess = payload =>
  R.evolve({
    entities: R.when(
      R.always(!R.isNil(payload.entities)),
      R.always(payload.entities)
    ),
    results: R.when(
      R.always(!R.isNil(payload.results)),
      R.always(payload.results)
    ),
    request: { isLoading: R.F }
  });

const _evolveFailure = payload =>
  R.evolve({
    request: { isLoading: R.F, error: R.always(payload) }
  });

export const requestStartEvolve = state => {
  return _evolveRequest(state);
};

export const requestSuccessEvolve = (state, { payload = {} }) =>
  _evolveSuccess(payload)(state);

export const requestFailureEvolve = (state, { payload = {} }) =>
  _evolveFailure(payload)(state);

export const push = (path = [], val, state) =>
  R.over(R.lensPath(path), R.union(_toList(val)))(state);

const _normalizedInitState = {
  entities: {},
  results: [],
  request: {
    isLoading: false,
    error: null
  }
};
export const normalizedInitialState = (entityName, stateExtras = {}) => {
  if (!entityName || R.is(Object)(entityName)) {
    return R.is(Object)(entityName)
      ? R.mergeDeepRight(_normalizedInitState, entityName)
      : _normalizedInitState;
  } else {
    return {
      [entityName]: R.mergeDeepRight(_normalizedInitState, stateExtras)
    };
  }
};
