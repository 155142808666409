import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { always, defaultTo, head, pipe, prop, tryCatch } from "ramda";
import { manifest } from "../api/aws/manifest";
import fetchWithRefreshToken from "../api/utils/fetch_with_refresh_token";
import { cache, setClientErrors } from "./cache";

export const parseNetworkError = networkError =>
  `Request failed: ${networkError}`;

export const parseGraphQLErrors = pipe(
  defaultTo([]),
  head,
  prop("message"),
  message =>
    tryCatch(pipe(JSON.parse, prop("message")), always(message))(message)
);

export const handleGraphQLError = ({
  graphQLErrors,
  networkError,
  operation
}) => {
  if (!(operation?.variables?.showError === false)) {
    const error = networkError
      ? parseNetworkError(networkError)
      : parseGraphQLErrors(graphQLErrors);
    setClientErrors(error);
  }
};

export const setupClient = (apiGatewayRootUrl, env) => {
  const httpLink = createHttpLink({
    fetch: fetchWithRefreshToken,
    uri: apiGatewayRootUrl + "/" + env + "/graphql"
    //uri: "http://localhost:3002/graphql"
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      onError(errorProps => handleGraphQLError(errorProps, client)),
      httpLink
    ]),
    cache
  });
  return client;
};

const clientSingleton = () => {
  let _client;

  return function getClient() {
    if (!_client) {
      if (!manifest.ApiGatewayRootUrl) {
        throw new Error("ApiGatewayRootUrl not in manifest");
      }

      if (!manifest.StackName) {
        throw new Error("StackName not in manifest");
      }

      _client = setupClient(manifest.ApiGatewayRootUrl, manifest.StackName);
    }
    return _client;
  };
};

export default clientSingleton();
