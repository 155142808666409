/* eslint-disable react/jsx-no-target-blank */
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DynamicWidget from "./dynamic_widget_wrapper";

const chipStyle = {
  WebkitUserSelect: "all",
  MozUserSelect: "all",
  MsUserSelect: "all",
  userSelect: "all",
  color: "rgba(77, 77, 77, 0.87)"
};

const getValueFromDomain = (key, valueMap) => {
  const keys = key.split(".");
  if (valueMap.domain === undefined || keys[0] !== "domain") {
    return "Unknown";
  }
  const reducer = (acc, cur) => acc[cur] || {};
  const result = keys.reduce(reducer, valueMap);
  if (result === undefined || (result !== null && typeof result === "object")) {
    return "Unknown";
  } else {
    return result;
  }
};

export const getAvailableVariablesValueMap = valueMap => {
  const filter = ([key, ...rest]) => key !== "domain";
  const entries = Object.entries(valueMap)
    .filter(filter)
    .map(([key, value]) => {
      if (
        value === undefined ||
        (value !== null && typeof value === "object")
      ) {
        return [key, getValueFromDomain(key, valueMap)];
      }
      return [key, value];
    });
  return Object.fromEntries(entries);
};

const DynamicWidgetEditor = ({ template, valueMap, handleTemplateChange }) => {
  const values = valueMap.toJSON();
  const availableVariablesValueMap = getAvailableVariablesValueMap(values);

  const [markdownText, setMarkdownText] = useState(template || "");
  const handleMarkdownChange = e => {
    const text = e.target.value;
    handleTemplateChange({ template: text });
    setMarkdownText(text);
  };
  const handleVariableClick = key => {
    const syntax = `\${${key}}`;
    const text = markdownText.concat(syntax);
    handleTemplateChange({ template: text });
    setMarkdownText(text);
  };

  return (
    <>
      <Typography variant="subtitle1" color="textSecondary">
        Text
      </Typography>
      <TextField
        variant="filled"
        label="Markdown input"
        multiline
        rows={5}
        fullWidth
        InputProps={{
          style: { fontFamily: "monospace" }
        }}
        value={markdownText}
        onChange={handleMarkdownChange}
        key="dynamicWidgetTextField"
      />
      <Typography variant="caption" color="textSecondary">
        what is{" "}
        <a target="_blank" href="https://en.wikipedia.org/wiki/Markdown">
          markdown
        </a>
        ?
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Live preview
      </Typography>
      <Paper
        style={{
          fontSize: "1.5em",
          padding: "20px",
          display: "inline-block"
        }}
      >
        <DynamicWidget
          style={{ wordBreak: "break-all" }}
          valueMap={valueMap}
          template={markdownText}
        />
      </Paper>
      <Typography variant="subtitle1" color="textSecondary">
        Available variables
      </Typography>
      <Typography variant="caption" color="textSecondary">
        All available variables that can be used in the markdown input are
        listed here. To use them, one can either write the syntax in the
        markdown input or simply click on the variable label which will append
        the variable to the markdown. The value of the variable can be displayed
        by hoovering over the variable label.
      </Typography>
      {Object.entries(availableVariablesValueMap).map(([key, value]) => (
        <Chip
          style={{ margin: "3px" }}
          key={key}
          onClick={() => handleVariableClick(key)}
          label={
            <Tooltip title={value} aria-label={value}>
              <span style={chipStyle}>{`\${${key}}`}</span>
            </Tooltip>
          }
        />
      ))}
    </>
  );
};

DynamicWidgetEditor.propTypes = {
  template: PropTypes.string,
  valueMap: PropTypes.object,
  handleTemplateChange: PropTypes.func,
  setCustomProps: PropTypes.func
};

export default DynamicWidgetEditor;
