import * as R from "ramda";

export const makeRegExp = R.tryCatch(
  query => new RegExp(query, "i"),
  R.always(/(.+)/)
);

export const hasRegexpTest = query =>
  R.pipe(
    R.defaultTo(""),
    R.when(R.complement(R.is(String)), R.toString),
    R.test(makeRegExp(query))
  );

export const whereKeyHasRegexMatch = (key, query) =>
  query
    ? R.where({
        [key]: hasRegexpTest(query)
      })
    : R.always(true);

export const propSatisifiesRegexOnPath = (query, path) =>
  R.pathSatisfies(hasRegexpTest(`(.*)${query}`), path);

export const filterOnPath = (query, path) =>
  R.filter(propSatisifiesRegexOnPath(query, path));
