import { Observable } from "rxjs";
import { fromJS } from "immutable";
import { setNotificationMessage } from "ducks/system";
import { apiError } from "ducks/errors";
import { logoFileName, faviconFileName } from "utils/theme_utils";
import { stripQuery } from "utils/url_utils";
import {
  SAVE_THEME_REQUEST,
  saveThemeSuccess,
  saveThemeFailure,
  setTheme
} from "ducks/theme";

export const saveThemeEpic = (action$, store, { api, themeUtils }) =>
  action$
    .ofType(SAVE_THEME_REQUEST)
    .map(action => ({
      domain: action.payload.domain,
      theme: themeUtils.immutableModelToAPIObject(action.payload.theme),
      logoFile: action.payload.logoUploadFile,
      faviconFile: action.payload.faviconFile
    }))
    .mergeMap(({ domain, theme, logoFile, faviconFile }) => {
      if (logoFile == null && faviconFile == null)
        return Observable.of({ domain, theme });

      const fileOperations = [];
      if (logoFile != null) {
        const logoFile$ = api.files
          .uploadV2$(
            { public: true, fileName: logoFileName(domain, logoFile.name) },
            logoFile
          )
          .map(result => ({
            logo: { src: stripQuery(result.url), alt: logoFile.name }
          }));

        fileOperations.push(logoFile$);
      }

      if (faviconFile != null) {
        const faviconFile$ = api.files
          .uploadV2$(
            {
              public: true,
              fileName: faviconFileName(domain, faviconFile.name)
            },
            faviconFile
          )
          .map(result => ({
            favicon: {
              src: stripQuery(result.url),
              timestamp: new Date().valueOf()
            }
          }));

        fileOperations.push(faviconFile$);
      }

      return Observable.forkJoin(fileOperations).map(results => {
        const flattened = results.reduce((acc, curr) => ({ ...acc, ...curr }));
        return {
          domain,
          theme: {
            ...theme,
            ...flattened
          }
        };
      });
    })
    .mergeMap(({ theme, domain }) =>
      api.domains
        .update$({ id: domain, settings: { theme } })
        .mergeMap(response => [
          saveThemeSuccess({ domain, theme: fromJS(theme) }),
          setNotificationMessage("Theme saved!"),
          ...(domain === "root" ? [setTheme(theme)] : [])
        ])
        .catch(error => Observable.of(saveThemeFailure(error), apiError(error)))
    );
