import { unImmute } from "utils/immutable_utils";

export default function createCSV(data) {
  let csvBodyString = "";
  let allDates = {};

  let startTime = null;
  let endTime = null;

  const mutableData = unImmute(data);

  Object.keys(mutableData).forEach(key => {
    const resource = mutableData[key];

    resource.observations.forEach(observation => {
      const date = observation.timestamp;

      if (startTime == null || startTime > date) startTime = date;
      if (endTime == null || endTime < date) endTime = date;

      if (allDates[date] == undefined) allDates[date] = {};

      allDates[date][key] = observation.value;
    });
  });

  const headers = Object.keys(mutableData);

  Object.keys(allDates)
    .sort()
    .forEach(key => {
      const observations = allDates[key.toString()];
      csvBodyString += new Date(Number(key)).toISOString();
      csvBodyString += ",";

      const values = headers.map(header => observations[header]);
      csvBodyString += values.join(",");

      csvBodyString += "\n";
    });

  startTime = new Date(startTime).toISOString();
  endTime = new Date(endTime).toISOString();

  // Prepare headings
  const headings = ["timestamp"].concat(headers).join(",");

  // Append headings
  return {
    title: `Cloud Connect Export ${startTime} - ${endTime}.csv`,
    body: [headings, csvBodyString].join("\n")
  };
}
