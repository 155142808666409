import { add as dateAdd, sub as dateSubtract } from "date-fns";

export const euclideanDistanceWithoutSqrt = (p, q) => {
  // Used to compare values to get the shortest distance which means that the
  // value is irrelevant; therefore the sqrt operation can be skipped.
  return Math.pow(q.x - p.x, 2) + Math.pow(q.y - p.y, 2);
};

const getSinglePointDomain = val => {
  const offset = 1;
  const minVal =
    val instanceof Date ? dateSubtract(val, { days: offset }) : +val - offset;
  const maxVal =
    val instanceof Date ? dateAdd(val, { days: offset }) : +val + offset;
  return val === 0 ? [0, maxVal] : [minVal, maxVal];
};

const getDomain = data => {
  const min = Math.min(...data);
  const max = Math.max(...data);
  return min === max ? getSinglePointDomain(data[0]) : [min, max];
};

export const getDomains = data => {
  if (!data || data.length === 0) {
    return undefined;
  }

  const xLs = data.map(points => points.x);
  const yLs = data.map(points => points.y);
  return {
    x: getDomain(xLs).map(x => new Date(x)),
    y: getDomain(yLs)
  };
};

export const getActivePoints = (points, mousePosition) => {
  if (!points || points.length === 0) return undefined;

  const reducer = (accumulator, currentValue) => {
    const distance = euclideanDistanceWithoutSqrt(mousePosition, currentValue);
    return accumulator === undefined || distance < accumulator.distance
      ? { points: [currentValue], distance }
      : currentValue.x === accumulator.points[0].x &&
        currentValue.y === accumulator.points[0].y
      ? { points: [...accumulator.points, currentValue], distance }
      : accumulator;
  };
  const shortestDistance = points.reduce(reducer, undefined);

  return shortestDistance.points;
};
