import React from "react";
import { withRouter } from "react-router";
import {
  compose,
  pure,
  withHandlers,
  withPropsOnChange
} from "react-recompose";
import { thingDetailsUrl } from "utils/route_utils";
import { widgetId } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import ThingsMapWidget from "./things_map_widget";
import withThingStateGeoAggregation from "components/widget/observation_hocs/with_thing_state_geo_aggregation";

export default compose(
  withThingStateGeoAggregation,
  withRouter,
  withPropsOnChange(["widget"], props => ({
    mapId: `things-map-${widgetId(props.widget)}`
  })),
  withHandlers({
    gotoThingDetail: ({ router }) => (dataPoint, evt) => {
      try {
        evt.preventDefault();
        evt.stopPropagation();
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }

      const thingName =
        imu.getIn(dataPoint, ["properties", "thingName"]) ||
        imu.getIn(dataPoint, ["options", "thingName"]);
      const thingType =
        imu.getIn(dataPoint, ["properties", "thingType"]) ||
        imu.getIn(dataPoint, ["options", "thingType"]);

      router.push(thingDetailsUrl(router, { thingName, thingType }));
      return false;
    }
  }),
  pure
)(({ mapId, data, gotoThingDetail, params: { thingType }, ...rest }) => (
  <ThingsMapWidget
    mapId={mapId}
    data={data}
    gotoThingDetail={gotoThingDetail}
    thingType={thingType}
    {...rest}
  />
));
