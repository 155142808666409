import { split, compose, any, is } from "ramda";

const RE_VALID_LATLNG = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;

const emptyArray = [];

const toArrayOfFloats = latLng => {
  const floats = latLng.map(coord => parseFloat(coord));

  return any(n => Number.isNaN(n), floats) ? emptyArray : floats;
};

// latLngfromString :: String -> Array[Float lat, Float lng] | Array[]
export function latLngfromString(latLng) {
  if (!is(String, latLng)) return emptyArray;
  const parseLatLng = compose(toArrayOfFloats, split(","));

  return parseLatLng(latLng);
}

export const isValidLatLng = latLng => RE_VALID_LATLNG.test(latLng);

/**
 * Converts degrees to radians
 * @param {*} deg degrees
 */
const deg2rad = deg => (deg * 2.0 * Math.PI) / 360;

const m1 = 111132.95255;
const m2 = -559.84957;
const m3 = 1.17514;
const m4 = -0.0023;
const p1 = 111412.87733;
const p2 = -93.50412;
const p3 = 0.11774;

/**
 * Gets the latitude length in meters
 * @param {*} lat latitude (degrees)
 */
export const latLen = lat => {
  const latRad = deg2rad(lat);
  return (
    m1 +
    m2 * Math.cos(2 * latRad) +
    m3 * Math.cos(4 * latRad) +
    m4 * Math.cos(6 * latRad)
  );
};
/**
 * Gets the longitude length in meters
 * @param {*} lat latitude (degrees)
 */

export const lonLen = lat => {
  const latRad = deg2rad(lat);
  return (
    p1 * Math.cos(latRad) +
    p2 * Math.cos(3 * latRad) +
    p3 * Math.cos(5 * latRad)
  );
};
/**
 * Loops at a certain value if value is greater or less then the limit
 *
 * Example: lonLenLooper(100, 90) will return -80 and lonLenLooper(-100, 90) will return 80
 * @param value value
 * @param limit limit
 */
export const lonLenLooper = (value, limit) =>
  value > limit
    ? value - limit * 2
    : value < -limit
    ? value + limit * 2
    : value;

/**
 * Loops the value around the latitude
 * @param {*} lat latitude (degrees)
 */
export const latLooper = lat => lonLenLooper(lat, 90);
/**
 * Loops the value around the longitude
 * @param {*} long longitude (degrees)
 */
export const longLooper = long => lonLenLooper(long, 180);
