import { graphql } from "@apollo/client/react/hoc";
import { withStyles } from "@material-ui/core/styles";
import ProgressCard from "components/cards/progress_card";
import { Loading } from "components/notification";
import { Page } from "components/page";
import { updateAuthUserDetails } from "ducks/auth";
import { setNotificationMessage } from "ducks/system";
import { Profile } from "forms_new/user";
import { ME_QUERY, UPDATE_ME_MUTATION } from "graphql/queries";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "react-recompose";
import { normalizePhone } from "utils/general_utils";

const styles = theme => ({
  progressCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  progressCardContent: {
    margin: theme.spacing(3)
  }
});

const ProfileEdit = ({ classes, isLoading, meQuery, onConfirm }) => (
  <Page title="Your Profile">
    <ProgressCard isLoading={meQuery.loading} classes={classes}>
      {meQuery.loading ? (
        <Loading />
      ) : (
        <Profile
          initialModel={meQuery.me}
          onSubmit={onConfirm}
          isLoading={isLoading}
        />
      )}
    </ProgressCard>
  </Page>
);

ProfileEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  meQuery: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const _withState = withState("isLoading", "setIsLoading", false);

const _withHandlers = withHandlers({
  onConfirm: ({
    setIsLoading,
    updateAuthUserDetails,
    setNotificationMessage,
    updateMeMutation
  }) => model => {
    setIsLoading(true);

    const input = R.pipe(
      R.omit(["email", "id", "userName"]),
      R.evolve({ phone: normalizePhone }),
      R.reject(R.isNil)
    )(model);

    updateMeMutation({ variables: { input } })
      .then(response => {
        const authDetails = R.pipe(
          R.path(["data", "updateMe"]),
          R.pick(["firstName", "lastName"])
        )(response);
        updateAuthUserDetails(authDetails);
        setNotificationMessage("Profile saved!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
});

const mapDispatchToProps = {
  setNotificationMessage,
  updateAuthUserDetails
};

export default compose(
  connect(undefined, mapDispatchToProps),
  withStyles(styles),
  graphql(ME_QUERY, { name: "meQuery" }),
  graphql(UPDATE_ME_MUTATION, { name: "updateMeMutation" }),
  _withState,
  _withHandlers
)(ProfileEdit);
