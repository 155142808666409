import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DateTimePicker } from "components/date_time";
import { isAfter, isBefore, isSameDay } from "date-fns";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";

export const startDatePickerFilter = R.curry(
  (endDate, date) => isSameDay(date, endDate) || isBefore(date, endDate)
);

export const endDatePickerFilter = R.curry(
  (startDate, date) =>
    (isSameDay(date, startDate) || isAfter(date, startDate)) &&
    (isSameDay(date, new Date()) || isBefore(date, new Date()))
);

const AdHocForm = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange
}) => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      <Typography component="h5">Export data from period</Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "center" }}>
      <DateTimePicker
        key="startTime"
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        onChange={handleStartDateChange}
        filterDate={startDatePickerFilter(endDate)}
      />
    </Grid>
    <Grid item xs={6} style={{ textAlign: "center" }}>
      <DateTimePicker
        key="endTime"
        isEndPicker
        startDate={startDate}
        endDate={endDate}
        selected={endDate}
        onChange={handleEndDateChange}
        filterDate={endDatePickerFilter(startDate)}
      />
    </Grid>
  </Grid>
);

AdHocForm.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func
};

export default AdHocForm;
