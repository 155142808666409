import { handleActions } from "redux-actions";
import { fromJS } from "immutable";

export const SYSTEM_ERROR = "SYSTEM_ERROR";
export const SYSTEM_WARNING = "SYSTEM_WARNING";
export const SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION";
export const SYSTEM_CLEAR_MESSAGES = "SYSTEM_CLEAR_MESSAGES";

export const setError = (message, options = {}) => {
  return {
    type: SYSTEM_ERROR,
    message,
    ...options
  };
};

export const setWarning = message => ({ type: SYSTEM_WARNING, message });
export const setNotificationMessage = message => ({
  type: SYSTEM_NOTIFICATION,
  message
});
export const clearMessages = () => ({ type: SYSTEM_CLEAR_MESSAGES });

// middleware
export const initialState = fromJS({
  warning: null,
  error: null,
  notification: { message: null }
});

export default handleActions(
  {
    [SYSTEM_ERROR]: (state, action) =>
      state.merge({
        error: action.message,
        visibleDuration: action.visibleDuration
      }),
    [SYSTEM_WARNING]: (state, action) =>
      state.merge({
        warning: action.message,
        visibleDuration: action.visibleDuration
      }),
    [SYSTEM_NOTIFICATION]: (state, action) =>
      state.merge({
        notification: { message: action.message },
        visibleDuration: action.visibleDuration
      }),
    [SYSTEM_CLEAR_MESSAGES]: state => {
      return state.merge({
        error: null,
        warning: null,
        notification: { message: null },
        visibleDuration: 5000
      });
    }
  },
  initialState
);
