export const base = { initialModel: {}, fields: [], schema: {}, defaults: {} };
export const ComposeForm = (...fragments) => props => {
  const combinedForm = fragments.reduce(
    (acc, fragment) => {
      const fragmentWithProps = fragment ? fragment(props) : base;
      Object.keys(base).forEach(k => {
        if (Array.isArray(base[k])) {
          acc[k] = [...acc[k], ...(fragmentWithProps[k] || [])];
        } else if (typeof acc[k] === "object") {
          acc[k] = { ...acc[k], ...fragmentWithProps[k] };
        }
      });
      return acc;
    },
    { ...base }
  ); // important not to mutate the base object since it will otherwise accumulate through calls
  return combinedForm;
};
