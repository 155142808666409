import * as R from "ramda";
import { fromJS } from "immutable";

export const getSearchOptions = (payload, size) => {
  const from = R.propOr(fromJS({}), "period", payload)
    .get("from")
    .valueOf();
  const to = R.propOr(fromJS({}), "period", payload)
    .get("to")
    .valueOf();

  const thingTypes = R.prop("thingType", payload);

  return {
    filter: {
      classification: R.prop("classification", payload),
      thingName: R.prop("thingName", payload),
      thingTypes: thingTypes ? [thingTypes] : undefined,
      from,
      to
    },
    paging: { size: size || 50 }
  };
};

export const eventActionType = R.path(["payload", "data", "type"]);
export const eventActionBatch = R.path(["payload", "data", "source", "batch"]);
export const eventActionThingType = R.path([
  "payload",
  "data",
  "source",
  "thingType"
]);
