import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { resourceLabelById } from "ducks/schema/schema_selectors";
import {
  withDefault,
  withTimezone,
  withVariables
} from "graphql/utils/general";
import { fromJS } from "immutable";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, pure, withPropsOnChange } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import { resourceStatePath } from "utils/widget_utils";

const mapStateToProps = (state, props) => {
  const { widget, container, layout, resource, unit } = props;
  const resourceId = resourceIdFromWidget(widget);
  const resources = widgetProp(widget, "resources");
  const resourceStatePathList = resources
    ? resources?.map(resource =>
        resourceStatePath(props.subthing, resource.resourceId)
      )
    : resourceId
    ? [resourceStatePath(props.subthing, resourceId)]
    : [];

  return {
    container: container,
    layout: layout,
    resource: resource,
    unit: unit,
    widget: widget,
    resourceLabel:
      resourceLabelById(state, { id: resourceId }) || props.resourceName,
    observationPeriod: props.observationPeriod,
    resourceStatePathList,
    thingName: props.params.thingName,
    thingType: props.params.thingType,
    from: imu.get(props.observationPeriod, "from").toISOString(),
    to: imu.get(props.observationPeriod, "to").toISOString()
  };
};

const thingObservationsQuery = gql`
  query(
    $thingName: String!
    $thingType: String!
    $resourceStatePathList: [String!]
    $from: String!
    $to: String!
    $timestampFormat: String!
    $timezone: String
  ) {
    thingObservations(
      searchOptions: {
        filter: {
          thingName: $thingName
          period: { from: $from, to: $to }
          resources: $resourceStatePathList
          thingTypes: [$thingType]
        }
        paging: { size: 100 }
        sorting: { sort: "-timestamp" }
      }
    ) {
      pageInfo {
        totalCount
      }
      observations {
        timestamp(
          momentFunction: {
            name: format
            arguments: [$timestampFormat]
            timezone: $timezone
          }
        )
        state
      }
    }
  }
`;

const OBSERVATIONS_PATH = [
  "thingObservationsQuery",
  "thingObservations",
  "observations"
];

const withThingObservations = compose(
  connect(mapStateToProps),
  graphql(thingObservationsQuery, {
    name: "thingObservationsQuery",
    options: withVariables(
      [
        "thingName",
        "thingType",
        "resourceStatePathList",
        "from",
        "to",
        "timestampFormat"
      ],
      withDefault("timestampFormat", ""),
      withTimezone
    )
  }),
  withPropsOnChange(
    (props, nextProps) =>
      R.path(OBSERVATIONS_PATH, props) !== R.path(OBSERVATIONS_PATH, nextProps),
    props => {
      const list = [];
      R.pipe(
        R.path(OBSERVATIONS_PATH),
        R.defaultTo([]),
        R.forEach(observation => {
          const resourcePaths = props.resourceStatePathList.filter(path =>
            R.hasPath(["state", ...path.split(".")])(observation)
          );
          resourcePaths.forEach(resourcePath =>
            list.push(
              R.applySpec({
                timestamp: R.prop("timestamp"),
                value: R.path(["state", ...resourcePath.split(".")]),
                resourcePath: () => resourcePath,
                state: R.prop("state")
              })(observation)
            )
          );
        })
      )(props);
      return {
        observations: fromJS(list)
      };
    }
  ),
  pure
);

export default withThingObservations;
