import { stringify } from "querystring";

const globals = {
  window: window
};

export default (account, env, { window } = globals) =>
  `https://1u31fuekv5.execute-api.eu-west-1.amazonaws.com/prod/manifest/?${stringify(
    { hostname: window.location.hostname, account, env }
  )}`;
