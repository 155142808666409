/* eslint-disable react/no-find-dom-node */
/**
 * Created by stephenwhite on 25/11/15.
 */
import {
  differenceInWeeks,
  format as formatDate,
  formatDistanceToNow,
  intervalToDuration,
  sub as dateSubtract
} from "date-fns";
import ReactDOM from "react-dom";
import { isDevelopmentNodeEnv } from "./general_utils";

export const sortViewModes = viewModes =>
  viewModes.sort((item1, item2) => {
    const timestamp1 = item1.get("timestamp");
    const timestamp2 = item2.get("timestamp");
    if (!timestamp1 && timestamp2) {
      return -1;
    }
    if (timestamp1 && !timestamp2) {
      return 1;
    }
    if (timestamp1 === timestamp2) {
      return 0;
    }
    return timestamp1 < timestamp2 ? -1 : 1;
  });

export function convertForDropDown(list, payloadKey, textKey) {
  if (list) {
    return sortViewModes(list).map(item => {
      const ret = {};
      ret[payloadKey] = item.get(payloadKey);
      ret[textKey] = item.get(textKey);
      if (ret[payloadKey] && !ret[textKey]) {
        ret[textKey] = ret[payloadKey];
      }
      return ret;
    });
  }

  return null;
}

export function reindexViewModes(thingType) {
  const viewModes = thingType.getIn(["ViewData", "viewModes"]);
  let i = 0;
  let tmpThingType = thingType;
  sortViewModes(viewModes).forEach((item, key) => {
    tmpThingType = tmpThingType.setIn(
      ["ViewData", "viewModes", key, "index"],
      i
    );
    i++;
  });
  return tmpThingType;
}

export function getBounds(element) {
  if (element) {
    let dm = null;
    if (element.getBoundingClientRect) {
      dm = element;
    } else {
      dm = ReactDOM.findDOMNode(element);
    }
    if (dm) {
      return { height: dm.clientHeight, width: dm.clientWidth };
    }
  }
  return null;
}

export const friendlyErrorMessage = error => {
  if (!error) return {};

  const messages = [
    {
      /* Login:
       * 1dfa2b45-e20b-11e5-a0f8-8b5691b772c3 Task timed out after 30.00 seconds
       */
      unfriendly: "Task timed out",
      friendly: "The server timed out. Please try again later."
    },
    {
      /* Login:
       * Invalid login token. Token is expired.
       */
      unfriendly: "Invalid login token. Token is expired.",
      friendly:
        "You have been logged out due to inactivity. Please log in again."
    },
    {
      /* Login:
       *   Missing credentials in config
       */
      unfriendly: "Missing credentials in config",
      friendly:
        "You have been logged out due to inactivity. Please log in again."
    }
    /* {ThingShadow: No shadow exists with name
     * This is ie empty state in thingshadow view

     unfriendly: 'No shadow exists with name',
     friendly: 'No data available. Most likely this thing has not connected yet.',
     },*/
  ];

  const message = messages.find(m => error.indexOf(m.unfriendly) > -1);
  return message ? message.friendly : error;
};

/**
 * Because of the array of proxies we use for Lambdas, DynaDb, ElasticSearch etc ...
 * we sometimes get error as just a string or error.message or even error.errorMessage ..
 * We need to look over this at some point.
 * @param error
 * @returns {*}
 */
export const errorMessage = error => {
  if (!error) return null;

  if (error && error.message) {
    return error.message;
  }
  if (error && error.errorMessage) {
    return error.errorMessage;
  }
  // Return sane error messages. Can this be done in a better way/place?
  return isDevelopmentNodeEnv() ? error : friendlyErrorMessage(error);
};

export const convertObservationPeriod = (period = "1w") => {
  const to = new Date();
  let from = dateSubtract(to, { hours: 12 });

  switch (period) {
    case "1M":
      from = dateSubtract(to, { months: 1 });
      break;
    case ">1M":
      from = dateSubtract(to, { months: 1, weeks: 1 });
      break;
    case "1w":
      from = dateSubtract(to, { weeks: 1 });
      break;
    case ">1w":
      from = dateSubtract(to, { weeks: 2 });
      break;
    case "1d":
      from = dateSubtract(to, { days: 1 });
      break;
    case ">1d":
      from = dateSubtract(to, { days: 2 });
      break;
    case "12h":
      from = dateSubtract(to, { hours: 12 });
      break;
    case "3h":
      from = dateSubtract(to, { hours: 3 });
      break;
    case "30m":
      from = dateSubtract(to, { minutes: 30 });
      break;
    case "10m":
      from = dateSubtract(to, { minutes: 10 });
      break;
    case "5m":
      from = dateSubtract(to, { minutes: 5 });
      break;
    case "1m":
      from = dateSubtract(to, { minutes: 1 });
      break;
    case "30s":
      from = dateSubtract(to, { seconds: 30 });
      break;
    default:
  }
  return { from, to };
};

export const convertDatesToRange = ({ from, to }) => {
  if (!from || !to) return "1w";
  const duration = to.getTime() - from.getTime();
  const { months, days } = intervalToDuration({ start: from, end: to });
  const weeks = differenceInWeeks(to, from);
  const durations = [
    { range: "30s", isWithinRange: duration <= 30000 },
    { range: "5m", isWithinRange: duration <= 300000 },
    { range: "10m", isWithinRange: duration <= 600000 },
    { range: "30m", isWithinRange: duration <= 1800000 },
    { range: "3h", isWithinRange: duration <= 10800000 },
    { range: "12h", isWithinRange: duration <= 43200000 },
    { range: "1d", isWithinRange: duration <= 86400000 },
    {
      range: ">1d",
      isWithinRange: days > 0 && days < 7 && months < 1
    },
    {
      range: "1w",
      isWithinRange: weeks === 1 && days === 7
    },
    {
      range: ">1w",
      isWithinRange: weeks >= 1 && months === 0
    },
    {
      range: "1M",
      isWithinRange: months === 1 && days === 0
    },
    {
      range: ">1M",
      isWithinRange: months >= 1 && days > 0
    }
  ];
  return durations.reduce((range, current) => {
    if (range.length > 0) return range;
    return current.isWithinRange ? current.range : "";
  }, "");
};

export const convertObservationPeriodAsDates = (period = "1w") => {
  const { from, to } = convertObservationPeriod(period);
  return { from, to };
};

const EPOCH_LOWER_LIMIT = 315532800000; // 1980-01-01T00:00:00.000Z
const INVALID_DATE_FORMATTED = null;

const timestampToDate = timestamp =>
  timestamp < EPOCH_LOWER_LIMIT
    ? new Date(timestamp * 1000)
    : new Date(timestamp);

export const formatTime = timestamp =>
  timestamp
    ? formatDate(timestampToDate(timestamp), "yyyy-MM-dd HH:mm:ss")
    : INVALID_DATE_FORMATTED;

export const timeFrom = timestamp =>
  timestamp
    ? formatDistanceToNow(timestampToDate(timestamp), { addSuffix: true })
    : INVALID_DATE_FORMATTED;

export function sizeChange(size, nextSize) {
  if (!size && !nextSize) return false;
  if (!size && nextSize) return true;
  return size.height !== nextSize.height || size.width !== nextSize.width;
}

export function numberOnly(value) {
  if (!value) return value;
  let istr = `${value}`;
  if (isNaN(istr.slice(-1))) {
    const end = istr.length > 1 ? istr.length - 1 : 1;
    istr = istr.slice(0, end);
    if (isNaN(istr)) {
      value = 1;
    } else {
      value = Number(istr);
    }
  }
  return Number(value);
}
