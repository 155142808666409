import { add as dateAdd } from "date-fns";
import { timer } from "rxjs";

export const duration = period => {
  if (!period) {
    throw new Error("period not defined");
  }

  if (!period.value && period.value !== 0) {
    throw new Error("period.value not defined");
  }

  if (!period.unit) {
    throw new Error("period.unit not defined");
  }

  const ms = dateAdd(new Date(0), { [period.unit]: period.value }).getTime();

  if (!ms || ms <= 0) {
    throw new Error(
      "invalid period definition, duration must be more than 0 ms"
    );
  }
  return ms;
};

const simulatorPayloadTimer = period => {
  return timer(0, duration(period));
};

export default simulatorPayloadTimer;
