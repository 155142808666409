import { Record } from "immutable";

export const DashboardViewState = new Record({
  isMovingWidgets: false
});

export const DashboardKeypath = new Record({
  dashboardId: undefined,
  viewModeId: undefined,
  gridId: undefined,
  widgetId: undefined
});

export const Dashboard = new Record({
  id: undefined,
  name: "",
  user: undefined,
  thingType: undefined,
  isMovingWidgets: false,
  viewModes: []
});

export const Viewmode = new Record({
  id: undefined,
  label: "",
  description: "",
  grids: []
});

export const Grid = new Record({
  id: undefined,
  label: "",
  type: "", // overview, list, thing, subthing
  thingName: undefined, // if the submode is of a thing or subthing type then this is the connection to the thingTaxonomy, all resources can be retrieved via this fk ( see thing type model )
  description: "",
  widgets: [],
  layout: undefined
});

export const Widget = new Record({
  id: undefined,
  type: "value", // default to value, we have: Value, Gauge, Map, Table, Timeseries, Events, ThingCredentials, ThingsMap, Histogram
  label: "",
  props: {} // loose bag of props based upon it's edit form, usual candidates are resource, unit, max, min etc .. really depends on widget form
});

export const Props = new Record({
  id: undefined,
  columnConfig: []
});

export const ColumnConfig = new Record({
  id: undefined,
  isDefault: false,
  visible: false,
  checked: false,
  selector: "label",
  text: undefined,
  label: undefined,
  type: "text"
});

export const Layout = new Record({
  id: undefined,
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: []
});

export const Position = new Record({
  i: undefined, // widget id fk
  x: 0,
  y: 0,
  h: 3,
  w: 3
});

export const Request = new Record({
  isLoading: false,
  error: null
});
