import { graphql } from "@apollo/client/react/hoc";
import { GET_THING_TYPE } from "graphql/queries";
import * as R from "ramda";
import { compose, withPropsOnChange } from "react-recompose";

const _withThingTypeId = withPropsOnChange(
  (props, nextProps) => props.params.thingType != nextProps.params.thingType,
  props => ({ thingTypeId: R.path(["params", "thingType"])(props) })
);

const _withThingType = withPropsOnChange(["getThingTypeQuery"], props => ({
  thingType: R.path(["getThingTypeQuery", "thingType"])(props),
  isLoadingThingType: R.path(["getThingTypeQuery", "loading"])(props)
}));

export const withThingType = () => {
  return compose(
    _withThingTypeId,
    graphql(GET_THING_TYPE, { name: "getThingTypeQuery" }),
    _withThingType
  );
};
