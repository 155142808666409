import { css } from "styled-components";
import telenorBold from "../fonts/telenor-bold.woff";
import telenorLight from "../fonts/telenor-light.woff";
import telenorMedium from "../fonts/telenor-medium.woff";
import telenorRegular from "../fonts/telenor-regular.woff";

export default css`
  @font-face {
    font-family: "telenor-bold";
    font-style: normal;
    font-weight: bold;
    src: url(${telenorBold}) format("woff");
  }

  @font-face {
    font-family: "telenor-light";
    font-style: normal;
    font-weight: lighter;
    src: url(${telenorLight}) format("woff");
  }

  @font-face {
    font-family: "telenor-medium";
    font-style: normal;
    font-weight: 500;
    src: url(${telenorMedium}) format("woff");
  }

  @font-face {
    font-family: "telenor-regular";
    font-style: normal;
    font-weight: normal;
    src: url(${telenorRegular}) format("woff");
  }
`;
