import {
  both,
  either,
  filter,
  has,
  isEmpty,
  isNil,
  pathOr,
  pipe,
  reject
} from "ramda";

export const adaptAnalyses = pipe(
  pathOr({}, ["data", "tcxn", "analyses"]),
  reject(either(isNil, isEmpty)),
  filter(both(has("name"), has("resources")))
);
