import { BUCKET_UPLOAD_REQUEST, uploadSuccess, uploadFailure } from "./bucket";
import { Observable } from "rxjs/Observable";

import { setNotificationMessage } from "ducks/system";

export const uploadEpic = (action$, store, { api }) =>
  action$
    .ofType(BUCKET_UPLOAD_REQUEST)
    .mergeMap(({ payload: { file, refetch, widgetId, ...attributes } }) =>
      api.files
        .uploadV2$(attributes, file)
        .map(() => Observable.fromPromise(refetch()))
        .map(() => uploadSuccess({ widgetId }))
        .catch(({ message }) =>
          Observable.of(
            setNotificationMessage(
              `Could not upload file. Message: ${message}`
            ),
            uploadFailure({ widgetId })
          )
        )
    );
