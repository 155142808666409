import React from "react";
import PropTypes from "prop-types";
import {
  compose,
  mapProps,
  withHandlers,
  withState,
  pure
} from "react-recompose";
import HighLightOff from "@material-ui/icons/HighlightOff";
import { Map } from "immutable";
import * as colors from "themes/colors";
import ResourcesDropdown from "./resources_dropdown";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const initialState = Map({
  hasSelectedResource: false
});

const iconCss = { fill: colors.colorDustyGrayApprox, padding: "0 10px 0 0" };
const selectCss = { width: "150px" };
const containerCss = {
  display: "inline-block",
  verticalAlign: "middle",
  position: "relative",
  paddingRight: "10px",
  marginTop: "20px"
};
const textFieldStyle = {
  width: 100,
  paddingLeft: "5px",
  paddingRight: "5px",
  marginTop: "16px"
};
const seperatorCss = {
  height: "45px",
  display: "inline-block",
  top: "10px",
  bottom: "10px",
  background: colors.colorDustyGrayApprox,
  width: "1px",
  position: "absolute"
};

const truncateValue = (_length, _value) => {
  if (_value)
    return _value.length < _length ? _value : `...${_value.slice(-_length)}`;
  else return false;
};

const Resource = ({
  index,
  hasSelectedResource,
  onPickResource,
  onRemoveResourceFilter,
  onChangeOperator,
  onChangeValue,
  label,
  name,
  value,
  operator,
  thingTypeId
}) => {
  return hasSelectedResource ? (
    <div style={containerCss}>
      <FormControl>
        <InputLabel>
          {truncateValue(35, name) || truncateValue(35, label) || ""}
        </InputLabel>
        <Select
          style={selectCss}
          hintText={operator}
          value={operator}
          onChange={onChangeOperator}
        >
          <MenuItem value="gt" key="ab">
            Above
          </MenuItem>
          <MenuItem value="eq" key="eq">
            Equal
          </MenuItem>
          <MenuItem value="lt" key="be">
            Below
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <TextField
          id={`resource-filter-text-${index}`}
          defaultValue={value}
          underlineShow
          style={textFieldStyle}
          onChange={onChangeValue}
        />
      </FormControl>
      <HighLightOff style={iconCss} onClick={onRemoveResourceFilter(index)} />
      <span style={seperatorCss}>&nbsp;</span>
    </div>
  ) : (
    <ResourcesDropdown
      onPickResource={onPickResource}
      thingTypeId={thingTypeId}
    />
  );
};

Resource.propTypes = {
  index: PropTypes.number,
  hasSelectedResource: PropTypes.bool,
  value: PropTypes.string,
  operator: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  setResourceFilter: PropTypes.func,
  onPickResource: PropTypes.func,
  onChangeOperator: PropTypes.func,
  onChangeValue: PropTypes.func,
  onRemoveResourceFilter: PropTypes.func,
  thingTypeId: PropTypes.string
};

Resource.defaultProps = {
  hasSelectedResource: false
};

export default compose(
  withState("state", "setState", initialState),
  mapProps(props => ({
    ...props
  })),
  withHandlers({
    onRemoveResourceFilter: ({ removeResourceFilter }) => index => () => {
      removeResourceFilter(index);
    },
    onChangeOperator: ({ index, setResourceFilter }) => event => {
      setResourceFilter({
        index,
        item: {
          operator: event.target.value
        }
      });
    },
    onChangeValue: ({ index, setResourceFilter }) => event => {
      setResourceFilter({
        index,
        item: {
          value: event.target.value
        }
      });
    },
    onPickResource: ({ index, setResourceFilter }) => (event, suggestion) => {
      setResourceFilter({
        index,
        item: {
          name: suggestion.id,
          hasSelectedResource: true,
          type: suggestion.type
        }
      });
    }
  }),
  pure
)(Resource);
