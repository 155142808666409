import WidgetEditResource from "components/widget/edit/widget_edit_resource";
import WidgetEditSetable from "components/widget/edit/widget_edit_setable";
import WidgetEditObservationsAggregated from "components/widget/edit/widget_edit_observations_aggregated";
import { ComposeForm } from "components/widget/edit/compose_form";
import WidgetEditRealtime from "../../edit/widget_edit_realtime";
const WidgetEditTable = WidgetEditResource;

export default ComposeForm(
  WidgetEditTable,
  WidgetEditSetable,
  WidgetEditRealtime,
  WidgetEditObservationsAggregated
);
