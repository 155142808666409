import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const ModalDialog = ({
  title,
  text,
  textSecondary,
  actions,
  children,
  ...rest
}) => (
  <Dialog {...rest}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
      <DialogContentText>{textSecondary}</DialogContentText>
      {children}
    </DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);

ModalDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  textSecondary: PropTypes.string,
  actions: PropTypes.array,
  children: PropTypes.node
};

export default ModalDialog;
