const notFoundInManifestErrors = /^\w+, \w+, not found in manifest$/i;
const refreshErrors = /Token is expired|Invalid login token|Missing credentials in config/i;

export default error => {
  if (typeof error === "string") {
    return notFoundInManifestErrors.test(error)
      ? false
      : refreshErrors.test(error);
  } else if (error != null && typeof error.message === "string") {
    return refreshErrors.test(error.message);
  } else {
    return false;
  }
};
