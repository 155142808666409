import { createAction } from "redux-actions";
export const DOMAINS_GET_REQUEST = "DOMAINS_GET_REQUEST";
export const DOMAINS_GET_SUCCESS = "DOMAINS_GET_SUCCESS";
export const DOMAINS_GET_FAILURE = "DOMAINS_GET_FAILURE";

export const DOMAINS_SHOULD_DO_REQUEST = "DOMAINS_SHOULD_DO_REQUEST";

export const DOMAINS_REQUEST_RESET = "DOMAINS_REQUEST_RESET";

export const DOMAINS_LIST_REQUEST = "DOMAINS_LIST_REQUEST";
export const DOMAINS_LIST_SUCCESS = "DOMAINS_LIST_SUCCESS";
export const DOMAINS_LIST_FAILURE = "DOMAINS_LIST_FAILURE";

export const DOMAINS_CLEAR_CURRENT = "DOMAINS_CLEAR_CURRENT";

export const getDomain = createAction(DOMAINS_GET_REQUEST);
export const getDomainSuccess = createAction(DOMAINS_GET_SUCCESS);
export const getDomainFailure = createAction(DOMAINS_GET_FAILURE);

export const getDomains = createAction(DOMAINS_SHOULD_DO_REQUEST);
export const getDomainsRequest = createAction(DOMAINS_LIST_REQUEST);
export const getDomainsFailure = createAction(DOMAINS_LIST_FAILURE);
export const getDomainsSuccess = createAction(DOMAINS_LIST_SUCCESS);

export const clearCurrentDomain = createAction(DOMAINS_CLEAR_CURRENT);
