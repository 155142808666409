import { Observable, defer } from "rxjs";
import { refreshToken$, refreshTokenLock } from "../utils/refresh_token";
import { traceLog, traceErr } from "../utils/trace";
import shouldRefreshToken from "../utils/should_refresh_token";
import wrapInvokeLambda$ from "../utils/wrap_invoke_lambda";

export const invoke$ = (functionName, payload) => {
  traceLog("[invoke$]", functionName, payload);
  return refreshTokenLock
    .sync(wrapInvokeLambda$(functionName, payload))
    .catch(error => {
      if (shouldRefreshToken(error)) {
        traceLog("[invoke$] refreshing token");
        return refreshTokenLock
          .singleton(defer(() => refreshToken$()))
          .mergeMap(() => wrapInvokeLambda$(functionName, payload));
      } else {
        traceErr(`[invoke$] lambda error: ${functionName} ${payload}`, error);
        return Observable.throw(error);
      }
    });
};

export const invoke = (functionName, payload) => {
  return invoke$(functionName, payload).toPromise();
};
