import { createAction, handleActions } from "redux-actions";
import * as R from "ramda";
import { getMessageOrError } from "utils/error_message_utils";
import { updateExistingResource, renameMetadata } from "./schema_utils";

export const ADD_RESOURCE = "ADD_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const UPDATE_RESOURCE_LABEL = "UPDATE_RESOURCE_LABEL";
export const NEW_RESOURCE = "NEW_RESOURCE";
export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const CREATE_RESOURCE_FAILURE = "CREATE_RESOURCE_FAILURE";
export const SAVE_RESOURCE = "SAVE_RESOURCE";
export const SAVE_RESOURCE_SUCCESS = "SAVE_RESOURCE_SUCCESS";
export const SAVE_RESOURCE_FAILURE = "SAVE_RESOURCE_FAILURE";
export const SCHEMA_UPDATE_RECEIVED = "SCHEMA_UPDATE_RECEIVED";
export const SCHEMAS_LOAD_FAILURE = "SCHEMAS_LOAD_FAILURE";
export const SCHEMAS_LOAD_REQUEST = "SCHEMAS_LOAD_REQUEST";
export const SCHEMAS_LOAD_SUCCESS = "SCHEMAS_LOAD_SUCCESS";
export const SCHEMA_LOAD_FAILURE = "SCHEMA_LOAD_FAILURE";
export const SCHEMA_LOAD_REQUEST = "SCHEMA_LOAD_REQUEST";
export const SCHEMA_LOAD_SUCCESS = "SCHEMA_LOAD_SUCCESS";
export const SCHEMA_UPDATED = "SCHEMA_UPDATED";
export const SAVE_OPTIONS = "SAVE_OPTIONS";
export const SAVE_OPTIONS_SUCCESS = "SAVE_OPTIONS_SUCCESS";
export const SAVE_OPTIONS_FAILURE = "SAVE_OPTIONS_FAILURE";
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAILURE = "GET_OPTIONS_FAILURE";
export const SET_SELECTED_RESOURCE = "SET_SELECTED_RESOURCE";

export const SYNC_UPDATED_RESOURCE = "SYNC_UPDATED_RESOURCE";
export const SYNC_NEW_RESOURCE = "SYNC_NEW_RESOURCE";
export const syncUpdatedResource = createAction(SYNC_UPDATED_RESOURCE);
export const syncNewResource = createAction(SYNC_NEW_RESOURCE);

export const addResource = createAction(ADD_RESOURCE);
export const newResource = createAction(NEW_RESOURCE);
export const deleteResource = createAction(DELETE_RESOURCE);
export const updateResourceLabel = createAction(UPDATE_RESOURCE_LABEL);
export const createResource = createAction(CREATE_RESOURCE);
export const createResourceFailure = createAction(CREATE_RESOURCE_FAILURE);
export const saveResource = createAction(SAVE_RESOURCE);
export const saveResourceFailure = createAction(SAVE_RESOURCE_FAILURE);
export const saveResourceSuccess = createAction(SAVE_RESOURCE_SUCCESS);

export const loadSchema = createAction(SCHEMA_LOAD_REQUEST);
export const loadSchemaFailure = createAction(SCHEMA_LOAD_FAILURE);
export const loadSchemaSuccess = createAction(SCHEMA_LOAD_SUCCESS);

export const loadSchemas = createAction(SCHEMAS_LOAD_REQUEST);
export const loadSchemasFailure = createAction(SCHEMAS_LOAD_FAILURE);
export const loadSchemasSuccess = createAction(SCHEMAS_LOAD_SUCCESS);

export const schemaUpdated = createAction(SCHEMA_UPDATED);
export const schemaUpdateReceived = createAction(SCHEMA_UPDATE_RECEIVED);
export const saveOptions = createAction(SAVE_OPTIONS);
export const saveOptionsSuccess = createAction(SAVE_OPTIONS_SUCCESS);
export const saveOptionsFailure = createAction(SAVE_OPTIONS_FAILURE);
export const getOptions = createAction(GET_OPTIONS);
export const getOptionsSuccess = createAction(GET_OPTIONS_SUCCESS);
export const getOptionsFailure = createAction(GET_OPTIONS_FAILURE);

export const setLastSelectedResource = createAction(SET_SELECTED_RESOURCE);

export const resourceKeys = ["id", "name", "elementId", "type", "resourceId"];
export const staticMetadataKeys = ["label", "unit", "widgettype", "isSettable"];
export const omitMetadataKeys = ["isVirtual", "toDelete"];
export const newResourceEntity = ({ thingType, id = "new", type }) => ({
  id,
  isNew: true,
  name: "",
  type,
  elementId: thingType,
  metadata: {
    label: "",
    unit: "",
    isVirtual: false,
    isSettable: false,
    widgettype: "Value"
  }
});

const initialState = {
  isLoading: false,
  error: null,
  entities: { schemaElement: {} },
  result: []
};

export default handleActions(
  {
    [SCHEMA_LOAD_REQUEST]: (state, action) =>
      R.mergeDeepLeft(
        {
          isLoading: true,
          error: null
        },
        state
      ),

    [SCHEMA_LOAD_SUCCESS]: (state, action) =>
      R.mergeDeepLeft(
        {
          isLoading: false,
          entities: action.payload.entities ? action.payload.entities : {},
          result: R.union(
            R.propOr([], "result", state),
            action.payload.result ? action.payload.result : []
          )
        },
        state
      ),

    [SCHEMA_LOAD_FAILURE]: (state, action) =>
      R.mergeDeepLeft(
        {
          isLoading: false,
          error: getMessageOrError(action.payload)
        },
        state
      ),

    [SCHEMAS_LOAD_REQUEST]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: true,
          error: null
        },
        state
      );
    },

    [SCHEMAS_LOAD_SUCCESS]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: false,
          entities: action.payload.entities ? action.payload.entities : {},
          result: action.payload.result
        },
        state
      );
    },

    [SCHEMA_UPDATE_RECEIVED]: (state, { payload }) => {
      const schema = payload.data.source.schema;
      const newState = R.mergeDeepLeft(
        {
          isLoading: false,
          entities: schema.entities ? schema.entities : {}
        },
        state
      );
      let imResults = newState.result || [];
      schema.result.forEach(r => {
        if (imResults.indexOf(r) === -1) {
          imResults.push(r);
        }
      });
      return R.set(R.lensProp("result"), imResults, newState);
    },

    [SCHEMAS_LOAD_FAILURE]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: false,
          error: getMessageOrError(action.payload)
        },
        state
      );
    },

    [DELETE_RESOURCE]: (state, { payload: { resourceId } }) => {
      if (!resourceId) return state;
      let newState = R.dissocPath(["entities", "resources", resourceId], state);

      const elementIdString = R.pipe(
        R.split("/"),
        R.dropLast(1),
        R.join("/")
      )(resourceId);

      newState = R.set(
        R.lensPath(["entities", "schemaElement", elementIdString, "resources"]),
        [],
        newState
      );

      return newState;
    },
    [CREATE_RESOURCE]: (state, { payload: { resource, options } }) =>
      R.mergeDeepLeft(
        {
          isLoading: true,
          error: undefined,
          new: { ...resource, isNew: true, settableOptions: options }
        },
        state
      ),
    [ADD_RESOURCE]: (state, { payload }) => {
      const type = payload.resourceType ? payload.resourceType : "string";
      const vrPath = payload.resourcePath.split("/");
      const virtualName = vrPath[vrPath.length - 1];
      const elementId = vrPath.slice(0, vrPath.length - 1);
      let newState = R.mergeDeepLeft(
        {
          entities: {
            resources: {
              [payload.resourcePath]: {
                id: payload.resourcePath,
                elementId: elementId.join("/"),
                name: virtualName,
                type
              }
            }
          }
        },
        state
      );
      const elementIdString = elementId.join("/");
      let resources = R.pathOr(
        [],
        ["entities", "schemaElement", elementIdString, "resources"],
        newState
      ).slice(0);
      resources.push(payload.resourcePath);
      newState = R.set(
        R.lensPath(["entities", "schemaElement", elementIdString, "resources"]),
        resources,
        newState
      );

      return newState;
    },
    [UPDATE_RESOURCE_LABEL]: (state, { payload: { resourcePath, label } }) =>
      R.set(
        R.lensPath(["entities", "resources", resourcePath, "label"]),
        label
      )(Object.assign({}, state)),
    [SAVE_RESOURCE_FAILURE]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: false,
          error: getMessageOrError(action.payload)
        },
        state
      );
    },
    [CREATE_RESOURCE_FAILURE]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: false,
          error: getMessageOrError(action.payload)
        },
        state
      );
    },

    [SYNC_NEW_RESOURCE]: (
      state,
      { payload: { resource, options, thingTypeId } }
    ) => {
      const updatedResourceIds = R.pathOr(
        [],
        ["entities", "schemaElement", thingTypeId, "resources"],
        state
      ).slice(0);
      updatedResourceIds.push(resource.id);
      const resourceModel = updateExistingResource(
        thingTypeId,
        resource,
        options
      );
      return R.pipe(
        R.set(
          R.lensPath(["entities", "schemaElement", thingTypeId, "resources"]),
          updatedResourceIds
        ),
        R.set(R.lensPath(["entities", "resources", resource.id]), resourceModel)
      )(Object.assign({}, state));
    },
    [SYNC_UPDATED_RESOURCE]: (state, { payload: { resource, options } }) =>
      R.pipe(
        R.mergeDeepLeft({
          entities: {
            resources: {
              [resource.id]: {
                metadata: renameMetadata(resource.metadata)
              }
            }
          }
        }),
        R.set(
          R.lensPath(["entities", "resources", resource.id, "settableOptions"]),
          options
        )
      )(state),

    [SAVE_RESOURCE]: (state, { payload: { resource, options } }) => {
      return R.pipe(
        R.mergeDeepLeft({
          isLoading: true,
          error: undefined,
          entities: {
            resources: {
              [resource.id]: resource
            }
          }
        }),
        R.set(
          R.lensPath(["entities", "resources", resource.id, "settableOptions"]),
          options
        )
      )(state);
    },
    [NEW_RESOURCE]: (state, { payload: { thingType } }) => {
      const resource = newResourceEntity({ thingType });
      return R.pipe(
        R.set(R.lensPath(["new"]), resource),
        R.set(R.lensPath(["lastSelectedResource"]), "new")
      )(Object.assign({}, state));
    },
    [SAVE_RESOURCE_SUCCESS]: (state, { payload: { resource } }) => {
      let newState = R.pipe(
        R.mergeDeepLeft({
          isLoading: false,
          error: undefined,
          entities: {
            resources: {
              [resource.resourceId]: resource
            }
          }
        }),
        R.set(
          R.lensPath([
            "entities",
            "resources",
            resource.resourceId,
            "metadata"
          ]),
          resource.metadata
        )
      )(state);

      let resources = R.pathOr(
        [],
        ["entities", "schemaElement", resource.elementId, "resources"],
        newState
      ).slice(0);

      resources.push(resource.id);
      newState = R.set(
        R.lensPath([
          "entities",
          "schemaElement",
          resource.elementId,
          "resources"
        ]),
        resources,
        newState
      );
      // remove new resource
      return R.pipe(
        R.omit(["new"]),
        R.set(R.lensPath(["lastSelectedResource"]), resource.resourceId)
      )(newState);
    },
    [SET_SELECTED_RESOURCE]: (state, { payload: { resourceId } }) => {
      let newState = R.set(
        R.lensPath(["lastSelectedResource"]),
        resourceId,
        Object.assign({}, state)
      );
      if (R.isNil(resourceId) || R.isEmpty(resourceId)) {
        newState = R.dissoc("new", newState);
      }
      return newState;
    },
    [GET_OPTIONS_SUCCESS]: (state, { payload = {} }) => {
      let newState = R.pipe(
        R.keys,
        R.reduce(
          (accState, resourceId) =>
            R.set(
              R.lensPath([
                "entities",
                "resources",
                resourceId,
                "settableOptions"
              ]),
              payload[resourceId],
              accState
            ),
          Object.assign({}, state)
        )
      )(payload);
      return newState;
    },
    [SAVE_OPTIONS_SUCCESS]: (state, { payload: { resourceId, options } }) => {
      if (options) {
        return R.set(
          R.lensPath(["entities", "resources", resourceId, "settableOptions"]),
          options,
          Object.assign({}, state)
        );
      }
      return state;
    },
    [SAVE_OPTIONS_FAILURE]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: false,
          error: getMessageOrError(action.payload)
        },
        state
      );
    },
    [GET_OPTIONS_FAILURE]: (state, action) => {
      return R.mergeDeepLeft(
        {
          isLoading: false,
          error: getMessageOrError(action.payload)
        },
        state
      );
    }
  },
  initialState
);
