import { values } from "ramda";

/*
 * The purpose of the request status is to track the lifecycle of a specific
 * request, for instance "delete user". Because of how state is modelled, the
 * UI state sometimes lives inside local state of a container, while the
 * request itself (and the result of it) lives in redux-observable epics and/or
 * the redux store.
 *
 * To work around this, the "request status" can be fed down to the stateful
 * component, and when it transitions to "successful", the component can do its
 * own side-effects, like closing modals etc.
 */
export const requestStatus = {
  IDLE: "idle",
  REQUESTED: "requested",
  SUCCESSFUL: "successful",
  FAILURE: "failure"
};

export const requestStatusValues = values(requestStatus);

export const transitioningToSuccessful = (status, nextStatus) =>
  nextStatus !== status && nextStatus === requestStatus.SUCCESSFUL;
