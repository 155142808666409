import FormSaveButton from "components/buttons/form_save";
import { Card } from "components/cards";
import { Label } from "components/label";
import { authSelectors } from "ducks/auth";
import { Field, Form, FormButtons } from "forms_new";
import {
  VerifySms as VerifySmsSchema,
  VerifySmsWithUserName as VerifySmsWithUserNameSchema
} from "forms_new/user/schemas";
import useLazyUnauthorizedFetch from "hooks/useLazyUnauthorizedFetch";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { getStyles } from "./index.style";

const VerifySms = ({ username }) => {
  const styles = getStyles();
  const [verifySms, { loading, success, error }] = useLazyUnauthorizedFetch(
    "/auth/confirm-sign-up",
    "POST"
  );

  const onVerify = formData => {
    verifySms(
      R.pipe(R.unless(R.has("userName"), R.assoc("userName", username)))(
        formData
      )
    );
  };

  return (
    <div style={styles.cardContainer}>
      <Label center type="h3">
        Verify phone number
      </Label>
      <Card noHeader>
        {success && (
          <p>
            Your sign up was successful. Please wait and check your email inbox
            for a confirmation link.
          </p>
        )}
        {!success && (
          <Form
            schema={username ? VerifySmsSchema : VerifySmsWithUserNameSchema}
            onSubmit={onVerify}
          >
            {!username && <Field id="userName" label="User name" />}
            <Field id="code" label="Code" />
            <FormButtons>
              <FormSaveButton
                variant="contained"
                fullWidth
                text="VERIFY"
                disabled={loading}
              />
            </FormButtons>
          </Form>
        )}
        {error && (
          <p style={styles.errorText}>
            {error.getIn(["errorMessage", "message"])}
          </p>
        )}
      </Card>
    </div>
  );
};

VerifySms.propTypes = {
  username: PropTypes.string
};

export default compose(
  connect(state => ({
    username: authSelectors.userNameSelector(state)
  }))
)(VerifySms);
