import * as R from "ramda";
import { Map } from "immutable";
import { createSelector } from "reselect";
import { thingsListSelectors } from "ducks/things_list";
import {
  getResources,
  resourceLabelById,
  resourceUnitById
} from "ducks/schema/schema_selectors";
import { getThingNamesAndResourcePaths } from "ducks/analyzer/utils/analyzer_epic_utils";
import { unImmute } from "utils/immutable_utils";

const EMPTY_MAP = Map();

export const getAnalyzer = state => state.analyzer;
export const getAnalyses = state =>
  getAnalyzer(state).get("analyses", EMPTY_MAP);
export const getDeleteStatus = state => getAnalyses(state).get("deleteStatus");
export const getLoading = state => getAnalyzer(state).get("isLoading");
export const isFetchingObservations = state =>
  getAnalyzer(state).get("isFetchingObservations");

export const getEditingAnalysis = state =>
  getAnalyses(state).get("edits", EMPTY_MAP);

export const getAggregationType = state =>
  state.analyzer.getIn(["analyses", "edits", "aggregationType"]);

export const getAnalysisData = state =>
  state.analyzer
    .getIn(["analyses", "data"], EMPTY_MAP)
    .sortBy((val, key) => key);

export const getMyAnalyses = state => {
  const searchTerm = state.analyzer.getIn(
    ["analyses", "query", "filter", "freeText"],
    null
  );

  return getAnalyses(state)
    .get("history", EMPTY_MAP)
    .filter(a => {
      if (!searchTerm) return true;

      return a
        .get("name")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
};
export const getHiddenResources = state => {
  return state.analyzer.getIn(
    ["analyses", "edits", "hiddenResources"],
    EMPTY_MAP
  );
};

export const getSortedThings = createSelector(
  thingsListSelectors.getPagedThings,
  R.sort(R.ascend(R.prop("label")))
);

const resourceFromThingShadow = ({ thingShadow, resourcePath, thingName }) => {
  if (!thingShadow) return null;

  const thingShadowResources = thingShadow
    .getIn([thingName, "resources"], Map())
    .toJS();

  return Object.values(thingShadowResources).find(
    r => r.thingPath === resourcePath
  );
};

const getResourcePathRegex = ({
  resourcePath,
  thingShadowResource,
  thingType
}) => {
  const pathParts = resourcePath.split("/");
  const isSubthing = pathParts.length > 1 && pathParts[0] !== "tcxn";

  // If we're not dealing with a subthing, we can exit early
  if (!isSubthing) return new RegExp(`^${thingType}/${resourcePath}`);

  /*
    We're dealing with a subthing. We need to make a best-effort of
    finding out if the subthing is typed or not. As the thing can be
    deleted but observations for the deleted thing remains, we can
    not trust that we have a thing-shadow to fetch the type from.
    If the thing is deleted, we wildcard beyond /subthing/. The reason we
    try to avoid wildcarding is the case of two different subthings
    with the same resource name. We then risk matching on the wrong
    subthing and hence displaying the wrong label for the subthing.
  */

  if (!thingShadowResource) {
    return new RegExp(
      `^${thingType}/subthing/[^/]+/${R.tail(pathParts).join("/")}`
    );
  }

  let expression;

  const isUntyped = thingShadowResource.subThingType === "untyped";

  if (isUntyped) {
    expression = new RegExp(
      `^${thingType}/subthing/untyped/${R.tail(pathParts).join("/")}`
    );
  } else {
    expression = new RegExp(
      `^${thingType}/subthing/${thingShadowResource.subThingType}/${R.tail(
        pathParts
      ).join("/")}`
    );
  }

  return expression;
};

export const getAnalysisResourceLookup = createSelector(
  state => state.analyzer.getIn(["analyses", "edits", "resources"]),
  state => state.analyzer.getIn(["analyses", "thingTypeByThingName"]),
  state => state.schema,
  state => state.thing.get("thingShadow", Map()),
  (analysesResources, thingTypeByThingName, schema, thingShadow) => {
    if (!analysesResources) {
      return {};
    }

    if (!thingTypeByThingName) {
      return {};
    }

    if (!thingShadow) {
      return {};
    }

    const thingTypes = R.pipe(unImmute, R.values, R.uniq)(thingTypeByThingName);

    const resourceIdsByThingType = thingTypes.reduce(
      (acc, thingType) => ({
        ...acc,
        [thingType]: getResources(schema, thingType, {
          type: "resourceIds"
        }).toJS()
      }),
      {}
    );

    const thingNamesAndResourcePaths = getThingNamesAndResourcePaths(
      unImmute(analysesResources)
    );

    return thingNamesAndResourcePaths.reduce(
      (acc, { thingName, resourcePath }) => {
        const thingType = thingTypeByThingName[thingName];
        if (!thingType) {
          return acc;
        }

        const thingShadowResource = resourceFromThingShadow({
          thingShadow,
          resourcePath,
          thingName
        });

        const expression = getResourcePathRegex({
          resourcePath,
          thingType,
          thingShadowResource
        });

        const thingTypeResources = resourceIdsByThingType[thingType];
        if (!thingTypeResources || thingTypeResources.length === 0) {
          return acc;
        }

        const actualResourceId = thingTypeResources.find(path =>
          expression.test(path)
        );

        if (actualResourceId) {
          const key = `${thingName}/${resourcePath}`;
          return { ...acc, [key]: actualResourceId };
        } else {
          return acc;
        }
      },
      {}
    );
  }
);

export const defaultLabel = R.pipe(R.split("/"), R.tail, R.join("/"));

export const analysesResourceLabelById = (
  state,
  lookup,
  analysesResourceId
) => {
  const id = lookup[analysesResourceId];
  return id
    ? resourceLabelById(state, { id: id }) || defaultLabel(analysesResourceId)
    : defaultLabel(analysesResourceId);
};

export const analysesResourceUnitById = (state, lookup, analysesResourceId) => {
  const id = lookup[analysesResourceId];
  return id ? resourceUnitById(state, { id }) || undefined : undefined;
};

export const getResourceListItems = createSelector(
  state => state,
  (state, { colorPool }) => colorPool,
  getHiddenResources,
  getAnalysisResourceLookup,
  (state, colorPool, hiddenResources, lookup) =>
    R.pipe(
      R.keys,
      R.addIndex(R.map)((key, i) => ({
        key,
        thingName: R.compose(R.head, R.split("/"))(key),
        color: colorPool[i % colorPool.length],
        label: analysesResourceLabelById(state, lookup, key),
        unit: analysesResourceUnitById(state, lookup, key),
        checked: !hiddenResources.get(key)
      })),
      R.sortWith([
        R.ascend(R.prop("thingName")),
        R.ascend(R.compose(R.toLower, R.prop("label")))
      ])
    )(lookup)
);
