import { graphql } from "@apollo/client/react/hoc";
import { GET_THING } from "graphql/queries";
import * as R from "ramda";
import { connect } from "react-redux";
import * as routeActions from "react-router-redux";
import { compose, withProps, withPropsOnChange } from "react-recompose";

// This component is used if we know thingName but not thingType and want to redirect to thingIds dashboard
// It fetches the thing for details and then redirect to it's dashboard when thingType is retrived.

export default compose(
  connect(),
  withProps(props => ({ thingName: R.path(["params", "thingId"], props) })),
  graphql(GET_THING, { name: "getThingQuery" }),
  withPropsOnChange(["getThingQuery"], props => {
    return {
      isLoading: R.path(["getThingQuery", "loading"], props),
      thing: R.path(["getThingQuery", "thing"], props)
    };
  })
)(({ dispatch, isLoading, thing }) => {
  if (isLoading) {
    return null;
  }

  if (thing) {
    dispatch(
      routeActions.replace(
        `/things/DefaultView/${thing.thingType}/detail/${thing.thingName}/untyped/default`
      )
    );
  } else {
    dispatch(routeActions.push("/"));
  }

  return null;
});
