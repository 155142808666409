import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { prop } from "ramda";
import QRCode from "qrcode.react";

class SimulatorHeader extends Component {
  render() {
    const { account, thingName } = this.props;

    const url = `/simulator/${thingName}?account=${prop(
      "Id",
      account
    )}&env=${prop("env", account)}`;
    return (
      <div
        style={{
          maxHeight: "300px",
          textAlign: "center",
          boxSizing: "border-box",
          marginRight: "48px"
        }}
      >
        <Link to={url}>
          <QRCode
            value={global.location.protocol + "//" + global.location.host + url}
            size={180}
          />
        </Link>
        <p>Scan the QR code above to run the simulator</p>
      </div>
    );
  }
}

SimulatorHeader.propTypes = {
  thingName: PropTypes.string,
  account: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default SimulatorHeader;
