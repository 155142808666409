import { graphql } from "@apollo/client/react/hoc";
import { thingSelectors } from "ducks/thing";
import { CREATE_THING, GET_THING_SHADOW } from "graphql/queries";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, withHandlers, withState } from "react-recompose";
import { getIn } from "utils/immutable_utils";
import CreateNetworkedThingPage from "./create_networked_thing_page";

const withIsLoading = withState("isLoading", "setIsLoading", false);

export const _withHandlers = withHandlers({
  onSubmit: ({
    createThing,
    thingShadow,
    setIsLoading,
    closeModalCreateNetworkedThing
  }) => formArgs => {
    const parentThingName = getIn(thingShadow, ["thingName"]);
    setIsLoading(true);
    createThing({
      variables: {
        input: {
          ...formArgs,
          domain: getIn(thingShadow, ["domain"]),
          parentThingName,
          thingName: formArgs.label
        }
      },
      refetchQueries: [
        {
          query: GET_THING_SHADOW,
          variables: { thingName: parentThingName }
        }
      ]
    })
      .then(() => {
        return closeModalCreateNetworkedThing();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
});

const defaultSelectors = {
  createStatus: thingSelectors.getCreateThingStatus
};

export const _makeMapStateToProps = (selectors = defaultSelectors) => (
  state,
  { params, thingShadow }
) => ({
  parentThingName: params.thingName,
  createStatus: selectors.createStatus(state),
  thing: state.thing,
  domainName: getIn(thingShadow, ["domain", "name"])
});

export default compose(
  connect(_makeMapStateToProps()),
  withRouter,
  graphql(CREATE_THING, { name: "createThing" }),
  withIsLoading,
  _withHandlers
)(CreateNetworkedThingPage);
