import { pathSatisfies, equals, both } from "ramda";
import { nonEmptyArray } from "utils/general_utils";

export const hasOptions = pathSatisfies(nonEmptyArray, ["options"]);

export const isSettable = pathSatisfies(equals(true), [
  "resource",
  "metadata",
  "issettable"
]);

export const shouldSaveOptions = both(isSettable, hasOptions);

export const renameMetadata = ({ isVirtual, isSettable, ...rest } = {}) => ({
  ...rest,
  ...(isVirtual !== undefined ? { isvirtual: isVirtual } : {}),
  ...(isSettable !== undefined ? { issettable: isSettable } : {})
});

export const updateExistingResource = (thingTypeId, resource, options) => {
  return {
    ...resource,
    metadata: renameMetadata(resource.metadata),
    elementId: thingTypeId,
    settableOptions: options
  };
};
