import React from "react";
import PropTypes from "prop-types";
import * as routeActions from "react-router-redux";
import { compose } from "react-recompose";
import { Link as RouterLink } from "react-router";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Link from "@material-ui/core/Link";
import { withStyles, withTheme } from "@material-ui/core/styles";
import ActionGroupWork from "@material-ui/icons/GroupWork";
import EditorShowCart from "@material-ui/icons/ShowChart";
import ActionSettings from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { ListNavItem } from "components/telenor";
import * as colors from "themes/colors";

const TopNav = ({
  dispatch,
  isActive,
  previousHref,
  onHandleNavToSettings
}) => {
  const selectedIndex = () => {
    if (isActive("things")) return 0;
    else if (isActive("analyze")) return 1;
    else return 2;
  };
  const styles = {
    icon: {
      margin: "0 auto",
      marginBottom: 5
    },
    topNavItem: {
      paddingBottom: 0
    }
  };
  return (
    <BottomNavigation
      style={{
        alignContent: "flex-end",
        verticalAlign: "bottom",
        height: "80px"
      }}
      value={selectedIndex()}
    >
      <BottomNavigationAction
        label="Things"
        style={styles.topNavItem}
        icon={<ActionGroupWork style={styles.icon} />}
        onClick={() => dispatch(routeActions.push(previousHref))}
        showLabel={true}
      />
      <BottomNavigationAction
        label="Analysis"
        style={styles.topNavItem}
        icon={<EditorShowCart style={styles.icon} />}
        onClick={() => {
          onHandleNavToSettings();
          dispatch(routeActions.push("/analyze"));
        }}
        showLabel={true}
      />
      <BottomNavigationAction
        label="Settings"
        style={styles.topNavItem}
        icon={<ActionSettings style={styles.icon} />}
        onClick={() => {
          onHandleNavToSettings();
          dispatch(routeActions.push("/settings"));
        }}
        showLabel={true}
      />
    </BottomNavigation>
  );
};

TopNav.propTypes = {
  isActive: PropTypes.func.isRequired,
  onHandleNavToSettings: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  previousHref: PropTypes.string
};

const styles = muiTheme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxWidth: "256px"
  },
  header: {
    flex: `0 0 ${muiTheme.spacing(10)}px`,
    backgroundColor: muiTheme.palette.grey[100]
  },
  body: {
    flex: "1 1 auto",
    overflowY: "auto",
    whiteSpace: "nowrap",
    paddingBottom: "10px",
    backgroundColor: muiTheme.palette.grey[100]
  },
  footer: {
    flex: "0 0 48px",
    width: "100%",
    borderTop: `1px solid ${muiTheme.palette.borderColor}`,
    backgroundColor: colors.white
  }
});

const RLink = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} {...props} />
));
const SideBarNav = ({
  previousHref = "/",
  onHandleNavToSettings,
  menu,
  dispatch,
  isActive,
  isCompact,
  theme = {},
  classes = {}
}) => (
  <section className={classes.container}>
    <div
      className={classes.header}
      style={{
        borderBottom: isCompact
          ? `1px solid ${theme.palette.borderColor}`
          : "0px"
      }}
    >
      {isCompact && (
        <TopNav
          previousHref={previousHref}
          dispatch={dispatch}
          isActive={isActive}
          onHandleNavToSettings={onHandleNavToSettings}
        />
      )}
    </div>
    <div
      className={classes.body}
      style={{ paddingTop: isCompact ? "0" : "19px" }}
    >
      {menu}
    </div>
    {isCompact && (
      <Link component={RLink} to="/logout" underline="none">
        <ListNavItem
          className={classes.footer}
          key={200}
          leftIcon={<ExitToAppIcon />}
        >
          Log out
        </ListNavItem>
      </Link>
    )}
  </section>
);

SideBarNav.propTypes = {
  isActive: PropTypes.func.isRequired,
  onHandleNavToSettings: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  menu: PropTypes.node.isRequired,
  previousHref: PropTypes.string,
  isCompact: PropTypes.bool,
  classes: PropTypes.object,
  theme: PropTypes.object
};

export default compose(withTheme, withStyles(styles))(SideBarNav);
