import Button from "@material-ui/core/Button";
import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { compose, withHandlers, withState } from "react-recompose";
import Container from "./styled/container";
import Input from "./styled/input";
import Label from "./styled/label";

export const normalizeTimeInput = (hours, minutes) => {
  let h = hours.replace(/\D/g, "").slice(0, 2);

  h = Number(h) > 23 ? "23" : h;

  let m = minutes.replace(/\D/g, "").slice(0, 2);

  m = Number(m) > 59 ? "59" : m;

  return { h, m };
};

export const handleTimeChanged = (hours, minutes, setState, onChange) => {
  const { h, m } = normalizeTimeInput(hours, minutes);
  const newTime = `${h}:${m}`;
  onChange(newTime);
  setState(newTime);
};

export const Time = ({
  label,
  handleHoursChanged,
  handleMinutesChanged,
  padToTwoDigits,
  selectedTime,
  state = selectedTime,
  resetToNow,
  isEndPicker
}) => {
  const [hours, minutes] = state.split(":");
  return (
    <Container>
      <Label>{label}</Label>
      <Input
        type="text"
        className="hours-input"
        data-test="hours-input"
        value={hours}
        onChange={handleHoursChanged}
        onBlur={padToTwoDigits}
      />
      :
      <Input
        type="text"
        className="minutes-input"
        data-test="minutes-input"
        value={minutes}
        onChange={handleMinutesChanged}
        onBlur={padToTwoDigits}
      />
      {isEndPicker && (
        <Button color="primary" onClick={resetToNow} data-test="setCurrent">
          set current
        </Button>
      )}
    </Container>
  );
};

Time.propTypes = {
  isEndPicker: PropTypes.bool,
  resetToNow: PropTypes.func,
  state: PropTypes.string,
  label: PropTypes.string.isRequired,
  selectedTime: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  handleHoursChanged: PropTypes.func,
  handleMinutesChanged: PropTypes.func,
  padToTwoDigits: PropTypes.func
};

export default compose(
  withState("state", "setState", ({ selectedTime }) => selectedTime),
  withHandlers({
    handleHoursChanged: ({ state, setState, onChange }) => ({ target }) => {
      const hours = target.value;
      const minutes = state.split(":")[1];
      handleTimeChanged(hours, minutes, setState, onChange);
    },
    handleMinutesChanged: ({ state, setState, onChange }) => ({ target }) => {
      const hours = state.split(":")[0];
      const minutes = target.value;
      handleTimeChanged(hours, minutes, setState, onChange);
    },
    padToTwoDigits: ({ state, setState }) => () => {
      let [hours, minutes] = state.split(":");
      hours = hours.length === 1 ? `0${hours}` : hours;
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;
      setState(`${hours}:${minutes}`);
    },
    resetToNow: ({ setState, onResetToToday }) => () => {
      const now = formatDate(new Date(), "HH:mm");
      setState(now);
      onResetToToday(now);
    }
  })
)(Time);
