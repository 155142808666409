import * as R from "ramda";
import { createSelector } from "reselect";

export const SUGGEST_RESOURCES = "SUGGEST_RESOURCES";
export const SUGGEST_THINGS = "SUGGEST_THINGS";
export const SUGGEST_THING_TYPES = "SUGGEST_THING_TYPES";
export const SUGGEST_DOMAINS = "SUGGEST_DOMAINS";
export const SUGGEST_ALL = "SUGGEST_ALL";
export const SUGGEST_USERS = "SUGGEST_USERS";

const suggesterRootSelector = R.prop("suggester");

const suggestionsTypeSelector = (state, props) => R.prop("type", props);

const resourceSelector = createSelector(
  suggesterRootSelector,
  R.prop("resources")
);

export const limitedResourceSelector = createSelector(
  resourceSelector,
  resources => resources
);

// eslint-disable-next-line no-unused-vars
const domainsSelector = createSelector(
  suggesterRootSelector,
  R.prop("domains")
);

// eslint-disable-next-line no-unused-vars
const thingsSelector = createSelector(suggesterRootSelector, R.prop("things"));

// eslint-disable-next-line no-unused-vars
const thingTypesSelector = createSelector(
  suggesterRootSelector,
  R.prop("thingTypes")
);

export const suggestionsSelector = createSelector(
  suggesterRootSelector,
  suggestionsTypeSelector,
  (sug, type) => {
    switch (type) {
      case SUGGEST_RESOURCES:
        return R.prop("resources", sug);
      case SUGGEST_THINGS:
        return R.prop("things", sug);
      case SUGGEST_THING_TYPES:
        return R.prop("thingTypes", sug);
      case SUGGEST_DOMAINS:
        return R.prop("domains", sug);
      case SUGGEST_USERS:
        return R.prop("users", sug);
      default:
        return [];
    }
  }
);

export const resourcesSelector = createSelector(
  suggesterRootSelector,
  R.propOr([], "resources")
);
