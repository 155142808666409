import React from "react";
import PropTypes from "prop-types";

import { Page } from "components/page";
import PayloadConfigEditor from "./paylod_config_editor/payload_config_editor";
import ToggleRunningButton from "./toggle_running_button";

import FormIconButton from "components/buttons/form_icon";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

import Warning from "components/warning";

const pageStyle = { maxWidth: "1005px", margin: "0 auto" };

const CanNotSimulatePage = ({ thingtypeLabel, thingName, navigateBack }) => (
  <Page
    title={`Simulate ${thingtypeLabel} ${thingName}`}
    style={pageStyle}
    leftElement={<FormIconButton icon={<BackIcon />} onClick={navigateBack} />}
  >
    <Warning>Thing {thingName} is not allowed to simulate values</Warning>
  </Page>
);

CanNotSimulatePage.propTypes = {
  thingtypeLabel: PropTypes.string,
  thingName: PropTypes.string,
  navigateBack: PropTypes.func
};

const Simulator = ({
  isLoading,
  allowedToBeSimulated,
  thingName,
  thingtypeLabel,
  resources,
  payloadConfig,
  onPayloadConfigChange,
  isRunning,
  isValid,
  toggleIsRunning,
  navigateBack,
  userCanCreateResource,
  enabledConfigsCount,
  errors
}) => {
  return allowedToBeSimulated ? (
    <Page
      title={`Simulate ${thingtypeLabel} ${thingName}`}
      style={pageStyle}
      leftElement={
        <FormIconButton icon={<BackIcon />} onClick={navigateBack} />
      }
      rightElement={
        <ToggleRunningButton
          isLoading={isLoading}
          isRunning={isRunning}
          isValid={isValid}
          toggleIsRunning={toggleIsRunning}
          errors={errors}
        />
      }
    >
      <PayloadConfigEditor
        isLoading={isLoading}
        isRunning={isRunning}
        resources={resources}
        payloadConfig={payloadConfig}
        onPayloadConfigChange={onPayloadConfigChange}
        enabledConfigsCount={enabledConfigsCount}
        errors={errors}
        userCanCreateResource={userCanCreateResource}
      />
    </Page>
  ) : (
    !isLoading && (
      <CanNotSimulatePage
        thingtypeLabel={thingtypeLabel}
        thingName={thingName}
        navigateBack={navigateBack}
      />
    )
  );
};

Simulator.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRunning: PropTypes.bool.isRequired,
  allowedToBeSimulated: PropTypes.bool.isRequired,
  enabledConfigsCount: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  onPayloadConfigChange: PropTypes.func.isRequired,
  payloadConfig: PropTypes.object.isRequired,
  simulatorThingQuery: PropTypes.object.isRequired,
  toggleIsRunning: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  userCanCreateResource: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  thingName: PropTypes.string.isRequired,
  thingtypeLabel: PropTypes.string.isRequired,
  resources: PropTypes.array.isRequired
};

export default Simulator;
