import React from "react";
import {
  compose,
  branch,
  withState,
  withHandlers,
  lifecycle,
  withProps
} from "react-recompose";
import { withRouter } from "react-router";
import { last } from "ramda";

export const _withModalState = name =>
  withState(`modalState${name}`, `setModalState${name}`, {
    [`open${name}`]: false
  });

export const _withModalHandlers = (name, onLeave, handlers, afterClose) => {
  const openName = `open${name}`;
  const openModalName = `openModal${name}`;
  const closeModalName = `closeModal${name}`;
  const setModalStateName = `setModalState${name}`;
  const modalStateName = `modalState${name}`;
  const onLeaveName = `onLeave${name}`;
  const afterCloseName = `afterClose${name}`;
  return compose(
    withHandlers({
      [openModalName]: componentProps => (args = {}) => {
        let open = args[openName];
        open = open === undefined ? true : open;
        componentProps[setModalStateName]({
          ...(args ? args : {}),
          open
        });
      },
      ...(onLeave ? { [onLeaveName]: onLeave } : {}),
      ...(afterClose ? { [afterCloseName]: afterClose } : {})
    }),
    withHandlers({
      [closeModalName]: componentProps => (args = {}) => {
        const modalState = componentProps[modalStateName];
        componentProps[setModalStateName]({
          ...modalState,
          ...args,
          open: false
        });
        const afterCloseFn = componentProps[afterCloseName];
        modalState.routeLeaveHook && modalState.routeLeaveHook();
        if (afterCloseFn) afterCloseFn();
      }
    }),
    withHandlers({
      ...handlers
    })
  );
};

export const _routerLeaveHook = name => {
  return compose(
    withRouter,
    lifecycle({
      componentDidMount() {
        const { router } = this.props;
        const closeModal = this.props[`closeModal${name}`];
        const onLeave = this.props[`onLeave${name}`];
        if (router && router.setRouteLeaveHook) {
          const setModalState = this.props[`setModalState${name}`];
          const modalState = this.props[`modalState${name}`];
          const routeLeaveHook = router.setRouteLeaveHook(
            last(router.routes),
            () => {
              onLeave && onLeave();
              closeModal();
              routeLeaveHook();
              return true;
            }
          );
          setModalState && setModalState({ ...modalState, routeLeaveHook });
        }
      }
    })
  );
};

export const withModalHandlers = ({
  name = "",
  onLeave,
  afterClose,
  handlers = {}
}) =>
  compose(
    _withModalState(name),
    _withModalHandlers(name, onLeave, handlers, afterClose)
  );

const _applyDefaultCondition = (name, condition, props) => {
  if (!condition) {
    const modalState = props[`modalState${name}`];
    return modalState && modalState.open;
  } else {
    return condition(props);
  }
};

export const _branchModal = (name, modal, condition) =>
  branch(
    props => _applyDefaultCondition(name, condition, props),
    C => {
      const ModalWrappedWithLeaveHook = _routerLeaveHook(name)(modal);
      return props => (
        <span>
          <C {...props} />
          <ModalWrappedWithLeaveHook {...props} />
        </span>
      );
    }
  );

export const _conditionModal = (name, modal, condition) => C => {
  const ModalWrappedWithLeaveHook = _routerLeaveHook(name)(modal);
  return props => (
    <span>
      <C {...props} />
      {_applyDefaultCondition(name, condition, props) && (
        <ModalWrappedWithLeaveHook {...props} />
      )}
    </span>
  );
};

export const branchModal = (name, modal, condition) =>
  _branchModal(name, modal, condition)(() => <span />);

const _withProps = modalProps =>
  withProps(props => ({
    modalProps:
      typeof modalProps === "function" ? modalProps(props) : modalProps
  }));

export const withModalHandlersAndBranch = ({
  name = "",
  modal,
  condition,
  onLeave,
  afterClose,
  handlers = {},
  props = {}
}) =>
  compose(
    _withProps(props),
    _withModalState(name),
    _withModalHandlers(name, onLeave, handlers, afterClose),
    _conditionModal(name, modal, condition)
  );
