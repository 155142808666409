import * as R from "ramda";

const createMinutes = config => () => {
  const scheduleType = R.prop("scheduleType", config);
  return [
    R.pipe(R.pathOr("", [scheduleType, "time"]), R.split(":"), R.last)(config)
  ];
};

const createHours = config => payload => {
  const scheduleType = R.prop("scheduleType", config);
  payload.push(
    R.pipe(R.pathOr("", [scheduleType, "time"]), R.split(":"), R.head)(config)
  );
  return payload;
};

const getDailyDayOfMonth = scheduleType =>
  R.ifElse(
    R.pathEq([scheduleType, "period"], "1"),
    R.always("*"),
    R.pipe(R.path([scheduleType, "period"]), period => `1/${period}`)
  );

const getWeeklyDayOfMonth = () => R.always("?");

const createDayOfMonth = config => payload => {
  const scheduleType = R.prop("scheduleType", config);
  const day = R.ifElse(
    R.propEq("scheduleType", "daily"),
    getDailyDayOfMonth(scheduleType),
    getWeeklyDayOfMonth(scheduleType)
  )(config);

  payload.push(day);
  return payload;
};

const createMonth = () => payload => {
  payload.push("*");
  return payload;
};

const createDayOfWeek = config => payload => {
  const scheduleType = R.prop("scheduleType", config);
  const day = R.ifElse(
    R.propEq("scheduleType", "daily"),
    R.always("?"),
    R.path([scheduleType, "period"])
  )(config);

  payload.push(day);
  return payload;
};

const createYear = () => payload => {
  payload.push("*");
  return payload;
};

const createString = parts => parts.join(" ");

export default config =>
  R.pipe(
    createMinutes(config),
    createHours(config),
    createDayOfMonth(config),
    createMonth(config),
    createDayOfWeek(config),
    createYear(config),
    createString
  )(config);
