import Default from "./change";
import _GeoPoint from "./geo_point";
import GenerateValue from "./generate";
import * as Validators from "../validators";

const Double = Default(Validators.Float);
const Float = Default(Validators.Float);
const Integer = Default(Validators.Int);
const Long = Default(Validators.Int);

const GeoPoint = _GeoPoint({
  lat: Validators.Lat,
  lon: Validators.Lon,
  lat_low: Validators.Lat,
  lon_low: Validators.Lon,
  lat_high: Validators.Lat,
  lon_high: Validators.Lon
});
export { Default, Double, Float, Integer, Long, GeoPoint, GenerateValue };
