import React from "react";
import PropTypes from "prop-types";
import { Form, Field, FormButtons, FieldSet } from "../index";
import FormSaveButton from "components/buttons/form_save";
import {
  Password as PasswordSchema,
  NewPassword as NewPasswordSchema,
  PasswordAndUserName as PasswordAndUserNameSchema
} from "forms_new/user/schemas";

const SetNewPasswordForm = ({
  handleSubmit,
  isLoading,
  userName,
  verifyByPhoneNumber
}) => {
  return (
    <Form
      schema={
        verifyByPhoneNumber
          ? userName
            ? PasswordSchema
            : PasswordAndUserNameSchema
          : NewPasswordSchema
      }
      onSubmit={handleSubmit}
    >
      <FieldSet>
        {verifyByPhoneNumber && !userName && (
          <Field id="userName" label="User name" />
        )}
        {verifyByPhoneNumber && (
          <Field id="code" label="Current password" type="password" />
        )}
        <Field id="password" label="New password" type="password" />
        <Field
          id="passwordConfirm"
          label="Password confirmation"
          type="password"
        />
      </FieldSet>
      <FormButtons>
        <FormSaveButton
          style={{ marginTop: "20px" }}
          fullWidth
          text="Set"
          variant="contained"
          disabled={isLoading}
        />
      </FormButtons>
    </Form>
  );
};

SetNewPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  verifyByPhoneNumber: PropTypes.bool
};

export default SetNewPasswordForm;
