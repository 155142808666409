import styled from "styled-components";

export default styled.div`
  box-sizing: border-box;
  display: inline-block;
  min-width: 28px;
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: none;
`;
