import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import Joi from "joi-browser";
import { omit } from "ramda";
import PropTypes from "prop-types";
import React from "react";
import { compose, withHandlers, withState } from "react-recompose";
import { Field, FieldSet, Form, ModalFormButtons } from "../";
import { SwitchFormControlLabel } from "../index";

const BatchSchema = Joi.object().keys({
  domain: Joi.string()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  requestedSize: Joi.number()
    .integer()
    .min(1)
    .max(1000)
    .required()
    .options({
      language: {
        number: {
          min: "!!Number must be larger than 0",
          max: "!!Number must be less than or equal to 1000"
        },
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    })
});

const thingNameRegex = /^[a-zA-Z0-9:_-]+$/gm;

const ThingSchema = Joi.object().keys({
  thingName: Joi.string()
    .regex(thingNameRegex)
    .options({
      language: {
        string: {
          regex: {
            base: "!!Invalid thing name, it must conform to [a-zA-Z0-9:_-]+"
          }
        }
      }
    })
    .empty("")
    .trim(),
  externalId: Joi.string()
    .empty("")
    .trim(),
  protocol: Joi.string()
    .empty("")
    .trim(),
  imsi: Joi.string()
    .empty("")
    .trim(),
  imei: Joi.string()
    .empty("")
    .trim(),
  label: Joi.string().trim(),
  description: Joi.string()
    .empty("")
    .trim(),
  domain: Joi.string()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  simulated: Joi.boolean()
});

export const networkLabelMappings = {
  mqtt: "Standard MQTT/TLS",
  lora: "The Thing Industries (Lora)",
  iotgw: "Telenor IoT Gateway (NB-IoT/LTE-M)"
};

const FormButtons = ({ handleCancel, isLoading }) => (
  <div>
    <ModalFormButtons>
      <FormCancelButton onClick={handleCancel} disabled={isLoading}>
        Cancel
      </FormCancelButton>
      <FormSaveButton
        text="Create"
        disabled={isLoading}
        data-test="new-thing-form__create-button"
      />
    </ModalFormButtons>
  </div>
);

const BatchForm = ({ createBatch, handleCancel, isLoading, thingType }) => (
  <Form schema={BatchSchema} onSubmit={createBatch}>
    <FieldSet>
      <Field
        id="requestedSize"
        key="requestedSize"
        type="number"
        data-test="new-thing-form__noOfThings-input"
        label="Number of things to add"
      />

      <Field
        id="domain"
        key="batchDomain"
        label="Domain"
        type="autocomplete"
        data-test="new-thing-form__domain-select"
        suggesterType={DATA_TYPES.DOMAINS}
        thingType={thingType}
      />

      <p>
        Things are created in batch and an Event notification is created when
        finished.
      </p>
    </FieldSet>
    {FormButtons({ handleCancel, isLoading })}
  </Form>
);

const ThingForm = ({
  createSingle,
  handleCancel,
  isLoading,
  supportedThingProtocols,
  setFormModel,
  formModel,
  thingType
}) => {
  const [showThingName, setShowThingName] = React.useState(false);
  const onShowThingNameChange = () => {
    if (showThingName === true) {
      // We're hiding the thing name field - remove from model.
      setFormModel(omit(["thingName"], formModel));
    }
    setShowThingName(!showThingName);
  };
  return (
    <Form
      forceModel={formModel}
      schema={ThingSchema}
      onSubmit={createSingle}
      onChange={({ model }) =>
        setFormModel(
          model.protocol === "iotgw"
            ? omit(["externalId"], model)
            : omit(["imsi", "imei"], model)
        )
      }
    >
      <Field id="label" key="label" label="Thing Label" />
      <Field id="description" key="description" label="Description" />
      {formModel.protocol !== "iotgw" ? (
        <Field id="externalId" key="externalId" label="External ID" />
      ) : (
        ""
      )}
      <Field
        id="domain"
        key="singleDomain"
        label="Domain"
        type="autocomplete"
        data-test="new-thing-form__domain-select"
        suggesterType={DATA_TYPES.DOMAINS}
        thingType={thingType}
      />
      {supportedThingProtocols && supportedThingProtocols.length > 1 ? (
        <Field
          id="protocol"
          type="select"
          label="Network integration"
          hintText="Network integration"
          labelProps={{ shrink: true }}
          options={supportedThingProtocols.map(p => ({
            id: p,
            label: networkLabelMappings[p],
            value: p
          }))}
        />
      ) : (
        ""
      )}
      {formModel.protocol === "iotgw" ? (
        <span>
          <Field id="imsi" key="imsi" label="IMSI" />
          <Field id="imei" key="imei" label="IMEI" />
        </span>
      ) : (
        ""
      )}
      <FormGroup row>
        <SwitchFormControlLabel
          labelPlacement="start"
          label="Set Thing Name"
          control={
            <Switch checked={showThingName} onChange={onShowThingNameChange} />
          }
        />
      </FormGroup>
      <FormGroup row>
        <InputLabel shrink={true} error={showThingName}>
          Setting the Thing Name is discouraged. Once a Thing Name has been set
          it can no longer be changed.
        </InputLabel>
      </FormGroup>
      {showThingName && (
        <Field id="thingName" key="thingName" label="Thing Name" />
      )}
      <Field
        id="simulated"
        key="simulated"
        label="Allow simulation"
        type="toggle"
        style={{}}
      />
      {FormButtons({ handleCancel, isLoading })}
    </Form>
  );
};

const CreateThingsForm = ({
  createBatch,
  createSingle,
  handleCancel,
  isBatch,
  toggleIsBatch,
  isLoading,
  data: { supportedThingProtocols },
  formModel,
  setFormModel,
  thingType
}) => (
  <div>
    <FormGroup row>
      <SwitchFormControlLabel
        labelPlacement="start"
        label="Create batch"
        control={
          <Switch
            checked={isBatch}
            value="1"
            id="batchToggle"
            onChange={toggleIsBatch}
          />
        }
      />
    </FormGroup>
    {isBatch ? (
      <BatchForm
        createBatch={createBatch}
        handleCancel={handleCancel}
        isLoading={isLoading}
        thingType={thingType}
      />
    ) : (
      <ThingForm
        createSingle={createSingle}
        handleCancel={handleCancel}
        isLoading={isLoading}
        supportedThingProtocols={supportedThingProtocols}
        formModel={formModel}
        setFormModel={setFormModel}
        thingType={thingType}
      />
    )}
  </div>
);

CreateThingsForm.propTypes = {
  createBatch: PropTypes.func.isRequired,
  createSingle: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  toggleIsBatch: PropTypes.func.isRequired,
  isBatch: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setFormModel: PropTypes.func.isRequired,
  formModel: PropTypes.object.isRequired,
  thingType: PropTypes.shape({
    domain: PropTypes.string
  })
};

ThingForm.propTypes = {
  createSingle: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  supportedThingProtocols: PropTypes.array.isRequired,
  setFormModel: PropTypes.func.isRequired,
  formModel: PropTypes.object.isRequired,
  thingType: PropTypes.shape({
    domain: PropTypes.string
  })
};

BatchForm.propTypes = {
  createBatch: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  thingType: PropTypes.shape({
    domain: PropTypes.string
  })
};

FormButtons.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const fetchProtocolsQuery = gql`
  {
    supportedThingProtocols
  }
`;

export default compose(
  graphql(fetchProtocolsQuery),
  withState("isBatch", "toggleIsBatch", true),
  withHandlers({
    toggleIsBatch: ({ toggleIsBatch }) => () =>
      toggleIsBatch(current => !current)
  }),
  withState("formModel", "setFormModel", {})
)(CreateThingsForm);
