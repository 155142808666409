import { adaptAnalyses } from "adapters/analyses_adapter";
import { adaptDashboard, adaptDashboards } from "adapters/dashboard_adapter";
import { adaptCreateJobAttributes } from "adapters/device_management_adapter";
import { normalizeDomainsResponse as adaptDomains } from "adapters/domains_adapter";
import { adaptEvents } from "adapters/events_adapter";
import {
  adaptAggregatedObservations,
  adaptRawObservations
} from "adapters/observations_adapter";
import { adaptRule, adaptRules } from "adapters/rules_adapter";
import { adaptThingTypeByThingName } from "adapters/thing_adapter";
import * as api from "api/aws";
import * as restApi from "api/rest";
import * as analyzerEpics from "ducks/analyzer/analyzer_epics";
import { authSelectors } from "ducks/auth";
import * as authEpics from "ducks/auth/auth_epics";
import * as batchesEpics from "ducks/batches/batches_epics";
import * as bucketEpics from "ducks/bucket_epics";
import * as bucketSelectors from "ducks/bucket_selectors";
import * as dashboardEpics from "ducks/dashboard/dashboard_epics";
import * as deviceManagementEpics from "ducks/device_management/device_management_epics";
import {
  createJobForm,
  validateCreateJobForm
} from "ducks/device_management/jobs_selectors";
import * as domainEpics from "ducks/domain/domain_epics";
import { hasDomains } from "ducks/domain/domain_selectors";
import * as errorsEpics from "ducks/errors_epics";
import * as eventsEpics from "ducks/events/events_epics";
import { filterSelectors } from "ducks/filter";
import * as filterEpics from "ducks/filter/filter_epics";
import * as mqttEpics from "ducks/mqtt/mqtt_epics";
import { pagingSelectors } from "ducks/paging";
import * as schemaEpics from "ducks/schema/schema_epics";
import * as simulatorEpics from "ducks/simulator/simulator_epics";
import simulatorPayloadGenerator from "ducks/simulator/simulator_payload_generator";
import simulatorPayloadTimer from "ducks/simulator/simulator_payload_timer";
import * as suggesterSelectors from "ducks/suggester";
import * as suggesterEpics from "ducks/suggester/suggester_epics";
import * as systemManagementEpics from "ducks/system_management/system_management_epics";
import * as themeEpics from "ducks/theme/theme_epics";
import * as thingEpics from "ducks/thing/thing_epics";
import * as thingsListEpics from "ducks/things_list/things_list_epics";
import { thingTypeSelectors, widgetSelectors } from "ducks/thing_types";
import * as thingTypesEpics from "ducks/thing_types/thing_types_epics";
import * as usersEpics from "ducks/users/users_epics";
import { saveAs } from "file-saver";
import { combineEpics } from "redux-observable";
import * as authUtils from "utils/auth_utils";
import * as bucketUtils from "utils/bucket_utils";
import * as domainUtils from "utils/domain_utils";
import { wrapEpics } from "utils/epic_utils";
import * as mqttUtils from "utils/mqtt_utils";
import * as themeUtils from "utils/theme_utils";
import * as thingTypeUtils from "utils/thing_type_utils";
import { adaptBucket } from "../adapters/bucket_adapter";
import getApolloClient from "../graphql/init";
import * as brandingEpics from "./branding/branding_epics";

const epics = wrapEpics([
  authEpics,
  brandingEpics,
  bucketEpics,
  dashboardEpics,
  schemaEpics,
  thingEpics,
  thingsListEpics,
  thingTypesEpics,
  errorsEpics,
  usersEpics,
  suggesterEpics,
  domainEpics,
  themeEpics,
  eventsEpics,
  mqttEpics,
  filterEpics,
  batchesEpics,
  analyzerEpics,
  deviceManagementEpics,
  systemManagementEpics,
  simulatorEpics
]);

export const rootEpic = combineEpics(...epics);
export const dependencies = {
  api,
  restApi,
  authSelectors,
  authUtils,
  bucketSelectors,
  bucketUtils,
  pagingSelectors,
  saveAs,
  filterSelectors,
  thingTypeSelectors,
  domainUtils,
  hasDomains,
  themeUtils,
  thingTypeUtils,
  mqttUtils,
  widgetSelectors,
  adaptDashboard,
  adaptDashboards,
  adaptBucket,
  suggesterSelectors,
  adaptDomains,
  adaptRule,
  adaptRules,
  adaptEvents,
  adaptAggregatedObservations,
  adaptRawObservations,
  adaptAnalyses,
  adaptThingTypeByThingName,
  createJobForm,
  validateCreateJobForm,
  adaptCreateJobAttributes,
  getApolloClient,
  simulatorPayloadGenerator,
  simulatorPayloadTimer
};
