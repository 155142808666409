import grey from "@material-ui/core/colors/grey";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Calendar from "@material-ui/icons/CalendarToday";
import DateRange from "@material-ui/icons/DateRange";
import FormCancelButton from "components/buttons/form_cancel";
import FormIconButton from "components/buttons/form_icon";
import FormSaveButton from "components/buttons/form_save";
import { DateTimePicker } from "components/date_time";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { setPeriod } from "ducks/observation/observation";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "react-recompose";
import { getStyles } from "./observation_style";

export const startDatePickerFilter = R.curry(
  (endDate, date) => isSameDay(date, endDate) || isBefore(date, endDate)
);
export const endDatePickerFilter = R.curry(
  (startDate, date) =>
    (isSameDay(date, startDate) || isAfter(date, startDate)) &&
    (isSameDay(date, new Date()) || isBefore(date, new Date()))
);

const DialogContents = ({
  title,
  state,
  handleFromChange,
  handleToChange,
  startDatePickerFilter,
  endDatePickerFilter,
  onCancel,
  onPick,
  validSelection,
  styles
}) => (
  <React.Fragment>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DateTimePicker
        key="startTime"
        startDate={state.from}
        endDate={state.to}
        selected={state.from}
        onChange={handleFromChange}
        filterDate={startDatePickerFilter(state.to)}
      />
      <div style={styles.verticalDivider} />
      <DateTimePicker
        key="endTime"
        isEndPicker
        startDate={state.from}
        endDate={state.to}
        selected={state.to}
        onChange={handleToChange}
        filterDate={endDatePickerFilter(state.from)}
      />
    </DialogContent>
    <DialogActions>
      <FormCancelButton
        data-test="observation-period-picker__cancel"
        key="cancel"
        onClick={onCancel}
      />
      <FormSaveButton
        data-test="observation-period-picker__pick"
        disabled={!validSelection}
        key="pick"
        text="Pick"
        onClick={onPick}
      />
    </DialogActions>
  </React.Fragment>
);
DialogContents.propTypes = {
  title: PropTypes.object,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  onPick: PropTypes.func,
  handleFromChange: PropTypes.func,
  handleToChange: PropTypes.func,
  state: PropTypes.object,
  styles: PropTypes.object,
  validSelection: PropTypes.bool,
  startDatePickerFilter: PropTypes.func,
  endDatePickerFilter: PropTypes.func
};

export const ObservationPeriod = ({
  onOpen,
  onPick,
  onCancel,
  handleFromChange,
  handleToChange,
  state,
  disabled
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const fromIsBeforeTo = isBefore(state.from, state.to);
  const validSelection = state.isValidFrom && state.isValidTo && fromIsBeforeTo;

  const title = (
    <div>
      <DateRange style={styles.dateRangeIcon} />
      <span style={styles.titleLable}>Pick a duration</span>
    </div>
  );

  return (
    <div style={styles.container}>
      <Tooltip
        title="Select time range for dashboard resource values"
        placement="top"
      >
        <FormIconButton
          data-test="observation-period-picker__open"
          key="openModal"
          onClick={onOpen}
          color="default"
          text="Time range"
          icon={<Calendar nativeColor={disabled ? grey[400] : grey[700]} />}
          disabled={disabled}
        />
      </Tooltip>
      <Dialog open={state.isOpen} maxWidth={false}>
        <DialogContents
          title={title}
          state={state}
          handleFromChange={handleFromChange}
          handleToChange={handleToChange}
          startDatePickerFilter={startDatePickerFilter}
          endDatePickerFilter={endDatePickerFilter}
          onCancel={onCancel}
          onPick={onPick}
          validSelection={validSelection}
          styles={styles}
        />
      </Dialog>
    </div>
  );
};

ObservationPeriod.propTypes = {
  observation: PropTypes.object.isRequired,
  setPeriod: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  onPick: PropTypes.func,
  handleFromChange: PropTypes.func,
  handleToChange: PropTypes.func,
  state: PropTypes.object,
  disabled: PropTypes.bool
};

const mapStateToProps = ({ observation }) => ({
  observation
});

const actions = {
  setPeriod
};

export default compose(
  connect(mapStateToProps, actions),
  withState("state", "setState", ({ observation }) => ({
    isOpen: false,
    isValidTo: true,
    isValidFrom: true,
    from: new Date(observation.get("from")),
    to: new Date(observation.get("to"))
  })),
  withHandlers({
    onOpen: props => () =>
      props.setState(previousState => ({ ...previousState, isOpen: true })),
    onCancel: ({ setState, observation }) => () =>
      setState({
        from: new Date(observation.get("from")),
        to: new Date(observation.get("to")),
        isOpen: false,
        isValidTo: true,
        isValidFrom: true
      }),
    handleFromChange: props => (date, isValid) =>
      props.setState(previousState => ({
        ...previousState,
        from: date,
        isValidFrom: isValid
      })),
    handleToChange: props => (date, isValid) =>
      props.setState(previousState => ({
        ...previousState,
        to: date,
        isValidTo: isValid
      })),
    onPick: ({ setPeriod, state, setState, onChange }) => () => {
      if (!state.isValidFrom || !state.isValidTo) {
        return;
      }

      setPeriod("from", state.from);
      setPeriod("to", state.to);

      if (onChange) {
        onChange();
      }
      setState(previousState => ({ ...previousState, isOpen: false }));
    }
  })
)(ObservationPeriod);
