import { createAction, handleActions } from "redux-actions";
import guid from "easy-guid";
import { fromJS } from "immutable";
import { WIDGET_TYPES } from "components/widget/edit/widget_types";
import DATA_TYPES from "./resource_types";
export const ADD_METADATA = "ADD_METADATA";
export const DELETE_METADATA = "DELETE_METADATA";
export const UPDATE_METADATA = "UPDATE_METADATA";

export const ADD_METADATA_ITEM = "ADD_METADATA_ITEM";
export const DELETE_METADATA_ITEM = "DELETE_METADATA_ITEM";
export const UPDATE_METADATA_ITEM = "UPDATE_METADATA_ITEM";

export const SAVE_METADATA = "SAVE_METADATA";

export const addMetadata = createAction(ADD_METADATA);
export const deleteMetadata = createAction(DELETE_METADATA);
export const updateMetadata = createAction(UPDATE_METADATA);

export const addMetadataItem = createAction(ADD_METADATA);
export const deleteMetadataItem = createAction(DELETE_METADATA_ITEM);
export const updateMetadataItem = createAction(UPDATE_METADATA);

export const newResourceMetadata = ({
  id = guid.new(16),
  resourceId,
  label,
  dataType = DATA_TYPES.STRING,
  unit = "-",
  defaultWidget = WIDGET_TYPES.VALUE,
  settable = false,
  virtual = false
}) => ({
  id,
  resourceId,
  label,
  dataType,
  unit,
  defaultWidget,
  settable,
  virtual
});

const initialState = fromJS(newResourceMetadata({}));

export default handleActions(
  {
    [ADD_METADATA]: (state, { payload = {} }) =>
      payload.resourceId
        ? state.merge({
            entities: {
              resources: {
                [payload.resourceId]: { metadata: payload }
              }
            }
          })
        : state,
    [UPDATE_METADATA]: (state, { payload = {} }) =>
      payload.resourceId
        ? state.merge({
            entities: {
              resources: {
                [payload.resourceId]: { metadata: payload }
              }
            }
          })
        : state,
    [DELETE_METADATA_ITEM]: (
      state,
      { payload: { resourceId, metadataIds = [] } }
    ) =>
      resourceId
        ? metadataIds.reduce(
            (stateAcc, id) =>
              stateAcc
                .deleteIn(["entities", "resources", resourceId, "metadata", id])
                .setIn(
                  [
                    "entities",
                    "resources",
                    resourceId,
                    "metadata",
                    "toDelete",
                    id
                  ],
                  null
                ),
            state
          )
        : state
  },
  initialState
);
