import styled from "styled-components";
import { gridRow, gridWrap } from "themes/layout";
import * as colors from "themes/colors";

const WidgetFooterWrapper = styled.div`
  min-height: 30px;
  margin: 0;
  padding: 5px 8px;
  justify-content: stretch;
  align-items: center;
  border-top: solid;
  border-top-width: 1px;
  border-top-color: ${colors.colorQuillGrayApprox};
  font-family: telenor-light;
  font-size: 12px;
  color: ${colors.colorDustyGrayApprox};
  ${gridRow};
  ${gridWrap};
`;

export default WidgetFooterWrapper;
