import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupWorkIcon from "@material-ui/icons/GroupWorkOutlined";
import FormCancelButton from "components/buttons/form_cancel";
import FormIconButton from "components/buttons/form_icon";
import FormSaveButton from "components/buttons/form_save";
import Dialog from "components/dialog";
import DataExportIcon from "components/icon/data_export";
import { GET_THING_TYPE } from "graphql/queries";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { compose, withState } from "react-recompose";
import ThingTypeSummary from "./components/thingtype_summary";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2) + "px !important"
  },
  summaryRoot: {
    padding: "0",
    paddingRight: theme.spacing(4),
    minHeight: "64px",
    cursor: "default !important"
  },
  summaryContent: {
    justifyContent: "space-between",
    margin: "12px 0px !important"
  },
  summaryTitleContent: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginTop: "-2px",
    marginRight: theme.spacing(1)
  },
  detailsExpander: { padding: "0px" }
});

const preventPropagation = (e, callback) => {
  e.stopPropagation();
  callback(e);
};

export const ThingTypeHeader = ({
  label,
  thingTypeId,
  userCanEditThingType,
  userCanCreateThings,
  onCreateThing,
  onEditThingType,
  onDeleteThingType,
  showDeleteDialog,
  setShowDeleteDialog,
  openModalDataExport,
  thingTypeMetadataQuery,
  getThingTypeQuery,
  classes
}) => (
  <React.Fragment>
    <ExpansionPanel
      elevation={0}
      defaultExpanded={false}
      classes={{
        root: classes.root
      }}
      square
    >
      <ExpansionPanelSummary
        classes={{
          content: classes.summaryContent
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <div className={classes.summaryTitleContent}>
          <GroupWorkIcon className={classes.icon} />
          <Typography variant="h5" data-test="thing-type-label">
            {label}
          </Typography>
        </div>
        <div className={classes.summaryTitleContent}>
          <Tooltip title="Export historical data" placement="top">
            <FormIconButton
              onClick={e => preventPropagation(e, () => openModalDataExport())}
              icon={<DataExportIcon />}
              data-test="data-export-icon"
              text="Export"
            />
          </Tooltip>
          {userCanEditThingType && (
            <div className={classes.summaryTitleContent}>
              <Tooltip title="Edit Thing Type" placement="top">
                <FormIconButton
                  icon={<EditIcon />}
                  data-test="edit-thingtype-button"
                  onClick={e =>
                    preventPropagation(e, () => onEditThingType(thingTypeId))
                  }
                />
              </Tooltip>
              <Tooltip title="Delete Thing Type" placement="top">
                <FormIconButton
                  data-test="delete-thingtype-button"
                  icon={<DeleteIcon />}
                  onClick={e =>
                    preventPropagation(e, () => setShowDeleteDialog(true))
                  }
                />
              </Tooltip>
            </div>
          )}
          {userCanCreateThings && (
            <Tooltip title="Add Things to Thing Type" placement="top">
              <FormIconButton
                color="primary"
                text="THINGS"
                data-test="add-things-button"
                icon={<AddIcon />}
                onClick={e => preventPropagation(e, () => onCreateThing())}
              />
            </Tooltip>
          )}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <ThingTypeSummary
          thingTypeId={thingTypeId}
          getThingTypeQuery={getThingTypeQuery}
          thingTypeMetadataQuery={thingTypeMetadataQuery}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <Dialog
      actions={[
        <FormCancelButton
          text="Cancel"
          secondary
          key="cancel"
          onClick={e => preventPropagation(e, () => setShowDeleteDialog(false))}
        />,
        <FormSaveButton
          text="Confirm"
          primary
          key="confirm"
          data-test="confirm"
          onClick={e => {
            preventPropagation(e, () => onDeleteThingType(thingTypeId));
          }}
        />
      ]}
      open={showDeleteDialog}
      title="Confirm delete thing type"
      text={`Are you sure you want to delete the thing type ${label}?`}
    />
  </React.Fragment>
);

ThingTypeHeader.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onEditThingType: PropTypes.func.isRequired,
  onDeleteThingType: PropTypes.func.isRequired,
  userCanEditThingType: PropTypes.bool.isRequired,
  userCanCreateThings: PropTypes.bool.isRequired,
  onCreateThing: PropTypes.func.isRequired,
  thingTypeId: PropTypes.string.isRequired,
  showDeleteDialog: PropTypes.bool.isRequired,
  setShowDeleteDialog: PropTypes.func.isRequired,
  openModalDataExport: PropTypes.func.isRequired,
  thingTypeMetadataQuery: PropTypes.object,
  getThingTypeQuery: PropTypes.object
};

const thingTypeMetadata = gql`
  query($domainId: ID!) {
    domain(domainId: $domainId) {
      thingTypeMetadata
    }
  }
`;

export default compose(
  graphql(GET_THING_TYPE, {
    name: "getThingTypeQuery",
    options: props => ({
      variables: { thingTypeId: props.thingTypeId },
      fetchPolicy: "cache-first"
    })
  }),
  graphql(thingTypeMetadata, {
    name: "thingTypeMetadataQuery",
    skip: props =>
      R.not(
        R.pathOr(false, ["getThingTypeQuery", "thingType", "domain"])(props)
      ),
    options: props => ({
      variables: {
        showError: false,
        domainId: R.path(["getThingTypeQuery", "thingType", "domain"])(props)
      },
      fetchPolicy: "cache-first"
    })
  }),
  withState("showDeleteDialog", "setShowDeleteDialog", false),
  withStyles(styles)
)(ThingTypeHeader);
