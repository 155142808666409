import { Record } from "immutable";

export const Params = new Record({
  viewMode: undefined,
  thingType: undefined,
  thingName: undefined,
  subThingType: undefined,
  subthing: undefined,
  classification: undefined,
  widgetId: undefined
});

export const Query = new Record({
  edit: false,
  sort: undefined
});

export const Location = new Record({
  pathname: undefined,
  dashboardType: undefined,
  params: undefined,
  query: undefined
});

const getDashboardType = pathname => {
  if (!pathname) return "unknown";
  if (pathname.indexOf("/overview") > 0) {
    return "collection";
  } else if (pathname.indexOf("/detail") > 0) {
    return "detail";
  } else {
    return "allThings";
  }
};

export const getThingTypeId = params => {
  if (params.subthing && params.subthing !== "default") {
    return `${params.thingType}/${params.subThingType}/${params.subthing}`;
  } else {
    return params.thingType;
  }
};

export const newLocation = (location, params) =>
  new Location({
    pathname: location ? location.pathname : "",
    dashboardType: location ? getDashboardType(location.pathname) : "",
    params: new Params(params),
    query: location ? new Query(location.query) : {}
  });
