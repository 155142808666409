import { graphql } from "@apollo/client/react/hoc";
import List from "@material-ui/core/List";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import AddIcon from "@material-ui/icons/Add";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import FormIconButton from "components/buttons/form_icon";
import { NotificationBadge } from "components/navbar";
import { ListNavItem } from "components/telenor";
import * as navActions from "ducks/nav";
import { thingTypeSelectors } from "ducks/thing_types";
import { ALL_THING_TYPES } from "graphql/queries";
import { branchModal, withModalHandlers } from "hocs/modal_hocs";
import PropTypes from "prop-types";
import * as R from "ramda";
import { pathOr, pick, pipe, propOr, sortBy, toLower, trim } from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose, withHandlers, withPropsOnChange } from "react-recompose";
import CreateThingTypeModal from "routes/main/things/modals/create_thing_type";
import ThingTypesList from "./thing_type/components/thing_types_list";
import { getStyles } from "./thing_types_sidebar.style";
import { throttle } from "../../../utils/execution_utils";

function noop() {}

const ThingModal = branchModal("CreateThingType", CreateThingTypeModal);
const _doNav = throttle(
  (
    {
      dispatch,
      thingsList,
      thingTypes,
      location: { pathname, query },
      params,
      isCompact
    },
    id
  ) => {
    let path = pathname.split("/");
    path[3] = id;
    path[4] = "overview";
    path = path.slice(0, 5);

    if (params.thingType !== id) {
      const viewMode = pathOr(
        "DefaultView",
        ["entities", "thingTypes", id],
        thingTypes
      );
      // reset to DefaultView
      path[2] = viewMode;
    }
    let newQuery = {};
    if (thingsList) {
      const queryIm = pathOr({}, ["query"], thingsList);
      if (queryIm) {
        newQuery = queryIm;
      } else {
        newQuery = { ...query };
      }
    }
    dispatch(
      push({
        pathname: path.join("/"),
        query: pick(["sort", "page"], newQuery)
      })
    );
    // Can we move this to Router.willTransistionTo?

    if (isCompact) dispatch(navActions.toggleSidebar());
  },
  100
);

const _handleNav = props => id => () => {
  return _doNav(props, id);
};

const _withThingTypes = withPropsOnChange(["data"], newProps => ({
  loading: newProps.data.loading,
  thingTypes: sortedThingTypes(newProps.data)
}));

const sortedThingTypes = pipe(
  pathOr([], ["allThingTypes", "thingTypes"]),
  sortBy(pipe(propOr("", "label"), trim, toLower))
);

class ThingsTypesSidebar extends Component {
  render() {
    const {
      loading,
      thingTypes,
      dispatch,
      params,
      location: { pathname, query },
      handleOpenCreateThingType,
      userCanCreateThingType
    } = this.props;
    const isCompact = isWidthDown("sm", this.props.width);
    const styles = getStyles();
    return (
      <section>
        <List
          style={styles.topItem}
          value={
            pathname.split("/").length === 3 &&
            pathname.split("/")[1] === "things"
              ? "/"
              : ""
          }
          onChange={noop}
        >
          <ListNavItem
            key={"1234567"}
            value={"/"}
            leftIcon={<GroupWorkIcon style={styles.allThingsIcon} />}
            onClick={() => {
              dispatch(push({ pathname: "/", query: query }));
              if (isCompact) dispatch(navActions.hideSidebar());
            }}
            selected={R.propSatisfies(R.isNil, "thingType")(params)}
            data-test={`select-thing-type__all_things`}
          >
            All Things
            {isCompact && <NotificationBadge inSidebar />}
          </ListNavItem>
        </List>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0"
          }}
        >
          {userCanCreateThingType ? (
            <FormIconButton
              text="New thing type"
              variant="contained"
              disabled={loading}
              color="primary"
              icon={<AddIcon />}
              data-test="create-thing-type-button"
              onClick={() => handleOpenCreateThingType()}
            />
          ) : null}
        </div>

        <ThingTypesList
          activeItem={params.thingType}
          handleNav={_handleNav(this.props)}
          isLoading={loading}
          types={thingTypes}
          location={location}
        />
        <ThingModal {...this.props} />
      </section>
    );
  }
}

ThingsTypesSidebar.propTypes = {
  width: PropTypes.string,
  dispatch: PropTypes.func,
  handleOpenCreateThingType: PropTypes.func,
  thingsList: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  userCanCreateThingType: PropTypes.bool,
  userCanEditThingType: PropTypes.bool,
  thingTypes: PropTypes.array,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    allThingTypes: PropTypes.object
  })
};

const makeMapStateToProps = () => {
  return state => ({
    thingsList: state.thingsList,
    userCanCreateThingType: thingTypeSelectors.userCanCreateThingType(state),
    userCanEditThingType: thingTypeSelectors.userCanEditThingType(state)
  });
};

export default compose(
  graphql(ALL_THING_TYPES),
  _withThingTypes,
  withModalHandlers({
    name: "CreateThingType",
    modal: CreateThingTypeModal
  }),
  withHandlers({
    handleOpenCreateThingType: ({ openModalCreateThingType }) => (
      thingTypeId = "create"
    ) => openModalCreateThingType({ thingTypeId }),
    onCancel: ({ closeModalCreateThingType }) => () =>
      closeModalCreateThingType()
  }),
  connect(makeMapStateToProps)
)(withWidth()(ThingsTypesSidebar));
