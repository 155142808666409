import FormIconButton from "components/buttons/form_icon";
import FormSaveButton from "components/buttons/form_save";
import { Dialog } from "components/telenor/dialog";
import { Field, Form, ModalFormButtons } from "forms_new";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import Joi from "joi-browser";
import PropTypes from "prop-types";
import React from "react";

const schema = Joi.object().keys({
  label: Joi.string()
    .required()
    .regex(/^[a-zA-Z0-9:_-]+$/)
    .options({
      language: {
        any: {
          empty: "!!Not allowed to be empty",
          required: "!!Required"
        },
        string: {
          regex: {
            base: "may not contain spaces or any special characters"
          }
        }
      }
    }),
  description: Joi.string()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  thingType: Joi.string()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  externalId: Joi.string()
    .empty("")
    .trim()
});

const CreateNetworkedThingPage = ({
  onSubmit,
  onCancel,
  isLoading,
  modalStateCreateNetworkedThing: { open }
}) => (
  <Dialog open={open} onClose={onCancel} title="Create new networked thing">
    <Form schema={schema} onSubmit={onSubmit}>
      <Field
        id="thingType"
        type="autocomplete"
        suggesterType={DATA_TYPES.THING_TYPES}
        data-test="thingType-suggester"
        label="Assign an existing thing type"
      />
      <Field
        id="label"
        label="Thing Label"
        name="label"
        dataTest="create-networked-thing-label-field"
      />
      <Field
        id="description"
        label="Description"
        name="description"
        dataTest="create-networked-thing-description-field"
      />
      <Field
        id="externalId"
        key="externalId"
        label="External ID"
        dataTest="create-networked-thing-external-id-field"
      />

      <ModalFormButtons>
        <FormIconButton
          text="Cancel"
          disabled={isLoading}
          onClick={onCancel}
          data-test="create-networked-thing-cancel-button"
        />
        <FormSaveButton
          text="Save"
          isLoading={isLoading}
          data-test="create-networked-thing-submit-button"
        />
      </ModalFormButtons>
    </Form>
  </Dialog>
);

CreateNetworkedThingPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired,
  parentThingName: PropTypes.string.isRequired,
  modalStateCreateNetworkedThing: PropTypes.object,
  isLoading: PropTypes.bool
};

export default CreateNetworkedThingPage;
