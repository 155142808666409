import React from "react";
import styled from "styled-components";
import { FormButtons, Form, FieldSet, Field } from "../";
import FormSaveButton from "components/buttons/form_save";
import { Link } from "react-router";
import { getStyles } from "./index.style";

const SignupButtonWrapper = styled(FormButtons)`
  margin: 50px 0px 20px 0px;
`;

const SignupUserForm = ({ ...form }) => {
  const styles = getStyles();
  return (
    <Form schema={form.signupSchema} {...form} allowUnknown>
      <FieldSet style={styles.fieldset}>
        <Field id="userName" label="Username" />
        <Field id="email" label="E-mail" />
        <Field id="password" label="Password" type="password" />
        <Field
          id="passwordConfirm"
          label="Password confirmation"
          type="password"
        />
        <Field id="firstName" label="First name" />
        <Field id="lastName" label="Last name" />
        <Field
          id="phone"
          label="Phone"
          hint="International format like +46 738 123456"
        />
        <Field id="company" label="Company" />
        <Field id="address" label="Address" />
        <Field id="zip" label="Zip" />
        <Field id="city" label="City" />
        <Field id="country" label="Country" />
        <Field id="notes1" label="Additional Information 1" />
        <Field id="notes2" label="Additional Information 2" />
        <Field id="notes3" label="Additional Information 3" />
      </FieldSet>

      <SignupButtonWrapper>
        <FormSaveButton
          variant="contained"
          fullWidth
          type="submit"
          text="SIGN UP"
          disabled={form.isLoading}
        />
      </SignupButtonWrapper>

      <Link to={"/"} style={styles.link({ textAlign: "right" })}>
        Already have an account? sign in
      </Link>
    </Form>
  );
};

export default SignupUserForm;
