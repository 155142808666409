import AWS from "aws-sdk/global";
import * as R from "ramda";
import { manifest } from "../aws/manifest";

const globals = { manifest, AWS };

const canClearCachedId = R.pathSatisfies(R.is(Function), [
  "config",
  "credentials",
  "clearCachedId"
]);

const credentials = (token, AWS, { IdentityPool, Region, UserPool }) =>
  new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPool,
    Logins: { [`cognito-idp.${Region}.amazonaws.com/${UserPool}`]: token }
  });

export const configureAuthenticated = (token, { AWS, manifest } = globals) => {
  AWS.config.credentials = credentials(token, AWS, manifest);
  return AWS.config.credentials;
};

export const configureUnauthenticated = ({ AWS, manifest } = globals) => {
  if (canClearCachedId(AWS)) {
    AWS.config.credentials.clearCachedId();
  }

  AWS.config.credentials = credentials(null, AWS, manifest);
  return AWS.config.credentials;
};
