import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { compose, withHandlers, withState } from "react-recompose";
import Calendar from "../calendar/calendar";
import Time from "../time/time";
import CalendarWrapper from "./styled/calendar_wrapper";
import Container from "./styled/container";
import TimePickerWrapper from "./styled/time_picker_wrapper";

const validateTimeSelection = timeSelection =>
  timeSelection
    .split(":")
    .reduce((ack, component) => ack && component.length > 0);

const DateTimePicker = ({
  startDate,
  endDate,
  handleCalendarChange,
  handleTimeChange,
  filterDate,
  isEndPicker,
  resetToNow,
  dateTimeSelection,
  handleResetToToday
}) => {
  return (
    <Container
      isEndPicker={isEndPicker}
      data-test={
        isEndPicker
          ? "observation-period-picker__end"
          : "observation-period-picker__start"
      }
    >
      <CalendarWrapper isEndPicker={isEndPicker}>
        <Calendar
          inline
          startDate={startDate}
          endDate={endDate}
          fixedHeight
          selected={dateTimeSelection.date}
          onChange={handleCalendarChange}
          filterDate={filterDate}
        />
      </CalendarWrapper>
      <TimePickerWrapper isEndPicker={isEndPicker}>
        <Time
          isEndPicker={isEndPicker}
          label={isEndPicker ? "End time" : "Start time"}
          selectedTime={dateTimeSelection.time}
          onChange={handleTimeChange}
          onResetToToday={handleResetToToday}
        />
      </TimePickerWrapper>
    </Container>
  );
};

DateTimePicker.propTypes = {
  handleResetToToday: PropTypes.func,
  dateTimeSelection: PropTypes.object,
  resetToNow: PropTypes.func,
  isEndPicker: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  handleCalendarChange: PropTypes.func,
  handleTimeChange: PropTypes.func,
  filterDate: PropTypes.func
};

DateTimePicker.defaultProps = {};

export default compose(
  withState("dateTimeSelection", "setDateTimeSelection", ({ selected }) => ({
    date: new Date(selected),
    time: formatDate(selected, "HH:mm")
  })),
  withHandlers({
    handleCalendarChange: ({
      dateTimeSelection,
      setDateTimeSelection,
      onChange
    }) => selectedDate => {
      const [hours, minutes] = dateTimeSelection.time.split(":");
      const newDate = new Date(selectedDate);
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      setDateTimeSelection({ time: dateTimeSelection.time, date: newDate });
      if (onChange) {
        const timeSelectionIsValid = validateTimeSelection(
          dateTimeSelection.time
        );
        onChange(newDate, timeSelectionIsValid);
      }
    },
    handleTimeChange: ({
      dateTimeSelection,
      setDateTimeSelection,
      onChange
    }) => newTime => {
      const newDate = new Date(dateTimeSelection.date);
      const [hours, minutes] = newTime.split(":");
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      const newSelection = { date: newDate, time: newTime };
      setDateTimeSelection(newSelection);
      if (onChange) {
        // Notify the calling component that the date has changed
        onChange(newDate, validateTimeSelection(newTime));
      }
    },
    handleResetToToday: ({ setDateTimeSelection, onChange }) => () => {
      const now = new Date();
      const newSelection = { date: now, time: formatDate(now, "HH:mm") };
      setDateTimeSelection(newSelection);
      if (onChange) {
        onChange(now, true);
      }
    }
  })
)(DateTimePicker);
