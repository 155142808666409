import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableHeaderCell } from "components/table";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const DEFAULT_TABLE_HEIGHT = 300;

const StyledContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: ${props => props.height}px;
`;

export const TABLE_TIMESTAMP_FORMAT = "yyyy-MM-dd HH:mm:ss";

const TableWidget = ({ observations, container, unit }) => {
  return (
    <StyledContainer height={container?.height || DEFAULT_TABLE_HEIGHT}>
      <Table stickyHeader selectable={false}>
        <TableHead displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderCell tooltip="Time of reading">
              Timestamp
            </TableHeaderCell>
            <TableHeaderCell tooltip="Value of reading">
              {"Value".toUpperCase()}
              {unit && (
                <div>
                  <small>({unit})</small>
                </div>
              )}
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody displayRowCheckbox={false}>
          {observations.map((observation, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{observation.get("timestamp")}</TableCell>
                <TableCell>{observation.get("value")}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledContainer>
  );
};

TableWidget.propTypes = {
  observations: PropTypes.object,
  container: PropTypes.object,
  unit: PropTypes.string
};
TableWidget.defaultProps = {};

export default TableWidget;
