import { createAction, handleActions } from "redux-actions";
import { fromJS, Map, List } from "immutable";
import { pick } from "ramda";
import * as imu from "utils/immutable_utils";

const ranStr = len =>
  Math.random()
    .toString(36)
    .substring(len);

export const initialState = fromJS({
  isLoading: false,
  error: null,
  filters: {}
});

const defaultResourceFilter = Map({
  id: null,
  hasSelectedResource: false,
  name: null,
  operator: "eq", // eq, gt, lt
  value: null
});

export const defaultRootFilter = fromJS({
  data: {
    genericList: {},
    domains: {},
    resources: {}
  },
  filter: {
    geo: List(),
    genericList: {},
    domains: {},
    thingTypes: [],
    thingStatus: "-1",
    resources: List([defaultResourceFilter.set("id", Math.random() * 100000)]),
    freeText: Map([[ranStr(5), ""]])
  },
  view: {
    geoIsActive: false
  }
});

const CLEAR_FILTERS = "CLEAR_FILTERS";
const UPDATE_THING_STATUS = "UPDATE_THING_STATUS";
const TOGGLE_ACTIVE_GEO_FILTER = "TOGGLE_ACTIVE_GEO_FILTER";
const UPDATE_FREE_TEXT_SEARCH = "UPDATE_FREE_TEXT_SEARCH";
const ADD_FREE_TEXT_SEARCH = "ADD_FREE_TEXT_SEARCH";
const REMOVE_FREE_TEXT_SEARCH = "REMOVE_FREE_TEXT_SEARCH";

export const INIT_FILTER_REQUEST = "INIT_FILTER_REQUEST";
export const INIT_FILTER_SUCCESS = "INIT_FILTER_SUCCESS";

const UPDATE_GENERIC_FILTER = "UPDATE_GENERAL_FILTER";
const UPDATE_GEO_FILTER = "UPDATE_GEO_FILTER";
const REMOVE_GEO_FILTER = "REMOVE_GEO_FILTER";

const UPDATE_RESOURCE_FILTER = "UPDATE_RESOURCE_FILTER";
const ADD_RESOURCE_FILTER = "ADD_RESOURCE_FILTER";
const REMOVE_RESOURCE_FILTER = "REMOVE_RESOURCE_FILTER";

const ADD_ROOT_FILTER = "ADD_ROOT_FILTER";

export const updateThingStatus = (filterId, thingStatus) => ({
  type: UPDATE_THING_STATUS,
  filterId,
  thingStatus
});

export const clearFilters = (filterId, thingType) => ({
  type: CLEAR_FILTERS,
  filterId,
  thingType
});

export const updateGenericFilter = (
  filterId,
  filterType,
  selectedIndices,
  genericList
) => ({
  type: UPDATE_GENERIC_FILTER,
  filterId,
  filterType,
  selectedIndices,
  genericList
});

export const removeResourceFilter = (filterId, index) => ({
  type: REMOVE_RESOURCE_FILTER,
  filterId,
  index
});

export const addResourceFilter = (filterId, index) => ({
  type: ADD_RESOURCE_FILTER,
  filterId,
  index
});

export const addRootFilter = (filterId, thingType) => ({
  type: ADD_ROOT_FILTER,
  filterId,
  thingType
});

export const updateResourceFilter = (filterId, index, item) => ({
  type: UPDATE_RESOURCE_FILTER,
  filterId,
  index,
  item
});

export const initFilter = createAction(INIT_FILTER_REQUEST);
export const initFilterSuccess = createAction(INIT_FILTER_SUCCESS);

export const updateGeoFilter = (filterId, geoJson) => {
  return {
    type: UPDATE_GEO_FILTER,
    filterId,
    geoJson
  };
};

export const removeGeoFilter = filterId => {
  return {
    type: REMOVE_GEO_FILTER,
    filterId
  };
};

export const updateFreeTextSearch = (id, key, text) => ({
  type: UPDATE_FREE_TEXT_SEARCH,
  id,
  key,
  text
});

export const addFreeTextSearch = id => ({
  type: ADD_FREE_TEXT_SEARCH,
  id
});

export const removeFreeTextSearch = (id, key) => ({
  type: REMOVE_FREE_TEXT_SEARCH,
  id,
  key
});

export const toggleGeoActive = filterId => ({
  type: TOGGLE_ACTIVE_GEO_FILTER,
  filterId
});

const makeRootFilter = (filterId, thingType) =>
  filterId === "allThings"
    ? defaultRootFilter
    : thingType
    ? defaultRootFilter.setIn(["filter", "thingTypes"], List([thingType]))
    : defaultRootFilter;

export default handleActions(
  {
    [UPDATE_THING_STATUS]: (state, { filterId, thingStatus }) =>
      state.setIn(["filters", filterId, "filter", "thingStatus"], thingStatus),
    [TOGGLE_ACTIVE_GEO_FILTER]: (state, { filterId }) =>
      state.updateIn(
        ["filters", filterId, "view", "geoIsActive"],
        isActive => !isActive
      ),
    [REMOVE_GEO_FILTER]: (state, { filterId }) =>
      state
        .setIn(["filters", filterId, "filter", "geo"], List())
        .setIn(["filters", filterId, "view", "geoIsActive"], false),
    [UPDATE_GEO_FILTER]: (state, { filterId, geoJson }) =>
      geoJson.size > 2
        ? state.setIn(["filters", filterId, "filter", "geo"], geoJson)
        : state
            .setIn(["filters", filterId, "filter", "geo"], List())
            .setIn(["filters", filterId, "view", "geoIsActive"], false),
    [REMOVE_RESOURCE_FILTER]: (state, { filterId, index }) =>
      state.updateIn(["filters", filterId, "filter", "resources"], list => {
        const newList = list.remove(index);
        return newList.size === 0
          ? newList.push(
              defaultResourceFilter.set("id", Math.random() * 100000)
            )
          : newList;
      }),
    [ADD_RESOURCE_FILTER]: (state, { filterId }) =>
      state.updateIn(["filters", filterId, "filter", "resources"], list =>
        list.push(defaultResourceFilter.set("id", Math.random() * 100000))
      ),
    [ADD_ROOT_FILTER]: (state, { filterId, thingType }) => {
      const path = ["filters", filterId];
      return state.getIn(["filters", filterId])
        ? state
        : state.setIn(path, makeRootFilter(filterId, thingType));
    },
    [UPDATE_RESOURCE_FILTER]: (state, { filterId, index, item }) =>
      state.updateIn(["filters", filterId, "filter", "resources"], list =>
        list.update(index, listItem => listItem.merge(item))
      ),
    [CLEAR_FILTERS]: (state, { filterId, thingType }) =>
      state.setIn(["filters", filterId], makeRootFilter(filterId, thingType)),
    [UPDATE_GENERIC_FILTER]: (
      state,
      { filterId, filterType, selectedIndices, genericList }
    ) => {
      const path = ["filters", filterId, "filter", `${filterType}DropDown`];
      const pathForFilter = ["filters", filterId, "filter", filterType];
      const items = imu.toJSArray(genericList);
      const selections = selectedIndices.map(index => ({
        ...pick(["id", "name", "label"], imu.toJS(items[index])),
        ...{ index }
      }));
      state = state.setIn(
        path,
        selections.reduce(
          (sel, curr) =>
            sel
              .update("ids", (list = List()) => list.push(curr.id))
              .update("indices", (list = List()) => list.push(curr.index))
              .update("names", (list = List()) =>
                list.push(curr.name || curr.label)
              )
              .set("suggesterItems", items),
          Map()
        )
      );
      state = state.setIn(pathForFilter, List(selections.map(cur => cur.id)));
      return state;
    },
    [ADD_FREE_TEXT_SEARCH]: (state, { id }) =>
      state.updateIn(["filters", id, "filter", "freeText"], (m = Map()) =>
        m.set(ranStr(5), "")
      ),
    [REMOVE_FREE_TEXT_SEARCH]: (state, { id, key }) =>
      state.updateIn(["filters", id, "filter", "freeText"], (m = Map()) =>
        m.size > 1 ? m.delete(key) : m.update(key, () => "")
      ),
    [UPDATE_FREE_TEXT_SEARCH]: (state, { id, key, text }) =>
      state.updateIn(["filters", id, "filter", "freeText"], (m = Map()) =>
        m.update(key, () => text)
      ),
    [INIT_FILTER_SUCCESS]: (state, { payload: { filterId, thingType } }) => {
      const path = ["filters", filterId];
      return state.getIn(path)
        ? state
        : state.setIn(path, makeRootFilter(filterId, thingType));
    }
  },
  initialState
);
