import Joi from "joi-browser";

export const operators = {
  gt: "> (greater than)",
  gte: ">= (greater than, or equal)",
  lt: "< (less than)",
  lte: "<= (less than, or equal)",
  eq: "== (equals)",
  neq: "!= (not equals)"
};

const dataSourceOptions = [
  {
    label: "Yes",
    id: "dataSource-historical",
    value: "HISTORICAL",
    index: 0
  },
  {
    label: "No",
    id: "dataSource-current",
    value: "CURRENT",
    index: 1
  }
];

export default () => ({
  defaults: {
    resourceName: "latlng"
  },
  fields: [
    {
      id: "dataSource",
      key: "dataSource",
      name: "dataSource",
      label: "Show historical trace",
      options: dataSourceOptions,
      type: "select"
    },
    {
      id: "conditions",
      name: "color",
      hintText: "color",
      options: operators,
      type: "color_thresholds_with_operator"
    }
  ],
  initialModel: {
    dataSource: "CURRENT",
    isObservationsAggregated: "RAW_OBSERVATIONS",
    conditions: {
      resourceId: undefined,
      markerColors: []
    }
  },
  schema: {
    dataSource: Joi.string().required(),
    isObservationsAggregated: Joi.string().required(),
    conditions: Joi.object({
      markerColors: Joi.array().items(
        Joi.object({
          operator: Joi.string().valid(...Object.keys(operators)),
          threshold: Joi.string().allow(""),
          color: Joi.string()
            .empty()
            .required()
        })
      ),
      resourceId: Joi.when("markerColors", {
        is: Joi.array().min(1),
        then: Joi.string()
          .required()
          .empty(),
        otherwise: Joi.string()
      })
    })
  }
});
