import { graphql } from "@apollo/client/react/hoc";
import { adaptThingShadow } from "adapters/thing_adapter_normalized";
import { getObservation } from "ducks/observation/observation_selectors";
import { getThingDetailsSuccess, thingSelectors } from "ducks/thing";
import { GET_THING_SHADOW } from "graphql/queries";
import { withIsLoading } from "graphql/utils/general";
import { fromJS } from "immutable";
import { path } from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, withPropsOnChange } from "react-recompose";

const mapStateToProps = (state, props) => {
  const {
    params: { viewMode, thingType, thingName, subthing }
  } = props;
  const thingShadow = thingSelectors.getThingShadow(state.thing, thingName);
  return {
    thingShadow,
    thingName,
    thingType,
    viewMode,
    subthing,
    observationPeriod: getObservation(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShadow: ({ thingName, thingShadow }) =>
      dispatch(getThingDetailsSuccess({ thingName, thingShadow }))
  };
};

export const onThingShadowUpdated = (props, adaptThingShadow) => {
  const domainPath = path(["thing", "domainTopic"])(props.data);
  const thingShadow = fromJS(
    adaptThingShadow({
      thingName: props.thingName,
      thingType: props.thingType,
      ...path(["thing"])(props.data)
    })
  );

  props.setShadow({ thingName: props.thingName, thingShadow });

  return { domainPath };
};

const _withThingShadow = withPropsOnChange(
  (props, nextProps) =>
    path(["thing", "shadow"])(props.data) !=
    path(["thing", "shadow"])(nextProps.data),
  props => onThingShadowUpdated(props, adaptThingShadow)
);

const _withThingName = withPropsOnChange(
  (props, newProps) => props.params.thingName != newProps.params.thingName,
  props => {
    return { thingName: props.params.thingName };
  }
);

const thingTypeResourcesPath = [
  "data",
  "thing",
  "thingTypeConnection",
  "resourcesConnection",
  "resources"
];

const networkedThingsPath = [
  "data",
  "thing",
  "networkedThingsConnection",
  "things"
];

const _withThingTypeResources = withPropsOnChange(
  (props, nextProps) =>
    path(thingTypeResourcesPath, props) !==
    path(thingTypeResourcesPath, nextProps),
  props => ({ thingTypeResources: path(thingTypeResourcesPath, props) })
);

const _withNetworkedThingUpdates = withPropsOnChange(
  (props, nextProps) =>
    path(networkedThingsPath, props) !== path(networkedThingsPath, nextProps),
  props => onThingShadowUpdated(props, adaptThingShadow)
);

export const observationLifecycle = compose(
  withRouter,
  _withThingName,
  graphql(GET_THING_SHADOW, {
    options: () => ({ fetchPolicy: "network-only" })
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withIsLoading,
  _withThingShadow,
  _withThingTypeResources,
  _withNetworkedThingUpdates
);
