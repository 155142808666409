import { Observable, defer } from "rxjs";
import { refreshToken$, refreshTokenLock } from "./refresh_token";
import { traceLog } from "./trace";
import shouldRefreshToken from "./should_refresh_token";
import "unfetch/polyfill";

const withAuth = (storage, headers) => {
  const apiKey = storage.getItem("apiKey");
  const account = storage.getItem("account");
  const { token, identityId } = JSON.parse(account).credentials || {};

  return {
    ...headers,
    Authorization: token ? `Bearer ${token}` : "",
    identityId,
    "X-Api-Key": apiKey
  };
};

const doFetch = (storage, fetcher, url, options) =>
  defer(() =>
    Observable.fromPromise(
      fetcher(url, { ...options, headers: withAuth(storage, options.headers) })
    ).mergeMap(response => {
      return response.status === 401
        ? Observable.throw(new Error("Token is expired"))
        : Observable.of(response);
    })
  );

export const fetchWithRefreshToken = ({ fetcher, lock, refresh$, storage }) => (
  url,
  options
) => {
  return lock
    .sync(doFetch(storage, fetcher, url, options))
    .catch(error => {
      if (shouldRefreshToken(error)) {
        traceLog("[fetch] refreshing token");
        return lock
          .singleton(defer(() => refresh$()))
          .mergeMap(() => doFetch(storage, fetcher, url, options));
      } else {
        return Observable.throw(error);
      }
    })
    .toPromise();
};

export default fetchWithRefreshToken({
  lock: refreshTokenLock,
  refresh$: refreshToken$,
  storage: localStorage,
  fetcher: fetch
});
