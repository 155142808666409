import { compose, withProps } from "react-recompose";
import {
  always,
  assoc,
  concat,
  ifElse,
  isNil,
  objOf,
  path,
  pipe,
  prop,
  propSatisfies
} from "ramda";
import FileList from "components/file_list/file_list_container";

export const _getSelected = ifElse(
  propSatisfies(isNil, "thingName"),
  prop("thingType"),
  prop("thingName")
);

export const _getScope = ifElse(
  propSatisfies(isNil, "thingName"),
  always("thingType"),
  always("thingName")
);

export const _withWidgetId = withProps(
  pipe(prop("selected"), concat("filesWidget-"), objOf("widgetId"))
);

export const _withScope = withProps(pipe(_getScope, objOf("scope")));
export const _withSelected = withProps(pipe(_getSelected, objOf("selected")));
export const _withoutProgressLine = withProps(assoc("showProgressLine", false));
export const _withHeight = withProps(
  pipe(path(["container", "height"]), objOf("height"))
);

export default compose(
  _withSelected,
  _withScope,
  _withWidgetId,
  _withoutProgressLine,
  _withHeight
)(FileList);
