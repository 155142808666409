import PropTypes from "prop-types";
import React from "react";
import { Scale, Cursor, ValueLabel, Legend } from "./gauge_chart_parts";

export const constructThresholds = (min, max, thresholds) => {
  let _thresholds = [];
  // Yes, I know it's a little ugly and imperative. But the tests work 😬
  if (thresholds && thresholds.length > 0) {
    _thresholds.push({
      y: Number(thresholds[0].threshold) - min,
      label: `${min}-${Number(thresholds[0].threshold)}`
    });

    for (let i = 0; i < thresholds.length - 1; i++) {
      _thresholds.push({
        y:
          Number(thresholds[i + 1].threshold) - Number(thresholds[i].threshold),
        label: `${Number(thresholds[i].threshold)}-${Number(
          thresholds[i + 1].threshold
        )}`
      });
    }
    _thresholds.push({
      y: max - Number(thresholds[thresholds.length - 1].threshold),
      label: `${Number(thresholds[thresholds.length - 1].threshold)}-${max}`
    });
  } else {
    _thresholds.push({ y: max, label: `${min}-${max}` });
  }
  return _thresholds.filter(t => t.y > 0); // just remove empty sections that otherwise would be displayed as "40 - 40" for instance.
};

const GaugeChart = ({
  min,
  max,
  value,
  pendingValue,
  unit,
  thresholds: colorThresholdConfig,
  theme,
  dimensions,
  numberOfDecimals
}) => {
  const pieThresholds = constructThresholds(min, max, colorThresholdConfig);

  return (
    <svg
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      width="100%"
      height="100%"
    >
      <Scale
        dimensions={dimensions}
        pieThresholds={pieThresholds}
        colorThresholdConfig={colorThresholdConfig}
        min={min}
        max={max}
        value={value}
        theme={theme}
      />
      <Legend
        pieThresholds={pieThresholds}
        colorThresholdConfig={colorThresholdConfig}
        theme={theme}
      />
      <Cursor dimensions={dimensions} min={min} max={max} value={value} />
      {pendingValue && (
        <Cursor
          dimensions={dimensions}
          min={min}
          max={max}
          value={pendingValue}
          color={"rgba(0, 0, 0, 0.5)"}
        />
      )}
      <ValueLabel
        dimensions={dimensions}
        value={value}
        pendingValue={pendingValue}
        unit={unit}
        numberOfDecimals={numberOfDecimals}
      />
    </svg>
  );
};

GaugeChart.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  pendingValue: PropTypes.number,
  unit: PropTypes.string,
  thresholds: PropTypes.array,
  theme: PropTypes.object,
  dimensions: PropTypes.object,
  numberOfDecimals: PropTypes.number
};

export default GaugeChart;
