import React from "react";
import PropTypes from "prop-types";
import ForwardIcon from "@material-ui/icons/Forward";

const pendingWrapperStyle = { color: "grey" };

const pendingIconStyle = {
  color: "grey",
  marginLeft: "8px",
  marginRight: "8px"
};

const pendingTooltipText = "Waiting for thing to mark setting as accepted";

const PendingValueDisplay = ({ displayValue }) => (
  <span style={pendingWrapperStyle} title={pendingTooltipText}>
    <ForwardIcon data-test="forward-icon" style={pendingIconStyle} />
    {displayValue}
  </span>
);

PendingValueDisplay.propTypes = {
  displayValue: PropTypes.any
};

export default PendingValueDisplay;
