import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const AppboardSnackbar = withStyles(theme => ({
  anchorOriginBottomCenter: {
    bottom: theme.spacing(3)
  }
}))(props => {
  const { onClose } = props;
  return (
    <Snackbar
      {...props}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      action={[
        <Button key="close" onClick={onClose} color="secondary" size="small">
          Close
        </Button>
      ]}
    />
  );
});

AppboardSnackbar.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AppboardSnackbar;
