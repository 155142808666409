import * as R from "ramda";

const validate = (parseFunc, error = "Must enter a valid number") => v =>
  R.when(
    R.pipe(R.prop("error"), R.isNil),
    R.ifElse(
      parseFunc,
      R.always({
        ...v,
        error: null
      }),
      R.always({ ...v, error: error })
    )
  )(v);

export const chain = (...validators) => R.pipe(...validators);

export const Keyword = ({ value }) => ({ value, error: null });

export const Float = validate(({ value }) => !isNaN(value));

export const Int = validate(
  ({ value }) =>
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
);

export const Between = (min, max, inc = true) =>
  validate(
    ({ value }) => value > min - inc && value < max + inc,
    `Must enter a valid number between ${min} and ${max}`
  );

export const Max = (max, inc = true) =>
  validate(
    ({ value }) => value < max + inc,
    `Must enter a valid number that is lower ${
      inc ? "or equals to" : "than"
    } ${max}`
  );
export const Min = (min, inc = true) =>
  validate(
    ({ value }) => value > min - inc,
    `Must enter a valid number that is higher ${
      inc ? "or equals to" : "than"
    } ${min}`
  );
export const Lat = chain(Float, Between(-90, 90));
export const Lon = chain(Float, Between(-180, 180));
