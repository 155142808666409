import { handleActions, createAction } from "redux-actions";
import * as R from "ramda";

export const THINGS_LIST_REQUEST = "THINGS_LIST_REQUEST";
export const THINGS_LIST_SUCCESS = "THINGS_LIST_SUCCESS";
export const THINGS_LIST_FAILURE = "THINGS_LIST_FAILURE";
export const THINGS_LIST_SET_LOADING = "THINGS_LIST_SET_LOADING";

export const DOWNLOAD_CERTIFICATE_REQUEST = "DOWNLOAD_CERTIFICATE_REQUEST";
export const DOWNLOAD_CERTIFICATE_SUCCESS = "DOWNLOAD_CERTIFICATE_SUCCESS";
export const DOWNLOAD_CERTIFICATE_FAILURE = "DOWNLOAD_CERTIFICATE_FAILURE";

export const REPLACE_CERTIFICATE_REQUEST = "REPLACE_CERTIFICATE_REQUEST";
export const REPLACE_CERTIFICATE_SUCCESS = "REPLACE_CERTIFICATE_SUCCESS";
export const REPLACE_CERTIFICATE_FAILURE = "REPLACE_CERTIFICATE_FAILURE";

export const UPDATE_THINGS_FILTER = "UPDATE_THINGS_FILTER";
export const CLEAR_THINGSLIST_FILTER = "CLEAR_THINGSLIST_FILTER";

export const getThingsList = createAction(THINGS_LIST_REQUEST);
export const thingsListSuccess = createAction(THINGS_LIST_SUCCESS);
export const thingsListFailure = createAction(THINGS_LIST_FAILURE);
export const downloadCertificate = createAction(DOWNLOAD_CERTIFICATE_REQUEST);
export const downloadCertificateFailure = createAction(
  DOWNLOAD_CERTIFICATE_FAILURE
);
export const downloadCertificateSuccess = createAction(
  DOWNLOAD_CERTIFICATE_SUCCESS
);
export const replaceCertificate = createAction(REPLACE_CERTIFICATE_REQUEST);
export const replaceCertificateSuccessful = createAction(
  REPLACE_CERTIFICATE_SUCCESS
);
export const replaceCertificateFailure = createAction(
  REPLACE_CERTIFICATE_FAILURE
);

export const changeThingsFilter = (thingFilter, domainFilter) => ({
  type: UPDATE_THINGS_FILTER,
  thingFilter,
  domainFilter
});

export const clearThingsListFilter = () => dispatch => {
  dispatch({
    type: CLEAR_THINGSLIST_FILTER
  });
};

export const initialState = {
  request: {
    isLoading: false,
    error: ""
  },
  result: [],
  entities: {
    thingTypes: {},
    things: {}
  }
};

export default handleActions(
  {
    [DOWNLOAD_CERTIFICATE_REQUEST]: (state, action) =>
      R.evolve({ request: { isLoading: R.T, error: R.empty } }, state),

    [DOWNLOAD_CERTIFICATE_SUCCESS]: (state, action) =>
      R.evolve({ request: { isLoading: R.F, error: R.empty } }, state),

    [DOWNLOAD_CERTIFICATE_FAILURE]: (state, action) =>
      R.evolve(
        { request: { isLoading: R.F, error: R.always(action.payload) } },
        state
      ),

    [REPLACE_CERTIFICATE_REQUEST]: (state, action) =>
      R.evolve({ request: { isLoading: R.T, error: R.empty } }, state),

    [REPLACE_CERTIFICATE_SUCCESS]: (state, action) =>
      R.evolve({ request: { isLoading: R.F, error: R.empty } }, state),

    [REPLACE_CERTIFICATE_FAILURE]: (state, action) =>
      R.evolve(
        { request: { isLoading: R.F, error: R.always(action.payload) } },
        state
      ),

    [THINGS_LIST_REQUEST]: state =>
      R.evolve({ request: { isLoading: R.T, error: R.empty } }, state),

    [THINGS_LIST_SUCCESS]: (state, { payload: { things, thingTypes } }) =>
      R.evolve({
        request: { isLoading: R.F, error: R.empty },
        result: R.union(R.keys(thingTypes)),
        entities: {
          things: R.mergeDeepLeft(things),
          thingTypes: R.mergeDeepWith(R.union, thingTypes)
        }
      })(state),

    [THINGS_LIST_FAILURE]: (state, action) =>
      R.evolve(
        { request: { isLoading: R.F, error: R.always(action.payload) } },
        state
      )
  },
  initialState
);
