import * as R from "ramda";
import Joi from "joi-browser";
import intervalForm, {
  INTERVAL_NAME
} from "./widget_edit_aggregation_interval";

const OPTION_NAME = "isObservationsAggregated";
const RAW = "RAW_OBSERVATIONS";
const AGGREGATED = "AGGREGATED_OBSERVATIONS";
const options = [
  { id: RAW, value: RAW, label: "No" },
  { id: AGGREGATED, value: AGGREGATED, label: "Yes" }
];

export const isObservationsAggregatedSchema = {
  isObservationsAggregated: Joi.string().required()
};

export default ({
  isRealtime = false,
  isObservationsAggregated,
  onIsObservationsAggregatedChange
}) => {
  const interval = intervalForm();

  if (isRealtime) {
    return {
      initialModel: { [OPTION_NAME]: RAW, ...interval.initialModel },
      schema: { ...isObservationsAggregatedSchema, [INTERVAL_NAME]: Joi.any() }
    };
  }

  const form = {
    fields: [
      {
        id: OPTION_NAME,
        name: OPTION_NAME,
        label: "Aggregate observations",
        hintText: "Aggregate observations",
        type: "select",
        options,
        disabled: isRealtime,
        onChange: onIsObservationsAggregatedChange
      }
    ],
    initialModel: { [OPTION_NAME]: AGGREGATED },
    schema: isObservationsAggregatedSchema
  };

  return isObservationsAggregated == null ||
    isObservationsAggregated === AGGREGATED
    ? R.mergeDeepWith(R.concat, form, interval)
    : { ...form, schema: { ...form.schema, [INTERVAL_NAME]: Joi.any() } };
};
