import styled from "styled-components";

const ThingShadowWidgetsGrid = styled.div.attrs({
  // this class is used as a selector in some place(s), should probably changed
  className: "thing-shadow__widgets-grid"
})`
  padding: 0;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 0px;
  z-index: -400;
`;

export default ThingShadowWidgetsGrid;
