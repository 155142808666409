import styled from "styled-components";
import { always, ifElse, cond, equals, pathOr, pipe, toLower, T } from "ramda";

export const contentBaseVerticalOverflow = ifElse(
  pipe(pathOr(false, ["scrollable"]), equals(true)),
  always("auto"),
  pipe(
    pathOr("", ["data-type"]),
    toLower,
    cond([
      [equals("allthings"), always("visible")],
      [T, always("hidden")]
    ])
  )
);

export default styled.div`
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  word-wrap: break-word;
  width: 100%;
  ${props => `overflow-y: ${contentBaseVerticalOverflow(props)}`};
  ${props => props.contentDirection === "row" && "flex-direction: row"};
`;
