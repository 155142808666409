import "core-js/stable";
import "themes/global_styles";

import React from "react";
import { render } from "react-dom";
import { pipe } from "ramda";
import appWithStore from "./app";
import { initStorage, initEnvAndAccount, initAws } from "./aws_init";
import "unfetch/polyfill";

const renderApp = App => render(<App />, global.document.getElementById("app"));
const errorPage = text => () => <p>{text}</p>;

pipe(
  initStorage,
  initEnvAndAccount,
  initAws({ renderApp, errorPage })
)(appWithStore);
