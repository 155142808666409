import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

import WeeklyForm from "./weekly_form";
import BackdateSelector from "./backdate_selector_form";
import DailyForm from "./daily_form";

const ScheduledForm = props => {
  const {
    scheduleParams: { scheduleType },
    handleScheduleTypeChanged
  } = props;
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Select value={scheduleType} onChange={handleScheduleTypeChanged}>
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        {scheduleType == "daily" ? (
          <DailyForm {...props} />
        ) : (
          <WeeklyForm {...props} />
        )}
        <BackdateSelector {...props} />
      </Grid>
    </Grid>
  );
};

ScheduledForm.propTypes = {
  scheduleParams: PropTypes.shape({
    scheduleType: PropTypes.string
  }),
  handleScheduleTypeChanged: PropTypes.func
};

export default ScheduledForm;
