import { branch } from "react-recompose";
import withThingObservations from "../../observation_hocs/with_thing_observations";
import withThingObservationsAggregation, {
  widgetIsAggregated
} from "../../observation_hocs/with_thing_observations_aggregation";
import withThingObservationsRealtime, {
  widgetIsRealtime
} from "../../observation_hocs/with_thing_observations_realtime";
import TimeseriesWidget from "./timeseries_widget";

export default branch(
  widgetIsRealtime,
  withThingObservationsRealtime,
  branch(
    widgetIsAggregated,
    withThingObservationsAggregation,
    withThingObservations
  )
)(TimeseriesWidget);
