import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import * as paging from "ducks/paging";
import * as thingsList from "./things_list";
import { setNotificationMessage } from "../system";
import responseToThings from "./adapters/response_things";
import responseToPaging from "./adapters/response_paging";
import { path } from "ramda";

const buildListQuery = (pagingQuery, page, sort, sortFieldType) => ({
  ...pagingQuery,
  ...(page === undefined ? {} : { page }),
  ...(sort === undefined ? {} : { sort }),
  ...(sortFieldType === undefined ? {} : { sortFieldType })
});

export const getThingsListForTypeEpic = (
  action$,
  store,
  { api, pagingSelectors }
) =>
  action$
    .ofType(thingsList.THINGS_LIST_REQUEST)
    .switchMap(
      ({
        payload: { thingType, filter, widgetId, page, sort, sortFieldType }
      }) =>
        Observable.merge(
          Observable.of(paging.setLoading({ widgetId })),
          api.things
            .find$({
              query: buildListQuery(
                pagingSelectors.getPagingQuery(store.getState(), widgetId),
                page,
                sort,
                sortFieldType
              ),
              thingType,
              filter
            })
            .mergeMap(response =>
              Observable.of(
                thingsList.thingsListSuccess(responseToThings(response)),
                paging.setPagingData({
                  pagingData: responseToPaging(response),
                  widgetId
                })
              )
            )
            .catch(error =>
              Observable.of(
                thingsList.thingsListFailure(error),
                apiError(error)
              )
            )
        )
    );

export const downloadCertificateEpic = (action$, store, { api, saveAs }) =>
  action$
    .ofType(thingsList.DOWNLOAD_CERTIFICATE_REQUEST)
    .mergeMap(({ payload: { thingName } }) =>
      api.certificate
        .download$({ thingName })
        .map(fileBlob => {
          saveAs(fileBlob, `${thingName}-cert.zip`);
          return thingsList.downloadCertificateSuccess();
        })
        .catch(error =>
          Observable.of(
            thingsList.downloadCertificateFailure(error),
            apiError(error)
          )
        )
    );

export const replaceCertificateEpic = (action$, store, { api }) =>
  action$
    .ofType(thingsList.REPLACE_CERTIFICATE_REQUEST)
    .mergeMap(({ payload: { thingName } }) =>
      api.certificate
        .replace$({ thingName })
        .mergeMap(() =>
          Observable.of(
            thingsList.replaceCertificateSuccessful(),
            setNotificationMessage(
              `Certificate has been replaced for ${thingName}`
            )
          )
        )
        .catch(error =>
          Observable.of(
            thingsList.replaceCertificateFailure(error),
            setNotificationMessage(
              `Error: ${path(["errorMessage", "message"], error)}`
            )
          )
        )
    );
