import { handleActions } from "redux-actions";
import { DashboardViewState } from "./records";

const DASHBOARDS_EDITING = "DASHBOARDS_EDITING";
export const initialState = new DashboardViewState();

export default handleActions(
  {
    // ---
    DASHBOARDS_EDITING: (state, { isMovingWidgets }) =>
      state.merge({
        isMovingWidgets
      })
  },
  initialState
);

export const setIsMovingWidgets = isMovingWidgets => ({
  type: DASHBOARDS_EDITING,
  isMovingWidgets
});

export const selectors = {
  getIsDashboardMovingItsWidgets: state =>
    state.dashboard.getIn(["dashboardViewState", "isMovingWidgets"], false)
};
