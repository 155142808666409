import React from "react";
import PropTypes from "prop-types";
import DownArrowIcon from "@material-ui/icons/ArrowDownward";
import UpArrowIcon from "@material-ui/icons/ArrowUpward";

const SortIcon = ({ type }) => {
  const style = {
    position: "relative",
    top: "1px",
    left: "2px",
    height: "12px",
    width: "13px"
  };

  switch (type) {
    case "UP":
      return <UpArrowIcon data-test="up-arrow-icon" style={style} />;
    case "DOWN":
      return <DownArrowIcon style={style} />;
    case "NONE":
      return <svg height="12px" width="0px" />;
    default:
      return null;
  }
};

SortIcon.propTypes = {
  type: PropTypes.string
};

export default SortIcon;
