import React from "react";
import PropTypes from "prop-types";
import { Responsive, WidthProvider } from "react-grid-layout";
import { pure, shouldUpdate, compose, lifecycle } from "react-recompose";
import { fromJS } from "immutable";
import ThingShadowWidgetsGrid from "../thing_shadow_widget_grid";
import WidgetContainer from "components/widget/widget_container";
import { SpinnerWhileLoading } from "components/notification";
import { equals } from "ramda";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const NoWidgets = () => <h1>No Widgets</h1>;

const _removeResizableHandle = isMovingWidgets => {
  // this is a horrible hack!!
  // basically, the grid layout unmounts and mounts all grid items on a isDraggable, isResizable change.
  // this happens when going from edit to non edit mode ...
  // this hugely degrades performance, hence this hack.
  // we're now always in edit mode but we hide or show the resizable handle.
  const display = isMovingWidgets ? "block" : "none";
  const elements = document.getElementsByClassName("react-resizable-handle");
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = display;
  }
};

const Grid = props => {
  const {
    widgets,
    viewMode,
    layouts,
    gridProps,
    isMovingWidgets,
    dashboardId,
    viewModeId,
    gridId,
    handleOnLayoutChange,
    handleOnBreakpointChange,
    filterId,
    dashboardType,
    dashboardViewMode
  } = props;
  return (
    <ThingShadowWidgetsGrid>
      <ResponsiveReactGridLayout
        className="layout"
        {...{
          ...gridProps.toJS(),
          draggableHandle: isMovingWidgets ? null : ".doesntExist"
        }}
        onLayoutChange={handleOnLayoutChange}
        layouts={layouts}
        onBreakpointChange={handleOnBreakpointChange}
      >
        {widgets.map(widget => (
          <div
            id={widget.widgetId}
            style={{
              zIndex: widget.widgetId === "allThings" ? "2" : "1"
            }}
            key={widget.widgetId}
          >
            <WidgetContainer
              widget={widget}
              viewMode={viewMode}
              filterId={filterId}
              dashboardType={dashboardType}
              dashboardViewMode={dashboardViewMode}
              dashboardId={dashboardId}
              viewModeId={viewModeId}
              gridId={gridId}
              isMovingWidgets={isMovingWidgets}
            />
          </div>
        ))}
      </ResponsiveReactGridLayout>
    </ThingShadowWidgetsGrid>
  );
};

Grid.propTypes = {
  widgets: PropTypes.any,
  layouts: PropTypes.any,
  dashboardType: PropTypes.oneOf(["collection", "detail", "allThings"]),
  gridProps: PropTypes.object,
  handleOnLayoutChange: PropTypes.func,
  handleOnBreakpointChange: PropTypes.func,
  setLayout: PropTypes.func,
  isMovingWidgets: PropTypes.bool,
  viewMode: PropTypes.string,
  filterId: PropTypes.string,
  dashboardId: PropTypes.string,
  viewModeId: PropTypes.string,
  gridId: PropTypes.string,
  dashboardViewMode: PropTypes.object
};

Grid.defaultProps = {
  handleOnBreakpointChange: () => {},
  handleOnLayoutChange: () => {},
  widgets: [],
  layouts: {},
  isMovingWidgets: false,
  gridProps: fromJS({
    className: "layout",
    autosize: true,
    useCSSTransforms: true,
    rowHeight: 100,
    breakpoints: {
      lg: 1200,
      md: 996,
      sm: 768,
      xs: 480,
      xxs: 200
    },
    cols: {
      lg: 12,
      md: 12,
      sm: 12,
      xs: 1,
      xxs: 1
    },
    verticalCompact: true,
    // minH: 100,
    // minW: 100,
    margin: [24, 24],
    isDraggable: true,
    isResizable: true
  })
};

const GridComposed = compose(
  lifecycle({
    componentDidUpdate() {
      _removeResizableHandle(this.props.isMovingWidgets);
    },

    componentDidMount() {
      _removeResizableHandle(this.props.isMovingWidgets);
    }
  }),
  shouldUpdate((props, next) => {
    const widgetsChanged =
      props.widgets && !equals(props.widgets, next.widgets);
    // TODO: this check looks really expensive?
    const layoutsChanged =
      props.layouts && !equals(props.layouts, next.layouts);
    const isMovingWidgetsChanged =
      props.isMovingWidgets !== next.isMovingWidgets;
    const requestChanged = props.request !== next.request;

    return (
      requestChanged ||
      widgetsChanged ||
      layoutsChanged ||
      isMovingWidgetsChanged
    );
  }),
  pure
)(Grid);

export default SpinnerWhileLoading(props => !props.isLoading)(
  SpinnerWhileLoading(
    props => props.layouts !== undefined,
    NoWidgets
  )(GridComposed)
);
