import * as Storage from "api/device/storage";
import { refreshToken$ as fetchRefreshToken$ } from "api/rest/auth";
import * as R from "ramda";
import RxSingletonLock from "rx-singleton-lock";
import { Observable } from "rxjs";
import { nonEmptyString } from "utils/general_utils";
import { setExpiredSession } from "utils/user_session";
import { configureUnauthenticated } from "./cognito_credentials";
import { setCredentials$ } from "./set_credentials";
import { traceErr, traceLog } from "./trace";

export const refreshTokenLock = new RxSingletonLock({ traceErr, traceLog });

export const refreshToken$ = (doRedirect = true) => {
  traceLog("[refreshToken$] refresh started");

  let account;
  try {
    account = Storage.get("account");
  } catch (error) {
    traceErr(
      "[refreshToken$] reading account from local storage failed",
      error
    );
  }

  const hasRefreshToken = R.pathSatisfies(
    nonEmptyString,
    ["credentials", "refreshToken"],
    account
  );

  if (hasRefreshToken) {
    configureUnauthenticated();

    return fetchRefreshToken$(account.credentials.refreshToken)
      .mergeMap(response => {
        traceLog("[refreshToken$] successful");
        return setCredentials$(response);
      })
      .catch(error => {
        traceErr("[refreshToken$] failed to refresh token", error);
        if (doRedirect) {
          doLogout(error);
          return Observable.never();
        } else {
          return Observable.throwError(error);
        }
      });
  } else {
    const error = new Error("Token is expired");
    traceErr(
      "[refreshToken$] failed to locate refresh token from storage",
      error
    );
    if (doRedirect) {
      doLogout(error);
      return Observable.never();
    } else {
      return Observable.throwError(error);
    }
  }
};

const sessionExpired = /Token is expired/;
const doLogout = error => {
  traceLog("[refreshToken$] logging out");
  Storage.remove("account");
  Storage.clearAwsCredentials();

  if (
    (typeof error === "string" && error.match(sessionExpired)) ||
    (error &&
      typeof error.message === "string" &&
      error.message.match(sessionExpired))
  ) {
    const { pathname, search } = window.location;
    setExpiredSession({
      pathname,
      search,
      message: "The login session has expired, please login again."
    });
  }

  const isLogout = window.location.pathname.indexOf("logout") >= 0;
  if (isLogout) {
    window.location.replace("/login");
  } else {
    window.location.replace("/logout");
  }
};
