import { Map } from "immutable";
import { lifecycle } from "react-recompose";
import guid from "easy-guid";
import * as dashboardUtils from "utils/dashboard_utils";
import { flatten } from "utils/general_utils";
import * as R from "ramda";
import diff from "deep-diff";

export const _getLastPosition = widgets => {
  const _biggestXY = widgets => {
    if (!widgets || widgets.size === 0) return;
    const result = widgets.reduce(
      (acc, val) => {
        const y = val.getIn(["layout", "y"]);
        const x = val.getIn(["layout", "x"]);
        if (y > acc.y) {
          acc.y = y;
          acc.biggestY = y;
          acc.x = x;
        }
        if (acc.biggestY === y && x > acc.x) {
          acc.x = x;
        }
        return acc;
      },
      { y: 0, x: 0, biggestY: 0 }
    );
    delete result.biggestY;
    return result;
  };
  return _biggestXY(widgets);
};

export const _getLastPositions = widgets => {
  if (!widgets) return {};
  const _thingPosition = (widgets, acc = {}) => {
    acc.thing = _getLastPosition(widgets.get("thingWidgets"));
    return acc;
  };
  const _subThingPositions = (widgets, acc = { subThings: {} }) => {
    if (widgets.has("subThings")) {
      acc.subThings = widgets.get("subThings").reduce((mem, val, key) => {
        mem[key] = _getLastPosition(val.get("thingWidgets"));
        return mem;
      }, {});
    }
    return acc;
  };
  const acc = _subThingPositions(widgets, _thingPosition(widgets));
  return acc;
};

export const initWidget = (resource, position) => {
  const id = guid.new(16);
  const layout = {
    x: position.x,
    y: position.y,
    w: 4,
    h: 3,
    i: id,
    moved: false
  };
  const resourceName = resource.get("id");
  let widget = new Map({
    resourceName,
    id,
    type: "Value",
    layout: new Map(layout),
    label: resource.getIn(["metadata", "label"]) || resource.get("name"),
    unit: ""
  });
  if (resourceName.indexOf("latlng") > -1) {
    widget = widget.set("type", "Map");
  }
  return widget;
};

const _isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export const _updatePositions = (positions, key) => {
  if (_isNullOrEmpty(positions)) {
    positions = { thing: { y: 0, x: 0 }, subThings: {} };
    if (key && key.length === 2) {
      positions.subThings[key[1]] = { y: 0, x: 0 };
    }
    return { positions, position: positions.thing };
  }
  if (!positions.subThings) positions.subThings = {};
  let position;
  if (key.indexOf("subThings") > -1) {
    position = positions.subThings[key[1]];
  } else {
    position = positions.thing;
  }
  if (_isNullOrEmpty(position)) {
    position = { y: 0, x: 0 };
  } else if (position.x + 4 > 8) {
    position.y += 3;
    position.x = 0;
  } else {
    position.x += 4;
  }

  if (key.indexOf("subThings") > -1) {
    positions.subThings[key[1]] = position;
  } else {
    positions.thing = position;
  }
  return { positions, position };
};

const sortOn = property => resources => {
  return resources
    ? resources.sort((a, b) =>
        a.get(property, "").toLowerCase() > b.get(property, "").toLowerCase()
          ? 1
          : -1
      )
    : resources;
};

export const flattenWidgetResourceIds = widgets => {
  return widgets
    ? flatten(
        widgets.get ? widgets.toJS() : widgets,
        (node, key, val, nodeKey, path, acc) => {
          if (key === "resourceName") {
            return [...acc, val];
          } else {
            return acc;
          }
        },
        []
      )
    : [];
};

export const widgetResourceDiff = (viewMode, resources) => {
  const widgetResourceIds = flattenWidgetResourceIds(viewMode);
  return resources.filter((val, key) => {
    return widgetResourceIds.indexOf(key) === -1;
  });
};

export const initWidgetsFromThingTypeResources = (resources, oldWidgets) => {
  if (!resources) return Map({});
  let positions = _getLastPositions(oldWidgets);
  const oldResources = flattenWidgetResourceIds(oldWidgets);
  resources = sortOn("id")(resources);
  return resources.reduce((mem, resource, key) => {
    if (oldResources && oldResources.indexOf(key) > -1) return mem;
    if (key.indexOf("subthing") === -1) {
      const ps = _updatePositions(positions, ["thing"]);
      const position = ps.position;
      positions = ps.positions;
      const widget = initWidget(resource, position);
      mem = mem.setIn(
        ["thingWidgets", dashboardUtils.widgetId(widget)],
        widget
      );
    } else {
      const path = key.split("/");
      const subThingType = path && path.length > 3 ? path[2] : "untyped";
      const ps = _updatePositions(positions, ["subThings", subThingType]);
      const position = ps.position;
      positions = ps.positions;
      const widget = initWidget(resource, position);
      mem = mem.setIn(
        [
          "subThings",
          subThingType,
          "thingWidgets",
          dashboardUtils.widgetId(widget)
        ],
        widget
      );
    }
    return mem;
  }, Map({}));
};

export const getWidgetId = widgets => {
  let id = 0;
  if (widgets) {
    // get the id
    let largest = 0;
    widgets.forEach(widget => {
      let wid = dashboardUtils.widgetId(widget);
      wid = parseInt(wid.replace("_", ""), 10);
      if (wid > largest) {
        largest = wid;
      }
    });
    id = largest + 1;
  }
  return id;
};

export const shiftWidgets = widgets => {
  if (!widgets) return widgets;
  return widgets.map(widget =>
    widget.setIn(["layout", "y"], widget.getIn(["layout", "y"]) + 3)
  );
};

export const setUid = (currentThingType, currentViewMode) => {
  const viewModeLabel = currentViewMode.get("label");
  let viewMode = currentThingType
    .getIn(["viewModes"])
    .find(viewMode => viewMode.get("label") === viewModeLabel);

  if (viewMode === undefined) {
    const viewModeId = `vm_${guid.new(16)}`;
    viewMode = currentViewMode
      .set("timestamp", Date.now())
      .set("id", viewModeId);
  } else {
    viewMode = viewMode.set(
      "thingWidgets",
      currentViewMode.get("thingWidgets")
    );
    viewMode = viewMode.set("subThings", currentViewMode.get("subThings"));
    viewMode = viewMode.set(
      "collectionWidgets",
      currentViewMode.get("collectionWidgets")
    );
    if (currentViewMode.has("userSaved"))
      viewMode = viewMode.set("userSaved", currentViewMode.get("userSaved"));
  }
  return viewMode;
};

export const thingTypeHasChanges = (thingTypeState, currentThingTypeId) => {
  if (!thingTypeState) return false;
  // compare details widgets
  // compare collection widgets
  // only do this check if the thingType is the same as the editing thing type
  const editingThingType = thingTypeState.get("editingThingType", Map());

  if (!editingThingType || editingThingType.get("id") !== currentThingTypeId)
    return false;
  const viewModes = thingTypeState.getIn(
    ["editingThingType", "viewModes"],
    Map()
  );
  const originalViewModes = thingTypeState.getIn(
    ["types", currentThingTypeId, "viewModes"],
    Map()
  );
  const originalThingType = thingTypeState.getIn(
    ["types", currentThingTypeId],
    Map()
  );

  const omitDeep = R.curry((keys, obj) =>
    R.when(R.is(Object), R.pipe(R.omit(keys), R.map(omitDeep(keys))))(obj)
  );

  // remove fluff that grid layout puts on the widgets
  if (!editingThingType || !originalThingType) return false;

  const ommittedOriginalViewModes = omitDeep(
    ["moved"],
    originalViewModes.toJS()
  );
  const ommittedViewModes = omitDeep(["moved"], viewModes.toJS());
  const viewModesDiffed = diff.diff(
    ommittedOriginalViewModes,
    ommittedViewModes
  );

  return (
    editingThingType.get("id") === originalThingType.get("id") &&
    viewModesDiffed !== undefined
  );
};

export function getHomeUrl(thingTypes) {
  let link = "/things/DefaultView";
  if (thingTypes && thingTypes.size > 0) {
    const type = thingTypes.first();
    if (type) {
      link = `/things/${type.get("viewMode")}/${type.get("id")}/list`;
    }
  }
  return link;
}

export const shouldSetViewMode = (prevProps, props) =>
  prevProps.params.viewMode !== props.params.viewMode ||
  prevProps.params.thingType !== props.params.thingType;

export const withSetThingtypeViewMode = lifecycle({
  componentDidMount() {
    this.props.setViewMode(
      this.props.params.thingType,
      this.props.params.viewMode
    );
  },
  componentDidUpdate(prevProps) {
    if (shouldSetViewMode(prevProps, this.props)) {
      this.props.setViewMode(
        this.props.params.thingType,
        this.props.params.viewMode
      );
    }
  }
});
