import { invoke$ } from "../invoke";
import { Map } from "immutable";
import { adaptThingShadow } from "adapters/thing_adapter_normalized";
import { pathOr, pipe, pluck } from "ramda";
import * as utils from "utils/invoke_utils";
import {
  adaptAggregation,
  adaptGeoAggregation
} from "adapters/aggregation_adapter";
import * as elasticThings from "api/elastic/things";
import { unImmute } from "utils/immutable_utils";
import { esVersion } from "api/aws/manifest";

export const find$ = ({ query, thingType, filter }) => {
  const action = {
    action: "FIND",
    query: elasticThings.findThings(
      {
        thingType,
        query: unImmute(query),
        filter: unImmute(filter)
      },
      esVersion
    )
  };

  return invoke$("ThingLambda", action).map(res => ({
    total: res.hits.total,
    things: res.hits.hits.map(t => t._source),
    query
  }));
};

export const findIds$ = ({ thingType, filter }) => {
  const action = {
    action: "FIND",
    query: elasticThings.findIds(
      {
        thingType,
        filter: unImmute(filter)
      },
      esVersion
    )
  };

  return invoke$("ThingLambda", action).map(
    pipe(pathOr([], ["hits", "hits"]), pluck("_id"))
  );
};

export const findAggregations$ = ({
  period,
  thingType,
  aggregationParams,
  aggregationType,
  resourceName,
  filter = Map()
}) => {
  const action = {
    action: "FIND",
    query: elasticThings.findAggregations(
      {
        period: unImmute(period),
        thingType,
        aggregation: {
          type: aggregationType,
          field: resourceName
        },
        aggregationParams,
        resourceName,
        filter: unImmute(filter)
      },
      esVersion
    )
  };
  return invoke$("ThingLambda", action).map(res => ({
    aggregations: adaptAggregation(res.aggregations)
  }));
};

export const findAggregationsForGeo$ = ({
  thingType,
  precision = 1,
  filter
}) => {
  const action = {
    action: "FIND",
    query: elasticThings.findAggregationsForGeo(
      {
        thingType,
        precision,
        filter: unImmute(filter)
      },
      esVersion
    )
  };

  return invoke$("ThingLambda", action).map(res => ({
    aggregations: adaptGeoAggregation(res)
  }));
};

export const thingTypeRemoveAttribute$ = (id, keyPath) =>
  invoke$("ThingTypeLambda", {
    action: "REMOVE_ATTRIBUTE",
    attributes: { id, keyPath }
  });

export const thingTypeUpdateItem$ = (id, keyPath, item) => {
  const action = {
    action: "UPDATE_ATTRIBUTE",
    attributes: { id, keyPath, item }
  };
  return invoke$("ThingTypeLambda", action);
};

export const updateViewMode$ = (thingTypeId, item) =>
  thingTypeUpdateItem$(
    thingTypeId,
    `viewModes.${item.id}`,
    utils.removeEmptyStrings(item)
  );

export const fetchThingDetails$ = query => {
  return invoke$("ThingLambda", {
    action: "GET",
    attributes: {
      ...query,
      createdAt: null,
      createdBy: null,
      label: null,
      description: null,
      thingType: null,
      domain: null,
      batchId: null,
      shadow: null,
      domainTopic: null,
      parentThingName: null,
      hasNetworkedThings: null
    }
  }).map(res => adaptThingShadow(res));
};

export const create$ = thing => {
  const action = {
    action: "CREATE",
    attributes: thing
  };
  return invoke$("ThingLambda", action);
};

export const update$ = thing => {
  const action = {
    action: "UPDATE",
    attributes: thing
  };
  return invoke$("ThingLambda", action);
};

export const remove$ = ({ thingName, subThingName }) => {
  const action = {
    action: "REMOVE",
    attributes: { thingName, subThingName }
  };
  return invoke$("ThingLambda", action);
};

export const createVirtualResource$ = (
  { thingType, keypath, defaultValue },
  { invoker = invoke$ } = {}
) =>
  invoker("ThingTypeLambda", {
    action: "UPDATE_ATTRIBUTE",
    attributes: {
      id: thingType,
      keyPath: keypath,
      item: defaultValue
    }
  });

export const findThingTypeIdsByThingNames$ = thingNames =>
  invoke$("ThingLambda", {
    action: "FIND",
    query: elasticThings.findThingTypeIdsByThingNames({ thingNames }, esVersion)
  });

export const findSubThingsRelation$ = ({ parentThingName }) =>
  invoke$("ThingLambda", {
    action: "FIND",
    query: elasticThings.findSubThingsRelation({ parentThingName }, esVersion)
  });
