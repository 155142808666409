import styled from "styled-components";

export default styled.div`
  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  text-align: center;
  white-space: nowrap;
  ${props =>
    props.inPopover &&
    `
    float: right;
    margin-top: 15px;
  `};
`;
