import { Observable } from "rxjs";
import * as R from "ramda";
import { loadSchemasFailure, loadSchemasSuccess } from "../schema";
import {
  SUGGEST_RESOURCES_REQUEST,
  SUGGEST_THINGS_REQUEST,
  SUGGEST_THING_TYPES_REQUEST,
  SUGGEST_DOMAINS_REQUEST,
  SUGGEST_USERS_REQUEST,
  fetchResourcesSearchSuccess,
  fetchDomainsSuccess,
  fetchThingsSuccess,
  fetchThingTypesSuccess,
  fetchUsersSuccess,
  resourcesByWildcardSelector,
  adjustResourceSuggestions,
  domainsByWildcardSelector,
  thingTypesByWildcardSelector,
  thingsByWildcardSelector,
  usersByWildcardSelector
} from "ducks/suggester";

import { apiError } from "ducks/errors";
import {
  ALL_THING_TYPES,
  DOMAIN_TREE,
  SUGGEST_USERS_QUERY
} from "../../graphql/queries";

export const suggesterResourcesEpic = (action$, store, { api }) =>
  action$
    .ofType(SUGGEST_RESOURCES_REQUEST)
    .debounceTime(500)
    .mergeMap(action => {
      const suggesterOptionsDecorator = R.path(
        ["payload", "suggesterOptionsDecorator"],
        action
      );
      const localResources = resourcesByWildcardSelector(
        store.getState(),
        R.path(["payload", "input", "value"], action),
        R.path(["payload", "thingType"], action)
      );

      return localResources.length > 0
        ? Observable.of(
            fetchResourcesSearchSuccess({
              resources: resourcesByWildcardSelector(
                store.getState(),
                R.path(["payload", "input", "value"], action),
                R.path(["payload", "thingType"], action)
              ),
              suggesterOptionsDecorator,
              searchLambda: false
            })
          ).catch(error => {
            // Observable.of(fetchSuggestionsError(error), apiError(error))
            // console.log("Fallback locally");
            return Observable.of(
              fetchResourcesSearchSuccess({
                resources: resourcesByWildcardSelector(
                  store.getState(),
                  R.path(["payload", "input", "value"], action),
                  R.path(["payload", "thingType"], action)
                ),
                suggesterOptionsDecorator
              }),
              apiError(error)
            );
          })
        : api.resources
            .list$()
            .mergeMap(response => {
              const resources = adjustResourceSuggestions(
                response.entities.resources,
                R.path(["payload", "input", "value"], action),
                R.path(["payload", "thingType"], action)
              );

              return Observable.of(
                loadSchemasSuccess(response),
                fetchResourcesSearchSuccess({
                  resources,
                  suggesterOptionsDecorator,
                  searchLambda: false
                })
              );
            })
            .catch(error => {
              // Observable.of(fetchSuggestionsError(error), apiError(error))
              // console.log("Fallback locally");
              return Observable.of(
                fetchResourcesSearchSuccess({
                  resources: resourcesByWildcardSelector(
                    store.getState(),
                    R.path(["payload", "input", "value"], action),
                    R.path(["payload", "thingType"], action)
                  ),
                  suggesterOptionsDecorator
                }),
                loadSchemasFailure(error),
                apiError(error)
              );
            });
    });

export const suggesterDomainsEpic = (action$, store, { getApolloClient }) =>
  action$
    .ofType(SUGGEST_DOMAINS_REQUEST)
    .debounceTime(500)
    .mergeMap(action =>
      getApolloClient()
        .query({ query: DOMAIN_TREE })
        .then(queryResult =>
          fetchDomainsSuccess({
            domains: domainsByWildcardSelector(
              R.path(["data", "domainTree", "tree"], queryResult),
              R.path(["payload", "input", "value"], action)
            )
          })
        )
    );

export const suggesterThingTypesEpic = (action$, store, { getApolloClient }) =>
  action$
    .ofType(SUGGEST_THING_TYPES_REQUEST)
    .debounceTime(500)
    .mergeMap(action =>
      getApolloClient()
        .query({ query: ALL_THING_TYPES })
        .then(queryResult =>
          fetchThingTypesSuccess({
            thingTypes: thingTypesByWildcardSelector(
              R.path(["data", "allThingTypes", "thingTypes"], queryResult),
              R.path(["payload", "input", "value"], action)
            )
          })
        )
    );

export const suggesterUsersEpic = (action$, store, { getApolloClient }) =>
  action$
    .ofType(SUGGEST_USERS_REQUEST)
    .debounceTime(500)
    .mergeMap(action =>
      getApolloClient()
        .query({
          variables: {
            freeText: R.path(["payload", "input", "value"], action)
          },
          query: SUGGEST_USERS_QUERY
        })
        .then(queryResult =>
          fetchUsersSuccess({
            users: usersByWildcardSelector(
              R.path(["data", "allUsers", "users"], queryResult),
              R.path(["payload", "input", "value"], action)
            )
          })
        )
    );

export const suggesterThingsEpic = (action$, store) =>
  action$
    .ofType(SUGGEST_THINGS_REQUEST)
    .debounceTime(500)
    .mergeMap(action => {
      return Observable.of(
        fetchThingsSuccess({
          things: thingsByWildcardSelector(store.getState(), {
            thingType: R.path(["payload", "thingType"], action),
            thingLabel: R.path(["payload", "input", "value"], action)
          })
        })
      );
    });
