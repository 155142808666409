import Card from "@material-ui/core/Card";
import styledMui from "utils/styled_mui";

export default styledMui(Card, "muiTheme", "isExpandable", "widgetType")`
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  ${props =>
    props.isExpandable &&
    `border-left: 4px solid ${props.muiTheme.palette.primary.main};`}}
`;
