import { widgetId } from "utils/dashboard_utils";
import MapWidget from "./map_widget_container";
import getWidgetBase from "../get_widget_base";

export const getWidget = props => {
  return getWidgetBase({
    ...props,
    includeFooter: false,
    mapId: `map-${widgetId(props.widget)}`,
    widgetComp: MapWidget
  });
};
