import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import {
  USERS_GET_USERDATA_REQUEST,
  getUserDataFailure,
  getUserDataSuccess
} from "./users";

export const getUserDataEpic = (action$, store, { api }) =>
  action$.ofType(USERS_GET_USERDATA_REQUEST).switchMap(action =>
    api.users
      .getUserData$()
      .map(response => getUserDataSuccess(response))
      .catch(error => Observable.of(getUserDataFailure(error), apiError(error)))
  );
