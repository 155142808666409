import React from "react";
import {
  branch,
  renderComponent,
  compose,
  pure,
  shouldUpdate
} from "react-recompose";
import Loading from "./loading";

const identity = t => t;

// `hasLoaded()` is a function that returns whether or not the the component
// has all the props it needs
export default (hasLoaded, WaitingComp = Loading) =>
  branch(
    hasLoaded,
    identity, // Component => Component
    renderComponent(WaitingComp) // <WaitingComp> is a React component that defaults to Loading
  );

export const spinnerWhileLoading = (isLoading, style) =>
  branch(
    isLoading,
    renderComponent(
      compose(
        shouldUpdate(() => false),
        pure
      )(() => {
        return (
          <div style={{ ...style }}>
            <Loading />
          </div>
        );
      })
    )
  );
