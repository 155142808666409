import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import FormIconButton from "components/buttons/form_icon";
import Warning from "components/warning";
import AutoComplete, { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import { insert, lensPath, lensProp, over, remove, set } from "ramda";
import React from "react";
import { compose, withHandlers } from "react-recompose";
import ColorPicker from "./color_picker";

export const ColorThresholdWithOperator = compose(
  withHandlers({
    onThresholdInput: ({ onChange, value }) => id => event => {
      onChange(
        set(
          lensPath(["markerColors", id, "threshold"]),
          event.target.value,
          value
        )
      );
    },
    onColorSelected: ({ onChange, value }) => id => color => {
      onChange(set(lensPath(["markerColors", id, "color"]), color, value));
    },
    onResourceSelected: ({ onChange, value }) => selectedResourceId => {
      onChange(set(lensProp("resourceId"), selectedResourceId, value));
    },
    onOperatorSelected: ({ onChange, value }) => id => event => {
      onChange(
        set(
          lensPath(["markerColors", id, "operator"]),
          event.target.value,
          value
        )
      );
    },
    onRemoveRow: ({ value, onChange }) => id => () => {
      onChange(over(lensProp("markerColors"), remove(id, 1), value));
    },
    onAddRow: ({ value, onChange }) => () => {
      onChange(
        set(
          lensProp("markerColors"),
          insert(
            value.markerColors.length,
            { threshold: "0", color: "#1F6E1F", operator: "gt" },
            value.markerColors
          ),
          value
        )
      );
    }
  }),
  withStyles(theme => ({
    addIcon: {
      marginRight: theme.spacing(1)
    },
    removeIconButton: {
      padding: theme.spacing(1)
    },
    conditionRow: {
      alignItems: "center",
      display: "flex"
    },
    operatorField: {
      margin: theme.spacing(1),
      width: 250
    },
    thresholdField: {
      margin: theme.spacing(1),
      width: 100
    },
    addIconButton: {
      marginTop: theme.spacing(1.5)
    },
    wrapper: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    warningCell: {
      backgroundColor: amber[50],
      paddingTop: 12,
      paddingBottom: 12,
      display: "flex"
    },
    warningCellText: {
      margin: "2px"
    },
    warningCellIcon: {
      margin: "0px 8px"
    }
  }))
)(
  ({
    classes,
    value,
    onThresholdInput,
    onColorSelected,
    onRemoveRow,
    onAddRow,
    onOperatorSelected,
    operators,
    onResourceSelected
  }) => {
    return (
      <div className={classes.wrapper}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.header}
        >
          Conditional marker colors
        </Typography>
        {value && value.markerColors && value.markerColors.length > 0 && (
          <React.Fragment>
            <AutoComplete
              type={DATA_TYPES.RESOURCES}
              defaultValue={value.resourceId}
              label="Condition resource"
              changeHandler={(_id, _value) => onResourceSelected(_value)}
              errorText=""
            />
            <Warning>The topmost matching condition will be applied</Warning>
            <div className={classes.conditionList}>
              {value.markerColors.map((colorThreshold, conditionIndex) => {
                return (
                  <div
                    className={classes.conditionRow}
                    key={`ct_${conditionIndex}`}
                  >
                    <IconButton
                      className={classes.removeIconButton}
                      onClick={onRemoveRow(conditionIndex)}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                    <div className={classes.inputFields}>
                      <Select
                        value={
                          colorThreshold.operator || Object.keys(operators)[0]
                        }
                        onChange={onOperatorSelected(conditionIndex)}
                        className={classes.operatorField}
                      >
                        {Object.entries(operators).map(operator => (
                          <MenuItem key={operator[0]} value={operator[0]}>
                            {operator[1]}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        className={classes.thresholdField}
                        value={colorThreshold.threshold}
                        placeholder="Threshold"
                        onChange={onThresholdInput(conditionIndex)}
                      />
                    </div>
                    <ColorPicker
                      color={colorThreshold.color}
                      onChange={onColorSelected(conditionIndex)}
                    />
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
        <FormIconButton
          className={classes.addIconButton}
          text="Add condition"
          onClick={onAddRow}
          icon={<AddCircleOutlineIcon className={classes.addIcon} />}
        />
      </div>
    );
  }
);
