import styled from "styled-components";
import * as colors from "themes/colors";

export default styled.input`
  text-align: center;
  height: 30px;
  width: 35px;
  margin-left: 12px;
  margin-right: 12px;
  border-style: none;
  border-bottom: solid 1px ${colors.telenorTextColorAlpha50};
  font-family: telenor-regular;
  position: relative;
  top: -2px;
  outline: none;
`;
