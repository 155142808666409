import * as colors from "themes/colors";

export const getStyles = ({
  palette: {
    primary: { main }
  }
}) => ({
  container: {
    display: "inline-block"
  },
  openModalButton: {
    height: 48
  },
  trendingFlatIcon: {
    position: "relative",
    top: 5,
    marginLeft: 5,
    marginRight: 5
  },
  dateRangeIcon: {
    width: 24,
    height: 24,
    top: 5,
    left: 20,
    position: "relative"
  },
  dialogTitle: {
    borderTop: `4px solid ${main}`,
    paddingTop: 15,
    paddingBottom: 20,
    fontSize: "18px"
  },
  dialogBody: {
    marginLeft: 34,
    overflowY: "auto"
  },
  titleLable: {
    paddingLeft: 30
  },
  verticalDivider: {
    display: "inline-block",
    borderLeft: `1px solid ${colors.telenorTextColorAlpha50}`,
    position: "absolute",
    top: "10%",
    bottom: "76px"
  }
});
