import { Observable } from "rxjs";

export default file =>
  Observable.create(subscriber => {
    const reader = new FileReader();
    let isPending = true;

    const onError = error => {
      isPending = false;
      subscriber.error({
        errorMessage: "Bad file or invalid content (must be valid JSON)"
      });
    };

    const onLoad = evt => {
      try {
        const json = JSON.parse(evt.target.result);
        subscriber.next(json);
      } catch (err) {
        onError(err);
      }
    };

    const onComplete = () => {
      isPending = false;
      subscriber.complete();
    };

    const onAbort = () => {
      if (isPending) {
        isPending = false;
        onError(new Error("Reader was aborted"));
      }
    };

    if (!(file instanceof Blob)) {
      onError(new Error("`file` must be an instance of File or Blob."));
    } else {
      reader.onerror = onError;
      reader.onload = onLoad;
      reader.onloadend = onComplete;
      reader.onabort = onAbort;
      reader.readAsText(file);
    }

    return function onUnsubscribed() {
      try {
        if (isPending) {
          isPending = false;
          reader.abort();
        }
      } catch (err) {} // eslint-disable-line no-empty
    };
  });
