import * as colors from "themes/colors";

export const getStyles = () => ({
  // eslint-disable-line
  errorText: {
    color: colors.telenorErrorTextColor
  },
  fieldset: {
    overflowY: "hidden"
  },
  link: (styles = {}) => ({
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    color: colors.telenorTextColorAlpha70,
    textDecoration: "none",
    ...styles
  })
});
