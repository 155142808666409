import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import FormCancel from "components/buttons/form_cancel";
import FormIconButton from "components/buttons/form_icon";
import FormSave from "components/buttons/form_save";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import { Field, FieldSet, Form } from "../";
import { generateUserWithDomainSchema } from "./schemas";

const userSchema = generateUserWithDomainSchema();

const roleList = [
  { id: "none", label: "No system role" },
  { id: "Read", label: "Read" },
  { id: "ReadWrite", label: "Read/Write" }
];

const ConsentDiv = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const ConsentStatus = ({
  consentNeeded,
  dateTermsAgreed,
  termsVersion,
  termsAgreed,
  isRevokingConsent,
  onRevokeConsent,
  userCanRevokeConsent
}) => (
  <ConsentDiv hidden={!consentNeeded}>
    <Typography data-test="consent-status">
      Consent or agreement acceptance status:
      <span data-test="consent-status" style={{ marginLeft: "4px" }}>
        {termsAgreed ? "APPROVED" : "PENDING"}
      </span>
    </Typography>
    {userCanRevokeConsent && termsAgreed && (
      <FormIconButton
        data-test="revoke-consent-button"
        onClick={onRevokeConsent}
        disabled={isRevokingConsent}
        text={isRevokingConsent ? "Revoking..." : "Revoke"}
      />
    )}
    <Typography>
      {termsVersion
        ? `${
            !termsAgreed ? "Latest accepted " : ""
          }version: ${termsVersion} (${dateTermsAgreed})`
        : ""}
    </Typography>
  </ConsentDiv>
);

ConsentStatus.propTypes = {
  consentNeeded: PropTypes.bool,
  dateTermsAgreed: PropTypes.object,
  termsVersion: PropTypes.string,
  termsAgreed: PropTypes.bool,
  isRevokingConsent: PropTypes.bool,
  userCanRevokeConsent: PropTypes.bool.isRequired,
  onRevokeConsent: PropTypes.func
};

const UserForm = ({
  isLoading,
  onCancel,
  onResetPassword,
  onRevokeConsent,
  isRevokingConsent,
  resetPasswordState,
  consentNeeded,
  userCanRevokeConsent,
  initialModel,
  userRoles,
  onSubmit
}) => {
  let resetButtonLabel = "Reset user password";
  if (resetPasswordState.isResetting) {
    resetButtonLabel = "Resetting password...";
  } else if (resetPasswordState.error) {
    resetButtonLabel = "Reset failed, try again...";
  } else if (resetPasswordState.success) {
    resetButtonLabel = "Reset succeeded";
  }

  const { termsAgreed, termsVersion, dateTermsAgreed } = initialModel;
  const resetButtonDisabled =
    resetPasswordState.isResetting || resetPasswordState.success;

  const _resetPassword = event => {
    event.preventDefault();
    onResetPassword(initialModel.userName);
  };

  const roles = R.pipe(
    R.pathOr([], ["currentRoles"]),
    R.reject(
      R.pipe(R.prop("id"), id =>
        R.includes(id, ["readrole", "readwriterole", "rootrole"])
      )
    ),
    R.map(role => R.find(R.propEq("id", role.id))(userRoles))
  )(initialModel);

  // Making a copy since we aren't allowed to add new properties to initialModel
  const initialModelCopy = {
    ...initialModel,
    roles
  };

  return (
    <Form
      schema={userSchema}
      onSubmit={onSubmit}
      initialModel={initialModelCopy}
      allowUnknown
    >
      <DialogContent>
        <FieldSet>
          <Field id="userName" label="Username" disabled />
          <Field id="firstName" label="First name" />
          <Field id="lastName" label="Last name" />
          <Field id="email" label="E-mail" disabled />
          <Field
            id="phone"
            label="Phone"
            hint="International format like +46738123456"
          />
          <Field
            id="domainName"
            data-test="domainName"
            label="Domain"
            type="autocomplete"
            suggesterType={DATA_TYPES.DOMAINS}
            hintText="Domain"
          />
          <Field
            id="roleName"
            data-test="roleName"
            label="System role"
            type="select"
            options={roleList}
          />
          <Field
            id="roles"
            dataTest="roles"
            label="Additional roles"
            type="autocomplete"
            suggesterType={DATA_TYPES.ADDITIONAL_ROLES}
            hintText="Additional roles"
            options={userRoles}
          />
          <Field id="company" label="Company" />
          <Field id="address" label="Address" />
          <Field id="zip" label="Zip" />
          <Field id="city" label="City" />
          <Field id="country" label="Country" />
          <Field id="notes1" label="Additional Information 1" />
          <Field id="notes2" label="Additional Information 2" />
          <Field id="notes3" label="Additional Information 3" />

          <ConsentStatus
            consentNeeded={consentNeeded}
            dateTermsAgreed={dateTermsAgreed}
            termsVersion={termsVersion}
            termsAgreed={termsAgreed}
            isRevokingConsent={isRevokingConsent}
            onRevokeConsent={onRevokeConsent}
            userCanRevokeConsent={userCanRevokeConsent}
          />

          <FormIconButton
            onClick={_resetPassword}
            disabled={resetButtonDisabled}
            text={resetButtonLabel}
          />
        </FieldSet>
      </DialogContent>
      <DialogActions>
        <FormCancel isLoading={isLoading} onClick={onCancel} type="button" />
        <FormSave isLoading={isLoading} />
      </DialogActions>
    </Form>
  );
};

UserForm.propTypes = {
  editRole: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  consentNeeded: PropTypes.bool.isRequired,
  isRevokingConsent: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onRevokeConsent: PropTypes.func.isRequired,
  resetPasswordState: PropTypes.object.isRequired,
  muiTheme: PropTypes.object,
  userSchema: PropTypes.object,
  userCanEditUsers: PropTypes.bool,
  initialModel: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userCanRevokeConsent: PropTypes.bool.isRequired,
  userRoles: PropTypes.array.isRequired
};

export default UserForm;
