import Default from "./circle";
import GenerateValue from "./generate";
import * as Validators from "../validators";

const GeoPoint = Default({
  lat: Validators.Lat,
  lon: Validators.Lon,
  radius: Validators.chain(Validators.Float, Validators.Min(0, false))
});
export { Default, GeoPoint, GenerateValue };
