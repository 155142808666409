import React from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "../styles";
import { onChange } from "../utils";
import { TYPE_FLOAT } from "../types";
const Static = validator =>
  withStyles(
    ({
      config,
      index,
      onStrategyParameterChange,
      disabled,
      errors,
      classes
    }) => {
      return (
        <React.Fragment>
          <TextField
            data-test="lat"
            type={"number"}
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat",
              validator
            )}
            value={config.strategyParameters.lat}
            label="Lat"
            className={classes.textField}
            error={!!errors.lat}
            helperText={errors.lat}
          />
          <TextField
            data-test="lon"
            type={"number"}
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon",
              validator
            )}
            value={config.strategyParameters.lon}
            label="Lon"
            className={classes.textField}
            error={!!errors.lon}
            helperText={errors.lon}
          />
        </React.Fragment>
      );
    }
  );

export default Static;
