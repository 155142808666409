import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";
import UploadFile from "./upload_file";
import { compose, withHandlers, withProps, withState } from "react-recompose";
import {
  getAttributesFromScopeAndSelected,
  getCurrentScopeObj
} from "ducks/bucket_selectors";
import { upload as uploadFile } from "ducks/bucket";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withModalHandlersAndBranch } from "../../hocs/modal_hocs";
export const _withFileState = withState("selectedFile", "onSelectFile", null);

export const _withHandlers = withHandlers({
  onUploadFile: ({
    uploadFile,
    selectedFile,
    closeModalUploadFile,
    data: { refetch },
    scope,
    selected,
    widgetId
  }) => () => {
    const attributes = {
      file: selectedFile,
      fileName: selectedFile.name,
      refetch,
      widgetId
    };
    uploadFile(getAttributesFromScopeAndSelected(scope, selected)(attributes));
    closeModalUploadFile();
  }
});

export const UploadFileDialog = props => {
  return (
    <Dialog modal open fullWidth>
      <DialogContent>
        <UploadFile {...props}>
          <FormSaveButton
            variant="contained"
            text={`Upload file`}
            color="primary"
            style={{ margin: "20px" }}
            onClick={() => props.onUploadFile()}
          />
        </UploadFile>
      </DialogContent>
      <DialogActions>
        <FormCancelButton
          key={"cancelButton"}
          color="primary"
          primary
          onClick={() => props.closeModalUploadFile()}
        />
      </DialogActions>
    </Dialog>
  );
};

UploadFileDialog.propTypes = {
  onUploadFile: PropTypes.func,
  closeModalUploadFile: PropTypes.func
};

export const _mapDispatchToProps = {
  uploadFile
};

const UploadFileModal = compose(
  withProps(({ modalProps: { widgetId } }) => ({ widgetId })),
  connect(getCurrentScopeObj, _mapDispatchToProps),
  _withFileState,
  _withHandlers
)(UploadFileDialog);

export const uploadFileModalHoc = widgetId =>
  withModalHandlersAndBranch({
    name: "UploadFile",
    modal: UploadFileModal,
    props: props => ({
      widgetId: typeof widgetId === "function" ? widgetId(props) : widgetId
    })
  });
