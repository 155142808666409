import { combineReducers } from "redux-immutable";
import { handleActions } from "redux-actions";
import datapicker from "./datapicker";
import * as analyzerSelectors from "./selectors";
import analyses, {
  FETCH_AGGREGATED_OBSERVATIONS_FAILURE,
  FETCH_AGGREGATED_OBSERVATIONS_REQUEST,
  FETCH_AGGREGATED_OBSERVATIONS_SUCCESS,
  FETCH_RAW_OBSERVATIONS_FAILURE,
  FETCH_RAW_OBSERVATIONS_REQUEST,
  FETCH_RAW_OBSERVATIONS_SUCCESS,
  INIT_ANALYZER_FAILURE,
  INIT_ANALYZER_REQUEST,
  INIT_ANALYZER_SUCCESS
} from "./analyses";

export * from "./analyses";
export { analyzerSelectors };

const isLoading = handleActions(
  {
    [INIT_ANALYZER_REQUEST]: () => true,
    [INIT_ANALYZER_FAILURE]: () => false,
    [INIT_ANALYZER_SUCCESS]: () => false
  },
  true
);

const isFetchingObservations = handleActions(
  {
    [FETCH_AGGREGATED_OBSERVATIONS_REQUEST]: () => true,
    [FETCH_AGGREGATED_OBSERVATIONS_SUCCESS]: () => false,
    [FETCH_AGGREGATED_OBSERVATIONS_FAILURE]: () => false,
    [FETCH_RAW_OBSERVATIONS_REQUEST]: () => true,
    [FETCH_RAW_OBSERVATIONS_SUCCESS]: () => false,
    [FETCH_RAW_OBSERVATIONS_FAILURE]: () => false
  },
  false
);

export default combineReducers({
  isLoading,
  isFetchingObservations,
  analyses,
  datapicker
});
