import TableWidget, {
  TABLE_TIMESTAMP_FORMAT
} from "../table_widget/table_widget";
import { compose, withProps } from "react-recompose";
import withFilteredObservationAggregations from "../../observation_hocs/with_filtered_observation_aggregations";

export default compose(
  withProps({ timestampFormat: TABLE_TIMESTAMP_FORMAT }),
  withFilteredObservationAggregations
)(TableWidget);
