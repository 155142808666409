import { createSelector } from "reselect";
import * as R from "ramda";
import * as imu from "utils/immutable_utils";
import { initialEntityState } from "./paging";
import { getLimits } from "utils/pagination_utils";

const getPagingEntities = state => state.paging.entities;

const getWidgetIdFromProps = (state, props) =>
  props.widgetId || imu.get(props.widget, "id");

const getWidgetPagingData = createSelector(
  getPagingEntities,
  getWidgetIdFromProps,
  (entities, widgetId) =>
    R.pathOr(initialEntityState.pagingData, [widgetId, "pagingData"], entities)
);

export const getWidgetPaginationStatus = createSelector(
  getWidgetPagingData,
  pagingData =>
    R.merge(
      R.pickAll(
        ["page", "pages", "pageSize", "sort", "sortFieldType", "totalCount"],
        pagingData
      ),
      getLimits(pagingData)
    )
);

export const getPagingQuery = createSelector(
  getPagingEntities,
  (state, widgetId) => widgetId,
  (entities, widgetId) =>
    R.pipe(
      R.pathOr(initialEntityState.pagingData, [widgetId, "pagingData"]),
      ({ sort, sortFieldType, page, pageSize }) => ({
        page,
        sort,
        sortFieldType,
        size: pageSize
      })
    )(entities)
);

export const getWidgetIsLoading = createSelector(
  getPagingEntities,
  getWidgetIdFromProps,
  (entities, widgetId) =>
    R.pathOr(false, [widgetId, "request", "isLoading"], entities)
);

export const getWidgetThingIds = createSelector(
  getWidgetPagingData,
  pagingData => R.pathOr([], ["things"], pagingData)
);

export const calculateWidgetPageAfterDeletion = createSelector(
  getWidgetPaginationStatus,
  ({ end, start, page }) => Math.max(1, end === start ? page - 1 : page)
);
