import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withPaletteColor } from "utils/theme_utils";

const OfflineBolt = props => (
  <SvgIcon {...props} color="primary">
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2.02c-5.51 0-9.98 4.47-9.98 9.98s4.47 9.98 9.98 9.98 9.98-4.47 9.98-9.98S17.51 2.02 12 2.02zM11.48 20v-6.26H8L13 4v6.26h3.35L11.48 20z" />
    </g>
  </SvgIcon>
);

export default withPaletteColor("main")(OfflineBolt);
