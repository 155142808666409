import PropTypes from "prop-types";
import ProcessWidgetEditor from "./process_widget_editor";
import ProcessWidgetContainer from "./process_widget_container";
import { compose } from "react-recompose";

const WidgetEditProcess = compose(ProcessWidgetContainer)(ProcessWidgetEditor);

WidgetEditProcess.propTypes = {
  template: PropTypes.string,
  handleUpdateCustomProps: PropTypes.func,
  thingName: PropTypes.string,
  params: PropTypes.object,
  editingWidget: PropTypes.object
};

export default () => ({
  fields: [WidgetEditProcess]
});
