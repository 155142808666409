import * as R from "ramda";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  compose,
  setDisplayName,
  withStateHandlers,
  withHandlers,
  lifecycle,
  withProps,
  withPropsOnChange
} from "react-recompose";

import {
  SUGGEST_RESOURCES,
  fetchSuggestionsByWildcard,
  suggestionsSelector
} from "ducks/suggester";

import { tablePickerStyle, modalStyle } from "./styles";
import { withRouter } from "react-router";
import Suggester from "./suggester";

import * as su from "../../utils/suggester_utils";

// ---- props
const _shouldAlwaysRenderSuggestions = type => {
  switch (type) {
    case SUGGEST_RESOURCES:
      return true;
    default:
      return false;
  }
};

export const _withShouldRenderSuggestions = withProps(
  ({ shouldAlwaysRenderSuggestions, type }) => {
    const res = {
      alwaysRenderSuggestions:
        shouldAlwaysRenderSuggestions !== undefined
          ? shouldAlwaysRenderSuggestions
          : _shouldAlwaysRenderSuggestions(type),
      shouldRenderSuggestions: R.T
    };
    return res;
  }
);

// ---- redux
const _mapStateToProps = createStructuredSelector({
  suggestions: suggestionsSelector
});

const _mapDispatchToProps = (dispatch, props) => ({
  onSuggestionsFetchRequested: input => {
    if (input.reason !== "suggestion-selected") {
      dispatch(
        fetchSuggestionsByWildcard(props.type, {
          suggesterType: props.type,
          suggesterOptionsDecorator: props.suggesterOptionsDecorator,
          input,
          thingType: props.thingType
            ? props.thingType
            : R.path(["params", "thingType"])(props),
          labelPath: props.labelPath
        })
      );
    }
  },
  onSuggestionsClearRequested: () => {
    // Open point: Should we clear suggestions aggresivly or just let the last ones reside until new ones are fetched. Risk for flickering.
    // console.log("onSuggestionsClearRequested");
    //dispatch(clearSuggestions(type))
  }
});

// ---- value state handlers
export const _withValueStateHandlers = withStateHandlers(
  ({ value, suggestions = [] }) => {
    return {
      value: R.when(R.is(Object), R.propOr("", "text"))(value),
      anchorEl: null,
      textField: undefined,
      isFocused: false,
      filteredSuggestions: suggestions,
      menuFocus: false,
      up: false,
      focusedSuggestion: -1
    };
  },
  {
    setValue: (state, { suggestions }) => value => {
      state = {
        ...state,
        value: R.when(R.is(Object), R.propOr("", "text"))(value)
      };
      const filteredSuggestions =
        suggestions && value
          ? R.pipe(su.filterOnPath(value, ["text"]), R.take(101))(suggestions)
          : suggestions;
      return { ...state, filteredSuggestions };
    },
    setAnchorEl: state => anchorEl => {
      return { ...state, anchorEl };
    },
    setFilteredSuggestions: state => filteredSuggestions => {
      filteredSuggestions = R.take(101, filteredSuggestions);
      return {
        ...state,
        filteredSuggestions
      };
    },
    setTextFieldRef: state => textField => {
      return { ...state, textField };
    },
    setTextFieldFocus: state => focus => {
      let focusedSuggestion = state.focusedSuggestion;
      if (state.textField) {
        if (focus) {
          if (state.textField && typeof state.textField.focus === "function") {
            state.textField.focus();
          }
        } else {
          setTimeout(() => {
            if (state.textField && typeof state.textField.blur === "function") {
              state.textField.blur();
            }
          }, 20);

          focusedSuggestion = -1;
        }
      }
      state = { ...state, isFocused: focus, focusedSuggestion };

      return state;
    },
    onKeyUpDownFocus: state => focusedSuggestion => {
      if (R.equals(state.focusedSuggestion, focusedSuggestion)) {
        return state;
      } else {
        return {
          ...state,
          focusedSuggestion,
          menuFocus: false
        };
      }
    },
    setMenuFocus: state => menuFocus => {
      return { ...state, menuFocus };
    },
    setFocus: state => (textFieldFocus, menuFocus) => {
      return textFieldFocus === state.isFocused && menuFocus === state.menu
        ? state
        : { ...state, isFocused: textFieldFocus, menuFocus };
    },
    resetTextField: state => () => {
      if (state.textField) {
        state.textField.blur();
      }
      return { ...state, menuFocus: false, isFocused: false };
    }
  }
);

export const _findExact = (valuePath, value, suggestions) =>
  R.pipe(R.defaultTo([]), R.find(R.pathEq(valuePath, value)))(suggestions);

const selectedSuggestionFromDefaultValue = ({
  idPath,
  defaultValue,
  suggestions
}) => {
  let selectedSuggestion = _findExact(idPath, defaultValue, suggestions);
  selectedSuggestion = !selectedSuggestion
    ? { id: defaultValue, label: defaultValue, text: defaultValue }
    : selectedSuggestion;

  return selectedSuggestion;
};

export const _chipsStateHandlers = withStateHandlers(
  ({
    chips,
    localStateChips,
    defaultValue,
    single,
    suggestions = [],
    idPath = ["id"],
    maxChips = -1
  }) => {
    chips = chips || localStateChips || [];
    if (single && defaultValue) {
      let selectedSuggestion;
      if (R.is(Object, defaultValue)) {
        selectedSuggestion = defaultValue;
      } else {
        selectedSuggestion = selectedSuggestionFromDefaultValue({
          idPath,
          defaultValue,
          suggestions
        });
      }
      chips = selectedSuggestion ? [selectedSuggestion] : [];
    }
    const suggestionsEmptyOnInit = R.isNil(suggestions);
    return {
      chips,
      suggestionsEmptyOnInit,
      isLoading: suggestionsEmptyOnInit,
      showAllChips: maxChips < 1
    };
  },
  {
    setChips: state => chips => ({ ...state, chips }),
    emptyChips: state => () => ({ ...state, chips: [] }),
    removeChip: ({ chips = [] }, { single, onSuggestionSelected }) => (
      event,
      chip
    ) => {
      chips = chip ? R.reject(R.equals(chip), chips) : R.dropLast(1)(chips);
      if (onSuggestionSelected) {
        if (single) {
          onSuggestionSelected(event, { suggestion: undefined });
        } else {
          onSuggestionSelected(event, { suggestion: chips, remove: chip });
        }
      }
      return { chips };
    },
    addChip: (
      { chips },
      {
        single,
        setValue,
        onSuggestionSelected,
        setTextFieldFocus,
        setAnchorEl,
        blurOnSelect = true
      }
    ) => (event, { suggestion }) => {
      chips = R.ifElse(
        R.always(single),
        () => {
          // this is the parents onSuggestionSelected function
          if (typeof onSuggestionSelected === "function") {
            const shouldUpdateChips = onSuggestionSelected(event, {
              suggestion
            });
            return shouldUpdateChips === false ? chips : [suggestion];
          } else {
            return [suggestion];
          }
        },
        R.when(
          R.complement(R.contains(suggestion)),
          R.pipe(R.append(suggestion), appendedChips => {
            if (onSuggestionSelected) {
              onSuggestionSelected(event, { suggestion: appendedChips });
            }
            return appendedChips;
          })
        )
      )(chips);
      // Proxy event to user prop, we don't have the latest state at this point so mimic update

      if (blurOnSelect) {
        setValue("");
        setTextFieldFocus(false);
        setAnchorEl(null);
      } else {
        setTextFieldFocus(true);
      }

      return {
        chips,
        selectedSuggestion: suggestion,
        suggestionsEmptyOnInit: false
      };
    },
    setSelectedSuggestion: state => ({ suggestion }) => ({
      ...state,
      selectedSuggestion: suggestion
    }),
    matchChip: (
      state,
      { suggestions = [], idPath = ["id"], defaultValue, single }
    ) => () => {
      if (!single || !defaultValue || !state.suggestionsEmptyOnInit)
        return state;
      const suggestion = _findExact(idPath, defaultValue, suggestions);
      const chips = suggestion ? [suggestion] : state.chips;
      return { ...state, chips, suggestionsEmptyOnInit: false };
    },
    setIsLoading: state => isLoading => ({ ...state, isLoading }),
    setShowAllChips: state => showAllChips => ({ ...state, showAllChips })
  }
);

export const _withHandlers = withHandlers({
  getSuggestionValue: () => R.prop("text"),
  handleInputBlur: ({ setValue, setFocus }) => () => {
    // We clear the input if the user blurs with something invalid
    setValue("");
    setFocus(false, false);
  },
  handleMenuEsc: ({ resetTextField }) => () => {
    // We clear the input if the user blurs with something invalid
    resetTextField();
  },
  handleInputChange: ({ type, setValue, onInputChange }) => event => {
    // We want a slightly different behaviour depending on what we suggest
    const newValue = event.target.value;
    switch (type) {
      case SUGGEST_RESOURCES:
        // Adding by clicking on a column should still show all suggestions
        setValue(newValue);
        event.preventDefault();
        break;
      default:
        setValue(newValue);
    }
    onInputChange && onInputChange(newValue);
  },
  handleSuggestionSelection: ({ addChip }) => (event, suggestion) => {
    return addChip(event, { suggestion });
  }
});

const _withSuggestionsChange = ({ prevProps, props }) => {
  if (!R.equals(props.suggestions, prevProps.suggestions)) {
    props.setFilteredSuggestions(props.suggestions);
    if (props.suggestionsEmptyOnInit) {
      props.matchChip(props.suggestions);
    }
    if (R.isNil(prevProps.suggestions) && R.not(R.isNil(props.suggestions))) {
      props.setIsLoading(false);
    }
  }
  return { prevProps, props };
};

const _withValueChange = ({ prevProps, props }) => {
  if (
    !R.equals(props.value, prevProps.value) ||
    (props.isFocused && !R.equals(props.isFocused, prevProps.isFocused))
  ) {
    props.onSuggestionsFetchRequested({
      reason: "",
      value: props.value
    });
  }
  return { prevProps, props };
};

const _emptyOrNil = R.anyPass([R.isNil, R.isEmpty]);
// this should really be clear chips
export const _withDefaultValueChange = ({ prevProps, props }) => {
  if (!_emptyOrNil(prevProps.defaultValue) && _emptyOrNil(props.defaultValue)) {
    props.onSuggestionsFetchRequested({
      reason: "",
      value: props.defaultValue
    });
    props.emptyChips();
  } else if (
    _emptyOrNil(prevProps.defaultValue) &&
    !_emptyOrNil(props.defaultValue)
  ) {
    const {
      setSelectedSuggestion,
      defaultValue,
      suggestions = [],
      idPath = ["id"]
    } = props;
    const selectedSuggestion = selectedSuggestionFromDefaultValue({
      idPath,
      defaultValue,
      suggestions
    });
    setSelectedSuggestion({ suggestion: selectedSuggestion });
  }
  if (
    !R.equals(
      R.pathOr([], ["localStateChips"], props),
      R.pathOr([], ["localStateChips"], prevProps)
    )
  )
    props.setChips(props.localStateChips);

  if (props.clearChips && !prevProps.clearChips) props.emptyChips();
  return { prevProps, props };
};

const _withLifecyle = lifecycle({
  componentDidMount() {
    this.props.onSuggestionsFetchRequested({
      reason: "mount",
      value: ""
    });
  },
  componentDidUpdate(prevProps) {
    R.pipe(
      _withSuggestionsChange,
      _withValueChange,
      _withDefaultValueChange
    )({
      prevProps,
      props: this.props
    });
  }
});

const getThemeByName = themeName => {
  switch (themeName) {
    case "modalStyle":
      return modalStyle;
    default:
      return tablePickerStyle;
  }
};

const _withPropsOnChange = withPropsOnChange(
  ["themeName", "styles"],
  ({ themeName, styles = modalStyle }) => ({
    styles: themeName ? getThemeByName(themeName) : styles
  })
);

export default compose(
  setDisplayName("SuggesterTextEnhancer"),
  withRouter,
  connect(_mapStateToProps, _mapDispatchToProps),
  _withShouldRenderSuggestions,
  _withValueStateHandlers,
  _chipsStateHandlers,
  _withHandlers,
  _withLifecyle,
  _withPropsOnChange
)(Suggester);

export const SuggesterContainerSB = compose(
  setDisplayName("SuggesterMultiEnhancer"),
  _withShouldRenderSuggestions,
  _withValueStateHandlers,
  _withValueStateHandlers,
  _chipsStateHandlers,
  _withHandlers,
  withHandlers({
    //never worked fix
    onSuggestionsFetchRequested: () => () => {}
  }),
  _withLifecyle,
  _withPropsOnChange
)(Suggester);
