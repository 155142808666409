import styled from "styled-components";
import WidgetContainerContentBase from "./widget_container_content_base";
import * as colors from "themes/colors";

export default styled(WidgetContainerContentBase)`
  background-color: ${colors.white};
  border-radius: 2px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
`;
