import { path, prop, propOr } from "ramda";
import { createSelector } from "reselect";
import { isSettingAllowed, OPERATIONS, SETTING_TYPES } from "utils/auth_utils";
import { authSelectors } from "../auth";

const rootSelector = propOr({}, "systemManagement");

const request = createSelector(rootSelector, propOr({}, "request"));

export const isLoading = createSelector(request, prop("isLoading"));

export const isUploadLoading = createSelector(request, prop("isUploadLoading"));
export const isLoadingRetentionTimes = createSelector(
  request,
  prop("isLoadingRetentionTimes")
);

export const errorText = createSelector(request, prop("error"));
export const retentionTimesError = createSelector(
  request,
  prop("retentionTimesError")
);

export const uploadUrl = createSelector(rootSelector, prop("uploadUrl"));

export const uploadVersion = createSelector(
  rootSelector,
  path(["uploadVersion"])
);

export const uploadFile = createSelector(
  rootSelector,
  propOr({}, "uploadFile")
);

export const uploadFileName = createSelector(
  rootSelector,
  path(["uploadFile", "name"])
);

export const uploadFileSize = createSelector(
  rootSelector,
  path(["uploadFile", "size"])
);

export const uploadFileType = createSelector(
  rootSelector,
  path(["uploadFile", "type"])
);

export const userCanReadStorageRetention = createSelector(
  authSelectors.permissionsSelector,
  isSettingAllowed(SETTING_TYPES.StorageRetention, OPERATIONS.READ)
);

export const selectedFile = createSelector(
  uploadFileName,
  uploadFileSize,
  uploadFileType,
  (name, size, type) => ({
    name,
    size,
    type
  })
);

export const documentUrl = createSelector(rootSelector, prop("documentUrl"));

export const documentVersion = createSelector(
  rootSelector,
  prop("documentVersion")
);
