import * as colors from "themes/colors";

const cardContainer = {
  width: "400px",
  maxHeight: "100%",
  padding: "30px 0",
  boxSizing: "border-box"
};

export const getStyles = () => ({
  header: {
    background: colors.white,
    display: "flex",
    height: "80px",
    minHeight: "80px",
    alignItems: "center",
    padding: "0 24px",
    width: "100%",
    boxSizing: "border-box",
    boxShadow: "0 1px 6px rgba(0,0,0,.1)"
  },
  main: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "scroll"
  },
  loginContent: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  heading: {
    textAlign: "center",
    marginTop: 0
  },
  errorText: {
    color: colors.telenorErrorTextColor
  },
  link: (styles = {}) => ({
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    color: colors.telenorTextColorAlpha70,
    textDecoration: "none",
    ...styles
  }),
  cardContainer,
  loginCardContainer: {
    ...cardContainer,
    width: "300px"
  },
  logo: {
    height: 40
  }
});
