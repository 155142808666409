import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import FormIconButton from "components/buttons/form_icon";
import AutoComplete, { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import PropTypes from "prop-types";
import * as React from "react";
import { baseTheme } from "themes";
import ColorPicker from "../color_thresholds/color_picker";

const defaultColor = baseTheme.palette.primary1Color;

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  addIcon: {
    marginRight: theme.spacing(1)
  },
  resourceRow: {
    alignItems: "center",
    display: "flex"
  },
  input: { flexGrow: 1 }
}));

const ColorPickerResource = ({ value, thingType, onChange }) => {
  const classes = useStyles();

  // Handlers
  const onAddRow = () => {
    onChange([
      ...(value?.length > 0 ? value : []),
      { resourceId: undefined, color: defaultColor }
    ]);
  };
  const onRemoveRow = index => {
    onChange(value.filter((_, idx) => idx !== index));
  };
  const onResourceSelected = ({ id }, selectedIndex) => {
    onChange(
      value.map((resource, index) =>
        index === selectedIndex ? { ...resource, resourceId: id } : resource
      )
    );
  };
  const onColorSelected = (selectedIndex, hexColor) => {
    onChange(
      value.map((resource, index) =>
        index === selectedIndex ? { ...resource, color: hexColor } : resource
      )
    );
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="subtitle1" color="textSecondary">
        Resources
      </Typography>
      {value?.map(({ resourceId, color }, index) => {
        return (
          <div
            key={`resource-row-${resourceId}-${color}-${index}`}
            className={classes.resourceRow}
          >
            <IconButton
              className={classes.removeIconButton}
              onClick={() => onRemoveRow(index)}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <div className={classes.input}>
              <AutoComplete
                type={DATA_TYPES.ALL_RESOURCES}
                defaultValue={resourceId}
                label="Resource"
                changeHandler={(_id, _value) =>
                  onResourceSelected(_value, index)
                }
                thingTypeId={thingType}
                errorText=""
              />
            </div>
            <ColorPicker
              color={color}
              onChange={hexColor => onColorSelected(index, hexColor)}
            />
          </div>
        );
      })}
      <FormIconButton
        className={classes.addIconButton}
        text="Add resource"
        onClick={onAddRow}
        icon={<AddCircleOutlineIcon className={classes.addIcon} />}
        disabled={
          value?.length > 0 && value[value.length - 1].resourceId === undefined
        }
      />
    </div>
  );
};

ColorPickerResource.propTypes = {
  value: PropTypes.array,
  thingType: PropTypes.string,
  onChange: PropTypes.func
};

export default ColorPickerResource;
