import { InMemoryCache, makeVar } from "@apollo/client";
import possibleTypes from "./possibleTypes.json";

const apiErrorVar = makeVar({
  error: null
});
export const clearClientErrors = () => apiErrorVar({ error: null });
export const setClientErrors = error => apiErrorVar({ error });

const typePolicies = {
  Query: {
    fields: {
      apiError: {
        read() {
          return apiErrorVar();
        }
      }
    }
  }
};

export const cache = new InMemoryCache({
  dataIdFromObject: o => o.id,
  addTypename: false,
  possibleTypes,
  typePolicies
});
