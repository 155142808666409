import * as routeActions from "react-router-redux";
import * as thingTypeUtils from "utils/thing_type_utils";

// ---- thunks
export function home() {
  return (dispatch, getState) => {
    const thingTypes = getState().thingTypes.get("types");
    dispatch(routeActions.push(thingTypeUtils.getHomeUrl(thingTypes)));
  };
}
