import { isEmpty, filter, indexBy, pipe, prop, propEq, pluck } from "ramda";

const THING_GROUP_ID_KEY = "id";
const JOBS_ID_KEY = "jobId";

const pickTargetId = (type, jobTargets) =>
  pipe(filter(propEq("type", type)), pluck("id"))(jobTargets);

const pickTargets = jobTargets => {
  const things = pickTargetId("thing", jobTargets);
  const groups = pickTargetId("group", jobTargets);
  return {
    ...(isEmpty(things) ? null : { things }),
    ...(isEmpty(groups) ? null : { groups })
  };
};

export const adaptGroup = response => ({
  entities: { [prop(THING_GROUP_ID_KEY, response)]: response },
  results: [prop(THING_GROUP_ID_KEY, response)]
});

export const adaptGroups = response => ({
  entities: indexBy(prop(THING_GROUP_ID_KEY), response.groups),
  results: pluck(THING_GROUP_ID_KEY, response.groups)
});

export const adaptJobs = response => ({
  entities: indexBy(prop(JOBS_ID_KEY), response),
  results: pluck(JOBS_ID_KEY, response)
});

export const adaptCreateJobAttributes = ({ form, jobDocumentId }) => ({
  domain: form.jobDomain,
  description: form.jobDescription,
  jobDocumentId: jobDocumentId,
  targets: pickTargets(form.jobTargets),
  targetSelection: form.selectedJobType
});
