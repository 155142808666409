import * as R from "ramda";

export function removeEdit(path) {
  let newPath = String(path);
  if (!path) {
    return;
  }
  const lastIndexOfEdit = path.indexOf("edit");
  if (lastIndexOfEdit > 0) {
    newPath = newPath.substring(0, lastIndexOfEdit - 1);
  }
  return newPath;
}

function remove(elementInIndex, inArray) {
  let sliced = inArray;
  if (elementInIndex > 0) {
    sliced = inArray.slice(0, elementInIndex);
    if (elementInIndex + 1 <= inArray.length - 1) {
      sliced = sliced.concat(inArray.slice(elementInIndex + 1, inArray.length));
    }
  }
  return sliced;
}

export function removeParams(path, params) {
  if (!path || !params || params.length === 0) return;
  return params.reduce((path, param) => removeParam(path, param), path);
}

export function removeParam(path, param) {
  if (!path) return;
  let apath = path.split("/");
  return remove(apath.indexOf(param), apath).join("/");
}

export function replaceParam(path, oldparam, newParam) {
  if (!path) return;
  let apath = path.split("/");
  if (!newParam) {
    return `${remove(apath.indexOf(oldparam), apath).join("/")}`;
  }
  const i = apath.indexOf(oldparam);
  apath[i] = newParam;
  return apath.join("/");
}

export function dropLastParams(n, path) {
  const dropParams = R.compose(R.join("/"), R.dropLast(n), R.split("/"));
  return dropParams(path);
}

export function replaceLastParam(path, replacement) {
  if (!path) return "";
  const dropAndAppend = R.compose(
    R.join("/"),
    R.append(replacement),
    R.dropLast(1),
    R.split("/")
  );
  return dropAndAppend(path);
}

export function replaceLastParamsUntil(replaceUntilParam, path, replacement) {
  if (!path) return "";
  const dropAndAppend = R.compose(
    R.join("/"),
    R.append(replacement),
    R.dropLastWhile(item => item !== replaceUntilParam)
  );
  const splitPath = R.split("/", path);
  return R.contains(replaceUntilParam, splitPath)
    ? dropAndAppend(splitPath)
    : path;
}

export function addParam(path, param) {
  return `${path}/${param}`;
}

export function replaceViewMode(path, viewMode) {
  if (!path) return;
  let apath = path.split("/");
  apath[2] = viewMode;
  return apath.join("/");
}

export function addEdit(path) {
  return path.indexOf("edit") > 0 ? path : `${path}/edit`;
}

export function addQueryParam(query, name, value) {
  let nquery = Object.assign({}, query);
  nquery[name] = value;
  return nquery;
}

export function removeQueryParam(query, name) {
  let nquery = Object.assign({}, query);
  delete nquery[name];
  return nquery;
}

export function removeWidgetId(fromPath) {
  if (!fromPath) return;
  let apath = fromPath.split("/");
  return remove(apath.length - 1, apath).join("/");
}

export function stringifyQuery(query) {
  // console.log('stringifyQuery', query);
  if (!query) return "";
  let i = 0;
  return query.reduce((sq, value, key) => {
    if (value && key) {
      sq = `${sq}${key}=${value}`;
    }
    i++;
    if (i < query.length) {
      sq += "&";
    }
    return sq;
  }, "?");
}

export function thingTypeOverviewPath(thingTypeId, viewMode) {
  return (
    "/things/" +
    (viewMode ? viewMode : "DefaultView") +
    "/" +
    thingTypeId +
    "/overview"
  );
}

export function thingTypePaths(thingTypeState) {
  const defaultPath = "/things/DefaultView";
  const homeUrl = thingTypeState.has("homeUrl")
    ? thingTypeState.get("homeUrl", defaultPath)
    : defaultPath;
  let previousLocation = thingTypeState.has("previousLocation")
    ? thingTypeState.get("previousLocation")
    : null;
  let previousPathname = previousLocation
    ? previousLocation.get("pathname")
    : homeUrl;
  // console.log('*** previousLocation', previousPathname);
  const stringedQuery = stringifyQuery(
    previousLocation && previousLocation.get("query")
  );
  const previousHref = `${previousPathname}${stringedQuery}`;
  // console.log(`**** previousHref ${previousHref}`);
  return {
    previousHref,
    homeUrl
  };
}
