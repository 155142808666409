import { Observable } from "rxjs";
import * as R from "ramda";
import {
  SAVE_RESOURCE,
  CREATE_RESOURCE,
  SCHEMA_LOAD_REQUEST,
  SCHEMA_UPDATED,
  SCHEMAS_LOAD_REQUEST,
  GET_OPTIONS,
  loadSchemaFailure,
  loadSchemaSuccess,
  schemaUpdated,
  loadSchemasFailure,
  loadSchemasSuccess,
  saveResourceSuccess,
  saveResourceFailure,
  createResourceFailure,
  saveOptionsSuccess,
  getOptionsSuccess,
  getOptionsFailure
} from "./schema";
import { apiError } from "ducks/errors";
import {
  UPDATE_VIEW_MODE_MUTATION,
  DEFAULT_THINGTYPE_VIEWMODE_QUERY
} from "graphql/queries";
import { addThingWidgetsToThingTypeViewMode } from "utils/dashboard_utils";
import * as thingTypes from "ducks/thing_types";
import * as imu from "utils/immutable_utils";
import { shouldSaveOptions, isSettable, hasOptions } from "./schema_utils";
import { userCanUpdateDashboard } from "ducks/dashboard/dashboard_selectors";

export const loadSchemaEpic = (action$, store, { api }) =>
  action$.ofType(SCHEMA_LOAD_REQUEST).mergeMap(({ payload }) => {
    return api.resources
      .get$(payload)
      .mergeMap(response =>
        Observable.of(loadSchemaSuccess(response), schemaUpdated())
      )
      .catch(error => Observable.of(loadSchemaFailure(error), apiError(error)));
  });

export const loadSchemasEpic = (action$, store, { api }) =>
  action$.ofType(SCHEMAS_LOAD_REQUEST).mergeMap(action =>
    api.resources
      .list$()
      .map(response => loadSchemasSuccess(response))
      .catch(error => Observable.of(loadSchemasFailure(error), apiError(error)))
  );

export const schemaUpdatedEpic = (
  actions$,
  store,
  { thingTypeSelectors, widgetSelectors }
) =>
  actions$
    .ofType(SCHEMA_UPDATED)
    .filter(action => !thingTypeSelectors.hasUserSaved(store.getState()))
    .map(action =>
      widgetSelectors.getEditingThingTypeWidgetResourceDiff(store.getState())
    )
    .filter(diffResources => diffResources.size > 0)
    .map(diffResources => {
      const state = store.getState();
      const viewModeId = thingTypeSelectors.getEditingThingTypeViewModeId(
        state
      );
      const thingTypeId = thingTypeSelectors.getEditingThingTypeId(state);

      return thingTypes.addWidgets({
        resources: diffResources,
        thingTypeId,
        viewModeId
      });
    });

export const saveResourceEpic = (action$, store, { api }) =>
  action$.ofType(SAVE_RESOURCE).mergeMap(({ payload = {} }) => {
    return api.resources
      .update$(payload)
      .mergeMap(response => {
        const saveResourceSuccessAction = saveResourceSuccess({
          resource: {
            ...response,
            id: response.resourceId,
            elementId: R.path(["resource", "elementId"])(payload)
          }
        });
        if (isSettable(payload)) {
          return (hasOptions(payload)
            ? api.resources.saveOptions$(payload)
            : api.resources.deleteOptions$(payload)
          ).mergeMap(optionsResponse => {
            return Observable.of(
              saveResourceSuccessAction,
              saveOptionsSuccess({
                ...optionsResponse,
                resourceId: payload.resource.id
              })
            );
          });
        } else {
          return Observable.of(saveResourceSuccessAction);
        }
      })
      .catch(error =>
        Observable.of(saveResourceFailure(error), apiError(error))
      );
  });

export const createResourceEpic = (action$, store, { api }) =>
  action$.ofType(CREATE_RESOURCE).mergeMap(({ payload = {} }) => {
    return api.resources
      .create$(payload)
      .mergeMap(response => {
        const saveResourceSuccessAction = saveResourceSuccess({
          resource: {
            ...response,
            id: response.resourceId,
            elementId: R.path(["resource", "elementId"])(payload)
          }
        });
        if (shouldSaveOptions(payload)) {
          return api.resources
            .saveOptions$(
              R.set(
                R.lensPath(["resource", "id"]),
                response.resourceId,
                payload
              )
            )
            .mergeMap(response => {
              return Observable.of(
                saveResourceSuccessAction,
                saveOptionsSuccess({
                  ...response
                })
              );
            });
        } else {
          return Observable.of(saveResourceSuccessAction);
        }
      })
      .catch(error =>
        Observable.of(createResourceFailure(error), apiError(error))
      );
  });

export const getOptionsEpic = (action$, store, { api }) =>
  action$.ofType(GET_OPTIONS).mergeMap(({ payload = {} }) => {
    return api.resources
      .getOptions$(payload)
      .mergeMap(response => {
        return Observable.of(getOptionsSuccess(response));
      })
      .catch(error => Observable.of(getOptionsFailure(error), apiError(error)));
  });

export const addWidgetsEpic = (action$, store, { getApolloClient }) =>
  action$
    .ofType(thingTypes.ADD_WIDGETS)
    .do(action => {
      const { thingTypeId, viewModeId, resources } = action.payload;
      const client = getApolloClient();

      client
        .query({
          query: DEFAULT_THINGTYPE_VIEWMODE_QUERY,
          variables: {
            thingType: thingTypeId,
            preferredViewModeId: viewModeId
          }
        })
        .then(({ data: { defaultViewMode } }) => {
          if (!defaultViewMode) {
            return;
          }

          if (defaultViewMode.isReadOnly) {
            return;
          }

          if (
            defaultViewMode.isPersonal === false &&
            !userCanUpdateDashboard(store.getState())
          ) {
            return;
          }

          const updatedViewMode = addThingWidgetsToThingTypeViewMode({
            viewMode: defaultViewMode,
            resources: imu.unImmute(resources)
          });

          if (updatedViewMode !== defaultViewMode) {
            client.mutate({
              mutation: UPDATE_VIEW_MODE_MUTATION,
              optimisticResponse: {
                updateViewMode: {
                  viewMode: updatedViewMode
                }
              },
              variables: {
                viewModeId: updatedViewMode.id,
                grids: updatedViewMode.grids
              }
            });
          }
        });
    })
    .ignoreElements();
