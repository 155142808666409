import * as gu from "utils/general_utils";
import * as R from "ramda";

const _addNode = props => {
  if (props.key === "attributes") {
    props = R.set(R.lensPath(["acc", props.joinedParentPath.parentId]), {
      ...props.val,
      path: props.joinedParentPath.parentId,
      order: props.state.index++,
      depth: props.joinedParentPath.path.length
    })(props);
  }
  return props;
};

const _addToChildren = (props = {}) =>
  R.when(R.propEq("key", "attributes"), props => {
    let path = props.joinedParentPath.path.slice(
      0,
      props.joinedParentPath.path.length - 1
    );
    return R.when(
      () => !R.isEmpty(path),
      R.set(
        R.lensProp("acc"),
        R.over(
          R.lensPath([path.join("/"), "children"]),
          R.append(props.joinedParentPath.id),
          props.acc
        )
      )
    )(props);
  })(props);

const _join = (path = []) => path.join("/");
const _joinedParentPath = props => ({
  parent: R.pipe(R.propOr([], "parentPath"), _join)(props),
  parentId: R.pipe(R.propOr([], "parentPath"), R.dropLast(1), _join)(props),
  id: R.pipe(R.propOr([], "parentPath"), R.dropLast(1), R.last)(props),
  path: R.pipe(R.propOr([], "parentPath"), R.dropLast(1))(props)
});

const _mapFn = (node, key, val, nodeKey, path, acc = {}, parentPath, state) => {
  return R.pipe(
    _addNode,
    _addToChildren,
    R.prop("acc")
  )({
    node,
    key,
    val,
    nodeKey,
    path,
    parentPath,
    acc,
    joinedParentPath: _joinedParentPath({ parentPath }),
    state
  });
};

const _normalize = R.pipe(
  R.values,
  R.reduce(
    (acc, val) =>
      R.pipe(
        R.when(
          R.always(R.isNil(val.parentId)),
          R.over(R.lensProp("result"), R.append(val.id))
        ),
        R.set(R.lensPath(["entities", "domains", val.id]), val)
      )(acc),
    { result: [], entities: { domains: {} } }
  )
);

export const _getName = R.pipe(
  R.when(
    R.is(Object),
    R.ifElse(
      R.has("attributes"),
      R.ifElse(
        R.pipe(R.path(["attributes", "name"]), R.isNil),
        R.pathOr("", ["attributes", "id"]),
        R.pathOr("", ["attributes", "name"])
      ),
      R.always("attributes")
    )
  ),
  R.when(R.compose(R.not, R.isNil), R.toLower)
);

export const _getId = R.when(
  R.compose(R.not, R.isNil),
  R.ifElse(
    R.has("attributes"),
    R.path(["attributes", "id"]),
    R.always("attributes")
  )
);

export const _sort = R.sort((a = {}, b = {}) => {
  const lowerA = _getName(a);
  const lowerB = _getName(b);
  if (lowerA === "attributes") return -1;
  if (lowerB === "attributes") return 1;
  if (lowerA < lowerB) return -1;
  if (lowerA > lowerB) return 1;
  return 0;
});

export const _keys = R.pipe(
  R.values,
  _sort,
  R.reduce((acc, val) => [...acc, _getId(val)], [])
);

export const _sortByIdOrName = R.sortBy();
export const normalizeDomainsResponse = response => {
  return _normalize(gu.flattenOnObj(response, _mapFn, _keys));
};
