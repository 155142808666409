import "rxjs";
import "rxjs-compat";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import middleware from "../middleware";
import * as newReducers from "ducks";

export default function configureStore(initialState, history) {
  const reducer = combineReducers({
    ...newReducers,
    routing: routerReducer
  });

  const store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(thunk, routerMiddleware(history), ...middleware),
      global.devToolsExtension ? global.devToolsExtension() : f => f
    )
  );

  return store;
}
