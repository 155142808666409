import { authSelectors, setAuthError } from "ducks/auth";
import { userCanCreateThingType } from "ducks/thing_types/thing_types_selectors";
import * as R from "ramda";

export const requireAuth = ({
  store,
  isLoggedIn,
  userHasAppBoardPermission
}) => (nextState, replace) => {
  if (!isLoggedIn(store.getState())) {
    const nextPathname = nextState.location.pathname;
    const query = nextPathname !== "/" ? { redirect: nextPathname } : {};
    replace({ pathname: "/login", query });
  } else if (!userHasAppBoardPermission(store.getState())) {
    store.dispatch(setAuthError("User is not allowed to use this application"));
    replace({ pathname: "/login" });
  }
};

export const hideSidebarIfToldTo = ({ store, hideSidebar }) => (
  prevState,
  nextState
) => {
  const state = nextState.location.state;
  if (state && state.hideSidebar) {
    store.dispatch(hideSidebar());
  }
};

export const denyIf = condition => (nextState, replace) => {
  if (condition) {
    replace({ pathname: "/404" });
  }
};

export const denyIfProductionAccount = ({ store }) => (nextState, replace) => {
  const isProduction = authSelectors.isProductionAccount(store.getState());
  if (isProduction) {
    replace({ pathname: "/404" });
  }
};

export const denyIfUserCanCreateThingTypes = ({ store }) => (
  nextState,
  replace
) => {
  const canCreateThingTypes = userCanCreateThingType(store.getState());
  if (canCreateThingTypes) {
    replace({ pathname: "/404" });
  }
};

export const thingDetailsUrl = (router, { thingName, thingType }) =>
  [
    "",
    "things",
    R.pathEq(["params", "thingType"], thingType, router)
      ? R.path(["params", "viewMode"], router)
      : "DefaultView",
    thingType,
    "detail",
    thingName,
    "untyped",
    "default"
  ].join("/");

export const defaultThingDetailsUrl = (router, { thingName, thingType }) =>
  [
    "",
    "things",
    "DefaultView",
    thingType,
    "detail",
    thingName,
    "untyped",
    "default"
  ].join("/");

export const thingTypeDashboardUrl = (router, thingType) =>
  [
    "",
    "things",
    R.pathEq(["params", "thingType"], thingType, router)
      ? R.path(["params", "viewMode"], router)
      : "DefaultView",
    thingType,
    "overview"
  ].join("/");

export const allThingsUrl = () => "/things/DefaultView";

export const denyIfUserDoesNotHaveThingPermissions = ({ store }) => (
  nextState,
  replace
) => {
  const userHasAppBoardThingPermission = authSelectors.userHasAppBoardThingPermission(
    store.getState()
  );
  if (!userHasAppBoardThingPermission) {
    replace({ pathname: "/404" });
  }
};

export const redirectToDefaultIndexUri = ({ store }) => (
  nextState,
  replace
) => {
  const userHasAppBoardThingPermission = authSelectors.userHasAppBoardThingPermission(
    store.getState()
  );
  replace({
    pathname: userHasAppBoardThingPermission
      ? `/things/${store.getState().thingTypes.get("defaultViewMode")}`
      : "/settings"
  });
};

export const denyIfUserDoesNotHavePermission = (store, permissionFunction) => (
  nextState,
  replace
) => {
  const condition = permissionFunction(store.getState());
  if (!condition) {
    replace({ pathname: "/404" });
  }
};
