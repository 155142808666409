import getWidgetBase from "../get_widget_base";
import ValueWidget from "./value_widget_container";

export const getWidget = props =>
  getWidgetBase({
    ...props,
    noResize: true,
    isRealtime: true,
    widgetComp: ValueWidget
  });
