import WidgetEditResource from "components/widget/edit/widget_edit_resource";
import AggregationTypeFragment from "components/widget/edit/widget_edit_aggregation_options";
import intervalForm from "components/widget/edit/widget_edit_aggregation_interval";
import { ComposeForm } from "components/widget/edit/compose_form";
import resourceTypes from "../widget_resource_types";

export default props =>
  ComposeForm(
    AggregationTypeFragment,
    intervalForm,
    WidgetEditResource
  )({
    ...props,
    resourceType: resourceTypes.NUMBER
  });
