import { createDeepEqualSelector } from "utils/selector_utils";
import { thingTypeSelectors } from "ducks/thing_types";
import { thingSelectors } from "ducks/thing";
import { domainSelectors } from "ducks/domain";

export const _isLoading = (state, options = ["domain", "thing", "thingType"]) =>
  options.reduce((acc, val) => {
    switch (val) {
      case "domain":
        acc = acc + domainSelectors.isLoading(state) ? 1 : 0;
        break;
      case "thing":
        acc = acc + thingSelectors.isLoading(state) ? 1 : 0;
        break;
      case "thingType":
        acc = acc + thingTypeSelectors.isLoading(state) ? 1 : 0;
        break;
      default:
    }
    return acc;
  }, 0) > 0;

export const isLoading = createDeepEqualSelector(
  state => state,
  (state, options) => options,
  (state, options) => exports._isLoading(state, options)
);
