import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";
import entities from "./domain_entities";
import result from "./domain_result";
import request from "./domain_request";
import * as nz from "utils/normalisation_utils";
import * as types from "./domain_action_types";
const normalizedReducer = combineReducers({ request, entities, result });

const initialState = nz.normalizedInitialState();
const domainsReducer = handleActions(
  {
    // ["default"]: state => state
    [types.DOMAINS_LIST_SUCCESS]: nz.listSuccess,
    [types.DOMAINS_CREATE_SUCCESS]: nz.success,
    [types.DOMAINS_UPDATE_SUCCESS]: nz.success,
    [types.DOMAINS_REMOVE_SUCCESS]: nz.listSuccess
  },
  initialState
);
// export default combineReducers(domainsReducer);
export default reduceReducers(domainsReducer, normalizedReducer);
