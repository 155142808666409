import React from "react";
import ObservationPeriod from "components/observation";

const style = {
  datePicker: {
    display: "inline-block",
    padding: "0"
  },
  container: {
    display: "flex"
  }
};

const ObservationPeriodPickerWrapper = props => {
  return (
    <span style={style.container}>
      <span style={style.datePicker}>
        <ObservationPeriod {...props} />
      </span>
    </span>
  );
};

export default ObservationPeriodPickerWrapper;
