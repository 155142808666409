import Default from "./line";
import GenerateValue from "./generate";
import * as Validators from "../validators";

const GeoPoint = Default({
  lat: Validators.Lat,
  lon: Validators.Lon,
  lat_end: Validators.Lat,
  lon_end: Validators.Lon,
  vel: Validators.chain(Validators.Float, Validators.Min(0))
});
export { Default, GeoPoint, GenerateValue };
