import guid from "easy-guid";
import * as R from "ramda";

export const allFieldsValid = form =>
  form.every(field => field.type !== "none" && field.label);

export const setMissingFieldIds = R.pipe(
  R.defaultTo([]),
  R.map(
    R.when(R.propSatisfies(R.isNil, "id"), field =>
      R.assoc("id", "a" + guid.new(16), field)
    )
  )
);
