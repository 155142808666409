import { compose, lifecycle, withHandlers, withState } from "react-recompose";
import ConsentDialog from "./consent_dialog";
import { nthArg, pipe } from "ramda";
import {
  documentUrl,
  getConsentDocumentRequest
} from "ducks/system_management";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isLoading as systemManagementLoading } from "../../ducks/system_management";
import { isLoading as authLoading } from "ducks/auth/auth_selectors";

export const _withCheckboxState = withState("ticked", "setTicked", false);
export const _withHandlers = withHandlers({
  onCheck: ({ setTicked }) => pipe(nthArg(1), setTicked)
});
const _defaultSelectors = {
  documentUrl: documentUrl,
  authLoading: authLoading,
  systemManagementLoading: systemManagementLoading
};

export const _makeMapStateToProps = (
  selectors = _defaultSelectors
) => state => ({
  documentUrl: selectors.documentUrl(state),
  isLoading: selectors.systemManagementLoading(state),
  isSubmitting: selectors.authLoading(state)
});

export const _mapDispatchToProps = dispatch =>
  bindActionCreators({ getConsentDocumentRequest }, dispatch);

export const _lifecycle = lifecycle({
  componentDidMount() {
    this.props.getConsentDocumentRequest();
  }
});

export default compose(
  connect(_makeMapStateToProps(), _mapDispatchToProps),
  _lifecycle,
  _withCheckboxState,
  _withHandlers
)(ConsentDialog);
