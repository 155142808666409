import styled from "styled-components";
import * as colors from "themes/colors";

export default styled.p`
  color: ${props =>
    props.hasFocus
      ? props.muiTheme.palette.primary.main
      : colors.telenorTextColorAlpha50};
  font-size: 16px;
`;
