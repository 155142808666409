import React from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "../styles";
import { isNumber, onChange } from "../utils";

const Static = validator =>
  withStyles(
    ({
      config,
      index,
      onStrategyParameterChange,
      disabled,
      errors,
      classes
    }) => {
      const isNum = isNumber(config.desiredDataType);
      return (
        <React.Fragment>
          <TextField
            data-test="value"
            type={isNum ? "number" : "text"}
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              config.desiredDataType,
              "value",
              validator
            )}
            value={config.strategyParameters.value}
            label="Value"
            className={classes.textField}
            error={!!errors.value}
            helperText={errors.value}
          />
        </React.Fragment>
      );
    }
  );

export default Static;

const GenerateValue = ({ value }) => value;

export { GenerateValue };
