import * as R from "ramda";
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange
} from "react-recompose";
import ColumnPicker from "./column_picker";

const _withPropsOnChange = withPropsOnChange(["fields"], props => ({
  ...props,
  items: props.fields.map(field => {
    let item = {
      ...field,
      text: field.label
    };
    if (field.hasOwnProperty("visible")) {
      item.checked = field.visible;
    }
    return item;
  })
}));

const _withSuggesterState = withState(
  "showSuggester",
  "setShowSuggester",
  false
);
const _withButtonTextState = withState(
  "buttonText",
  "setButtonText",
  "+ Add new resource"
);

// ({*}, [a]) -> [a] -> [a]
const concatAndDedupByIdProp = R.pipe(
  R.flip(R.concat(R.__)),
  R.uniqBy(R.prop("id"))
);

const _withHandlers = withHandlers({
  onChangeWithTable: ({ onChange, tableName }) => ({ items }) => {
    onChange({ tableName, items });
  },
  setDefault: ({ setDefaultColumns, tableName }) => () => {
    setDefaultColumns(tableName);
  },
  handleUpdateFields: ({ onChange, tableName, items }) => ({ field }) => {
    const filteredItems = concatAndDedupByIdProp([field], items);
    onChange({ tableName, items: filteredItems });
  },
  onSaveClicked: ({ setIsSaving, onSave, items }) => async () => {
    if (R.is(Function, onSave)) {
      setIsSaving(true);
      try {
        await onSave({ items });
      } finally {
        setIsSaving(false);
      }
    }
  },
  onShowButtonTap: ({
    showSuggester,
    setShowSuggester,
    setButtonText
  }) => () => {
    setButtonText(showSuggester ? "+ Add new resource" : "Close");
    setShowSuggester(!showSuggester);
  }
});

export default compose(
  _withPropsOnChange,
  withState("isSaving", "setIsSaving", false),
  _withSuggesterState,
  _withButtonTextState,
  _withHandlers
)(ColumnPicker);
