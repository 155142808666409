import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DesiredIcon from "components/icon/desired";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";

const styles = theme => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center"
  },
  desiredIcon: {
    marginRight: theme.spacing(1)
  },
  formWrapper: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 420
  },
  currentValueWrapper: {
    marginRight: theme.spacing(1),
    flex: 1
  },
  desiredValueWrapper: {
    marginLeft: theme.spacing(1),
    flex: 1
  }
});

const DesiredDialogContent = ({
  classes,
  reportedValue,
  desiredValue,
  isLoading,
  label,
  onClose,
  onConfirm,
  onDesiredValueChange,
  settableOptions,
  thingName,
  type,
  unit
}) => (
  <React.Fragment>
    <DialogTitle className={classes.dialogTitle} disableTypography>
      <DesiredIcon fontSize="small" className={classes.desiredIcon} />
      <Typography variant="h6">
        {type === "reject" ? "Reject" : "Set"} {label} on {thingName}
      </Typography>
    </DialogTitle>
    <DialogContent>
      {isLoading ? (
        <div className={classes.formWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.formWrapper}>
          <TextField
            className={classes.currentValueWrapper}
            label="Reported value"
            disabled
            value={reportedValue}
            InputProps={{
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : null
            }}
          />
          {settableOptions.length > 0 && type !== "reject" ? (
            <div className={classes.desiredValueWrapper}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Desired value</InputLabel>
                <Select
                  value={desiredValue}
                  onChange={onDesiredValueChange}
                  input={<Input autoFocus />}
                >
                  {settableOptions.map(({ label, value }, i) => (
                    <MenuItem key={i} value={value}>
                      {label} ({value} {unit})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <TextField
              className={classes.desiredValueWrapper}
              disabled={type === "reject"}
              label="Desired value"
              onChange={onDesiredValueChange}
              value={desiredValue}
              InputProps={{
                autoFocus: true,
                endAdornment: unit ? (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ) : null
              }}
            />
          )}
        </div>
      )}
    </DialogContent>
    <DialogActions>
      <FormCancelButton onClick={onClose} />
      <FormSaveButton
        onClick={onConfirm}
        text="Confirm"
        disabled={desiredValue === ""}
      />
    </DialogActions>
  </React.Fragment>
);

DesiredDialogContent.propTypes = {
  classes: PropTypes.object,
  reportedValue: PropTypes.string,
  desiredValue: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onDesiredValueChange: PropTypes.func,
  resourceName: PropTypes.string,
  settableOptions: PropTypes.array,
  thingName: PropTypes.string,
  type: PropTypes.string,
  unit: PropTypes.string
};

const DesiredDialog = withStyles(styles)(props => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DesiredDialogContent {...props} />
  </Dialog>
));

export default DesiredDialog;
