import React from "react";
import PropTypes from "prop-types";
import FormIconButton from "components/buttons/form_icon";
import FormSaveButton from "components/buttons/form_save";

const NextButton = ({
  steps,
  activeStep,
  handleCreateExport,
  selectedThings,
  handleNextStep,
  thingType,
  isLoading,
  formValid,
  selectedResources
}) => {
  const isLastStep = activeStep === steps - 1;
  const isDisabled =
    isLastStep || selectedResources.size === 0 || selectedResources.size > 100;
  return isLastStep ? (
    <FormSaveButton
      onClick={() =>
        handleCreateExport({
          selectedThings,
          thingType
        })
      }
      data-test="submit"
      isLoading={isLoading}
      disabled={!formValid}
      text="Submit"
      color="primary"
    />
  ) : (
    <React.Fragment>
      {selectedResources.size > 100 ? (
        <span
          style={{
            position: "absolute",
            right: "100px",
            top: "19px",
            fontSize: "12px",
            color: "red"
          }}
        >
          You can select max 100 resources
        </span>
      ) : (
        ""
      )}
      <FormIconButton
        onClick={() => handleNextStep()}
        disabled={isDisabled}
        data-test="next"
        text="Next"
        color="primary"
      />
    </React.Fragment>
  );
};

NextButton.propTypes = {
  steps: PropTypes.number,
  activeStep: PropTypes.number,
  handleCreateExport: PropTypes.func,
  selectedThings: PropTypes.array,
  handleNextStep: PropTypes.func,
  thingType: PropTypes.func,
  isLoading: PropTypes.bool,
  formValid: PropTypes.bool,
  selectedResources: PropTypes.array
};

export default NextButton;
