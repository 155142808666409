import {
  allPass,
  either,
  has,
  ifElse,
  is,
  pathOr,
  pipe,
  prop,
  propOr,
  tryCatch,
  when
} from "ramda";

const unpackLambdaErrorKey = errorMessageKey =>
  pipe(
    propOr({}, "errorMessage"),
    when(
      is(String),
      tryCatch(JSON.parse, (parsingError, originalString) => ({
        [errorMessageKey]: originalString
      }))
    ),
    prop(errorMessageKey)
  );

const isGenericError = allPass([is(Object), has("errorMessage")]);
const isDynamoError = allPass([
  is(Object),
  has("message"),
  has("code"),
  has("requestId")
]);

const unpackDynamoError = propOr(undefined, "message");

export const getMessage = ifElse(
  isDynamoError,
  unpackDynamoError,
  unpackLambdaErrorKey("message")
);
export const getMessageKey = unpackLambdaErrorKey("messageKey");
export const getMessageOrError = when(propOr(null, "errorMessage"), getMessage);
export const isErrorAction = pipe(
  pathOr({}, ["payload", "error"]),
  either(isDynamoError, isGenericError)
);
