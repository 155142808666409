import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import baseTheme from "themes/base";
const disabledColor = baseTheme.palette.disabledColor;

import * as colors from "themes/colors";

const styles = theme => ({
  listItem: {
    transition: "none",
    borderLeft: "4px solid transparent",
    backgroundColor: "transparent"
  },
  listItemSelected: {
    fontWeight: "bold",
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: colors.colorGalleryApproxDarker
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
    minWidth: 0
  }
});

const StyledListItem = withStyles({
  root: {
    padding: 0
  },
  primaryDisabled: {
    color: disabledColor
  }
})(({ classes, children, disabled }) => (
  <ListItemText
    classes={{
      root: classes.root,
      primary: disabled ? classes.primaryDisabled : null
    }}
  >
    {children}
  </ListItemText>
));

const ListNavItem = ({
  disabled,
  children,
  classes,
  selected,
  leftIcon = null,
  ...rest
}) => (
  <ListItem
    button
    className={selected ? classes.listItemSelected : classes.listItem}
    {...rest}
  >
    {leftIcon && (
      <ListItemIcon className={classes.listItemIcon}>{leftIcon}</ListItemIcon>
    )}
    <StyledListItem disabled={disabled}>{children}</StyledListItem>
  </ListItem>
);

ListNavItem.muiName = "ListItem";
ListNavItem.defaultProps = ListItem.defaultProps;
ListNavItem.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  selected: PropTypes.bool,
  leftIcon: PropTypes.node
};

export default withStyles(styles)(ListNavItem);
