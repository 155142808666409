export const debounce = function(func, delay) {
  let timer;
  return function() {
    //anonymous function
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const throttle = (func, delay) => {
  let toThrottle = false;
  return function() {
    if (!toThrottle) {
      toThrottle = true;
      func.apply(this, arguments);
      setTimeout(() => {
        toThrottle = false;
      }, delay);
    }
  };
};
