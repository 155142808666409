import { traceLog } from "api/utils/trace";
import "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "mapbox.js/theme/style.css";
import PropTypes from "prop-types";
import { equals, pipe } from "ramda";
import React from "react";
import { compose, lifecycle, withState } from "react-recompose";
import * as colors from "themes/colors";
import * as imu from "utils/immutable_utils";
import * as mapUtils from "utils/map_utils";
import { formatTime } from "utils/view_utils";
import MapBoxWrapper from "../things_map_widget/styled/map_box_wrapper";
const MapWidget = props => <MapBoxWrapper id={props.mapId} />;
MapWidget.propTypes = {
  mapId: PropTypes.string
};

const buildWidgetMap = pipe(
  mapUtils.newMap,
  mapUtils.withWorldView,
  mapUtils.withTileLayer,
  mapUtils.withFeatureGroupLayer,
  mapUtils.withMarkerData,
  mapUtils.withZoomControl
);

export const getMarkerType = (d, index) => (index === 0 ? "current" : "trace");

export const titleSelector = d =>
  formatTime(imu.getIn(d, ["properties", "timestamp"]));

export default compose(
  withState("mapContext", "setMapContext"),
  lifecycle({
    componentDidMount() {
      const { mapId, data, setMapContext } = this.props;
      setMapContext(
        buildWidgetMap({
          mapId,
          markerOptions: {
            data,
            titleSelector,
            getMarkerType,
            connectingLines: true
          },
          lineOptions: {
            color: colors.telenorPrimaryColor
          },
          mapOptions: {
            trackResize: true,
            attributionControl: false,
            maxZoom: 18,
            minZoom: 2,
            scrollWheelZoom: false,
            zoomControl: false
          }
        })
      );
    },
    componentDidUpdate(prevProps) {
      if (!equals(this.props.container, prevProps.container)) {
        traceLog("[map] container changed");
        this.props.mapContext.map.invalidateSize();
      }
      if (!equals(this.props.data, prevProps.data)) {
        traceLog("[map] new data received");
        this.props.mapContext.onReceiveData({ data: this.props.data });
      }
    }
  })
)(MapWidget);
