import { createAction, handleActions } from "redux-actions";

const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

export const open = createAction(OPEN_MODAL);
export const close = createAction(CLOSE_MODAL);

const initialState = null;

export default handleActions(
  {
    [OPEN_MODAL]: (_, { payload }) => ({
      ...{
        onCancel: () => {},
        onConfirm: () => {}
      },
      open: true,
      ...payload
    }),
    [CLOSE_MODAL]: () => null
  },
  initialState
);
