import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import withStyles from "../styles";
import { onChange } from "../utils";
import { TYPE_FLOAT } from "../types";

const RandomGeoPoint = validator =>
  withStyles(
    ({
      config,
      disabled,
      index,
      onStrategyParameterChange,
      errors,
      classes
    }) => {
      return (
        <React.Fragment>
          <TextField
            data-test="lat"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat",
              validator,
              true
            )}
            value={config.strategyParameters.lat}
            label="Lat"
            className={classes.textField}
            type="number"
            error={!!errors.lat}
            helperText={errors.lat}
          />
          <TextField
            data-test="lon"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon",
              validator,
              true
            )}
            value={config.strategyParameters.lon}
            label="Long"
            className={classes.textField}
            type="number"
            error={!!errors.lon}
            helperText={errors.lon}
          />
          <TextField
            data-test="lat_low"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat_low",
              validator,
              true
            )}
            value={config.strategyParameters.lat_low}
            label="Low (Lat)"
            className={classes.textField}
            type="number"
            error={!!errors.lat_low}
            helperText={errors.lat_low}
          />
          <TextField
            data-test="lat_high"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat_high",
              validator,
              true
            )}
            value={config.strategyParameters.lat_high}
            label="High (Lat)"
            className={classes.textField}
            type="number"
            error={!!errors.lat_high}
            helperText={errors.lat_high}
          />
          <TextField
            data-test="lon_low"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon_low",
              validator,
              true
            )}
            value={config.strategyParameters.lon_low}
            label="Low (Long)"
            className={classes.textField}
            type="number"
            error={!!errors.lon_low}
            helperText={errors.lon_low}
          />
          <TextField
            data-test="lon_high"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon_high",
              validator,
              true
            )}
            value={config.strategyParameters.lon_high}
            label="High (Long)"
            className={classes.textField}
            type="number"
            error={!!errors.lon_high}
            helperText={errors.lon_high}
          />
        </React.Fragment>
      );
    }
  );

RandomGeoPoint.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onStrategyParameterChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default RandomGeoPoint;
