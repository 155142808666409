import * as AllThings from "./all_things_widget";
import * as Events from "./events_widget";
import * as Files from "./files_widget";
import * as Gauge from "./gauge_widget";
import * as GaugeAggregation from "./gauge_aggregation_widget";
import * as Histogram from "./histogram_widget";
import * as Map from "./map_widget";
import * as ThingsMap from "./things_map_widget";
import * as Pie from "./pie_widget";
import * as Process from "./process_widget";
import * as Table from "./table_widget";
import * as TableAggregation from "./table_aggregation_widget";
import * as ThingCredentials from "./thing_credentials_widget";
import * as Timeseries from "./timeseries_widget";
import * as TimeseriesAggregation from "./timeseries_aggregation_widget";
import * as Value from "./value_widget";
import * as ValueAggregation from "./value_aggregation_widget";
import * as Dynamic from "./dynamic_widget";
import * as ResourceOverview from "./resource_overview";

const widgets = {
  AllThings,
  Events,
  Files,
  Gauge,
  GaugeAggregation,
  Histogram,
  Map,
  ThingsMap,
  Pie,
  Process,
  Table,
  TableAggregation,
  ThingCredentials,
  Timeseries,
  TimeseriesAggregation,
  Value,
  ValueAggregation,
  Dynamic,
  ResourceOverview
};

export const getWidgetAsset = (type, assetName) => {
  if (widgets && widgets.hasOwnProperty(type)) {
    return widgets[type][assetName];
  } else {
    return null;
  }
};

export const getEditForm = (type = "") =>
  getWidgetAsset(type, `WidgetEdit${type}`);
