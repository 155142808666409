import { graphql } from "@apollo/client/react/hoc";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Dialog as TelenorDialog } from "components/telenor/dialog";
import ThingForm from "forms_new/things/edit";
import { GET_THING, UPDATE_THING } from "graphql/queries";
import PropTypes from "prop-types";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getContext,
  withHandlers,
  withPropsOnChange,
  withState
} from "react-recompose";
import { compose } from "redux";

const ConfirmDialog = ({ title, message, open, onChoice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = response => {
    onChoice(response);
    setIsLoading(true);
  };
  useEffect(() => {
    setIsLoading(false);
  }, [open]);
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading && <CircularProgress size={16} />}
        <Button
          disabled={isLoading}
          onClick={handleClick.bind(null, false)}
          color="default"
        >
          No
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleClick.bind(null, true)}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onChoice: PropTypes.func.isRequired
};

const ThingEdit = ({ title, initialModel, onCancel, onSave, isLoading }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const RELATION = {
    STANDARD: "STANDARD",
    NETWORKED: "NETWORKED",
    PARENT: "PARENT"
  };

  const relation =
    initialModel &&
    (initialModel.hasNetworkedThings
      ? RELATION.PARENT
      : initialModel.parentThingName
      ? RELATION.NETWORKED
      : RELATION.STANDARD);

  const dialogTitle =
    relation === RELATION.PARENT
      ? "Move Networked Things?"
      : "Moving Networked Thing";
  const dialogMessage =
    relation === RELATION.PARENT
      ? "You are about to change Domain for a Thing that has Networked Things connected to it. Would you like them to be moved to that Domain as well?"
      : "You are about to change Domain for a Networked Thing, the Parent Thing will not change its Domain. Would you like to proceed?";
  return (
    <TelenorDialog title={title} onClose={onCancel}>
      <ConfirmDialog
        title={dialogTitle}
        message={dialogMessage}
        open={openDialog}
        onChoice={shouldMove => {
          if (relation === RELATION.NETWORKED && !shouldMove) {
            // in the case of a networked thing not wanting to proceed, just close the dialog
            setOpenDialog(false);
          } else {
            onSave({ ...formData, moveNetworkedThings: shouldMove });
          }
        }}
      />
      {initialModel && (
        <ThingForm
          initialModel={initialModel}
          handleCancel={onCancel}
          handleSubmit={formData => {
            if (
              // if we are dealing with a PARENT or NETWORKED thing that changed its domain
              formData.domain !== initialModel.domain &&
              relation !== RELATION.STANDARD
            ) {
              setFormData(formData);
              setOpenDialog(true);
            } else {
              onSave({ ...formData, moveNetworkedThings: false });
            }
          }}
          isLoading={isLoading}
        />
      )}
    </TelenorDialog>
  );
};

ThingEdit.propTypes = {
  title: PropTypes.string.isRequired,
  initialModel: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  searchOptions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (
  state,
  { modalStateEditThing: { thingName }, searchOptions }
) => {
  return {
    searchOptions,
    thingName,
    title: "Update thing",
    router: state.router
  };
};

const mapDispatchToProps = (dispatch, { closeModalEditThing }) => {
  return {
    onCancel: closeModalEditThing
  };
};

const _withHandlers = withHandlers({
  onSave: ({
    closeModalEditThing,
    updateThing,
    refetchOnSave,
    setIsLoading
  }) => ({
    thingName,
    label,
    description,
    domain,
    externalId,
    protocol,
    imei,
    imsi,
    moveNetworkedThings
  }) => {
    setIsLoading(true);
    updateThing({
      variables: {
        thingName,
        input: {
          label,
          description,
          domain,
          externalId,
          protocol,
          imei,
          imsi,
          moveNetworkedThings
        }
      }
    })
      .then(() => {
        setIsLoading(false);
        refetchOnSave();
        closeModalEditThing();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }
});

const _withThing = withPropsOnChange(["data"], props => {
  const initialModel = R.path(["data", "thing"])(props);
  return { initialModel };
});

export default compose(
  getContext({ router: PropTypes.object }),
  withState("isLoading", "setIsLoading", false),
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_THING, { options: () => ({ fetchPolicy: "network-only" }) }),
  graphql(UPDATE_THING, { name: "updateThing" }),
  _withHandlers,
  _withThing
)(ThingEdit);
