export default {
  ANY: "any",
  NUMBER: "number",
  STRING: "string"
};

export const isString = type => type === "string";

export const isNumber = type =>
  type === "byte" ||
  type === "number" ||
  type === "long" ||
  type === "int" ||
  type === "float" ||
  type === "double" ||
  type === "integer";
