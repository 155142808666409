import React, { Component } from "react";

class WelcomeContainer extends Component {
  render() {
    return (
      <section>
        <h3>
          This view is unused for now. Can be used as a blank slate when user
          logs in
        </h3>
      </section>
    );
  }
}

export default WelcomeContainer;
