import { css } from "styled-components";
import styled from "styled-components";

export const gridCol = css`
  display: flex;
  flex-direction: column;
`;

export const gridRow = css`
  display: flex;
  flex-direction: row;
`;

export const gridWrap = css`
  flex-wrap: wrap;
`;

// ---- flex containers
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${({ flexStyles }) => flexStyles || ""};
`;

export const EndItem = styled.span`
  align-items: flex-end;
  flex: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ flexStyles }) => flexStyles || ""};
`;
