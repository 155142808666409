import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import { pipe } from "ramda";
import { compose, withState, lifecycle, defaultProps } from "react-recompose";
import MapContainer from "./styled/map_container";
import MapElement from "./styled/map_element";
import MapBoxWrapper from "../widget/widget_types/things_map_widget/styled/map_box_wrapper";
import * as mapUtils from "utils/map_utils";
import { getLength } from "utils/immutable_utils";
import withThingStateGeoAggregation from "components/widget/observation_hocs/with_thing_state_geo_aggregation";

const Perimeter = ({ isActive, mapId }) => (
  <MapContainer isActive={isActive}>
    <MapBoxWrapper>
      <MapElement id={mapId} />
    </MapBoxWrapper>
  </MapContainer>
);

Perimeter.propTypes = {
  isActive: PropTypes.bool.isRequired,
  mapId: PropTypes.string.isRequired
};

const buildPerimeterMap = pipe(
  mapUtils.newMap,
  mapUtils.withNewCluster,
  mapUtils.withWorldView,
  mapUtils.withTileLayer,
  mapUtils.withMarkerData,
  mapUtils.withDrawPoly
);

export default compose(
  defaultProps({
    isActive: false,
    onPerimeterChange: () => {},
    geoJson: List()
  }),
  withState("mapContext", "setMapContext"),
  withThingStateGeoAggregation,
  lifecycle({
    componentDidMount() {
      this.props.setMapContext(
        buildPerimeterMap({
          mapId: this.props.mapId,
          type: this.props.type,
          tileOptions: { maxZoom: 18 },
          drawPolyOptions: {
            geoJson: this.props.geoJson,
            onPerimeterChange: this.props.onPerimeterChange,
            type: this.props.type
          },
          markerOptions: {
            data: this.props.data,
            disableMarkerAutofit: getLength(this.props.geoJson) > 0
          },
          mapOptions: {
            attributionControl: false,
            doubleClickZoom: false,
            maxZoom: 18,
            minZoom: 2,
            scrollWheelZoom: false,
            trackResize: true,
            zoomControl: true
          }
        })
      );

      setTimeout(() => {
        // WORKAROUND: see https://confluence.skunk-works.no/jira/browse/TCAB-1836
        window.dispatchEvent(new Event("resize"));
      }, 0);
    },
    componentDidUpdate(prevProps) {
      if (!this.props.mapContext) {
        return;
      }

      const { data, geoJson, mapContext, onPerimeterChange, type } = this.props;

      if (data !== prevProps.data) {
        mapContext.onReceiveData({ data });
      }
      if (
        geoJson !== prevProps.geoJson ||
        (geoJson && geoJson.isEmpty() && type !== prevProps.type)
      ) {
        mapContext.onReceiveGeoJson({ geoJson, type });
      }
      if (onPerimeterChange !== prevProps.onPerimeterChange) {
        mapContext.onPerimeterChangeChanged({ onPerimeterChange, type });
      }
    }
  })
)(Perimeter);
