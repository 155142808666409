import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { Dialog } from "components/telenor/dialog";
import { authSelectors } from "ducks/auth";
import { Create, Edit } from "forms_new/thingType";
import { ALL_THING_TYPES, GET_THING_TYPE } from "graphql/queries";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getContext, withHandlers, withPropsOnChange } from "react-recompose";
import { compose } from "redux";

const ThingTypeEdit = ({
  title,
  initialModel,
  onCancel,
  onSave,
  metadata,
  modalStateCreateThingType: { open },
  createMode
}) => {
  return (
    <Dialog onRequestClose={onCancel} open={open} title={title}>
      {createMode ? (
        <Create
          initialModel={initialModel}
          metadata={metadata}
          handleCancel={onCancel}
          handleSubmit={onSave}
        />
      ) : (
        <Edit
          initialModel={initialModel}
          metadata={metadata}
          handleCancel={onCancel}
          handleSubmit={onSave}
        />
      )}
    </Dialog>
  );
};

ThingTypeEdit.propTypes = {
  domainId: PropTypes.string,
  title: PropTypes.string.isRequired,
  initialModel: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  metadata: PropTypes.array.isRequired,
  createMode: PropTypes.bool.isRequired,
  modalStateCreateThingType: PropTypes.object.isRequired
};

const mapStateToProps = (
  state,
  { modalStateCreateThingType: { thingTypeId }, data }
) => {
  const { id, label, description, domain } = R.pathOr({}, ["thingType"], data);
  const metadata = R.path(["thingType", "data"], data);

  const createMode = thingTypeId === "create";
  const title = createMode ? `Create ThingType` : `Edit ThingType ${label}`;
  const initialModel = {
    id,
    label,
    description,
    domain,
    ...metadata // metadata saved values
  };

  return {
    initialModel,
    domainId: authSelectors.userSelector(state).domainName,
    title,
    createMode
  };
};

const _withMetadata = withPropsOnChange(["data"], newProps => ({
  metadata: R.pathOr([], ["data", "domain", "thingTypeMetadata"], newProps)
}));

const _withThingTypeId = withPropsOnChange(["data"], newProps => ({
  thingTypeId: R.path(["modalStateCreateThingType", "thingTypeId"], newProps)
}));

const _isNew = thingTypeId => thingTypeId === "create";

export const onUpdateSave = (mutationName, closeModalCreateThingType) => (
  proxy,
  result
) => {
  const mutationResult = R.pathOr({}, ["data", mutationName], result);
  const data = proxy.readQuery({ query: ALL_THING_TYPES });

  if (
    R.none(R.propEq("id", mutationResult.id), data.allThingTypes.thingTypes)
  ) {
    proxy.writeQuery({
      query: ALL_THING_TYPES,
      data: R.over(
        R.lensPath(["allThingTypes", "thingTypes"]),
        R.append(mutationResult),
        data
      )
    });
  }

  closeModalCreateThingType();
};

const _withHandlers = withHandlers({
  onSave: ({
    createThingType,
    updateThingType,
    closeModalCreateThingType,
    modalStateCreateThingType: { thingTypeId }
  }) => thingType => {
    const { id, label, description, domain, ...fieldData } = thingType;

    if (_isNew(thingTypeId)) {
      createThingType({
        variables: {
          thingType: {
            id,
            label,
            description,
            domain,
            data: fieldData
          }
        },
        update: onUpdateSave("createThingType", closeModalCreateThingType)
      });
    } else {
      updateThingType({
        variables: {
          id: thingTypeId,
          thingType: {
            label,
            description,
            domain,
            data: fieldData
          }
        },
        update: onUpdateSave("updateThingType", closeModalCreateThingType)
      });
    }
  }
});

const createThingType = gql`
  mutation createThingType($thingType: InputThingType!) {
    createThingType(input: $thingType) {
      id
      label
      description
      readOnly
      domain
      data
    }
  }
`;

const updateThingType = gql`
  mutation updateThingType($id: ID!, $thingType: InputThingType!) {
    updateThingType(id: $id, input: $thingType) {
      id
      label
      description
      readOnly
      domain
      data
    }
  }
`;

const thingTypeMetadata = gql`
  query($domainId: ID!) {
    domain(domainId: $domainId) {
      thingTypeMetadata
    }
  }
`;

const validateProps = ({ thingTypeId }) => thingTypeId === "create";

export default compose(
  withRouter,
  getContext({ router: PropTypes.object }),
  graphql(createThingType, { name: "createThingType" }),
  graphql(updateThingType, { name: "updateThingType" }),
  _withThingTypeId,
  graphql(GET_THING_TYPE, { skip: validateProps }),
  _withHandlers,
  connect(mapStateToProps),
  graphql(thingTypeMetadata),
  _withMetadata
)(ThingTypeEdit);
