import { ACCOUNT, ENV } from "./config.json";

module.exports = {
  ENTER_KEY: 13,
  ENV,
  ACCOUNT,
  THING_SIMULATOR: {
    URL: topic =>
      `http://s3-eu-west-1.amazonaws.com/cc20-public-eu-west-1/simulator/index.html?topic=${encodeURIComponent(
        JSON.stringify(topic)
      )}`
  },
  MANIFEST: {
    URL: (account = ACCOUNT, env = ENV) =>
      `https://s3-eu-west-1.amazonaws.com/cc20-stacks-eu-west-1/${account}/${env}-manifest.json`
  },
  MAPBOX: {
    TOKEN:
      "pk.eyJ1Ijoibm9lbGJyYWdhbnphIiwiYSI6ImNpanpldWlidzAwNWd2aGtwOG1tNGd1cXAifQ.TEWdVruK75uSk2nxZ1eraA",
    CUSTOM_TILES: {
      DARK_TILE_STYLE: "mapbox://styles/noelbraganza/cijzev9e7010nbpm3i8x2gdvk"
    },
    TILES: {
      STREETS: "mapbox.streets"
    }
  },
  DEFAULT_URL: "/things/DefaultView",
  LOCAL_STORAGE_SESSION_EXPIRED: "loginSessionExpired",
  DEV_ACCOUNT_ID: "683440172240",
  UAT_ACCOUNT_ID: "810902881249"
};
