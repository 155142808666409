import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  acknowledge,
  fetchWidgetEvents,
  getWidgetEvents,
  eventsSelectors
} from "ducks/events";
import EventWidget from "components/widget/widget_types/events_widget/events_widget";
import { widgetProp } from "utils/dashboard_utils";

class EventsWidgetContainer extends React.Component {
  componentDidMount() {
    const {
      observation,
      classification,
      thingName,
      thingType,
      filter
    } = this.props;
    if (observation && classification) {
      this.props.fetchWidgetEvents({
        period: observation,
        classification,
        thingName,
        thingType,
        filter
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      observation,
      classification,
      thingName,
      thingType,
      filter
    } = this.props;
    if (
      observation !== prevProps.observation ||
      classification !== prevProps.classification ||
      thingName !== prevProps.thingName ||
      thingType !== prevProps.thingType ||
      (!filter && prevProps.filter) ||
      (filter && !filter.equals(prevProps.filter))
    ) {
      this.props.fetchWidgetEvents({
        period: observation,
        classification,
        thingName,
        thingType,
        filter
      });
    }
  }

  render() {
    const { events, acknowledge, isUserAuthorizedToAcknowledge } = this.props;
    return (
      <EventWidget
        animate
        events={events}
        eventsTotal={events.size}
        onAcknowledge={acknowledge}
        maxEvents={50}
        isUserAuthorizedToAcknowledge={isUserAuthorizedToAcknowledge}
      />
    );
  }
}

EventsWidgetContainer.propTypes = {
  acknowledge: PropTypes.func,
  classification: PropTypes.string.isRequired,
  events: PropTypes.object,
  fetchWidgetEvents: PropTypes.func.isRequired,
  filter: PropTypes.object,
  observation: PropTypes.object,
  thingName: PropTypes.string,
  thingType: PropTypes.string,
  isUserAuthorizedToAcknowledge: PropTypes.bool
};

const getClassification = widget => widgetProp(widget, "classification");

const mapStateToProps = (
  state,
  { params: { thingType, thingName }, filterId, widget }
) => ({
  events: getWidgetEvents(
    state.events,
    getClassification(widget),
    thingName,
    thingType
  ),
  isUserAuthorizedToAcknowledge: eventsSelectors.userCanAcknowledgeEvents(
    state
  ),
  classification: getClassification(widget),
  filter: state.filter.getIn(["filters", filterId, "filter"]),
  observation: state.observation,
  thingType,
  thingName
});

const mapDispatchToProps = dispatch => ({
  acknowledge: id => dispatch(acknowledge(id)),
  fetchWidgetEvents: payload => dispatch(fetchWidgetEvents(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsWidgetContainer);
