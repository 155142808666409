import { mergeAll } from "ramda";
import { stringify } from "querystring";

const globals = {
  window: window,
  basePath: __BASE_PATH__
};

export default (account, env, { window, basePath } = globals) => {
  window.location.replace(
    `${basePath}/?${stringify(mergeAll({ account, env }))}`
  );
};
