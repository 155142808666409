import * as R from "ramda";

export const adaptEvents = response => {
  return R.applySpec({
    result: R.pipe(
      R.pathOr({}, ["data", "allEvents", "events"]),
      R.pluck("id")
    ),
    total: R.path(["data", "allEvents", "pageInfo", "totalCount"]),
    entities: {
      events: R.pipe(
        R.pathOr({}, ["data", "allEvents", "events"]),
        R.indexBy(R.prop("id"))
      )
    }
  })(response);
};
