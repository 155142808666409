import WidgetEditResource from "components/widget/edit/widget_edit_resource";
import AggregationTypeFragment from "components/widget/edit/widget_edit_aggregation_options";
import { ComposeForm } from "components/widget/edit/compose_form";
import resourceTypes from "../widget_resource_types";
import { gaugeFragment } from "../gauge_widget/gauge_widget_form";

export default props =>
  ComposeForm(
    AggregationTypeFragment,
    WidgetEditResource,
    gaugeFragment
  )({
    ...props,
    resourceType: resourceTypes.NUMBER
  });
