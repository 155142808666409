import React from "react";
import styled from "styled-components";
import { omit } from "ramda";

// Components must be wrapped to avoid "Unknown props ..." validation errors,
// since material-ui will pass on any unknown props directly to the underlying
// React-DOM components:
//  - https://github.com/styled-components/styled-components/issues/305
//  - https://github.com/styled-components/styled-components/issues/439
export default (Component, ...styleOnlyProps) => (...templateArgs) => {
  const StyledComponent = styled(props => (
    <Component {...omit(styleOnlyProps, props)} />
  ))(...templateArgs);
  StyledComponent.muiName = Component.muiName;
  return StyledComponent;
};
