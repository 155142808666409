import * as R from "ramda";
import { createSelector } from "reselect";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";
import { authSelectors } from "../auth";
// ------------------ new selectors for normilazed state

// this could be abstracted send in state path
const _getDomainsState = R.pathOr({}, ["domains"]);

export const _getDomains = createSelector(_getDomainsState, domains =>
  R.pathOr({}, ["entities", "domains"])(domains)
);

const _isNullOrEmpty = R.either(R.isNil, R.isEmpty);
const _hasChildren = R.complement(_isNullOrEmpty);

const _getSubDomains = (domains, domainIds = [], acc) => {
  return domainIds.reduce((mem, domainId) => {
    const domain = domains[domainId];
    mem = { ...mem, [domain.id]: domain };
    if (_hasChildren(domain)) {
      return _getSubDomains(domains, domain.children, mem);
    }
    return mem;
  }, acc);
};

export const fuzzyGetDomains = (domains, fuzzyDomainId) => {
  const rx = new RegExp(fuzzyDomainId);
  return R.filter(R.test(rx))(R.keys(domains));
};

const _isNotNil = R.compose(R.not, R.isNil);
export const _getLabel = R.cond([
  [R.pipe(R.prop("label"), _isNotNil), R.prop("label")],
  [R.pipe(R.prop("name"), _isNotNil), R.prop("name")],
  [R.T, R.prop("id")]
]);
const _selectorize = items => {
  return items.map(item => {
    const label = _getLabel(item);
    return {
      id: item.id,
      label,
      depth: item.depth,
      order: item.order
    };
  });
};

export const _sortByOrder = R.sort((a, b) => a.order - b.order);
const _getDomainValues = createSelector(
  _getDomains,
  R.nthArg(1),
  (domains, domainId) =>
    R.pipe(
      R.values,
      R.uniq,
      _sortByOrder,
      R.when(R.isNil, R.always([]))
    )(
      domainId
        ? _getSubDomains(domains, fuzzyGetDomains(domains, domainId), {})
        : domains
    )
);

export const getDomainValues = _getDomainValues;
export const getDomains = _getDomains;

export const getDomainsForPicker = createSelector(
  _getDomainValues,
  _selectorize
);

const _getDomain = createSelector(
  _getDomainsState,
  R.nthArg(1),
  (domains, domainId) => R.path(["entities", "domains", domainId])(domains)
);

export const getDomainById = (state, domainId) => _getDomain(state, domainId);

const _getDomainsRequest = createSelector(
  _getDomainsState,
  R.propOr({}, "request")
);

export const isLoading = createSelector(
  _getDomainsRequest,
  R.propOr(false, "isLoading")
);

export const isRoot = R.ifElse(R.isNil, R.F, R.propEq("id", "root"));

export const hasDomainWithId = createSelector(
  _getDomainValues,
  R.nthArg(1),
  (domains, id) =>
    R.pipe(R.filter(R.whereEq({ id })), R.complement(R.isEmpty))(domains)
);

export const hasAccessToDomainWithId = createSelector(
  R.pathOr([], ["auth", "user", "accessibleDomains"]),
  R.nthArg(1),
  (domains, id) => R.includes(id, domains)
);

export const hasDomains = createSelector(
  _getDomainsState,
  R.pipe(R.pathOr({}, ["entities", "domains"]), R.isEmpty, R.not)
);

export const hasRoot = state => hasAccessToDomainWithId(state, "root");

export const getCreateStatus = createSelector(
  _getDomainsRequest,
  R.prop("createStatus")
);

export const getUpdateStatus = createSelector(
  _getDomainsRequest,
  R.prop("updateStatus")
);

const EMPTY_LIST = [];

const _selectDomainMetadata = createSelector(
  _getDomainsState,
  R.nthArg(1),
  (metadataState, domainId) =>
    R.pathOr(EMPTY_LIST, ["entities", "domains", domainId, "domainMetadata"])(
      metadataState
    )
);

export const getDomainMetadataItem = createSelector(
  _selectDomainMetadata,
  R.nthArg(2),
  (metadata, itemId) => R.find(R.propEq("id", itemId))(metadata)
);

export const getDomainResources = createSelector(
  _selectDomainMetadata,
  metadata => {
    const metadatafields = metadata.map(value => {
      const id = value.id;
      const label = value.label;
      return {
        id: `domain.data.${id}`,
        label,
        icon: "domain",
        value: `domain.data.${id}`
      };
    });

    const defaults = [
      {
        icon: "domain",
        id: "domain.name",
        label: "Domain name",
        value: "domain.name"
      },
      {
        icon: "domain",
        id: "domain.description",
        label: "Domain description",
        value: "domain.description"
      }
    ];

    return [...defaults, ...metadatafields];
  }
);

export const _getForms = createSelector(_getDomainsState, domains =>
  R.pathOr({}, ["entities", "forms"])(domains)
);
export const getSetupFormFields = createSelector(_getForms, fields => fields);

export const userCanDeleteDomains = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Domains, OPERATIONS.DELETE)
);

export const userCanEditDomains = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Domains, OPERATIONS.UPDATE)
);

export const userCanCreateDomains = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Domains, OPERATIONS.CREATE)
);

export const userCanReadDomains = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Domains, OPERATIONS.READ)
);
