import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

export const createCustomTheme = mutableTheme =>
  createMuiTheme({
    palette: {
      primary: { main: mutableTheme.palette.primary1Color },
      secondary: { main: mutableTheme.palette.accent1Color }
    },
    typography: {
      useNextVariants: true,
      fontFamily: "telenor-regular, sans-serif"
    },
    // Ugly hack to make the Select MenuItems display on top of the navbar. Remove once we're completely migrated to new mUI.
    overrides: {
      MuiModal: {
        root: {
          zIndex: 1500
        }
      },
      MuiSnackbar: {
        root: {
          zIndex: 1600
        }
      },
      // this is a fix for MUI v3 and not needed in newer versions
      // https://github.com/mui-org/material-ui/blob/v3.x/packages/material-ui/src/FormLabel/FormLabel.js#L18
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: mutableTheme.palette.primary1Color
          }
        }
      },
      //https://github.com/mui-org/material-ui/blob/v3.x/packages/material-ui/src/Input/Input.js#L32
      MuiInput: {
        underline: {
          "&:after": {
            borderBottom: `2px solid ${mutableTheme.palette.primary1Color}`
          }
        }
      }
    },
    loginTexts: mutableTheme.loginTexts,
    tabTitle: mutableTheme.tabTitle,
    logo: mutableTheme.logo,
    favicon: mutableTheme.favicon
  });

export const muiThemeSelector = createSelector(
  state => state.theme,
  stateTheme => createCustomTheme(stateTheme.toJS())
);

const ThemeProvider = ({ muiTheme, children }) => (
  <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
);

ThemeProvider.propTypes = {
  muiTheme: PropTypes.object,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  muiTheme: muiThemeSelector(state)
});

export default connect(mapStateToProps)(ThemeProvider);
