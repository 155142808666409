import { Observable } from "rxjs";
import { getMessage, isErrorAction } from "utils/error_message_utils";
import { API_ERROR, setError } from "./errors";
import { setError as setSystemError } from "./system";

export const apiErrorEpic = action$ =>
  action$
    .ofType(API_ERROR)
    .filter(isErrorAction)
    .mergeMap(({ payload: { error, onErrorOptions } }) =>
      Observable.of(
        setSystemError(getMessage(error), onErrorOptions),
        setError(error.errorMessage || {})
      )
    );
