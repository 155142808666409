import React from "react";
import PropTypes from "prop-types";
import PlayIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  progress: {
    color: theme.palette.primary,
    position: "absolute",
    zIndex: 1
  }
});

const ToggleRunningButton = ({
  toggleIsRunning,
  isLoading,
  isRunning,
  isValid,
  classes
}) => (
  <IconButton onClick={toggleIsRunning} disabled={isLoading || !isValid}>
    {isRunning ? (
      <React.Fragment>
        <PauseIcon color="primary" fontSize="large" data-test="pause-icon" />
        <CircularProgress className={classes.progress} />
      </React.Fragment>
    ) : (
      <PlayIcon color="primary" fontSize="large" data-test="play-icon" />
    )}
  </IconButton>
);

ToggleRunningButton.propTypes = {
  toggleIsRunning: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRunning: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  classes: PropTypes.object
};

export default withStyles(styles)(ToggleRunningButton);
