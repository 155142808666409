import { widgetId } from "utils/dashboard_utils";
import getWidgetBase from "../get_widget_base";
import ThingsMapWidget from "./things_map_widget_container";

export const getWidget = props =>
  getWidgetBase({
    ...props,
    isContainer: true,
    isComponent: true,
    includeFooter: false,
    mapId: `things-map-${widgetId(props.widget)}`,
    noResize: false,
    widgetComp: ThingsMapWidget
  });
