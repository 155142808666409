import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ForwardIcon from "@material-ui/icons/Forward";
import {
  FloatingHoverActionRow,
  HoverButton
} from "components/composable_table/action_row_column";
import { TableRowFragment } from "components/composable_table/table_row_fragment";
import { TableHeaderCell } from "components/table";
import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const propertyIcon = require("assets/images/property-16px.svg");

const styles = theme => ({
  forwardArrow: {
    float: "left"
  },
  tableHeader: {
    fontSize: "30px"
  },
  upperField: {
    height: "24px"
  },
  hoverButton: {
    padding: "0px 20px",
    height: "68px",
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  }
});

const TableScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;
const DoubleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 62px;
  margin-bottom: -8px;
  margin-top: 6px;
`;
const EllipsisText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  margin: 0px;
  ${props => (props.color ? `color : ${props.color}` : null)};
`;

const DoubleText = ({
  upper,
  lower,
  upperMuiColor = "default",
  tooltip,
  classes = {}
}) => {
  return (
    <DoubleTextContainer>
      <Typography
        className={classes.upperField}
        color={upperMuiColor}
        variant="subtitle1"
        component="div"
      >
        <EllipsisText title={tooltip}>{upper}</EllipsisText>
      </Typography>
      <Typography variant="body2" component="div">
        <EllipsisText color="#757575">{lower || " "}</EllipsisText>
      </Typography>
    </DoubleTextContainer>
  );
};
DoubleText.propTypes = {
  upper: PropTypes.any,
  lower: PropTypes.any,
  upperMuiColor: PropTypes.string,
  tooltip: PropTypes.string,
  classes: PropTypes.object
};

export const _shortenValue = (value, type) => {
  if (type === "geo_point" && value && typeof value === "string") {
    return value
      .split(",")
      .map(item => item.trim())
      .map(item => (String(item).length > 6 ? `${item.substr(0, 6)}...` : item))
      .join(", ");
  }
  return value;
};

export const _withUnit = (unit, value) =>
  value !== null && value !== undefined
    ? `${value}${unit ? " " + unit : ""}`
    : "";

export const resourceNameSplitter = resourceName => {
  const resourceNameParts = resourceName.split("/");
  if (resourceNameParts.length === 1) return resourceName;
  const [resourceId, ...resourcePath] = resourceNameParts.reverse();
  return (
    <React.Fragment>
      <span style={{ color: "#757575" }}>
        {resourcePath.reverse().join("/")}/
      </span>
      <span>{resourceId}</span>
    </React.Fragment>
  );
};

const renderRow = (classes, openModalDesired, openModalNewWidget) => row => {
  const desiredValue = row.desired.value && (
    <React.Fragment>
      <ForwardIcon className={classes.forwardArrow} color="action" />
      <span
        style={{
          float: "left",
          marginTop: "3px",
          color: "#757575"
        }}
      >
        {_withUnit(row.unit, row.desired.value)}
      </span>
    </React.Fragment>
  );
  const reportedDate = new Date(row.reported.timestamp * 1000);

  return (
    <TableRowFragment
      key={`resource_${row.name}`}
      renderColumns={({ isHovered }) => {
        return (
          <React.Fragment>
            <TableCell align="center" padding="checkbox">
              <img src={propertyIcon} width="24px" height="24px" />
            </TableCell>
            <TableCell>
              <DoubleText
                upper={resourceNameSplitter(row.name)}
                lower={row.label}
              />
            </TableCell>
            <TableCell>
              <DoubleText
                tooltip={String(row.reported.value)}
                upper={_withUnit(
                  row.unit,
                  _shortenValue(row.reported.value, row.type)
                )}
                upperMuiColor="primary"
                lower={desiredValue}
                classes={classes}
              />
            </TableCell>
            <TableCell>
              {row.reported.timestamp && (
                <DoubleText
                  upper={formatDate(reportedDate, "h:mm a")}
                  lower={formatDate(reportedDate, "MMMM do yyyy")}
                />
              )}
            </TableCell>
            <TableCell>{row.type}</TableCell>
            <TableCell>
              {row.isVirtual && (
                <div style={{ display: "table-cell", padding: "2px" }}>
                  <Chip label="Virtual" />
                </div>
              )}
              {row.isSettable && (
                <div style={{ display: "table-cell", padding: "2px" }}>
                  <Chip label="Settable" />
                </div>
              )}
            </TableCell>
            {isHovered ? (
              <FloatingHoverActionRow show={isHovered}>
                <HoverButton
                  onClick={() => {
                    openModalDesired({
                      resourceId: row.id,
                      thingName: row.thingName,
                      thingType: row.thingType,
                      subthing: row.subthing,
                      subThingType: row.subThingType,
                      type: row.desired.value ? "reject" : "set"
                    });
                  }}
                >
                  {row.desired.value ? "Reject" : "Set"}
                </HoverButton>
                <HoverButton
                  onClick={() => {
                    openModalNewWidget({
                      resourceId: row.id,
                      resourceLabel: row.label || row.name
                    });
                  }}
                >
                  <AddIcon /> Widget
                </HoverButton>
              </FloatingHoverActionRow>
            ) : (
              <TableCell padding="none" />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

const ResourceOverviewWidgetTable = ({
  isLoading,
  resources,
  classes,
  openModalDesired,
  openModalNewWidget
}) =>
  isLoading ? (
    <CircularProgress disableShrink />
  ) : (
    <TableScrollContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableHeaderCell padding="checkbox" />
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Value</TableHeaderCell>
            <TableHeaderCell>Last update</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>Metadata</TableHeaderCell>
            <TableHeaderCell padding="none" />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {resources.map(
            renderRow(classes, openModalDesired, openModalNewWidget)
          )}
        </TableBody>
      </Table>
    </TableScrollContainer>
  );

ResourceOverviewWidgetTable.propTypes = {
  resources: PropTypes.array,
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  openModalDesired: PropTypes.func,
  openModalNewWidget: PropTypes.func
};

export default withStyles(styles)(ResourceOverviewWidgetTable);
