import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import { withStyles } from "@material-ui/core/styles";

const labelStyle = { top: "0" };
const underlineStyle = { display: "none" };

const styles = theme => ({
  formControl: {
    margin: 0,
    marginLeft: theme.spacing(2),
    minWidth: 130
  },
  filledInput: {
    backgroundColor: "inherit",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "& underline": {
      borderBottom: "1px solid red"
    }
  },
  selectMenu: {
    paddingTop: 15,
    paddingBottom: 14,
    paddingRight: 25,
    color: theme.palette.primary.main
  },
  underline: {
    "&::before": {
      borderBottom: `4px solid ${theme.palette.primary.main}`
    },
    "&:hover:before": {
      borderBottom: `4px solid ${theme.palette.primary.main}`
    }
  },
  icon: {
    color: theme.palette.primary.main
  }
});

const SubThingPicker = ({
  query,
  params,
  subThings,
  handleChangeSubthing,
  classes,
  disabled
}) => {
  const preventDefault = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  let value = `${params.subthing}`;
  const subThingType = params.subThingType;

  if (subThingType && subThingType !== "untyped") {
    value += ` - ${subThingType}`;
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        data-test="subthing"
        name="subthing"
        value={value}
        onChange={handleChangeSubthing}
        onClick={preventDefault}
        labelStyle={labelStyle}
        classes={{
          selectMenu: classes.selectMenu,
          icon: classes.icon
        }}
        underlineStyle={underlineStyle}
        displayEmpty
        disabled={disabled}
        input={
          <FilledInput
            classes={{
              root: classes.filledInput,
              underline: classes.underline
            }}
            name="viewModePicker"
            id="viewModePicker"
            margin="dense"
          />
        }
      >
        {subThings.map(subthing => {
          let subthingId = subthing.get("subthingId");
          let subThingName = subthing.get("subThingName");
          let text = subThingName;
          const type = subthing.get("type");
          if (type && type !== "untyped") {
            text = `${subThingName} - ${type}`;
          }
          return (
            <MenuItem
              data-test={`subthing__${subThingName}__${type}`}
              key={subthingId}
              value={text}
            >
              {text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SubThingPicker.propTypes = {
  params: PropTypes.object,
  subThings: PropTypes.object.isRequired,
  handleChangeSubthing: PropTypes.func,
  query: PropTypes.object,
  classes: PropTypes.object,
  disabled: PropTypes.bool
};

export default withStyles(styles)(SubThingPicker);
