import { invoke$ as lambdaInvoke$ } from "./invoke";

/*
Example query:
{
    "attributes": {
        "term": "empty for wildcard",
        "size": "default 100",
        "from": ""
        "sort": {
          "field": "" default label, ex "thingType"
          "order": "asc/desc, default asc",
        }
        "filter": {
            "type": "domain/resource"            
            "dataType": "string/long/geo_point/unknown"
            "thingType": "<thingTypeId>"
            "resourceType": "thing/sub-thing/virtual/tcxn"
        }
    }
}
*/

export const queryResources = (term, filter) => ({
  term,
  filter: { type: "resource", ...filter }
});

export const queryDomain = (term, filter) => ({
  term,
  filter: { type: "domain", ...filter }
});

export const searchInvoke$ = invoke$ => query => {
  const action = {
    action: "SEARCH",
    attributes: query
  };
  return invoke$("SearchLambda", action);
};

export const query$ = searchInvoke$(lambdaInvoke$);
