import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export default () => (
  <LoadingWrapper>
    <CircularProgress size={40} thickness={2} />
  </LoadingWrapper>
);
