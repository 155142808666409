import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { withStyles } from "@material-ui/core/styles";
import amber from "@material-ui/core/colors/amber";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import { upperCaseFirst } from "utils/string_utils";
import { pluralizeWithoutCounter } from "utils/general_utils";
import {
  supportedGenerators,
  componentFromConfig,
  isSpecificResourceOrTypeSupported,
  resourceSpecificTypes
} from "ducks/simulator/simulator_data_types";
import * as Types from "ducks/simulator/strategy_parameter_editors/types";
import FormIconButton from "components/buttons/form_icon";

const styles = theme => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: 50
  },
  cardHeader: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between"
  },
  periodLabel: {
    marginBottom: 2 * theme.spacing(1)
  },
  periodValue: {
    marginRight: 2 * theme.spacing(1)
  },
  checkboxColumn: {
    width: 24,
    paddingTop: 12,
    paddingBottom: 12
  },
  paddedCell: {
    paddingTop: 12,
    paddingBottom: 12
  },
  parameterEditorCell: {
    paddingTop: 12,
    paddingBottom: 12,
    width: 510
  },
  fromThingTypeLabel: {
    color: theme.palette.grey[500]
  },
  warningCell: {
    backgroundColor: amber[50],
    paddingTop: 12,
    paddingBottom: 12
  },
  warningText: {
    fontSize: "13px",
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[600]
  },
  warningWrapper: {
    display: "flex",
    alignItems: "center"
  },
  strategySelect: {
    width: "100px"
  },
  typeNotSupportedMessage: {
    color: theme.palette.grey[500]
  },
  desiredDataTypeLabel: {
    whiteSpace: "nowrap"
  }
});

const resourceHeader = type => {
  switch (type) {
    case Types.TYPE_LONG:
      return "NUMERIC (INTEGERS)";
    case Types.TYPE_INTEGER:
      return "NUMERIC (INTEGERS)";
    case Types.TYPE_DOUBLE:
      return "NUMERIC (FLOATS)";
    case Types.TYPE_FLOAT:
      return "NUMERIC (FLOATS)";
    case Types.TYPE_GEOPOINT:
      return "GEO POSITION";
    case Types.TYPE_KEYWORD:
      return "STRING";
    case Types.TYPE_BYTE:
      return "NO TYPE";
  }
};

const newResourcesTypes = config => {
  const isResourceSpecific = isSpecificResourceOrTypeSupported(
    null,
    config.resourceStatePath
  );

  const select = isResourceSpecific
    ? R.keys(resourceSpecificTypes[config.resourceStatePath])
    : [
        Types.TYPE_LONG,
        Types.TYPE_DOUBLE,
        Types.TYPE_GEOPOINT,
        Types.TYPE_KEYWORD
      ];

  return R.pipe(
    R.map(v => [
      { header: resourceHeader(v) },
      { divider: true },
      { value: v }
    ]),
    R.flatten
  )(select);
};

const TypeNotSupportedMessage = ({ className, type }) => (
  <Typography className={className} variant="body2">
    {`Simulation of the data type "${type}" is not supported.`}
  </Typography>
);

TypeNotSupportedMessage.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
};

const StrategyTypeEditor = ({
  config,
  disabled,
  index,
  onStrategyTypeChange,
  classes
}) => (
  <FormControl className={classes.strategySelect} disabled={disabled}>
    <InputLabel shrink>Strategy</InputLabel>
    <Select
      value={config.strategyType}
      onChange={evt => onStrategyTypeChange(evt, index, evt.target.value)}
    >
      {supportedGenerators(
        config.desiredDataType,
        config.resourceStatePath
      ).map(strategyType => (
        <MenuItem key={strategyType} value={strategyType}>
          {upperCaseFirst(strategyType)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

StrategyTypeEditor.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onStrategyTypeChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

const StrategyParameterEditor = ({
  config,
  disabled,
  type,
  index,
  onStrategyParameterChange,
  errors
}) => {
  const Comp = componentFromConfig({
    type,
    strategyType: config.strategyType,
    resourcePath: config.resourceStatePath
  });
  return (
    <Comp
      config={config}
      disabled={disabled}
      index={index}
      onStrategyParameterChange={onStrategyParameterChange}
      errors={errors[index] || {}}
    />
  );
};

StrategyParameterEditor.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  onStrategyParameterChange: PropTypes.func.isRequired
};

const TitleText = ({ enabledConfigsCount }) => (
  <Typography variant="h6">
    {enabledConfigsCount === 0 && (
      <React.Fragment>Select simulator payload data</React.Fragment>
    )}
    {enabledConfigsCount > 0 && (
      <React.Fragment>
        Payload with
        <Typography component="span" variant="inherit" color="primary" inline>
          {` ${enabledConfigsCount} `}
        </Typography>
        {pluralizeWithoutCounter(
          "resources",
          "resource",
          "resources",
          enabledConfigsCount
        )}
      </React.Fragment>
    )}
  </Typography>
);

TitleText.propTypes = {
  enabledConfigsCount: PropTypes.number.isRequired
};

const PayloadConfigEditor = ({
  classes,
  enabledConfigsCount,
  isRunning,
  onAddNewConfig,
  onEnabledChange,
  onPeriodUnitChange,
  onResourceStatePathChange,
  onStrategyTypeChange,
  onStrategyParameterChange,
  onPeriodValueChange,
  onDesiredDataTypeChange,
  onToggleCheckAllConfigs,
  periodUnit,
  periodValue,
  configsWithoutResource,
  configsWithResource,
  allConfigsEnabled,
  userCanCreateResource,
  errors
}) => (
  <div>
    <Typography variant="body2" className={classes.periodLabel}>
      Send payload every
    </Typography>
    <TextField
      disabled={isRunning}
      className={classes.periodValue}
      value={periodValue}
      label="Period"
      type="number"
      onChange={onPeriodValueChange}
    />
    <FormControl disabled={isRunning}>
      <InputLabel>Unit</InputLabel>
      <Select value={periodUnit} onChange={onPeriodUnitChange}>
        <MenuItem value="seconds">seconds</MenuItem>
        <MenuItem value="minutes">minutes</MenuItem>
      </Select>
    </FormControl>

    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        <TitleText enabledConfigsCount={enabledConfigsCount} />
        <FormIconButton
          disabled={isRunning || !userCanCreateResource}
          text="Add resource"
          onClick={onAddNewConfig}
          icon={<AddIcon />}
        />
      </div>
      {configsWithResource.length === 0 &&
      configsWithoutResource.length === 0 ? (
        <CardContent>
          <Typography component="p">
            No resources exist for this thing type, click {`"Add resource"`} to
            create a new resource.
          </Typography>
        </CardContent>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" className={classes.checkboxColumn}>
                <Checkbox
                  disabled={isRunning}
                  checked={allConfigsEnabled}
                  onChange={onToggleCheckAllConfigs}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Current Value</TableCell>
              <TableCell>Simulate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configsWithoutResource.map(({ index, config }) => (
              <TableRow key={index}>
                <TableCell
                  padding="checkbox"
                  className={classes.checkboxColumn}
                >
                  <Checkbox
                    disabled={isRunning}
                    checked={config.enabled}
                    onChange={(evt, checked) =>
                      onEnabledChange(evt, index, checked)
                    }
                  />
                </TableCell>
                <TableCell className={classes.paddedCell}>
                  <TextField
                    disabled={isRunning}
                    autoFocus={!config.resourceStatePath}
                    label="Name"
                    onChange={e =>
                      onResourceStatePathChange(e, index, e.target.value)
                    }
                    value={config.resourceStatePath}
                    error={errors[index] && errors[index].resourceStatePath}
                    helperText={
                      errors[index] && errors[index].resourceStatePath
                    }
                    style={{ minWidth: "100px" }}
                  />
                </TableCell>
                <TableCell className={classes.paddedCell}>
                  <FormControl>
                    <InputLabel className={classes.desiredDataTypeLabel}>
                      Desired type
                    </InputLabel>
                    <Select
                      disabled={isRunning}
                      value={config.desiredDataType}
                      onChange={e =>
                        onDesiredDataTypeChange(e, index, e.target.value)
                      }
                    >
                      {newResourcesTypes(config).map(item =>
                        item.header ? (
                          <ListSubheader key={item.header}>
                            {item.header}
                          </ListSubheader>
                        ) : item.divider ? (
                          <Divider />
                        ) : (
                          <MenuItem value={item.value}>{item.value}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.paddedCell}></TableCell>
                <TableCell className={classes.parameterEditorCell}>
                  {config.enabled ? (
                    <React.Fragment>
                      <StrategyTypeEditor
                        disabled={isRunning}
                        config={config}
                        index={index}
                        onStrategyTypeChange={onStrategyTypeChange}
                        classes={classes}
                      />

                      <StrategyParameterEditor
                        disabled={isRunning}
                        config={config}
                        type={config.desiredDataType}
                        index={index}
                        errors={errors}
                        onStrategyParameterChange={onStrategyParameterChange}
                      />
                    </React.Fragment>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}

            {configsWithResource.length > 0 &&
            configsWithoutResource.length > 0 ? (
              <TableRow>
                <TableCell colSpan="5" className={classes.warningCell}>
                  <div className={classes.warningWrapper}>
                    <WarningIcon nativeColor={amber[500]} />
                    <Typography variant="body2" className={classes.warningText}>
                      When you start the simulator, the configured payloads
                      above will result in the creation of new resources on the
                      ThingType data model. This cannot be undone.
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}

            {configsWithResource.length > 0 ? (
              <TableRow>
                <TableCell colSpan="5">
                  <Typography
                    variant="overline"
                    className={classes.fromThingTypeLabel}
                  >
                    From thing type
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}

            {configsWithResource.map(({ index, config, resource }) => (
              <TableRow key={resource.id}>
                <TableCell
                  padding="checkbox"
                  className={classes.checkboxColumn}
                >
                  <Checkbox
                    checked={config ? config.enabled : false}
                    disabled={
                      isRunning ||
                      !isSpecificResourceOrTypeSupported(
                        resource.type,
                        resource.name
                      )
                    }
                    onChange={(evt, checked) =>
                      index == null
                        ? onAddNewConfig(evt, resource)
                        : onEnabledChange(evt, index, checked)
                    }
                  />
                </TableCell>
                <TableCell className={classes.paddedCell}>
                  {resource.metadata.label}
                </TableCell>
                <TableCell className={classes.paddedCell}>
                  {resource.type}
                </TableCell>
                <TableCell className={classes.paddedCell}>
                  {isRunning && config && config.enabled ? (
                    <strong>{resource.metadata.value}</strong>
                  ) : (
                    resource.metadata.value
                  )}
                </TableCell>
                <TableCell className={classes.parameterEditorCell}>
                  {isSpecificResourceOrTypeSupported(
                    resource.type,
                    resource.name
                  ) ? (
                    config && config.enabled ? (
                      <React.Fragment>
                        <StrategyTypeEditor
                          config={config}
                          disabled={isRunning}
                          index={index}
                          classes={classes}
                          onStrategyTypeChange={onStrategyTypeChange}
                        />

                        <StrategyParameterEditor
                          config={config}
                          disabled={isRunning}
                          type={resource.type}
                          index={index}
                          onStrategyParameterChange={onStrategyParameterChange}
                          errors={errors}
                        />
                      </React.Fragment>
                    ) : null
                  ) : (
                    <TypeNotSupportedMessage
                      className={classes.typeNotSupportedMessage}
                      type={resource.type}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Card>
  </div>
);

PayloadConfigEditor.propTypes = {
  allConfigsEnabled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  configsWithResource: PropTypes.array.isRequired,
  configsWithoutResource: PropTypes.array.isRequired,
  enabledConfigsCount: PropTypes.number.isRequired,
  isRunning: PropTypes.bool.isRequired,
  periodUnit: PropTypes.string,
  periodValue: PropTypes.number,
  onAddNewConfig: PropTypes.func.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
  onPeriodUnitChange: PropTypes.func.isRequired,
  onPeriodValueChange: PropTypes.func.isRequired,
  onDesiredDataTypeChange: PropTypes.func.isRequired,
  onResourceStatePathChange: PropTypes.func.isRequired,
  onStrategyTypeChange: PropTypes.func.isRequired,
  onStrategyParameterChange: PropTypes.func.isRequired,
  onToggleCheckAllConfigs: PropTypes.func.isRequired,
  userCanCreateResource: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
};

export default withStyles(styles)(PayloadConfigEditor);
