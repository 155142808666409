import PropTypes from "prop-types";
import * as React from "react";
import { VictoryTheme, VictoryTooltip } from "victory";

const TooltipContainer = props => {
  const xLabel = props.points[0].xLabel.split(": ");
  const yTop = props.y - props.points.length * 6 + 3;

  return (
    <g>
      <text {...props} y={yTop}>
        <tspan fontWeight="bold">{xLabel[0]}:</tspan>
        <tspan dx={2}>{xLabel[1]}</tspan>
      </text>
      {props.points.map(({ yLabel, color }, index) => {
        const label = yLabel.split(": ");
        return (
          <text
            {...props}
            key={`tooltip-label-${index}}`}
            y={yTop}
            dy={(index + 1) * 12}
          >
            <tspan fill={color}>•</tspan>
            <tspan dx={2} fontWeight="bold">
              {label[0]}:
            </tspan>
            <tspan dx={2}>{label[1]}</tspan>
          </text>
        );
      })}
    </g>
  );
};
TooltipContainer.propTypes = {
  text: PropTypes.array,
  x: PropTypes.number,
  y: PropTypes.number,
  points: PropTypes.array
};

const Cursor = ({ x, y, height, width, domain, cursorDimension, points }) => {
  const yRange = domain.y;
  const xRange = domain.x;

  const showY = cursorDimension && cursorDimension === "x" ? false : true;
  const toolBottomOrientation =
    y < 60 || (!showY && y < height / 2) ? true : false;
  const flyoutPadding = {
    top: VictoryTheme.material.tooltip.flyoutPadding,
    bottom: VictoryTheme.material.tooltip.flyoutPadding,
    left: 10,
    right: 10
  };
  const xLabel = points[0].xLabel;
  const yLabels = points.map(({ yLabel }) => yLabel);

  return (
    <>
      <line
        x1={x}
        y1={yRange[0]}
        x2={x}
        y2={yRange[1]}
        style={{
          stroke: "black",
          strokeWidth: 1,
          strokeDasharray: 1,
          strokeOpacity: 0.3
        }}
      />
      {showY && (
        <line
          x1={xRange[0]}
          y1={y}
          x2={xRange[1]}
          y2={y}
          style={{
            stroke: "black",
            strokeWidth: 1,
            strokeDasharray: 1,
            strokeOpacity: 0.3
          }}
        />
      )}
      <VictoryTooltip
        x={x}
        y={showY ? y : height / 2}
        width={width}
        height={height}
        orientation={toolBottomOrientation ? "bottom" : "top"}
        // Victory calculates the width of the tooltip by checking the length
        // of the text.
        text={[xLabel, ...yLabels]}
        active={true}
        dy={toolBottomOrientation ? 7 : -7}
        cornerRadius={4}
        flyoutPadding={flyoutPadding}
        flyoutStyle={VictoryTheme.material.tooltip.flyoutStyle}
        style={{
          ...VictoryTheme.material.tooltip.style,
          fontFamily: "telenor-regular",
          padding: 6
        }}
        renderInPortal={false}
        constrainToVisibleArea
        labelComponent={<TooltipContainer points={points} />}
      />
    </>
  );
};
Cursor.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  domain: PropTypes.object,
  cursorDimension: PropTypes.string,
  points: PropTypes.array
};

export default Cursor;
