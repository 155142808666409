import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResourceOverviewWidgetTable from "./resource_overview_widget_table";
import { resourcesWithValuesSelector } from "ducks/schema/schema_selectors";
const ResourceOverviewWidget = ({
  resources,
  isLoading,
  openModalDesired,
  handleNewWidget
}) => {
  return (
    <ResourceOverviewWidgetTable
      openModalDesired={openModalDesired}
      openModalNewWidget={handleNewWidget}
      resources={resources}
      isLoading={isLoading}
    />
  );
};

ResourceOverviewWidget.propTypes = {
  resources: PropTypes.array,
  isLoading: PropTypes.bool,
  openModalDesired: PropTypes.func,
  handleNewWidget: PropTypes.func
};

export const mapStateToProps = (
  state,
  { params: { thingName, thingType, subthing, subThingType } }
) => ({
  resources: resourcesWithValuesSelector(state, {
    thingName,
    thingType,
    subthing,
    subThingType
  })
});

export default connect(mapStateToProps)(ResourceOverviewWidget);
