import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import withStyles from "../styles";
import { onChange } from "../utils";
import { TYPE_FLOAT } from "../types";

const RandomGeoPoint = validator =>
  withStyles(
    ({
      config,
      disabled,
      index,
      onStrategyParameterChange,
      errors,
      classes
    }) => {
      return (
        <React.Fragment>
          <TextField
            data-test="lat_min"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat_min",
              validator,
              true
            )}
            value={config.strategyParameters.lat_min}
            label="Min (Lat)"
            className={classes.textField}
            type="number"
            error={!!errors.lat_min}
            helperText={errors.lat_min}
          />
          <TextField
            data-test="lat_max"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lat_max",
              validator,
              true
            )}
            value={config.strategyParameters.lat_max}
            label="Max (Lat)"
            className={classes.textField}
            type="number"
            error={!!errors.lat_max}
            helperText={errors.lat_max}
          />
          <TextField
            data-test="lon_min"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon_min",
              validator,
              true
            )}
            value={config.strategyParameters.lon_min}
            label="Min (Long)"
            className={classes.textField}
            type="number"
            error={!!errors.lon_min}
            helperText={errors.lon_min}
          />
          <TextField
            data-test="lon_max"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              TYPE_FLOAT,
              "lon_max",
              validator,
              true
            )}
            value={config.strategyParameters.lon_max}
            label="Max (Long)"
            className={classes.textField}
            type="number"
            error={!!errors.lon_max}
            helperText={errors.lon_max}
          />
        </React.Fragment>
      );
    }
  );

RandomGeoPoint.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onStrategyParameterChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default RandomGeoPoint;
