import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryLegend,
  VictoryGroup
} from "victory";
import { useTheme } from "@material-ui/core/styles";
import MouseContainer from "./MouseContainer";

const TimeSeries = ({
  width,
  height,
  data,
  colors,
  labels,
  removeYAxis,
  unit
}) => {
  const hasData =
    Array.isArray(data) && Array.isArray(data[0]) && data[0].length > 0;

  const [activePoint, setActivePoint] = useState(undefined);

  const maxima =
    removeYAxis && data.map(dataset => Math.max(...dataset.map(d => d.y)));

  const theme = useTheme();

  const totalLabelsLength = labels.reduce((acc, item) => acc + item.length, 0);

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      width={width}
      height={height}
      scale={{ x: "time", y: "linear" }}
      padding={{ top: 50, bottom: 50, left: 80, right: 50 }}
    >
      <VictoryLegend
        x={theme.spacing()}
        orientation="horizontal"
        itemsPerRow={Math.round(width / (100 + totalLabelsLength))} // simple responsiveness
        colorScale={
          colors && colors.length > 0
            ? colors
            : Array(labels.length).fill(theme.palette.primary.main)
        }
        data={labels.map(label => ({ name: label }))}
        standalone={true}
        style={{ labels: { fontFamily: "telenor-regular" } }}
      />
      <VictoryAxis
        crossAxis
        style={{
          ticks: { stroke: "grey", size: 2 },
          tickLabels: hasData
            ? { fontFamily: "telenor-regular" }
            : { display: "none" }
        }}
        label="Time"
        axisLabelComponent={<VictoryLabel dy={20} />}
      />
      {!removeYAxis && hasData && (
        <VictoryAxis
          dependentAxis
          style={{
            ticks: { stroke: "grey", size: 2 },
            tickLabels: { fontFamily: "telenor-regular" }
          }}
          label={unit}
          axisLabelComponent={
            <VictoryLabel
              dy={-(height / 2 - VictoryTheme.material.scatter.padding + 10)}
              angle={0}
            />
          }
        />
      )}
      {!hasData && (
        <VictoryLabel
          x={width / 2}
          y={height / 2}
          text="No data"
          textAnchor="middle"
          style={{ fontFamily: "telenor-regular" }}
        />
      )}
      {data.map(
        (subData, index) =>
          hasData && (
            <VictoryGroup key={index}>
              <VictoryLine
                data={subData}
                key={`line-${index}`}
                y={datum => (maxima ? datum.y / maxima[index] : datum.y)}
                style={{
                  data: {
                    stroke: colors && colors[index] ? colors[index] : "black",
                    opacity: 0.8
                  }
                }}
              />
              <VictoryScatter
                data={subData}
                key={`scatter-${index}`}
                y={datum => (maxima ? datum.y / maxima[index] : datum.y)}
                size={({ x, y }) =>
                  x === activePoint?.x && (removeYAxis || y === activePoint?.y)
                    ? 5
                    : 3
                }
                style={{
                  data: {
                    fill: colors && colors[index] ? colors[index] : "black"
                  }
                }}
              />
            </VictoryGroup>
          )
      )}
      <MouseContainer
        data={data}
        setActivePoint={setActivePoint}
        labels={labels}
        colors={colors}
        removeYAxis={removeYAxis}
      />
    </VictoryChart>
  );
};

TimeSeries.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  colors: PropTypes.array,
  labels: PropTypes.array,
  removeYAxis: PropTypes.bool,
  unit: PropTypes.string
};
TimeSeries.defaultProps = {
  width: 450,
  height: 350,
  removeYAxis: false
};

export default TimeSeries;
