import { randomValue } from "utils/general_utils";
import { TYPE_FLOAT, TYPE_DOUBLE, TYPE_GEOPOINT } from "../types";

export default (type, params) => {
  switch (type) {
    case TYPE_FLOAT:
    case TYPE_DOUBLE: {
      const { min, max, precision } = params;
      return parseFloat(randomValue(min, max).toFixed(precision));
    }
    case TYPE_GEOPOINT: {
      const { lat_min, lat_max, lon_min, lon_max, precision } = params;

      const lat = parseFloat(randomValue(lat_min, lat_max).toFixed(precision));
      const lon = parseFloat(randomValue(lon_min, lon_max).toFixed(precision));
      return `${lat},${lon}`;
    }
    default: {
      const { min, max } = params;
      return Math.floor(randomValue(min, max) + 0.5);
    }
  }
};
