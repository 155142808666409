import React from "react";
import PropTypes from "prop-types";
import DropZone from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import FormIconButton from "components/buttons/form_icon";
import { compose, setPropTypes } from "react-recompose";
import styles from "./image.styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const style = {
  display: "flex",
  justifyContent: "center"
};

export default compose(
  setPropTypes({
    styles: PropTypes.object,
    containerStyles: PropTypes.object,
    imageStyle: PropTypes.object,
    thingId: PropTypes.string,
    thingType: PropTypes.string,
    uploadThingImage: PropTypes.func,
    deleteThingImage: PropTypes.func,
    userCanUploadFiles: PropTypes.bool
  })
)(props => {
  const {
    data = {},
    thingId,
    thingType,
    uploadThingImage,
    deleteThingImage,
    userCanUploadFiles,
    isLoadingImage,
    fileStatus,
    setFileStatus,
    imageStyle
  } = props;

  const { file, loading } = data;

  if (isLoadingImage) {
    return (
      <div style={props.containerStyles}>
        <div style={style}>
          <CircularProgress size={40} thickness={2} />
        </div>
      </div>
    );
  }

  if (loading || fileStatus === "loading") {
    return (
      <div style={props.containerStyles}>
        <div style={style}>
          <CircularProgress size={40} thickness={2} />
        </div>
        {file && (
          <img
            style={imageStyle}
            src={file.url}
            onError={() => setFileStatus("error")}
            onLoad={() => setFileStatus("loaded")}
            hidden
          />
        )}
      </div>
    );
  }

  if (!file || fileStatus === "error") {
    return userCanUploadFiles ? (
      <div style={props.containerStyles}>
        <DropZone
          style={styles.dropzone}
          data-test="thingimage-upload"
          className="image-upload__dropzone"
          onDrop={files => {
            setFileStatus("loading");
            return uploadThingImage({ thingType, thingId, file: files[0] });
          }}
        >
          No image available.
          <br />
          Drag and drop or click to upload.
          <br />
        </DropZone>
      </div>
    ) : null;
  }
  return (
    <div data-test="thingsummary-image" style={props.containerStyles}>
      {userCanUploadFiles ? (
        <FormIconButton
          data-test="thingsummary-deleteimage-button"
          style={styles.closeButton}
          onClick={() => deleteThingImage({ thingId })}
          icon={<DeleteIcon />}
        />
      ) : null}
      <img
        style={imageStyle}
        src={file.url}
        onError={() => setFileStatus("error")}
        onLoad={() => setFileStatus("loaded")}
      />
    </div>
  );
});
