import * as R from "ramda";
import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import { setNotificationMessage } from "ducks/system";
import { getSetupFormFields } from "./domain_selectors";
import { DOMAIN_TREE } from "graphql/queries";
import * as crud from "./domain_action_types";
import * as setup from "./domain_entities_form";

export const shouldGetDomainsEpic = (action$, store, { hasDomains }) =>
  action$
    .ofType(crud.DOMAINS_SHOULD_DO_REQUEST)
    .filter(() => !hasDomains(store.getState()))
    .mergeMap(action => Observable.of(crud.getDomainsRequest()));

export const getDomainsEpic = (
  action$,
  store,
  { adaptDomains, getApolloClient }
) =>
  action$.ofType(crud.DOMAINS_LIST_REQUEST).mergeMap(action =>
    Observable.from(
      getApolloClient()
        .query({ query: DOMAIN_TREE, fetchPolicy: "network-only" })
        .then(queryResult => {
          const response = R.path(["data", "domainTree", "tree"], queryResult);
          const normalized = adaptDomains(response);
          return crud.getDomainsSuccess(normalized);
        })
    ).catch(error =>
      Observable.of(crud.getDomainsFailure(error), apiError(error))
    )
  );

export const getDomainEpic = (action$, store, { api }) =>
  action$
    .ofType(crud.DOMAINS_GET_REQUEST)
    .mergeMap(({ payload: { domainId } }) =>
      api.domains
        .get$(domainId)
        .map(response => crud.getDomainSuccess(response))
        .catch(error =>
          Observable.of(crud.getDomainFailure(error), apiError(error))
        )
    );

export const saveFieldsEpic = (action$, store, { api, domainUtils }) =>
  action$
    .ofType(setup.SAVE_FIELDS_REQUEST)
    .map(action => domainUtils.setMissingFieldIds(action.payload))
    .mergeMap(fields =>
      api.domains
        .update$({ id: "root", domainMetadata: fields })
        .mergeMap(response => [
          setup.saveFieldsSuccess(
            R.pathOr([], ["root", "attributes", "domainMetadata"], response)
          ),
          setNotificationMessage("Domain saved!")
        ])
        .catch(error =>
          Observable.of(setup.saveFieldsFailure(error), apiError(error))
        )
    );

export const loadFieldsEpic = (action$, store, { api }) =>
  action$.ofType(setup.LOAD_FIELDS_REQUEST).mergeMap(action =>
    api.domains
      .get$("root")
      .map(response =>
        setup.loadFieldsSuccess(R.pathOr([], ["domainMetadata"], response))
      )
      .catch(error =>
        Observable.of(setup.loadFieldsFailure(error), apiError(error))
      )
  );

export const deleteFieldEpic = (action$, store) =>
  action$
    .ofType(setup.DELETE_FIELD)
    .map(action => setup.saveFields(getSetupFormFields(store.getState())));
