/**
 * Downloads a file
 * @param fileName name for the file to be downloaded
 * @param data name the file data
 * @param mimeType the MIME type for the file
 */

export const downloadFile = (fileName, data, mimeType) => {
  const file = new Blob([data], { type: mimeType });
  // IE11
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName);
    // Other browsers
  } else {
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};
