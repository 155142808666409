import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  titleCard: {
    display: "flex",
    flexDirection: "column",
    padding: "20px"
  },
  titleCardContent: {},
  titleCardHeader: {
    marginBottom: "10px"
  }
});

const TitleCard = ({ children, dataTest, title }) => {
  const classes = useStyles();
  return (
    <Card data-test={dataTest}>
      <LinearProgress variant="determinate" value={100} />
      <div className={classes.titleCard}>
        <Typography
          className={classes.titleCardHeader}
          variant="h6"
          component="h6"
        >
          {title}
        </Typography>
        <div className={classes.progressCardContent}>{children}</div>
      </div>
    </Card>
  );
};

TitleCard.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  dataTest: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object
};

export default TitleCard;
