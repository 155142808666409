import { Children, Component } from "react";
import PropTypes from "prop-types";

export default class DeviceProvider extends Component {
  state = {};

  getChildContext() {
    return {
      device: this.state
    };
  }

  componentDidMount() {
    // Register listeners
    const { device } = this.props;
    this.unsubscribeMouse = device.subscribe("mouse", ({ x, y }) =>
      this.setState({
        mouseX: x,
        mouseY: y
      })
    );

    this.unsubscribeLocation = device.subscribe(
      "location",
      ({ coords: { latitude, longitude } }) =>
        this.setState({
          latlng: `${latitude}, ${longitude}`
        })
    );

    this.unsubscribeOrientation = device.subscribe(
      "orientation",
      ({ alpha, beta, gamma }) =>
        this.setState({
          yaw: Math.round(alpha * 100) / 100,
          pitch: Math.round(beta * 100) / 100,
          roll: Math.round(gamma * 100) / 100
        })
    );
  }

  componentWillUnmount() {
    this.unsubscribeOrientation();
    this.unsubscribeLocation();
    this.unsubscribeMouse();
  }

  render() {
    return Children.only(this.props.children);
  }
}

DeviceProvider.propTypes = {
  children: PropTypes.element.isRequired,
  device: PropTypes.object.isRequired
};

DeviceProvider.childContextTypes = {
  device: PropTypes.object.isRequired
};
