/* eslint-disable react/prop-types */
import React from "react";
import * as R from "ramda";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import RootRef from "@material-ui/core/RootRef";

import { compose, withStateHandlers, pure } from "react-recompose";
import { nonEmptyArray, isFunction, isEmptyOrNil } from "utils/general_utils";

const styles = theme => ({
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  input: {
    display: "flex",
    flexWrap: "wrap",
    "& input": {
      flexGrow: 1,
      width: "inherit"
    }
  }
});

const ChipRenderer = ({
  text,
  isDisabled,
  handleClick,
  handleRequestDelete,
  isTextChip,
  dataTest,
  classes
}) =>
  isTextChip ? (
    <span data-test={dataTest}>{text}</span>
  ) : (
    <Chip
      label={text}
      style={{
        pointerEvents: isDisabled ? "none" : undefined
      }}
      onClick={handleClick}
      data-test={dataTest}
      onDelete={handleRequestDelete}
      className={classes.chip}
    />
  );

const _allowChipDeletion = (allowEmptySelected, chips) => {
  if (allowEmptySelected === false) {
    const chipLength = (chips ? chips.length : 0) || 0;
    return chipLength - 1 > 0;
  }
  return true;
};

const ChipAdornment = ({
  chips,
  showAllChips,
  maxChips,
  dataTest,
  disabled,
  focusedChip,
  isTextChip,
  setValue,
  setFocusedChip,
  setTextFieldFocus,
  setSelected,
  setShowAllChips,
  removeChip,
  classes
}) => {
  if (isEmptyOrNil(chips)) return null;
  const _chips = showAllChips ? chips : chips.slice(0, maxChips);

  return (
    <React.Fragment>
      {_chips.map((chip, i) => (
        <ChipRenderer
          dataTest={`${dataTest}-chip-${chip.id}`}
          key={`${i}-${chip.id}`}
          text={chip.text}
          chip
          isDisabled={disabled}
          isFocused={R.equals(focusedChip)(chip)}
          handleClick={() => {
            setValue("");
            setFocusedChip(chip);
            setTextFieldFocus(false);
            setSelected(null);
          }}
          handleRequestDelete={event => removeChip(event, chip)}
          isTextChip={isTextChip}
          classes={classes}
        />
      ))}
      {chips.length > maxChips && maxChips > 0 && (
        <ChipRenderer
          dataTest={`${dataTest}-chip-has-more`}
          text={
            !showAllChips
              ? `... show ${chips.length - maxChips} more`
              : "... show less"
          }
          chip
          isDisabled={disabled}
          isFocused={false}
          handleClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowAllChips(!showAllChips);
          }}
          isTextChip={isTextChip}
          classes={classes}
        />
      )}
    </React.Fragment>
  );
};

export const InputComponent = props => {
  // input props
  const {
    setAnchorRef,
    anchorRef,
    chips,
    placeholder,
    autoFocus = false,
    disabled = false,
    isFocused = true,
    underlineShow = true,
    setFocusedChip,
    focusedChip,
    setValue,
    value,
    removeChip,
    maxChips,
    showAllChips,
    setShowAllChips,
    floatingLabelText,
    floatingLabelFixed,
    textChip,
    id,
    classes
  } = props;
  // action props come from the multi enhancer
  const {
    setTextFieldRef,
    setTextFieldFocus,
    handleOnInputBlur,
    handleOnInputFocused,
    handleOnKeyDown,
    setSelected
  } = props;
  const hasInput = (value && value.length > 0) || chips.length > 0;

  const _placeholder =
    !hasInput && (isFocused || floatingLabelFixed || !floatingLabelText)
      ? placeholder
      : null;

  const helperText = props.errorText || props.helperText;
  const error = Boolean(props.errorText);

  return (
    <RootRef rootRef={setAnchorRef}>
      <FormControl
        id={`${id ? id : "noId"}-suggesterContainer`}
        disabled={disabled}
        error={error}
        margin="normal"
        fullWidth
      >
        {floatingLabelText && (
          <InputLabel
            color="primary"
            margin="normal"
            htmlFor={id}
            shrink={isFocused || floatingLabelFixed || hasInput}
            focused={isFocused}
          >
            {floatingLabelText}
          </InputLabel>
        )}
        {anchorRef && (
          <Input
            id={id}
            key={id}
            inputRef={setTextFieldRef}
            placeholder={_placeholder}
            disableUnderline={!underlineShow}
            onBlur={handleOnInputBlur}
            onFocus={handleOnInputFocused}
            onChange={props.handleInputChange}
            onKeyDown={handleOnKeyDown}
            startAdornment={
              <ChipAdornment
                dataTest={props.dataTest}
                chips={chips}
                maxChips={maxChips}
                showAllChips={showAllChips}
                disabled={disabled}
                focusedChip={focusedChip}
                isTextChip={textChip}
                setValue={setValue}
                setFocusedChip={setFocusedChip}
                setTextFieldFocus={setTextFieldFocus}
                setSelected={setSelected}
                setShowAllChips={setShowAllChips}
                removeChip={removeChip}
                classes={classes}
              />
            }
            inputProps={{
              "data-test": props.dataTest
            }}
            value={value}
            margin="normal"
            color="primary"
            autoComplete={"off"}
            autoFocus={autoFocus}
            className={classes.input}
          />
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </RootRef>
  );
};

const _withInputStateHandlers = withStateHandlers(
  ({ selectedSuggestion }) => ({
    anchorRef: null,
    focusedChip: selectedSuggestion
  }),
  {
    setAnchorRef: state => anchorRef => ({ ...state, anchorRef }),
    setFocusedChip: state => focusedChip => ({ ...state, focusedChip }),
    handleOnInputBlur: (state, { setTextFieldFocus, onBlur }) => event => {
      R.when(
        R.complement(
          R.pathSatisfies(R.test(/-menuItem/), [
            "relatedTarget",
            "attributes",
            "data-test",
            "value"
          ])
        ),
        () => {
          setTextFieldFocus(false);
          isFunction(onBlur) && onBlur();
        }
      )(event);
      return state;
    },
    handleOnInputFocused: (
      state,
      { onFocus, setTextFieldFocus, setAnchorEl }
    ) => event => {
      isFunction(onFocus) && onFocus(event);
      setTextFieldFocus(true);
      setAnchorEl(state.anchorRef);
      return state;
    },
    handleOnKeyDown: (
      state,
      {
        suggestions,
        filteredSuggestions,
        chips,
        value,
        removeChip,
        allowEmptySelected = true,
        onKeyUpDownFocus,
        addChip,
        selectedSuggestion,
        setValue,
        textField,
        setTextFieldFocus,
        resetTextField,
        blurOnSelect = true,
        focusedSuggestion
      }
    ) => event => {
      const _delete = event => event.keyCode === 8 || event.keyCode === 46;
      const _esc = event => event.keyCode === 27;
      const _left = event => event.keyCode === 37;
      const _right = event => event.keyCode === 39;
      const _hasValue = event => R.not(R.isEmpty(event.target.value));
      const _canDelete = event =>
        _delete(event) &&
        nonEmptyArray(chips) &&
        !_hasValue(event) &&
        _allowChipDeletion(allowEmptySelected, chips);

      const _up = event => event.keyCode === 38;
      const _down = event => event.keyCode === 40;
      const _enter = event => event.keyCode === 13;
      if (_esc(event)) {
        if (textField) {
          textField.blur();
        }
        setTextFieldFocus(false);
        return { ...state };
      }

      if ((_up(event) || _down(event)) && onKeyUpDownFocus) {
        const up = _up(event);
        let fs = -1;
        fs = up ? focusedSuggestion - 1 : focusedSuggestion + 1;
        fs = fs < 0 ? filteredSuggestions.length - 1 : fs;
        fs = fs >= filteredSuggestions.length ? 0 : fs;
        onKeyUpDownFocus(fs);
        return {
          ...state,
          focusedSuggestion: fs,
          focusedSuggestionItem: filteredSuggestions[fs]
        };
      }
      if (_left(event) || _right(event)) {
        resetTextField();
      }
      if (_enter(event) && (_hasValue(event) || state.focusedSuggestionItem)) {
        if (!isEmptyOrNil(value) && !R.isNil(suggestions)) {
          // first do a direct match
          selectedSuggestion = R.find(
            R.pipe(R.prop("text"), R.toLower, R.equals(R.toLower(value)))
          )(suggestions);
          // then do a regex
          selectedSuggestion = selectedSuggestion
            ? selectedSuggestion
            : R.find(
                R.propSatisfies(
                  R.pipe(R.toLower, R.test(new RegExp(`${R.toLower(value)}`))),
                  "text"
                )
              )(suggestions);
        }
        if (state.focusedSuggestionItem) {
          selectedSuggestion = state.focusedSuggestionItem;
        }
        if (selectedSuggestion) {
          addChip(event, { suggestion: selectedSuggestion });
          setValue("");
          blurOnSelect && setTextFieldFocus(false);
          event.preventDefault();
          return { ...state, focusedChip: selectedSuggestion };
        } else {
          event.preventDefault();
          return state;
        }
      }
      if (_hasValue(event)) {
        setTextFieldFocus(true);
        return {
          ...state,
          focusedChip: null,
          focusedSuggestionItem: undefined
        };
      }
      if (_canDelete(event)) {
        removeChip(event, state.focusedChip);
      }
      const nextChip = (event, focusedChip, chips) => {
        if (chips && chips.length === 0) return null;
        const direction = (event, focusedChip, chips) => {
          let i = -1;
          if (_canDelete(event) || _left(event)) {
            i = focusedChip
              ? R.findIndex(R.equals(focusedChip))(chips) - 1
              : chips.length - 1;
            i = i < 0 ? chips.length - 1 : i;
          } else if (_right(event)) {
            i = focusedChip
              ? R.findIndex(R.equals(focusedChip))(chips) + 1
              : chips.length + 1;
            i = i === chips.length ? 0 : i;
          }
          return i;
        };
        let j = direction(event, focusedChip, chips);
        return j === -1 ? focusedChip : chips[j];
      };
      let newChip = nextChip(event, state.focusedChip, chips);
      return { ...state, focusedChip: newChip };
    }
  }
);

export default compose(
  _withInputStateHandlers,
  pure
)(withStyles(styles)(InputComponent));
