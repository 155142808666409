const listeners = {
  location(callback) {
    const id = navigator.geolocation.watchPosition(position =>
      callback(position)
    );
    return () => navigator.geolocation.clearWatch(id);
  },
  mouse(callback) {
    const handle = ({ x, y }) => callback({ x, y });
    global.addEventListener("mousemove", handle);
    return () => global.removeEventListener("mousemove", handle);
  },
  orientation(callback) {
    const handle = data => callback(data);
    global.addEventListener("deviceorientation", handle);
    return () => global.removeEventListener("deviceorientation", handle);
  }
};

const subscribe = (event, callback) => listeners[event](callback);

export default { subscribe };
