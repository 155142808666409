export default [
  {
    id: "label",
    label: "Thing",
    type: "text",
    selector: "label",
    visible: true,
    isDefault: true
  },
  {
    id: "domain",
    label: "Domain",
    type: "text",
    selector: "domain",
    visible: true,
    isDefault: true
  },
  {
    id: "thingType",
    label: "Type",
    type: "text",
    selector: "thingType",
    visible: true,
    isDefault: true
  },
  {
    id: "lastHeard",
    label: "Last update",
    type: "text",
    selector: "lastHeardFrom",
    visible: true,
    isDefault: true
  },
  {
    id: "createdAt",
    label: "Created",
    type: "text",
    selector: "createdAt",
    visible: true,
    isDefault: true
  },
  {
    id: "Status",
    label: "Status",
    type: "component",
    selector: "Status",
    width: "50px",
    visible: true,
    isDefault: true
  },
  {
    id: "thingName",
    label: "Thing ID",
    selector: "thingName",
    type: "text",
    isDefault: true,
    visible: false
  },
  {
    id: "description",
    label: "Description",
    selector: "description",
    type: "text",
    isDefault: true,
    visible: false
  }
];
