/*eslint no-useless-escape: "off"*/
/*eslint no-empty: "off"*/

import * as R from "ramda";
import { lower } from "./general_utils";
import { Parser } from "expr-eval";
const REPLACEMENT_REGEX = /\${([^\${}]*)}/g;

export const upperCaseFirst = R.compose(
  R.converge(R.concat, [
    R.compose(R.toUpper, R.head),
    R.compose(R.toLower, R.tail)
  ]),
  R.defaultTo("")
);

export const unconstantify = R.pipe(
  R.defaultTo(""),
  // "IN_PROGRESS"
  R.replace(/_/g, " "),
  // "IN PROGRESS"
  R.split(" "),
  // ["IN","PROGRESS"]
  R.map(upperCaseFirst),
  // ["In","Progress"]
  R.join(" ")
  // "In Progress"
);

const intersperseSeparatorsAndGrammar = (sep, conj) =>
  // Converges into  ["Bob", ", ", "Alice", " and ", "John"]
  R.converge(R.concat, [
    // ["Bob", ", ", "Alice"]
    R.compose(R.intersperse(sep), R.init),
    // [" and ", "John"]
    R.compose(R.concat(R.of(conj)), R.of, R.last)
  ]);

export const unconstantifyWithGrammar = R.pipe(
  R.map(unconstantify),
  R.when(
    R.pipe(R.length, R.gte(R.__, 2)),
    intersperseSeparatorsAndGrammar(", ", " and ")
  ),
  R.join("")
);

export const kebabCase = R.pipe(
  R.defaultTo(""),
  R.split(" "),
  R.reject(R.isEmpty),
  R.map(lower),
  R.join("-")
);

/**
 * Replaces variable placeholders in a string like 'A string with ${variable}'
 * with the value of the variable from an object containing the values.
 *
 * @param str the string to replace the variables in.
 * @param vars an object containing the values.
 * @return the resulting string.
 */
export const replaceVarsInString = (str, vars, _options) => {
  const options = { ...{ missingValueReplacement: null }, ..._options };

  if (!str || typeof str !== "string") {
    return str;
  }
  return str.replace(REPLACEMENT_REGEX, (a, b) => {
    let replacement;
    try {
      replacement = Parser.evaluate(b, vars);
    } catch (error) {}

    switch (typeof replacement) {
      case "undefined":
        if (vars && vars.hasOwnProperty(b)) {
          return options.missingValueReplacement === null
            ? a
            : options.missingValueReplacement;
        }
        return a;
      case "number":
      case "string":
        return replacement;
      default:
        return replacement ? JSON.stringify(replacement) : a;
    }
  });
};
