import React from "react";
import NoDataIcon from "@material-ui/icons/VisibilityOff";
import styled from "styled-components";

const OuterIconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InnerIconWrapper = styled.div`
  text-align: center;
  margin: auto;
`;

const iconStyle = {
  width: "195px",
  height: "195px",
  opacity: ".3",
  textAlign: "center",
  margin: "auto"
};

export default props => (
  <OuterIconWrapper {...props}>
    <InnerIconWrapper>
      <NoDataIcon style={iconStyle} />
      <p>No observation data available</p>
      <p>Most likely this thing has not connected yet</p>
    </InnerIconWrapper>
  </OuterIconWrapper>
);
