import PropTypes from "prop-types";
import { equals, pickAll } from "ramda";
import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { makeGridLayouts, widgetId } from "utils/dashboard_utils";
import ThingShadowWidgetsGrid from "../thing_shadow_widget_grid";
import WidgetContainer from "../widget/widget_container";

const LAYOUT_PROP_WHITELIST = ["x", "y", "w", "h", "i"];

const BREAKPOINTS = {
  lg: 1200,
  md: 996,
  sm: 768,
  xs: 480,
  xxs: 200
};
const COLS = {
  lg: 12,
  md: 12,
  sm: 12,
  xs: 1,
  xxs: 1
};
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const removeResizableHandle = isMovingWidgets => {
  // this is a horrible hack!!
  // basically, the grid layout unmounts and mounts all grid items on a isDraggable, isResizable change.
  // this happens when going from edit to non edit mode ...
  // this hugely degrades performance, hence this hack.
  // we're now always in edit mode but we hide or show the resizable handle.
  const display = isMovingWidgets ? "block" : "none";
  const elements = document.getElementsByClassName("react-resizable-handle");
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = display;
  }
};

const getLayoutProps = edit => {
  return {
    className: "layout",
    autosize: true,
    useCSSTransforms: true,
    rowHeight: 100,
    cols: 12,
    verticalCompact: true,
    // minH: 100,
    // minW: 100,
    margin: [24, 24],
    isDraggable: true,
    isResizable: true,
    draggableHandle: edit ? null : ".doesntExist"
  };
};

const createWidgets = ({
  widgets,
  deleteEditModeWidget,
  params,
  type,
  dispatch,
  thingShadowResourceKeypath,
  virtualResourcesKeypath,
  filterId,
  viewMode,
  thingType,
  isMovingWidgets,
  observationPeriod,
  updateEditModeWidget,
  openModalDesired,
  handleNewWidget,
  viewModeId,
  gridId
}) => {
  if (!widgets) return;

  const widgetComponents = widgets.map(widget => {
    const wId = widgetId(widget);
    const wkey = wId.replace("_", "");
    return (
      <div key={wId}>
        <WidgetContainer
          filterId={filterId}
          widget={widget}
          thingShadowResourceKeypath={thingShadowResourceKeypath}
          virtualResourcesKeypath={virtualResourcesKeypath}
          deleteEditModeWidget={deleteEditModeWidget}
          dashboardType={type}
          wkey={wkey}
          dispatch={dispatch}
          key={wkey}
          params={params}
          viewMode={viewMode}
          thingType={thingType}
          isMovingWidgets={isMovingWidgets}
          observationPeriod={observationPeriod}
          updateEditModeWidget={updateEditModeWidget}
          openModalDesired={openModalDesired}
          handleNewWidget={handleNewWidget}
          viewModeId={viewModeId}
          gridId={gridId}
        />
      </div>
    );
  });

  return widgetComponents;
};

const Dashboard = props => {
  const [wasDraggingOrResizing, setWasDraggingOrResizing] = useState(false);
  const [breakpoint, setBreakpoint] = useState("lg");
  const [gridContainer, setGridContainer] = useState(undefined);

  const widgetComponents = createWidgets(props);

  const getWidth = () => {
    return gridContainer ? gridContainer.clientWidth : undefined;
  };
  const handleOnLayoutChange = changedLayout => {
    const { setEditModeLayout } = props;
    const width = getWidth();
    const isSmallBp =
      width === undefined ||
      width < 770 ||
      breakpoint === "xs" ||
      breakpoint === "xxs";

    const thisLayout = props.gridLayouts[breakpoint];
    const newLayout = changedLayout.map(pickAll(LAYOUT_PROP_WHITELIST));

    // only update the layout on a drag event
    if (wasDraggingOrResizing) setWasDraggingOrResizing(false);
    const shouldUpdateLayout =
      wasDraggingOrResizing && !isSmallBp && !equals(newLayout, thisLayout);

    if (shouldUpdateLayout) {
      setEditModeLayout(makeGridLayouts(newLayout));
    }
  };
  const handleOnDragResize = () => {
    setWasDraggingOrResizing(true);
    return true;
  };

  useEffect(() => {
    removeResizableHandle(props.isMovingWidgets);
  });

  const { isMovingWidgets, widgets, gridLayouts } = props;

  if (!widgets || widgets.length === 0) {
    return <h3 data-test="dashboard-no-widgets-message">No Widgets</h3>;
  }

  const layoutProps = getLayoutProps(isMovingWidgets, widgets.size);

  return (
    <ThingShadowWidgetsGrid ref={ref => setGridContainer(ref)}>
      <ResponsiveReactGridLayout
        className="layout"
        {...layoutProps}
        onLayoutChange={handleOnLayoutChange}
        layouts={gridLayouts}
        breakPoints={BREAKPOINTS}
        cols={COLS}
        onBreakpointChange={setBreakpoint}
        onDragStart={handleOnDragResize}
        onResizeStart={handleOnDragResize}
      >
        {widgetComponents}
      </ResponsiveReactGridLayout>
    </ThingShadowWidgetsGrid>
  );
};

Dashboard.propTypes = {
  filterId: PropTypes.string,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      widgetId: PropTypes.string.isRequired,
      label: PropTypes.string,
      type: PropTypes.string.isRequired,
      props: PropTypes.object.isRequired
    })
  ),
  thingShadowResourceKeypath: PropTypes.array,
  virtualResourcesKeypath: PropTypes.array,
  setEditModeLayout: PropTypes.func,
  deleteEditModeWidget: PropTypes.func,
  isMovingWidgets: PropTypes.bool,
  thingType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  viewMode: PropTypes.string,
  params: PropTypes.object,
  subthing: PropTypes.string,
  dispatch: PropTypes.func,
  type: PropTypes.oneOf(["collection", "detail"]),
  observationPeriod: PropTypes.object,
  gridLayouts: PropTypes.object
};

Dashboard.defaultProps = {
  edit: true
};

export default Dashboard;
