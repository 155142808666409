import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { makeStyles } from "@material-ui/core/styles";
import DashboardContainer from "components/dashboard/dashboard_container";
import { CreateDataExportDialog } from "components/data_export/create_data_export";
import { SpinnerWhileLoading } from "components/notification";
import { authSelectors } from "ducks/auth";
import {
  userCanCreateDashboard,
  userCanUpdateDashboard
} from "ducks/dashboard/dashboard_selectors";
import { userCanCreateThings } from "ducks/things_list/things_list_selectors";
import { setViewMode, thingTypeSelectors } from "ducks/thing_types";
import { ALL_THING_TYPES, GET_THING_TYPE } from "graphql/queries";
import { branchModal, withModalHandlers } from "hocs/modal_hocs";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { compose, withHandlers, withPropsOnChange } from "react-recompose";
import ThingCreateModal from "routes/main/things/modals/create_things";
import CreateThingTypeModal from "routes/main/things/modals/create_thing_type";
import withRouteLeaveViewModeModalHoc from "routes/main/things/modals/route_leave_view_mode_modal_hoc";
import { allThingsUrl } from "utils/route_utils";
import ThingTypeHeader from "./thing_type_header";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0)
  }
}));

const removeThingType = gql`
  mutation removeThingType($id: ID!) {
    removeThingType(id: $id) {
      id
    }
  }
`;

const ThingTypeModal = branchModal("CreateThingType", CreateThingTypeModal);
const ThingModal = branchModal("Thing", ThingCreateModal);
const CreateDataExportModal = branchModal("DataExport", CreateDataExportDialog);

const ThingTypeOverviewContainer = props => {
  const classes = useStyles();
  const {
    thingType,
    userCanEditThingType,
    userCanCreateThings,
    handleOpenEditThingType,
    onDeleteThingType,
    openModalDataExport
  } = props;
  if (!thingType) return null;

  const _handleCreateThing = () => {
    return () => {
      const { openModalThing } = props;
      openModalThing();
    };
  };

  return (
    <div
      className={classes.root}
      data-test={`page-thing-type__${thingType.label}`}
    >
      <Helmet title={thingType.label} />
      <ThingTypeHeader
        label={thingType.label}
        userCanCreateThings={userCanCreateThings}
        userCanEditThingType={userCanEditThingType}
        onEditThingType={handleOpenEditThingType}
        onDeleteThingType={onDeleteThingType}
        onCreateThing={_handleCreateThing()}
        openModalDataExport={openModalDataExport}
        thingTypeId={thingType.id}
      />

      <DashboardContainer
        {...props}
        type="collection"
        thingTypeId={thingType.id}
        setViewMode={setViewMode}
        filterId={props.filterId}
      />

      <CreateDataExportModal thingTypeId={thingType.id} {...props} />
      <ThingModal {...props} />
      <ThingTypeModal {...props} onCancel={props.onCancelEditThingType} />
    </div>
  );
};

ThingTypeOverviewContainer.propTypes = {
  dispatch: PropTypes.func,
  router: PropTypes.object,
  thingType: PropTypes.object,
  route: PropTypes.object,
  children: PropTypes.object,
  loggedInUser: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  routes: PropTypes.array,
  setIsMovingWidgets: PropTypes.func,
  restoreUndoWidgets: PropTypes.func,
  isMovingWidgets: PropTypes.bool,
  openModalThing: PropTypes.func.isRequired,
  closeModalThing: PropTypes.func.isRequired,
  onCancelEditThingType: PropTypes.func.isRequired,
  handleOpenEditThingType: PropTypes.func.isRequired,
  userCanCreateThingType: PropTypes.bool,
  userCanEditThingType: PropTypes.bool.isRequired,
  userCanCreateThings: PropTypes.bool.isRequired,
  onDeleteThingType: PropTypes.func.isRequired,
  widgets: PropTypes.array,
  gridLayouts: PropTypes.object,
  dashboardIsLoading: PropTypes.bool,
  dashboardViewMode: PropTypes.object,
  dashboardViewModes: PropTypes.array,
  setEditModeLayout: PropTypes.func,
  addEditModeWidget: PropTypes.func,
  updateEditModeWidget: PropTypes.func,
  deleteEditModeWidget: PropTypes.func,
  dashboardId: PropTypes.string,
  viewModeId: PropTypes.string,
  openModalDataExport: PropTypes.func,
  setEditModeWidgetsAndLayout: PropTypes.func,
  gridId: PropTypes.string,
  gridType: PropTypes.string,
  editModeWidgets: PropTypes.object,
  editModeLayout: PropTypes.object,
  gridLayout: PropTypes.object,
  classes: PropTypes.object,
  filterId: PropTypes.string,
  modalStateDataExport: PropTypes.object
};

const makeMapStateToProps = () => (state, { params }) => {
  const hasUndoWidgets = thingTypeSelectors.hasUndoWidgets(state);
  return {
    hasUndoWidgets,
    params,
    thingTypeId: params.thingType,
    userDomain: authSelectors.userDomainSelector(state),
    userCanEditThingType: thingTypeSelectors.userCanEditThingType(state),
    userCanCreateThingType: thingTypeSelectors.userCanCreateThingType(state),
    userCanCreateDashboard: userCanCreateDashboard(state),
    userCanUpdateDashboard: userCanUpdateDashboard(state),
    userCanCreateThings: userCanCreateThings(state),
    filterId: `thingType-${params.thingType}`,
    editingViewMode: state.thingTypes.getIn([
      "editingThingType",
      "viewModes",
      params.viewMode
    ]),
    editingThingType: state.thingTypes.getIn(["editingThingType"]),
    location,
    thingType: state.thingTypes.get("editingThingType")
  };
};

export default compose(
  connect(makeMapStateToProps, dispatch => ({
    dispatch,
    setViewMode: (...args) => dispatch(setViewMode(...args))
  })),
  graphql(GET_THING_TYPE, { name: "getThingTypeQuery" }),
  withPropsOnChange(["getThingTypeQuery"], props => ({
    thingType: R.path(["getThingTypeQuery", "thingType"], props)
  })),

  withModalHandlers({
    name: "Thing"
  }),

  withModalHandlers({
    name: "DataExport",
    modal: CreateDataExportDialog
  }),
  withModalHandlers({
    name: "CreateThingType",
    modal: CreateThingTypeModal
  }),
  graphql(removeThingType, { name: "removeThingType" }),
  withHandlers({
    onDeleteThingType: ({ removeThingType, router }) => thingTypeId => {
      removeThingType({
        variables: {
          id: thingTypeId
        },
        refetchQueries: () => [{ query: ALL_THING_TYPES }],
        update: () => router.push({ pathname: allThingsUrl() })
      });
    },
    handleOpenEditThingType: ({ openModalCreateThingType }) => thingTypeId =>
      openModalCreateThingType({ thingTypeId }),
    onCancelEditThingType: ({ closeModalCreateThingType }) => () =>
      closeModalCreateThingType()
  }),
  withRouteLeaveViewModeModalHoc,
  SpinnerWhileLoading(({ isLoadingThingType }) => !isLoadingThingType)
)(ThingTypeOverviewContainer);
