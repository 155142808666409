import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Provider } from "react-redux";
import { browserHistory, Router } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import { ThemeProvider } from "themes";
import GlobalStyle from "themes/global_styles";
import getApolloClient from "./graphql/init";
import createRoutes from "./routes";
import configureStore from "./store/configureStore";

const store = configureStore({}, browserHistory);
const routes = createRoutes(store);
const history = syncHistoryWithStore(browserHistory, store);

const App = () => (
  <ApolloProvider client={getApolloClient()}>
    <Provider store={store}>
      <ThemeProvider>
        <GlobalStyle />
        <Router history={history} routes={routes} />
      </ThemeProvider>
    </Provider>
  </ApolloProvider>
);

export default { App, store };
