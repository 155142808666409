import React from "react";
import PropTypes from "prop-types";
import { compose, mapProps, pure } from "react-recompose";
import { Map, List } from "immutable";
import Resource from "./resource";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import * as colors from "themes/colors";

const iconCss = { fill: colors.colorDustyGrayApprox, padding: "0 10px 0 0" };

const Resources = ({
  setResourceFilter,
  addResourceFilter,
  removeResourceFilter,
  filter,
  thingTypeId
}) => {
  const showAddIcon =
    (filter.last() || Map()).get("hasSelectedResource") === true;
  const items = filter.map((item, index) => {
    return (
      <Resource
        key={item.get("id")}
        index={index}
        hasSelectedResource={item.get("hasSelectedResource")}
        value={item.get("value")}
        operator={item.get("operator")}
        name={item.get("name")}
        label={item.get("label")}
        setResourceFilter={setResourceFilter}
        removeResourceFilter={removeResourceFilter}
        addResourceFilter={addResourceFilter}
        thingTypeId={thingTypeId}
      />
    );
  });
  return (
    <div>
      {items}
      <div style={{ display: "inline-block", verticalAlign: "middle" }}>
        {showAddIcon && <AddIcon style={iconCss} onClick={addResourceFilter} />}
      </div>
    </div>
  );
};

Resources.propTypes = {
  setResourceFilter: PropTypes.func,
  removeResourceFilter: PropTypes.func,
  addResourceFilter: PropTypes.func,
  filter: PropTypes.object,
  thingTypeId: PropTypes.string
};

Resources.defaultProps = {
  filter: List(),
  setResourceFilter: () => {},
  removeResourceFilter: () => {}
};

export default compose(
  mapProps(props => ({
    ...props
  })),
  pure
)(Resources);
