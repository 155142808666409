import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { inputElasticThingsFilterSelector } from "ducks/filter/filter_selectors";
import { fromJS, List } from "immutable";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, pure, withPropsOnChange } from "react-recompose";
import { resourceStatePath, withMarkerColors } from "utils/widget_utils";

const thingStateGeoAggregationQuery = gql`
  query($filter: InputListThingsFilter!, $resources: [String!]) {
    thingStateGeoAggregation(
      searchOptions: { filter: $filter, resources: $resources }
      precision: 1
    ) {
      buckets {
        key
        count
        values {
          lat
          lon
          thingName
          thingType
          resources
        }
      }
    }
  }
`;

const getStateResourcePathFromProps = props => {
  if (!R.path(["widget", "props", "conditions", "resourceId"])(props)) {
    return null;
  }

  return resourceStatePath(
    props.params && props.params.subthing ? props.params.subthing : null,
    props.widget.props.conditions.resourceId
  );
};

const THING_STATE_GEO_AGGREGATION_BUCKETS_PATH = [
  "thingStateGeoAggregationQuery",
  "thingStateGeoAggregation",
  "buckets"
];

const mapStateToProps = (state, props) => {
  const _resourcePath = getStateResourcePathFromProps(props);
  return {
    ...props,
    filter: inputElasticThingsFilterSelector(state, props),
    resources: _resourcePath ? [_resourcePath] : null
  };
};

const valueToDataPoint = (
  { lat, lon, thingName, thingType, resources },
  props
) => ({
  latLng: [lat, lon],
  properties: {
    thingName,
    thingType,
    conditionValue: resources[getStateResourcePathFromProps(props)]
  }
});

const EMPTY_BUCKETS = List();

export const asMapData = (buckets, props) => {
  if (!buckets || buckets.length === 0) {
    return EMPTY_BUCKETS;
  }

  const dataPoints = buckets.reduce(
    (acc, bucket) =>
      acc.concat(bucket.values.map(value => valueToDataPoint(value, props))),
    []
  );

  return props.widget
    ? withMarkerColors(props.widget.props.conditions, fromJS(dataPoints))
    : fromJS(dataPoints);
};

const withThingStateGeoAggregation = compose(
  connect(mapStateToProps),
  graphql(thingStateGeoAggregationQuery, {
    name: "thingStateGeoAggregationQuery"
  }),
  withPropsOnChange(
    (props, nextProps) =>
      R.path(THING_STATE_GEO_AGGREGATION_BUCKETS_PATH, props) !==
        R.path(THING_STATE_GEO_AGGREGATION_BUCKETS_PATH, nextProps) ||
      props.widget !== nextProps.widget,
    props => ({
      data: asMapData(
        R.path(THING_STATE_GEO_AGGREGATION_BUCKETS_PATH, props),
        props
      )
    })
  ),
  pure
);

export default withThingStateGeoAggregation;
