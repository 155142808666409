/* eslint-disable react/no-find-dom-node */
import React from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import { compose } from "react-recompose";

const itemSource = {
  canDrag({ canDrag }) {
    return canDrag === undefined ? true : canDrag;
  },
  beginDrag({ id, index }) {
    return { id, index };
  }
};

const itemTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveItem(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const DraggableItem = props => {
  const {
    checked,
    isDefault,
    onDeleteClicked,
    onChecked,
    index,
    text,
    connectDragSource,
    connectDropTarget,
    id,
    ...rest
  } = props;
  const Template = props.template;
  return connectDragSource(
    connectDropTarget(
      <div>
        <Template
          isDefault={isDefault}
          onDeleteClicked={onDeleteClicked}
          onChecked={(proxy, checked) => onChecked({ value: checked, index })}
          checked={checked}
          text={text}
          index={index}
          id={id}
          {...rest}
        />
      </div>
    )
  );
};

DraggableItem.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  onChecked: PropTypes.func,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  canDrag: PropTypes.bool,
  id: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  template: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired
};

export default compose(
  DropTarget("ListItem", itemTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  })),
  DragSource("ListItem", itemSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }))
)(DraggableItem);
