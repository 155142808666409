import { invoke$ as lambdaInvoke$ } from "./invoke";

const DOMAIN_LAMBDA = "DomainLambda";

export const listInvoke$ = invoke$ => () =>
  invoke$(DOMAIN_LAMBDA, { action: "LIST" });

export const getInvoke$ = invoke$ => id =>
  invoke$(DOMAIN_LAMBDA, {
    action: "GET",
    attributes: {
      id,
      parentId: null,
      name: null,
      description: null,
      data: null,
      domainMetadata: null
    }
  });

export const createInvoke$ = invoke$ => attributes =>
  invoke$(DOMAIN_LAMBDA, { action: "CREATE", attributes });

export const updateInvoke$ = invoke$ => attributes =>
  invoke$(DOMAIN_LAMBDA, { action: "UPDATE", attributes });

export const removeInvoke$ = invoke$ => id =>
  invoke$(DOMAIN_LAMBDA, { action: "REMOVE", attributes: { id } });

export const themeInvoke$ = invoke$ => id =>
  invoke$(DOMAIN_LAMBDA, { action: "THEME", attributes: { id } });

export const list$ = listInvoke$(lambdaInvoke$);
export const get$ = getInvoke$(lambdaInvoke$);
export const create$ = createInvoke$(lambdaInvoke$);
export const update$ = updateInvoke$(lambdaInvoke$);
export const remove$ = removeInvoke$(lambdaInvoke$);
export const theme$ = themeInvoke$(lambdaInvoke$);
