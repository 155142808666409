import TablePagination from "@material-ui/core/TablePagination";
import OffsetPaginationFooter from "components/pagination_footer/offset_pagination_footer";
import PropTypes from "prop-types";
import React from "react";

const ThingsListPaginationFooter = ({
  isLoading,
  paging,
  onPage,
  thingType,
  widgetId,
  filter
}) => {
  const handleChangePage = (event, pageIndex) => {
    const page = pageIndex + 1;
    onPage({ page, filter, thingType, widgetId });
  };
  const handleChangeRowsPerPage = event => {
    const pageSize = event.target.value;
    onPage({ pageSize, filter, thingType, widgetId });
  };
  const pageIndex = paging.page - 1;
  return (
    paging.totalCount > 0 && (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={paging.totalCount}
        rowsPerPage={paging.pageSize}
        page={pageIndex}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={props => (
          <OffsetPaginationFooter isLoading={isLoading} {...props} />
        )}
      />
    )
  );
};
ThingsListPaginationFooter.defaultProps = {
  isLoading: false
};

ThingsListPaginationFooter.propTypes = {
  filter: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onPage: PropTypes.func.isRequired,
  paging: PropTypes.shape({
    end: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    start: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  thingType: PropTypes.string,
  widgetId: PropTypes.string.isRequired
};

export default ThingsListPaginationFooter;
