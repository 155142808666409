import Joi from "joi-browser";
import * as R from "ramda";
import { manifest } from "api/aws/manifest";

// Explains password regexp.
// (?=.*[A-Z]) use positive lookahead to verify password contains at least one upper case letter
// (?=.*[a-z]) use positive lookahead to verify password contains at least one lower case letter
// (?=.*\d) use positive lookahead to verify password contains at least one digit
// .{8,} make sure password is at least 8 characters long
const PASSWORD_REGEX = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}/;
const PASSWORD_MESSAGE =
  "!!Password min length 8 characters, at least 1 number, 1 lower case and 1 upper case letter.";

// If configValue is an empty string it will default to optional.
// Nil and undefined will default to required email.
export const presenceInManifest = (configKey, configValue, config) =>
  R.compose(
    R.ifElse(
      R.contains(configValue),
      R.always("required"),
      R.always("optional")
    ),
    R.split(","),
    R.defaultTo("email"),
    R.prop(configKey)
  )(config);

export const INVALID_PHONE_NUMBER =
  "!!Please enter your phone number starting with country code (e.g. +46 for Sweden) followed by 7-13 digits";
export const Password = Joi.object().keys({
  password: Joi.string()
    .regex(PASSWORD_REGEX)
    .required()
    .options({
      language: {
        string: { regex: { base: PASSWORD_MESSAGE } },
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  passwordConfirm: Joi.string()
    .regex(PASSWORD_REGEX)
    .valid(Joi.ref("password"))
    .required()
    .options({
      language: {
        any: {
          allowOnly:
            "!!Password confirmation must be the same as your password",
          required: "!!Required"
        },
        string: {
          regex: {
            base: "!!Password confirmation must be the same as your password"
          }
        }
      }
    }),
  code: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    })
});
export const NewPassword = Joi.object().keys({
  password: Joi.string()
    .regex(PASSWORD_REGEX)
    .required()
    .options({
      language: {
        string: { regex: { base: PASSWORD_MESSAGE } },
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  passwordConfirm: Joi.string()
    .regex(PASSWORD_REGEX)
    .valid(Joi.ref("password"))
    .required()
    .options({
      language: {
        any: {
          allowOnly:
            "!!Password confirmation must be the same as your password",
          required: "!!Required"
        },
        string: {
          regex: {
            base: "!!Password confirmation must be the same as your password"
          }
        }
      }
    })
});

export const PasswordAndUserName = Joi.object().keys({
  password: Joi.string()
    .regex(PASSWORD_REGEX)
    .required()
    .options({
      language: {
        string: { regex: { base: PASSWORD_MESSAGE } },
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  passwordConfirm: Joi.string()
    .regex(PASSWORD_REGEX)
    .valid(Joi.ref("password"))
    .required()
    .options({
      language: {
        any: {
          allowOnly:
            "!!Password confirmation must be the same as your password",
          required: "!!Required"
        },
        string: {
          regex: {
            base: "!!Password confirmation must be the same as your password"
          }
        }
      }
    }),
  code: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  userName: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    })
});

export const VerifySms = Joi.object().keys({
  code: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    })
});

export const VerifySmsWithUserName = Joi.object().keys({
  code: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  userName: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    })
});
const _userKeys = () => {
  return {
    userName: Joi.string()
      .trim()
      .min(3)
      .max(128)
      .required()
      .options({
        language: {
          string: {
            max: "!!Username can be at most 128 characters long",
            min: "!!Username needs to be at least 3 characters long"
          },
          any: { empty: "!!Not allowed to be empty", required: "!!Required" }
        }
      }),
    firstName: Joi.string()
      .trim()
      .required()
      .options({
        language: {
          any: {
            empty: "!!Not allowed to be empty",
            required: "!!Required"
          }
        }
      }),
    lastName: Joi.string()
      .trim()
      .required()
      .options({
        language: {
          any: {
            empty: "!!Not allowed to be empty",
            required: "!!Required"
          }
        }
      }),
    email: Joi.string()
      .email()
      .empty("")
      .allow(null)
      .options({
        presence: presenceInManifest(
          "SignUpVerificationMedium",
          "email",
          manifest
        ),
        language: {
          any: {
            empty: "!!Not allowed to be empty",
            required: "!!Required"
          },
          string: { email: "!!Invalid email address" }
        }
      }),
    phone: Joi.string()
      .regex(/^\s*\+(?:[\s-]*\d){8,15}\s*$/)
      .empty("")
      .allow(null)
      .options({
        presence: presenceInManifest(
          "SignUpVerificationMedium",
          "phone_number",
          manifest
        ),
        language: {
          any: {
            required: "!!Required"
          },
          string: { regex: { base: INVALID_PHONE_NUMBER } }
        }
      }),
    // domainName: Joi.string().required().options({language: {any: {empty: '!!Not allowed to be empty', required: '!!Required'}}}),
    // roleName: Joi.string().required().options({language: {any: {empty: '!!Not allowed to be empty', required: '!!Required'}}}),
    company: Joi.string()
      .empty("")
      .allow(null)
      .trim(),
    address: Joi.string()
      .empty("")
      .allow(null)
      .trim(),
    zip: Joi.string()
      .empty("")
      .allow(null)
      .trim(),
    city: Joi.string()
      .empty("")
      .allow(null)
      .trim(),
    country: Joi.string()
      .empty("")
      .allow(null)
      .trim()
  };
};

export const User = Joi.object().keys(_userKeys());

export const generateSignupSchema = () =>
  Joi.object()
    .keys(_userKeys())
    .concat(NewPassword);

const withDomain = Joi.object().keys({
  domainName: Joi.string()
    .required()
    .options({
      language: {
        any: {
          empty: "!!Not allowed to be empty",
          required: "!!Required"
        }
      }
    })
});

export const generateUserWithDomainSchema = () =>
  Joi.object()
    .keys(_userKeys())
    .concat(withDomain);
