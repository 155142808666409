import { css } from "styled-components";
import * as colors from "themes/colors";

export default css`
  .react-grid-layout {
    position: relative;
  }

  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;

    &.cssTransforms {
      transition-property: transform;
    }

    &.react-grid-item {
      z-index: 1;
    }

    &.react-draggable-dragging {
      transition: none;
      z-index: 3;
    }

    &.resizing {
      opacity: 0.9;
    }

    &.static {
      background: ${colors.colorPeriwinkleGrayApprox};
    }

    .text {
      font-size: 24px;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 24px;
    }

    .minMax {
      font-size: 12px;
    }

    .add {
      cursor: pointer;
    }

    &:not(.react-grid-placeholder) {
      background: ${colors.colorCelesteApprox};
    }
  }

  .react-grid-placeholder {
    background: ${colors.colorCuriousBlueApprox};
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    user-select: none;
  }

  .react-resizable {
    position: relative;
  }

  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
    /* Since this handle is absolutely positioned, we don't want the draggable transforms to actually move it */
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
  }

  body {
    padding: 0;
  }

  #content {
    width: 100%;
  }

  .layoutJSON {
    background: ${colors.colorAltoApprox};
    margin-top: 10px;
    padding: 10px;
  }

  .columns {
    columns: 120px;
  }
`;
