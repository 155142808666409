import { fromJS, Map } from "immutable";
import {
  filter,
  head,
  ifElse,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  assocPath,
  is,
  isNil,
  isEmpty,
  either,
  last,
  values
} from "ramda";
import { nonEmptyString } from "utils/general_utils";
import { getParentThingNameFromThingShadow } from "utils/thing_utils";

export let _unixTimeStampCheck = timestamp =>
  String(timestamp).length > 10 ? Math.floor(timestamp / 1000) : timestamp;

export const _getLatestTimestamp = value => {
  let reported = value && value.reported ? value.reported.timestamp : 0;
  reported = reported ? reported : 0;
  const latest =
    reported === 0 || reported === undefined ? new Date().getTime() : reported;
  return _unixTimeStampCheck(latest);
};

const _newValue = (
  value,
  id,
  thingPath,
  actualShadow,
  subThingName,
  subThingType
) => {
  const timestamp = _getLatestTimestamp(value);
  let actualValue;
  const desiredValue = path(["desired", "val"], value);
  if (actualShadow) {
    actualValue = actualShadow.getIn(["resources", id], null);
    if (actualValue) {
      if (desiredValue === null) {
        actualValue = actualValue.set("pendingValue", null);
        actualValue = actualValue.set("pending", false);
      } else if (desiredValue !== undefined) {
        actualValue = actualValue.set("pendingValue", desiredValue);
        actualValue = actualValue.set("pending", true);
      }
    }
  }
  let resource = actualValue
    ? actualValue
    : new Map({
        id,
        thingPath,
        subThingName,
        subThingType,
        pendingValue: desiredValue,
        pending: !isNil(desiredValue)
      });
  let reportedValue = value.reported ? value.reported.val : undefined;
  if (reportedValue !== undefined) {
    resource = resource.setIn(["latestValue", "value"], reportedValue);
    // set timestamp
    resource = resource.setIn(["latestValue", "timestamp"], timestamp);
  }
  return resource;
};

function _updateLastHeardFrom(thingShadow, imValue) {
  if (String(imValue.getIn(["thingPath"])) === "tcxn/connection_status") {
    return thingShadow;
  }
  const keyPath = ["lastHeardFrom"];
  const lastHeardFrom = Number(thingShadow.getIn(keyPath, 0));
  const timestamp = Number(
    _unixTimeStampCheck(imValue.getIn(["latestValue", "timestamp"]))
  );
  if (timestamp > lastHeardFrom) {
    thingShadow = thingShadow.setIn(keyPath, timestamp);
  }
  return thingShadow;
}

export const _extractTypeFromActualShadow = (
  actualShadow,
  type,
  key,
  nodeKey
) => {
  if (actualShadow && !type && type !== "untyped") {
    const resources = actualShadow
      ? actualShadow.getIn(["resources"], Map())
      : Map();
    const resource = resources.find(
      (elemValue, elemKey) =>
        elemKey.indexOf(`/${key}`) > 0 && elemKey.indexOf(`/${nodeKey}`) > 0
    );
    type = resource ? resource.get("subThingType") : undefined;
  }
  return type ? type : "untyped";
};

export const flatten = (node, mapFn, acc = {}, path = []) => {
  if (!node) return acc;
  Object.keys(node).forEach(key => {
    const val = node[key];
    if (is(Object, val)) {
      path = [...path, key];
      acc = flatten(val, mapFn, acc, path);
      path = [...path.slice(0, -1)];
    } else {
      acc = mapFn(node, key, val, last(path), [...path, key], acc);
    }
  });
  return acc;
};

const flattenShadow = (setKeyPath, actualShadow) => (
  node,
  key,
  val,
  nodeKey,
  path,
  acc
) => {
  const isTimestamp = path.indexOf("timestamp") > -1;
  const isType = path.indexOf("type") > -1;
  const isTcxn = path.indexOf("tcxn") > -1;
  const isDomain = path.indexOf("domain") > -1;
  const isSubthing = path.length > 1 && !isTcxn && !isDomain && !isTimestamp;
  if (isTimestamp || isType) {
    path.pop();
  }
  const keyPath = path.join("/");
  if (isSubthing && !isType) {
    // if we just get a message then the type can be ommitted.
    // as a result we need to check the original current normalized shadow to get the type.
    const type = _extractTypeFromActualShadow(
      actualShadow,
      node.type,
      key,
      nodeKey
    );
    acc = assocPath([keyPath, "isSubthing"], isSubthing, acc);
    acc = assocPath([keyPath, "subThingType"], type, acc);
    acc = assocPath([keyPath, "subThingName"], path[0], acc);
  }
  if (!isType) {
    if (pathOr(undefined, [keyPath, "key"], acc) === undefined) {
      acc = assocPath([keyPath, "key"], key, acc);
      acc = assocPath([keyPath, "path"], path, acc);
    }
    acc = assocPath([keyPath, ...setKeyPath], val, acc);
  }
  return acc;
};

export const _mergeReportedAndDesiredAndTimestamp = (
  thingShadow,
  actualShadow
) => {
  if (!thingShadow || !thingShadow.shadow) return;
  let merged = flatten(
    thingShadow.shadow.state.desired,
    flattenShadow(["desired", "val"], actualShadow)
  );
  merged = flatten(
    thingShadow.shadow.state.reported,
    flattenShadow(["reported", "val"], actualShadow),
    merged
  );
  if (thingShadow.shadow.metadata) {
    merged = flatten(
      thingShadow.shadow.metadata.reported,
      flattenShadow(["reported", "timestamp"]),
      merged
    );
    merged = flatten(
      thingShadow.shadow.metadata.desired,
      flattenShadow(["desired", "timestamp"]),
      merged
    );
  }
  return merged;
};

const _isNullOrEmpty = either(isNil, isEmpty);

const _hasState = thingShadow =>
  thingShadow &&
  thingShadow.shadow &&
  !_isNullOrEmpty(thingShadow.shadow.state);

const _shouldClearDesired = thingShadow =>
  _hasState(thingShadow) &&
  thingShadow.shadow.state.hasOwnProperty("desired") &&
  _isNullOrEmpty(thingShadow.shadow.state.desired);

const _getResources = normalizedThingShadow =>
  normalizedThingShadow.getIn(["resources"], Map());

export const _clearPending = normalizedThingShadow => {
  const resources = _getResources(normalizedThingShadow);
  return resources.reduce((normalizedThingShadow, resource, resourceKey) => {
    resource = resource.set("pendingValue", undefined);
    resource = resource.set("pending", false);
    return normalizedThingShadow.setIn(["resources", resourceKey], resource);
  }, normalizedThingShadow);
};

export const _clearDesired = (thingShadow, normalizedThingShadow = Map({})) => {
  if (_shouldClearDesired(thingShadow) && normalizedThingShadow.size > 0) {
    normalizedThingShadow = _clearPending(normalizedThingShadow);
  }
  return normalizedThingShadow;
};

export const _addSubThingResource = ({
  val,
  normalizedThingShadow,
  thingShadow
}) => {
  const { subThingName, subThingType, key } = val;
  const { thingName, thingType } = thingShadow;
  const resourceIdString = [
    thingName,
    subThingName,
    thingType,
    "subthing",
    subThingType,
    key
  ].join("/");
  const subthingIdString = [
    thingName,
    subThingName,
    thingType,
    "subthing",
    subThingType
  ].join("/");

  const shadowKeyPathString = val.path.join("/");
  const imValue = _newValue(
    val,
    resourceIdString,
    shadowKeyPathString,
    normalizedThingShadow,
    subThingName,
    subThingType
  );
  normalizedThingShadow = _updateLastHeardFrom(normalizedThingShadow, imValue);
  // subthings by type
  normalizedThingShadow = normalizedThingShadow.setIn(
    ["subThingTypes", subThingType, resourceIdString],
    resourceIdString
  );
  // subthings by name
  normalizedThingShadow = normalizedThingShadow.setIn(
    ["subthings", subthingIdString, resourceIdString],
    resourceIdString
  );
  return normalizedThingShadow.setIn(["resources", resourceIdString], imValue);
};

export const _addThingResource = ({
  val,
  normalizedThingShadow,
  thingShadow
}) => {
  const { thingName, thingType } = thingShadow;
  const resourceIdString = [thingName, thingType, ...val.path].join("/");
  const shadowKeyPathString = val.path.join("/");
  const imValue = _newValue(
    val,
    resourceIdString,
    shadowKeyPathString,
    normalizedThingShadow
  );
  normalizedThingShadow = _updateLastHeardFrom(normalizedThingShadow, imValue);
  return normalizedThingShadow.setIn(["resources", resourceIdString], imValue);
};

const _normalizeShadow = (
  flattenedShadow,
  actualShadow = Map(),
  thingShadow
) => {
  const newShadow = values(flattenedShadow).reduce((acc, val) => {
    if (val.isSubthing) {
      return _addSubThingResource({
        val,
        normalizedThingShadow: acc,
        thingShadow
      });
    } else {
      return _addThingResource({
        val,
        normalizedThingShadow: acc,
        thingShadow
      });
    }
  }, actualShadow);

  return newShadow;
};

function _adaptThing(thingShadow, actualShadow) {
  if (!_hasState(thingShadow)) return;
  // the state can just come in as desired
  const flattenedShadow = _mergeReportedAndDesiredAndTimestamp(
    thingShadow,
    actualShadow
  );
  let normalizedThingShadow = _normalizeShadow(
    flattenedShadow,
    actualShadow,
    thingShadow
  );
  if (_shouldClearDesired(thingShadow)) {
    normalizedThingShadow = _clearDesired(thingShadow, normalizedThingShadow);
  }

  return normalizedThingShadow;
}

export function adaptThingShadow(thingShadow, actualShadow = Map()) {
  if (!thingShadow) return thingShadow;
  let adapted = _adaptThing(thingShadow, actualShadow);

  adapted = adapted ? adapted : Map({ thingName: {} });
  if (
    thingShadow.shadow &&
    thingShadow.shadow.state &&
    thingShadow.shadow.state.reported
  ) {
    adapted = adapted.setIn(
      ["tcxn"],
      fromJS(thingShadow.shadow.state.reported.tcxn)
    );
  }

  if (thingShadow.label) {
    adapted = adapted.setIn(["id"], thingShadow.thingName);
    adapted = adapted.setIn(["thingName"], thingShadow.thingName);
    adapted = adapted.setIn(["description"], thingShadow.description);
    adapted = adapted.setIn(["createdAt"], thingShadow.createdAt);
    adapted = adapted.setIn(["createdBy"], thingShadow.createdBy);
    adapted = adapted.setIn(["label"], thingShadow.label);
    adapted = adapted.setIn(["thingType"], thingShadow.thingType);
    adapted = adapted.setIn(["domain"], fromJS(thingShadow.domain));
    adapted = adapted.setIn(["batchId"], thingShadow.batchId);
    adapted = adapted.setIn(["simulated"], thingShadow.simulated);
    adapted = adapted.setIn(["domainTopic"], thingShadow.domainTopic);
    adapted = adapted.setIn(
      ["hasNetworkedThings"],
      thingShadow.hasNetworkedThings
    );
    adapted = adapted.setIn(["parentThingName"], thingShadow.parentThingName);
    const parentThingName = getParentThingNameFromThingShadow(thingShadow);
    adapted = adapted.setIn(["networkedThingsConnection"], {
      parentThing: {
        thingName: parentThingName,
        label: parentThingName,
        thingType: pipe(
          pathOr([], ["networkedThingsConnection", "things"]),
          filter(propEq("thingName", parentThingName)),
          head,
          prop("thingType")
        )(thingShadow)
      },
      subThings: ifElse(
        nonEmptyString,
        () =>
          filter(
            subThing => subThing.thingName !== parentThingName,
            pathOr([], ["networkedThingsConnection", "things"])(thingShadow)
          ),
        () => path(["networkedThingsConnection", "things"], thingShadow)
      )(parentThingName)
    });
  }
  return adapted;
}
