import * as R from "ramda";
import { compose, withStateHandlers, withPropsOnChange } from "react-recompose";
import simulatorDataTypes, {
  resourceSpecificTypes,
  isSpecificResourceOrTypeSupported,
  supportedGenerators
} from "ducks/simulator/simulator_data_types";
import {
  TYPE_GEOPOINT,
  TYPE_LONG,
  TYPE_DOUBLE,
  TYPE_KEYWORD
} from "ducks/simulator/strategy_parameter_editors/types";
import PayloadConfigEditorComponent from "./payload_config_editor_component";

const resourceStatePathFromResource = resource => resource.name;
const defaultStrategy = resource =>
  R.pathOr(
    R.path([resource.type, "defaultStrategy"], simulatorDataTypes),
    [resource.name, resource.type, "defaultStrategy"],
    resourceSpecificTypes
  )(resource);

export const availableTypesForConfig = path =>
  isSpecificResourceOrTypeSupported(null, path)
    ? R.keys(resourceSpecificTypes[path])
    : [TYPE_LONG, TYPE_DOUBLE, TYPE_GEOPOINT, TYPE_KEYWORD];

export const isValidResourceStatePath = resourceStatePath =>
  !!(resourceStatePath || "").trim();

export const getDefaultConfigForResource = resource => ({
  enabled: true,
  resourceStatePath: resourceStatePathFromResource(resource),
  desiredDataType: resource.type,
  hasResource: true,
  ...defaultStrategy(resource)
});

export const getDefaultConfig = (desiredDataType = "long") => ({
  enabled: true,
  resourceStatePath: "",
  desiredDataType,
  hasResource: false,
  ...defaultStrategy({ type: desiredDataType, name: "" })
});

const mergeErrors = (errors, error, index, parameterName) => {
  const errorPath = [String(index), parameterName];
  return error
    ? R.set(R.lensPath(errorPath), error, errors)
    : R.pipe(
        R.dissocPath(errorPath),
        R.when(R.propSatisfies(R.isEmpty, index), R.dissoc(index))
      )(errors);
};

const updatePayloadConfig = (
  { errors, payloadConfig, onPayloadConfigChange },
  path,
  value,
  updatedErrors
) => {
  const updatedPayloadConfig = R.set(R.lensPath(path), value, payloadConfig);
  onPayloadConfigChange(updatedPayloadConfig, updatedErrors || errors);
};

export const onPeriodValueChange = (state, props) => evt => {
  const value =
    parseInt(evt.target.value, 10) ||
    R.pathOr(0, ["payloadConfig", "period", "value"])(props);

  updatePayloadConfig(props, ["period", "value"], value);
  return state;
};

export const onPeriodUnitChange = (state, props) => evt => {
  const unit = evt.target.value;
  updatePayloadConfig(props, ["period", "unit"], unit);
  return state;
};

export const onEnabledChange = (state, props) => (evt, index, enabled) => {
  updatePayloadConfig(props, ["resourceConfig", index, "enabled"], enabled);
  return state;
};

export const onAddNewConfig = (state, props) => (evt, resource) => {
  const config = resource
    ? getDefaultConfigForResource(resource)
    : getDefaultConfig();
  props.onPayloadConfigChange(
    R.over(R.lensProp("resourceConfig"), R.append(config), props.payloadConfig)
  );

  return state;
};

export const onResourceStatePathChange = (state, props) => (
  evt,
  index,
  newPath
) => {
  let error = isValidResourceStatePath(newPath)
    ? null
    : "A valid name is required";

  const pathExists = R.pipe(
    R.propOr([], "resources"),
    R.find(R.propEq("name", newPath)),
    R.complement(R.isNil)
  )(props);

  error = pathExists && !error ? "Resource already exists" : error;

  const dataType = R.path(
    ["payloadConfig", "resourceConfig", index, "desiredDataType"],
    props
  );
  const isGeoPointType = R.equals(dataType, TYPE_GEOPOINT);
  const isLatLngName = R.not(R.isEmpty(R.match(/(.+\/|^)latlng$/, newPath)));

  error =
    isGeoPointType && !isLatLngName && !error ? "Must be named latlng" : error;

  const mergedErrors = mergeErrors(
    props.errors,
    error,
    index,
    "resourceStatePath"
  );

  const availablesTypes = availableTypesForConfig(newPath);

  if (!R.includes(dataType, availablesTypes)) {
    updatePayloadConfig(
      props,
      ["resourceConfig", index],
      R.mergeRight(R.path(["payloadConfig", "resourceConfig", index], props), {
        resourceStatePath: newPath.trim(),
        desiredDataType: availablesTypes[0],
        ...R.pick(
          ["strategyParameters", "strategyType"],
          getDefaultConfigForResource({
            type: availablesTypes[0],
            name: newPath.trim()
          })
        )
      }),
      mergedErrors
    );
  } else {
    updatePayloadConfig(
      props,
      ["resourceConfig", index, "resourceStatePath"],
      newPath.trim(),
      mergedErrors
    );
  }

  return state;
};

const latlngIfGeoPoint = (dataTypePath, resourceStatePath) =>
  R.when(
    r =>
      R.and(
        R.pathEq(dataTypePath, TYPE_GEOPOINT, r),
        R.pathSatisfies(R.isEmpty, resourceStatePath, r)
      ),
    R.set(R.lensPath(resourceStatePath), "latlng")
  );

export const onDesiredDataTypeChange = (state, props) => (
  evt,
  index,
  dataType
) => {
  if (!isSpecificResourceOrTypeSupported(dataType)) {
    return state;
  }

  const strategies = supportedGenerators(dataType);

  const resourceStatePath = ["resourceConfig", index, "resourceStatePath"];
  const dataTypePath = ["resourceConfig", index, "desiredDataType"];
  const currentStrategy = R.path(
    ["resourceConfig", index, "strategyType"],
    props.payloadConfig
  );

  const paramsPath = ["resourceConfig", index, "strategyParameters"];
  const strategyDefaults = defaultStrategy({ type: dataType });
  const strategyPath = ["resourceConfig", index, "strategyType"];

  if (!strategies.includes(currentStrategy)) {
    const updatedPayloadConfig = R.pipe(
      R.set(R.lensPath(dataTypePath), dataType),
      R.set(R.lensPath(strategyPath), strategies[0]),
      latlngIfGeoPoint(dataTypePath, resourceStatePath),
      R.over(
        R.lensPath(paramsPath),
        R.merge(strategyDefaults.strategyParameters)
      )
    )(props.payloadConfig);

    props.onPayloadConfigChange(updatedPayloadConfig);
    props.onResourceStatePathChange(
      evt,
      index,
      R.path(resourceStatePath, updatedPayloadConfig)
    );
    return state;
  }

  const currentStrategyParams = R.path(paramsPath, props.payloadConfig);

  const mergedParams = R.mergeDeepLeft(
    currentStrategyParams,
    strategyDefaults.strategyParameters
  );

  const updatedPayloadConfig = R.pipe(
    R.set(R.lensPath(dataTypePath), dataType),
    R.set(R.lensPath(paramsPath), mergedParams),
    latlngIfGeoPoint(dataTypePath, resourceStatePath)
  )(props.payloadConfig);

  props.onPayloadConfigChange(updatedPayloadConfig);
  props.onResourceStatePathChange(
    evt,
    index,
    R.path(resourceStatePath, updatedPayloadConfig)
  );
  return state;
};

export const onStrategyTypeChange = (state, props) => (
  evt,
  index,
  strategyType
) => {
  updatePayloadConfig(
    props,
    ["resourceConfig", index, "strategyType"],
    strategyType
  );
  return state;
};

export const onStrategyParameterChange = (state, props) => (
  evt,
  index,
  parameterName,
  parameterValue,
  error
) => {
  const errorsMerged = mergeErrors(props.errors, error, index, parameterName);
  updatePayloadConfig(
    props,
    ["resourceConfig", index, "strategyParameters", parameterName],
    parameterValue,
    errorsMerged
  );
};

export const onToggleCheckAllConfigs = (state, props) => evt => {
  const {
    resources,
    payloadConfig: { resourceConfig }
  } = props;

  const { configsWithResource } = groupConfigsByResource(
    resourceConfig,
    resources
  );

  const { checked } = evt.target;
  const newConfigs = checked
    ? configsWithResource.reduce((acc, current) => {
        if (
          !current.config &&
          isSpecificResourceOrTypeSupported(
            current.resource.type,
            current.resource.name
          )
        )
          acc.push(getDefaultConfigForResource(current.resource));
        return acc;
      }, [])
    : [];

  const newResourceConfig = R.pipe(
    R.concat(newConfigs),
    R.map(R.set(R.lensProp("enabled"), checked))
  )(resourceConfig);

  updatePayloadConfig(props, ["resourceConfig"], newResourceConfig);
};

export const shouldMapDerivedProps = (props, nextProps) =>
  props.resources !== nextProps.resources ||
  props.payloadConfig !== nextProps.payloadConfig;

const groupConfigsByResource = (resourceConfig, resources) => {
  const payloadAndIndexByStatePath = resourceConfig.reduce(
    (acc, config, index) => {
      if (config.hasResource) acc[config.resourceStatePath] = { index, config };
      return acc;
    },
    {}
  );

  const configsWithResource = R.pipe(
    R.map(resource => {
      const resourceStatePath = resourceStatePathFromResource(resource);
      const { index, config } =
        payloadAndIndexByStatePath[resourceStatePath] || {};

      delete payloadAndIndexByStatePath[resourceStatePath];

      return { index, config, resource };
    }),
    R.sortBy(R.path(["resource", "name"]))
  )(resources);

  const configsWithoutResource = R.pipe(
    R.addIndex(R.map)((config, index) => ({ config, index })),
    R.filter(({ config }) => !config.hasResource),
    R.sortBy(R.prop("index"))
  )(resourceConfig);

  return { configsWithResource, configsWithoutResource };
};

export const mapDerivedProps = ({
  enabledConfigsCount,
  payloadConfig,
  resources
}) => {
  const resourceConfig = payloadConfig.resourceConfig || [];

  const {
    configsWithoutResource,
    configsWithResource
  } = groupConfigsByResource(resourceConfig, resources);

  const allConfigsEnabled =
    enabledConfigsCount ===
    configsWithResource.length + configsWithoutResource.length;

  return {
    configsWithoutResource,
    configsWithResource,
    periodUnit: payloadConfig.period.unit,
    periodValue: payloadConfig.period.value,
    allConfigsEnabled
  };
};

const _withHandlers = withStateHandlers(
  {},
  {
    onPeriodValueChange,
    onPeriodUnitChange,
    onAddNewConfig,
    onEnabledChange,
    onResourceStatePathChange,
    onStrategyTypeChange,
    onStrategyParameterChange,
    onToggleCheckAllConfigs
  }
);
const _withHandlersWithHandlerDependencies = withStateHandlers(
  {},
  {
    onDesiredDataTypeChange
  }
);

const _withDerivedPropsOnChange = withPropsOnChange(
  shouldMapDerivedProps,
  mapDerivedProps
);

export default compose(
  _withHandlers,
  _withHandlersWithHandlerDependencies,
  _withDerivedPropsOnChange
)(PayloadConfigEditorComponent);
