import React from "react";
import PropTypes from "prop-types";
import { Map, List } from "immutable";
import { statusTexts } from "./online_offline";

const FilterTitle = ({
  defaultTitle = "Filter by",
  filter = Map(),
  onClearFilter = () => {},
  css = {}
}) => {
  const geo = filter.get("geo", List()).size;
  const numDomains = filter.getIn(["domainsDropDown", "ids"], List()).size;
  const numFreeTexts = filter.get("freeText", List()).filter(t => t.length > 0)
    .size;
  const numThingTypes = filter.getIn(["thingTypesDropDown", "ids"], List())
    .size;
  const thingStatus = filter.get("thingStatus", "-1");
  const numResources = filter
    .get("resources", List())
    .filter(
      r => r.get("hasSelectedResource") && r.get("name") && r.get("value")
    ).size;

  const collapsedTitleContent = [
    {
      condition: numResources > 0,
      prefix: ", ",
      text: `${numResources} ${
        numResources === 1 ? "resource type" : "resource types"
      }`
    },
    {
      condition: numFreeTexts > 0,
      prefix: ", ",
      text: `${numFreeTexts} ${numFreeTexts === 1 ? "free text" : "free texts"}`
    },
    {
      condition: numDomains > 0,
      prefix: " in ",
      text: `${numDomains} ${numDomains === 1 ? "domain" : "domains"}`
    },
    {
      condition: numThingTypes > 0,
      prefix: " for ",
      text: `${numThingTypes} ${
        numThingTypes === 1 ? "thing-type" : "thing-types"
      }`
    },
    {
      condition: thingStatus !== "-1",
      prefix: " with status ",
      text: statusTexts[thingStatus].text
    },
    { condition: geo > 0, prefix: ", ", text: "location active" }
  ]
    .filter(c => c.condition)
    .map((c, i) => {
      return c.prefix && i > 0 ? (
        <span key={i}>
          {c.prefix}
          <span style={css.primaryColor}>{c.text}</span>
        </span>
      ) : (
        <span key={i} style={css.primaryColor}>
          {c.text}
        </span>
      );
    });

  const collapsedTitle = (
    <span style={css.collapsedTitleWrapper}>
      Filtering
      <span style={css.collapsedTitle} data-test="filter-title-active">
        {collapsedTitleContent}
      </span>
      <span onClick={onClearFilter} style={css.clearFilter}>
        Clear filter
      </span>
    </span>
  );
  return collapsedTitleContent.length ? (
    collapsedTitle
  ) : (
    <span data-test="filter-title-default">{defaultTitle}</span>
  );
};

FilterTitle.propTypes = {
  defaultTitle: PropTypes.string,
  filter: PropTypes.object,
  onClearFilter: PropTypes.func,
  css: PropTypes.object
};

export default FilterTitle;
