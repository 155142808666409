import * as R from "ramda";
import { createAction, handleActions } from "redux-actions";

import {
  SUGGEST_RESOURCES,
  SUGGEST_THINGS,
  SUGGEST_THING_TYPES,
  SUGGEST_DOMAINS,
  SUGGEST_ALL
} from "ducks/suggester/suggester_selectors";

import {
  dropdownTransducer,
  optionsDecorator,
  filterOnQuery,
  filterOnThingType
} from "./suggester_global_selectors";

import { resourceSort, sortByCaseInsensitive } from "utils/sorters";

export const SUGGESTER_CLEAR = "SUGGESTER_CLEAR";
export const SUGGESTER_FETCH_ALL = "SUGGESTER_FETCH_ALL";
export const SUGGESTER_FETCH_WILDCARD = "SUGGESTER_FETCH_WILDCARD";
export const SUGGESTER_RESPONSE_SUCCESS = "SUGGESTER_RESPONSE_SUCCESS";
export const SUGGESTER_RESPONSE_ERROR = "SUGGESTER_RESPONSE_ERROR";

export const SUGGESTER_SEARCH_SUCCESS = "SUGGESTER_SEARCH_SUCCESS";
export const SUGGESTER_SEARCH_ERROR = "SUGGESTER_SEARCH_ERROR";

export const SUGGEST_RESOURCES_REQUEST = "SUGGEST_RESOURCES_REQUEST";
export const SUGGEST_RESOURCES_SUCCESS = "SUGGEST_RESOURCES_SUCCESS";
export const SUGGEST_RESOURCES_FAILURE = "SUGGEST_RESOURCES_FAILURE";
export const SUGGEST_THINGS_REQUEST = "SUGGEST_THINGS_REQUEST";
export const SUGGEST_THINGS_SUCCESS = "SUGGEST_THINGS_SUCCESS";
export const SUGGEST_THINGS_FAILURE = "SUGGEST_THINGS_FAILURE";
export const SUGGEST_THING_TYPES_REQUEST = "SUGGEST_THING_TYPES_REQUEST";
export const SUGGEST_THING_TYPES_SUCCESS = "SUGGEST_THING_TYPES_SUCCESS";
export const SUGGEST_THING_TYPES_FAILURE = "SUGGEST_THING_TYPES_FAILURE";
export const SUGGEST_DOMAINS_REQUEST = "SUGGEST_DOMAINS_REQUEST";
export const SUGGEST_DOMAINS_SUCCESS = "SUGGEST_DOMAINS_SUCCESS";
export const SUGGEST_DOMAINS_FAILURE = "SUGGEST_DOMAINS_FAILURE";
export const SUGGEST_USERS_REQUEST = "SUGGEST_USERS_REQUEST";
export const SUGGEST_USERS_SUCCESS = "SUGGEST_USERS_SUCCESS";
export const SUGGEST_USERS_FAILURE = "SUGGEST_USERS_FAILURE";

export const clearSuggestions = createAction(SUGGESTER_CLEAR);
export const fetchSuggestions = createAction(SUGGESTER_FETCH_ALL);

export const fetchSuggestionsByWildcard = (type, payload) => ({
  type: `${type}_REQUEST`,
  payload
});

export const fetchSuggestionsSuccess = createAction(SUGGESTER_RESPONSE_SUCCESS);
export const fetchDomainsSuccess = createAction(SUGGEST_DOMAINS_SUCCESS);
export const fetchThingsSuccess = createAction(SUGGEST_THINGS_SUCCESS);
export const fetchThingTypesSuccess = createAction(SUGGEST_THING_TYPES_SUCCESS);
export const fetchSuggestionsError = createAction(SUGGESTER_RESPONSE_ERROR);
export const fetchUsersSuccess = createAction(SUGGEST_USERS_SUCCESS);

export const fetchSuggestionsSearchSuccess = createAction(
  SUGGESTER_SEARCH_SUCCESS
);
export const fetchSuggestionsSearchError = createAction(SUGGESTER_SEARCH_ERROR);

export const fetchResourcesSearchSuccess = createAction(
  SUGGEST_RESOURCES_SUCCESS
);
const isResources = R.propEq("payload", SUGGEST_RESOURCES);
const isThing = R.propEq("payload", SUGGEST_THINGS);
const isThingTypes = R.propEq("payload", SUGGEST_THING_TYPES);
const isDomains = R.propEq("payload", SUGGEST_DOMAINS);
const isAll = R.propEq("payload", SUGGEST_ALL);
const isSuggester = R.anyPass([
  isResources,
  isThing,
  isThingTypes,
  isDomains,
  isAll
]);

export const initialState = {
  domains: null,
  resources: null,
  things: null,
  thingTypes: null,
  users: null
};

export default handleActions(
  {
    [SUGGESTER_SEARCH_SUCCESS]: (state, action) =>
      R.evolve(
        {
          domains: R.always(
            R.pipe(
              R.pathOr([], ["payload", "domains", "hits", "hits"]),
              R.map(
                R.pipe(
                  R.prop("_source"),
                  R.pickAll(["id", "label", "metadata"])
                )
              ),
              R.transduce(dropdownTransducer(), R.flip(R.append), [])
            )(action)
          )
        },
        state
      ),
    [SUGGEST_RESOURCES_SUCCESS]: (state, action) => {
      state = R.set(
        R.lensProp("resources"),
        R.pipe(
          R.ifElse(
            R.pathEq(["payload", "searchLambda"], true),
            R.pipe(
              R.pathOr([], ["payload", "resources", "hits", "hits"]),
              R.map(
                R.pipe(
                  R.prop("_source"),
                  R.pickAll(["id", "label", "path", "metadata"])
                )
              ),
              filterOnThingType(R.path(["payload", "thingType"], action))
            ),
            R.pathOr([], ["payload", "resources"])
          ),
          optionsDecorator(
            R.path(["payload", "suggesterOptionsDecorator"])(action)
          ),
          filterOnQuery(R.path(["payload", "query"], action)),
          R.transduce(
            dropdownTransducer(undefined, true),
            R.flip(R.append),
            []
          ),
          resourceSort
        )(action),
        state
      );
      return state;
    },
    [SUGGESTER_SEARCH_ERROR]: (state, action) => state,
    [SUGGESTER_RESPONSE_SUCCESS]: (state, action) => {
      const newState = R.evolve(
        {
          domains: R.always(
            R.pipe(
              R.pathOr([], ["payload", "domains"]),
              R.uniqBy(R.prop("id")),
              sortByCaseInsensitive("path")
            )(action)
          ),
          resources: R.always(
            R.pipe(
              R.pathOr([], ["payload", "resources"]),
              R.uniqBy(R.prop("id")),
              sortByCaseInsensitive("name")
            )(action)
          ),
          thingTypes: R.always(
            R.pipe(
              R.pathOr([], ["payload", "thingTypes"]),
              R.uniqBy(R.prop("id")),
              sortByCaseInsensitive("label")
            )(action)
          ),
          things: R.always(
            R.pipe(
              R.pathOr([], ["payload", "things"]),
              R.uniqBy(R.prop("thingName")),
              sortByCaseInsensitive("label")
            )(action)
          )
        },
        state
      );
      return newState;
    },
    [SUGGEST_DOMAINS_SUCCESS]: (state, action) => {
      const newState = R.evolve(
        {
          domains: R.always(
            R.pipe(
              R.pathOr([], ["payload", "domains"]),
              R.uniqBy(R.prop("id")),
              sortByCaseInsensitive("path")
            )(action)
          )
        },
        state
      );
      return newState;
    },
    [SUGGEST_THINGS_SUCCESS]: (state, action) => {
      const newState = R.evolve(
        {
          things: R.always(
            R.pipe(
              R.pathOr([], ["payload", "things"]),
              R.uniqBy(R.prop("thingName")),
              sortByCaseInsensitive("label")
            )(action)
          )
        },
        state
      );
      return newState;
    },
    [SUGGEST_USERS_SUCCESS]: (state, action) => {
      const newState = R.evolve(
        {
          users: R.always(R.pipe(R.pathOr([], ["payload", "users"]))(action))
        },
        state
      );
      return newState;
    },
    [SUGGEST_THING_TYPES_SUCCESS]: (state, action) => {
      const newState = R.evolve(
        {
          thingTypes: R.always(
            R.pipe(
              R.pathOr([], ["payload", "thingTypes"]),
              R.uniqBy(R.prop("id")),
              sortByCaseInsensitive("label")
            )(action)
          )
        },
        state
      );
      return newState;
    },
    [SUGGESTER_CLEAR]: (state, action) =>
      R.ifElse(
        isSuggester(action),
        R.evolve(
          {
            [isSuggester(action.payload)]: R.empty
          },
          state
        ),
        R.always(state)
      )
  },
  initialState
);
