import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { SpinnerWhileLoading } from "components/notification";
import { isLoading as areThingDomainTTLoading } from "ducks/aggregators/loading";
import {
  getEditingWidgetResources,
  getResourceValues
} from "ducks/aggregators/resources";
import { getDomain } from "ducks/thing/thing_selectors";
import { fromJS } from "immutable";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { widgetProp } from "utils/dashboard_utils";
import DynamicWidget from "./dynamic_widget_wrapper";

const query = gql`
  query domainMetadataQuery($thingName: String!) {
    thing(thingName: $thingName) {
      thingName
      domainConnection {
        name
        description
        data
      }
    }
  }
`;

export const isDomainMetadataResourceId = resourceId =>
  resourceId ? !!resourceId.match(/^domain./) : false;

const DynamicWidgetContainer = ({ template, resources, children, widget }) => {
  if (children) {
    return React.cloneElement(children, {
      template,
      valueMap: resources
    });
  }
  return (
    <DynamicWidget template={template} valueMap={resources} widget={widget} />
  );
};

DynamicWidgetContainer.propTypes = {
  isLoading: PropTypes.bool,
  template: PropTypes.string,
  resources: PropTypes.object,
  children: PropTypes.object,
  widget: PropTypes.object
};

const addDomainMetadataToProps = (allResources, domainMetadataQuery) => {
  const domainConnection = R.pathOr(
    {},
    ["thing", "domainConnection"],
    domainMetadataQuery
  );
  const metaKeys = R.pathOr([], ["data", "metadata"], domainConnection).map(
    item => item.id
  );
  const metadataGlorification = metaKeys.reduce(
    (acc, metaKey) => ({
      ...acc,
      domain: R.assocPath(
        ["data", metaKey],
        domainConnection.data[metaKey],
        acc.domain || {}
      )
    }),
    {}
  );
  return allResources.merge(
    fromJS({
      domain: {
        ...metadataGlorification.domain,
        name: domainConnection.name,
        description: domainConnection.description,
        data: domainConnection.data
      }
    })
  );
};

export const mapStateToProps = (state, ownProps) => {
  const isLoading = areThingDomainTTLoading(state);
  if (isLoading) {
    return { isLoading };
  }
  const { widget } = ownProps;
  const { thingName, thingType, subthing, subThingType } = ownProps.params;
  const domainId = getDomain(state, thingName, ["id"]);
  const resources = getEditingWidgetResources(
    state,
    thingName,
    thingType,
    subthing,
    subThingType,
    {
      includeSubthings: false
    }
  ).map(resource => ({ ...resource, label: resource.name }));
  const allResources = getResourceValues({
    state,
    resources,
    thingName,
    thingType,
    subthing,
    subThingType,
    simple: true,
    map: true
  });

  const allResourcesAndDomainMetadata = addDomainMetadataToProps(
    allResources,
    ownProps.domainMetadataQuery,
    resources
  );

  return {
    template: widgetProp(widget, "template"),
    resources: allResourcesAndDomainMetadata,
    domainId,
    widget
  };
};

export default compose(
  graphql(query, {
    name: "domainMetadataQuery",
    options: props => {
      const { thingName } = props.params;
      return { variables: { thingName } };
    }
  }),
  connect(
    mapStateToProps,
    {},
    (ownProps, stateProps, dispatchProps) =>
      Object.assign({}, ownProps, stateProps, dispatchProps),
    {
      pure: true
    }
  )
)(SpinnerWhileLoading(props => !props.isLoading)(DynamicWidgetContainer));
