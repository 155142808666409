import React from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Time from "components/date_time/time/time";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  daysRow: {
    width: "460px"
  },
  timeStyle: { width: "200px", margin: "20px 11px" },
  dayDot: { marginLeft: "0" },
  responsiveForm: {
    display: "flex",
    alignItems: "center",
    paddingTop: "18px",
    paddingBottom: "8px",
    fontSize: "12px",
    flexWrap: "wrap"
  }
});

const WeeklyForm = ({
  scheduleParams: {
    weekly: { period, time }
  },
  handleScheduleParamsChange,
  classes
}) => (
  <Grid container spacing={8}>
    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
      <Typography component="p" variant="body1">
        On
      </Typography>
      <FormGroup className={classes.daysRow} row>
        <FormControl component="fieldset">
          <RadioGroup
            value={period}
            row
            onChange={e =>
              handleScheduleParamsChange({
                weekly: { period: e.target.value }
              })
            }
          >
            <FormControlLabel
              labelPlacement="bottom"
              value="2"
              control={<Radio />}
              label="Mon"
              className={classes.dayDot}
            />
            <FormControlLabel
              labelPlacement="bottom"
              value="3"
              control={<Radio />}
              label="Tue"
              className={classes.dayDot}
            />
            <FormControlLabel
              labelPlacement="bottom"
              value="4"
              control={<Radio />}
              label="Wed"
              className={classes.dayDot}
            />
            <FormControlLabel
              labelPlacement="bottom"
              value="5"
              control={<Radio />}
              label="Thu"
              className={classes.dayDot}
            />
            <FormControlLabel
              labelPlacement="bottom"
              value="6"
              control={<Radio />}
              label="Fri"
              className={classes.dayDot}
            />
            <FormControlLabel
              labelPlacement="bottom"
              value="7"
              control={<Radio />}
              label="Sat"
              className={classes.dayDot}
            />
            <FormControlLabel
              labelPlacement="bottom"
              value="1"
              control={<Radio />}
              label="Sun"
              className={classes.dayDot}
            />
          </RadioGroup>
        </FormControl>
      </FormGroup>
      <Typography component="p" variant="body1">
        <Time
          label="at"
          selectedTime={time}
          onChange={time => handleScheduleParamsChange({ weekly: { time } })}
        />
        {"UTC"}
      </Typography>
    </Grid>
  </Grid>
);

WeeklyForm.propTypes = {
  scheduleParams: PropTypes.shape({
    weekly: PropTypes.shape({
      period: PropTypes.string,
      time: PropTypes.string
    })
  }),
  handleScheduleParamsChange: PropTypes.func,
  classes: PropTypes.object
};

export default withStyles(styles)(WeeklyForm);
