import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { Page } from "components/page";
import { authSelectors } from "ducks/auth";
import { selectors } from "ducks/dashboard";
import {
  CREATE_ALL_THINGS_VIEWMODE_MUTATION,
  DEFAULT_ALLTHINGS_VIEWMODE_QUERY
} from "graphql/queries";
import { fromJS } from "immutable";
import PropTypes from "prop-types";
import { clone, equals, path } from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  compose,
  shouldUpdate,
  withHandlers,
  withPropsOnChange,
  withState,
  withStateHandlers
} from "react-recompose";
import { makeGridLayouts } from "utils/dashboard_utils";
import defaultAllThingsGrid from "./default_all_things_grid.json";
import Grid from "./grid";
import { DashboardHeader } from "./header";
import AllThingsToolBarContainer from "./toolbar";

const GridLayout = props => {
  return (
    <Page
      title={"All Things"}
      rightElement={<AllThingsToolBarContainer {...props} />}
    >
      <DashboardHeader {...props} />
      <Grid {...props} />
    </Page>
  );
};

GridLayout.propTypes = {
  widgets: PropTypes.array,
  layouts: PropTypes.object,
  request: PropTypes.object,
  handleOnLayoutChange: PropTypes.func,
  dashboardType: PropTypes.string,
  type: PropTypes.string,
  filterId: PropTypes.string,
  isMovingWidgets: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  handleUndo: PropTypes.func,
  location: PropTypes.object,
  params: PropTypes.object,
  setIsMovingWidgets: PropTypes.func,
  setEditModeLayout: PropTypes.func,
  data: PropTypes.object
};

const makeMapStateProps = () => (state, { location, params }) => {
  const userDomain = authSelectors.userDomainSelector(state);
  const filterId = "allThings";
  const filter = state.filter.getIn(
    ["filters", filterId, "filter"],
    fromJS({})
  );
  const dashboardType = "allThings";
  const request = selectors.getDashboardRequest(state);

  return {
    request,
    userDomain,
    filterId,
    filter,
    type: "text",
    dashboardType,
    location,
    params
  };
};

const _withProps = withPropsOnChange(
  (props, nextProps) =>
    path(["defaultAllThingsViewModeQuery", "loading"], nextProps) !==
      path(["defaultAllThingsViewModeQuery", "loading"], props) ||
    path(["defaultAllThingsViewModeQuery", "defaultViewMode"], nextProps) !==
      path(["defaultAllThingsViewModeQuery", "defaultViewMode"], props),
  ({ defaultAllThingsViewModeQuery, createAllThingsViewModeMutation }) => {
    const dashboardViewMode = path(
      ["defaultViewMode"],
      defaultAllThingsViewModeQuery
    );

    const hasDashboard = dashboardViewMode != null;
    const isQueryLoading = path(["loading"], defaultAllThingsViewModeQuery);
    const shouldCreateDashboard = !isQueryLoading && !hasDashboard;

    if (shouldCreateDashboard) {
      createAllThingsViewModeMutation({
        variables: { grids: [defaultAllThingsGrid] },
        update: (proxy, mutationResult) => {
          proxy.writeQuery({
            query: DEFAULT_ALLTHINGS_VIEWMODE_QUERY,
            data: {
              defaultViewMode: path(
                ["data", "createViewMode", "viewMode"],
                mutationResult
              )
            }
          });
        }
      });
    }

    return {
      error: path(["error"], defaultAllThingsViewModeQuery),
      isLoading: isQueryLoading || !hasDashboard,
      layouts: path(
        ["defaultViewMode", "grids", 0, "layout"],
        defaultAllThingsViewModeQuery
      ),
      gridId: path(
        ["defaultViewMode", "grids", 0, "gridId"],
        defaultAllThingsViewModeQuery
      ),
      viewModeId: path(
        ["defaultViewMode", "id"],
        defaultAllThingsViewModeQuery
      ),
      widgets: path(
        ["defaultViewMode", "grids", 0, "widgets"],
        defaultAllThingsViewModeQuery
      ),
      dashboardViewMode: path(
        ["defaultViewMode"],
        defaultAllThingsViewModeQuery
      ),
      keypath: `grids[0].layout`
    };
  }
);

export const UPDATE_GRID_MUTATION = gql`
  mutation($viewModeId: ID!, $gridId: String!, $layouts: JSON) {
    updateGrid(
      scope: { gridId: $gridId, viewModeId: $viewModeId }
      input: { layout: $layouts }
    ) {
      viewMode {
        id
        label
        grids {
          gridId
          type
          layout
          widgets {
            widgetId
            label
            type
            props
          }
        }
      }
    }
  }
`;

export default compose(
  connect(makeMapStateProps),
  withRouter,
  graphql(CREATE_ALL_THINGS_VIEWMODE_MUTATION, {
    name: "createAllThingsViewModeMutation"
  }),
  graphql(DEFAULT_ALLTHINGS_VIEWMODE_QUERY, {
    name: "defaultAllThingsViewModeQuery"
  }),
  _withProps,
  graphql(UPDATE_GRID_MUTATION, { name: "updateGridMutation" }),
  withState("breakpoint", "setBreakpoint"),
  withHandlers({
    handleOnBreakpointChange: ({ setBreakpoint }) => breakpoint => {
      setBreakpoint(breakpoint);
    }
  }),
  withStateHandlers(
    {
      isMovingWidgets: false,
      editModeLayout: null
    },
    {
      setIsMovingWidgets: (state, { layouts }) => isMovingWidgets => ({
        ...state,
        isMovingWidgets,
        editModeLayout: isMovingWidgets ? layouts : null
      }),
      setEditModeLayout: state => layouts => {
        return {
          ...state,
          editModeLayout: layouts
        };
      }
    }
  ),
  withHandlers({
    handleOnLayoutChange: ({ setEditModeLayout }) => layout => {
      setEditModeLayout(makeGridLayouts(layout));
    },
    handleSave: ({
      updateGridMutation,
      dashboardId,
      gridId,
      viewModeId,
      editModeLayout,
      setIsMovingWidgets
    }) => () => {
      updateGridMutation({
        variables: {
          viewModeId,
          gridId,
          dashboardId,
          layouts: editModeLayout
        },
        update: () => {
          setIsMovingWidgets(false);
        }
      });
    }
  }),
  withPropsOnChange(
    ["isMovingWidgets", "layouts", "editModeLayout"],
    ({ isMovingWidgets, layouts, editModeLayout }) => {
      return isMovingWidgets === true
        ? { layouts: editModeLayout }
        : { layouts: clone(layouts) };
    }
  ),
  shouldUpdate((props, next) => {
    const widgetsChanged =
      props.widgets && !equals(props.widgets, next.widgets);
    const isMovingWidgetsChanged =
      props.isMovingWidgets !== next.isMovingWidgets;
    const layoutsChanged = props.layouts !== next.layouts;
    const requestChanged = props.request !== next.request;
    const dashboardChanged = !equals(
      props.dashboardViewMode,
      next.cashboardashboardViewMode
    );
    return (
      requestChanged ||
      widgetsChanged ||
      layoutsChanged ||
      isMovingWidgetsChanged ||
      dashboardChanged
    );
  })
)(GridLayout);
