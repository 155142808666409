import { connect } from "react-redux";
import * as R from "ramda";
import { branch, compose, withPropsOnChange } from "react-recompose";
import { fromJS, List } from "immutable";
import { latLngfromString, isValidLatLng } from "utils/latlng_utils";
import { widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import ThingMapWidget from "./map_widget";
import withThingObservations from "components/widget/observation_hocs/with_thing_observations";
import { resourcesWithValuesSelector } from "ducks/schema/schema_selectors";
import { resourceStatePath, withMarkerColors } from "utils/widget_utils";

export const getResourceValueFromObservation = (
  observation,
  conditions,
  subthing
) => {
  if (!R.prop("resourceId", conditions)) return undefined;
  const path = resourceStatePath(subthing, conditions.resourceId);
  const value = imu.getIn(observation, ["state", ...path.split(".")]);
  return value;
};

const _dataFromResource = (resource, conditions, resources) => {
  const resourceValue = imu.getIn(resource, ["latestValue", "value"]);
  return isValidLatLng(resourceValue)
    ? withMarkerColors(
        conditions,
        fromJS([
          {
            latLng: latLngfromString(resourceValue),
            properties: {
              timestamp: imu.getIn(resource, ["latestValue", "timestamp"]),
              conditionValue:
                conditions && conditions.resourceId
                  ? resources.find(({ id }) => id === conditions.resourceId)
                      .reported.value
                  : undefined
            }
          }
        ])
      )
    : List();
};

const _dataFromObservations = props => {
  const { observations, widget, params } = props;
  return withMarkerColors(
    widget.props.conditions,
    observations
      .filter(observation => isValidLatLng(observation.get("value")))
      .map(observation =>
        fromJS({
          latLng: latLngfromString(observation.get("value")),
          properties: {
            timestamp: observation.get("timestamp"),
            conditionValue: getResourceValueFromObservation(
              observation,
              widget.props.conditions,
              params.subthing
            )
          }
        })
      )
  );
};
const widgetIsHistorical = ({ widget }) =>
  widgetProp(widget, "dataSource") === "HISTORICAL";

const _mapStateToProps = (state, props) => {
  const { thingName, thingType, subthing, subThingType } = props.params;
  const allResources = resourcesWithValuesSelector(state, {
    thingName,
    thingType,
    subthing,
    subThingType
  });
  return {
    data: _dataFromResource(
      props.resource,
      props.widget.props.conditions,
      allResources
    )
  };
};

const withThingShadow = connect(_mapStateToProps);

export default branch(
  widgetIsHistorical,
  compose(
    withThingObservations,
    withPropsOnChange(["observations", "widget", "params"], props => ({
      data: _dataFromObservations(props)
    }))
  ),
  withThingShadow
)(ThingMapWidget);
