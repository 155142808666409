import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Label } from "components/label";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { withRouter } from "react-router";
import { compose, withHandlers } from "react-recompose";
import PageContent from "./styled/page_content";
import PageHeader from "./styled/page_header";
import PageRight from "./styled/page_right";

const backBtnContainerCss = {
  margin: "10px 10px 10px 0px",
  display: "inline-block"
};
const backBtnCss = {
  display: "inline-block",
  background: "rgb(235, 235, 235)"
};
const labelCss = {
  marginRight: "10px",
  display: "flex",
  alignItems: "center"
};

const Page = ({
  title,
  titleElement,
  leftElement,
  backButton,
  rightElement,
  handleBackButton,
  style,
  children,
  parentName,
  pageContentStyle
}) => {
  return (
    <div className="page" style={style}>
      <Helmet title={title} />
      <PageHeader>
        {backButton && (
          <span style={backBtnContainerCss}>
            <div style={backBtnCss}>
              <Button
                data-test="page-header-back-button"
                onClick={handleBackButton}
                variant="text"
                size="medium"
              >
                {parentName}
              </Button>
            </div>
          </span>
        )}
        <Label data-test="page-header-left-content" type="h1" style={labelCss}>
          {leftElement} {title} {titleElement}
        </Label>
        {rightElement && (
          <PageRight data-test="page-header-right-content">
            {rightElement}
          </PageRight>
        )}
      </PageHeader>
      <PageContent styles={pageContentStyle}>
        <Grid container spacing={2}>
          {Array.isArray(children) ? (
            children
              .filter(child => child)
              .map((child, index) => (
                <Grid key={`page-grid-item-${index}`} item xs={12}>
                  {child}
                </Grid>
              ))
          ) : (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </PageContent>
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  titleElement: PropTypes.node,
  rightElement: PropTypes.node,
  leftElement: PropTypes.node,
  backButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.object,
  children: PropTypes.node,
  parentUrl: PropTypes.string,
  parentName: PropTypes.string,
  pageContentStyle: PropTypes.string,
  handleBackButton: PropTypes.func
};

Page.contextTypes = {
  history: PropTypes.object
};

export default compose(
  withRouter,
  withHandlers({
    handleBackButton: ({ router, parentUrl }) => () => {
      return router.push(parentUrl);
    }
  })
)(Page);
