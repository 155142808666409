import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Time from "components/date_time/time/time";

const DailyForm = ({
  scheduleParams: {
    daily: { period, time }
  },
  handleScheduleParamsChange
}) => (
  <Grid container spacing={8}>
    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
      <Typography component="p" variant="body1">
        Every
      </Typography>
      <TextField
        style={{ marginLeft: "6px", marginRight: "20px", maxWidth: "80px" }}
        value={period}
        required
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">days</InputAdornment>
        }}
        onChange={e =>
          handleScheduleParamsChange({
            daily: { period: e.target.value > 0 ? e.target.value : 1 }
          })
        }
      />
      <Typography component="p" variant="body1">
        <Time
          label="at"
          selectedTime={time}
          onChange={time => handleScheduleParamsChange({ daily: { time } })}
        />{" "}
        UTC
      </Typography>
    </Grid>
  </Grid>
);

DailyForm.propTypes = {
  scheduleParams: PropTypes.shape({
    daily: PropTypes.shape({
      period: PropTypes.string,
      time: PropTypes.string
    })
  }),
  handleScheduleParamsChange: PropTypes.func
};

export default DailyForm;
