import { assoc, omit, path, pathOr, prop, propOr, props } from "ramda";
import { createSelector } from "reselect";
import { fromJS } from "immutable";
import { authSelectors } from "./auth";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";
import {
  getFileUrlStatePath,
  scopePath,
  currentScope,
  currentScopeState
} from "utils/bucket_utils";
import { removeEmptyStrings } from "utils/invoke_utils";
import * as R from "ramda";

export const bucketRootSelector = state => prop("bucket")(state);
export const isLoading = createSelector(
  bucketRootSelector,
  path(["request", "isLoading"])
);

export const getBucket = createSelector(
  bucketRootSelector,
  (state, { bucket }) => bucket,
  (buckets, bucketName) => path(["entities", "buckets", bucketName])(buckets)
);

const fileUrlsRootSelector = createSelector(
  bucketRootSelector,
  path(["entities", "fileUrls"])
);

const bucketPagingSelector = createSelector(getBucket, bucket =>
  propOr("", "paging")(bucket)
);

const bucketFilesArraySelector = createSelector(getBucket, bucket =>
  propOr([], "files")(bucket)
);

export const getFilesForBucket = createSelector(
  bucketRootSelector,
  bucketFilesArraySelector,
  (state, bucketFiles) => fromJS(props(bucketFiles, state.entities.files))
);

export const getFilesForWidgetId = createSelector(
  bucketRootSelector,
  (state, { widgetId }) => widgetId,
  (bucketState, widgetId) =>
    path([...scopePath(bucketState, widgetId), "files"], bucketState)
);

export const getPaginationForBucket = createSelector(
  bucketRootSelector,
  bucketPagingSelector,
  (state, bucketPaging) => {
    return fromJS(propOr({}, bucketPaging)(state.entities.paging));
  }
);

export const getFileUrlForIndex = createSelector(
  getFilesForBucket,
  (state, { index }) => index,
  (files, index) => files.getIn([index, "url"])
);

export const getFileUrl = createSelector(
  fileUrlsRootSelector,
  (state, attributes) => attributes.fileName,
  (state, attributes) => attributes.thingName,
  (state, attributes) => attributes.thingType,
  (state, attributes) => attributes.root,
  (state, attributes) => attributes.public,
  (fileUrls, fileName, thingName, thingType, root, isPublic) =>
    path(
      getFileUrlStatePath({
        fileName,
        thingName,
        thingType,
        root,
        public: isPublic
      })
    )(fileUrls)
);

export const getAttributesFromScopeAndSelected = (scope, selected) => {
  switch (scope) {
    case "root":
      return assoc("root", true);
    case "public":
      return assoc("public", true);
    case "thingType":
      return assoc(scope, propOr(selected, "id")(selected));
    default:
      return assoc(scope, selected);
  }
};

export const scopeAndSelectedForWidgetId = createSelector(
  bucketRootSelector,
  (state, { widgetId }) => widgetId,
  (bucketState, widgetId) => {
    const scope = currentScope(bucketState, widgetId);
    const { selected } = path(
      [...scopePath(bucketState, widgetId)],
      bucketState
    );
    return {
      scope,
      selected
    };
  }
);

export const getCurrentScope = createSelector(
  bucketRootSelector,
  (state, { widgetId }) => widgetId,
  (bucketState, widgetId) => currentScope(bucketState, widgetId)
);

export const getScopeState = createSelector(
  bucketRootSelector,
  (state, { widgetId }) => widgetId,
  (bucketState, widgetId) => {
    const scope = getCurrentScope(bucketState, widgetId);
    return currentScopeState(bucketState, widgetId, scope);
  }
);

export const getCurrentScopeObj = createSelector(getCurrentScope, scope => ({
  scope
}));

const widgetScopeRoot = createSelector(
  bucketRootSelector,
  (state, { widgetId }) => widgetId,
  (bucketState, widgetId) =>
    path([...scopePath(bucketState, widgetId)], bucketState)
);

export const getFiles = createSelector(widgetScopeRoot, pathOr([], ["files"]));

export const getSelected = createSelector(
  widgetScopeRoot,
  pathOr(null, ["selected"])
);

export const getSelectedRows = createSelector(
  widgetScopeRoot,
  pathOr([], ["selectedRows"])
);

export const getPrefix = createSelector(
  widgetScopeRoot,
  pathOr("", ["prefix"])
);

export const getNextContinuationToken = createSelector(
  widgetScopeRoot,
  pathOr(null, ["paging", "nextContinuationToken"])
);

export const getIsTruncated = createSelector(
  widgetScopeRoot,
  pathOr(false, ["paging", "isTruncated"])
);

export const getIsLoading = createSelector(
  widgetScopeRoot,
  pathOr(false, ["request", "isLoading"])
);

export const listAttributes = createSelector(
  getCurrentScope,
  getSelected,
  getPrefix,
  (state, { continuationToken }) => continuationToken,
  (scopeName, selected, prefix, continuationToken) => {
    const attributes = removeEmptyStrings({
      prefix,
      continuationToken
    });
    const attr = getAttributesFromScopeAndSelected(
      scopeName,
      selected
    )(attributes);
    return attr;
  }
);

export const listAttributesScoped = createSelector(
  bucketRootSelector,
  (state, { widgetId }) => widgetId,
  getCurrentScope,
  (state, widgetId, scope) => {
    const scopeState = currentScopeState(state, widgetId, scope);
    let newScopeState = R.omit(["paging", "request"])(scopeState);
    newScopeState = {
      currentScope: scope,
      ...newScopeState,
      ...R.propOr({}, "request", scopeState),
      ...R.propOr({}, "paging", scopeState)
    };
    return newScopeState;
  }
);
export const removeAttributes = createSelector(
  listAttributes,
  omit(["prefix", "continuationToken"])
);

export const getCurrentMarkerForBucket = createSelector(
  getPaginationForBucket,
  paging => paging.get("currentMarker", "")
);

export const userCanUploadFiles = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Files, OPERATIONS.CREATE)
);

export const userCanReadFiles = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Files, OPERATIONS.READ)
);
