import React from "react";
import PropTypes from "prop-types";
import IconButton from "components/buttons/form_icon";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import LinearProgress from "@material-ui/core/LinearProgress";

import FileDownload from "@material-ui/icons/GetApp";
import { timeFrom } from "utils/view_utils";

const processingStyle = { opacity: "0.5" };
const nameColStyle = { width: "30%" };
const tableWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "auto"
};
const downloadColStyle = { textAlign: "right", width: "10%" };

const COLUMN_HEADER_HEIGHT = 60;

const FileRow = ({ file, userCanDownloadBatch }) => (
  <TableRow key={file.id}>
    <TableCell style={nameColStyle}>{file.id}</TableCell>
    <TableCell>{parseInt(file.requestedSize, 10)}</TableCell>
    <TableCell>{file.attributes.domain}</TableCell>
    <TableCell>{timeFrom(file.createdAt)}</TableCell>
    <TableCell style={downloadColStyle}>
      {userCanDownloadBatch ? (
        <IconButton href={file.url} icon={<FileDownload />} />
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

FileRow.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string,
    requestedSize: PropTypes.int,
    createdAt: PropTypes.string,
    url: PropTypes.string,
    attributes: PropTypes.shape({
      domain: PropTypes.string
    })
  }),
  userCanDownloadBatch: PropTypes.bool
};

const ProcessingRow = ({ file, isRecent }) => (
  <TableRow key={file ? file.id : 0}>
    <TableCell style={nameColStyle} title={file ? file : "Creating bafh"}>
      <span style={processingStyle}>Processing {file ? file.id : ""}...</span>
    </TableCell>
    <TableCell
      colSpan="4"
      title={
        file
          ? `${parseInt(file.currentSize, 10)} of ${parseInt(
              file.requestedSize,
              10
            )} things done`
          : "creating batch"
      }
    >
      {isRecent || !file ? (
        <LinearProgress />
      ) : (
        <LinearProgress
          mode="determinate"
          max={parseInt(file.requestedSize, 10)}
          value={parseInt(file.currentSize, 10)}
        />
      )}
    </TableCell>
  </TableRow>
);

ProcessingRow.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string,
    requestedSize: PropTypes.int,
    currentSize: PropTypes.int
  }),
  isRecent: PropTypes.bool
};

const ThingCredentialList = ({
  container,
  hasProcessingBatches,
  files,
  isLoading,
  isCreating,
  maxProcessingBatchAge,
  userCanDownloadBatch
}) => {
  const hasFilesNotLoading = files && files.length > 0 && !isLoading;
  const now = new Date().getTime();

  // Tbody can't be empty or else it will throw an exception.
  // Quick hotfix-hack for now. Also tooltip in TableHader is disabled for now, also throws error. (ttooltip=...)
  const height =
    container && container.height
      ? `${container.height - COLUMN_HEADER_HEIGHT}px`
      : "inherit";
  /* eslint-disable react/no-children-prop */
  return (
    <div style={tableWrapperStyle}>
      <Table height={height}>
        <TableHead>
          <TableRow style={{ textTransform: "uppercase" }}>
            <TableCell style={nameColStyle}>Batch</TableCell>
            <TableCell>Things in batch</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>Created</TableCell>
            <TableCell style={downloadColStyle}>Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isCreating && !hasProcessingBatches && <ProcessingRow />}
          {hasFilesNotLoading || isCreating ? (
            files.map(file => {
              if (!file) return null;
              const requestedSize = parseInt(file.requestedSize, 10);
              const currentSize = parseInt(file.currentSize, 10);
              const isProcessing = currentSize !== requestedSize;
              const isRecent =
                !file.finishedAt &&
                now - new Date(file.createdAt).valueOf() <
                  maxProcessingBatchAge;
              return isProcessing ? (
                <ProcessingRow
                  key={file.id ? file.id : 0}
                  isRecent={isRecent}
                  file={file}
                />
              ) : (
                <FileRow
                  file={file}
                  key={file.id ? file.id : 0}
                  userCanDownloadBatch={userCanDownloadBatch}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell>No files</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

ThingCredentialList.propTypes = {
  files: PropTypes.array,
  container: PropTypes.object,
  isLoading: PropTypes.bool,
  isCreating: PropTypes.bool,
  hasProcessingBatches: PropTypes.bool,
  maxProcessingBatchAge: PropTypes.number,
  userCanDownloadBatch: PropTypes.bool
};

ThingCredentialList.defaultProps = {
  maxProcessingBatchAge: 60000 * 5,
  files: []
};

export default ThingCredentialList;
