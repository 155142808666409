import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormCancel from "components/buttons/form_cancel";
import FormSave from "components/buttons/form_save";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import PropTypes from "prop-types";
import React from "react";
import { Field, FieldSet, Form } from "../";
import { generateUserWithDomainSchema } from "./schemas";

const userSchema = generateUserWithDomainSchema();

const roleList = [
  { id: "none", label: "No system role" },
  { id: "Read", label: "Read" },
  { id: "ReadWrite", label: "Read/Write" }
];

const CreateUserForm = ({ userRoles, onCancel, onSubmit, isLoading }) => {
  return (
    <Form schema={userSchema} allowUnknown onSubmit={onSubmit}>
      <DialogContent>
        <FieldSet>
          <Field id="userName" label="Username" />
          <Field id="firstName" label="First name" />
          <Field id="lastName" label="Last name" />
          <Field id="email" label="E-mail" />
          <Field
            id="phone"
            label="Phone"
            hint="International format like +46 738 123456"
          />
          <Field
            data-test="domainName"
            id="domainName"
            label="Domain"
            type="autocomplete"
            suggesterType={DATA_TYPES.DOMAINS}
            hintText="Domain"
            localState
          />
          <Field
            id="roleName"
            dataTest="roleName"
            label="System role"
            type="select"
            options={roleList}
          />
          <Field
            id="roles"
            data-test="roles"
            label="Additional roles"
            type="autocomplete"
            suggesterType={DATA_TYPES.ADDITIONAL_ROLES}
            hintText="Additional roles"
            options={userRoles}
            defaultValue={[]}
          />
          <Field id="company" label="Company" />
          <Field id="address" label="Address" />
          <Field id="zip" label="Zip" />
          <Field id="city" label="City" />
          <Field id="country" label="Country" />
        </FieldSet>
      </DialogContent>
      <DialogActions>
        <FormCancel isLoading={isLoading} onClick={onCancel} type="button" />
        <FormSave isLoading={isLoading} />
      </DialogActions>
    </Form>
  );
};

CreateUserForm.propTypes = {
  userRoles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default CreateUserForm;
