import { useTheme } from "@material-ui/core/styles";
import LoadingWrapper from "components/loading/loading_wrapper";
import PropTypes from "prop-types";
import React from "react";
import Loadable from "react-loadable";
import {
  compose,
  defaultProps,
  setPropTypes,
  withHandlers,
  withState
} from "react-recompose";
import ErrorText from "./styled/error_text";
import FloatingLabelText from "./styled/floating_label_text";

const LoadableEditor = Loadable({
  loader: () => import(/* webpackChunkName: "code-field-editor"*/ "./editor"),
  loading: LoadingWrapper
});

const options = {
  showLineNumbers: true,
  showGutter: true,
  highlightGutterLine: true
};

const editorProps = { $blockScrolling: true };

export default compose(
  withState("state", "setState", { hasFocus: false }),
  setPropTypes({
    theme: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    hintText: PropTypes.string,
    floatingLabelText: PropTypes.string,
    errorText: PropTypes.string
  }),
  defaultProps({
    value: "",
    onChange: () => {},
    id: "code_field",
    disabled: false,
    hintText: null,
    floatingLabelText: null,
    errorText: null
  }),
  withHandlers({
    setFocus: ({ setState, state }) => () =>
      setState({ ...state, hasFocus: true }),
    setBlur: ({ setState, state }) => () =>
      setState({ ...state, hasFocus: false })
  })
)(
  ({
    onChange,
    value,
    id,
    errorText,
    floatingLabelText,
    state,
    setBlur,
    setFocus,
    disabled
  }) => {
    const theme = useTheme();
    /* eslint-disable react/no-unescaped-entities */
    return (
      <div>
        {floatingLabelText && (
          <FloatingLabelText hasFocus={state.hasFocus} muiTheme={theme}>
            {floatingLabelText}
          </FloatingLabelText>
        )}
        <LoadableEditor
          name={id}
          readOnly={disabled}
          width={"100%"}
          tabSize={3}
          maxLines={60}
          minLines={5}
          setOptions={options}
          value={value || ""}
          mode="javascript"
          theme="xcode"
          onFocus={setFocus}
          onBlur={setBlur}
          onChange={onChange}
          editorProps={editorProps}
        />
        {errorText && <ErrorText>"{errorText}"</ErrorText>}
      </div>
    );
  }
);
