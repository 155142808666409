import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormIconButton from "components/buttons/form_icon";

import { compose, lifecycle, withState, withHandlers } from "react-recompose";
import { PropTypes } from "prop-types";
import {
  append,
  clone,
  evolve,
  keys,
  map,
  mergeDeepLeft,
  pipe,
  without,
  propOr
} from "ramda";
import styled from "styled-components";
import { widgetProp, widgetId } from "utils/dashboard_utils";
import ProcessWidget from "./process_widget";

const SelectFieldOption = key => (
  <MenuItem value={key} key={key}>
    {key}
  </MenuItem>
);

const FileInput = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: block;
  opacity: 0;
`;

const FileUploadButtonWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 15px;
  display: inline-block;
`;

const FileUploadButton = ({ handleFiles }) => (
  <FileUploadButtonWrapper>
    <FormIconButton
      key="create"
      text="Upload file"
      color="primary"
      variant="contained"
      data-test={"create-job-create-btn"}
    >
      <FileInput
        type="file"
        id="fileUploadInput"
        accept=".bmp, .jpg, .png, .gif"
        onChange={handleFiles}
      />
    </FormIconButton>
  </FileUploadButtonWrapper>
);

FileUploadButton.propTypes = {
  handleFiles: PropTypes.func
};

const ControlsGrid = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
`;

const LabelButtonsContainer = styled.div`
  display: flex;
`;

const ProcessWidgetEditor = props => {
  const {
    state,
    handleChangeVariable,
    handleChangeLabel,
    handleChangeFont,
    handleFiles,
    addLabelToSVG,
    deleteLabel,
    resources,
    startDrag,
    deselectLabel
  } = props;
  return (
    <React.Fragment>
      <ControlsGrid>
        <TextField
          style={{ width: "50%" }}
          label="Label"
          value={state.selectedVariable.label}
          onChange={handleChangeLabel}
        />
        <FormControl style={{ width: "50%" }}>
          <InputLabel htmlFor="available-variables">
            Available variables{" "}
          </InputLabel>

          <Select
            inputProps={{
              name: "available-variables",
              id: "available-variables"
            }}
            label="Available variables"
            value={state.selectedVariable.value}
            onChange={handleChangeVariable}
          >
            {pipe(keys, map(SelectFieldOption))(resources)}
          </Select>
        </FormControl>
        <FormControl style={{ width: "50%" }}>
          <InputLabel htmlFor="age-simple">Font Size </InputLabel>

          <Select
            label="Font size"
            value={state.selectedVariable.fontSize}
            onChange={handleChangeFont}
          >
            <MenuItem value={size1}>{size1}</MenuItem>
            <MenuItem value={size2}>{size2}</MenuItem>
            <MenuItem value={size3}>{size3}</MenuItem>
          </Select>
        </FormControl>
        <div />
        <LabelButtonsContainer>
          <FormIconButton
            color="primary"
            key="add"
            text="Add"
            onClick={addLabelToSVG}
            disabled={state.selectedVariable.id ? true : false}
            data-test="add-label"
          />
          <FormIconButton
            key="delete"
            disabled={state.selectedVariable.id ? false : true}
            text="Delete"
            onClick={deleteLabel}
            data-test="delete-label"
          />
        </LabelButtonsContainer>
        <FileUploadButton handleFiles={handleFiles} />
      </ControlsGrid>

      <div style={{ padding: "24px", background: "#ebebeb" }}>
        <div style={{ background: "white" }}>
          <ProcessWidget
            deselectLabel={deselectLabel}
            isEdit={true}
            startDrag={startDrag}
            image={state.svg}
            addedVariables={state.addedVariables}
            resources={resources}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

ProcessWidgetEditor.propTypes = {
  state: PropTypes.object,
  addLabelToSVG: PropTypes.func,
  deleteLabel: PropTypes.func,
  handleChangeVariable: PropTypes.func,
  handleChangeLabel: PropTypes.func,
  handleChangeFont: PropTypes.func,
  handleFiles: PropTypes.func,
  setState: PropTypes.func,
  setSvg: PropTypes.func,
  setSvgRectElem: PropTypes.func,
  startDrag: PropTypes.func,
  resources: PropTypes.object,
  deselectLabel: PropTypes.func
};
const size1 = "2px";
const size2 = "4px";
const size3 = "8px";

const _initialState = {
  selectedVariable: {
    label: "",
    fontSize: "8px",
    value: "",
    id: null,
    selected: false
  },
  addedVariables: []
};

const _withState = withState("state", "setState", props => ({
  ...clone(_initialState),
  addedVariables: clone(widgetProp(props.widget, "addedVariables", [])),
  fileName: widgetProp(props.widget, "fileName"),
  svg: props.image
}));

const _lifecycle = lifecycle({
  componentDidMount() {
    const { setBeforeDone, doBeforeDone } = this.props;
    setBeforeDone({ do: doBeforeDone });
  }
});

const _withHandlers = withHandlers({
  doBeforeDone: props => () => {
    const { state, uploadFile, params, data } = props;
    const thingType = propOr("", "thingType", params);
    const fileName = state.fileName;

    if (state.file) {
      uploadFile({
        file: state.file,
        fileName,
        thingType,
        refetch: propOr(() => Promise.resolve(), "refetch", data)
      });
    }

    return {
      fileName: state.fileName,
      addedVariables: state.addedVariables
    };
  },
  addLabelToSVG: ({ setState, state }) => () => {
    const addedVariable = {
      x: 50,
      y: 50,
      fontSize: state.selectedVariable.fontSize,
      label: state.selectedVariable.label,
      value: state.selectedVariable.value,
      dragOffset: { x: 0, y: 0 }
    };
    state.selectedVariable = clone(_initialState.selectedVariable);

    setState(
      evolve(
        {
          addedVariables: append(addedVariable)
        },
        state
      )
    );
  },
  deleteLabel: ({ state, setState }) => () => {
    const selected = state.selectedVariable;
    state.selectedVariable = clone(_initialState.selectedVariable);
    setState(evolve({ addedVariables: without([selected]) }, state));
  },
  deselectLabel: ({ state, setState }) => event => {
    if (event.target.getAttribute("id") != state.selectedVariable.id) {
      const selectedVar = state.selectedVariable;
      selectedVar.selected = false;
      const vars = state.addedVariables;
      vars[selectedVar.id] = selectedVar;
      setState(
        mergeDeepLeft(
          {
            selectedVariable: clone(_initialState.selectedVariable),
            addedVariables: vars
          },
          state
        )
      );
    }
  },
  handleChangeVariable: ({ state, setState }) => event => {
    const value = event.target.value;
    const selectedVar = state.selectedVariable;
    selectedVar.value = value;
    const vars = state.addedVariables;
    if (selectedVar.id) {
      vars[selectedVar.id] = selectedVar;
    }
    setState(
      mergeDeepLeft(
        { selectedVariable: { value: value }, addedVariables: vars },
        state
      )
    );
  },
  handleChangeLabel: ({ state, setState }) => event => {
    const label = event.target.value;
    const selectedVar = state.selectedVariable;
    selectedVar.label = label;
    const vars = state.addedVariables;
    if (selectedVar.id) {
      vars[selectedVar.id] = selectedVar;
    }
    setState(
      mergeDeepLeft(
        { selectedVariable: { label: label }, addedVariables: vars },
        state
      )
    );
  },
  handleChangeFont: ({ state, setState }) => event => {
    const selectedVar = state.selectedVariable;
    const fontSize = event.target.value;
    selectedVar.fontSize = fontSize;
    const vars = state.addedVariables;
    if (selectedVar.id) {
      vars[selectedVar.id] = selectedVar;
    }
    setState(
      mergeDeepLeft(
        { selectedVariable: { fontSize: fontSize }, addedVariables: vars },
        state
      )
    );
  },
  handleFiles: ({ state, setState, modalStateWidget, params }) => event => {
    const selectedFile = event.target.files[0];
    const viewMode = propOr("", "viewMode", params);
    const modalWidgetId = widgetId(modalStateWidget.widget);
    const fileName = `process-picture-${viewMode}${modalWidgetId}`;

    const reader = new FileReader();
    reader.onload = () => {
      setState(
        mergeDeepLeft(
          { svg: reader.result, file: selectedFile, fileName: fileName },
          state
        )
      );
    };

    reader.readAsDataURL(selectedFile);
  },
  startDrag: ({ state, setState }) => (event, rootSvg) => {
    const draggedElem = event.target;
    const id = draggedElem.getAttribute("id");
    event.preventDefault();
    const addedVariable = state.addedVariables[parseInt(id, 10)];
    addedVariable.id = id;
    addedVariable.selected = true;

    let point = rootSvg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    point = point.matrixTransform(rootSvg.getScreenCTM().inverse());
    addedVariable.dragOffset = {
      x: point.x - addedVariable.x,
      y: point.y - addedVariable.y
    };

    setState(
      mergeDeepLeft(
        { dragElement: addedVariable, selectedVariable: addedVariable },
        state
      )
    );

    const mousemove = event => {
      event.preventDefault();

      point.x = event.clientX;
      point.y = event.clientY;
      let cursor = point.matrixTransform(rootSvg.getScreenCTM().inverse());
      (addedVariable.x = cursor.x - addedVariable.dragOffset.x),
        (addedVariable.y = cursor.y - addedVariable.dragOffset.y);
      // just to force a rerender...
      setState(
        mergeDeepLeft(
          { dragElement: addedVariable, selectedVariable: addedVariable },
          state
        )
      );
    };

    const mouseup = event => {
      event.preventDefault();

      rootSvg.removeEventListener("mousemove", mousemove);
      rootSvg.removeEventListener("mouseup", mouseup);

      setState(
        mergeDeepLeft(
          { dragElement: null, selectedVariable: addedVariable },
          state
        )
      );
    };

    rootSvg.addEventListener("mousemove", mousemove);
    rootSvg.addEventListener("mouseup", mouseup);
  }
});
export default compose(
  _withState,
  _withHandlers,
  _lifecycle
)(ProcessWidgetEditor);
