import { TYPE_GEOPOINT } from "../types";

export default (type, params) => {
  switch (type) {
    case TYPE_GEOPOINT: {
      const { lat, lon, precision } = params;
      return `${lat.toFixed(precision)},${lon.toFixed(precision)}`;
    }
    default: {
      const { value } = params;
      return value;
    }
  }
};
