import { Observable } from "rxjs";
import { mergeDeepLeft } from "ramda";
import * as Storage from "api/device/storage";
import { traceErr, traceLog } from "./trace";
import { configureAuthenticated } from "./cognito_credentials";

export const setCredentials = account => {
  try {
    let storageAccount = Storage.get("account");
    if (storageAccount && account) {
      account = mergeDeepLeft(account, storageAccount);
    }
    Storage.set("account", account);
  } catch (e) {
    traceErr("[setCredentials] failed to set account in Storage", e);
    return Promise.reject(e);
  }

  return configureAuthenticated(account.credentials.token)
    .getPromise()
    .then(() => {
      traceLog("[setCredentials] successful", account);
      return account;
    })
    .catch(err => {
      traceErr("[setCredentials] error", err);
      return Promise.reject(err);
    });
};

export const setCredentials$ = account =>
  Observable.fromPromise(setCredentials(account));
