import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Step1Content from "./step1_content";

const Step1 = ({
  thingType,
  handleSelectedResourcesChange,
  selectedThings,
  selectedResources,
  handleToggleResource,
  resources,
  selectedState,
  toggleSelectAllResources
}) => (
  <React.Fragment>
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Typography component={"h6"} variant={"h6"}>
          1. Specify data sources
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Step1Content
          thingType={thingType}
          selectedThings={selectedThings}
          resources={resources}
          toggleSelectAllResources={toggleSelectAllResources}
          selectedState={selectedState}
          handleToggleResource={handleToggleResource}
          selectedResourceNames={selectedResources}
          handleSelectedResourcesChange={handleSelectedResourcesChange}
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

Step1.propTypes = {
  thingType: PropTypes.string,
  handleSelectedResourcesChange: PropTypes.func,
  selectedThings: PropTypes.array,
  selectedResources: PropTypes.object,
  handleToggleResource: PropTypes.func,
  resources: PropTypes.array,
  selectedState: PropTypes.integer,
  toggleSelectAllResources: PropTypes.func,
  setFilterQuery: PropTypes.func,
  filterQuery: PropTypes.string
};

export default Step1;
