import { createAction, handleActions } from "redux-actions";
import {
  append,
  assoc,
  contains,
  F,
  mergeDeepLeft,
  evolve,
  propSatisfies,
  T,
  unless
} from "ramda";

export const PAGING_INIT_PAGING = "PAGING_INIT_PAGING";
export const PAGING_SET_LOADING = "PAGING_SET_LOADING";
export const PAGING_SET_PAGING_DATA = "PAGING_SET_PAGING_DATA";

export const initPaging = createAction(PAGING_INIT_PAGING);
export const setLoading = createAction(PAGING_SET_LOADING);
export const setPagingData = createAction(PAGING_SET_PAGING_DATA);

export const initialEntityState = {
  request: {
    isLoading: false
  },
  pagingData: {
    page: 1,
    pages: 0,
    pageSize: 0,
    sort: "",
    sortFieldType: "",
    things: [],
    totalCount: 0
  }
};

export const initialState = {
  result: [],
  entities: {}
};

export default handleActions(
  {
    [PAGING_INIT_PAGING]: (state, { payload: { widgetId, pagingData = {} } }) =>
      unless(
        propSatisfies(contains(widgetId), "result"),
        evolve({
          result: append(widgetId),
          entities: assoc(
            widgetId,
            mergeDeepLeft({ pagingData }, initialEntityState)
          )
        })
      )(state),

    [PAGING_SET_LOADING]: (state, { payload: { widgetId } }) =>
      evolve({
        entities: {
          [widgetId]: {
            request: { isLoading: T }
          }
        }
      })(state),

    [PAGING_SET_PAGING_DATA]: (state, { payload: { widgetId, pagingData } }) =>
      evolve({
        entities: {
          [widgetId]: {
            pagingData: mergeDeepLeft(pagingData),
            request: { isLoading: F }
          }
        }
      })(state)
  },
  initialState
);
