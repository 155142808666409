import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import DeleteIcon from "@material-ui/icons/Delete";

import { pd } from "utils/btn_utils";

const Template = ({
  text,
  checked,
  onChecked,
  onDeleteClicked,
  index,
  id,
  isDefault,
  disabled
}) => {
  return (
    <ListItem
      key={index}
      dense
      button
      style={{
        background: index % 2 === 0 ? "#f5f5f5" : "white",
        padding: 0
      }}
      onClick={e => onChecked(e, !checked)}
    >
      <ListItemIcon>
        <Checkbox
          color="primary"
          edge="start"
          checked={checked}
          disableRipple
          tabIndex={-1}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText primary={text.length > 0 ? text : id} />
      <ListItemSecondaryAction>
        {!isDefault && (
          <IconButton edge="end" onClick={pd(onDeleteClicked, { index })}>
            <DeleteIcon />
          </IconButton>
        )}
        <IconButton
          edge="end"
          disableRipple={true}
          style={{ backgroundColor: "transparent" }}
        >
          <SwapVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

Template.propTypes = {
  text: PropTypes.string,
  onDeleteClicked: PropTypes.func,
  checked: PropTypes.bool,
  isDefault: PropTypes.bool,
  index: PropTypes.number,
  onChecked: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

export default Template;
