import AggregationTypeFragment from "components/widget/edit/widget_edit_aggregation_options";
import { ComposeForm } from "components/widget/edit/compose_form";
import resourceTypes from "../widget_resource_types";
import { WidgetEditValue } from "../value_widget/value_widget_form";

export default props =>
  ComposeForm(
    AggregationTypeFragment,
    WidgetEditValue
  )({
    ...props,
    resourceType: resourceTypes.NUMBER
  });
