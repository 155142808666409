import * as R from "ramda";
import { createSelector } from "reselect";
import { createDeepEqualSelector } from "utils/selector_utils";
import { Map } from "immutable";
import {
  viewModeCrudDuck,
  gridCrudDuck,
  widgetCrudDuck,
  layoutCrudDuck
} from "./dashboard_reducers";
import { permissionsSelector } from "ducks/auth/auth_selectors";
import { isAllowed, OPERATIONS, OBJECT_TYPES } from "utils/auth_utils";

export const entitiesKeypath = ["entities"];

const _getDashboardState = state =>
  state.dashboard
    ? state.dashboard
    : state.get
    ? state.get("dashboard")
    : Map();
const _getEntities = createDeepEqualSelector(
  _getDashboardState,
  dashboardState => dashboardState.getIn(["entities"], Map())
);
const _getDashboardEntities = createDeepEqualSelector(_getEntities, entities =>
  entities.get("dashboards", Map())
);
const _getDashboardEntity = createDeepEqualSelector(
  _getDashboardEntities,
  (state, id) => id,
  (dashboardEntities, id) => dashboardEntities.get(id, Map())
);
export const getDashboards = createDeepEqualSelector(
  ({ dashboard }) => dashboard.result.map(id => dashboard.entities[id]),
  dashboards => dashboards
);
export const getDashboard = createDeepEqualSelector(
  _getDashboardEntity,
  entity => entity
);

export const getDashboardByUser = createDeepEqualSelector(
  _getDashboardEntities,
  (state, user) => user,
  (state, user, first) => first,
  (dashboardEntities, user, first = true) => {
    const dashboards = dashboardEntities
      .filter(dashboard => dashboard.user === user)
      .toList();
    if (first && dashboards.size > 0) {
      return dashboards.get(0);
    } else {
      return dashboards;
    }
  }
);

export const getAllThingsDashboard = createDeepEqualSelector(
  _getEntities,
  getDashboardByUser,
  (dashboardEntities, dashboard) => {
    const viewMode = "DefaultView";
    const grid = "DefaultGrid";
    let viewModeEntity;
    let widgets;
    let layouts;
    let keypath;
    let dashboardId;
    if (dashboard && dashboard.size > 0) {
      dashboardId = dashboard.id;
      // get the default view mode
      const viewModeEntities = viewModeCrudDuck.selectors.getByIds(
        dashboardEntities,
        dashboard.viewModes,
        {
          propertyName: "label",
          propertyValue: viewMode
        }
      );
      if (viewModeEntities && viewModeEntities.size > 0) {
        viewModeEntity = viewModeEntities.get(0); // get first view mode
        // get the default grid, for the details dashboard there will a number of grids based on subthing
        // but only one for all things
        const defaultGrids = gridCrudDuck.selectors.getByIds(
          dashboardEntities,
          viewModeEntity.grids,
          {
            propertyName: "label",
            propertyValue: grid
          }
        );

        if (defaultGrids && defaultGrids.size > 0) {
          // again there will only be one grid for the all things dashboard
          const defaultGrid = defaultGrids.get(0);
          // get the widgets from the grid
          widgets = widgetCrudDuck.selectors.getByIds(
            dashboardEntities,
            defaultGrid.widgets
          );
          // get the layout from the grid, the widgets and the layouts are related via widget.id = layout.[lg|md|sm..].position.i
          layouts = layoutCrudDuck.selectors.get(
            dashboardEntities,
            defaultGrid.layout
          );
          keypath = `viewModes.${viewModeEntity.id}.grids.${defaultGrid.id}.layout`;
        }
      }
    }
    return { dashboardId, widgets, layouts, keypath };
  }
);

export const isMovingWidgets = createDeepEqualSelector(
  _getDashboardEntity,
  entity => entity.get("isMovingWidgets", false)
);

export const dashboardRootSelector = R.prop("dashboard");

export const dashboardsSelector = createDeepEqualSelector(
  dashboardRootSelector,
  R.path(["entities", "dashboards"])
);

export const getWidgetState = createSelector(
  state => state.dashboard.getIn(["entities", "widgets"]),
  (state, props) => props.widgetId,
  (widgets, widgetId) => widgets.get(widgetId)
);

export const userCanCreateDashboard = createSelector(
  permissionsSelector,
  isAllowed(OBJECT_TYPES.Dashboards, OPERATIONS.CREATE)
);

export const userCanUpdateDashboard = createSelector(
  permissionsSelector,
  isAllowed(OBJECT_TYPES.Dashboards, OPERATIONS.UPDATE)
);

export const userCanUpdateDashboardForDomain = createSelector(
  permissionsSelector,
  (state, domain) => domain,
  (permissions, domain) => {
    const val = R.pipe(
      R.pathOr([], ["domains", domain, OBJECT_TYPES.Dashboards]),
      R.includes(OPERATIONS.UPDATE)
    )(permissions);
    return val;
  }
);
