import { pipe, filter, map } from "ramda";
import { fromJS } from "immutable";
import { getLatestResourceTimestamp } from "ducks/aggregators/resources";
import { widgetProp } from "utils/dashboard_utils";

const REPLACEMENT_REGEX = /\${([^${}]*)}/g;
const isValidVariable = s => typeof s === "string" && s.length > 3;
const extractVariableName = s => s.substr(2, s.length - 3);
const parseVariables = ({ widget }) =>
  widget
    ? (widgetProp(widget, "template") || "").match(REPLACEMENT_REGEX) || []
    : [];

export const getResourceNames = pipe(
  parseVariables,
  filter(isValidVariable),
  map(extractVariableName)
);

export default (state, props) =>
  fromJS({
    latestValue: {
      value: null,
      timestamp: getLatestResourceTimestamp({
        state,
        resourceNames: getResourceNames(props),
        thingType: props.params.thingType,
        thingName: props.params.thingName,
        subThingType: props.params.subThingType,
        subthing: props.params.subthing
      })
    }
  });
