import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import DraggableListItem from "./draggable_list_item";
import List from "@material-ui/core/List";
import { compose } from "react-recompose";
import update from "immutability-helper";

class Container extends Component {
  static propTypes = {
    items: PropTypes.array,
    itemTemplate: PropTypes.func,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.moveItem = this.moveItem.bind(this);
    this.handleOnChecked = this.handleOnChecked.bind(this);
    this.handleOnDeleteItem = this.handleOnDeleteItem.bind(this);
  }

  moveItem(dragIndex, hoverIndex) {
    let items = this.props.items;

    let dragItem = items[dragIndex];

    const updatedItems = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragItem]
      ]
    });
    if (this.props.onChange) this.props.onChange({ items: updatedItems });
  }

  handleOnChecked(event) {
    const { index, value } = event;
    const items = [
      ...this.props.items.slice(0, index),
      {
        ...this.props.items[index],
        checked: value
      },
      ...this.props.items.slice(index + 1)
    ];

    if (this.props.onChange) this.props.onChange({ items: items });
  }

  handleOnDeleteItem(event) {
    const item = this.props.items[event.index];
    if (!item.isDefault) {
      const updatedItems = update(this.props.items, {
        $splice: [[event.index, 1]]
      });
      if (this.props.onChange) this.props.onChange({ items: updatedItems });
    }
  }

  render() {
    const { items, itemTemplate, ...rest } = this.props;
    return (
      <List style={{ padding: "0px" }}>
        {items.map((item, i) => (
          <DraggableListItem
            key={item.id}
            index={i}
            id={item.id}
            isDefault={item.isDefault}
            text={item.text}
            checked={item.checked}
            moveItem={this.moveItem}
            onChecked={this.handleOnChecked}
            onDeleteClicked={this.handleOnDeleteItem}
            template={itemTemplate}
            {...rest}
          />
        ))}
      </List>
    );
  }
}

export default compose(DragDropContext(HTML5Backend))(Container);
