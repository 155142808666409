import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles, useTheme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import NotificationIcon from "@material-ui/icons/Notifications";
import RingIcon from "@material-ui/icons/RingVolume";
import WarningIcon from "@material-ui/icons/Warning";
import FormIconButton from "components/buttons/form_icon";
import { isUnread, needsAcknowledgment } from "ducks/events";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "react-recompose";
import * as colors from "themes/colors";
import EmptyMessage from "./styled/empty_message";
import EventListTransitionGroup from "./styled/events_list_transition_group";

const styles = {
  item: (isLast, isUnread) => ({
    ...(isLast
      ? null
      : { borderBottom: `1px solid ${colors.colorCelesteApprox}` }),
    ...(isUnread ? { backgroundColor: colors.backgroundColor } : null),
    overflow: "hidden"
  }),
  inner: needsAcknowledgment => ({
    paddingLeft: 55,
    paddingRight: needsAcknowledgment ? 132 : 20
  }),
  button: { backgroundColor: colors.colorGalleryApprox, width: "auto" }
};

const classifications = {
  NOTIFICATION: {
    icon: RingIcon
  },
  WARNING: {
    icon: WarningIcon
  },
  ALARM: {
    icon: ErrorIcon
  },
  INTERNAL: {
    icon: NotificationIcon
  }
};

const LastUpdated = ({ event }) => (
  <span style={{ color: colors.colorDustyGrayApprox, padding: "5px 0" }}>
    {event.get("acknowledgedBy") ? (
      <span>
        addressed {event.get("acknowledgedAt")} by{" "}
        <b>{event.get("acknowledgedBy")}</b>
      </span>
    ) : null}
    <small>{event.get("timestamp")}</small>
  </span>
);

LastUpdated.propTypes = {
  event: PropTypes.object.isRequired
};

const renderIcon = (icon, color) =>
  React.createElement(icon, { style: { fill: color } });

export const EventList = ({
  events,
  eventsTotal,
  lastEventReadTimestamp = Date.now(),
  onAcknowledge,
  onLoadMore,
  animate = false,
  keyPrefix = "",
  maxEvents = 0,
  overflowText = `Please note that only the first ${maxEvents} events are loaded in this view. Please narrow down your search criteria to display all messages`,
  isUserAuthorizedToAcknowledge,
  classes
}) => {
  const theme = useTheme();
  const eventsToShow = maxEvents > 0 ? events.take(maxEvents) : events;
  return eventsToShow && eventsToShow.size ? (
    <List style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }}>
      <EventListTransitionGroup
        transitionEnter={animate}
        transitionEnterTimeout={500}
        transitionLeave={animate}
        transitionLeaveTimeout={2000}
      >
        {eventsToShow.map((e, index) => (
          <ListItem
            key={keyPrefix + e.get("id")}
            style={styles.item(
              index + 1 === eventsToShow.size,
              isUnread(e, lastEventReadTimestamp)
            )}
          >
            <ListItemIcon>
              {renderIcon(
                classifications[e.get("classification")].icon,
                theme.palette.secondary.main
              )}
            </ListItemIcon>
            <ListItemText
              primary={<strong>{e.get("message")}</strong>}
              secondary={<LastUpdated event={e} />}
            />
            {needsAcknowledgment(e) && isUserAuthorizedToAcknowledge ? (
              <ListItemSecondaryAction classes={classes}>
                <FormIconButton
                  text="Acknowledge"
                  onClick={() => onAcknowledge(e.get("id"))}
                />
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
        {events.size >= maxEvents ? (
          <ListItem>
            <ListItemText primary={overflowText} />
          </ListItem>
        ) : null}
      </EventListTransitionGroup>
    </List>
  ) : (
    <EmptyMessage>
      {eventsTotal > 0 && onLoadMore ? (
        <div>
          <span>No more events loaded</span>{" "}
          <a href="#" onClick={onLoadMore}>
            Load more...
          </a>
        </div>
      ) : (
        <span>No events</span>
      )}
    </EmptyMessage>
  );
};

EventList.propTypes = {
  animate: PropTypes.bool,
  events: PropTypes.object,
  eventsTotal: PropTypes.number,
  keyPrefix: PropTypes.string,
  lastEventReadTimestamp: PropTypes.number,
  onAcknowledge: PropTypes.func,
  onLoadMore: PropTypes.func,
  maxEvents: PropTypes.number,
  overflowText: PropTypes.string,
  isUserAuthorizedToAcknowledge: PropTypes.bool,
  classes: PropTypes.object
};

export default compose(
  withStyles(theme => ({
    root: {
      right: theme.spacing(2)
    }
  }))
)(EventList);
