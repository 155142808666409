import React from "react";
import PropTypes from "prop-types";
import { pure } from "react-recompose";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import * as colors from "themes/colors";

const styles = {
  appBar: {
    position: "fixed",
    height: 80,
    display: "flex",
    backgroundColor: colors.white,
    boxShadow: "0 1px 6px rgba(0,0,0,.1)",
    color: colors.black
  },
  toolbar: {
    height: "100%"
  },
  title: { fontSize: "20px" }
};

const onLeftIconButtonClick = (e, onToggleSidebar) => {
  e.preventDefault(); // Workaround for onClick doublefiring
  onToggleSidebar();
};

const CompactNavBar = withStyles(styles)(props => {
  const { pageTitle = "", onToggleSidebar, classes } = props;

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={e => onLeftIconButtonClick(e, onToggleSidebar)}
        >
          <MenuIcon />
        </IconButton>
        <span className={classes.title}>{pageTitle}</span>
      </Toolbar>
    </AppBar>
  );
});

CompactNavBar.propTypes = {
  pageTitle: PropTypes.string,
  muiTheme: PropTypes.object.isRequired,
  onToggleSidebar: PropTypes.func.isRequired
};

export default pure(CompactNavBar);
