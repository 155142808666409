// Background color
export const backgroundColor = "#f5f5f5";

// Telenor primary colors
export const telenorPrimaryColor = "#00ace7";
export const telenorPrimaryColorAlpha90 = "rgba(0, 172, 231, .9)"; // #00ace7 90% opacity
export const telenorSecondaryColor = "#2aaee2";
export const telenorAccentColor = "#bd1f6a";
export const telenorErrorTextColor = "rgb(244, 67, 54)";
export const telenorTextColor = "#4d4d4d";
export const telenorTextColorAlpha33 = "rgba(77, 77, 77, .33)"; // #4d4d4d 33% opacity
export const telenorTextColorAlpha50 = "rgba(77, 77, 77, .50)"; // #4d4d4d 50% opacity
export const telenorTextColorAlpha70 = "rgba(77, 77, 77, .70)"; // #4d4d4d 70% opacity

/* Base colors */
export const white = "#fff";
export const blackAlpha50 = "rgba(0, 0, 0, .5)";
export const red = "#f00";
export const black = "#000";

/* Grayscale */
export const colorCelesteApprox = "#ccc";
export const colorQuillGrayApprox = "#d3d3d3";
export const colorAltoApprox = "#ddd";
export const colorBonJourApprox = "#e0e0e0";
export const colorDustyGrayApprox = "#9b9b9b";
export const colorMercuryApprox = "#e1e1e1";
export const colorGalleryApprox = "#eeeeee";
export const colorGalleryApproxDarker = "#ebebeb";
export const colorGalleryApproxLighter = "#f0f0f0";

/* Material-ui palette */
export const pinkA200 = "#ff4081";

/* Other colors */
export const colorCuriousBlueApprox = "#3296d4";
export const colorPeriwinkleGrayApprox = "#cce";
export const colorMatisseApprox = "#216ba5";
export const colorNobelApprox = "#b3b3b3";
export const colorSilverChaliceApprox = "#aeaeae";
export const colorHarlequinApprox = "#30cb00";
