import { createSelector } from "reselect";
import { path, pipe, values, propOr } from "ramda";
import { sortJobs } from "./jobs_selectors";

const _getCompletedRequest = path([
  "deviceManagement",
  "jobsCompleted",
  "request"
]);
const _getCompletedEntities = path([
  "deviceManagement",
  "jobsCompleted",
  "entities"
]);

export const getCompletedJobs = createSelector(
  _getCompletedEntities,
  pipe(values, sortJobs)
);
export const isCompletedJobsLoading = createSelector(
  _getCompletedRequest,
  propOr(false, "isLoading")
);
