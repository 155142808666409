import {
  eventActionType,
  eventActionThingType
} from "ducks/events/events_utils";
import { MQTT_DATA_RECEIVED } from "ducks/mqtt";
import { THING_TYPE_THING_COUNT } from "graphql/queries";
import { updateThingCountSuccess } from "./thing_types";

const shouldUpdateThingCount = action => {
  const type = eventActionType(action);
  return (
    (type === "BATCH" || type === "THING.CREATE" || type === "THING.REMOVE") &&
    eventActionThingType(action) != null
  );
};

const updateThingCount = getApolloClient => action =>
  getApolloClient()
    .query({
      fetchPolicy: "network-only",
      query: THING_TYPE_THING_COUNT,
      variables: { thingTypeId: eventActionThingType(action) }
    })
    .then(
      ({
        data: {
          thingType: {
            id,
            thingsConnection: {
              pageInfo: { totalCount: thingCount }
            }
          }
        }
      }) => updateThingCountSuccess({ thingType: id, thingCount })
    );

export const updateThingCountEpic = (action$, store, { getApolloClient }) =>
  action$
    .ofType(MQTT_DATA_RECEIVED)
    .filter(shouldUpdateThingCount)
    .mergeMap(updateThingCount(getApolloClient));
