import { Observable } from "rxjs";
import readJson$ from "utils/rx_file_reader";

export const getFileName = (file, overrideFileName) =>
  overrideFileName || file.name;

export const getContentType = (file, overrideContentType) =>
  overrideContentType || file.type;

export const putFileUrlInvoke$ = (ajax$, readJson$) => ({
  file,
  url,
  contentType
}) => {
  return (contentType === "application/json"
    ? readJson$(file)
    : Observable.of(file)
  ).mergeMap(body =>
    ajax$({
      url,
      method: "PUT",
      body: body,
      headers: { "Content-Type": getContentType(file, contentType) }
    }).map(response => ({ file, response, url }))
  );
};

export default putFileUrlInvoke$(Observable.ajax, readJson$);
