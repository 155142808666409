import React from "react";
import { compose, withProps, withHandlers } from "react-recompose";
import PropTypes from "prop-types";
import { viewModeHasThingListWidget } from "utils/dashboard_utils";

import {
  withDefaultViewModeQuery,
  withDashboardGridProps,
  withDashboardEditState,
  withDashboardEditProps
} from "utils/dashboard_utils";
import { withSetThingtypeViewMode } from "utils/thing_type_utils";

import { Dashboard } from "components/dashboard";
import DashboardToolbar from "./dashboard_toolbar_container";
import { FilterContainer, FILTER_TYPES } from "components/filter";
import NoData from "./no_data";
import Loading from "components/notification/loading";
import {
  makeNewThingDetailsWidget,
  makeNewCollectionWidget,
  getViewModeId
} from "utils/dashboard_utils";
import { withModalHandlers } from "hocs/modal_hocs";

const _withProps = withProps(
  ({ editingViewMode, params, dashboardViewMode }) => ({
    viewModeLabel: editingViewMode ? editingViewMode.get("label") : "",
    showEdit: params.thingName !== undefined,
    viewModeId:
      params.viewMode === "DefaultView"
        ? getViewModeId(dashboardViewMode)
        : params.viewMode,
    dashboardHasThingListWidget: viewModeHasThingListWidget(dashboardViewMode)
  })
);

const withThingList = withProps(({ router }) => {
  if (router && router.location.pathname.split("/").pop() === "thingslist") {
    return {
      widgets: [
        {
          label: "Things List",
          props: {},
          type: "AllThings",
          widgetId: "allThings"
        }
      ],
      gridLayouts: {
        lg: [
          {
            h: 6,
            x: 0,
            y: 0,
            i: "allThings",
            w: 12
          }
        ],
        md: [
          {
            h: 6,
            x: 0,
            y: 0,
            i: "allThings",
            w: 12
          }
        ],
        sm: [
          {
            h: 6,
            x: 0,
            y: 0,
            i: "allThings",
            w: 12
          }
        ],
        xs: [
          {
            h: 6,
            x: 0,
            y: 0,
            i: "allThings",
            w: 12
          }
        ],
        xxs: [
          {
            h: 6,
            x: 0,
            y: 0,
            i: "allThings",
            w: 12
          }
        ]
      }
    };
  } else return {};
});

const withHandleNewWidget = withHandlers({
  handleNewWidget: ({
    setIsMovingWidgets,
    addEditModeWidget,
    openModalWidget,
    type
  }) => ({ resourceId = null, resourceLabel = null, widgetType } = {}) => {
    const newWidget =
      type === "detail"
        ? makeNewThingDetailsWidget({
            resourceLabel,
            resourceId
          })
        : makeNewCollectionWidget({ type: widgetType });

    setIsMovingWidgets(true);
    addEditModeWidget(newWidget);
    openModalWidget({ open: true, widget: newWidget, isNewWidget: true });
  },
  handleCancelWidget: ({ deleteEditModeWidget, closeModalWidget }) => ({
    widget,
    isNewWidget
  }) => {
    closeModalWidget();
    if (widget !== undefined && isNewWidget === true) {
      deleteEditModeWidget(widget);
    }
  }
});

const DashboardContainer = compose(
  withDefaultViewModeQuery,
  withDashboardGridProps,
  withDashboardEditState,
  withDashboardEditProps,
  withSetThingtypeViewMode,
  withModalHandlers({
    name: "Widget",
    onLeave: ({ restoreUndoWidgets }) => () => {
      restoreUndoWidgets();
    }
  }),
  withHandleNewWidget,
  _withProps,
  withThingList
)(props => {
  return (
    <React.Fragment>
      <DashboardToolbar {...props} />

      {props.dashboardIsLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {props.type === "collection" && (
            <FilterContainer
              filterId={props.filterId}
              type={FILTER_TYPES.THINGS}
              classes="classes"
              noGap
            />
          )}
          {!props.thingShadowHasResources &&
            props.type !== "collection" &&
            !props.isMovingWidgets && <NoData data-test="no-data-message" />}
          <Dashboard {...props} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

DashboardContainer.propTypes = {
  dashboardProps: PropTypes.object,
  dashboardToolbarProps: PropTypes.object
};

export default DashboardContainer;
