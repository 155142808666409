import grey from "@material-ui/core/colors/grey";
import * as colors from "./colors";

export const config = {
  logo: {
    src: require("assets/images/logo.min.svg"),
    alt: "Telenor Connexion"
  },
  palette: {
    primary: colors.telenorPrimaryColor,
    secondary: colors.telenorSecondaryColor,
    accent: colors.telenorAccentColor,
    text: colors.telenorTextColor
  }
};
export default {
  fontFamily: "telenor-regular, sans-serif",
  logo: config.logo,
  loginTexts: {
    welcome: null,
    title: null,
    text: null,
    contact: {}
  },
  palette: {
    primary1Color: config.palette.primary,
    accent1Color: config.palette.accent,
    textColor: config.palette.text,
    alternateTextColor: "#fff",
    canvasColor: "#fff",
    borderColor: grey[300],
    disabledColor: "rgba(0, 0, 0, 0.3)",
    backgroundColor: colors.red
  }
};
