import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import withStyles from "../styles";

import { precisionDefaultValidator, isFloatingPoint, onChange } from "../utils";

const Random = validator =>
  withStyles(
    ({
      config,
      disabled,
      index,
      onStrategyParameterChange,
      errors,
      classes
    }) => {
      const _isFloatingPoint = isFloatingPoint(config.desiredDataType);

      return (
        <React.Fragment>
          <TextField
            data-test="min"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              config.desiredDataType,
              "min",
              validator,
              true
            )}
            value={config.strategyParameters.min}
            label="Min"
            className={classes.textField}
            type="number"
            error={!!errors.min}
            helperText={errors.min}
          />
          <TextField
            data-test="max"
            disabled={disabled}
            onChange={onChange(
              onStrategyParameterChange,
              index,
              config.desiredDataType,
              "max",
              validator,
              true
            )}
            value={config.strategyParameters.max}
            label="Max"
            className={classes.textField}
            type="number"
            error={!!errors.max}
            helperText={errors.max}
          />

          {_isFloatingPoint && (
            <TextField
              data-test="precision"
              disabled={disabled}
              onChange={onChange(
                onStrategyParameterChange,
                index,
                "long",
                "precision",
                validator.precision || precisionDefaultValidator
              )}
              value={config.strategyParameters.precision}
              label="Precision"
              className={classes.textField}
              type="number"
              error={!!errors.precision}
              helperText={errors.precision}
            />
          )}
        </React.Fragment>
      );
    }
  );

Random.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onStrategyParameterChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default Random;
