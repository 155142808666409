import LoadingWrapper from "components/loading/loading_wrapper";
import Loadable from "react-loadable";

const DynamicWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'dynamic-widget' */ "./dynamic_widget"),
  loading: LoadingWrapper
});

export default DynamicWidget;
