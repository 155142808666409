export const removeEmptyStrings = value => {
  if (!value) {
    return value;
  }
  let nvalue = value.attributes ? value.attributes : value;
  let clone = Object.assign({}, nvalue);
  Object.keys(nvalue).forEach(key => {
    const val = nvalue[key];
    if (!val || val.size === 0) {
      delete clone[key];
    }
  });
  if (value.attributes) {
    value.attributes = clone;
  } else {
    value = clone;
  }
  return value;
};
