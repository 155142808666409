import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import CellularSignalIcon from "@material-ui/icons/SignalCellular0Bar";
import CellularSignalIcon2 from "@material-ui/icons/SignalCellular1Bar";
import CellularSignalIcon3 from "@material-ui/icons/SignalCellular2Bar";
import CellularSignalIcon4 from "@material-ui/icons/SignalCellular3Bar";
import CellularSignalIcon5 from "@material-ui/icons/SignalCellular4Bar";
import CellularSignalIconNull from "@material-ui/icons/SignalCellularNull";
import WifiSignalIcon from "@material-ui/icons/SignalWifi0Bar";
import WifiSignalIcon2 from "@material-ui/icons/SignalWifi1Bar";
import WifiSignalIcon3 from "@material-ui/icons/SignalWifi2Bar";
import WifiSignalIcon4 from "@material-ui/icons/SignalWifi3Bar";
import WifiSignalIcon5 from "@material-ui/icons/SignalWifi4Bar";
import WifiSignalIconNull from "@material-ui/icons/PermScanWifi";
import Tooltip from "@material-ui/core/Tooltip";
import grey from "@material-ui/core/colors/grey";
import { withStyles } from "@material-ui/core/styles";
import { keyWithValueInRange } from "./utils";

const styles = theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center"
  },
  tooltip: {
    paddingRight: theme.spacing(0.5)
  }
});

const wifiLevels = props => ({
  "0-19": <WifiSignalIcon data-test="wifi-signal-icon" {...props} />,
  "20-39": <WifiSignalIcon2 data-test="wifi-signal-icon-2" {...props} />,
  "40-59": <WifiSignalIcon3 data-test="wifi-signal-icon-3" {...props} />,
  "60-79": <WifiSignalIcon4 data-test="wifi-signal-icon-4" {...props} />,
  "80-100": <WifiSignalIcon5 data-test="wifi-signal-icon-5" {...props} />
});

const cellularLevels = props => ({
  "0-19": <CellularSignalIcon data-test="cellular-signal-icon" {...props} />,
  "20-39": (
    <CellularSignalIcon2 data-test="cellular-signal-icon-2" {...props} />
  ),
  "40-59": (
    <CellularSignalIcon3 data-test="cellular-signal-icon-3" {...props} />
  ),
  "60-79": (
    <CellularSignalIcon4 data-test="cellular-signal-icon-4" {...props} />
  ),
  "80-100": (
    <CellularSignalIcon5 data-test="cellular-signal-icon-5" {...props} />
  )
});

const Rssi = ({ networkType, signalStrength, showNetworkType, classes }) => {
  if (networkType === undefined || signalStrength === undefined) {
    return <span />;
  }
  const isWifi = networkType === "wifi";
  const displayName = isWifi ? "WiFi" : networkType.toUpperCase();
  if (networkType && R.isNil(signalStrength)) {
    return (
      <span
        style={{
          display: "inline-block",
          height: "24px",
          padding: "6px 0",
          verticalAlign: "bottom"
        }}
      >
        {displayName}
      </span>
    );
  }
  const SignalIconProps = { color: "default", nativeColor: grey[700] };
  const rssiLevels = isWifi
    ? wifiLevels(SignalIconProps)
    : cellularLevels(SignalIconProps);
  const rssiRange = keyWithValueInRange(signalStrength, rssiLevels);

  return (
    <span className={classes.wrapper}>
      <Tooltip title={`${signalStrength}%`} className={classes.tooltip}>
        {rssiRange === undefined ? (
          isWifi ? (
            <WifiSignalIconNull
              data-test="wifi-signal-icon-null"
              {...SignalIconProps}
            />
          ) : (
            <CellularSignalIconNull
              data-test="cellular-signal-icon-null"
              {...SignalIconProps}
            />
          )
        ) : (
          rssiLevels[rssiRange]
        )}
      </Tooltip>
      {showNetworkType && <span>{displayName}</span>}
    </span>
  );
};

Rssi.propTypes = {
  networkType: PropTypes.string,
  signalStrength: PropTypes.number,
  showNetworkType: PropTypes.bool,
  classes: PropTypes.object
};

Rssi.defaultProps = {
  showNetworkType: true
};

export default withStyles(styles)(Rssi);
