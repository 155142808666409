import * as R from "ramda";
import { List, Map } from "immutable";
import { createDeepEqualSelector } from "utils/selector_utils";

export const resourcePath = resource => {
  if (resource === undefined) return;
  const resourceParts = resource.split("/");
  // thingType1/r1
  // thingType1/virtual/vr2
  // thingType1/subthing/untyped/s1
  // thingType1/subthing/untyped/virtual/vr2
  let thingType;
  let subthing;
  let subThingType;
  let resourceName;
  let isVirtual;
  const length = resourceParts.length;
  resourceName = resourceParts[length === 1 ? 0 : length - 1];
  thingType = length > 1 ? resourceParts[0] : undefined;
  const isTcxn = resourceParts[1] === "tcxn";
  if ((!isTcxn && length === 3) || length === 5) {
    isVirtual = true;
  }
  if (!isTcxn && length >= 4) {
    subthing = resourceParts[1];
    subThingType = resourceParts[2];
  }

  return {
    isTcxn,
    subthing,
    subThingType,
    resourceName,
    thingType,
    isVirtual,
    resource
  };
};

export const convertResourceToObservationResource = resource => {
  const parts = resourcePath(resource);
  const isTcxn = parts.isTcxn;

  if (isTcxn) {
    return resource
      .split("/")
      .slice(1)
      .join("/");
  } else if (parts.subThingType) {
    return parts.thingType + "/" + parts.resourceName;
  } else {
    return parts.resourceName;
  }
};

export const getResource = (resourceName, resources) => {
  if (resources === undefined) return resourceName;
  const fr = resources.filter(r => r.indexOf(resourceName) >= 0);
  if (fr.length > 0) {
    return fr[0];
  } else {
    return resourceName;
  }
};

export const _buildResourceIdPath = ({
  thingType,
  subthing,
  subThingType = "untyped",
  isVirtual,
  resourceName
}) => {
  let keyPath = thingType ? [thingType] : [];
  if (subthing && subthing !== "default")
    keyPath = [...keyPath, "subthing", subThingType];
  if (isVirtual) keyPath = [...keyPath, "virtual"];
  keyPath = [...keyPath, resourceName];
  return keyPath.join("/");
};

export const buildResourceIdPath = createDeepEqualSelector(
  props => props,
  props => _buildResourceIdPath(props)
);

export const addResource = ({ schemaState, resourceType, resourcePath }) => {
  // 1/sub1/untyped/temp
  // thing 2
  // thing virtual 3
  // thing subthing 4
  // thing subthing virtual 5
  if (resourcePath === undefined || resourcePath.length === 0)
    return schemaState;

  const type = resourceType ? resourceType : "string";
  const vrPath = resourcePath.split("/");
  const resultId = vrPath[0];
  const virtualName = vrPath[vrPath.length - 1];
  const pelementId = vrPath.slice(0, vrPath.length - 1);

  const stype = path => {
    const i = path.length;
    if (i === 1) {
      return "thing";
    } else if (path[i - 1] === "virtual") {
      return "virtual";
    } else if (i === 3) {
      return "subthing";
    } else {
      return undefined;
    }
  };

  let { state } = vrPath.reduce(
    (mem, key, i) => {
      if (i + 1 === vrPath.length) return mem;
      const newPath = [...mem.path, key];
      const elementId = newPath.join("/");
      const schemaType = stype(newPath);
      if (
        schemaType !== undefined &&
        schemaState.getIn(["entities", "schemaElement", elementId]) ===
          undefined
      ) {
        let parentId;
        if (schemaType === "subthing") {
          parentId = [mem.path[0]];
        } else if (schemaType === "virtual") {
          parentId = mem.path;
        }

        mem.state = mem.state.mergeDeep({
          entities: {
            schemaElement: {
              [elementId]: {
                id: elementId,
                parentId: parentId ? parentId.join("/") : undefined,
                schemaType
              }
            }
          }
        });
        // child to parent
        if (parentId) {
          const path = ["entities", "schemaElement", ...parentId];
          let parentElement = mem.state.getIn(path);
          let useState = mem.state;
          let useMem = true;
          if (parentElement === undefined) {
            useMem = false;
            useState = schemaState;
            parentElement = schemaState.getIn(path);
          }
          useState = useState.setIn(
            ["entities", "schemaElement", ...parentId],
            parentElement.set(
              "elements",
              parentElement.get("elements", List()).push(elementId)
            )
          );
          if (useMem) {
            mem.state = useState;
          } else {
            schemaState = useState;
          }
        }
      }
      mem.path = newPath;
      return mem;
    },
    {
      path: [],
      state: Map({})
    }
  );
  state = state.mergeDeep({
    entities: {
      resources: {
        [resourcePath]: {
          id: resourcePath,
          elementId: pelementId.join("/"),
          name: virtualName,
          type
        }
      }
    }
  });
  schemaState = schemaState.mergeDeep(state);
  const elementIdString = pelementId.join("/");
  let resources = schemaState.getIn(
    ["entities", "schemaElement", elementIdString, "resources"],
    List()
  );
  resources = resources.push(resourcePath);
  schemaState = schemaState.setIn(
    ["entities", "schemaElement", elementIdString, "resources"],
    resources
  );
  // finally add the root id to result if not there
  let results = schemaState.get("result", List());
  if (results.indexOf(resultId) < 0) {
    results = results.push(resultId);
    schemaState = schemaState.set("results", results);
  }
  return schemaState;
};

export const _missingKeys = (resources, thingShadow) =>
  (thingShadow || Map())
    .filter((v, key) => key.indexOf("/") > 0 && resources.indexOf(key) < 0)
    .reduce((mem, v, k) => [...mem, k], []);

/**
 * Filters resources based on thing type and tcxn.
 * The resource list can come in as a List or a Map keyed with the ids of the resource.
 * @param resources
 * @param thingType
 */
export const filterResourcesOnThingType = (resources = Map(), thingType) =>
  resources.filter((val, key) => {
    let id = val.get("id");
    id = id ? id : key;
    return id && id.indexOf(`${thingType}/`) >= 0 && id.indexOf("tcxn/") === -1;
  });

export const _lowerCaseSubThing = resource =>
  resource && resource.indexOf("subThing") > 0
    ? resource.replace("subThing", "subthing")
    : resource;

export const typeValue = value =>
  isNaN(value)
    ? { virtualValue: value, resourceType: "string" }
    : { virtualValue: Number(value), resourceType: "long" };

export const isVirtual = resourceId =>
  resourceId && resourceId.indexOf("/virtual/") > -1;

export const isSubThingType = R.pipe(
  resource => resourcePath(resource),
  R.prop("subThingType"),
  R.complement(R.isNil)
);
