import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withPaletteColor } from "utils/theme_utils";

const ParentObject = props => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(4,4)">
      <path d="M20-3v24H-4V-3z" />
      <g>
        <circle cx="7.313" cy="5.063" r="5.063" fill={props.color} />
        <circle cx="2.813" cy="15.188" r="2.313" stroke={props.color} />
        <circle cx="12.938" cy="15.188" r="2.313" stroke={props.color} />
        <path
          stroke={props.color}
          strokeLinecap="square"
          d="M7.313 5.063l-3.375 7.875M7.313 5.063l4.5 7.875"
        />
      </g>
    </g>
  </SvgIcon>
);

ParentObject.propTypes = {
  color: PropTypes.string.isRequired
};

export default withPaletteColor("main")(ParentObject);
