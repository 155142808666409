import { createAction, handleActions } from "redux-actions";
import { fromJS } from "immutable";
import { Dashboard } from "./records";
import guid from "easy-guid";

export const DASHBOARDS_REQUEST = "DASHBOARDS_REQUEST";
export const DASHBOARDS_SUCCESS = "DASHBOARDS_SUCCESS";
export const DASHBOARDS_FAILURE = "DASHBOARDS_FAILURE";
export const REQUEST_RESET = "REQUEST_RESET";

export const DASHBOARD_SAVE_REQUEST = "DASHBOARD_SAVE_REQUEST";
export const DASHBOARD_SAVE_FAILURE = "DASHBOARD_SAVE_FAILURE";

export const DASHBOARD_SAVE_WIDGET_REQUEST = "DASHBOARD_SAVE_WIDGET_REQUEST";
export const DASHBOARD_SAVE_WIDGET_FAILURE = "DASHBOARD_SAVE_WIDGET_FAILURE";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_CREATE = "DASHBOARD_CREATE";
export const DASHBOARD_UPDATE = "DASHBOARD_UPDATE";
export const DASHBOARD_DELETE = "DASHBOARD_DELETE";
export const DASHBOARD_EDITING = "DASHBOARD_EDITING";

// relationships
export const VIEWMODES_CREATE = "viewModes_CREATE";
export const VIEWMODES_DELETE = "viewModes_DELETE";

export const initialState = fromJS({});

const dashboard = handleActions(
  {
    DASHBOARDS_SUCCESS: (state, { payload: { entities } }) =>
      entities && entities.dashboards
        ? Object.keys(entities.dashboards).reduce(
            (state, key) =>
              state.set(key, new Dashboard(entities.dashboards[key])),
            state
          )
        : state,

    DASHBOARD_SUCCESS: (state, { payload }) =>
      state.mergeDeep({
        [payload.id]: new Dashboard(...payload)
      }),

    DASHBOARD_UPDATE_SUCCESS: (state, { payload }) =>
      state.mergeDeep({
        [payload.id]: new Dashboard(...payload)
      }),

    DASHBOARD_CREATE: (
      state,
      {
        id = guid.new(16),
        name = "new dashboard",
        user,
        thingType,
        isMovingWidgets
      }
    ) =>
      state.mergeDeep({
        [id]: new Dashboard({
          id,
          name,
          user,
          thingType,
          isMovingWidgets
        })
      }),

    DASHBOARD_UPDATE: (state, { dashboard }) =>
      state.mergeDeep({
        [dashboard.id]: { dashboard }
      }),

    DASHBOARD_DELETE: (state, { id }) => state.removeIn([id]),

    DASHBOARD_EDITING: (state, { id, isMovingWidgets }) =>
      state.setIn([id, "isMovingWidgets"], isMovingWidgets),

    // --- relationships
    [VIEWMODES_CREATE]: (state, { parentId, entity: { id } }) =>
      state.mergeDeep({
        [parentId]: {
          viewModes: [id]
        }
      }),

    [VIEWMODES_DELETE]: (state, { parentId, id }) =>
      state.setIn(
        [parentId, "viewModes"],
        state.getIn([parentId, "viewModes"], []).filter(key => key !== id)
      )
  },
  initialState
);

export default dashboard;

export const actions = {
  getDashboards: createAction(DASHBOARDS_REQUEST),
  getDashboardsFailure: createAction(DASHBOARDS_FAILURE),
  getDashboardsSuccess: createAction(DASHBOARDS_SUCCESS),
  requestReset: createAction(REQUEST_RESET),

  saveDashboard: createAction(DASHBOARD_SAVE_REQUEST),
  saveDashboardFailure: createAction(DASHBOARD_SAVE_FAILURE),

  saveDashboardWidget: createAction(DASHBOARD_SAVE_WIDGET_REQUEST),
  saveDashboardWidgetFailure: createAction(DASHBOARD_SAVE_FAILURE),

  setEditing: (id, isMovingWidgets = true) => {
    // TODO: check if this is needed
    return {
      type: DASHBOARD_EDITING,
      id,
      isMovingWidgets
    };
  }
};
