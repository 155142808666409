import { graphql } from "@apollo/client/react/hoc";
import defaultThingsFields from "ducks/dashboard/thing_list_default_fields";
import guid from "easy-guid";
import {
  ADD_GRID_MUTATION,
  CREATE_THING_TYPE_VIEWMODE_MUTATION,
  DEFAULT_THINGTYPE_VIEWMODE_QUERY,
  GET_THING_TYPE,
  THING_TYPE_VIEWMODES_QUERY,
  UPDATE_GRID_MUTATION
} from "graphql/queries";
import * as R from "ramda";
import { compose, withPropsOnChange, withStateHandlers } from "react-recompose";
import { nonEmptyString } from "utils/general_utils";
import { resourcePath } from "utils/resource_utils";
import * as imu from "./immutable_utils";
import { sortByCaseInsensitive } from "./sorters";

const NO_SUBTHING_TYPE = "__no_subthing_type";
const partitionResourcesBySubthingType = resources =>
  R.groupBy(
    ({ id }) => resourcePath(id).subThingType || NO_SUBTHING_TYPE,
    R.values(resources)
  );

export const getDashboardViewMode = R.curry(({ viewModeId }, dashboard) => {
  try {
    return R.pipe(
      R.prop("viewModes"),
      R.find(R.propEq("viewModeId", viewModeId)),
      R.defaultTo({})
    )(dashboard);
  } catch (e) {
    return {};
  }
});

export const getViewModeGrid = R.curry(({ gridId, gridType }, viewMode) => {
  try {
    return R.pipe(
      R.propOr([], "grids"),
      R.find(R.both(R.propEq("type", gridType), R.propEq("gridId", gridId))),
      R.defaultTo({})
    )(viewMode);
  } catch (e) {
    return {};
  }
});

export const getViewModeFromParams = R.prop("viewMode");
export const getThingTypeFromParams = R.prop("thingType");

const isListLocation = (thingType, location) =>
  location.pathname.endsWith(`/${thingType}/list`);

export const gridTypeFromParams = (params, location) => {
  if (isListLocation(params.thingType, location)) {
    return "thingList";
  }

  if (nonEmptyString(params.thingName)) {
    if (params.subthing !== "default") {
      return "subThings";
    } else {
      return "thingWidgets";
    }
  }

  return "collectionWidgets";
};

export const gridIdFromParams = (params, location) => {
  if (isListLocation(params.thingType, location)) {
    return "thingList";
  }

  if (nonEmptyString(params.thingName)) {
    if (params.subthing !== "default") {
      return params.subThingType;
    } else {
      return "thingWidgets";
    }
  }

  return "collectionWidgets";
};

export const getWidgetLayout = R.curry(({ widget, size }, layout) =>
  R.pipe(R.propOr([], size), R.find(R.propEq("i", widget)))(layout)
);

export const makeDefaultThingListWidget = () => ({
  widgetId: "thingList",
  type: "thingList",
  props: {}
});

export const getThingListWidget = dashboardViewMode => {
  try {
    const grid = R.find(R.propEq("type", "thingList"), dashboardViewMode.grids);
    return grid.widgets[0];
  } catch (e) {
    return undefined;
  }
};

export const getThingListColumnConfig = dashboardViewMode => {
  try {
    const widget = getThingListWidget(dashboardViewMode);
    return widget.props.columnConfig || defaultThingsFields;
  } catch (e) {
    return defaultThingsFields;
  }
};

export const makeGridLayouts = largeLayouts => {
  const sortedLayouts = R.pipe(
    R.defaultTo([]),
    R.sortWith([R.ascend(R.prop("y")), R.ascend(R.prop("x"))])
  )(largeLayouts);

  const smallLayouts = sortedLayouts.map((entry, i) => ({
    ...entry,
    x: 0,
    y: i,
    w: 1,
    h: 3
  }));

  return {
    lg: sortedLayouts,
    md: sortedLayouts,
    sm: sortedLayouts,
    xs: smallLayouts,
    xxs: smallLayouts
  };
};

export const widgetProp = (widget, propName, defaultValue) =>
  R.pipe(
    imu.unImmute,
    R.defaultTo({}),
    R.ifElse(
      R.propIs(Object, "props"),
      R.path(["props", propName]),
      R.prop(propName)
    ),
    R.when(R.equals(undefined), R.always(defaultValue))
  )(widget);

export const widgetId = widget => {
  if (widget == null) {
    throw new Error("widget not defined");
  }

  const unImmutableWidget = imu.unImmute(widget);
  const widgetId = unImmutableWidget.widgetId || unImmutableWidget.id;

  if (!nonEmptyString(widgetId)) {
    throw new Error("widget id not defined");
  }

  return widgetId;
};

export const randomViewModeId = () => `vm_${guid.new(16)}`;
export const randomWidgetId = () => guid.new(16);

export const makeNewCollectionWidget = ({ type }) => ({
  type: R.when(R.isNil, R.always("ThingCredentials"))(type),
  widgetId: randomWidgetId(),
  label: "New Widget",
  props: {}
});

export const makeNewThingDetailsWidget = ({
  resourceLabel = null,
  resourceId = null
} = {}) => ({
  widgetId: randomWidgetId(),
  type: "Value",
  label: resourceLabel || "New Widget",
  props: resourceId ? { resourceId } : {}
});

export const addWidgetToLayout = (widgetId, layout) =>
  R.pipe(
    R.map(R.over(R.lensProp("y"), R.add(3))),
    R.prepend({ i: widgetId, x: 0, y: 0, w: 4, h: 3 })
  )(layout);

export const removeWidgetFromLayout = (widgetId, layout) =>
  R.reject(R.propEq("i", widgetId), layout);

const CREATE_NAME = "createThingTypeViewModeMutation";
// const QUERY_NAME = "dashboardsQuery";
const DEFAULT_QUERY_NAME = "defaultViewModeQuery";
const GET_THING_TYPE_NAME = "dashboardThingTypeQuery";

export const withDefaultViewModeQuery = compose(
  graphql(GET_THING_TYPE, {
    name: GET_THING_TYPE_NAME,
    skip: props => typeof getThingTypeFromParams(props.params) !== "string",
    options: props => ({
      variables: {
        thingTypeId: getThingTypeFromParams(props.params) || props.thingType
      }
    })
  }),
  graphql(CREATE_THING_TYPE_VIEWMODE_MUTATION, {
    name: CREATE_NAME
  }),
  graphql(DEFAULT_THINGTYPE_VIEWMODE_QUERY, {
    name: DEFAULT_QUERY_NAME,
    skip: props => typeof getThingTypeFromParams(props.params) !== "string",
    options: props => ({
      variables: {
        thingType: getThingTypeFromParams(props.params) || props.thingType,
        preferredViewModeId: getViewModeFromParams(props.params)
      }
    })
  })
);

const createDefaultThingTypeViewMode = props => {
  const thingType = R.path([GET_THING_TYPE_NAME, "thingType"], props);
  const thingTypeId = R.path([GET_THING_TYPE_NAME, "thingType", "id"], props);
  const mutation = props[CREATE_NAME];

  return mutation({
    variables: createThingTypeViewModeVariables({
      thingType,
      userCanCreateDashboard: props.userCanCreateDashboard,
      userDomain: props.userDomain
    }),
    update: (proxy, mutationResult) => {
      proxy.writeQuery({
        query: DEFAULT_THINGTYPE_VIEWMODE_QUERY,
        variables: {
          thingType: thingTypeId,
          preferredViewModeId: "DefaultView"
        },
        data: {
          defaultViewMode: R.path(
            ["data", "createViewMode", "viewMode"],
            mutationResult
          )
        }
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: THING_TYPE_VIEWMODES_QUERY,
        variables: { thingType: thingTypeId }
      }
    ]
  });
};

export const withDashboardGridProps = withPropsOnChange(
  (props, nextProps) =>
    nextProps.location.pathname !== props.location.pathname ||
    nextProps.params.viewMode !== props.params.viewMode ||
    nextProps.params.subthing !== props.params.subthing ||
    nextProps.params.subthingType !== props.params.subthingType ||
    nextProps[DEFAULT_QUERY_NAME].loading !==
      props[DEFAULT_QUERY_NAME].loading ||
    nextProps[GET_THING_TYPE_NAME].loading !==
      props[GET_THING_TYPE_NAME].loading ||
    R.path([DEFAULT_QUERY_NAME, "defaultViewMode"], nextProps) !==
      R.path([DEFAULT_QUERY_NAME, "defaultViewMode"], props),
  props => {
    const dashboardViewMode = props[DEFAULT_QUERY_NAME].defaultViewMode;
    const isThingTypeLoading = props[GET_THING_TYPE_NAME].loading;
    const isQueryLoading = props[DEFAULT_QUERY_NAME].loading;
    const hasDashboard = dashboardViewMode != null;
    const shouldCreateViewMode =
      !isQueryLoading && !isThingTypeLoading && !hasDashboard;

    if (shouldCreateViewMode) {
      createDefaultThingTypeViewMode(props);
    }

    const viewModeId = R.prop("id", dashboardViewMode);
    const gridType = gridTypeFromParams(props.params, props.location);
    const gridId = gridIdFromParams(props.params, props.location);

    const dashboardGrid = getViewModeGrid(
      { gridType, gridId },
      dashboardViewMode
    );

    return {
      widgets: dashboardGrid.widgets,
      gridLayouts: makeGridLayouts(R.path(["layout", "all"], dashboardGrid)),
      dashboardIsLoading: isQueryLoading || isThingTypeLoading || !hasDashboard,
      dashboardViewMode,
      viewModeId,
      gridId,
      gridType
    };
  }
);

export const _shouldAddGrid = props => {
  const existingGrid = getViewModeGrid(
    { gridId: props.gridId, gridType: props.gridType },
    props.dashboardViewMode
  );
  return existingGrid == null || existingGrid.gridId == null;
};

export const _updateGridMutationProps = (state, props) => ({
  viewModeId: props.dashboardViewMode.id,
  gridId: props.gridId,
  widgets: state.editModeWidgets,
  layout: { all: state.editModeLayout.lg }
});

export const _addGridMutationProps = (state, props) => ({
  ..._updateGridMutationProps(state, props),
  type: props.gridType
});

const _updateGridOptimisticResponse = (
  dashboardViewMode,
  { gridId, widgets, layout }
) => ({
  updateGrid: {
    viewMode: {
      ...dashboardViewMode,
      grids: dashboardViewMode.grids.map(_grid =>
        _grid.gridId === gridId ? { ..._grid, widgets, layout } : _grid
      )
    }
  }
});

const _addGrid = (state, props) => {
  const variables = _addGridMutationProps(state, props);
  props.addGridMutation({
    variables,
    update: () => {
      if (props.navigateLocationOnClose) {
        props.router.push(props.navigateLocationOnClose);
      }
    },
    optimisticResponse: _updateGridOptimisticResponse(
      props.dashboardViewMode,
      variables
    )
  });
};

const _updateGrid = (state, props) => {
  const variables = _updateGridMutationProps(state, props);
  props.updateGridMutation({
    variables,
    update: () => {
      if (props.navigateLocationOnClose) {
        props.router.push(props.navigateLocationOnClose);
      }
    },
    optimisticResponse: _updateGridOptimisticResponse(
      props.dashboardViewMode,
      variables
    )
  });
};

export const withDashboardEditState = compose(
  graphql(ADD_GRID_MUTATION, { name: "addGridMutation" }),
  graphql(UPDATE_GRID_MUTATION, { name: "updateGridMutation" }),
  withStateHandlers(
    {
      isMovingWidgets: false,
      editModeLayout: null,
      editModeWidgets: null
    },
    {
      saveGrid: (state, props) => () => {
        if (_shouldAddGrid(props)) {
          _addGrid(state, props);
        } else {
          _updateGrid(state, props);
        }
        return {
          isMovingWidgets: false,
          editModeLayout: null,
          editModeWidgets: null
        };
      },
      setIsMovingWidgets: (
        state,
        { gridLayouts, widgets }
      ) => isMovingWidgets => {
        if (!state.isMovingWidgets && isMovingWidgets) {
          return {
            ...state,
            isMovingWidgets: true,
            editModeLayout: gridLayouts,
            editModeWidgets: widgets || []
          };
        } else if (state.isMovingWidgets && !isMovingWidgets) {
          return {
            ...state,
            isMovingWidgets: false,
            editModeLayout: null,
            editModeWidgets: null
          };
        }
      },
      setEditModeLayout: state => layout => {
        return {
          ...state,
          editModeLayout: layout
        };
      },
      setEditModeWidgetsAndLayout: state => ({ widgets, layout }) => {
        return {
          ...state,
          editModeWidgets: widgets,
          editModeLayout: makeGridLayouts(layout)
        };
      },
      addEditModeWidget: state => newWidget => {
        const newLayout = addWidgetToLayout(
          newWidget.widgetId,
          state.editModeLayout.lg
        );
        const newWidgets = [...state.editModeWidgets, newWidget];
        return {
          ...state,
          editModeWidgets: newWidgets,
          editModeLayout: makeGridLayouts(newLayout)
        };
      },
      deleteEditModeWidget: state => widget => {
        const newLayout = removeWidgetFromLayout(
          widget.widgetId,
          state.editModeLayout.lg
        );
        return {
          ...state,
          editModeLayout: makeGridLayouts(newLayout),
          editModeWidgets: R.reject(
            R.propEq("widgetId", widget.widgetId),
            state.editModeWidgets
          )
        };
      },
      updateEditModeWidget: state => updatedWidget => {
        const widgetIndex = R.findIndex(
          R.propEq("widgetId", updatedWidget.widgetId),
          state.editModeWidgets
        );
        if (widgetIndex < 0) {
          throw new Error("widget not found");
        }
        return {
          ...state,
          editModeWidgets: R.update(
            widgetIndex,
            updatedWidget,
            state.editModeWidgets
          )
        };
      },
      restoreUndoWidgets: state => () => {
        return {
          ...state,
          editModeLayout: null,
          editModeWidgets: null
        };
      }
    }
  )
);

export const withDashboardEditProps = withPropsOnChange(
  [
    "isMovingWidgets",
    "widgets",
    "gridLayouts",
    "editModeLayout",
    "editModeWidgets"
  ],
  ({
    isMovingWidgets,
    widgets,
    gridLayouts,
    editModeLayout,
    editModeWidgets
  }) =>
    isMovingWidgets === true
      ? { widgets: editModeWidgets, gridLayouts: editModeLayout }
      : { widgets, gridLayouts }
);

const GRID_WIDTH = 12;
const WIDGETS_PER_ROW = 3;
const WIDGET_HEIGHT = 3;
const WIDGET_WIDTH = GRID_WIDTH / WIDGETS_PER_ROW;

export const distributeWidgetGridLayout = widgets =>
  widgets.map((widget, i) => ({
    x: (i * WIDGET_WIDTH) % GRID_WIDTH,
    y: WIDGET_HEIGHT * Math.floor(i / WIDGETS_PER_ROW),
    w: WIDGET_WIDTH,
    h: WIDGET_HEIGHT,
    i: widget.widgetId
  }));

const widgetTypeFromResource = resource =>
  R.propEq("type", "geo_point", resource) ? "Map" : "Value";

const thingWidgetFromResource = makeRandomWidgetId => resource => ({
  widgetId: makeRandomWidgetId(),
  type: widgetTypeFromResource(resource),
  label: R.pathOr(resource.name, ["metadata", "label"], resource),
  props: { resourceId: resource.id, resourceName: resource.id }
});

export const resourceIdFromWidget = widget =>
  widgetProp(widget, "resourceId") ||
  widgetProp(widget, "resourceName") ||
  widgetProp(widget, "histogramResource");

const addThingWidgetsToGrid = ({
  viewMode,
  gridId,
  gridType,
  resources,
  makeRandomWidgetId
}) => {
  const existingGridIndex = R.pipe(
    R.prop("grids"),
    R.findIndex(R.both(R.propEq("type", gridType), R.propEq("gridId", gridId)))
  )(viewMode);

  const existingWidgets = R.pipe(
    R.view(R.lensPath(["grids", existingGridIndex, "widgets"])),
    R.defaultTo([])
  )(viewMode);

  if (existingWidgets.length > 0) {
    return viewMode;
  }

  const gridWidgets = R.pipe(
    R.values,
    R.map(thingWidgetFromResource(makeRandomWidgetId)),
    sortByCaseInsensitive("label")
  )(resources);

  const thingWidgetsGrid = {
    gridId: gridId,
    type: gridType,
    layout: { all: distributeWidgetGridLayout(gridWidgets) },
    widgets: gridWidgets
  };

  if (existingGridIndex >= 0) {
    return R.over(
      R.lensProp("grids"),
      R.update(existingGridIndex, thingWidgetsGrid),
      viewMode
    );
  } else {
    return R.over(R.lensProp("grids"), R.append(thingWidgetsGrid), viewMode);
  }
};

export const addThingWidgetsToThingTypeViewMode = ({
  viewMode,
  resources,
  makeRandomWidgetId = randomWidgetId
}) => {
  const resourcesBySubthingType = partitionResourcesBySubthingType(resources);

  const _getResources = subthingType => resourcesBySubthingType[subthingType];

  const _getGridType = subthingType =>
    subthingType === NO_SUBTHING_TYPE ? "thingWidgets" : "subThings";

  const _getGridId = subthingType =>
    subthingType === NO_SUBTHING_TYPE ? "thingWidgets" : subthingType;

  return Object.keys(resourcesBySubthingType).reduce(
    (currentViewMode, subthingType) => {
      return addThingWidgetsToGrid({
        viewMode: currentViewMode,
        resources: _getResources(subthingType),
        gridType: _getGridType(subthingType),
        gridId: _getGridId(subthingType),
        makeRandomWidgetId
      });
    },
    viewMode
  );
};

export const createThingTypeViewModeVariables = ({
  thingType,
  makeRandomWidgetId = randomWidgetId,
  userCanCreateDashboard,
  userDomain
}) => {
  const thingsListWidget = {
    widgetId: makeRandomWidgetId(),
    label: "Things",
    type: "AllThings",
    props: {}
  };

  const thingsMapWidget = {
    widgetId: makeRandomWidgetId(),
    label: "Things Map",
    type: "ThingsMap",
    props: {}
  };
  const eventsWidget = {
    widgetId: makeRandomWidgetId(),
    label: "Events",
    type: "Events",
    props: { classification: "ALL" }
  };
  const credentialsWidget = {
    widgetId: makeRandomWidgetId(),
    label: "Thing Credentials",
    type: "ThingCredentials",
    props: { includeHeader: true, includeFooter: false }
  };

  const widgets = [
    thingsListWidget,
    thingsMapWidget,
    eventsWidget,
    credentialsWidget
  ];
  const layout = {
    all: [
      { x: 0, h: 3, y: 0, w: 12, i: thingsListWidget.widgetId },
      { x: 0, h: 3, y: 7, w: 8, i: thingsMapWidget.widgetId },
      { x: 8, h: 3, y: 7, w: 4, i: eventsWidget.widgetId },
      { x: 0, h: 5, y: 14, w: 12, i: credentialsWidget.widgetId }
    ]
  };

  const grids = [
    {
      gridId: "collectionWidgets",
      label: "Default View",
      type: "collectionWidgets",
      layout,
      widgets
    }
  ];

  if (userCanCreateDashboard === true) {
    return {
      label: "Default View",
      domain: userDomain,
      thingType: thingType.id,
      isPersonal: false,
      isDefault: true,
      grids
    };
  } else {
    return {
      label: "Personal View",
      domain: null,
      thingType: thingType.id,
      isPersonal: true,
      isDefault: true,
      grids
    };
  }
};

export const viewModeHasThingListWidget = R.pipe(
  R.propOr([], "grids"),
  R.find(R.propEq("type", "collectionWidgets")),
  R.ifElse(
    R.isNil,
    R.F,
    R.pipe(R.propOr([], "widgets"), R.any(R.propEq("type", "AllThings")))
  )
);

export const getViewModeId = R.pathOr("", ["id"]);
