import styled from "styled-components";
import * as colors from "themes/colors";

export default styled.div`
  top: 0px;
  bottom: 0px;
  flex: 1;
  height: 100%;

  a.leaflet-control-zoom-in,
  a.leaflet-control-zoom-out {
    line-height: 27px !important;
  }
  .leaflet-marker-icon {
    border: none !important;
    background: none;
  }
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    background-image: none;
  }

  .custom-cluster-marker {
    background-color: ${colors.telenorPrimaryColorAlpha90};
    width: 30px;
    height: 30px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    position: relative;
    left: -8px;

    span {
      line-height: 30px;
      color: ${colors.white};
    }
  }

  /* Because of an issue in leaflet 1 we need to use a custom marker until fixed.
     https://github.com/Leaflet/Leaflet/issues/4849 */
  .custom-marker {
    width: 24px !important;
    height: 34px !important;
    margin-left: -12px !important;
    margin-top: -34px !important;
  }

  .trace-marker {
    width: 10px !important;
    height: 10px !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
  }
`;
