import React from "react";
import getWidgetBase from "../get_widget_base";
import TimeseriesWidget from "./timeseries_widget_container";
import Footer from "../observation_widget_footer";

export const getWidget = props =>
  getWidgetBase({
    ...props,
    footer: <Footer {...props} />,
    widgetComp: TimeseriesWidget
  });
