import React from "react";
import PropTypes from "prop-types";
import Dashboard from "components/allthings_dashboard";

const AllThingsContainer = props => {
  return (
    <section>
      <Dashboard
        id={"foo"}
        type="things"
        viewMode={props.params.viewMode}
        subViewMode={props.params.subViewMode}
      />
    </section>
  );
};

AllThingsContainer.propTypes = {
  params: PropTypes.object,
  subViewMode: PropTypes.object
};

export default AllThingsContainer;
