import { invoke$ } from "./invoke";
import { map, range } from "ramda";

export const download$ = ({ thingName }) => {
  const action = {
    action: "DOWNLOAD_CERTIFICATE",
    attributes: { thingName }
  };
  return invoke$("ThingLambda", action).map(encodedCert => {
    // base64-encoded string
    const byteChars = atob(encodedCert);
    const charCodeOfIndex = index => byteChars.charCodeAt(index);
    const byteNumbers = map(charCodeOfIndex, range(0, byteChars.length));
    const decodedCert = new Uint8Array(byteNumbers);
    const blob = new Blob([decodedCert], {
      type: "application/zip"
    });

    return blob;
  });
};
export const replace$ = ({ thingName }) => {
  const action = {
    action: "REPLACE_CERTIFICATE",
    attributes: { thingName }
  };
  return invoke$("ThingLambda", action);
};
