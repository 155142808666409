import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import * as colors from "themes/colors";
import OfflineBolt from "../../svg/offline_bolt";

export const getFooterText = resource => {
  let text;

  if (resource) {
    const timestamp = resource.getIn(["latestValue", "timestamp"]);
    if (timestamp) {
      const dateFormat = format(
        new Date(timestamp * 1000),
        "yyyy-MM-dd HH:mm:ss"
      );
      text = `Last updated: ${dateFormat}`;
    }
  }

  return text || "Never updated";
};

const spanWrapStyle = {
  marginRight: "auto",
  display: "flex"
};

const spanStyle = {
  marginLeft: "6px",
  lineHeight: "24px"
};

const boltStyle = {
  color: colors.colorCuriousBlueApprox
};

const RealtimeWidgetFooterAnnotation = ({ resource }) => {
  const timestamp = getFooterText(resource);

  return (
    <span style={spanWrapStyle}>
      <OfflineBolt style={boltStyle} />
      <span style={spanStyle}>{timestamp}</span>
    </span>
  );
};

RealtimeWidgetFooterAnnotation.propTypes = {
  resource: PropTypes.object
};

export default RealtimeWidgetFooterAnnotation;
