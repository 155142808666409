import { Observable } from "rxjs";
import wrapInvokeLambda$ from "../utils/wrap_invoke_lambda";

const createFormBody = payload => {
  const body = {
    grant_type: "authorization_code",
    client_id: payload.clientId,
    redirect_uri: `${location.origin}/oauth2callback`,
    code: payload.code
  };
  const formBody = Object.keys(body)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(body[key]))
    .join("&");

  return formBody;
};

export const initiateOauth$ = payload => {
  return Observable.from(
    fetch(`${payload.endpoint}/oauth2/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: createFormBody(payload)
    }).then(data => data.json())
  );
};

export const refreshToken$ = refreshToken => {
  return wrapInvokeLambda$("AuthLambda", {
    action: "REFRESH",
    attributes: { refreshToken: refreshToken }
  });
};
