import * as R from "ramda";

export const getFileUrlStatePath = attributes => {
  if (attributes.thingName) {
    return ["thingName", attributes.thingName, attributes.fileName];
  }
  if (attributes.thingType) {
    return ["thingType", attributes.thingType, attributes.fileName];
  }
  if (attributes.root) {
    return ["root", attributes.fileName];
  }
  if (attributes.public) {
    return ["public", attributes.fileName];
  }
  throw new Error("No matching file attribute");
};

export const currentScope = (bucketState, widgetId) =>
  R.pathOr(
    widgetId === "FileBrowser" ? "root" : "public",
    ["entities", "widgets", widgetId, "currentScope"],
    bucketState
  );

export const currentScopeState = (bucketState, widgetId, scope) =>
  R.path(["entities", "widgets", widgetId, "scopes", scope], bucketState);

export const scopePath = (bucketState, widgetId) => [
  "entities",
  "widgets",
  widgetId,
  "scopes",
  currentScope(bucketState, widgetId)
];

const _scopeState = (scope, thingName, thingType) => {
  switch (scope) {
    case "root":
      return {
        selected: "",
        isRoot: true,
        isPublic: false,
        thingName: null,
        thingType: null
      };
    case "public":
      return {
        selected: "",
        isRoot: false,
        isPublic: true,
        thingName: null,
        thingType: null
      };
    case "thingName":
      return {
        selected: thingName,
        isRoot: false,
        isPublic: false,
        thingName: thingName,
        thingType: null
      };
    case "thingType":
      return {
        selected: thingType,
        isRoot: false,
        isPublic: false,
        thingName: null,
        thingType: thingType
      };
  }
};
export const newWidgetState = (initialScope, thingName, thingType) => {
  const newScope = (scope, thingName, thingType) => ({
    selectedRows: [],
    prefix: "",
    files: [],
    paging: {
      continuationToken: null,
      nextContinuationToken: null,
      isTruncated: false
    },
    request: {
      error: null,
      isLoading: false
    },
    ..._scopeState(scope, thingName, thingType)
  });

  return {
    currentScope: initialScope,
    scopes: {
      public: newScope("public"),
      root: newScope("root"),
      thingName: newScope("thingName", thingName, null),
      thingType: newScope("thingType", null, thingType)
    }
  };
};

export const shouldMakeListRequest = (getState, bucketSelectors) => action => {
  const state = getState();
  const widgetId = action.payload.widgetId;
  const props = { widgetId };

  switch (bucketSelectors.getCurrentScope(state, props)) {
    case "thingName":
    case "thingType":
      return R.allPass([R.complement(R.isEmpty), R.complement(R.isNil)])(
        bucketSelectors.getSelected(state, props)
      );
    default:
      return true;
  }
};

export const shouldMakeFirstListRequest = (
  getState,
  bucketSelectors
) => action => {
  const state = getState();
  const widgetId = action.payload.widgetId;
  const props = { widgetId };

  if (bucketSelectors.isWidgetScopeInitialized(state, props)) {
    return false;
  }

  return shouldMakeListRequest(getState, bucketSelectors)(action);
};
