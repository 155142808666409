import { createSelector } from "reselect";
import { Map } from "immutable";
import { withResourceMetadata } from "utils/table_utils";
import { getThingListColumnConfig } from "utils/dashboard_utils";
import { _resourceEntities } from "ducks/schema/schema_selectors";
import widgetCrudDuck from "./widget_reducer";

const emptyDashboard = Map();
const emptyWidgets = Map();

const getDashboardState = state =>
  state.dashboard ? state.dashboard : emptyDashboard;

const getWidgetEntities = createSelector(getDashboardState, dashboardState =>
  dashboardState.getIn(["entities", "widgets"], emptyWidgets)
);

const getWidgetProps = createSelector(
  getWidgetEntities,
  (state, id) => id,
  (widgetEntities, id) => widgetEntities.getIn([id, "props"], null)
);

const getEditingViewModeColumnConfig = createSelector(
  getWidgetProps,
  _resourceEntities,
  (state, id, dashboardViewMode) => getThingListColumnConfig(dashboardViewMode),
  (widgetProps, entities, columnConfig) =>
    columnConfig.map(withResourceMetadata(entities))
);

const getThingsColumnConfig = createSelector(
  (state, _columnConfig) => _columnConfig,
  _resourceEntities,
  (columnConfig, resourceMetadata) =>
    columnConfig.map(withResourceMetadata(resourceMetadata))
);

export default {
  ...widgetCrudDuck.selectors,
  getWidgetProps,
  getEditingViewModeColumnConfig,
  getThingsColumnConfig
};
