import { graphql } from "@apollo/client/react/hoc";
import AWS from "aws-sdk/global";
import DashboardContainer from "components/dashboard/dashboard_container";
import { CreateDataExportDialog } from "components/data_export/create_data_export";
import LoadingWrapper from "components/loading/loading_wrapper";
import { ErrorMsg } from "components/messages";
import DesiredDialogContainer from "components/widget/desired/desired_container";
import { authSelectors } from "ducks/auth";
import {
  userCanCreateDashboard,
  userCanUpdateDashboard
} from "ducks/dashboard/dashboard_selectors";
import {
  selectors as dashboardViewStateSelectors,
  setIsMovingWidgets
} from "ducks/dashboard/dashboard_view_state";
import { getSchemaElement } from "ducks/schema/schema_selectors";
import { clearThingShadow, closeStream, openStream } from "ducks/thing";
import {
  getTCXNValuesByThingId,
  hasResources,
  userCanPublish
} from "ducks/thing/thing_selectors";
import { userCanCreateThings } from "ducks/things_list/things_list_selectors";
import { setViewMode, thingTypeSelectors } from "ducks/thing_types";
import { REMOVE_THING } from "graphql/queries";
import {
  branchModal,
  withModalHandlers,
  withModalHandlersAndBranch
} from "hocs/modal_hocs";
import { Map } from "immutable";
import PropTypes from "prop-types";
import * as R from "ramda";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose, lifecycle, withState } from "react-recompose";
import { createSelector } from "reselect";
import EditThingModal from "routes/main/things/modals/edit_thing";
import withRouteLeaveViewModeModalHoc from "routes/main/things/modals/route_leave_view_mode_modal_hoc";
import { withThingType } from "routes/main/things/thing_type/with_thing_type";
import { thingTypeOverviewPath } from "utils/path_utils";
import { thingDetailsUrl, thingTypeDashboardUrl } from "utils/route_utils";
import * as widgetUtils from "utils/widget_utils";
import { ConfirmDeleteThingDialog } from "./components/confirm_delete_thing_dialog";
import ThingToolbar from "./components/thing_toolbar";
import CreateNetworkedThingModal from "./create_networked_thing/create_networked_thing_modal";
import { observationLifecycle } from "./observation_lifecycle_hoc";

const CreateDataExportModal = branchModal("DataExport", CreateDataExportDialog);

const DesiredModal = branchModal("Desired", DesiredDialogContainer);
const NetworkedThingModal = branchModal(
  "CreateNetworkedThing",
  CreateNetworkedThingModal
);

const shouldSubscribeToMqtt = (prevProps, props) =>
  prevProps.domainPath === undefined && props.domainPath !== undefined;

const shouldSwitchSubscriptionToMqtt = (prevProps, props) =>
  prevProps.domainPath !== undefined &&
  props.domainPath !== undefined &&
  prevProps.params.thingName !== props.params.thingName;

class ThingDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.onDeleteSubThing = this.onDeleteSubThing.bind(this);
    this.state = { thingShadowJustSet: false };
  }

  onDeleteSubThing({ subThingName, thingName }) {
    const {
      deleteThingMutation,
      data,
      dispatch,
      router,
      thingType
    } = this.props;
    return async () => {
      const pathname = thingDetailsUrl(router, { thingName, thingType });
      await deleteThingMutation({ variables: { subThingName, thingName } });
      await data.refetch();
      dispatch(push({ pathname }));
    };
  }

  onDeleteThing(thingName) {
    const { deleteThingMutation, dispatch, router, thingType } = this.props;
    deleteThingMutation({
      variables: { thingName },
      update: () => {
        dispatch(push({ pathname: thingTypeDashboardUrl(router, thingType) }));
      }
    });
  }

  _dashboardProps = R.pick([
    "dispatch",
    "filterId",
    "gridLayouts",
    "handleCancelWidget",
    "observationPeriod",
    "openModalDesired",
    "setEditModeLayout",
    "subthing",
    "thingShadowResourceKeypath",
    "viewMode",
    "virtualResourcesKeypath",
    "widgets",
    "location",
    "params",
    "readOnly",
    "userCanCreateThingType",
    "userCanCreateThings",
    "thingShadow",
    "thingShadowHasResources",
    "dashboardViewMode",
    "thingType",
    "thingName"
  ]);

  render() {
    const {
      account,
      children,
      error,
      thingShadow,
      params,
      thingType,
      viewMode,
      editingThingType,
      openModalEditThing,
      openModalCreateNetworkedThing,
      closeModalCreateNetworkedThing,
      openModalDataExport,
      deleteModalOpen,
      setDeleteModalOpen,
      userCanPublish,
      setViewMode,
      isLoading,
      data: { thing },
      tcxnValues
    } = this.props;
    if (isLoading === true) return <LoadingWrapper />;
    if (!thingShadow) return null;
    const thingTypeUrl = thingTypeOverviewPath(thingType, viewMode);
    const thingTypeLabel = R.prop("label", editingThingType);
    const title = R.prop("label", thing);

    return (
      <React.Fragment>
        <Helmet title={title} />
        <ErrorMsg error={error} />
        <ThingToolbar
          account={account}
          title={title}
          thingTypeUrl={thingTypeUrl}
          thingTypeLabel={thingTypeLabel}
          tcxnValues={tcxnValues}
          onEditThing={() =>
            openModalEditThing({ thingName: params.thingName })
          }
          onDeleteThing={() => {
            setDeleteModalOpen(true);
          }}
          onAddNetworkedThing={() => {
            openModalCreateNetworkedThing({ thingShadow });
          }}
          onCreateExport={() => {
            openModalDataExport();
          }}
          thingShadow={thingShadow}
          thing={thing}
          userCanPublish={userCanPublish}
          isLoading={isLoading}
        />
        <DashboardContainer
          {...this._dashboardProps(this.props)}
          thingTypeId={thingType}
          params={params}
          location={location}
          setViewMode={setViewMode}
          type={"detail"}
        />
        {children}
        <CreateDataExportModal
          selectedThings={[this.props.thingName]}
          thingTypeId={this.props.thingType}
          {...this.props}
        />
        <NetworkedThingModal
          {...this.props}
          onCancel={closeModalCreateNetworkedThing}
        />
        <DesiredModal
          modalStateDesired={this.props.modalStateDesired}
          closeModalDesired={this.props.closeModalDesired}
        />
        <ConfirmDeleteThingDialog
          thingName={params.thingName}
          open={deleteModalOpen}
          onDone={shouldDelete => {
            if (shouldDelete) this.onDeleteThing(params.thingName);
            else setDeleteModalOpen(false);
          }}
        />
      </React.Fragment>
    );
  }
}

ThingDetailsContainer.propTypes = {
  router: PropTypes.object,
  data: PropTypes.object,
  thingShadow: PropTypes.object,
  openModalWidget: PropTypes.func,
  openModalViewModeCreateEdit: PropTypes.func,
  domainPath: PropTypes.string,
  thingShadowResourceKeypath: PropTypes.array,
  widgetKeypath: PropTypes.array,
  params: PropTypes.object,
  widgetsMap: PropTypes.object,
  widgets: PropTypes.array,
  children: PropTypes.object,
  showViewModeEdit: PropTypes.bool,
  dashboardState: PropTypes.object,
  setDashboardState: PropTypes.func,
  setHasDashboardBeenSaved: PropTypes.func,
  editingThingType: PropTypes.object,
  editingViewMode: PropTypes.object,
  setViewMode: PropTypes.func,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  query: PropTypes.object,
  pathname: PropTypes.string,
  currentObservationPeriod: PropTypes.string,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  subThingError: PropTypes.bool,
  thingShadowHasResources: PropTypes.bool,
  readOnly: PropTypes.bool,
  isLoadingThingType: PropTypes.bool,
  routes: PropTypes.array,
  initWidgets: PropTypes.func,
  hasUndoWidgets: PropTypes.bool,
  setIsMovingWidgets: PropTypes.func,
  isMovingWidgets: PropTypes.bool,
  restoreUndoWidgets: PropTypes.func,
  viewMode: PropTypes.string,
  thingType: PropTypes.string,
  userCanCreateThingType: PropTypes.bool,
  userCanCreateThings: PropTypes.bool,
  deleteThingMutation: PropTypes.func.isRequired,
  setDeleteModalOpen: PropTypes.func,
  deleteModalOpen: PropTypes.bool,
  thingName: PropTypes.string,
  dashboardViewMode: PropTypes.object.isRequired,
  modalStateDesired: PropTypes.object.isRequired,
  closeModalDesired: PropTypes.func.isRequired,
  openModalEditThing: PropTypes.func,
  openModalCreateNetworkedThing: PropTypes.func,
  closeModalCreateNetworkedThing: PropTypes.func,
  openModalDataExport: PropTypes.func,
  userCanPublish: PropTypes.bool,
  saveGrid: PropTypes.func,
  thing: PropTypes.object,
  tcxnValues: PropTypes.object,
  account: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const selectAwsCredentails = createSelector(
  aws => aws.config.credentials,
  credentials => ({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken
  })
);

const selectPaths = createSelector(
  ({ viewMode }) => viewMode,
  ({ subthing }) => subthing,
  ({ subThingType }) => subThingType,
  ({ editingThingType }) => editingThingType,
  ({ prefix }) => prefix,
  (viewMode, subthing, subThingType, editingThingType, prefix) =>
    widgetUtils.getKeypaths({
      viewMode,
      subthing,
      subThingType,
      editingThingType,
      prefix
    })
);

const prefix = ["editingThingType", "viewModes"];

const makeMapStateToProps = () => (state, props) => {
  const {
    params,
    isLoadingThingType,
    thingType,
    params: { viewMode, subthing, subThingType },
    location,
    location: { query, pathname }
  } = props;
  let editingThingType;
  let readOnly;
  let subThingError = false;
  // let widgets = Map({});
  let awsCredentials;
  let thingShadowHasResources;
  let widgetKeypath;
  let virtualResourcesKeypath;
  let thingShadowResourceKeypath;
  let showViewModeEdit = false;
  let schemaElement;
  let hasSchemaResources = false;
  if (!isLoadingThingType) {
    editingThingType = thingType;
    readOnly = R.prop("readonly", editingThingType);

    const paths = selectPaths({
      viewMode,
      subthing,
      subThingType,
      editingThingType,
      prefix
    });
    widgetKeypath = paths.widgetKeypath;
    virtualResourcesKeypath = paths.virtualResourcesKeypath;
    thingShadowResourceKeypath = paths.thingShadowResourceKeypath;

    // widgets = getWidgets(state, { viewMode, subThingType, subthing });

    if (query.viewModeEdit === "true") {
      showViewModeEdit = true;
    }

    awsCredentials = selectAwsCredentails(AWS);
    thingShadowHasResources = hasResources(
      state.thing,
      params.thingName,
      params.thingType
    );
    schemaElement = getSchemaElement(state.schema, {
      thingType: params.thingType,
      subthing: params.subthing,
      subThingType: params.subThingType
    });
    hasSchemaResources =
      schemaElement && schemaElement.get("resources", Map()).size > 0;
  }
  const hasUndoWidgets = thingTypeSelectors.hasUndoWidgets(state);
  const tcxnValues = getTCXNValuesByThingId(state, {
    thingId: params.thingName
  });

  return {
    thingShadowHasResources,
    hasUndoWidgets,
    subThingError,
    thingType: params.thingType,
    widgetKeypath,
    thingShadowResourceKeypath,
    virtualResourcesKeypath,
    editingThingType,
    params,
    error: state.thing.get("error"),
    editingViewMode: state.thingTypes.getIn([
      "editingThingType",
      "viewModes",
      params.viewMode
    ]),
    // widgets,
    showViewModeEdit,
    account: authSelectors.accountSelector(state),
    userCanPublish: userCanPublish(state),
    awsCredentials,
    location,
    pathname,
    query,
    isMovingWidgets: dashboardViewStateSelectors.getIsDashboardMovingItsWidgets(
      state
    ), // we might fimpa this one
    readOnly,
    userDomain: authSelectors.userDomainSelector(state),
    userCanCreateThingType: thingTypeSelectors.userCanCreateThingType(state),
    userCanCreateThings: userCanCreateThings(state),
    userCanCreateDashboard: userCanCreateDashboard(state),
    userCanUpdateDashboard: userCanUpdateDashboard(state),
    isLoadingThingType,
    schemaElement,
    hasSchemaResources,
    tcxnValues
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  setIsMovingWidgets: isMovingWidgets =>
    dispatch(setIsMovingWidgets(isMovingWidgets)),
  mqttSubscribe: payload => dispatch(openStream(payload)),
  setViewMode: (...args) => dispatch(setViewMode(...args))
});

const resetCurrentThing = ({
  dispatch,
  params,
  domainPath,
  setIsMovingWidgets
}) => {
  dispatch(closeStream({ domainPath, thingName: params.thingName }));
  dispatch(clearThingShadow());
  setIsMovingWidgets(false);
};

const _thingDetailsContainer = compose(
  withThingType(),
  graphql(REMOVE_THING, {
    name: "deleteThingMutation",
    options: { refetchQueries: ["allThings"] }
  }),
  connect(makeMapStateToProps, mapDispatchToProps),
  observationLifecycle,
  withState("deleteModalOpen", "setDeleteModalOpen", false),

  withModalHandlers({
    name: "CreateNetworkedThing",
    modal: NetworkedThingModal
  }),
  withModalHandlers({
    name: "DataExport",
    modal: CreateDataExportModal
  }),
  withModalHandlers({
    name: "Desired",
    modal: DesiredDialogContainer
  }),
  withModalHandlersAndBranch({
    name: "EditThing",
    modal: EditThingModal,
    handlers: {
      refetchOnSave: ({ data }) => () => {
        data.refetch();
      }
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (shouldSubscribeToMqtt(prevProps, this.props)) {
        this.props.mqttSubscribe({
          domainPath: this.props.domainPath,
          thingName: this.props.params.thingName
        });
      } else if (shouldSwitchSubscriptionToMqtt(prevProps, this.props)) {
        this.props.dispatch(
          closeStream({
            domainPath: prevProps.domainPath,
            thingName: prevProps.params.thingName
          })
        );
        this.props.mqttSubscribe({
          domainPath: this.props.domainPath,
          thingName: this.props.params.thingName
        });
      }
    },
    componentDidMount() {
      if (this.props.domainPath !== undefined) {
        this.props.mqttSubscribe({
          domainPath: this.props.domainPath,
          thingName: this.props.params.thingName
        });
      }
    },
    componentWillUnmount() {
      resetCurrentThing(this.props);
    }
  }),
  withRouteLeaveViewModeModalHoc
)(ThingDetailsContainer);

export default _thingDetailsContainer;
