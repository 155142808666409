import { handleActions } from "redux-actions";

const initialState = {};

export const API_ERROR = "API_ERROR";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const apiError = (error, onErrorOptions) => ({
  type: API_ERROR,
  payload: { error, onErrorOptions }
});
export const setError = errorMessage => ({
  type: SET_ERROR,
  payload: errorMessage
});
export const clearError = () => ({ type: CLEAR_ERROR });

export default handleActions(
  {
    SET_ERROR: (state, { payload }) => ({
      [payload.property || "general"]: payload.message
    }),
    CLEAR_ERROR: () => ({})
  },
  initialState
);
