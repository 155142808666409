import { useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import FilterIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { Card } from "components/cards";
import PerimeterMap from "components/maps/perimeter";
import Autocomplete, { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";
import { compose, defaultProps, setPropTypes } from "react-recompose";
import { getStyles } from "./filter.style.js";
import FilterTitle from "./filter_title";
import FreeText from "./free_text";
import MapToggle from "./map_toggle";
import OnlineOffline from "./online_offline";
import Resources from "./resources";

export default compose(
  setPropTypes({
    isLoading: PropTypes.bool,
    isLoadingThingTypes: PropTypes.bool,
    isLoadingDomains: PropTypes.bool,
    error: PropTypes.object,
    filter: PropTypes.object,
    filterView: PropTypes.object,
    domains: PropTypes.array,
    type: PropTypes.string,
    filterId: PropTypes.string.isRequired,
    onAddFreeTextSearch: PropTypes.func,
    onRemoveFreeTextSearch: PropTypes.func,
    onUpdateFreeTextSearch: PropTypes.func,
    onResourceFilterChange: PropTypes.func,
    onRemoveResourceFilter: PropTypes.func,
    onAddResourceFilter: PropTypes.func,
    onClearFilter: PropTypes.func,
    onChangeThingStatus: PropTypes.func,
    onRemoveGeoFilter: PropTypes.func,
    onToggleGeoActive: PropTypes.func,
    onUpdateGeoFilter: PropTypes.func,
    onThingTypesFilterChange: PropTypes.func,
    onDomainsFilterChange: PropTypes.func,
    muiTheme: PropTypes.object,
    noGap: PropTypes.bool
  }),
  defaultProps({
    filter: Map(),
    domains: [],
    noGap: false
  })
)(
  ({
    filterId,
    filter,
    filterView,
    thingTypes,
    onUpdateFreeTextSearch,
    onRemoveFreeTextSearch,
    onAddFreeTextSearch,
    onThingTypesFilterChange,
    onDomainsFilterChange,
    onResourceFilterChange,
    onRemoveResourceFilter,
    onAddResourceFilter,
    onChangeThingStatus,
    onClearFilter,
    onRemoveGeoFilter,
    onToggleGeoActive,
    onUpdateGeoFilter,
    noGap
  }) => {
    const theme = useTheme();
    const css = getStyles(theme);

    const filterDomains = filter.get("domains")?.toJS();
    const domainsDefaultValues =
      filterDomains && Array.isArray(filterDomains)
        ? filterDomains.map(domain => ({ id: domain }))
        : [];
    const filterThingTypes = filter.get("thingTypes")?.toJS();
    const thingTypesDefaultValues =
      filterThingTypes && Array.isArray(filterThingTypes)
        ? filterThingTypes.map(thingType => ({ id: thingType }))
        : [];
    return (
      <Card
        title={"Filter by"}
        collapsedTitle={
          <FilterTitle
            filter={filter}
            onClearFilter={onClearFilter}
            css={css}
          />
        }
        icon={<FilterIcon />}
        isExpandable
        data-test="filter-component"
        style={noGap ? { marginBottom: "24px" } : {}}
      >
        <div style={css.freeTextContainer}>
          <div style={css.searchIconWrapper}>
            <SearchIcon style={css.searchIcon} />
          </div>
          <div style={css.freeTextWrapper}>
            {filter
              .get("freeText", Map())
              .entrySeq()
              .map(([k, v]) => (
                <FreeText
                  key={k}
                  text={v}
                  onRemoveFreeTextSearch={onRemoveFreeTextSearch(k)}
                  onUpdateFreeText={onUpdateFreeTextSearch(k)}
                />
              ))}
            {(filter.get("freeText", Map()).first() || "").length > 0 && (
              <AddIcon style={css.addIcon} onClick={onAddFreeTextSearch} />
            )}
          </div>
        </div>
        <div style={css.global}>
          <div style={css.innerRowHeight}>
            {thingTypes && (
              <Autocomplete
                data-test="filter-thingtypes"
                type={DATA_TYPES.MULTI_THING_TYPES}
                defaultValue={thingTypesDefaultValues}
                label="Thing types"
                hintText="Enter a Thing type name to search"
                changeHandler={onThingTypesFilterChange}
                errorText=""
              />
            )}
            <Autocomplete
              data-test="filter-domains"
              type={DATA_TYPES.MULTI_DOMAINS}
              defaultValue={domainsDefaultValues}
              label="Domains"
              hintText="Enter a Domain name to search"
              changeHandler={onDomainsFilterChange}
              errorText=""
            />
            <OnlineOffline
              onChangeThingStatus={onChangeThingStatus}
              thingStatus={filter.get("thingStatus")}
            />
            <MapToggle
              filter={filter.get("geo", List())}
              onToggleGeoActive={onToggleGeoActive}
              onRemoveGeoFilter={onRemoveGeoFilter}
              isActive={filterView.get("geoIsActive", false)}
            />
          </div>
        </div>

        <div style={css.resource}>
          <div>
            <Resources
              removeResourceFilter={onRemoveResourceFilter}
              setResourceFilter={onResourceFilterChange}
              addResourceFilter={onAddResourceFilter}
              filter={filter.get("resources", List())}
              thingTypeId={
                filterId !== "allThings"
                  ? filterThingTypes && filterThingTypes.length === 1
                    ? filterThingTypes[0]
                    : null
                  : null
              }
            />
          </div>
        </div>

        {filterView.get("geoIsActive", false) && (
          <PerimeterMap
            ignoreGeo
            filterId={filterId}
            geoJson={filter.get("geo")}
            onPerimeterChange={onUpdateGeoFilter}
            isActive={filterView.get("geoIsActive", false)}
            mapId={"filter-perimeter-map"}
          />
        )}
      </Card>
    );
  }
);
