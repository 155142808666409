import React, { Component } from "react";
import PropTypes from "prop-types";
import { OrderedSet } from "immutable";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import FormIconButton from "components/buttons/form_icon";

const FormLabel = withStyles({
  label: { color: "#999", textTransform: "uppercase" }
})(FormControlLabel);

const classifications = OrderedSet.of(
  "ALL",
  "ALARM",
  "WARNING",
  "NOTIFICATION",
  "INTERNAL"
);

const filterClassification = classification =>
  classifications.filter((value, key) => key === classification.toUpperCase());

class EventsPageTabs extends Component {
  constructor(props) {
    super(props);
    this.handleChangeClassification = this.handleChangeClassification.bind(
      this
    );
    this.state = {
      classification: filterClassification("ALL")
    };
  }

  componentDidMount() {
    const { classification } = this.props;
    this.setState({
      classification: filterClassification(classification)
    });
  }

  handleChangeClassification(event) {
    const { routeToNewClassification } = this.props;
    const { classification: currentClassification } = this.state;

    const newClassification = event.target.innerText;

    if (
      newClassification &&
      !currentClassification.has(newClassification.toUpperCase())
    ) {
      this.setState({
        classification: filterClassification(newClassification)
      });
      routeToNewClassification(newClassification.toLowerCase());
    }
  }

  handleToggleOnlyAcknowledged = () => this.props.toggleOnlyAcknowledged();

  render() {
    const { classification } = this.state;
    return (
      <div
        style={{
          display: "flex",
          paddingTop: "32px",
          paddingBottom: "16px",
          paddingRight: "16px",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex"
          }}
        >
          {classifications.map((value, key) => {
            return (
              <FormIconButton
                key={key}
                text={value}
                color={classification.has(key) ? "primary" : "default"}
                onClick={this.handleChangeClassification}
              />
            );
          })}
        </div>
        <div>
          <FormGroup>
            <FormLabel
              control={
                <Switch
                  labelPosition="right"
                  labelStyle={{ color: "#999", textTransform: "uppercase" }}
                  onChange={this.handleToggleOnlyAcknowledged}
                />
              }
              label="Need acknowledgement"
            />
          </FormGroup>
        </div>
      </div>
    );
  }
}

EventsPageTabs.propTypes = {
  routeToNewClassification: PropTypes.func.isRequired,
  classification: PropTypes.string,
  toggleOnlyAcknowledged: PropTypes.func
};

export default EventsPageTabs;
