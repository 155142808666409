import { userCanReadFiles } from "ducks/bucket_selectors";
import { hideSidebar } from "ducks/nav";
import { getThingTypeSuccess } from "ducks/thing_types/thing_types";
import * as R from "ramda";
import React from "react";
import { IndexRoute, Redirect, Route, IndexRedirect } from "react-router";
import {
  denyIfProductionAccount,
  denyIfUserCanCreateThingTypes,
  denyIfUserDoesNotHavePermission,
  denyIfUserDoesNotHaveThingPermissions,
  hideSidebarIfToldTo,
  redirectToDefaultIndexUri,
  requireAuth
} from "utils/route_utils";
import {
  isLoggedIn,
  userHasAppBoardPermission
} from "./ducks/auth/auth_selectors";
import { userCanReadDataExport } from "./ducks/data_export/data_export_selectors";
import { userCanReadThingGroup } from "./ducks/device_management/groups_selectors";
import { userCanReadThingJobs } from "./ducks/device_management/jobs_selectors";
import { userCanReadDomains } from "./ducks/domain/domain_selectors";
import { userCanReadRules } from "./ducks/rules/rules_selectors";
import { userCanReadThingType } from "./ducks/thing_types/thing_types_selectors";
import { userCanReadUnits } from "./ducks/units/units_selectors";
import {
  userCanListRoles,
  userCanReadUsers
} from "./ducks/users/users_selectors";
import * as routes from "./routes/";

export default store => {
  return (
    <Route component={routes.AppContainer}>
      <Route
        path="/graphiql"
        component={routes.graphiql}
        onEnter={requireAuth({ store, isLoggedIn, userHasAppBoardPermission })}
      />
      <Route
        path="/"
        component={routes.main.MainContainer}
        onChange={hideSidebarIfToldTo({ store, hideSidebar })}
        onEnter={requireAuth({ store, isLoggedIn, userHasAppBoardPermission })}
      >
        <IndexRoute onEnter={redirectToDefaultIndexUri({ store })} />
        <Route path="redirectThing/:thingId" component={routes.RedirectThing} />
        <Route path="simulator/:thingId" component={routes.main.simulator} />
        <Route
          path="thingsimulator/:thingType/:thingName"
          component={routes.main.thingSimulator}
        />
        <Route
          path="things"
          onEnter={denyIfUserDoesNotHaveThingPermissions({ store })}
        >
          <Route
            name="globalEventsPage"
            path="events/:classification"
            components={{
              sidebar: routes.main.things.ThingsSidebar,
              content: routes.main.things.events.EventsPageContainer
            }}
          />
          <Route
            path=":viewMode"
            components={{
              sidebar: routes.main.things.ThingsSidebar,
              content: routes.main.things.ThingsContainer
            }}
          >
            <IndexRoute component={routes.main.things.AllThingsContainer} />
            <Route
              name="allThingsEvents"
              path="events/:classification"
              component={routes.main.things.events.EventsPageContainer}
            />
            <Route
              name="overviewEvents"
              path=":thingType/events/:classification"
              component={routes.main.things.events.EventsPageContainer}
            />
            <Redirect from=":thingType" to=":thingType/overview" />

            <Route
              path=":thingType"
              component={routes.main.things.thingType.ThingTypesContainer}
              onEnter={props =>
                store.dispatch(
                  getThingTypeSuccess({
                    id: R.path(["params", "thingType"], props)
                  })
                )
              }
            >
              <Route
                path="overview"
                component={
                  routes.main.things.thingType.overview
                    .ThingTypesOverviewContainer
                }
              />
              <Route
                path="thingslist"
                component={
                  routes.main.things.thingType.overview
                    .ThingTypesOverviewContainer
                }
                onEnter={denyIfUserCanCreateThingTypes({ store })}
              />
              <Route
                name="thingDetailEvents"
                path="detail/:thingName/events/:classification"
                component={routes.main.things.events.EventsPageContainer}
              />
              {/* Don't remove the name it's used! */}
              <Route
                name="thingDetail"
                path="detail/:thingName/:subThingType/:subthing"
                component={
                  routes.main.things.thingType.thingDetail.ThingDetailsContainer
                }
              >
                <Route
                  path="viewMode"
                  component={
                    routes.main.things.modals.ViewModeEditModalContainer
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          path="analyze"
          components={{
            content: routes.analyze.Layout,
            sidebar: routes.analyze.Sidebar
          }}
          onEnter={denyIfUserDoesNotHaveThingPermissions({ store })}
        >
          <IndexRoute component={routes.analyze.AnalyzePageContainer} />
          <Route
            path=":analysisId"
            component={routes.analyze.AnalyzePageContainer}
          />
        </Route>

        <Route
          path="settings"
          components={{
            content: routes.main.settings.Layout,
            sidebar: routes.main.settings.Sidebar
          }}
        >
          <IndexRedirect to="overview" />
          <Route
            path="overview"
            component={routes.main.settings.SettingsHome}
          ></Route>
          <Route
            path="domains/setup"
            component={routes.main.settings.DomainsSetup}
          />
          <Route
            path="domains*"
            onEnter={denyIfUserDoesNotHavePermission(store, userCanReadDomains)}
          >
            <IndexRoute component={routes.main.settings.Domains} />
          </Route>
          <Route
            path="rules"
            onEnter={denyIfUserDoesNotHavePermission(store, userCanReadRules)}
          >
            <IndexRoute component={routes.main.settings.Rules} />
            <Route path=":ruleId" component={routes.main.settings.RulesEdit} />
          </Route>
          <Route
            path="users"
            component={routes.main.settings.Users}
            onEnter={denyIfUserDoesNotHavePermission(store, userCanReadUsers)}
          />
          <Route
            path="roles"
            component={routes.main.settings.RolesListContainer}
            onEnter={denyIfUserDoesNotHavePermission(store, userCanListRoles)}
          />
          <Route
            path="roles/:roleId"
            component={routes.main.settings.RoleDetailsPage}
            onEnter={denyIfUserDoesNotHavePermission(store, userCanListRoles)}
          />
          <Route
            path="files"
            component={routes.main.settings.FileBrowserContainer}
            onEnter={denyIfUserDoesNotHavePermission(store, userCanReadFiles)}
          />
          <Route
            path="thingtypes"
            onEnter={denyIfUserDoesNotHavePermission(
              store,
              userCanReadThingType
            )}
          >
            <IndexRoute component={routes.main.settings.ThingTypes} />
            <Route path=":thingType">
              <Route path="resources">
                <Route path=":resourceId" />
              </Route>
            </Route>
          </Route>
          <Route
            path="units"
            component={routes.main.settings.Units}
            onEnter={denyIfUserDoesNotHavePermission(store, userCanReadUnits)}
          />
          <Route
            path="systemmanagement"
            component={routes.main.settings.SystemManagement}
          />

          <Route
            path="devicemanagement"
            component={routes.main.settings.DeviceManagement}
            onEnter={denyIfUserDoesNotHavePermission(
              store,
              userCanReadThingGroup
            )}
          >
            <Route
              path="groups"
              component={routes.main.settings.DeviceManagementGroupsContainer}
            />
            <Route
              path="jobs"
              component={routes.main.settings.DeviceManagementJobsDetails}
              onEnter={denyIfUserDoesNotHavePermission(
                store,
                userCanReadThingJobs
              )}
            />
            <Route
              path="firmwares"
              component={
                routes.main.settings.DeviceManagementFirmwaresContainer
              }
              onEnter={denyIfProductionAccount({ store })}
            />
          </Route>
          <Route
            path="devicemanagement/firmwares/:firmwareId"
            component={routes.main.settings.DeviceManagementFirmwareDetails}
            onEnter={denyIfProductionAccount({ store })}
          />
          <Route
            path="devicemanagement/groups/:thingGroupId"
            component={routes.main.settings.DeviceManagementGroupDetails}
          />
          <Route
            path="devicemanagement/groups/:thingGroupId/add"
            component={routes.main.settings.DeviceManagementAddThings}
          />
          <Route
            path="devicemanagement/jobs/create/addGroups"
            component={routes.main.settings.DeviceManagementAddGroups}
          />
          <Route
            path="devicemanagement/jobs/create"
            component={routes.main.settings.DeviceManagementCreateJob}
          />
          <Route
            path="devicemanagement/jobs/:jobId"
            component={routes.main.settings.DeviceManagementJobDetails}
          />
          <Route path="branding" component={routes.main.settings.Branding} />
          <Route path="export" component={routes.main.settings.ExportPage} />
          <Route
            path="export/jobs/:jobId"
            component={routes.main.settings.ExportJobDetailsPage}
            onEnter={denyIfUserDoesNotHavePermission(
              store,
              userCanReadDataExport
            )}
          />
        </Route>
        <Route
          components={{
            content: routes.main.user.Layout,
            sidebar: routes.main.settings.Sidebar
          }}
        >
          <Route path="about" component={routes.main.user.About} />
          <Route path="profile" component={routes.main.user.Profile} />
        </Route>
      </Route>
      <Route path="/" component={routes.auth.AuthContainer}>
        <Route path="login" component={routes.auth.LoginContainer} />
        <Route path="signup" component={routes.auth.SignupContainer} />
        <Route path="logout" component={routes.auth.LogoutContainer} />
        <Route
          path="setPassword"
          component={routes.auth.SetPasswordContainer}
        />
        <Route path="recoverPassword" component={routes.auth.RecoverPassword} />
        <Route path="verify" component={routes.auth.VerifyEmail} />
        <Route path="verifySms" component={routes.auth.VerifySmsContainer} />
      </Route>
      <Route path="/oauth2callback" component={routes.auth.OAuth2Callback} />
      <Route path="*" component={routes.NotFoundContainer} />
    </Route>
  );
};
