import React, { useState } from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";
import { ModalFormButtons, Form, Field, FieldSet } from "../";
import { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";

export const ThingTypeSchema = Joi.object().keys({
  label: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  description: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    }),
  domain: Joi.string()
    .trim()
    .required()
    .options({
      language: {
        any: { empty: "!!Not allowed to be empty", required: "!!Required" }
      }
    })
});

const ThingTypeForm = ({
  initialModel,
  handleSubmit,
  handleCancel,
  metadata
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  return (
    <Form
      schema={ThingTypeSchema}
      initialModel={initialModel}
      forceModel={initialModel}
      onSubmit={_thingTypeFormData => {
        setSubmitting(true);
        handleSubmit(_thingTypeFormData);
      }}
      allowUnknown // Metadata information schema is unknown
    >
      <FieldSet>
        <Field id="label" label="Label" />
        <Field id="description" label="Description" />
        <Field
          data-test="domain-dropdown"
          id="domain"
          label="Domain"
          type="autocomplete"
          suggesterType={DATA_TYPES.DOMAINS}
        />
        {metadata.map(f => (
          <>
            <p>{f.label}</p>
            <Field key={f.id} {...f} />
          </>
        ))}
      </FieldSet>

      <ModalFormButtons>
        <FormCancelButton secondary onClick={handleCancel}>
          Cancel
        </FormCancelButton>
        <FormSaveButton
          disabled={isSubmitting}
          isLoading={isSubmitting}
          data-test="edit-thing-type-save-button"
        >
          Save
        </FormSaveButton>
      </ModalFormButtons>
    </Form>
  );
};

ThingTypeForm.propTypes = {
  initialModel: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  metadata: PropTypes.array
};

ThingTypeForm.defaultProps = {
  metadata: []
};

export default ThingTypeForm;
