import {
  VictoryPie,
  VictoryContainer,
  VictoryLabel,
  VictoryLegend
} from "victory";
import PropTypes from "prop-types";
import React from "react";
import {
  getDecimalPrecisionValue,
  numberOfDecimalsIsSet
} from "utils/widget_utils";

const HEIGHT_FACTOR = 1.7; // this seems like a wierd value, it's close to 2 because the pie chart's size originally is that of a circle, and to compensate for this, some size calculations use this factor to make it look nice and waste less spaceF
const MOVE_SIDEWAYS_FACTOR = 0.2;

const getInnerRadius = dimensions => {
  if (dimensions.width < dimensions.height * HEIGHT_FACTOR) {
    return dimensions.width / 4;
  } else {
    return dimensions.height / 2;
  }
};
const getOuterRadius = dimensions => getInnerRadius(dimensions) * 1.3;

export const getAngleFromInterval = (min, max, value) => {
  // should translate value between min and max to an angle value between 270 and 450.
  const percentageOfMax = (value - min) / (max - min);
  const scaleValue = percentageOfMax * 180 + 270;
  if (scaleValue > 450) return 450;
  if (scaleValue < 270) return 270;
  return scaleValue;
};

const getColorScale = (thresholds, theme) => {
  if (thresholds && thresholds.length > 0) {
    return thresholds.map(t => t.color);
  }
  return [theme.palette.primary.main, "#e0e0e0"];
};

export const ValueLabel = ({
  dimensions,
  value,
  pendingValue,
  unit,
  numberOfDecimals
}) => {
  const innerRadius = getInnerRadius(dimensions);
  if (!numberOfDecimalsIsSet(numberOfDecimals)) numberOfDecimals = 1;
  value = getDecimalPrecisionValue(value, numberOfDecimals);

  const texts = pendingValue
    ? [unit ? `${value}${unit}` : value, `(${pendingValue})`]
    : [unit ? `${value}${unit}` : value];

  return (
    <VictoryLabel
      textAnchor="middle"
      style={[
        {
          fontSize: Math.round(innerRadius * 0.3),
          fontFamily: "telenor-regular"
        },
        {
          fill: "rgba(0, 0, 0, 0.5)",
          fontSize: Math.round(innerRadius * 0.2),
          fontFamily: "telenor-regular"
        }
      ]}
      y={(dimensions.height * HEIGHT_FACTOR) / 2 - innerRadius * 0.1}
      x={dimensions.width / 2 + (dimensions.width * MOVE_SIDEWAYS_FACTOR) / 2}
      text={texts}
    />
  );
};

export const Scale = ({
  dimensions,
  pieThresholds,
  colorThresholdConfig,
  min,
  max,
  value,
  theme
}) => {
  const innerRadius = getInnerRadius(dimensions);
  const outerRadius = getOuterRadius(dimensions);
  const BLEND_FACTOR = 0.2;

  return (
    <VictoryPie
      padding={{ right: dimensions.width * -MOVE_SIDEWAYS_FACTOR }}
      containerComponent={<VictoryContainer responsive={false} />}
      standalone={false}
      width={dimensions.width}
      height={dimensions.height * HEIGHT_FACTOR} // 1.8 is kind of wierd (appears in some places below for the same reason). It's actually to get the half circle drawn on a nice part of the widget area.
      radius={outerRadius}
      innerRadius={innerRadius}
      labelRadius={outerRadius * 10.2}
      labelComponent={
        <VictoryLabel
          style={{
            fontSize: Math.round(innerRadius * 0.2),
            fontFamily: "telenor-regular"
          }}
        />
      }
      style={{
        data: {
          opacity: ({ datum }) =>
            getAngleFromInterval(min, max, value) < datum.startAngle
              ? BLEND_FACTOR
              : 1
        }
      }}
      data={pieThresholds}
      startAngle={270} // values to make it a half circle
      endAngle={450} // values to make it a half circle
      colorScale={getColorScale(colorThresholdConfig, theme)}
    />
  );
};

export const Cursor = ({ dimensions, min, max, value, color = "black" }) => {
  const innerRadius = getInnerRadius(dimensions);
  const outerRadius = getOuterRadius(dimensions);
  return (
    <VictoryPie // this is actually a very narrow slice of a Pie acting as a pointer to Gauge scale
      padding={{ right: dimensions.width * -MOVE_SIDEWAYS_FACTOR }}
      animate={{ duration: 1500 }}
      colorScale={[color]}
      standalone={false}
      width={dimensions.width}
      height={dimensions.height * HEIGHT_FACTOR}
      data={[{ x: 1, y: 1 }]} // doesn't matter the data here. Just to be something at all
      radius={outerRadius + 3} // 3 pixels outside of the gauge
      innerRadius={innerRadius * 0.6}
      startAngle={getAngleFromInterval(min, max, value) + 1.5} // 1.5 degrees ahead of actual angle
      endAngle={getAngleFromInterval(min, max, value) - 1.5} // 1.5 degrees after actual angle
      style={{ labels: { fontSize: 0, fill: "white" } }}
      cornerRadius={10}
    />
  );
};

export const Legend = ({ colorThresholdConfig, pieThresholds, theme }) => {
  return (
    <VictoryLegend
      x={theme.spacing()}
      colorScale={getColorScale(colorThresholdConfig, theme)}
      data={pieThresholds.map(t => ({ name: t.label }))}
      standalone={false}
      style={{
        labels: {
          fontFamily: "telenor-regular"
        }
      }}
    />
  );
};

ValueLabel.propTypes = {
  dimensions: PropTypes.object,
  value: PropTypes.number,
  pendingValue: PropTypes.number,
  unit: PropTypes.string,
  numberOfDecimals: PropTypes.number
};
Scale.propTypes = {
  dimensions: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  theme: PropTypes.object,
  pieThresholds: PropTypes.array,
  colorThresholdConfig: PropTypes.array
};
Cursor.propTypes = {
  dimensions: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  color: PropTypes.string
};
Legend.propTypes = {
  theme: PropTypes.object,
  pieThresholds: PropTypes.array,
  colorThresholdConfig: PropTypes.array
};
