import { Label } from "components/label";
import useLazyUnauthorizedFetch from "hooks/useLazyUnauthorizedFetch";
import PropTypes from "prop-types";
import * as R from "ramda";
import React, { useEffect } from "react";
import { getStyles } from "./index.style";

const VerifyEmail = ({ location }) => {
  const styles = getStyles();
  const [verifyEmail, { loading, success, error }] = useLazyUnauthorizedFetch(
    "/auth/confirm-sign-up",
    "POST"
  );

  useEffect(() => {
    if (R.hasPath(["query", "token"])(location)) {
      verifyEmail({ token: R.path(["query", "token"])(location) });
    }
  }, []);

  return (
    <>
      <Label center type="h3">
        Verify
      </Label>
      {loading && <p>Please wait, sending verification...</p>}
      {success && (
        <p>
          Your account has been verified. Please wait for an email once your
          administrator has approved you.
        </p>
      )}
      {error && <p style={styles.errorText}>{error}</p>}
    </>
  );
};

VerifyEmail.propTypes = {
  location: PropTypes.object
};

export default VerifyEmail;
