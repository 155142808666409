import {
  SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_REQUEST,
  getConsentDocumentSuccess,
  getConsentDocumentFailure,
  SYSTEM_MANAGEMENT_UPLOAD_CONSENT_REQUEST,
  getConsentDocumentRequest,
  consentUploadSuccess,
  consentUploadFailure,
  uploadFile,
  uploadVersion
} from ".";
import { pathOr } from "ramda";
import { Observable } from "rxjs";
import { apiError } from "../errors";

export const getDocumentUrlEpic = (action$, store, { restApi }) =>
  action$.ofType(SYSTEM_MANAGEMENT_GET_CONSENT_DOCUMENT_REQUEST).mergeMap(() =>
    restApi.auth
      .getConsentDocument$()
      .map(getConsentDocumentSuccess)
      .catch(error => {
        switch (pathOr("", ["errorMessage", "messageKey"], error)) {
          case "FILE_NOT_FOUND":
            return Observable.of(getConsentDocumentSuccess());
          default:
            return Observable.of(
              getConsentDocumentFailure(error),
              apiError(error)
            );
        }
      })
  );

export const uploadConsentDocument = (action$, store, { api }) =>
  action$.ofType(SYSTEM_MANAGEMENT_UPLOAD_CONSENT_REQUEST).mergeMap(() => {
    const file = uploadFile(store.getState());
    const version = uploadVersion(store.getState());
    if (!file.name || file.size == 0) {
      return Observable.of(
        consentUploadFailure({
          errorMessage: { message: "File is missing, try refreshing the page" }
        })
      );
    }
    if (!version) {
      return Observable.of(
        consentUploadFailure({
          errorMessage: { message: "Version is missing" }
        })
      );
    }
    return api.systemManagement
      .uploadConsentDocument$(version, file)
      .mergeMap(resp =>
        Observable.of(consentUploadSuccess(resp), getConsentDocumentRequest())
      )
      .catch(error => Observable.of(consentUploadFailure(error)));
  });
