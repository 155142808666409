import React from "react";
import PropTypes from "prop-types";
import Autocomplete, { DATA_TYPES } from "forms_new/autocomplete/Autocomplete";
const containerCss = {
  display: "inline-block",
  verticalAlign: "middle",
  position: "relative",
  paddingRight: "10px",
  width: "300px",
  top: "10px"
};

const ResourcesDropdown = ({ onPickResource, thingTypeId }) => {
  return (
    <div style={containerCss}>
      <Autocomplete
        label="Pick a resource"
        fullWidth={true}
        type={DATA_TYPES.ALL_RESOURCES}
        changeHandler={(id, value) => {
          onPickResource(null, value);
        }}
        thingTypeId={thingTypeId}
      />
    </div>
  );
};

ResourcesDropdown.propTypes = {
  onPickResource: PropTypes.func,
  thingTypeId: PropTypes.string
};

export default ResourcesDropdown;
