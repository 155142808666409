import { combineReducers } from "redux";
import jobsOngoing from "ducks/device_management/jobs_ongoing";
import jobsCompleted from "ducks/device_management/jobs_completed";
import jobsCanceled from "ducks/device_management/jobs_canceled";
import jobs from "ducks/device_management/jobs";
import groups from "ducks/device_management/groups";

export default combineReducers({
  groups,
  jobsOngoing,
  jobsCompleted,
  jobsCanceled,
  jobs
});
