import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { manifest } from "api/aws/manifest";
import { Page } from "components/page";
import PropTypes from "prop-types";
import React from "react";
import ProgressCard from "../../../components/cards/progress_card";

const useStyles = makeStyles(theme => ({
  progressCardContent: {
    padding: theme.spacing(3)
  },
  label: {
    color: theme.palette.grey[500],
    fontWeight: "bold"
  }
}));

const GridRow = ({ label, children }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={4} sm={3} lg={2}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={8} sm={9} lg={10}>
        {children}
      </Grid>
    </>
  );
};

GridRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any
};

const getVersionNumber = text => {
  const versionNumber = text.match(/^\d+\.\d+\.\d+/);

  if (versionNumber && versionNumber.length > 0) return versionNumber[0];
  return text;
};

const About = () => {
  const classes = useStyles();
  // We get the frontend version from package.json,
  // Through webpack.DefinePlugin in XXXwebpack.config.js
  //eslint-disable-next-line
  const frontendVersion = __FRONTEND_VERSION__ || "?";
  const backendVersion = manifest.Rev ? getVersionNumber(manifest.Rev) : "?";
  const apiGateway = manifest.ApiGatewayRootUrl || "?";
  const iotEndpointAts = manifest.IotEndpointATS;

  return (
    <Page title="About">
      <ProgressCard classes={classes}>
        <Grid container spacing={1}>
          <GridRow label="Backend version:">{backendVersion}</GridRow>
          <GridRow label="Frontend version:">{frontendVersion}</GridRow>
          <GridRow label="Cloud Rest API:">
            <Link href={apiGateway} inline>
              {apiGateway}
            </Link>
          </GridRow>
          <GridRow label="IoT Endpoint (ATS):">{iotEndpointAts}</GridRow>
        </Grid>
      </ProgressCard>
    </Page>
  );
};

export default About;
