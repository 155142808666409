import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import Root from "./styled/root";

export const Calendar = ({
  startDate,
  endDate,
  selected,
  onChange,
  filterDate
}) => {
  const theme = useTheme();
  return (
    <Root muiTheme={theme}>
      <DatePicker
        inline
        startDate={startDate}
        endDate={endDate}
        fixedHeight
        selected={selected}
        onChange={onChange}
        filterDate={filterDate}
        showYearDropdown
        yearDropdownItemNumber={2}
      />
    </Root>
  );
};

Calendar.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  selected: PropTypes.object,
  onChange: PropTypes.func,
  filterDate: PropTypes.func
};

export default Calendar;
