import { handleActions } from "redux-actions";
import { fromJS } from "immutable";

const initialState = fromJS([]);

export default handleActions(
  {
    DASHBOARDS_SUCCESS: (state, { payload: { entities } }) =>
      entities.dashboards
        ? state.merge(Object.keys(entities.dashboards))
        : state,
    DASHBOARD_SUCCESS: (state, { payload: { id } }) => state.push(id),
    DASHBOARD_CREATE: (state, { id }) => state.push(id),
    DASHBOARD_DELETE: (state, { id }) => state.filterNot(key => key === id)
  },
  initialState
);
