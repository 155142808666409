import * as R from "ramda";
import { unImmute } from "utils/immutable_utils";
import { invoke$ as lambdaInvoke$ } from "./invoke";
import { nonEmptyString } from "utils/general_utils";

const _isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export const batchQuery = ({ thingTypeId, filter }) => {
  const filterObj = unImmute(R.defaultTo({}, filter));
  const domains = R.pathOr([], ["domains"], filterObj);
  const thingTypes = R.pathOr([], ["thingTypes"], filterObj);
  const freeTexts = R.pathOr([], ["freeText"], filterObj).filter(
    nonEmptyString
  );
  const query = {
    bucketName: "ThingCertsBucket",
    prefix:
      _isNullOrEmpty(thingTypeId) || thingTypeId === "allThings"
        ? "batch-"
        : `batch-${thingTypeId}-`
  };
  if (domains.length > 0) {
    query.filter = { domains };
  }
  if (thingTypes.length > 0) {
    query.filter = query.filter ? query.filter : {};
    query.filter.thingTypes = thingTypes;
  }
  if (freeTexts.length > 0) {
    query.filter = query.filter ? query.filter : {};
    query.filter.freeTexts = freeTexts;
  }
  return query;
};

export const listInvoke$ = invoke$ => ({
  thingTypeId,
  filterDomainIds,
  thingTypes,
  filter
}) =>
  invoke$("ThingBatchLambda", {
    action: "LIST",
    attributes: batchQuery({
      thingTypeId,
      filterDomainIds,
      thingTypes,
      filter
    })
  });

export const createInvoke$ = invoke$ => ({
  thingType,
  domain,
  requestedSize
}) =>
  invoke$("ThingBatchLambda", {
    action: "CREATE",
    attributes: { thingType, domain },
    requestedSize
  });

export const create$ = createInvoke$(lambdaInvoke$);
export const list$ = listInvoke$(lambdaInvoke$);
