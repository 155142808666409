import React from "react";
import PropTypes from "prop-types";
import { compose, withStateHandlers } from "react-recompose";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { pluralizeWithoutCounter } from "utils/general_utils";
import { baseTheme } from "themes";
import * as R from "ramda";
import { lower } from "utils/general_utils";
import { withStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { THING_TYPE_THING_COUNT } from "graphql/queries";

const filterOnLabel = (filterQuery = "") => resources => {
  const hasMatch = R.pipe(
    R.defaultTo(""),
    lower,
    R.contains(lower(filterQuery))
  );
  return R.pipe(
    R.defaultTo([]),
    R.filter(R.propSatisfies(hasMatch, "name"))
  )(resources);
};

const primaryColor = baseTheme.palette.primary1Color;

const _withStateHandlers = withStateHandlers(
  {
    filterQuery: ""
  },
  {
    setFilterQuery: () => filterQuery => ({
      filterQuery
    })
  }
);

const styles = theme => ({
  headerCheckbox: {
    paddingLeft: 0
  },
  searchIcon: {
    marginTop: theme.spacing(2.5)
  },
  tableWrapper: {
    height: "40vh",
    overflow: "auto"
  },
  table: {
    tableLayout: "fixed"
  },
  searchWrapper: {
    textAlign: "left",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  }
});

const ResourceTable = withStyles(styles)(
  ({
    thingType,
    selectedThings,
    selectedResourceNames,
    handleToggleResource,
    toggleSelectAllResources,
    selectedState,
    setFilterQuery,
    filterQuery,
    resources,
    classes
  }) => {
    const filteredResources = filterOnLabel(filterQuery)(resources || []);
    const { data } = useQuery(THING_TYPE_THING_COUNT, {
      skip: selectedThings || !thingType?.id,
      variables: { thingTypeId: thingType?.id }
    });
    const thingCount = R.pathOr(0, [
      "thingType",
      "thingsConnection",
      "pageInfo",
      "totalCount"
    ])(data);
    return (
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"body1"}>
                <span
                  data-test="number-of-selected-things"
                  style={{ color: primaryColor }}
                >
                  {selectedThings ? selectedThings.length : thingCount}
                </span>
                {pluralizeWithoutCounter(
                  " Things selected",
                  " Thing selected",
                  " Things selected",
                  selectedThings ? selectedThings.length : thingCount
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"body1"}>
                <span
                  style={{ color: primaryColor }}
                  data-test="number-of-selected-resources"
                >
                  {selectedResourceNames.size}
                </span>
                {pluralizeWithoutCounter(
                  ` Resources selected`,
                  ` Resource selected`,
                  ` Resources selected`,
                  selectedResourceNames.size
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.searchWrapper}>
          <TextField
            data-test={"export-label"}
            value={filterQuery}
            label={"search for Resource"}
            placeholder={"search for Resource"}
            errorText={""}
            onChange={e => setFilterQuery(e.target.value)}
          />
          <SearchIcon className={classes.searchIcon} />
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" style={{ width: "48px" }}>
                  <Checkbox
                    //className={classes.headerCheckbox}
                    onChange={() => toggleSelectAllResources(filteredResources)}
                    checked={selectedState === 2}
                    indeterminate={selectedState === 1}
                  />
                </TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>TYPE</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableBody>
                {filteredResources.map(resource => {
                  const isChecked = selectedResourceNames.has(resource.name);
                  return (
                    <TableRow key={resource.name}>
                      <TableCell padding="checkbox" style={{ width: "48px" }}>
                        <Checkbox
                          onChange={() => handleToggleResource(resource.name)}
                          checked={isChecked}
                        />
                      </TableCell>
                      <TableCell>{resource.name}</TableCell>
                      <TableCell>{resource.type}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    );
  }
);

ResourceTable.propTypes = {
  thingType: PropTypes.object,
  selectedThings: PropTypes.array,
  selectedState: PropTypes.integer,
  resources: PropTypes.array,
  toggleSelectAllResources: PropTypes.func,
  selectedResourceNames: PropTypes.object,
  thingTypeResourceQuery: PropTypes.object,
  toggleSelected: PropTypes.func,
  handleToggleResource: PropTypes.func,
  setFilterQuery: PropTypes.func,
  filterQuery: PropTypes.string
};

export default compose(_withStateHandlers)(ResourceTable);
