import {
  __,
  addIndex,
  append,
  assoc,
  concat,
  curry,
  defaultTo,
  evolve,
  head,
  inc,
  indexBy,
  join,
  keys,
  lensPath,
  map,
  merge,
  pipe,
  prepend,
  prop,
  propOr,
  reduceRight,
  set,
  useWith
} from "ramda";

const _extractIndexedEntities = curry((data, entityName) =>
  pipe(
    propOr([], __, data),
    addIndex(map)((val, idx) =>
      assoc(
        "id",
        defaultTo("")(_rulePrefix(data.id)(entityName)) + inc(idx)
      )(val)
    ),
    indexBy(prop("id"))
  )(entityName)
);

const _rulePrefix = curry((ruleIndex, entityName) =>
  pipe(
    append(ruleIndex),
    append(entityName),
    join("/"),
    concat(__, "/")
  )(["rule"])
);

const _extractKeysAndSetReferencesInRule = curry((entityName, data) =>
  set(lensPath(["rule", entityName]), keys(prop(entityName, data)))(data)
);

const _adaptRule = pipe(
  evolve({ filter: append(__, []) }),
  assoc("rule", __, {}),
  data =>
    reduceRight(
      (a, b) => assoc(a, _extractIndexedEntities(prop("rule", data), a))(b),
      data,
      ["actions", "filter", "expression"]
    ),
  _extractKeysAndSetReferencesInRule("actions"),
  _extractKeysAndSetReferencesInRule("expression"),
  _extractKeysAndSetReferencesInRule("filter"),
  evolve({ rule: { filter: head } })
);

export const adaptRule = data => _adaptRule(data);

export const adaptRules = data =>
  reduceRight(
    useWith(
      ({ rule, actions, filter, expression }, rules) =>
        evolve(
          {
            results: prepend(rule.id),
            entities: {
              rules: assoc(rule.id, rule),
              filters: merge(filter),
              actions: merge(actions),
              expressions: merge(expression)
            }
          },
          rules
        ),
      [_adaptRule]
    ),
    {
      results: [],
      entities: { rules: {}, actions: {}, expressions: {}, filters: {} }
    }
  )(data);
