import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

const FormCancelButton = ({ text, isLoading, disabled, ...rest }) => (
  <Button disabled={isLoading || disabled} {...rest}>
    {text}
  </Button>
);

FormCancelButton.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.object
};

FormCancelButton.defaultProps = {
  "data-test": "cancel",
  text: "Cancel",
  color: "secondary"
};

export default FormCancelButton;
