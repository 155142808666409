import { withProps, withPropsOnChange } from "react-recompose";
import * as R from "ramda";

export const withIsLoading = withPropsOnChange(["data"], props => {
  const isLoading = R.pathOr(true, ["data", "loading"], props);
  return { isLoading };
});

export const isLoading = queryName =>
  withProps(props => {
    const isLoading = R.pathOr(true, [queryName, "loading"], props);
    return { isLoading };
  });

export const getTimezone = R.memoizeWith(R.identity, () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  } catch (err) {
    return null;
  }
});

export const withTimezone = () => ({ timezone: getTimezone() });

export const withDefault = (propName, propDefault) => props =>
  props[propName] == null ? { ...props, [propName]: propDefault } : props;

export const withVariables = (propWhitelist = [], ...enhancers) => props => ({
  variables: enhancers.reduce(
    (acc, enhancer) => ({ ...acc, ...enhancer(acc) }),
    R.pick(propWhitelist, props)
  )
});
