const TYPE_KEYWORD = "keyword";
const TYPE_INTEGER = "integer";
const TYPE_LONG = "long";
const TYPE_DOUBLE = "double";
const TYPE_FLOAT = "float";
const TYPE_BYTE = "byte";
const TYPE_GEOPOINT = "geo_point";

export {
  TYPE_KEYWORD,
  TYPE_INTEGER,
  TYPE_LONG,
  TYPE_DOUBLE,
  TYPE_FLOAT,
  TYPE_BYTE,
  TYPE_GEOPOINT
};
