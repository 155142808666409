import styled from "styled-components";
import StyledBadgeContent from "./styled_badge_content";
import * as colors from "themes/colors";

export default styled(StyledBadgeContent)`
  background-color: ${props =>
    props.needsAcknowledgment
      ? props.muiTheme.palette.secondary.dark
      : colors.colorGalleryApproxDarker};
  color: ${props =>
    props.needsAcknowledgment
      ? colors.white
      : props.muiTheme.palette.text.primary};
  min-width: 30px;
`;
