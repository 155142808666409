import styled from "styled-components";

export default styled.ul`
  list-style-type: none;
  display: inline-block;
  margin: 0 0 0 ${props => (props.isBigSize ? "16px" : "8px")};
  padding: 0;
  svg {
    width: ${props => (props.isBigSize ? "24px" : "16px")};
    height: ${props => (props.isBigSize ? "24px" : "16px")};
  }
`;
