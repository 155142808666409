import { branch, withProps, compose } from "react-recompose";
import withThingObservations from "../../observation_hocs/with_thing_observations";
import withThingObservationsAggregation, {
  widgetIsAggregated
} from "../../observation_hocs/with_thing_observations_aggregation";
import withThingObservationsRealtime, {
  widgetIsRealtime
} from "../../observation_hocs/with_thing_observations_realtime";
import TableWidget from "./table_widget";

export default branch(
  widgetIsRealtime,
  withThingObservationsRealtime,
  compose(
    withProps({ timestampFormat: "yyyy-MM-DD HH:mm:ss" }),
    branch(
      widgetIsAggregated,
      withThingObservationsAggregation,
      withThingObservations
    )
  )
)(TableWidget);
