import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Logo } from "components/page";
import { Link } from "react-router";
import { compose, setPropTypes } from "react-recompose";
import { getStyles } from "./index.style";
import { authSelectors } from "ducks/auth";

export default compose(
  connect(state => ({
    signUpEnabled: authSelectors.getSignupEnabled(state)
  })),
  setPropTypes({
    signUpEnabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    children: PropTypes.node,
    location: PropTypes.object
  })
)(({ children, location, signUpEnabled }) => {
  const isLogin = location.pathname.match(/login/);
  const styles = getStyles();
  return (
    <div style={styles.main}>
      <div style={styles.header}>
        <Logo style={styles.logo} />
        {signUpEnabled && (
          <Link
            to={isLogin ? "/signup" : "/login"}
            style={styles.link({ fontSize: 14, marginLeft: "auto" })}
          >
            {isLogin ? "SIGN UP" : "SIGN IN"}
          </Link>
        )}
      </div>
      <div style={isLogin ? styles.loginContent : null}>{children}</div>
    </div>
  );
});
