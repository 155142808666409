import { graphql } from "@apollo/client/react/hoc";
import { sub as dateSubtract } from "date-fns";
import {
  CREATE_DATA_EXPORT,
  THING_TYPE_RESOURCES_QUERY
} from "graphql/queries";
import Joi from "joi-browser";
import * as R from "ramda";
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withState,
  withStateHandlers
} from "react-recompose";
import buildCron from "./build_cron";
import DataExportDialog from "./create_data_export";

const validateForm = (numberOfResources, label) => {
  const { error } = Joi.validate(label, Joi.string().trim());
  return numberOfResources > 0 && numberOfResources < 100 && !error;
};

const getAdHocMutationInput = ({
  selectedThings,
  selectedResources,
  label,
  thingTypeId,
  startDate,
  endDate,
  recipients,
  notifyByEmail
}) => ({
  type: "instant",
  things: selectedThings,
  resources: Array.from(selectedResources),
  jobName: label,
  thingType: selectedThings ? undefined : thingTypeId,
  from: startDate,
  to: endDate,
  recipients,
  notifyByEmail
});

const getPeriod = R.ifElse(
  R.pathEq(["backdate", "usePeriod"], true),
  ({ scheduleParams }) =>
    R.cond([
      [R.propEq("scheduleType", "daily"), f => `${f.daily.period}d`],
      [R.propEq("scheduleType", "weekly"), R.always("7d")]
    ])(scheduleParams),
  ({ backdate: { value, unit } }) => `${value}${unit}`
);

const getScheduledMutationInput = ({
  selectedThings,
  selectedResources,
  label,
  thingTypeId,
  backdate,
  cronString,
  scheduleParams,
  recipients,
  notifyByEmail
}) => ({
  type: "scheduled",
  schedule: `cron(${cronString})`,
  things: selectedThings,
  resources: Array.from(selectedResources),
  jobName: label,
  thingType: selectedThings ? undefined : thingTypeId,
  timePeriod: getPeriod({ backdate, scheduleParams }),
  recipients,
  notifyByEmail
});

const getMutationInput = R.ifElse(
  R.propEq("isScheduled", true),
  getScheduledMutationInput,
  getAdHocMutationInput
);

export const scheduleParamsAreValid = params => {
  let valid = true;

  if (R.pathSatisfies(period => period < 1, ["daily", "period"])(params))
    valid = false;

  if (R.pathSatisfies(period => period < 1, ["weekly", "period"])(params))
    valid = false;

  return valid;
};

const _withStateHandlers = withStateHandlers(
  {
    startDate: dateSubtract(new Date(), { days: 5 }),
    endDate: new Date(),
    label: "",
    selectedResources: new Set(),
    selectedState: 0,
    formValid: false,
    errorText: "",
    activeStep: 0,
    isScheduled: false,
    notifyByEmail: false,
    recipients: "",
    scheduleParams: {
      scheduleType: "daily",
      daily: {
        period: "3", // Every 3 days
        time: "10:49" // at 10:49
      },
      weekly: {
        day: "0", // On monday
        time: "10:49" // At 10:49
      }
    },
    backdate: {
      value: "7",
      unit: "d",
      usePeriod: true
    }
  },
  {
    handleToggleNotifyByEmail: () => ev => ({
      notifyByEmail: ev.target.checked
    }),
    handleRecipientsChanged: () => ev => ({ recipients: ev.target.value }),
    validateRecipients: state => ev => {
      const recipients = ev.target.value.replace(/[, ]+$/, "");
      if (recipients === "") {
        return {
          emailValidationError: "This field is required",
          recipients,
          formValid: false
        };
      }

      const emails = recipients.split(",");
      const errors = [];

      for (const email of emails) {
        const { error } = Joi.validate(email.trim(), Joi.string().email());
        if (error) errors.push(email);
      }

      if (errors.length === 0) {
        return {
          emailValidationError: undefined,
          recipients,
          formValid: validateForm(state.selectedResources.size, state.label)
        };
      }

      const errorMessage = `Invalid emails entered: ${errors.join(", ")}`;
      return {
        recipients,
        emailValidationError: errorMessage,
        formValid: false
      };
    },
    handleBackdateChanged: state => backDate => {
      if (backDate.value === "") return;
      if (backDate.value && isNaN(parseInt(backDate.value, 10))) return;

      return R.mergeDeepRight(state, { backdate: backDate });
    },
    handleNextStep: ({ activeStep }) => () => ({ activeStep: activeStep + 1 }),
    handlePreviousStep: ({ activeStep }) => () => ({
      activeStep: Math.max(0, activeStep - 1)
    }),
    handleScheduleParamsChange: state => newScheduleParams => {
      if (!scheduleParamsAreValid(newScheduleParams)) return;

      return R.mergeDeepRight(state, {
        scheduleParams: newScheduleParams
      });
    },
    handleStartDateChange: () => selectedDate => ({
      startDate: selectedDate
    }),
    handleEndDateChange: () => selectedDate => ({ endDate: selectedDate }),
    handleToggleIsScheduled: state => () => ({
      isScheduled: !state.isScheduled
    }),
    handleScheduleTypeChanged: state => ev =>
      R.mergeDeepRight(state, {
        scheduleParams: {
          scheduleType: ev.target.value
        }
      }),
    handleThingTypeSelected: () => (e, thingType) => ({ thingType: thingType }),
    handleLabelChange: state => change => {
      const { error } = Joi.validate(change, Joi.string().trim());
      return {
        ...state,
        label: change,
        errorText: !error ? "" : "This field is required",
        formValid: validateForm(state.selectedResources.size, change)
      };
    },
    toggleSelectedResources: () => (selectedResources, selectedState) => {
      return { selectedResources, selectedState };
    },
    handleSelectedResourcesChange: state => resources => ({
      selectedResources: resources,
      formValid: validateForm(resources.size, state.label)
    })
  }
);

const _withHandler = withHandlers({
  toggleSelectAllResources: ({
    resources,
    selectedState,
    toggleSelectedResources
  }) => filteredResources => {
    const allIsSelected = filteredResources.length === resources.length;
    const nextSelectedState =
      allIsSelected && selectedState === 0
        ? 2
        : !allIsSelected && selectedState === 2
        ? 1
        : !allIsSelected && selectedState === 0
        ? 1
        : 0;

    const selected =
      nextSelectedState === 2
        ? new Set(R.pluck("name", resources))
        : nextSelectedState === 1
        ? new Set(R.pluck("name", filteredResources))
        : new Set();
    toggleSelectedResources(selected, nextSelectedState);
  },
  handleToggleResource: ({
    resources,
    selectedResources,
    toggleSelectedResources
  }) => resource => {
    const modifiableSet = new Set(selectedResources);
    modifiableSet.has(resource)
      ? modifiableSet.delete(resource)
      : modifiableSet.add(resource);
    toggleSelectedResources(
      modifiableSet,
      resources.length === modifiableSet.size
        ? 2
        : modifiableSet.size > 0
        ? 1
        : 0
    );
  },

  handleCreateExport: ({
    label,
    selectedResources,
    startDate,
    endDate,
    isScheduled,
    scheduleParams,
    backdate,
    recipients,
    notifyByEmail,
    selectedThings,
    setIsLoading,
    thingTypeId,
    createDataExportMutation,
    closeModalDataExport
  }) => () => {
    setIsLoading(true);
    createDataExportMutation({
      variables: {
        input: getMutationInput({
          label,
          selectedResources,
          startDate,
          endDate,
          selectedThings,
          thingTypeId,
          cronString: buildCron(scheduleParams),
          scheduleParams,
          backdate,
          isScheduled,
          recipients,
          notifyByEmail
        })
      },
      update: () => {
        setIsLoading(false);
        closeModalDataExport();
      }
    }).catch(() => setIsLoading(false));
  }
});

const _validateProps = ({ thingTypeId }) => {
  return thingTypeId ? false : true;
};

const _withResources = withPropsOnChange(["thingTypeResourceQuery"], props => {
  return {
    resources: R.pathOr(
      [],
      ["thingTypeResourceQuery", "thingTypeResources", "resources"],
      props
    )
  };
});

export default compose(
  withState("isLoading", "setIsLoading", false),

  _withStateHandlers,
  graphql(THING_TYPE_RESOURCES_QUERY, {
    name: "thingTypeResourceQuery",
    skip: _validateProps,
    options: () => ({ fetchPolicy: "network-only" })
  }),
  graphql(CREATE_DATA_EXPORT, {
    name: "createDataExportMutation"
  }),
  _withResources,
  _withHandler
)(DataExportDialog);
