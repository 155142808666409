import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import { ProgressLine } from "components/cards/card";
import {
  EmptyActionRowCell,
  FloatingHoverActionRow,
  HoverButton
} from "components/composable_table/action_row_column";
import { TableRowFragment } from "components/composable_table/table_row_fragment";
import { TableHeaderCell } from "components/table";
import AllThingsPaginationFooter from "components/things_list_pagination_footer/component";
import AllThingsWidgetHeader from "components/widget/widget_types/all_things_widget/all_things_widget_header";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import * as imu from "utils/immutable_utils";
import { sortingFromString } from "utils/table_utils";

const fieldIsClickable = field =>
  !field.disableSorting && field.onClick != null;

const createSortHandler = field => () =>
  fieldIsClickable(field) && field.onClick();

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden"
  },
  tableWrapper: {
    flex: 1,
    overflow: "auto"
  },
  link: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    display: "inline-block",
    overflow: "hidden",
    paddingBottom: 5,
    paddingTop: 10,
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    "&:hover": {
      color: theme.palette.text.secondary
    }
  },
  table: {
    tableLayout: "fixed"
  },
  ellipsisCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

const FieldLabel = ({ field }) => (
  <React.Fragment>
    {typeof field.label === "string" ? field.label.toUpperCase() : field.label}
    {field.labelSecondary && (
      <div>
        <small>{field.labelSecondary}</small>
      </div>
    )}
  </React.Fragment>
);

FieldLabel.propTypes = {
  field: PropTypes.object
};

const AllThingsTableHead = ({ decoratedFields, paginationStatus }) => {
  const classes = useStyles();
  const sorting = sortingFromString(paginationStatus.sort);

  return (
    <TableHead>
      <TableRow>
        {decoratedFields.map(
          (field, i) => (
            <TableHeaderCell
              key={i}
              sortDirection={sorting.order}
              className={classes.ellipsisCell}
            >
              {fieldIsClickable(field) ? (
                <TableSortLabel
                  active={sorting.column === field.sortString}
                  direction={sorting.order}
                  onClick={createSortHandler(field)}
                >
                  <FieldLabel field={field} />
                </TableSortLabel>
              ) : (
                <FieldLabel field={field} />
              )}
            </TableHeaderCell>
          ),
          this
        )}
        <EmptyActionRowCell />
      </TableRow>
    </TableHead>
  );
};

AllThingsTableHead.propTypes = {
  decoratedFields: PropTypes.array,
  paginationStatus: PropTypes.object
};

const getContent = (field, thing, thingIndex) =>
  field.transform
    ? field.transform(thing, thingIndex, field)
    : imu.get(thing, field.selector);

const buildActionCell = (classes, fieldContent, thing, field) => {
  const title = typeof fieldContent === "string" ? fieldContent : undefined;

  return field.linksTo ? (
    <Link title={title} to={field.linksTo(thing)} className={classes.link}>
      {fieldContent}
    </Link>
  ) : field.handleClick ? (
    <span onClick={field.handleClick(thing)} title={title}>
      {fieldContent}
    </span>
  ) : (
    <span title={title}>{fieldContent}</span>
  );
};
const AllThingsTable = ({
  columnPickerProps,
  decoratedFields,
  filter,
  handleDeleteAction,
  handleDownloadCertificateAction,
  handleEditAction,
  handleReplaceCertificateAction,
  handleOnPage,
  isLoading,
  paginationStatus,
  params,
  things,
  userCanDeleteThings,
  userCanEditThings,
  widgetLabel,
  widgetId,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <ProgressLine isLoading={isLoading} />
      <AllThingsWidgetHeader
        {...rest}
        widgetLabel={widgetLabel}
        columnPickerProps={columnPickerProps}
      />
      <TableContainer className={classes.tableWrapper}>
        <Table size="small" stickyHeader className={classes.table}>
          <AllThingsTableHead
            decoratedFields={decoratedFields}
            paginationStatus={paginationStatus}
          />
          <TableBody>
            {things.map((thing, thingIndex) => {
              return (
                <TableRowFragment
                  hover
                  tabIndex={-1}
                  key={thingIndex}
                  renderColumns={({ isHovered }) => (
                    <React.Fragment>
                      {decoratedFields.map((field, fieldIndex) => (
                        <TableCell
                          key={fieldIndex}
                          className={classes.ellipsisCell}
                        >
                          {buildActionCell(
                            classes,
                            getContent(field, thing, thingIndex),
                            thing,
                            field
                          )}
                        </TableCell>
                      ))}
                      <FloatingHoverActionRow show={isHovered}>
                        {userCanEditThings && (
                          <Tooltip title="Edit thing">
                            <HoverButton
                              data-test="action-edit-thing"
                              onClick={() => handleEditAction(thing)}
                            >
                              <EditIcon />
                            </HoverButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Download certificate">
                          <HoverButton
                            data-test="action-download-certificate"
                            onClick={() =>
                              handleDownloadCertificateAction(thing)
                            }
                          >
                            <DownloadIcon />
                          </HoverButton>
                        </Tooltip>
                        {userCanEditThings && (
                          <Tooltip title="Replace certificate">
                            <HoverButton
                              data-test="action-replace-certificate"
                              onClick={() =>
                                handleReplaceCertificateAction(thing)
                              }
                            >
                              <CancelIcon />
                            </HoverButton>
                          </Tooltip>
                        )}
                        {userCanDeleteThings && (
                          <Tooltip title="Delete thing">
                            <HoverButton
                              data-test="action-delete-thing"
                              onClick={() => handleDeleteAction(thing)}
                            >
                              <DeleteIcon />
                            </HoverButton>
                          </Tooltip>
                        )}
                      </FloatingHoverActionRow>
                    </React.Fragment>
                  )}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AllThingsPaginationFooter
        isLoading={isLoading}
        paging={paginationStatus}
        onPage={handleOnPage}
        thingType={params.thingType}
        widgetId={widgetId}
        filter={filter}
      />
    </div>
  );
};

AllThingsTable.propTypes = {
  columnPickerProps: PropTypes.object,
  decoratedFields: PropTypes.array,
  filter: PropTypes.object,
  handleDeleteAction: PropTypes.func,
  handleDownloadCertificateAction: PropTypes.func,
  handleEditAction: PropTypes.func,
  handleReplaceCertificateAction: PropTypes.func,
  handleOnPage: PropTypes.func,
  isLoading: PropTypes.bool,
  onColumnPickerExpandedChange: PropTypes.func,
  paginationStatus: PropTypes.object,
  params: PropTypes.object,
  things: PropTypes.array,
  userCanDeleteThings: PropTypes.bool,
  userCanEditThings: PropTypes.bool,
  widget: PropTypes.object,
  widgetId: PropTypes.string,
  widgetLabel: PropTypes.string
};

export default AllThingsTable;
