import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  AVERAGE as defaultAggregationType,
  OPTION_NAME as aggregationTypeOptionName
} from "components/widget/edit/widget_edit_aggregation_options";
import { inputElasticThingsFilterSelector } from "ducks/filter/filter_selectors";
import { observationSelectors } from "ducks/observation";
import { resourceLabelById } from "ducks/schema/schema_selectors";
import {
  withDefault,
  withTimezone,
  withVariables
} from "graphql/utils/general";
import { fromJS } from "immutable";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, pure, withPropsOnChange } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import { getAggregationInterval, resourceStatePath } from "utils/widget_utils";

const filteredThingObservationsAggregationQuery = gql`
  query(
    $filter: InputListThingsFilter!
    $from: String!
    $to: String!
    $resourceStatePath: String!
    $aggregationType: AggregationType!
    $aggregationInterval: String!
    $timestampFormat: String!
    $timezone: String
  ) {
    filteredThingObservationsAggregation(
      searchOptions: {
        filter: $filter
        period: { from: $from, to: $to }
        resource: $resourceStatePath
      }
      aggregationType: $aggregationType
      aggregationInterval: $aggregationInterval
    ) {
      buckets {
        key(
          momentFunction: {
            name: format
            arguments: [$timestampFormat]
            timezone: $timezone
          }
        )
        value
      }
    }
  }
`;

const OBSERVATIONS_BUCKETS_PATH = [
  "filteredThingObservationsAggregationQuery",
  "filteredThingObservationsAggregation",
  "buckets"
];

const mapStateToProps = (state, props) => {
  const { widget } = props;
  const resourceId = resourceIdFromWidget(widget);

  const observation = observationSelectors.getObservation(state);
  return {
    ...props,
    observation,
    filter: inputElasticThingsFilterSelector(state, props),
    resource: fromJS({ id: resourceId }),
    resourceLabel:
      resourceLabelById(state, { id: resourceIdFromWidget(widget) }) ||
      props.resourceName,
    aggregationType: widgetProp(
      widget,
      aggregationTypeOptionName,
      defaultAggregationType
    ),
    aggregationInterval: getAggregationInterval({
      widget,
      observationPeriod: observation
    }),
    resourceStatePath: resourceStatePath(props.subthing, resourceId),
    thingName: props.params.thingName,
    thingType: props.params.thingType,
    from: imu.get(observation, "from").toISOString(),
    to: imu.get(observation, "to").toISOString()
  };
};

const withFilteredObservationAggregations = compose(
  connect(mapStateToProps),
  graphql(filteredThingObservationsAggregationQuery, {
    name: "filteredThingObservationsAggregationQuery",
    options: withVariables(
      [
        "filter",
        "from",
        "to",
        "resourceStatePath",
        "aggregationType",
        "aggregationInterval",
        "timestampFormat"
      ],
      withDefault("timestampFormat", ""),
      withTimezone
    )
  }),
  withPropsOnChange(
    (props, nextProps) =>
      R.path(OBSERVATIONS_BUCKETS_PATH, props) !==
      R.path(OBSERVATIONS_BUCKETS_PATH, nextProps),
    props => ({
      observations: R.pipe(
        R.path(OBSERVATIONS_BUCKETS_PATH),
        R.defaultTo([]),
        R.map(
          R.applySpec({
            timestamp: R.prop("key"),
            value: R.prop("value")
          })
        ),
        observations =>
          fromJS(observations).sort((a, b) => {
            const at = a.get("timestamp");
            const bt = b.get("timestamp");
            return at > bt ? -1 : 1;
          })
      )(props)
    })
  ),
  pure
);

export default withFilteredObservationAggregations;
