import { Observable } from "rxjs";
import { apiError } from "ducks/errors";
import { getWidgetState } from "./dashboard_selectors";
import * as dashboards from "./dashboards";

const getSaveDashboardWidgetAttributes = store => ({
  payload: { dashboardId, widgetId, entity }
}) => ({
  id: dashboardId,
  keypath: `viewModes.v1.grids.g1.widgets.${widgetId}`,
  entity: getWidgetState(store.getState(), { widgetId })
    .merge(entity)
    .toJS()
});

export const getDashboardsEpic = (action$, store, { api, adaptDashboards }) =>
  action$.ofType(dashboards.DASHBOARDS_REQUEST).mergeMap(action =>
    api.dashboard
      .fetchDashboards$(action.payload)
      .map(response => adaptDashboards(response))
      .map(normalized => dashboards.actions.getDashboardsSuccess(normalized))
      .catch(error =>
        Observable.of(
          dashboards.actions.getDashboardsFailure(error),
          apiError(error)
        )
      )
  );

export const saveDashboardEpic = (action$, store, { api, adaptDashboard }) =>
  action$.ofType(dashboards.DASHBOARD_SAVE_REQUEST).mergeMap(action =>
    api.dashboard
      .saveDashboard$(action.payload)
      .map(response => adaptDashboard(response))
      .map(normalized => dashboards.actions.getDashboardsSuccess(normalized))
      .catch(error =>
        Observable.of(
          dashboards.actions.saveDashboardFailure(error),
          apiError(error)
        )
      )
  );

export const saveDashboardWidgetEpic = (
  action$,
  store,
  { api, adaptDashboard }
) =>
  action$
    .ofType(dashboards.DASHBOARD_SAVE_WIDGET_REQUEST)
    .map(getSaveDashboardWidgetAttributes(store))
    .mergeMap(mappedAttributes =>
      api.dashboard
        .saveDashboard$(mappedAttributes)
        .map(response => adaptDashboard(response))
        .map(normalized => dashboards.actions.getDashboardsSuccess(normalized))
        .catch(error =>
          Observable.of(
            dashboards.actions.saveDashboardWidgetFailure(error),
            apiError(error)
          )
        )
    );
