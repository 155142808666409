import { TYPE_GEOPOINT } from "../types";
import { startGeoPoint } from "../utils";
import { latLen, lonLen, latLooper, longLooper } from "utils/latlng_utils";

export default (type, params, { oldValue, interval }) => {
  switch (type) {
    case TYPE_GEOPOINT:
    default: {
      const { lat, lon, lat_end, lon_end, vel, precision } = params;
      // Point a
      const a = startGeoPoint(lat, lon, oldValue);

      if (!oldValue)
        return `${a[0].toFixed(precision)},${a[1].toFixed(precision)}`;
      // Point b
      const b = [lat_end, lon_end];
      // Vector a->b
      const ab = [b[0] - a[0], b[1] - a[1]];
      const norm = Math.sqrt(Math.pow(ab[0], 2) + Math.pow(ab[1], 2)) || 1;
      // Normlized vector
      ab[0] = ab[0] / norm;
      ab[1] = ab[1] / norm;
      // Next Point based on the velocity (m/s) and the simulation interval
      const p = [
        a[0] + ((ab[0] * vel) / latLen(a[0])) * (interval / 1000),
        a[1] + ((ab[1] * vel) / lonLen(a[0])) * (interval / 1000)
      ];
      // If overshooting point b
      p[0] = ab[0] < 0 ? Math.max(p[0], b[0]) : Math.min(p[0], b[0]);
      p[1] = ab[1] < 0 ? Math.max(p[1], b[1]) : Math.min(p[1], b[1]);
      return `${latLooper(p[0]).toFixed(precision)},${longLooper(p[1]).toFixed(
        precision
      )}`;
    }
  }
};
