import { authSelectors } from "ducks/auth";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { ViewModeEditButtons } from "routes/main/things/thing_type/overview/components/view_mode";
import styled from "styled-components";

const ToolbarContainer = styled.div`
  display: flex;
`;

const AllThingsToolBarContainer = props => {
  return (
    <ToolbarContainer>
      <ViewModeEditButtons {...props} />
    </ToolbarContainer>
  );
};

AllThingsToolBarContainer.propTypes = {
  loggedInUser: PropTypes.object,
  isMovingWidgets: PropTypes.bool,
  showEdit: PropTypes.bool
};
AllThingsToolBarContainer.defaultProps = {};

const makeMapStateToProps = () => {
  return (
    state,
    { params, location, setIsMovingWidgets, restoreUndoWidgets }
  ) => {
    const loggedInUser = authSelectors.userSelector(state);

    // let the handleEdit-function show the edit viewmode modal form

    const handleMoveWidgets = () => setIsMovingWidgets(true);
    const handleUndo = () => {
      setIsMovingWidgets(false);
    };
    return {
      handleUndo,
      handleMoveWidgets,
      params,
      location,
      loggedInUser
    };
  };
};

export default connect(makeMapStateToProps)(AllThingsToolBarContainer);
