import { createAction, handleActions } from "redux-actions";
import { fromJS, Map, List } from "immutable";
import { unionSetMerger } from "utils/immutable_utils";

export const ADD_RAW_OBSERVATIONS = "ADD_RAW_OBSERVATIONS";
export const CLEAR_OBSERVATIONS = "CLEAR_OBSERVATIONS";
export const AGGREGATED_OBSERVATION_SUCCESS = "AGGREGATED_OBSERVATION_SUCCESS";

export const addRawObservations = createAction(ADD_RAW_OBSERVATIONS);
export const clearObservations = createAction(CLEAR_OBSERVATIONS);
export const findAggregatedObservationSuccess = createAction(
  AGGREGATED_OBSERVATION_SUCCESS
);

const initialState = fromJS({
  entities: {
    things: {},
    resources: {},
    rawObservations: {},
    aggregatedObservations: {}
  }
});

export const observationsReducer = (state, action, type) => {
  const entities = action.payload.entities || {};
  const resources = Object.keys(entities.resources || []);
  const res = state
    .mergeIn(["entities", type], fromJS(entities[type]) || Map({}))
    .mergeWith(unionSetMerger, fromJS({ entities }));

  const b = List(resources).reduce((acc, resource) => {
    const newResources =
      (entities &&
        entities.resources &&
        entities.resources[resource] &&
        entities.resources[resource][type]) ||
      [];

    return acc.setIn(
      ["entities", "resources", resource, type],
      fromJS(newResources)
    );
  }, res);
  return b;
};

export default handleActions(
  {
    [ADD_RAW_OBSERVATIONS]: (state, action) =>
      observationsReducer(state, action, "rawObservations"),

    [CLEAR_OBSERVATIONS]: () => initialState,

    [AGGREGATED_OBSERVATION_SUCCESS]: (state, action) =>
      observationsReducer(state, action, "aggregatedObservations")
  },
  initialState
);
