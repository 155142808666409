import React from "react";
import PropTypes from "prop-types";
import { compose, withStateHandlers } from "react-recompose";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import { HexColorPicker } from "react-colorful";

import { Swatch } from "components/colors";

const swatchStyle = {
  margin: "8px"
};

const ColorPicker = ({
  color,
  onChange,
  handleOpen,
  handleClose,
  anchorEl,
  classes
}) => {
  return (
    <div>
      <Swatch color={color} onClick={handleOpen} style={swatchStyle} />
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <HexColorPicker
          className={classes.colorPicker}
          color={color}
          onChange={chosenColor => {
            onChange(chosenColor);
          }}
        />
      </Popover>
    </div>
  );
};

ColorPicker.propTypes = {
  anchorEl: PropTypes.any,
  color: PropTypes.string,
  classes: PropTypes.shape({
    colorPicker: PropTypes.string
  }),
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default compose(
  withStateHandlers(
    { anchorEl: null },
    {
      handleOpen: () => evt => ({ anchorEl: evt.target }),
      handleClose: () => () => ({ anchorEl: null })
    }
  ),
  withStyles(theme => ({
    colorPicker: {
      padding: theme.spacing(1)
    }
  }))
)(ColorPicker);
