import * as R from "ramda";
import resourceTypes from "../widget_types/widget_resource_types.js";
import { isNumber, isString } from "../widget_types/widget_resource_types.js";

export const isObservationWidget = type =>
  type === "Table" || type === "Timeseries";

const filterResourcesOnStringType = resourceType => resources =>
  isString(resourceType)
    ? resources.filter(
        resource => isString(resourceType) && isString(resource.type)
      )
    : resources;

const filterResourcesOnNumberType = resourceType => resources =>
  isNumber(resourceType)
    ? resources.filter(
        resource =>
          isNumber(resourceType) &&
          (isNumber(resource.type) || isNumber(resource.dataType))
      )
    : resources;

export const filterResourcesOnType = (resourceType = "") => (resources = []) =>
  resourceType === resourceTypes.ANY
    ? resources
    : R.compose(
        filterResourcesOnStringType(resourceType),
        filterResourcesOnNumberType(resourceType)
      )(resources);

export const filterResourcesOnDomain = resources =>
  resources.filter(resource =>
    resource.value ? resource.value.indexOf("domain.") < 0 : true
  );

export const filterResources = (widgetType, resourceType, resources) => {
  const result = R.compose(
    filterResourcesOnType(resourceType),
    R.when(() => isObservationWidget(widgetType), filterResourcesOnDomain)
  )(resources);
  return result;
};
