import { fromJS, Map, List } from "immutable";
import { createSelector } from "reselect";
import { byIconAndLabel } from "utils/sorters";
import * as R from "ramda";
import * as imu from "utils/immutable_utils";
import { makeResourcePathString } from "utils/thing_utils";
import { authSelectors } from "../auth";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";

export const getResources = (schema, thingTypeId, _options) => {
  if (!schema || !thingTypeId) return List();
  const defaults = {
    includeVirtual: true,
    includeTcxn: true,
    includeSubthings: true,
    type: "list"
  };
  const options = Object.assign(defaults, _options);
  const isSubthing = thingTypeId.indexOf("/") > 0;
  const schemaPath = ["entities", "schemaElement", thingTypeId];
  const resourcePath = ["entities", "resources"];

  let resourceIds = R.pathOr([], [...schemaPath, "resources"], schema);

  if (options.includeVirtual) {
    const virtualSchemaPath = [
      "entities",
      "schemaElement",
      `${thingTypeId}/virtual`
    ];
    let virtualResourcesIds = R.pathOr(
      [],
      [...virtualSchemaPath, "resources"],
      schema
    );
    resourceIds = virtualResourcesIds
      ? resourceIds.concat(virtualResourcesIds)
      : resourceIds;
  }

  if (options.includeSubthings && !isSubthing) {
    const subThingResourcesIds = R.pipe(
      R.pathOr([], resourcePath),
      R.keys,
      R.filter(resourceId => resourceId.indexOf(`${thingTypeId}/subthing`) == 0)
    )(schema);
    resourceIds = resourceIds.concat(subThingResourcesIds);
  }

  if (!options.includeTcxn) {
    resourceIds = resourceIds.filter(id => {
      return !/tcxn\//.test(id);
    });
  }

  if (options.type === "resourceIds") {
    return fromJS(resourceIds);
  } else if (options.type === "list") {
    return fromJS(resourceIds.map(id => R.path([...resourcePath, id], schema)));
  } else {
    return fromJS(
      resourceIds.reduce((mem, id) => {
        mem[id] = R.path([...resourcePath, id], schema);
        return mem;
      }, {})
    );
  }
};

export const getResource = (schema, resourcePath) => {
  return fromJS(imu.getIn(schema, ["entities", "resources", resourcePath], {}));
};

const isTcxn = name => /^tcxn\//.test(name);
const stripName = id =>
  isTcxn(id)
    ? id.slice(id.indexOf("/") + 1)
    : id.slice(id.lastIndexOf("/") + 1);

const resourceEntities = state =>
  R.pathOr({}, ["entities", "resources"], state.schema);

const withLabelAndIcon = resource => {
  const name = imu.get(resource, "name");
  const label = imu.getIn(resource, ["metadata", "label"]);
  return isTcxn(name)
    ? resource.set("icon", "tcxn").set("label", label || stripName(name))
    : resource.set("label", label || name);
};
export const getResourcesForAllThings = createSelector(
  state => resourceEntities(state),
  resources => {
    resources = fromJS(resources);
    return resources
      .reduce((mem, resource) => {
        const name = resource.get("name");
        const id = resource.get("id");
        return isTcxn(name)
          ? mem.set(name, withLabelAndIcon(resource))
          : mem.set(id, withLabelAndIcon(resource));
      }, Map())
      .sort(byIconAndLabel);
  }
);

export const getResourceName = resourceName =>
  resourceName.indexOf("/") > 0
    ? resourceName.slice(resourceName.lastIndexOf("/") + 1)
    : resourceName;

const _appendToPath = (path, item) => (item ? `${path}/${item}` : `${path}`);

const _thingTypePath = ({ thingType, subThingType, virtual }) => {
  let path = _appendToPath(thingType);
  if (subThingType) {
    path = _appendToPath(_appendToPath(path, "subthing"), subThingType);
  }
  if (virtual) {
    path = _appendToPath(path, "virtual");
  }
  return path;
};

export const getResourcesByParams = (
  schemaState,
  { thingType, subthing, subThingType, virtual },
  options
) => {
  if (subthing === "default") subThingType = undefined;
  const path = _thingTypePath({ thingType, subThingType, virtual });
  return getResources(schemaState, path, options);
};

export const getSchemaElement = (
  schemaState,
  { thingType, subthing, subThingType }
) => {
  if (subthing === "default") subThingType = undefined;
  const path = _thingTypePath({ thingType, subThingType });
  return fromJS(R.path(["entities", "schemaElement", path], schemaState));
};

export const filterResources = (
  schemaState,
  { thingType, subThingType, virtual }
) => {
  const path = R.concat(
    _thingTypePath({ thingType, subThingType, virtual }),
    "/"
  );
  return R.pipe(
    R.pathOr([], ["entities", "resources"]),
    R.filter(resource => resource.id.indexOf(path) === 0),
    fromJS
  )(schemaState);
};

export const resourceTypesByName = createSelector(resourceEntities, resources =>
  R.pipe(
    R.values,
    R.groupBy(R.pipe(R.prop("id"), makeResourcePathString)),
    R.map(R.compose(R.uniq, R.pluck(["type"])))
  )(resources)
);

const emptyObject = Map({});

const _getSchemaState = R.propOr({}, "schema");

export const _resourceEntities = createSelector(_getSchemaState, schema => {
  const res = R.path(["entities", "resources"], schema);
  return res ? fromJS(res) : emptyObject;
});

export const resourceUnitById = createSelector(
  _resourceEntities,
  (state, { id }) => id,
  (entities, resourceId) => resourceUnitByIdFromEntities(entities, resourceId)
);

export const resourceUnitByIdFromEntities = createSelector(
  entities => entities,
  (entities, resourceId) => resourceId,
  (entities, resourceId) =>
    R.pipe(
      imu.unImmute,
      R.path([resourceId, "metadata", "unit"]),
      fromJS
    )(entities)
);

export const resourceLabelById = createSelector(
  _resourceEntities,
  (state, { id }) => id,
  (entities, id) => resourceLabelByIdFromEntities(entities, id)
);

export const resourceLabelByIdFromEntities = createSelector(
  entities => entities,
  (entities, resourceId) => resourceId,
  (entities, resourceId) =>
    imu.getIn(entities, [resourceId, "metadata", "label"])
);

export const resourceIsSettableById = createSelector(
  _resourceEntities,
  (state, { id }) => id,
  (resources, resourceId) =>
    imu.getIn(resources, [resourceId, "metadata", "issettable"], true)
);

export const getResourceIdsByThingTypes = createSelector(
  _getSchemaState,
  (state, thingTypes) => thingTypes,
  (schema, thingTypes) => {
    return thingTypes.reduce(
      (acc, thingType) => ({
        ...acc,
        [thingType]: getResources(schema, thingType, {
          type: "resourceIds"
        }).toJS()
      }),
      {}
    );
  }
);

export const isLoading = state => R.path(["schema", "isLoading"], state);

export const getError = createSelector(
  state => R.path(["schema", "error"], state),
  error => fromJS(error)
);

export const lastSelectedResource = createSelector(
  state => R.path(["schema", "lastSelectedResource"], state),
  lastSelectedResource => fromJS(lastSelectedResource)
);

export const getCommon = createSelector(
  getError,
  isLoading,
  lastSelectedResource,
  (error, isLoading, lastSelectedResource) => {
    error, isLoading, lastSelectedResource;
  }
);

export const getNewResource = createSelector(
  state => R.path(["schema", "new"], state),
  newResource => fromJS(newResource)
);

export const _hasStateValue = item =>
  R.not(R.pathSatisfies(R.isNil, ["reported", "value"])(item)) ||
  R.not(R.pathSatisfies(R.isNil, ["desired", "value"])(item));

export const isCorrectThingType = (_thingType, _resources) => resourceKey => {
  const _resource = _resources[resourceKey] || "";
  return _resource.id.split("/")[0] === _thingType;
};

export const isCorrectSubThing = (
  _subThing,
  _subThingType,
  _resources
) => resourceKey => {
  const _resource = _resources[resourceKey];
  const isSubthingResource = _resource.id.split("/")[1] === "subthing";
  const isCorrectSubthingResource = isSubthingResource
    ? _subThingType === _resource.id.split("/")[2]
    : false;
  return _subThing !== "default"
    ? isSubthingResource && isCorrectSubthingResource
    : !isSubthingResource;
};

export const combineResourcesAndShadow = (
  schemaResources,
  thingShadowResources,
  thingName,
  thingType,
  subThing = "default",
  subThingType
) => {
  const combinedValues = Object.keys(schemaResources)
    .filter(isCorrectThingType(thingType, schemaResources))
    .filter(isCorrectSubThing(subThing, subThingType, schemaResources))
    .map(key => {
      const _resource = schemaResources[key];
      const _metadata = R.propOr({}, "metadata")(_resource);
      const shadowValue =
        thingShadowResources[
          _resource.id.split("/")[1] === "subthing"
            ? `${thingName}/${subThing}/${_resource.id}`
            : `${thingName}/${_resource.id}`
        ];
      return {
        id: _resource.id,
        thingName,
        thingType,
        subThing,
        subThingType,
        label: _metadata.label,
        name: _resource.name,
        type: _resource.type,
        isSettable: _metadata.issettable,
        isVirtual: _metadata.isvirtual,
        unit: _metadata.unit,
        reported: {
          value: R.pathOr(null, ["latestValue", "value"])(shadowValue),
          timestamp: R.pathOr(null, ["latestValue", "timestamp"])(shadowValue)
        },
        desired: {
          value: R.ifElse(
            R.prop("pending"),
            R.propOr(null, "pendingValue"),
            () => null
          )(shadowValue)
        }
      };
    });
  return R.sortWith([R.descend(_hasStateValue), R.ascend(R.prop("name"))])(
    combinedValues
  );
};

export const resourcesWithValuesSelector = createSelector(
  [
    state => R.pathOr([], ["schema", "entities", "resources"])(state),
    (state, { thingName }) =>
      state.thing.getIn(["thingShadow", thingName, "resources"], fromJS([])),
    (state, { thingName }) => thingName,
    (state, { thingType }) => thingType,
    (state, { subthing }) => subthing,
    (state, { subThingType }) => subThingType
  ],
  (
    schemaResources,
    thingShadowResources,
    thingName,
    thingType,
    subThing,
    subThingType
  ) =>
    combineResourcesAndShadow(
      schemaResources,
      thingShadowResources.toJS(),
      thingName,
      thingType,
      subThing,
      subThingType
    )
);

export const userCanCreateResource = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.ThingTypes, OPERATIONS.CREATE)
);
