import * as R from "ramda";
import { defer, Observable } from "rxjs";
import unauthorizedFetch from "../utils/unauthorized_fetch";

const convertToErrorMessage = payload => {
  return R.ifElse(
    R.has("message"),
    R.pipe(
      R.assoc("errorMessage", R.prop("message")(payload)),
      R.dissoc("message")
    ),
    () => payload
  )(payload);
};

const handleLogIn = async ({ userName, password }) => {
  const response = await unauthorizedFetch("/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ userName, password })
  });
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  }

  return data;
};

export const loginUser$ = ({ userName, password }) => {
  return defer(() =>
    Observable.fromPromise(handleLogIn({ userName, password }))
  );
};

const doRefreshToken = async refreshToken => {
  const response = await unauthorizedFetch("/auth/refresh", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ refreshToken })
  });
  const data = await response.json();

  if (!response.ok) {
    throw data;
  }

  return data;
};

export const refreshToken$ = refreshToken => {
  return defer(() => Observable.fromPromise(doRefreshToken(refreshToken)));
};

const doSetPassword = async payload => {
  const response = await unauthorizedFetch("/auth/set-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  }

  return data;
};

export const setPassword$ = payload => {
  return defer(() => Observable.fromPromise(doSetPassword(payload)));
};

const getConsentDocument = async () => {
  const response = await unauthorizedFetch("/auth/get-consent-document");
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  }

  return data;
};

export const getConsentDocument$ = () => {
  return defer(() => Observable.fromPromise(getConsentDocument()));
};

const giveConsent = async payload => {
  const response = await unauthorizedFetch("/auth/consent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
  const data = await response.json();

  if (!response.ok) {
    throw data;
  }

  return data;
};

export const giveConsent$ = payload => {
  return defer(() => Observable.fromPromise(giveConsent(payload)));
};

const signUp = async payload => {
  const response = await unauthorizedFetch("/auth/sign-up", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
  const data = await response.json();

  if (!response.ok) {
    throw convertToErrorMessage(data);
  }

  return data;
};

export const registerUser$ = payload => {
  return defer(() => Observable.fromPromise(signUp(payload)));
};

export const getAppConfig = async () => {
  const response = await unauthorizedFetch("/auth/app-config");
  const data = await response.json();

  if (!response.ok) {
    throw data;
  }

  return data;
};
