import unauthorizedFetch from "api/utils/unauthorized_fetch";
import { useReducer } from "react";

const handleRequestError = async response => {
  const responseBody = await response.json();
  if (
    responseBody instanceof Object &&
    responseBody?.message?.messageKey &&
    responseBody?.message?.message
  ) {
    return `${responseBody.message.messageKey}: ${responseBody.message.message}`;
  }
  if (response.status === 400) {
    return `${response.status}: Bad request`;
  }
  if (response.status === 500) {
    return `${response.status}: Internal server error`;
  }
  return `${response.status}: Error`;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return {
        loading: true,
        success: false,
        data: undefined,
        error: undefined
      };
    case "error":
      return {
        loading: false,
        success: false,
        data: undefined,
        error: action.payload
      };
    case "success":
      return {
        loading: false,
        success: true,
        data: action.payload,
        error: undefined
      };
    default:
      return state;
  }
};

const useLazyUnauthorizedFetch = (url, method) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    success: false,
    data: undefined,
    error: undefined
  });

  const doFetch = async body => {
    dispatch({ type: "loading" });
    try {
      const response = await unauthorizedFetch(url, {
        method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch({ type: "success", payload: responseData });
      } else {
        const errorMessage = await handleRequestError(response);
        dispatch({ type: "error", payload: errorMessage });
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: "error",
          payload: `Application error: ${error.message}`
        });
      } else {
        dispatch({
          type: "error",
          payload: `Application error: An unexpected error occurred`
        });
      }
    }
  };

  return [doFetch, state];
};

export default useLazyUnauthorizedFetch;
