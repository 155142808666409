import React from "react";
import WidgetContent from "../widget_content";

const getWidgetBase = props => {
  return {
    decoratedProps: props,
    content: <WidgetContent {...props} />
  };
};

export const getWidgetComponent = props =>
  getWidgetBase({ ...props, isComponent: true });

export default getWidgetBase;
