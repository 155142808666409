import React from "react";
import { getWidgetComponent } from "../get_widget_base";
import FilesWidget from "./files_widget";

export const getWidget = props =>
  getWidgetComponent({
    ...props,
    noResize: false,
    footer: <div />,
    widgetComp: FilesWidget
  });
