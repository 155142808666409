import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { compose, pure } from "react-recompose";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import * as colors from "themes/colors";
import { Logo } from "../page";
import NotificationBadge from "./notification_badge";
import ProfileNav from "./profilenav";
import FormIconButton from "components/buttons/form_icon";
import { withStyles } from "@material-ui/core/styles";

const getButtonTextColor = isActive => (isActive ? "primary" : "default");

const NavBar = withStyles(theme => ({
  appBar: {
    backgroundColor: colors.white,
    boxShadow: "0 1px 6px rgba(0,0,0,.1)",
    height: 80,
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    justifyContent: "space-between",
    height: "100%",
    alignItems: "center"
  },
  toolbarItem: {
    display: "flex",
    alignItems: "center"
  },
  linkContainer: {
    textDecoration: "none"
  },
  logo: {
    height: 40,
    verticalAlign: "middle"
  }
}))(props => {
  const {
    name,
    image,
    isActive,
    homeUrl,
    previousHref,
    onHandleNavToSettings,
    showThings,
    classes
  } = props;

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarItem}>
          <Logo href={homeUrl} className={classes.logo} />
        </div>

        <div className={classes.toolbarItem}>
          <NotificationBadge />
          {showThings && (
            <Link to={previousHref} className={classes.linkContainer}>
              <FormIconButton
                data-test="nav_things"
                text="Things"
                color={getButtonTextColor(isActive("things"))}
              />
            </Link>
          )}
          {showThings && (
            <Link to="/analyze" className={classes.linkContainer}>
              <FormIconButton
                data-test="nav_analyze"
                text="Analyze"
                color={getButtonTextColor(isActive("analyze"))}
                onClick={onHandleNavToSettings}
              />
            </Link>
          )}
          <Link to="/settings" className={classes.linkContainer}>
            <FormIconButton
              data-test="nav_settings"
              text="Settings"
              color={getButtonTextColor(isActive("settings"))}
              onClick={onHandleNavToSettings}
            />
          </Link>
          <ProfileNav name={name} image={image} />
        </div>
      </Toolbar>
    </AppBar>
  );
});

NavBar.propTypes = {
  isActive: PropTypes.func.isRequired,
  onHandleNavToSettings: PropTypes.func,
  name: PropTypes.string.isRequired,
  previousHref: PropTypes.string,
  homeUrl: PropTypes.string,
  image: PropTypes.string.isRequired,
  showThings: PropTypes.bool
};

export default compose(pure)(NavBar);
