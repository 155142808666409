import { useTheme } from "@material-ui/core/styles";
import { Card } from "components/cards";
import { Label } from "components/label";
import { ErrorMsg } from "components/messages";
import { Logo } from "components/page";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import * as colors from "themes/colors";
import ConsentDialogModal from "../../../components/consent_dialog/consent_dialog_modal";
import LoginForm from "../../../forms_new/login";

const LoginComponent = ({
  isLoading,
  error,
  handleLogin,
  handleConsent,
  handleCancelConsent,
  loginTexts,
  smsVerification,
  userConsentNeeded,
  styles
}) => {
  const theme = useTheme();
  return (
    <div style={styles.loginCardContainer}>
      <Card isLoading={isLoading} noHeader data-test="login-card">
        <div>
          <Logo style={{ maxWidth: "270px" }} href="/" />
          <Label center type="h4">
            {loginTexts.welcome}
          </Label>
          <LoginForm onSubmit={handleLogin} isLoading={isLoading} />
          <div
            style={{
              textAlign: "right",
              marginTop: "10px",
              color: colors.telenorTextColor
            }}
          >
            <Link style={styles.link()} to="/recoverPassword">
              Trouble signing in?
            </Link>
            {smsVerification && (
              <Link style={styles.link({ display: "block" })} to="/verifySms">
                Verify phone number
              </Link>
            )}
            {smsVerification && (
              <Link style={styles.link({ display: "block" })} to="/setPassword">
                Set password
              </Link>
            )}
          </div>
          <ErrorMsg error={error} data-test={"login-container-error-message"} />
          <ConsentDialogModal
            consentLabel={"Proceed"}
            open={userConsentNeeded}
            handleConsent={handleConsent}
            handleCancel={handleCancelConsent}
          />
        </div>
      </Card>
      {(loginTexts.title ||
        loginTexts.text ||
        loginTexts.mail ||
        loginTexts.tel) && (
        <div style={{ textAlign: "center" }}>
          <Label center type="h5">
            {loginTexts.title}
          </Label>
          <div style={{ margin: "10px auto" }}>{loginTexts.text}</div>
          <div style={{ margin: "10px auto" }}>
            <a
              style={{
                color: theme.palette.secondary.main,
                fontSize: "0.8em",
                marginBottom: "5px"
              }}
              href={`mailto:${loginTexts.mail}`}
              rel="nofollow"
            >
              {loginTexts.mail}
            </a>
            <br />
            <a
              style={{ color: theme.palette.secondary.main, fontSize: "0.8em" }}
              href={`tel:${loginTexts.tel}`}
              rel="nofollow"
            >
              {loginTexts.tel}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

LoginComponent.defaultProps = { isLoading: false };

LoginComponent.propTypes = {
  loginTexts: PropTypes.object,
  isLoading: PropTypes.bool,
  handleLogin: PropTypes.func,
  handleConsent: PropTypes.func,
  handleCancelConsent: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  styles: PropTypes.object,
  smsVerification: PropTypes.bool,
  userConsentNeeded: PropTypes.bool
};

export default LoginComponent;
