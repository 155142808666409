import * as R from "ramda";
import { manifest as manifestObject } from "../aws/manifest";

const withApiGatewayRootUrl = (manifest, url) => {
  const rootUrl = R.propOr("", "ApiGatewayRootUrl")(manifest);
  const stackName = R.propOr("prod", "StackName")(manifest);
  return `${rootUrl}/${stackName}${url}`;
};

const withApiKeyInHeader = (storage, options) => {
  const apiKey = storage.getItem("apiKey");
  return R.assocPath(["headers", "X-Api-Key"], apiKey)(options);
};

const unauthorizedFetch = ({ storage, manifest }) => (url, options) =>
  fetch(
    withApiGatewayRootUrl(manifest, url),
    withApiKeyInHeader(storage, options || {})
  );

export default unauthorizedFetch({
  storage: localStorage,
  manifest: manifestObject
});
