import { lower } from "utils/general_utils";
import * as imu from "utils/immutable_utils";
import * as R from "ramda";

export const defaultComparator = (a, b) => {
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined) {
    return 1;
  } else if (b === undefined) {
    return -1;
  } else {
    return a > b ? 1 : a < b ? -1 : 0;
  }
};

export const byPathImmutable = path => (a, b) =>
  defaultComparator(lower(a.getIn(path)), lower(b.getIn(path)));

export const byName = (a, b) => defaultComparator(a.get("name"), b.get("name"));

export const byLabel = (a, b) =>
  defaultComparator(a.label.toLowerCase(), b.label.toLowerCase());

export const byIcon = (a, b) =>
  defaultComparator(imu.get(a, "icon", "-"), imu.get(b, "icon", "-"));

export const byLabelImmutable = (a, b) =>
  defaultComparator(
    a
      .get("label")
      .trim()
      .toLowerCase(),
    b
      .get("label")
      .trim()
      .toLowerCase()
  );

export const byIconAndLabel = (a, b) => {
  const iconOrder = byIcon(a, b);
  return iconOrder === 0 ? byLabelImmutable(a, b) : iconOrder;
};

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

export const nilFirstComparator = (a, b) => {
  if (isNilOrEmpty(a) && isNilOrEmpty(b)) {
    return 1;
  } else if (a === undefined) {
    return 1;
  } else if (b === undefined) {
    return -1;
  } else {
    return a > b ? 1 : a < b ? -1 : 0;
  }
};

const _getAndLower = prop =>
  R.pipe(R.propOr("", prop), R.when(R.isNil, R.always("")), R.trim, R.toLower);

const _byLabel = (a, b) =>
  nilFirstComparator(_getAndLower("label")(a), _getAndLower("label")(b));

const _isEmptyIcon = R.pipe(_getAndLower("icon"), isNilOrEmpty);
const _isIconTcxn = R.pipe(_getAndLower("icon"), icon => /tcxn/.test(icon));
const _isIconDomain = R.pipe(_getAndLower("icon"), icon => /domain/.test(icon));
const _isIconVr = R.pipe(_getAndLower("icon"), icon => /vr/.test(icon));

export const resourceSort = list => {
  const noIcon = R.pipe(
    R.filter(_isEmptyIcon),
    R.defaultTo([]),
    R.sort(_byLabel)
  )(list);
  const tcxns = R.pipe(
    R.filter(_isIconTcxn),
    R.defaultTo([]),
    R.sort(_byLabel)
  )(list);
  const domains = R.pipe(
    R.filter(_isIconDomain),
    R.defaultTo([]),
    R.sort(_byLabel)
  )(list);
  const vrs = R.pipe(
    R.filter(_isIconVr),
    R.defaultTo([]),
    R.sort(_byLabel)
  )(list);
  const unknownMortalOrchestra = R.pipe(
    R.filter(
      R.allPass([
        R.complement(_isEmptyIcon),
        R.complement(_isIconVr),
        R.complement(_isIconDomain),
        R.complement(_isIconTcxn)
      ])
    ),
    R.defaultTo([]),
    R.sort(_byLabel)
  )(list);
  return [...noIcon, ...tcxns, ...domains, ...vrs, ...unknownMortalOrchestra];
};

export const sortByCaseInsensitive = sortby =>
  R.sortBy(R.compose(R.defaultTo(""), R.toLower, R.prop(sortby)));
