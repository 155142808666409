import SetPassword from "./set_password";
import { clearCredentials, setPassword } from "ducks/auth";
import { connect } from "react-redux";
import {
  always,
  assoc,
  equals,
  head,
  path,
  pick,
  pipe,
  prop,
  propSatisfies,
  split,
  unless,
  has,
  tryCatch
} from "ramda";
import { manifest } from "api/aws/manifest";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState
} from "react-recompose";
import {
  setPasswordErrorSelector,
  setPasswordLoadingSelector,
  setPasswordSuccessSelector,
  userConsentNeeded,
  verifyUserNameSelector
} from "../../ducks/auth/auth_selectors";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { giveConsent, userConsentCancelled } from "../../ducks/auth";

const _decodeBase64 = window.atob;
const _objectFromJSON = JSON.parse;
export const _userNameFromToken = tryCatch(
  pipe(split("-"), head, _decodeBase64, _objectFromJSON, prop("userName")),
  always("")
);

export const _withHandlers = withHandlers({
  handleSetPassword: ({
    setPassword,
    location,
    userName,
    setUser
  }) => formData => {
    const token = path(["query", "token"])(location);

    formData = unless(
      has("userName"),
      assoc("userName", userName || _userNameFromToken(token))
    )(formData);

    const _user = pick(["userName", "password"], formData);

    setUser(_user);
    setPassword({ token, ...formData });
  },
  handleConsent: ({ giveConsent, user }) => () => giveConsent({ user: user }),
  handleConsentCancelled: ({ userConsentCancelled, push }) => () => {
    push({ pathname: "/" });
    userConsentCancelled();
  }
});

export const _userState = withState("user", "setUser", null);

export const _mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setPassword, clearCredentials, giveConsent, userConsentCancelled, push },
    dispatch
  );

const _verifyByPhoneNumber = propSatisfies(
  equals("phone_number"),
  "SignUpVerificationMedium"
);

export const _withProps = _manifest =>
  withProps(() => ({
    verifyByPhoneNumber: _verifyByPhoneNumber(_manifest ? _manifest : manifest)
  }));

export const _lifecycle = lifecycle({
  componentDidMount() {
    this.props.clearCredentials();
  }
});

const _defaultSelectors = {
  setPasswordErrorSelector: setPasswordErrorSelector,
  setPasswordLoadingSelector: setPasswordLoadingSelector,
  setPasswordSuccessSelector: setPasswordSuccessSelector,
  verifyUserNameSelector: verifyUserNameSelector,
  userConsentNeeded: userConsentNeeded
};

export const _makeMapStateToProps = (
  selectors = _defaultSelectors
) => state => ({
  error: selectors.setPasswordErrorSelector(state),
  isLoading: selectors.setPasswordLoadingSelector(state),
  success: selectors.setPasswordSuccessSelector(state),
  userName: selectors.verifyUserNameSelector(state),
  userConsentNeeded: selectors.userConsentNeeded(state)
});

export default compose(
  connect(_makeMapStateToProps(), _mapDispatchToProps),
  _lifecycle,
  _withProps(),
  _userState,
  _withHandlers
)(SetPassword);
