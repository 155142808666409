import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import Drawer from "@material-ui/core/Drawer";
import { withStyles, withTheme } from "@material-ui/core/styles";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import { CompactNavBar, NavBar } from "components/navbar";
import { SideBarNav } from "components/sidebar_nav";
import Snackbar from "components/snackbar";
import { createHash } from "crypto";
import { authSelectors } from "ducks/auth";
import { subscribeToEventsStream } from "ducks/events";
import * as navActions from "ducks/nav";
import * as systemActions from "ducks/system";
import { setPreviousLocation } from "ducks/thing_types";
import { clearClientErrors } from "graphql/cache";
import PropTypes from "prop-types";
import * as R from "ramda";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { compose, pure, withProps } from "react-recompose";
import { createSelector } from "reselect";
import { getTabTitle } from "themes/utils";
import { getFaviconFallback } from "utils/favicon_utils";
import { thingTypePaths } from "utils/path_utils";
import MainArticle from "./main_article";

const StyledDrawer = withStyles(theme => ({
  paperAnchorDockedLeft: {
    width: theme.spacing(32),
    borderRight: "none"
  }
}))(Drawer);

class MainContainer extends Component {
  constructor(...args) {
    super(...args);
    this.handleSidebar = this.handleSidebar.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleNavToSettings = this.handleNavToSettings.bind(this);
  }

  componentDidMount() {
    const { dispatch, domainPath } = this.props;
    dispatch(subscribeToEventsStream(domainPath));
  }

  handleNavToSettings() {
    // Should we move this to the router?
    const {
      dispatch,
      location: { pathname, query }
    } = this.props;
    if (pathname.indexOf("/things") >= 0) {
      dispatch(setPreviousLocation(pathname, query));
    }
  }

  handleDismiss() {
    const { dispatch } = this.props;
    clearClientErrors();
    dispatch(systemActions.clearMessages());
  }

  handleSidebar() {
    const { dispatch } = this.props;
    dispatch(navActions.toggleSidebar());
  }

  render() {
    const { router } = this.context;
    const {
      children,
      dispatch,
      content,
      sidebar,
      message,
      errorMessage,
      visibleDuration,
      warningMessage,
      nav,
      name,
      image,
      previousHref,
      location,
      graphQlError,
      tabTitle,
      theme,
      favicon
    } = this.props;
    const isNotCompact = isWidthUp("md", this.props.width);
    const isCompact = isWidthDown("sm", this.props.width);

    const contentStyle = Object.assign(
      {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
      },
      isNotCompact && sidebar
        ? {
            marginLeft: theme.spacing(32)
          }
        : {}
    );

    const msg = message || warningMessage || errorMessage || graphQlError;

    // Temp. solution until we figure out the appbar pattern for smaller screens
    const pageTitle = location => {
      let title =
        location && location.pathname
          ? location.pathname.split("/")[1]
          : "Things";
      return title[0].toUpperCase() + title.substring(1).toLowerCase();
    };
    return (
      <section
        style={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          paddingTop: theme.spacing(10)
        }}
      >
        <Helmet
          title="Welcome"
          titleTemplate={`%s - ${tabTitle}`}
          link={[{ rel: "shortcut icon", type: "image/png", href: favicon }]}
        />
        {isCompact ? (
          <CompactNavBar
            pageTitle={pageTitle(location)}
            onToggleSidebar={this.handleSidebar}
          />
        ) : (
          <NavBar
            previousHref={previousHref}
            onHandleNavToSettings={this.handleNavToSettings}
            homeUrl={"/"}
            name={name}
            image={image}
            isActive={router.isActive}
            location={location}
            showThings={this.props.userHasAppBoardThingPermission}
          />
        )}
        {sidebar && (
          <StyledDrawer
            open={nav || isNotCompact}
            variant={isCompact ? "temporary" : "permanent"}
          >
            <SideBarNav
              previousHref={previousHref}
              onHandleNavToSettings={this.handleNavToSettings}
              menu={sidebar}
              dispatch={dispatch}
              isActive={router.isActive}
              isCompact={isCompact}
            />
          </StyledDrawer>
        )}
        <main className="mainContainer">
          <MainArticle style={contentStyle}>
            {content}
            {children}
          </MainArticle>
        </main>
        <Snackbar
          data-test="app-snack-bar"
          open={!R.isEmpty(msg)}
          message={msg}
          action="close"
          autoHideDuration={visibleDuration}
          onClose={this.handleDismiss}
        />
      </section>
    );
  }
}

MainContainer.contextTypes = {
  router: PropTypes.object,
  muiTheme: PropTypes.object
};

MainContainer.propTypes = {
  width: PropTypes.string,
  router: PropTypes.object,
  sidebar: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.object,
  content: PropTypes.object,
  dispatch: PropTypes.func,
  domainPath: PropTypes.string,
  message: PropTypes.string,
  previousHref: PropTypes.string,
  systemWarningMessage: PropTypes.string,
  systemErrorMessage: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  visibleDuration: PropTypes.number,
  nav: PropTypes.bool,
  errorMessage: PropTypes.string,
  graphQlError: PropTypes.string,
  warningMessage: PropTypes.string,
  tabTitle: PropTypes.string,
  favicon: PropTypes.string,
  theme: PropTypes.object,
  userHasAppBoardThingPermission: PropTypes.bool
};

const imageSelector = createSelector(
  authSelectors.userSelector,
  user =>
    `//www.gravatar.com/avatar/${createHash("md5")
      .update(user.email || "")
      .digest("hex")}`
);

const getFaviconUrl = theme => {
  const src = theme.getIn(["favicon", "src"]);
  const timestamp = theme.getIn(["favicon", "timestamp"]);
  if (src && timestamp) {
    return `${theme.getIn(["favicon", "src"])}?v=${theme.getIn([
      "favicon",
      "timestamp"
    ])}`;
  }
  return getFaviconFallback();
};

function mapStateToProps(state) {
  const tabTitle = getTabTitle(state.theme);
  const favicon = getFaviconUrl(state.theme);

  const user = authSelectors.userSelector(state);
  const message = R.isNil(state.system.getIn(["notification", "message"]))
    ? ""
    : state.system.getIn(["notification", "message"]);
  const errorMessage = R.isNil(state.system.getIn(["error"]))
    ? ""
    : state.system.getIn(["error"]);
  const warningMessage = R.isNil(state.system.getIn(["warning"]))
    ? ""
    : state.system.getIn(["warning"]);
  const visibleDuration = R.isNil(state.system.get("visibleDuration"))
    ? 5000
    : state.system.get("visibleDuration");
  const { previousHref } = thingTypePaths(state.thingTypes);
  const userHasAppBoardThingPermission = authSelectors.userHasAppBoardThingPermission(
    state
  );

  return {
    domainPath: user.domainPath,
    message,
    errorMessage,
    warningMessage,
    visibleDuration,
    nav: state.nav.getIn(["sidebar"]),
    name: `${user.firstName} ${user.lastName}`,
    image: imageSelector(state),
    previousHref,
    tabTitle,
    favicon,
    userHasAppBoardThingPermission
  };
}

const getErrors = gql`
  query {
    apiError @client {
      error
    }
  }
`;

const _withGraphQlError = withProps(props => {
  const error = R.pathOr(undefined, ["data", "apiError", "error"])(props);
  return {
    graphQlError: R.isNil(error) ? "" : error
  };
});

export default compose(
  connect(mapStateToProps),
  graphql(getErrors),
  _withGraphQlError,
  withTheme,
  withWidth()
)(pure(MainContainer));
