import * as R from "ramda";
import { createSelector } from "reselect";
import { pagingSelectors } from "ducks/paging";
import { schemaSelectors } from "ducks/schema";
import { isResourceField } from "utils/table_utils";
import { unImmute } from "utils/immutable_utils";
import { makeResourcePathString } from "utils/thing_utils";
import { authSelectors } from "../auth";
import { isAllowed, OBJECT_TYPES, OPERATIONS } from "utils/auth_utils";

const thingTypeIdSelector = (state, props) =>
  R.path(["params", "thingType"])(props);

export const isLoadingSelector = R.path(["thingsList", "request", "isLoading"]);

export const thingsListSelector = R.path(["thingsList", "entities", "things"]);

export const thingsTypeListSelector = R.path([
  "thingsList",
  "entities",
  "thingTypes"
]);

export const thingTypeFilterIdSelector = createSelector(
  thingTypeIdSelector,
  thingTypeId => `thingType-${thingTypeId}`
);

export const thingsListTitleSelector = createSelector(
  pagingSelectors.getWidgetPaginationStatus,
  ({ pageSize, totalCount, start, end }) =>
    totalCount <= pageSize
      ? `${totalCount} ${totalCount === 1 ? "thing" : "things"}`
      : totalCount !== undefined
      ? `Things ${start}-${end} of ${totalCount}`
      : "Things"
);

export const getThing = createSelector(
  thingsListSelector,
  (state, props) => props.thingName,
  (things, thingName) => things[thingName]
);

export const getPagedThings = createSelector(
  pagingSelectors.getWidgetThingIds,
  thingsListSelector,
  (thingIds, things) => {
    return thingIds.reduce(
      (acc, thingId) =>
        things[thingId] ? R.append(things[thingId], acc) : acc,
      []
    );
  }
);

export const getFieldTypes = createSelector(
  schemaSelectors.resourceTypesByName,
  (state, fields) => fields,
  (typesByName, fields) => {
    const noTypes = [];
    const typesForField = R.pipe(
      R.prop("resource"),
      R.ifElse(
        R.isNil,
        R.always(noTypes),
        R.pipe(
          resource => makeResourcePathString(resource),
          name => typesByName[name],
          R.defaultTo(noTypes)
        )
      )
    );

    return R.map(
      R.ifElse(isResourceField, typesForField, R.always(noTypes)),
      unImmute(fields)
    );
  }
);

export const userCanDeleteThings = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Things, OPERATIONS.DELETE)
);

export const userCanCreateThings = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Things, OPERATIONS.CREATE)
);

export const userCanEditThings = createSelector(
  authSelectors.permissionsSelector,
  isAllowed(OBJECT_TYPES.Things, OPERATIONS.UPDATE)
);
