import { graphql } from "@apollo/client/react/hoc";
import ThingStatusBar from "components/thing/thing_status_bar";
import { actions, widgetSelectors } from "ducks/dashboard";
import defaultAllThingsFields from "ducks/dashboard/all_things_default_fields";
import defaultThingsFields from "ducks/dashboard/thing_list_default_fields";
import { filterSelectors } from "ducks/filter";
import * as modal from "ducks/modal";
import * as paging from "ducks/paging";
import { initPaging, pagingSelectors } from "ducks/paging";
import { thingCreateReset } from "ducks/thing/thing";
import { hasThingTypeNewlyCreatedThings } from "ducks/thing/thing_selectors";
import {
  downloadCertificate,
  replaceCertificate
} from "ducks/things_list/things_list";
import {
  getFieldTypes,
  thingsListTitleSelector,
  userCanCreateThings,
  userCanDeleteThings,
  userCanEditThings
} from "ducks/things_list/things_list_selectors";
import {
  ADD_GRID_MUTATION,
  ALL_THINGS,
  REMOVE_THING,
  UPDATE_WIDGET_MUTATION
} from "graphql/queries";
import { getTimezone } from "graphql/utils/general";
import {
  allThingsQueryPropsHandler,
  createSearchOptions,
  withThings
} from "graphql/utils/thing";
import { withModalHandlersAndBranch } from "hocs/modal_hocs";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import {
  compose,
  lifecycle,
  pure,
  withHandlers,
  withProps,
  withPropsOnChange,
  withStateHandlers
} from "react-recompose";
import { bindActionCreators } from "redux";
import EditThingModal from "routes/main/things/modals/edit_thing";
import { getViewModeGrid, widgetId, widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import { thingDetailsUrl } from "utils/route_utils";
import {
  fieldOnClickHandler,
  fieldSortString,
  getNewSortingString
} from "utils/table_utils";
import { getConnectionStatus, getResourceValue } from "utils/thing_utils";
import AllThingsTable from "./all_things_table";

const _defaultSelectors = {
  ...filterSelectors,
  ...pagingSelectors,
  ...widgetSelectors
};

const _linksTos = router => ({
  label: prop => {
    const thingName = imu.get(prop, "thingName");
    const thingType = imu.get(prop, "thingType");
    return {
      pathname: thingDetailsUrl(router, { thingType, thingName })
    };
  }
});

const defaultColumnConfig = props =>
  props.params.thingType ? defaultThingsFields : defaultAllThingsFields;

export const _makeMapStateToProps = (selectors = _defaultSelectors) => (
  state,
  props
) => {
  const fields = selectors.getThingsColumnConfig(
    state,
    widgetProp(props.widget, "columnConfig", defaultColumnConfig(props))
  );
  const allFieldTypes = getFieldTypes(state, fields);
  const wId = widgetId(props.widget);
  return {
    fields,
    allFieldTypes,
    filter: selectors.filterByFilterIdProp(state, props),
    paginationStatus: selectors.getWidgetPaginationStatus(state, {
      widgetId: wId
    }),
    hasNewlyCreatedThings: hasThingTypeNewlyCreatedThings(
      state.thing,
      props.params.thingType
    ),
    userCanEditThings: userCanEditThings(state),
    userCanCreateThings: userCanCreateThings(state),
    userCanDeleteThings: userCanDeleteThings(state),
    widgetId: wId,
    widgetLabel: thingsListTitleSelector(state, {
      widgetId: wId
    })
  };
};

const _mapDispatchToProps = dispatch => {
  return {
    setPaging: payload => {
      dispatch(paging.setPagingData(payload));
    },
    initPaging: payload => dispatch(initPaging(payload)),
    ...bindActionCreators(
      {
        ...actions,
        ...modal,
        downloadCertificate,
        replaceCertificate,
        push,
        thingCreateReset
      },
      dispatch
    )
  };
};

const _lifecycle = lifecycle({
  componentDidMount() {
    const { initPaging, widgetId, widget } = this.props;
    initPaging({
      widgetId,
      pagingData: {
        pageSize: widgetProp(widget, "rowsPerPage", 10),
        sort: "label.lowercase"
      }
    });
  },
  componentDidUpdate() {
    /* Comment for future coder, take a sip of coffee, put phone in Airplac mode and prepare for some good refactoring cravings:

      This finds out about newly created things, it also resets them.
      This exact procedure is also made in .../tabs/list/things_list_container.js in the function componentDidUpdate()...
      Since this file has the exact same GraphQL query as that file, this operation will not collide with the one in that file.
      But it feels kind of bad and it would be nice for this kind of behaviour to listen to MQTT instead of making roundtrips via Redux
    */
    const {
      hasNewlyCreatedThings,
      params,
      data,
      thingCreateReset
    } = this.props;
    if (hasNewlyCreatedThings && !data.loading) {
      thingCreateReset(params.thingType);
      setTimeout(() => {
        data.refetch();
      }, 1000);
    }
  }
});

const _transforms = {
  // casing is "status" in "thingType" column configs
  status: prop => (
    <ThingStatusBar thing={prop} connectionStatus={getConnectionStatus(prop)} />
  ),
  // casing is "Status" in "allThings" column configs
  Status: prop => (
    <ThingStatusBar thing={prop} connectionStatus={getConnectionStatus(prop)} />
  ),
  resource: (thing, itemIndex, field) =>
    getResourceValue(thing, field.resource),
  domain: thing => imu.getIn(thing, ["domainConnection", "name"], ""),
  thingType: thing => imu.getIn(thing, ["thingTypeConnection", "label"], "")
};

const _applyFieldMethods = ({
  router,
  paginationStatus,
  setPaging,
  widgetId
}) => {
  const linksTo = _linksTos(router);

  return (field, fieldTypes) => {
    const sortString = fieldSortString(field, fieldTypes);
    const clickHandler = ({ sortFieldType }) => {
      setPaging({
        pagingData: {
          sort: getNewSortingString(paginationStatus.sort, sortString),
          sortFieldType
        },
        widgetId
      });
    };
    return {
      ...field,
      transform: _transforms[field.selector],
      linksTo: linksTo[field.selector],
      onClick: fieldOnClickHandler(clickHandler, {
        paginationStatus,
        sortString,
        sortFieldTypes: fieldTypes
      }),
      sortString
    };
  };
};

const _decoratedFields = ({ fields, allFieldTypes, ...otherProps }) => {
  return fields
    ? fields
        .filter(field => field.visible)
        .map((field, i) =>
          _applyFieldMethods(otherProps)(field, allFieldTypes[i])
        )
    : fields;
};

const _withFields = withProps(
  ({
    filter,
    domainList,
    fields,
    editModeFields,
    dispatch,
    router,
    paginationStatus,
    setPaging,
    allFieldTypes,
    widgetId
  }) => {
    const fieldsToShow = editModeFields || fields;
    const decoratedFields = _decoratedFields({
      allFieldTypes,
      fields: fieldsToShow,
      domainList,
      router,
      filter,
      paginationStatus,
      dispatch,
      setPaging,
      widgetId
    });
    return {
      fields: fieldsToShow,
      decoratedFields
    };
  }
);

const _withSearchOptions = withPropsOnChange(
  ["paginationStatus", "filter", "params"],
  props => {
    const searchOptions = createSearchOptions({
      filter: props.filter,
      routeParams: props.params,
      paging: props.paginationStatus,
      paginationStatus: props.paginationStatus
    });

    return { searchOptions };
  }
);

const withThingActionHandlers = withHandlers({
  handleDeleteAction: ({ open, data, deleteThingMutation }) => thing => {
    open({
      title: "Confirm delete thing",
      message: "Are you sure you want to delete this thing?",
      onConfirm: () => {
        deleteThingMutation({
          variables: { thingName: imu.get(thing, "thingName") },
          update: () => {
            data.refetch();
          }
        });
      }
    });
  },

  handleEditAction: ({ openModalEditThing }) => thing => {
    openModalEditThing({ thingName: imu.get(thing, "thingName") });
  },

  handleReplaceCertificateAction: ({ open, replaceCertificate }) => thing => {
    open({
      title: "Confirm replace certificate",
      message: "Are you sure you want to refresh this things certificate?",
      onConfirm: () =>
        replaceCertificate({ thingName: imu.get(thing, "thingName") })
    });
  },

  handleDownloadCertificateAction: ({ downloadCertificate }) => thing => {
    downloadCertificate({ thingName: imu.get(thing, "thingName") });
  }
});

const withColumnPickerState = withStateHandlers(
  { editModeFields: null },
  {
    clearEditModeFields: () => () => ({ editModeFields: null }),
    onColumnPickerChange: () => ({ items }) => {
      return {
        editModeFields: items.map(item => {
          let field = {
            ...item,
            visible: item.checked,
            label: item.text
          };

          if (item.hasOwnProperty("checked")) {
            field.visible = item.checked;
          }
          return field;
        })
      };
    },

    setColumnPickerDefault: (state, props) => () => ({
      editModeFields: defaultColumnConfig(props)
    })
  }
);

const addOrUpdateWidgetProps = ({
  addGridMutation,
  dashboardViewMode,
  gridId,
  gridType,
  updateWidgetMutation,
  viewModeId,
  widget,
  newWidgetProps
}) => {
  const existingGrid = getViewModeGrid({ gridId, gridType }, dashboardViewMode);

  if (R.isEmpty(existingGrid) && gridType === "thingList") {
    // special case for the "list" tab, the grid might not exist yet
    return addGridMutation({
      variables: {
        viewModeId,
        gridId,
        layout: {},
        type: gridType,
        widgets: [
          {
            widgetId: widgetId(widget),
            label: null,
            type: "thingList",
            props: newWidgetProps
          }
        ]
      }
    });
  }

  return updateWidgetMutation({
    variables: {
      viewModeId,
      gridId,
      widgetId: widgetId(widget),
      widgetProps: { ...widget.props, ...newWidgetProps }
    }
  });
};

const withColumnPickerHandlers = withHandlers({
  onColumnPickerSave: ({
    addGridMutation,
    clearEditModeFields,
    dashboardViewMode,
    editModeFields,
    gridId,
    gridType,
    updateWidgetMutation,
    viewModeId,
    widget
  }) => () => {
    if (editModeFields) {
      return addOrUpdateWidgetProps({
        addGridMutation,
        dashboardViewMode,
        gridId,
        gridType,
        updateWidgetMutation,
        viewModeId,
        widget,
        newWidgetProps: { columnConfig: editModeFields }
      }).then(() => {
        clearEditModeFields();
      });
    }
    return Promise.resolve(true);
  }
});

const withColumnPickerProps = withProps(
  ({
    fields,
    editModeFields,
    onColumnPickerChange,
    onColumnPickerSave,
    setColumnPickerDefault,
    widgetId
  }) => ({
    columnPickerProps: {
      tableName: widgetId,
      fields: editModeFields || fields,
      show: true,
      onColumnPickerChange,
      setColumnPickerDefault,
      onColumnPickerSave
    }
  })
);

const withPagingHandlers = withHandlers({
  handleOnPage: ({
    gridId,
    addGridMutation,
    dashboardViewMode,
    gridType,
    setPaging,
    updateWidgetMutation,
    viewModeId,
    widget
  }) => ({ page, widgetId, pageSize }) => {
    if (pageSize) {
      addOrUpdateWidgetProps({
        addGridMutation,
        dashboardViewMode,
        gridId,
        gridType,
        updateWidgetMutation,
        viewModeId,
        widget,
        newWidgetProps: { rowsPerPage: pageSize }
      });
      setPaging({ widgetId, pagingData: { pageSize, page: 1 } });
    } else {
      setPaging({ widgetId, pagingData: { page } });
    }
  }
});

export default compose(
  withRouter,
  connect(_makeMapStateToProps(), _mapDispatchToProps),
  graphql(ADD_GRID_MUTATION, { name: "addGridMutation" }),
  graphql(UPDATE_WIDGET_MUTATION, { name: "updateWidgetMutation" }),
  graphql(REMOVE_THING, {
    name: "deleteThingMutation",
    options: { refetchQueries: ["allThings"] }
  }),
  _withSearchOptions,
  graphql(ALL_THINGS, {
    skip: props => !props.paginationStatus.pageSize,
    options: props => ({
      variables: {
        searchOptions: props.searchOptions,
        timezone: getTimezone()
      },
      fetchPolicy: "cache-and-network"
    }),
    props: allThingsQueryPropsHandler
  }),
  withModalHandlersAndBranch({
    name: "EditThing",
    modal: EditThingModal,
    handlers: {
      refetchOnSave: ({ data }) => () => {
        data.refetch();
      }
    }
  }),
  withColumnPickerState,
  withColumnPickerHandlers,
  withColumnPickerProps,
  withPagingHandlers,
  _withFields,
  withThings,
  withThingActionHandlers,
  _lifecycle,
  pure
)(AllThingsTable);
