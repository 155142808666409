import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { baseTheme } from "themes";
import { widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import { resourceStatePath } from "utils/widget_utils";
import TimeSeries from "../../../chart/line_graph/TimeSeries";
import { filterNullnNaN } from "../../../../../src/utils/general_utils";

const defaultColor = baseTheme.palette.primary1Color;

const round = function round(num, decimals) {
  const n = Math.pow(10, decimals);
  return Math.round((n * num).toFixed(decimals)) / n;
};

const getData = observations => {
  const data = R.pipe(
    R.defaultTo([]),
    R.filter(observation => imu.get(observation, "value") != null),
    R.map(observation => {
      const value = round(imu.get(observation, "value"), 2);
      const timestamp = new Date(imu.get(observation, "timestamp"));

      return {
        x: timestamp,
        y: value,
        resourcePath: imu.get(observation, "resourcePath")
      };
    }),
    R.groupBy(obj => obj.resourcePath),
    group => (group?.toJSON ? group?.toJSON() : group)
  )(observations);

  return data;
};

const TimeSeriesWidget = ({
  unit,
  observations,
  container,
  widget,
  subthing
}) => {
  const resourcesFromWidget = widgetProp(widget, "resources");
  const yAxisTypeFromWidget = widgetProp(widget, "yAxisType");
  const groupedData = getData(observations);
  const data = [];
  const labels = [];
  const colors = [];
  Object.entries(groupedData).forEach(([key, value]) => {
    data.push(value);
    labels.push(key);
    colors.push(
      resourcesFromWidget?.find(
        element => resourceStatePath(subthing, element.resourceId) === key
      )?.color || defaultColor
    );
  });

  const filteredData = filterNullnNaN(data);

  return (
    <TimeSeries
      data={filteredData}
      width={container.width}
      height={container.height}
      colors={colors}
      labels={labels}
      unit={unit}
      removeYAxis={yAxisTypeFromWidget === 1}
    />
  );
};

TimeSeriesWidget.propTypes = {
  resource: PropTypes.object,
  layout: PropTypes.object,
  observations: PropTypes.object,
  container: PropTypes.object,
  resourceLabel: PropTypes.string,
  unit: PropTypes.string,
  widget: PropTypes.object,
  subthing: PropTypes.string
};

TimeSeriesWidget.defaultProps = {};

export default TimeSeriesWidget;
