import { createGlobalStyle } from "styled-components";
import base from "./base";
import tables from "./tables";
import typography from "./typography";
import extensionsMaterialUI from "./extensions_material_ui";
import extensionsReactGridLayout from "./extensions_react_grid_layout";

export default createGlobalStyle`
  ${base};
  ${tables};
  ${typography};
  ${extensionsMaterialUI};
  ${extensionsReactGridLayout};
`;
