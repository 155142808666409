export default function downloadCSV(csv) {
  const link = document.createElement("a");
  link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv.body)}`;
  link.download = csv.title;
  link.style.display = "none";
  link.target = "_blank";
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 2000);
}
