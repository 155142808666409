import React from "react";
import PropTypes from "prop-types";
import Loadable from "react-loadable";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormCancelButton from "components/buttons/form_cancel";
import FormSaveButton from "components/buttons/form_save";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { Label } from "components/label";
import styled from "styled-components";
import LoadingWrapper from "components/loading/loading_wrapper";

const CircularProgressContainer = styled.div`
  height: 300px;
  margin: auto;
  width: 150px;
  transform: translate(0, 25%);s
`;
const LoadablePDFViewer = Loadable({
  loader: () => import(/* webpackChunkName: "pdf-viewer"*/ "./pdf_viewer"),
  loading: LoadingWrapper
});

const ConsentDialog = ({
  documentUrl,
  consentLabel,
  handleConsent,
  handleCancel,
  ticked = false,
  onCheck,
  isLoading,
  isSubmitting
}) => (
  <div>
    <DialogContent>
      {isLoading || isSubmitting ? (
        <CircularProgressContainer>
          <CircularProgress size={150} />
          <Label type="h4">
            {isSubmitting ? "Consenting..." : "Loading Document..."}
          </Label>
        </CircularProgressContainer>
      ) : (
        <LoadablePDFViewer file={documentUrl} />
      )}
      <div />
    </DialogContent>
    <DialogActions>
      <FormControlLabel
        control={<Checkbox onChange={onCheck} disabled={isLoading} />}
        label={
          <span>
            {" "}
            I hereby agree to the{" "}
            <a
              rel="noopener noreferrer"
              href={documentUrl}
              target="_blank"
              data-test={"consent-dialog-document-link"}
            >
              above.
            </a>
          </span>
        }
      />
      <FormCancelButton
        onClick={handleCancel}
        data-test={"consent-dialog-cancel-button"}
      />
      <FormSaveButton
        onClick={handleConsent}
        text={consentLabel}
        disabled={!ticked || isLoading}
        data-test={"consent-dialog-ok-button"}
      />
    </DialogActions>
  </div>
);
ConsentDialog.propTypes = {
  documentUrl: PropTypes.string,
  consentLabel: PropTypes.string,
  ticked: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleConsent: PropTypes.func,
  handleCancel: PropTypes.func,
  onCheck: PropTypes.func,
  numPages: PropTypes.func,
  setNumPages: PropTypes.func
};

export default ConsentDialog;
