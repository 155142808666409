import bodybuilder from "bodybuilder";
import * as R from "ramda";
import * as eu from "./elastic_utils";
import { sortingFromString } from "utils/table_utils";

// ---- general utils
const _propSortHash = {
  label: "label.lowercase"
};
const _propSortTranslator = prop => _propSortHash[prop] || prop;

const _parseSorting = (sorting, sortFieldType) => {
  if (!sorting) {
    return {};
  }
  // Convert query from 'foo,-bar' to {foo: 'asc', bar: 'desc'}
  const fields = sorting.split(",");

  return fields.reduce((prev, field) => {
    const { order, column: statePath } = sortingFromString(field);
    return {
      ...prev,
      [eu.stripThingType(statePath)]: {
        order,
        missing: "_last",
        ...(sortFieldType ? { unmapped_type: sortFieldType } : {})
      }
    };
  }, {});
};

// ---- api
const _findIds = ({ thingType, resources = ["_id"], filter }) =>
  R.pipe(
    eu.size(10000),
    eu.fetch({
      fieldId: "thingType",
      fieldValue: thingType,
      resources,
      withState: false
    }),
    _filter(filter)
  );

export const findIds = (props, version = 2) =>
  eu.adaptForVersion(_findIds(props)(bodybuilder()).build(), version);

const _pageNumber = ({ page = 0, size = 0 }) =>
  page <= 0 ? 0 : Number((page - 1) * size);

export const _hasThingStatus = status =>
  R.not(R.isNil(status)) &&
  R.not(R.equals("-1", status)) &&
  R.not(R.equals("3", status)) &&
  R.not(R.equals("4", status));

export const _hasThingStatusTerm = status =>
  R.ifElse(
    _hasThingStatus,
    () =>
      eu.termFilter({
        fieldId: "state.tcxn.connection_status",
        fieldValue: parseInt(status, 10)
      }),
    eu.none
  )(status);

const _isStatusNotConnected = R.ifElse(
  R.equals("3"),
  () => eu.mustNotExistsFilter("state"),
  eu.none
);

const _isStatusUnknown = R.ifElse(
  status => R.equals("4", status),
  () =>
    R.pipe(
      eu.mustNotExistsFilter("state.tcxn.connection_status"),
      eu.existFilter("state")
    ),
  eu.none
);

const _statusFilter = status =>
  R.pipe(
    _hasThingStatusTerm(status),
    _isStatusNotConnected(status),
    _isStatusUnknown(status)
  );

const _resourcesFilter = (resources = []) => bodybuilder =>
  resources.reduce(
    (bb, resource) =>
      R.ifElse(
        R.propEq("operator", "eq"),
        () =>
          eu.termFilter({
            fieldId: eu.createStateString(resource.name),
            fieldValue: eu._toNumber(resource.value)
          })(bb),
        () =>
          eu.rangeFilter({
            rangeId: eu.createStateString(resource.name),
            rangeValue: { [resource.operator]: eu._toNumber(resource.value) }
          })(bb)
      )(resource),
    bodybuilder
  );

const _geoFilter = geo => eu.geoFilter(geo);

const _rangeFilter = (period = {}, resourceName) =>
  eu.rangeFilter({
    rangeId: `metadata.${resourceName}`,
    ...eu.periodTimestamps(period)
  });

const _fromRaw = query => eu._rawOption("from", _pageNumber(query));

const _baseQuery = (query = { size: 10, page: 0 }) =>
  query
    ? R.pipe(
        eu.size(query.size),
        _fromRaw(query),
        eu._rawOption(
          "sort",
          _parseSorting(_propSortTranslator(query.sort), query.sortFieldType)
        )
      )
    : eu.none();

const _filter = (filter = {}) =>
  R.pipe(
    !eu.isNilOrEmpty(filter.thingTypes)
      ? eu._filter({
          option: "bool",
          nest: eu.generalOrTerm("thingType", filter.thingTypes)
        })
      : eu.none(),
    !eu.isNilOrEmpty(filter.domains)
      ? eu._filter({
          option: "bool",
          nest: eu.generalOrTerm("domain", filter.domains)
        })
      : eu.none(),
    eu.generalWildcard("label.lowercase", filter.freeText),
    _statusFilter(filter.thingStatus),
    _resourcesFilter(filter.resources),
    _geoFilter(filter.geo)
  );

const _aggsForGeo = precision =>
  eu.agg({
    type: "geohash_grid",
    name: "geoBuckets",
    field: "state.latlng",
    options: { precision },
    aggs: bb =>
      eu.agg({
        type: "top_hits",
        name: "results",
        options: {
          _source: {
            include: ["thingName", "thingType", "state.latlng"]
          },
          size: 1000
        }
      })(bb)
  });

const _findThings = ({ thingType, thingName, filter, query }) =>
  R.pipe(
    _baseQuery(query),
    eu.generalTerm("thingType", thingType),
    eu.generalTerm("thingName", thingName),
    eu.thingTypeTermFromSorting(query && query.sort),
    eu.thingTypeTermFromResources(filter && filter.resources),
    _filter(filter)
  );

export const findThings = (props = {}, version) =>
  eu.adaptForVersion(_findThings(props)(bodybuilder()).build(), version);

const _findSubThingsRelation = ({ parentThingName }) => bodybuilder =>
  bodybuilder
    .orFilter("term", "parentThingName", parentThingName)
    .orFilter("term", "thingName", parentThingName)
    .rawOption("_source", [
      "label",
      "thingName",
      "thingType",
      "hasNetworkedThings"
    ]);

export const findSubThingsRelation = (props = {}, version) =>
  eu.adaptForVersion(
    _findSubThingsRelation(props)(bodybuilder()).build(),
    version
  );

const _findAggregations = ({
  period,
  thingType,
  aggregation,
  resourceName,
  filter,
  aggregationParams
}) =>
  R.pipe(
    R.unless(R.always(R.isNil(period)), _rangeFilter(period, resourceName)),
    eu.generalTerm("thingType", thingType),
    _filter(filter),
    eu.aggsByType(aggregation, aggregationParams)
  );

export const findAggregations = (props = {}, version) =>
  eu.adaptForVersion(_findAggregations(props)(bodybuilder()).build(), version);

const _findAggregationsForGeo = ({ thingType, precision, filter }) =>
  R.pipe(
    eu.size(0),
    eu.generalTerm("thingType", thingType),
    _filter(filter),
    _aggsForGeo(precision)
  );

export const findAggregationsForGeo = (props = {}, version) =>
  eu.adaptForVersion(
    _findAggregationsForGeo(props)(bodybuilder()).build(),
    version
  );

const _findThingTypeIdsByThingNames = ({ thingNames }) =>
  R.pipe(
    eu.size(10000),
    eu.termsOrFilter("thingName", thingNames),
    eu.sourceFilter(["thingName", "thingType"], false)
  );

export const findThingTypeIdsByThingNames = (props = {}, version) =>
  eu.adaptForVersion(
    _findThingTypeIdsByThingNames(props)(bodybuilder()).build(),
    version
  );
