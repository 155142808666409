import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import device from "./api/device";
import { getDomainPath } from "ducks/thing/thing_selectors";
import App from "./containers/index";
import { client } from "utils/mqtt_utils";
import DeviceProvider from "./providers/device";
import { getThingDetails } from "ducks/thing";
import { compose, lifecycle } from "react-recompose";
import { SpinnerWhileLoading } from "components/notification";
import { getResourceValue } from "ducks/thing/thing_selectors";
import { authSelectors } from "ducks/auth";

// Simulator timeout period in ms
const timeout = 1000 * 60;

class SimulatorContainer extends Component {
  state = {
    err: null,
    mqttClient: null,
    isLoading: true
  };

  static propTypes = {
    env: PropTypes.string,
    params: PropTypes.object
  };

  componentDidMount() {
    const { env, domainPath, thingName } = this.props;
    if (!this.state.client) {
      client(env, domainPath, thingName).then((mqttClient, err) => {
        this.setState({
          err,
          mqttClient,
          isLoading: false
        });
      });
    }
  }

  render() {
    const { err, mqttClient, isLoading } = this.state;
    const { background, fontColor, fontSize } = this.props;
    if (isLoading) {
      return <pre>connecting...</pre>;
    }

    if (err) {
      return (
        <div>
          Something went wrong
          <pre>{err.toString()}</pre>
        </div>
      );
    }
    return (
      <DeviceProvider device={device}>
        <App
          mqttClient={mqttClient}
          timeout={timeout}
          thingName={this.props.params.thingId}
          background={background}
          fontColor={fontColor}
          fontSize={fontSize}
        />
      </DeviceProvider>
    );
  }
}

SimulatorContainer.propTypes = {
  env: PropTypes.string,
  thingName: PropTypes.string,
  domainPath: PropTypes.string,
  background: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string
};

export default compose(
  connect(
    (state, props) => {
      const {
        params: { thingId }
      } = props;
      let domainPath = getDomainPath(state.thing, thingId);
      // background: '',
      // fontColor: '#000',
      // fontSize: '12px'
      const vals = ["background", "fontColor", "fontSize"].reduce(
        (mem, resource) => {
          const latestValue = getResourceValue(state, thingId, resource);
          const value = latestValue
            ? latestValue.getIn(["latestValue", "value"])
            : latestValue;
          if (value) {
            mem = { ...mem, [resource]: value };
          }
          return mem;
        },
        {}
      );
      return {
        env: authSelectors.getEnv(state),
        domainPath,
        thingName: thingId,
        ...vals
      };
    },
    {
      getThingDetails
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        params: { thingId },
        getThingDetails
      } = this.props;
      getThingDetails(thingId);
    }
  })
)(
  SpinnerWhileLoading(props => props.domainPath !== undefined)(
    SimulatorContainer
  )
);
