import { schema, normalize } from "normalizr";

const widgetSchema = new schema.Entity("widgets");
const layoutSchema = new schema.Entity("layouts");

const gridSchema = new schema.Entity("grids", {
  widgets: [widgetSchema],
  layout: layoutSchema
});

const viewModeSchema = new schema.Entity("viewModes", {
  grids: [gridSchema]
});

const dashboardSchema = new schema.Entity("dashboards", {
  viewModes: [viewModeSchema]
});

export const adaptDashboards = dashboardsResponse =>
  normalize(dashboardsResponse, [dashboardSchema]);

export const adaptDashboard = dashboardResponse =>
  normalize([dashboardResponse], [dashboardSchema]);
