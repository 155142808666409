import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  getEditingWidgetResources,
  getResourceValues
} from "ducks/aggregators/resources";
import { upload as uploadFile } from "ducks/bucket";
import { objOf, pathOr, pipe } from "ramda";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "react-recompose";
import { bindActionCreators } from "redux";
import { widgetProp } from "utils/dashboard_utils";
import { default as ProcessWidgetComponent } from "./process_widget";

const _connect = connect(
  (state, props) => {
    const { widget } = props;
    const { thingName, thingType, subthing, subThingType } = props.params;
    const resources = getEditingWidgetResources(
      state,
      thingName,
      thingType,
      subthing,
      subThingType,
      {
        includeSubthings: false
      }
    );
    const allResources = getResourceValues({
      state,
      resources,
      thingName,
      thingType,
      subthing,
      subThingType,
      simple: true,
      map: true,
      showUnit: true
    });

    return {
      widget: widget,
      resources: allResources.toJS(),
      fileName: widgetProp(widget, "fileName")
    };
  },
  dispatch => bindActionCreators({ uploadFile }, dispatch)
);

export const _lifecycle = lifecycle({
  componentDidUpdate(prevProps) {
    if (!this.props.widget || !prevProps.widget || !this.props.params) {
      return;
    }

    const previousFileName = widgetProp(prevProps.widget, "fileName");
    const currentFileName = widgetProp(this.props.widget, "fileName");

    if (currentFileName && currentFileName !== previousFileName) {
      this.props.data.refetch();
    }
  }
});

export const fetchProcessWidgetImage = gql`
  query($fileName: String!, $thingType: String!) {
    file(scope: { fileName: $fileName, thingType: $thingType }) {
      url
    }
  }
`;

const _validateProps = ({ fileName, thingType }) => {
  const shouldSkip =
    thingType == null ||
    thingType === "" ||
    fileName == null ||
    fileName === "";
  return shouldSkip;
};

const ProcessWidgetContainer = compose(
  _connect,
  graphql(fetchProcessWidgetImage, { skip: _validateProps }),
  _lifecycle,
  withProps(pipe(pathOr("", ["data", "file", "url"]), objOf("image")))
);
export default ProcessWidgetContainer;
export const ProcessWidget = compose(ProcessWidgetContainer)(
  ProcessWidgetComponent
);
