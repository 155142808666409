import { handleActions, createAction } from "redux-actions";
import * as nz from "utils/normalisation_utils";

export const initialStateJobsOngoing = nz.normalizedInitialState();

export const DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_SUCCESS =
  "DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_SUCCESS";
export const DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_REQUEST =
  "DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_REQUEST";
export const DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_FAILURE =
  "DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_FAILURE";

export const DEVICE_MANAGEMENT_CANCEL_JOBS = "DEVICE_MANAGEMENT_CANCEL_JOBS";
export const DEVICE_MANAGEMENT_CANCEL_JOBS_SUCCESS =
  "DEVICE_MANAGEMENT_CANCEL_JOBS_SUCCESS";
export const DEVICE_MANAGEMENT_CANCEL_JOBS_FAILURE =
  "DEVICE_MANAGEMENT_CANCEL_JOBS_FAILURE";

export const listJobsOngoing = createAction(
  DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_REQUEST
);
export const listJobsOngoingSuccess = createAction(
  DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_SUCCESS
);
export const listJobsOngoingFailure = createAction(
  DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_FAILURE
);

export const cancelJobs = createAction(DEVICE_MANAGEMENT_CANCEL_JOBS);
export const cancelJobsSuccess = createAction(
  DEVICE_MANAGEMENT_CANCEL_JOBS_SUCCESS
);
export const cancelJobsFailure = createAction(
  DEVICE_MANAGEMENT_CANCEL_JOBS_FAILURE
);

export default handleActions(
  {
    [DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_REQUEST]: nz.requestStartEvolve,
    [DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_SUCCESS]: nz.requestSuccessEvolve,
    [DEVICE_MANAGEMENT_LIST_ONGOING_JOBS_FAILURE]: nz.requestFailureEvolve,
    [DEVICE_MANAGEMENT_CANCEL_JOBS]: nz.requestStartEvolve,
    [DEVICE_MANAGEMENT_CANCEL_JOBS_SUCCESS]: nz.requestSuccessEvolve,
    [DEVICE_MANAGEMENT_CANCEL_JOBS_FAILURE]: nz.requestFailureEvolve
  },
  initialStateJobsOngoing
);
