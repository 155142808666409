import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { resourceLabelById } from "ducks/schema/schema_selectors";
import { withTimezone, withVariables } from "graphql/utils/general";
import { fromJS } from "immutable";
import * as R from "ramda";
import { connect } from "react-redux";
import { compose, pure, withPropsOnChange } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import {
  getAggregationInterval,
  isObservationsAggregated,
  resourceStatePath
} from "utils/widget_utils";
import {
  AVERAGE as defaultAggregationType,
  OPTION_NAME as aggregationTypeOptionName
} from "../edit/widget_edit_aggregation_options";

const mapStateToProps = (state, props) => {
  const {
    widget,
    container,
    layout,
    resource,
    unit,
    observationPeriod
  } = props;
  const resourceId = resourceIdFromWidget(widget);
  const resources = widgetProp(widget, "resources");
  const resourceStatePathList = resources
    ? resources?.map(resource =>
        resourceStatePath(props.subthing, resource.resourceId)
      )
    : resourceId
    ? [resourceStatePath(props.subthing, resourceId)]
    : [];

  return {
    container: container,
    layout: layout,
    resource: resource,
    unit: unit,
    widget: widget,
    resourceLabel:
      resourceLabelById(state, { id: resourceId }) || props.resourceName,
    observationPeriod,
    aggregationType: widgetProp(
      widget,
      aggregationTypeOptionName,
      defaultAggregationType
    ),
    aggregationInterval: getAggregationInterval({ widget, observationPeriod }),
    resourceStatePathList,
    thingName: props.params.thingName,
    thingType: props.params.thingType,
    from: imu.get(props.observationPeriod, "from").toISOString(),
    to: imu.get(props.observationPeriod, "to").toISOString()
  };
};

const OBSERVATIONS_BUCKETS_PATH = [
  "thingObservationsAggregationQuery",
  "thingObservationsAggregation",
  "buckets"
];

const thingObservationsAggregationQuery = gql`
  query(
    $thingName: String!
    $thingType: String!
    $resourceStatePathList: [String!]!
    $from: String!
    $to: String!
    $aggregationType: AggregationType!
    $aggregationInterval: String!
    $timezone: String
  ) {
    thingObservationsAggregation(
      searchOptions: {
        filter: {
          thingName: $thingName
          period: { from: $from, to: $to }
          resources: $resourceStatePathList
          thingTypes: [$thingType]
        }
        paging: { size: 100 }
        sorting: { sort: "-timestamp" }
      }
      aggregationType: $aggregationType
      aggregationInterval: $aggregationInterval
    ) {
      buckets {
        key(momentFunction: { name: format, timezone: $timezone })
        value
      }
    }
  }
`;

const withThingObservationsAggregation = compose(
  connect(mapStateToProps),
  graphql(thingObservationsAggregationQuery, {
    name: "thingObservationsAggregationQuery",
    options: withVariables(
      [
        "thingName",
        "thingType",
        "resourceStatePathList",
        "from",
        "to",
        "aggregationType",
        "aggregationInterval"
      ],
      withTimezone
    )
  }),
  withPropsOnChange(
    (props, nextProps) =>
      R.path(OBSERVATIONS_BUCKETS_PATH, props) !==
      R.path(OBSERVATIONS_BUCKETS_PATH, nextProps),
    props => {
      const list = [];
      R.pipe(
        R.path(OBSERVATIONS_BUCKETS_PATH),
        R.defaultTo([]),
        R.forEach(observation => {
          const resourcePaths = props.resourceStatePathList.filter(path =>
            R.hasPath(["value", path])(observation)
          );
          resourcePaths.forEach(resourcePath =>
            list.push(
              R.applySpec({
                timestamp: R.prop("key"),
                value: R.path(["value", resourcePath]),
                resourcePath: () => resourcePath
              })(observation)
            )
          );
        })
      )(props);

      return {
        observations: fromJS(list)
      };
    }
  ),
  pure
);

export const widgetIsAggregated = ({ widget }) =>
  isObservationsAggregated(widget);

export default withThingObservationsAggregation;
