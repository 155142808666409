import Lambda from "aws-sdk/clients/lambda";
import * as R from "ramda";
import { Observable } from "rxjs";
import { manifest } from "api/aws/manifest";
import { traceErr } from "./trace";

const parseError = R.ifElse(
  error => error && R.is(String, error.errorMessage),
  error => ({ errorMessage: JSON.parse(error.errorMessage) }),
  R.ifElse(
    error => error && R.is(String, error),
    error => JSON.parse(error),
    error => error
  )
);

const invokeLambda = (functionName, payload, resolve, reject) => {
  const Payload = JSON.stringify(payload);
  const FunctionName = manifest[functionName];

  if (!FunctionName) {
    return reject(`Function, ${functionName}, not found in manifest`);
  }

  const lambda = new Lambda();
  return lambda.invoke({ FunctionName, Payload }, (err, data) => {
    try {
      if (err) {
        reject(parseError(err));
      } else if (!data || !data.Payload) {
        reject("No data");
      } else {
        const result = JSON.parse(data.Payload);
        if (
          data.FunctionError ||
          result.errorMessage ||
          result.statusCode >= 400
        ) {
          reject(parseError(result));
        } else {
          resolve(result);
        }
      }
    } catch (e) {
      reject(e);
    }
  });
};

const wrapInvokeLambda$ = (functionName, payload) => {
  return Observable.create(subscriber => {
    let isInvokationPending = true;

    const onResult = result => {
      isInvokationPending = false;
      subscriber.next(result);
      subscriber.complete();
    };

    const onError = error => {
      isInvokationPending = false;
      subscriber.error(error);
    };

    const invokation = invokeLambda(functionName, payload, onResult, onError);
    return function onUnsubscribed() {
      try {
        isInvokationPending && invokation.abort();
      } catch (err) {
        traceErr(`aborting pending (${functionName} ${payload}`, err);
      }
    };
  });
};

export default wrapInvokeLambda$;
