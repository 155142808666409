import React, { Component } from "react";
import PropTypes from "prop-types";

class ThingsContainer extends Component {
  render = () => <section>{this.props.children}</section>;
}

ThingsContainer.propTypes = {
  getThingTypes: PropTypes.func,
  children: PropTypes.object,
  client: PropTypes.object
};

export default ThingsContainer;
