import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    maxWidth: "110px"
  }
});

export default comp => withStyles(styles)(comp);
