import { createAction, handleActions } from "redux-actions";
import { fromJS } from "immutable";
import { baseTheme } from "themes";
import {
  SET_THEME,
  SAVE_THEME_SUCCESS,
  SAVE_THEME_REQUEST,
  SAVE_THEME_FAILURE
} from "ducks/theme";
import { isNilOrEmpty } from "utils/sorters";
import * as R from "ramda";

export const SET_FAVICON_REQUEST = "BRANDING_SET_FAVICON_REQUEST";

export const SET_LOGO_REQUEST = "BRANDING_SET_LOGO_REQUEST";
export const SET_LOGO_SUCCESS = "BRANDING_SET_LOGO_SUCCESS";
export const SET_LOGO_FAILURE = "BRANDING_SET_LOGO_FAILURE";

const SET_TAB = "BRANDING_SET_TAB";
const SET_COLOR = "BRANDING_SET_COLOR";
const SET_TEXT = "BRANDING_SET_TEXT";
const SET_TAB_TITLE = "BRANDING_SET_TAB_TITLE";

export const SET_SELECTED_DOMAIN = "BRANDING_SET_SELECTED_DOMAIN";
export const GET_BRANDING_THEME = "GET_BRANDING_THEME";
export const GET_BRANDING_THEME_SUCCESS = "GET_BRANDING_THEME_SUCCESS";
export const GET_BRANDING_THEME_FAILURE = "GET_BRANDING_THEME_FAILURE";

export const setTab = createAction(SET_TAB);
export const setColor = createAction(SET_COLOR);
export const setText = createAction(SET_TEXT);
export const setTabTitle = createAction(SET_TAB_TITLE);
export const setSelectedDomain = createAction(SET_SELECTED_DOMAIN);

export const setFavicon = createAction(SET_FAVICON_REQUEST);

export const setLogo = createAction(SET_LOGO_REQUEST);
export const setLogoSuccess = createAction(SET_LOGO_SUCCESS);
export const setLogoFailure = createAction(SET_LOGO_FAILURE);

export const getBrandingTheme = createAction(GET_BRANDING_THEME);
export const getBrandingThemeFailure = createAction(GET_BRANDING_THEME_FAILURE);
export const getBrandingThemeSuccess = createAction(GET_BRANDING_THEME_SUCCESS);

export const initialState = fromJS({
  selectedDomain: "root",
  activeTab: 0,
  isLoading: false,
  colors: [
    {
      label: "Primary",
      key: "primary1Color"
    },
    {
      label: "Secondary",
      key: "accent1Color"
    }
  ],
  theme: {
    loginTexts: {
      welcome: "Welcome to Example Company",
      title: "Title",
      text:
        "This is where your text appears. You can add about two lines of text.",
      mail: "success@simulator.amazonses.com",
      tel: "+46 - xxxxxxxxxx"
    },
    ...baseTheme
  },
  logoUploadFile: null
});

export const getColors = state =>
  state.get("colors").map(c => state.getIn(["theme", "palette", c.get("key")]));

export default handleActions(
  {
    // Keep in sync with theme
    [SET_THEME]: (state, { payload: theme }) =>
      (theme ? state.mergeDeep({ theme }) : state).set("logoUploadFile", null),

    [SAVE_THEME_REQUEST]: state => state.set("isLoading", true),

    [SAVE_THEME_FAILURE]: state => state.set("isLoading", false),

    [SAVE_THEME_SUCCESS]: (state, { payload }) =>
      state
        .set("theme", payload.theme)
        .set("logoUploadFile", null)
        .set("isLoading", false),

    [GET_BRANDING_THEME_SUCCESS]: (state, { payload: theme }) =>
      (theme
        ? state.mergeDeep({ theme }).set("isLoading", false)
        : state.set("isLoading", false)
      ).set("logoUploadFile", null),

    [GET_BRANDING_THEME_FAILURE]: state =>
      state.set("isLoading", false).set("logoUploadFile", null),

    // Logo, Highlight colors, Login page text
    [SET_TAB]: (state, { payload }) => state.set("activeTab", payload),

    // Set logo preview image
    [SET_LOGO_REQUEST]: (state, { payload: { file } }) =>
      state
        .setIn(["theme", "logo"], { src: file.preview, alt: file.name })
        .set("logoUploadFile", file),

    // Set favicon preview image
    [SET_FAVICON_REQUEST]: (state, { payload: { file } }) =>
      state
        .setIn(["theme", "favicon"], { src: file.preview, alt: file.name })
        .set("faviconFile", file),

    [SET_COLOR]: (state, { payload }) => {
      const color = state.getIn(["colors", payload.i, "key"]);
      return state.setIn(["theme", "palette", color], payload.color);
    },

    [SET_TEXT]: (state, { payload }) => {
      return state.setIn(["theme", "loginTexts", payload.key], payload.val);
    },

    [SET_TAB_TITLE]: (state, { payload }) =>
      R.ifElse(
        isNilOrEmpty,
        () => state.removeIn(["theme", "tabTitle"]),
        title => state.setIn(["theme", "tabTitle"], title)
      )(payload),

    [SET_SELECTED_DOMAIN]: (state, { payload }) =>
      state
        .set("selectedDomain", payload)
        .set("activeTab", 0)
        .set("isLoading", true)
        .set("logoUploadFile", null)
  },
  fromJS(initialState)
);
