import { withProps } from "react-recompose";
import * as R from "ramda";

export const LEGACY_ROLES = ["readrole", "readwriterole", "rootrole"];

export const withReadOnly = withProps(({ roleId }) => ({
  isReadOnly: isLegacyRole(roleId)
}));

export const isLegacyRole = R.includes(R.__, LEGACY_ROLES);
export const isLegacyWriteRole = R.includes(R.__, [
  "readwriterole",
  "rootrole"
]);
