import { sub as dateSubtract } from "date-fns";
import * as R from "ramda";
import { nonEmptyArray } from "utils/general_utils";
import { sortingFromString } from "utils/table_utils";

export const stripThingType = str =>
  str && !/^tcxn\//.test(str) ? str.replace(/^[^/]+\//, "") : str;

export const extractThingType = R.ifElse(
  R.isNil,
  R.always(undefined),
  R.pipe(
    R.match(/^([^/]+)\//),
    R.tail,
    R.head,
    R.when(R.equals("tcxn"), R.always(undefined))
  )
);

export const extractResourceThingTypes = R.pipe(
  R.defaultTo([]),
  R.pluck("name"),
  R.map(extractThingType),
  R.filter(R.is(String)),
  R.uniq
);

resources =>
  resources
    .map(r => extractThingType(r.name))
    .filter(thingTypeId => typeof thingTypeId === "string");

export const _logQuery = (query, log = false) => {
  if (log) {
    // eslint-disable-next-line no-console
    console.log("---------------- query");
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(query, null, 2));
  }
  return query;
};

export const _toNumber = val => (val ? Number(val) : val);

export const isNilOrEmpty = items => R.isNil(items) || R.isEmpty(items);

export const createStateString = r =>
  r
    ? !/timestamp|thingName|thingType|^state\..+/.test(r)
      ? `state.${stripThingType(r).replace(/\//g, ".")}`
      : r
    : r;

export const periodTimestamps = ({ from, to }) => {
  to = to ? new Date(to) : new Date();
  from = from ? new Date(from) : dateSubtract(new Date(to), { days: 1 });
  return {
    to: to.valueOf(),
    from: from.valueOf()
  };
};

const _geoFilterValue = (geoJson = []) => ({
  points: geoJson.map(g => ({
    lon: R.head(g),
    lat: R.last(g)
  }))
});

export const _decorateWildcard = text =>
  R.isNil(text) || R.isEmpty(text)
    ? text
    : `*${text ? text.toLowerCase() : ""}*`;

export const _decorateWildcards = (freeText = []) =>
  freeText.map(text =>
    R.isNil(text) || R.isEmpty(text)
      ? text
      : `*${text ? text.toLowerCase() : ""}*`
  );

export const sources = (resources, withState = true) =>
  resources && withState ? resources.map(createStateString) : resources;

// ----- hof wrappers
export const _filter = ({
  option,
  fieldId,
  fieldValue,
  options,
  nest
}) => bodybuilder =>
  bodybuilder.filter(option, fieldId, fieldValue, options, nest);

export const _query = ({
  option,
  fieldId,
  fieldValue,
  options,
  nest
}) => bodybuilder =>
  bodybuilder.query(option, fieldId, fieldValue, options, nest);

export const _rawOption = (key, value) => bodybuilder =>
  !isNilOrEmpty(key) && !isNilOrEmpty(value)
    ? bodybuilder.rawOption(key, value)
    : bodybuilder;

export const size = (value = 10) => bodybuilder =>
  _rawOption("size", value)(bodybuilder);

export const trackScores = (value = false) => bodybuilder =>
  _rawOption("trackScores", value)(bodybuilder);

export const sourceFilter = (resources, withState = true) => bodybuilder =>
  _rawOption("_source", sources(resources, withState))(bodybuilder);

export const sort = (field, order = "asc") => bodybuilder =>
  _rawOption("sort", { [field]: { order } })(bodybuilder);

export const existFilter = field => bodybuilder =>
  _filter({ option: "exists", fieldId: "field", fieldValue: field })(
    bodybuilder
  );

export const geoFilter = (geos, fieldId = "state") => bodybuilder =>
  nonEmptyArray(geos)
    ? _filter({
        option: "geo_polygon",
        fieldId: `${fieldId}.latlng`,
        fieldValue: _geoFilterValue(geos)
      })(bodybuilder)
    : bodybuilder;

export const none = () => bodybuilder => bodybuilder;

export const termFilter = ({ fieldId, fieldValue, nest }) => bodybuilder =>
  !isNilOrEmpty(fieldValue) && !isNilOrEmpty(fieldId)
    ? _filter({ option: "term", fieldId, fieldValue, nest })(bodybuilder)
    : !isNilOrEmpty(fieldValue)
    ? termFilterWithObj(fieldValue)(bodybuilder)
    : bodybuilder;

const _key = (obj = {}) => R.head(Object.keys(obj));
const _keyValue = (nameId, valueId, obj = {}) => {
  const key = _key(obj);
  return { [nameId]: key, [valueId]: obj[key] };
};

export const _existsFilter = (resources = []) => bodybuilder =>
  resources.reduce(
    (bb, resource) =>
      bb.orFilter("exists", "field", createStateString(resource)),
    bodybuilder
  );

export const termFilterWithObj = obj => bodybuilder =>
  isNilOrEmpty(obj)
    ? bodybuilder
    : _filter({ option: "term", ..._keyValue("fieldId", "fieldValue", obj) })(
        bodybuilder
      );

export const constantScoreFilter = props => bodybuilder =>
  _filter({ ...props, option: "constant_score" })(bodybuilder);

export const mustNotExistsFilter = (resources = []) => bodybuilder =>
  (R.is(Array, resources) ? resources : [resources]).reduce(
    (bb, resource) => bb.notFilter("exists", "field", resource),
    bodybuilder
  );

export const mustNotTermFilter = (terms = []) => bodybuilder =>
  terms.reduce(
    (bb, term) => bb.notFilter("term", term.id, term.value),
    bodybuilder
  );

export const termsFilter = (fieldId, terms = []) => bodybuilder =>
  terms.reduce((bb, term) => bb.filter("term", fieldId, term), bodybuilder);

export const termsOrFilter = (fieldId, terms = []) => bodybuilder =>
  terms.reduce((bb, term) => bb.orFilter("term", fieldId, term), bodybuilder);

export const termsFilterCollated = (fieldId, terms = []) => bodybuilder =>
  bodybuilder.filter("terms", fieldId, terms);

export const termsQuery = (fieldId, terms = []) => bodybuilder =>
  bodybuilder.query("terms", fieldId, terms);

export const wildcardFilter = (
  fieldId,
  wildcards = [],
  decorate = true
) => bodybuilder =>
  wildcards.reduce(
    (bb, term) =>
      isNilOrEmpty(term)
        ? bb
        : bb.orFilter(
            "wildcard",
            fieldId,
            decorate ? _decorateWildcard(term) : term
          ),
    bodybuilder
  );

const _includeIfNotUndefined = (key, val) =>
  val !== undefined ? { [key]: val } : {};

const _range = (range, from, to) =>
  range
    ? range
    : {
        ..._includeIfNotUndefined("gte", from),
        ..._includeIfNotUndefined("lte", to)
      };

export const rangeFilter = ({
  rangeId,
  range,
  rangeValue,
  from,
  to
}) => bodybuilder =>
  _filter({
    option: "range",
    fieldId: rangeId,
    fieldValue: rangeValue ? rangeValue : _range(range, from, to)
  })(bodybuilder);

export const rangeQuery = ({
  rangeId,
  range,
  rangeValue,
  from,
  to
}) => bodybuilder =>
  _query({
    option: "range",
    fieldId: rangeId,
    fieldValue: rangeValue ? rangeValue : _range(range, from, to)
  })(bodybuilder);

export const agg = ({ type, field, options, name, aggs }) => bodybuilder =>
  type ? bodybuilder.agg(type, field, options, name, aggs) : bodybuilder;

export const aggsByType = (aggregation = {}, aggregationParams) =>
  agg({
    ...aggregation,
    name: `${aggregation.type}_${aggregation.field}`,
    field: createStateString(aggregation.field),
    options: aggregationParams
  });

export const generalTerm = (fieldId, items) =>
  R.ifElse(isNilOrEmpty, none, items =>
    termsFilter(fieldId, R.is(Array, items) ? items : [items])
  )(items);

export const thingTypeTermFromSorting = sorting => {
  const thingType = extractThingType(sortingFromString(sorting).column);
  return thingType ? generalOrTerm("thingType", [thingType]) : none();
};

export const thingTypeTermFromResources = resources => {
  const thingTypeIds = extractResourceThingTypes(resources);
  return generalOrTerm("thingType", thingTypeIds);
};

export const generalOrTerm = (fieldId, items) =>
  R.ifElse(isNilOrEmpty, none, items =>
    termsOrFilter(fieldId, R.is(Array, items) ? items : [items])
  )(items);

export const generalWildcard = (fieldId, items) =>
  R.ifElse(isNilOrEmpty, none, items =>
    wildcardFilter(fieldId, R.is(Array, items) ? items : [items])
  )(items);

// ----- time
export const calculateInterval = ms => {
  const ONE_HOUR = 24 * 60 * 1000;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_MONTH = 30 * ONE_DAY;
  const ONE_YEAR = 365 * ONE_DAY;

  if (ms >= 5 * ONE_YEAR) {
    return "1M";
  } else if (ms >= 2 * ONE_YEAR) {
    return "1w";
  } else if (ms >= 6 * ONE_MONTH) {
    return "1d";
  } else if (ms >= 30 * ONE_DAY) {
    return "12h";
  } else if (ms >= 7 * ONE_DAY) {
    return "3h";
  } else if (ms >= 24 * ONE_HOUR) {
    return "30m";
  } else if (ms >= 12 * ONE_HOUR) {
    return "10m";
  } else if (ms >= 4 * ONE_HOUR) {
    return "5m";
  } else if (ms >= ONE_HOUR) {
    return "1m";
  }
  return "30s";
};

// ----- fetches
export const rangeFetch = ({
  fieldId,
  fieldValue,
  rangeId,
  resources,
  from,
  to
}) =>
  R.pipe(
    termFilter({ fieldId, fieldValue }),
    rangeFilter({
      rangeId,
      range: {
        gte: from,
        lte: to
      }
    }),
    _existsFilter(resources)
  );

export const fetch = ({ fieldId, fieldValue, resources, withState = true }) =>
  R.pipe(
    termFilter({ fieldId, fieldValue }),
    sourceFilter(resources, withState)
  );

// --- adaption
export const _adaptToVersion2 = query => ({
  ...R.omit(["query"], query),
  ...(!isNilOrEmpty(query.query)
    ? {
        query: {
          filtered: R.pathOr({}, ["query", "bool"], query)
        }
      }
    : {})
});

export const adaptForVersion = (query, version = 2) =>
  _logQuery(version < 5 ? _adaptToVersion2(query) : query);
