import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { manifest } from "api/aws/manifest";
import Snackbar from "components/snackbar";
import config from "config.json";
import { initiateOauth, refreshTokenOauth } from "ducks/auth";
import * as systemActions from "ducks/system";
import { clearClientErrors } from "graphql/cache";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "react-recompose";
import * as imu from "utils/immutable_utils";

const OauthComponent = ({
  classes,
  errorMessage,
  visibleDuration,
  handleDismiss
}) => (
  <div className={classes.wrapper}>
    <div>
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
      <div>Please wait while authenticating</div>
    </div>
    <Snackbar
      data-test="oauth-snack-bar"
      open={!R.isEmpty(errorMessage)}
      message={errorMessage}
      autoHideDuration={visibleDuration}
      onClose={handleDismiss}
    />
  </div>
);

OauthComponent.propTypes = {
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  visibleDuration: PropTypes.number.isRequired,
  handleDismiss: PropTypes.func.isRequired
};

const _withStyles = withStyles(theme => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  progressWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4)
  }
}));

export const _lifecycle = lifecycle({
  componentDidMount() {
    const {
      location: {
        query: { code }
      }
    } = this.props;

    this.props.initiateOauth({
      code,
      manifest,
      location,
      config
    });
  }
});

export const _mapStateToProps = state => {
  const errorMessage = imu.getIn(state.system, ["error"]) || "";
  const visibleDuration = imu.getIn(state.system, ["visibleDuration"]) || 5000;
  return { errorMessage, visibleDuration };
};

const _mapDispatchToProps = {
  initiateOauth,
  refreshTokenOauth,
  clearMessages: systemActions.clearMessages
};

export const _withHandlers = withHandlers({
  handleDismiss: ({ clearMessages }) => () => {
    clearClientErrors();
    clearMessages();
  }
});

export default compose(
  connect(_mapStateToProps, _mapDispatchToProps),
  _withStyles,
  _lifecycle,
  _withHandlers
)(OauthComponent);
