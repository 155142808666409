import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import { withStyles } from "@material-ui/core/styles";

const getViewModeSection = ({ viewModes, name, addDivider = true }) => {
  if (!viewModes || viewModes.length === 0) return null;

  // Some unorthodoxy is necessary here, as Select can't accept fragments
  // or nested MenuItems in order to function correctly
  const menuItems = [];

  if (addDivider) {
    menuItems.push(<Divider key={`divider-${name}`} />);
  }

  menuItems.push(
    <ListSubheader key={`subheader-${name}`} style={{ whiteSpace: "nowrap" }}>
      {name.toUpperCase()} DASHBOARDS
    </ListSubheader>
  );

  viewModes.forEach(viewMode => {
    menuItems.push(
      <MenuItem value={viewMode.id} key={viewMode.id}>
        {viewMode.label}
      </MenuItem>
    );
  });

  return menuItems;
};

const styles = theme => ({
  formControl: {
    margin: 0,
    minWidth: 130
  },
  filledInput: {
    backgroundColor: "inherit",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  selectMenu: {
    paddingTop: 15,
    paddingBottom: 14,
    paddingRight: 25,
    color: theme.palette.primary.main
  },
  underline: {
    "&::before": {
      borderBottom: `4px solid ${theme.palette.primary.main}`
    },
    "&:hover:before": {
      borderBottom: `4px solid ${theme.palette.primary.main}`
    }
  },
  icon: {
    color: theme.palette.primary.main
  }
});

const ViewModePicker = ({
  viewModes: { domain = {}, personal = [] } = {},
  selectedViewMode = "",
  onChange,
  classes,
  disabled = false
}) => (
  <FormControl className={classes.formControl}>
    <Select
      value={selectedViewMode}
      onChange={onChange}
      data-test="viewmode-picker"
      displayEmpty
      classes={{
        selectMenu: classes.selectMenu,
        icon: classes.icon
      }}
      disabled={disabled}
      input={
        <FilledInput
          classes={{
            root: classes.filledInput,
            underline: classes.underline
          }}
          name="viewModePicker"
          id="viewModePicker"
          margin="dense"
        />
      }
    >
      {Object.keys(domain).map((name, i) =>
        getViewModeSection({
          viewModes: domain[name],
          name,
          addDivider: i > 0
        })
      )}
      {getViewModeSection({
        viewModes: personal,
        name: "PERSONAL",
        addDivider: Object.keys(domain).length > 0
      })}
    </Select>
  </FormControl>
);

ViewModePicker.propTypes = {
  selectedViewMode: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.any,
  viewModes: PropTypes.shape({
    domain: PropTypes.object,
    personal: PropTypes.array
  }),
  disabled: PropTypes.bool
};

export default withStyles(styles)(ViewModePicker);
