import { handleActions } from "redux-actions";
import { fromJS, Map } from "immutable";
import {
  SET_SELECTED,
  SET_SELECTED_DATA,
  SET_SELECTED_RESOURCES,
  CLEAR_RESOURCES,
  CREATE
} from "./analyses";

export const initialState = fromJS({
  selected: {
    thingType: null,
    thing: null
  },
  resources: {}
});

export const reduceSetSelectedData = (state, { payload }) =>
  state.setIn(["selected", payload.type], fromJS(payload.values));

export const reduceSetSelected = (state, { payload }) => {
  return state.setIn(
    ["selected"],
    state.getIn(
      ["history", payload, "selected"],
      Map({ thing: "", thingType: "" })
    )
  );
};

export const reduceSetResources = (state, { payload }) =>
  state.setIn(["resources", payload.thing], fromJS(payload.resources));

export const reduceClearObservations = state =>
  state.setIn(["resources"], fromJS({}));

export default handleActions(
  {
    [CREATE]: () => initialState,
    [SET_SELECTED_DATA]: reduceSetSelectedData,
    [SET_SELECTED]: reduceSetSelected,
    [SET_SELECTED_RESOURCES]: reduceSetResources,
    [CLEAR_RESOURCES]: reduceClearObservations
  },
  initialState
);
