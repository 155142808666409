/* eslint-disable react/no-children-prop */
import { Card } from "components/cards";
import * as aggregationOptions from "components/widget/edit/widget_edit_aggregation_options";
import { getResourceValue } from "ducks/aggregators/resources";
import * as dashboardSelectors from "ducks/dashboard/dashboard_selectors";
import {
  resourceIsSettableById,
  resourceUnitById
} from "ducks/schema/schema_selectors";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { resourceIdFromWidget, widgetProp } from "utils/dashboard_utils";
import * as imu from "utils/immutable_utils";
import { isVirtual } from "utils/resource_utils";
import {
  getWidget,
  getWidgetResourceValue
} from "./widget_types/widget_factory";

const StyleText = {
  display: "flex"
};

const StyleCardDimensions = {
  marginBottom: null,
  height: "100%"
};

const WidgetContainer = props => {
  const { header, content, footer, decoratedProps } = getWidget(props);
  const { type, elevation } = props;
  return (
    <Card
      data-test={props.dataTestId}
      style={StyleCardDimensions}
      noHeader={!decoratedProps.includeHeader}
      header={header}
      footer={footer}
      children={content}
      showProgressLine={decoratedProps.showProgressLine}
      textStyle={decoratedProps.StyleText || StyleText}
      noPadding
      elevation={elevation}
      widgetType={type}
    />
  );
};

WidgetContainer.propTypes = {
  filterId: PropTypes.string,
  widget: PropTypes.object,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
  params: PropTypes.object,
  query: PropTypes.object,
  resource: PropTypes.object,
  includeHeader: PropTypes.bool,
  includeFooter: PropTypes.bool,
  container: PropTypes.object,
  gridSize: PropTypes.object,
  wkey: PropTypes.string,
  subthing: PropTypes.string,
  isMovingWidgets: PropTypes.bool,
  setEditWidget: PropTypes.func,
  setCurrentWidget: PropTypes.func,
  deleteEditModeWidget: PropTypes.func,
  location: PropTypes.object,
  elevation: PropTypes.number,
  thingShadowResourceKeypath: PropTypes.array,
  dashboardType: PropTypes.oneOf(["collection", "detail", "allThings"])
};

export const getUnit = (state, widget) => {
  const widgetUnit = widgetProp(widget, "unit");
  if (
    (widgetUnit && widgetUnit !== "-") ||
    widgetProp(widget, aggregationOptions.OPTION_NAME) ===
      aggregationOptions.COUNT
  ) {
    return widgetUnit;
  } else {
    return resourceUnitById(state, { id: resourceIdFromWidget(widget) });
  }
};

const emptyParams = {};

const makeMapStateToProps = () => (state, props) => {
  let {
    params = emptyParams,
    widget,
    includeHeader = true,
    includeFooter = true,
    isComponent = false,
    filterId,
    dashboardType,
    dashboardId,
    isMovingWidgets,
    observationPeriod
  } = props;
  let query;
  let location;
  if (state.routing && state.routing.locationBeforeTransitions) {
    query = state.routing.locationBeforeTransitions.query;
    location = state.routing.locationBeforeTransitions;
    isMovingWidgets =
      dashboardType === "allThings"
        ? dashboardSelectors.isMovingWidgets(state, dashboardId)
        : isMovingWidgets;
  }

  const resourceName = resourceIdFromWidget(widget);
  const resourcesFromWidget = widgetProp(widget, "resources") || [];
  const type = imu.get(widget, "type", undefined);
  const unit = getUnit(state, widget);
  const dataTestId = `widget__${widget &&
    imu.get(widget, "label", "").replace(" ", "-")}__${type}`;
  const isVirtualResource = isVirtual(widget ? resourceName : undefined);

  const resourceValueGetter = getWidgetResourceValue(type);
  const resource = resourceValueGetter
    ? resourceValueGetter(state, props)
    : getResourceValue({
        state,
        resourceName,
        thingType: params.thingType, // sc
        thingName: params.thingName,
        subThingType: params.subThingType,
        subthing: params.subthing
      });
  const resourceList = resourcesFromWidget.map(({ resourceId }) =>
    getResourceValue({
      state,
      resourceName: resourceId,
      thingType: params.thingType,
      thingName: params.thingName,
      subThingType: params.subThingType,
      subthing: params.subthing
    })
  );
  const resourceIsSettable = resourceIsSettableById(state, {
    id: resourceName
  });

  return {
    resource, // obj
    resourceList,
    subthing: params.subthing, // sc, should wrap these into a params record
    thingType: params.thingType, // sc
    thingName: params.thingName, // sc

    type, // sc
    isMovingWidgets, // sc
    elevation: isMovingWidgets ? 4 : 2, // sc
    location,
    query, // obj
    params, // obj
    includeHeader, // sc
    includeFooter, // sc
    isComponent, // sc
    widget, // im
    dataTestId,
    filterId, // sc
    dashboardType,
    includeEditMenu: dashboardType !== "allThings",
    unit,
    isVirtualResource,
    resourceIsSettable,
    observationPeriod
  };
};

export default compose(connect(makeMapStateToProps))(WidgetContainer);
