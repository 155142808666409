import { createAction, handleActions } from "redux-actions";
import * as R from "ramda";
const ADD_FIELD = "ADD_FIELD";
const SET_FIELD = "SET_FIELD";

export const DELETE_FIELD = "DELETE_FIELD";

export const LOAD_FIELDS_REQUEST = "LOAD_FIELDS_REQUEST";
export const LOAD_FIELDS_SUCCESS = "LOAD_FIELDS_SUCCESS";
export const LOAD_FIELDS_FAILURE = "LOAD_FIELDS_FAILURE";

export const SAVE_FIELDS_REQUEST = "SAVE_FIELDS_REQUEST";
export const SAVE_FIELDS_SUCCESS = "SAVE_FIELDS_SUCCESS";
export const SAVE_FIELDS_FAILURE = "SAVE_FIELDS_FAILURE";

export const addField = createAction(ADD_FIELD);
export const setField = createAction(SET_FIELD);
export const deleteField = createAction(DELETE_FIELD);

export const saveFields = createAction(SAVE_FIELDS_REQUEST);
export const saveFieldsSuccess = createAction(SAVE_FIELDS_SUCCESS);
export const saveFieldsFailure = createAction(SAVE_FIELDS_FAILURE);

export const loadFields = createAction(LOAD_FIELDS_REQUEST);
export const loadFieldsSuccess = createAction(LOAD_FIELDS_SUCCESS);
export const loadFieldsFailure = createAction(LOAD_FIELDS_FAILURE);

export const initialState = [];

export default handleActions(
  {
    [ADD_FIELD]: R.append({ label: "", type: "none" }),

    [SET_FIELD]: (state, action) =>
      R.over(
        R.lensIndex(action.payload.i),
        R.mergeDeepLeft(R.omit(["i"], action.payload))
      )(state),
    [DELETE_FIELD]: (state, action) => R.remove(action.payload, 1)(state),

    [LOAD_FIELDS_SUCCESS]: (state, { payload = [] }) => payload,

    [SAVE_FIELDS_SUCCESS]: (state, { payload = [] }) => payload
  },
  initialState
);
