import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import FileDownload from "@material-ui/icons/GetApp";
import RemoveIcon from "@material-ui/icons/Remove";
import FormIconButton from "components/buttons/form_icon";
import { ConfirmDialog } from "components/modal";
import WidgetFooterWrapper from "components/widget/widget_footer_wrapper";
import prettyBytes from "pretty-bytes";
import PropTypes from "prop-types";
import {
  concat,
  includes,
  isEmpty,
  objOf,
  pipe,
  prop,
  subtract,
  toString,
  __
} from "ramda";
import React from "react";
import { branch, compose, renderComponent, withProps } from "react-recompose";
import styled from "styled-components";
import { pluralize } from "utils/general_utils";
import { timeFrom } from "utils/view_utils";
import { TableHeaderCell } from "components/table";

const styles = {
  actionContainer: {
    display: "flex",
    alignItems: "center"
  },
  actionButton: {
    marginRight: "24px"
  },
  checkboxColumn: {
    width: "24px"
  },
  header: {
    flex: 1,
    paddingLeft: "12px",
    paddingTop: "12px",
    paddingBottom: "12px"
  },
  table: {
    tableLayout: "fixed"
  },
  fileNameCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  downloadCell: {
    width: "80px"
  },
  fileSizeCell: {
    maxWidth: "70px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  lastModifiedCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  selected: {
    backgroundColor: "rgb(245, 245, 245) !important"
  }
};

const pluralizedFiles = pluralize("No files", "file", "files");

const isCheckedAll = (files, selectedRows) =>
  files.length > 0 && files.length === selectedRows.length;

const handleCheckAll = handler => (e, isChecked) =>
  handler(isChecked ? "all" : "none");

const TableFooterWrapper = styled(WidgetFooterWrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  border-top: none;
`;

export const NoSelection = styled.div`
  height: 507px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoSelectionCard = ({ text, showProgressLine = true }) => (
  <Card noHeader noPadding showProgressLine={showProgressLine}>
    <NoSelection>
      <span>{text}</span>
    </NoSelection>
  </Card>
);

NoSelectionCard.propTypes = {
  text: PropTypes.string.isRequired,
  showProgressLine: PropTypes.bool
};

const ThingNameNoSelection = () => (
  <NoSelectionCard text="Please select a thing to list the files for that thing" />
);

const ThingTypeNoSelection = () => (
  <NoSelectionCard text="Please select a thing type to list the files for that thing type" />
);

const isThingNameNoSelection = ({ currentScope, thingName }) =>
  currentScope === "thingName" && !thingName;

const isThingTypeNoSelection = ({ currentScope, thingType }) =>
  currentScope === "thingType" && !thingType;

const removeDisabled = props => props.isLoading || isEmpty(props.selectedRows);

const FilesCard = withStyles(styles)(props => (
  <React.Fragment>
    <div className={props.classes.actionContainer}>
      <Typography variant="h6" className={props.classes.header}>
        {pluralizedFiles(props.files.length)}
      </Typography>
      <ConfirmDialog
        confirmAction={props.onRemove}
        title="Confirm remove files"
        message={`Are you sure you want to delete the ${props.selectedRows.length} selected files?`}
        disabled={removeDisabled(props)}
      >
        <FormIconButton
          color="primary"
          text="Remove"
          icon={<RemoveIcon />}
          onClick={() => {}}
          data-test={"remove-button"}
          disabled={removeDisabled(props)}
          className={props.classes.actionButton}
        />
      </ConfirmDialog>
      <FormIconButton
        color="primary"
        text="Upload"
        icon={<AddIcon />}
        onClick={() => props.openModalUploadFile()}
        data-test={"upload-button"}
        disabled={props.isLoading}
        className={props.classes.actionButton}
      />
    </div>
    <Table size="small" className={props.classes.table}>
      <TableHead>
        <TableRow>
          <TableHeaderCell
            className={props.classes.checkboxColumn}
            padding="checkbox"
            align="center"
          >
            <Checkbox
              checked={isCheckedAll(props.files, props.selectedRows)}
              onChange={handleCheckAll(props.onSelectAll)}
              color="primary"
            />
          </TableHeaderCell>
          <TableHeaderCell className={props.classes.fileNameCell}>
            Key
          </TableHeaderCell>
          <TableHeaderCell className={props.classes.fileSizeCell}>
            Size
          </TableHeaderCell>
          <TableHeaderCell className={props.classes.lastModifiedCell}>
            Last modified
          </TableHeaderCell>
          <TableHeaderCell className={props.classes.downloadCell}>
            Download
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.files.map((file, i) => (
          <TableRow
            key={i}
            hover
            selected={includes(i, props.selectedRows)}
            classes={{ selected: props.classes.selected }}
          >
            <TableCell
              className={props.classes.checkboxColumn}
              padding="checkbox"
              align="center"
            >
              <Checkbox
                checked={includes(i, props.selectedRows)}
                onChange={e => props.onRowSelection(e, i)}
                color="primary"
              />
            </TableCell>
            <TableCell className={props.classes.fileNameCell}>
              {file.key}
            </TableCell>
            <TableCell className={props.classes.fileSizeCell}>
              {prettyBytes(file.size)}
            </TableCell>
            <TableCell className={props.classes.lastModifiedCell}>
              {timeFrom(file.lastModified)}
            </TableCell>
            <TableCell className={props.classes.downloadCell}>
              <FormIconButton
                data-test={`download-button-${file.eTag}`}
                href={file.url}
                onClick={e => e.stopPropagation()}
                target="_blank"
                icon={
                  <FileDownload
                    data-test="file-download-icon"
                    nativeColor="#000"
                  />
                }
                color="primary"
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {props.isTruncated && (
        <TableFooter>
          <TableRow>
            <TableCell colspan="5">
              <TableFooterWrapper>
                <FormIconButton
                  data-test="load-more-button"
                  onClick={props.onLoadMore}
                  isLoading={props.isLoading}
                  text="Load more files"
                  color="primary"
                />
              </TableFooterWrapper>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  </React.Fragment>
));

FilesCard.defaultProps = {
  isLoading: false,
  files: [],
  selectedRows: []
};

FilesCard.propTypes = {
  isLoading: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      lastModified: PropTypes.string,
      eTag: PropTypes.string,
      size: PropTypes.number,
      url: PropTypes.string
    })
  ).isRequired,
  isTruncated: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onRemove: PropTypes.func,
  onRowSelection: PropTypes.func,
  onSelectAll: PropTypes.func,
  scope: PropTypes.oneOf(["root", "public", "thingName", "thingType"]),
  selected: PropTypes.any,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  openModalUploadFile: PropTypes.func,
  showProgressLine: PropTypes.bool,
  height: PropTypes.string,
  classes: PropTypes.object
};

const COLUMN_HEADER_HEIGHT = 56;
const CARD_HEADER_HEIGHT = 48;
export const _withHeight = withProps(
  pipe(
    prop("height"),
    subtract(__, COLUMN_HEADER_HEIGHT),
    subtract(__, CARD_HEADER_HEIGHT),
    toString,
    concat(__, "px"),
    objOf("height")
  )
);

export default compose(
  branch(isThingNameNoSelection, renderComponent(ThingNameNoSelection)),
  branch(isThingTypeNoSelection, renderComponent(ThingTypeNoSelection)),
  _withHeight
)(FilesCard);
