import * as Validators from "./validators";
import { TYPE_FLOAT, TYPE_DOUBLE, TYPE_INTEGER, TYPE_LONG } from "./types";

export const precisionDefaultValidator = Validators.chain(
  Validators.Int,
  Validators.Min(0)
);
export const isFloatingPoint = type =>
  type === TYPE_FLOAT || type === TYPE_DOUBLE;

export const isNumber = type =>
  type === TYPE_FLOAT ||
  type === TYPE_DOUBLE ||
  type === TYPE_INTEGER ||
  type === TYPE_LONG;

export const parse = (value, isFloatingPoint = false) => {
  const parsedValue = isFloatingPoint ? parseFloat(value) : parseInt(value, 10);
  return isNaN(parsedValue) ? value : parsedValue;
};
export const onChange = (
  fn,
  index,
  type,
  param,
  validator,
  parseNum = false
) => e => {
  const isNum = isNumber(type);
  const isFloat = isNum && isFloatingPoint(type);
  const { value, error } = (validator[param] || validator)({
    value: isNum || parseNum ? parse(e.target.value, isFloat) : e.target.value
  });
  fn(e, index, param, value, error);
};

export const startGeoPoint = (lat, lon, oldValue = null) => {
  if (oldValue) {
    const latLng = oldValue.split(",");
    return [parseFloat(latLng[0]), parseFloat(latLng[1])];
  }
  return [parseFloat(lat), parseFloat(lon)];
};
