import * as R from "ramda";
import { createSelector } from "reselect";
import {
  isAllowed,
  isSettingAllowed,
  OBJECT_TYPES,
  OPERATIONS,
  SETTING_TYPES
} from "utils/auth_utils";
import { DEV_ACCOUNT_ID, UAT_ACCOUNT_ID } from "../../constants";

const EMPTY_CREDENTIALS = {};
const EMPTY_ACCOUNT = {};
const EMPTY_USER = {};

const _authRoot = R.prop("auth");
const _registerRoot = R.path(["auth", "view", "register"]);
const _verifyRoot = R.path(["auth", "view", "verify"]);
const _passwordRoot = R.path(["auth", "view", "password"]);
const _permissionsRoot = R.path(["auth", "permissions"]);

export const isLoggedIn = state => !!credentialsSelector(state).token;

export const isProductionAccount = createSelector(
  state => state.auth,
  auth =>
    R.pipe(
      R.pathOr("", ["account", "Id"]),
      R.complement(R.either(R.equals(DEV_ACCOUNT_ID), R.equals(UAT_ACCOUNT_ID)))
    )(auth)
);

export const getSignupEnabled = createSelector(
  _authRoot,
  R.prop("signUpEnabled")
);

export const userSelector = createSelector(
  _authRoot,
  R.pathOr(EMPTY_USER, ["user"])
);

export const userIdSelector = createSelector(userSelector, R.prop("id"));

export const userNameSelector = createSelector(
  userSelector,
  R.prop("userName")
);
export const userDomainSelector = createSelector(
  userSelector,
  R.prop("domainName")
);

export const userIsRoot = createSelector(
  userSelector,
  R.propEq("domainName", "root")
);

export const credentialsSelector = createSelector(
  _authRoot,
  R.pathOr(EMPTY_CREDENTIALS, ["credentials"])
);

export const accountSelector = createSelector(
  _authRoot,
  R.pathOr(EMPTY_ACCOUNT, ["account"])
);

export const permissionsSelector = createSelector(
  _permissionsRoot,
  permissions => permissions
);

export const getEnv = createSelector(accountSelector, R.propOr(null, "env"));

export const getAccountId = createSelector(
  accountSelector,
  R.propOr(null, "Id")
);

export const errorSelector = createSelector(_authRoot, R.prop("error"));

export const warningSelector = createSelector(_authRoot, R.prop("warning"));

export const isLoading = createSelector(_authRoot, R.prop("isLoading"));

export const verifyUserNameSelector = createSelector(
  _verifyRoot,
  R.prop("userName")
);

export const registerErrorSelector = createSelector(
  _registerRoot,
  R.prop("error")
);

export const registerIsLoadingSelector = createSelector(
  _registerRoot,
  R.prop("isLoading")
);
export const registerSuccessSelector = createSelector(
  _registerRoot,
  R.prop("registerSuccess")
);

export const setPasswordErrorSelector = createSelector(
  _passwordRoot,
  R.prop("error")
);

export const setPasswordLoadingSelector = createSelector(
  _passwordRoot,
  R.prop("isLoading")
);

export const setPasswordSuccessSelector = createSelector(
  _passwordRoot,
  R.prop("success")
);

export const userConsentNeeded = createSelector(
  _authRoot,
  R.prop("userConsentNeeded")
);

export const userHasAppBoardPermission = createSelector(
  permissionsSelector,
  isSettingAllowed(SETTING_TYPES.AppBoard, OPERATIONS.READ)
);

export const userHasAppBoardThingPermission = createSelector(
  permissionsSelector,
  R.allPass([
    isAllowed(OBJECT_TYPES.Dashboards, OPERATIONS.READ),
    isAllowed(OBJECT_TYPES.Domains, OPERATIONS.READ),
    isAllowed(OBJECT_TYPES.Observations, OPERATIONS.READ),
    isAllowed(OBJECT_TYPES.ThingTypes, OPERATIONS.READ),
    isAllowed(OBJECT_TYPES.Things, OPERATIONS.READ)
  ])
);

const hasSystemRole = createSelector(
  userSelector,
  R.pipe(R.propOr(null, "roleName"), value =>
    R.includes(value, ["Read", "ReadWrite"])
  )
);
export const userHasDeveloperToolsPermission = state => {
  const permissions = permissionsSelector(state);

  return (
    R.allPass([
      isAllowed(OBJECT_TYPES.Domains, OPERATIONS.READ),
      isAllowed(OBJECT_TYPES.Events, OPERATIONS.READ),
      isAllowed(OBJECT_TYPES.Things, OPERATIONS.READ)
    ])(permissions) &&
    (isAllowed(OBJECT_TYPES.ThingPubSub, OPERATIONS.READ)(permissions) ||
      hasSystemRole(state))
  );
};
